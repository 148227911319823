import { ThunkAction } from 'redux-thunk'
import { State } from 'reducers'
import axios, { AxiosResponse } from 'axios'
import { FailureError } from 'common/utils/reduxApiFactory'
import { PaymentSettings, TApiError } from 'api/types'
import {
  deleteDriverPaymentSettingsActions,
  getDriverPaymentSettingsActions,
  patchDriverPaymentSettingsActions,
  postDriverPaymentSettingsActions,
} from 'api/driversById/payment-settings/actions'
import {
  DriverPaymentSettingsActions,
  DriverPaymentSettingsParams,
} from 'api/driversById/payment-settings/types'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  DriverPaymentSettingsActions
>

export const getPaymentSettingsRequest = ({
  driverId,
}: DriverPaymentSettingsParams): THandleRequest => async (dispatch) => {
  const { start, update, success, failure } = getDriverPaymentSettingsActions
  dispatch(start())
  try {
    const response: AxiosResponse<PaymentSettings> = await axios.get(
      `erp/1/drivers/${driverId}/payment-settings`
    )
    dispatch(update(response.data))
    dispatch(success())
  } catch (e) {
    if (e.response) {
      const data = e.response.data as TApiError<DriverPaymentSettingsParams>
      const error: FailureError<DriverPaymentSettingsParams> = new Error(
        data.message
      )
      error.data = data
      dispatch(failure(error))
    }
  }
}

export const postPaymentSettingsRequest = (
  { driverId }: DriverPaymentSettingsParams,
  settings: Partial<PaymentSettings>
): THandleRequest => async (dispatch) => {
  const { start, success, failure } = postDriverPaymentSettingsActions
  dispatch(start())
  try {
    await axios.post(`erp/1/drivers/${driverId}/payment-settings`, settings)
    dispatch(success())
  } catch (e) {
    if (e.response) {
      const data = e.response.data as TApiError<DriverPaymentSettingsParams>
      const error: FailureError<DriverPaymentSettingsParams> = new Error(
        data.message
      )
      error.data = data
      dispatch(failure(error))
      throw e.response.data.payload
    }
  }
}

export const patchPaymentSettingsRequest = (
  { driverId }: DriverPaymentSettingsParams,
  settings: Partial<PaymentSettings>
): THandleRequest => async (dispatch) => {
  const { start, success, failure } = patchDriverPaymentSettingsActions
  dispatch(start())
  try {
    await axios.patch(`erp/1/drivers/${driverId}/payment-settings`, settings)
    dispatch(success())
  } catch (e) {
    if (e.response) {
      const data = e.response.data as TApiError<DriverPaymentSettingsParams>
      const error: FailureError<DriverPaymentSettingsParams> = new Error(
        data.message
      )
      error.data = data
      dispatch(failure(error))
      throw e.response.data.payload
    }
  }
}

export const deletePaymentSettingsRequest = ({
  driverId,
}: DriverPaymentSettingsParams): THandleRequest => async (dispatch) => {
  const { start, success, failure } = deleteDriverPaymentSettingsActions
  dispatch(start())
  try {
    await axios.delete(`erp/1/drivers/${driverId}/payment-settings`)
    dispatch(success())
  } catch (e) {
    if (e.response) {
      const data = e.response.data as TApiError<DriverPaymentSettingsParams>
      const error: FailureError<DriverPaymentSettingsParams> = new Error(
        data.message
      )
      error.data = data
      dispatch(failure(error))
    }
  }
}
