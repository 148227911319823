import React, { useEffect } from 'react'
import styled from 'styled-components'

import { ContainerProps } from 'settings/features/tariffTab/containers/TariffTabContainer'
import TariffContainer from 'elastic-ui/tariff/containers/TariffContainer'

const Wrapper = styled.div`
  padding: ${({ theme }) => theme.paddings.main}px;
`

export const TariffTab: React.FC<ContainerProps> = ({ onDidMount }) => {
  useEffect(() => {
    onDidMount()
  }, [onDidMount])

  return (
    <Wrapper>
      <TariffContainer />
    </Wrapper>
  )
}
