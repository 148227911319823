import { connect } from 'react-redux'
import { State } from 'reducers'
import { apiActions, apiSelectors } from 'api'
import { MyThunkDispatch } from 'api/reducer'
import { AggregatorsCard } from '../molecules'
import { Aggregator } from 'api/types'

const mapStateToProps = (state: State) => ({
  isLoading: apiSelectors.drivers.contractsNoContract.getIsFetching(
    state
  ) || apiSelectors.park.aggregators.getIsFetching(state),
  aggregators: apiSelectors.park.aggregators.getAggregators(state)
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  onDownloadClick: (aggregators: Omit<Aggregator, "account">[]) =>
        dispatch(apiActions.drivers.contractsNoContract.handleRequest(aggregators)),
    fetchAggregators: () => dispatch(apiActions.park.aggregators.handleRequest())
})

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const AggregatorsCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AggregatorsCard)
