import React from 'react'

import { Image } from 'shared/ui'

import {
  ErrorLayoutDescription,
  ErrorLayoutHeader,
  ErrorLayoutLeft,
  ErrorLayoutRight,
  ErrorLayoutSides,
  ErrorLayoutWrapper,
} from 'shared/router/components/ServerErrorLayout/styles'

import {
  BodyText,
  Icon,
  ListItem,
  TitleText,
  TransparentButton,
} from 'ui-kit/components'

import ufoWithGhostSrc from 'static/backgrounds/ufo-with-ghost.svg'

interface Props {
  onReturnClick: () => void
}

export const ServerErrorLayout: React.FC<Props> = ({ onReturnClick }) => {
  return (
    <ErrorLayoutWrapper>
      <ErrorLayoutHeader>
        <Icon pointer={false} className="mozen-logo" name="mozen" />
      </ErrorLayoutHeader>
      <ErrorLayoutSides>
        <ErrorLayoutLeft>
          <ListItem>
            <TitleText fontWeight="semi-bold">Mozen не отвечает</TitleText>
          </ListItem>
          <ErrorLayoutDescription>
            <BodyText color="gray9">
              Мы уже в курсе и работаем над исправлением.
              <br />
              Приносим извинения за временные неудобства.
            </BodyText>
          </ErrorLayoutDescription>
          <TransparentButton onClick={onReturnClick} fontWeight="semi-bold">
            Вернуться на главную
          </TransparentButton>
        </ErrorLayoutLeft>
        <ErrorLayoutRight>
          <Image src={ufoWithGhostSrc} />
        </ErrorLayoutRight>
      </ErrorLayoutSides>
    </ErrorLayoutWrapper>
  )
}
