import { connect } from 'react-redux'

import { State } from 'reducers'
import { apiSelectors } from 'api'
import { MyThunkDispatch } from 'api/reducer'
import { ModalsNames } from 'common/enums'
import { modalsActions, modalsSelectors } from 'shared/modals'
import { ParkE2C } from 'settings/features/parkE2C/organisms'

const mapStateToProps = (state: State) => ({
  data: apiSelectors.park.getParkPayoutCredentials.selectData(state),
  bankType: apiSelectors.park.details.getBankType(state),
  isEditE2CModalOpened: modalsSelectors.getIsModalOpened(
    state,
    ModalsNames.EditE2C
  ),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  openEditE2CModal: () =>
    dispatch(modalsActions.openModal(ModalsNames.EditE2C)),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const ParkE2CContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ParkE2C)
