import { createApiReducer } from 'common/utils/reduxApiFactory'
import { postNotifyBotActions } from 'api/park/notify-bot/post/actions'
import { PostNotifyBotState } from 'api/park/notify-bot/post/types'

export const initialState: PostNotifyBotState = {
  status: null,
  data: null,
}

export const postNotifyBotReducer = createApiReducer(
  postNotifyBotActions,
  initialState
)
