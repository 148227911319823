import { ReferralInvite } from 'api/referral/invite/list/post/types'
import { Application } from 'elastic-ui/referral-program/organisms/NewApplications/types'

export default ({
  created_at,
  id,
  invited_driver_name,
  invited_driver_phone,
}: ReferralInvite): Application => ({
  date: new Date(created_at),
  id: id.toString(),
  name: invited_driver_name,
  phoneNumber: invited_driver_phone,
})
