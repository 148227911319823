import React, { MutableRefObject, useEffect } from 'react'
import { PaymentTransactionParticipant } from 'api/types'
import { LargeFilter } from 'common/types/local/filter'
import { toISOFormat } from 'common/utils/dates'
import _ from 'lodash'

export const separateFilter = (
  currentFilters: string[],
  filterData: LargeFilter[],
  categoryName: string | number
): string[] => {
  const filterCategories: LargeFilter[] = filterData.filter(
    (item) => item.category === categoryName
  )

  return currentFilters.filter(
    (firstCollection) =>
      filterCategories.filter(
        (secondCollection) => secondCollection.name === firstCollection
      ).length !== 0
  )
}

export const filterToQuery = (filters: string[]) =>
  filters.length !== 0 ? filters.join('&') : undefined

export const makeDefaultDateRange = () => {
  const dateStart = new Date()
  dateStart.setDate(dateStart.getDate() - 14)
  const dateEnd = new Date()
  dateEnd.setDate(dateEnd.getDate() - 1)
  return {
    from: toISOFormat(dateStart),
    dateFrom: dateStart,
    to: toISOFormat(dateEnd),
    dateTo: dateEnd,
  }
}

export const addUsedeskWidget = () => {
  const widgetId = 'omnidesk_widget'
  const hasOmindeskWidget = !!document.getElementById(widgetId)
  const isProd = !__DEV__
  if ((isProd && hasOmindeskWidget) || __DEV__) return

  const script = document.createElement('script')
  script.innerHTML = `!function(e,o){!window.omni?window.omni=[]:'';window.omni.push(o);o.g_config={widget_id:"13287-h085k401"}; o.email_widget=o.email_widget||{};var w=o.email_widget;w.readyQueue=[];o.config=function(e){ this.g_config.user=e};w.ready=function(e){this.readyQueue.push(e)};var r=e.getElementsByTagName("script")[0];c=e.createElement("script");c.type="text/javascript",c.async=!0;c.src="https://omnidesk.ru/bundles/acmesite/js/cwidget0.2.min.js";r.parentNode.insertBefore(c,r)}(document,[]);`
  script.async = true
  script.id = widgetId
  document.body.appendChild(script)
}

export function useDataCollector<T>(
  initialValues: T,
  onChange?: (data: T) => void
): [MutableRefObject<T>, (updates: T) => void] {
  const data = React.useRef<T>(initialValues)

  const updateData = (updates: T) => {
    data.current = { ...data.current, ...updates }

    if (onChange) {
      onChange(data.current)
    }
  }

  return [data, updateData]
}

export const renderParticipant = (
  participant: PaymentTransactionParticipant
): string =>
  participant.participant ? participant.participant : 'Водитель без договора'

export const deepEqual = (prevProps: any, nextProps: any) =>
  _.isEqual(prevProps, nextProps)

export const useScript = (url: string) => {
  useEffect(() => {
    const script = document.createElement('script')

    script.src = url
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [url])
}

export const addKTrackingData = () => {
  if (!(window as any).KTracking) {
    ;(window as any).KTracking = {
      collectNonUniqueClicks: false,
      multiDomain: false,
      R_PATH: '//tracker.mozen.io/hRNVN7',
      P_PATH: '//tracker.mozen.io/108a743/postback',
      listeners: [],
      reportConversion(...args: any) {
        this.queued = args
      },
      getSubId(fn: any) {
        this.listeners.push(fn)
      },
      ready(fn: any) {
        this.listeners.push(fn)
      },
    }
  }
}
