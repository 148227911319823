import { ThunkAction } from 'redux-thunk'
import { State } from 'reducers'
import axios from 'axios'
import { FailureError } from 'common/utils/reduxApiFactory'
import { TApiError } from 'api/types'
import { getReferralOffersActions as actions } from './actions'
import { GetReferralOffersActions } from './types'
import { ReferralOfferResponseSchema } from '../types'

export type HandleRequest = ThunkAction<
  Promise<ReferralOfferResponseSchema>,
  State,
  undefined,
  GetReferralOffersActions
>

export const getReferralOffersRequest = (): HandleRequest => async (
  dispatch
) => {
  dispatch(actions.start())
  try {
    const { data } = await axios.get<ReferralOfferResponseSchema>(
      'erp/1/referral/offer'
    )
    dispatch(actions.success())
    return data
  } catch (e) {
    if (e.response) {
      const data = e.response.data as TApiError
      const error: FailureError = new Error(data.message)
      error.data = data
      dispatch(actions.failure(error))
    }

    throw e
  }
}
