import { ThunkAction } from 'redux-thunk'

import { State, TRootAction } from 'reducers'
import { apiActions } from 'api'
import { makeDefaultDateRange } from 'common/utils/helpers'

type ThunkResult<R> = ThunkAction<R, State, undefined, TRootAction>

export const onDidMount: () => ThunkResult<void> = () => {
  return async (dispatch) => {
    const { from, to } = makeDefaultDateRange()

    await Promise.race([
      dispatch(
        apiActions.park.statisticsFees.handleRequest({
          date_start: from,
          date_end: to,
        })
      ),
      dispatch(
        apiActions.park.statisticsWeekdayWithdraw.handleRequest({
          date_start: from,
          date_end: to,
        })
      ),
    ])
  }
}
