import React, { useState } from 'react'
import {
  EuiBadge,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiTitle,
} from '@elastic/eui'
import moment from 'moment'

import { ReportPeriod } from 'elastic-ui/referral-program/types'
import { EnhancedPanel } from 'elastic-ui/shared/atoms/EnhancedPanel/EnhancedPanel'
import HorizontalSpacer from 'elastic-ui/shared/atoms/HorizontalSpacer'
import DateRangePicker from 'ui-kit/molecules/dateRangePicker/DateRangePicker'

import Flexbox from '../../../shared/atoms/Flexbox'

interface Props {
  isLoading: boolean
  onDownloadClick: (period: ReportPeriod) => void
}

const DownloadReport: React.FC<Props> = ({ isLoading, onDownloadClick }) => {
  const yesterday = moment().subtract(1, 'd').toDate()

  const [from, setFrom] = useState<Date>(moment().subtract(12, 'd').toDate())
  const [to, setTo] = useState<Date>(yesterday)

  const handleDownloadClick = () => {
    if (from && to) {
      onDownloadClick({
        from: moment(from).format('YYYY-MM-DD'),
        to: moment(to).format('YYYY-MM-DD'),
      })
    }
  }

  const handleChangeDate = (newFrom: Date, newTo: Date) => {
    setFrom(newFrom)
    setTo(newTo)
  }

  return (
    <EnhancedPanel maxWidth={550} display="inline-block">
      <EuiTitle size="xs">
        <Flexbox>
          <h4>Отчёт за период</h4>
          <HorizontalSpacer size="xs" />
          <EuiBadge color="secondary">.CSV</EuiBadge>
          <HorizontalSpacer size="xs" />
        </Flexbox>
      </EuiTitle>
      <EuiSpacer size="s" />
      <EuiFlexGroup gutterSize="none" alignItems="center">
        <EuiFlexItem grow={5}>
          <DateRangePicker
            hasCloseButton={false}
            dateTo={to}
            dateFrom={from}
            maxDate={yesterday}
            onChangeDate={handleChangeDate}
          />
        </EuiFlexItem>
        <HorizontalSpacer size="m" />
        <EuiFlexItem>
          <EuiButton
            color="secondary"
            iconType="email"
            isLoading={isLoading}
            onClick={handleDownloadClick}
          >
            Отправить&nbsp;на&nbsp;почту
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EnhancedPanel>
  )
}

export default DownloadReport
