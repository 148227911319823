import { Action, BaseAction } from 'redux-actions'

import { PaymentTransactionsResponse } from 'api/types'

export type TUpdate = Action<{
  data: PaymentTransactionsResponse
  timestamp: number
}>
export type TStart = Action<number>
export type TSuccess = BaseAction
export type TFailure = Action<{ error: any }>
export type TReset = BaseAction
export type TResetStatus = BaseAction

export type TPaymentTransactionsAction =
  | TUpdate
  | TStart
  | TSuccess
  | TFailure
  | TReset
  | TResetStatus

export const UPDATE: TUpdate['type'] = 'api/park/transactions->UPDATE'
export const START: TStart['type'] = 'api/park/transactions->START'
export const SUCCESS: TSuccess['type'] = 'api/park/transactions->SUCCESS'
export const FAILURE: TFailure['type'] = 'api/park/transactions->FAILURE'
export const RESET: TReset['type'] = 'api/park/transactions->RESET'
export const RESET_STATUS: TResetStatus['type'] =
  'api/park/transactions->RESET_STATUS'

export const update = (
  data: PaymentTransactionsResponse,
  timestamp: number
): TUpdate => ({
  type: UPDATE,
  payload: {
    data,
    timestamp,
  },
})
export const start = (timestamp: number): TStart => ({
  type: START,
  payload: timestamp,
})
export const success = () => ({
  type: SUCCESS,
})
export const failure = (error: any): TFailure => ({
  type: FAILURE,
  payload: error,
})
export const reset = () => ({
  type: RESET,
})
export const resetStatus = () => ({
  type: RESET_STATUS,
})

export type TPayloads = Pick<TUpdate & TFailure & TStart, 'payload'>['payload']
