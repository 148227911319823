import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import { State } from 'reducers'
import { MyThunkDispatch } from 'api/reducer'
import { BankType, FormNames, ModalsNames } from 'common/enums'
import { modalsActions } from 'shared/modals'
import { apiActions, apiSelectors } from 'api'
import {
  postPayoutCredentialsRequest,
  BankOptionsFromBankType,
} from 'api/park/payout/credentials/post/thunks'
import { PostPayoutCredentialsRequestSchema } from 'api/park/payout/credentials/post/types'
import { BankIntegrationModal } from '../pages/BankIntegrationModal'

export type FormProps = PostPayoutCredentialsRequestSchema[keyof PostPayoutCredentialsRequestSchema]

const mapStateToProps = (state: State) => ({
  bankType: apiSelectors.park.details.getBankType(state),
  data: apiSelectors.park.getParkPayoutCredentials.selectData(state),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  onSave: <T extends BankType>(
    bankType: T,
    bankOptions: BankOptionsFromBankType<T>
  ) => dispatch(postPayoutCredentialsRequest(bankType, bankOptions)),
  closeModal: () => dispatch(modalsActions.closeModal(ModalsNames.EditE2C)),
  updateParkPayoutCredentials: () =>
    dispatch(apiActions.park.getParkPayoutCredentials.handleRequest()),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const Form = reduxForm<FormProps, ContainerProps>({
  form: FormNames.EditE2C,
})(BankIntegrationModal)

export const BankIntegrationModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Form)
