import React from 'react'

import { Icon } from 'ui-kit/components'

import { MenuPayload } from 'ui-kit/components/SideMenu/interfaces'

import {
  Wrapper,
  ContentWrapper,
  Trigger,
  Header,
  List,
  ListItem,
  Link,
  LinkContent,
} from 'ui-kit/components/SideMenu/styles'

interface Props {
  menuData: MenuPayload[]
  id?: string
  className?: string
  minWidth?: number
  maxWidth?: number
  minified?: boolean
  resizable?: boolean
  resizeCallback?: (menuSize: number, emptySpace: number) => void
}

interface LocalState {
  logoPositionLeft?: boolean
  textHidden?: boolean
}

type Direction = 'right' | 'left'

export class SideMenu extends React.Component<Props, LocalState> {
  private direction?: Direction

  private oldXMousePosition = -1

  constructor(props: Props) {
    super(props)

    this.state = {
      logoPositionLeft: props.minified || false,
      textHidden: props.minified || false,
    }
  }

  componentDidMount() {
    const { resizable } = this.props
    if (resizable) {
      document.addEventListener('mouseleave', this.stopResize, false)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mouseleave', this.stopResize, false)
  }

  private renderMenuListItems = (): JSX.Element[] => {
    const { menuData } = this.props
    return menuData.map((item) => (
      <ListItem
        className="muk-side-menu-item"
        key={`${item.text}-${item.iconName}`}
      >
        {this.renderMenuItem(item)}
      </ListItem>
    ))
  }

  private renderMenuItem = (item: MenuPayload): JSX.Element => {
    return (
      <Link
        to={item.href}
        className="muk-side-menu-link"
        activeClassName="muk-side-menu-link-active"
      >
        <LinkContent>
          <Icon className="muk-side-menu-link-icon" name={item.iconName}>
            {item.text}
          </Icon>
        </LinkContent>
      </Link>
    )
  }

  private getDirection = (e: MouseEvent): { direction: Direction } => {
    this.direction = this.oldXMousePosition < e.pageX ? 'right' : 'left'

    this.oldXMousePosition = e.pageX

    return { direction: this.direction }
  }

  private getEmptySpace = (menuSize: number): number =>
    window.innerWidth - menuSize

  private resizeFunc = (e: MouseEvent): void => {
    const { minWidth = 56, maxWidth = 240, resizeCallback } = this.props
    const sideMenu = document.querySelector<HTMLDivElement>('[data-menu]')
    if (!sideMenu) return

    const pos: number = e.pageX - sideMenu.getBoundingClientRect().left
    const dirX: Direction = this.getDirection(e).direction

    if (dirX === 'left' && pos >= minWidth / 2 && pos <= maxWidth) {
      const size: number = Math.max(pos, minWidth)

      sideMenu.style.width = `${size}px`

      if (resizeCallback) {
        resizeCallback(size, this.getEmptySpace(size))
      }
    } else if (dirX === 'right' && pos <= maxWidth * 2 && pos >= minWidth) {
      const size: number = Math.min(pos, maxWidth)

      sideMenu.style.width = `${size}px`

      if (resizeCallback) {
        resizeCallback(size, this.getEmptySpace(size))
      }
    }

    this.setState({
      textHidden: pos <= minWidth,
      logoPositionLeft: pos <= minWidth,
    })
  }

  private stopResize = (): void =>
    window.removeEventListener('mousemove', this.resizeFunc)

  private resizeTrigger = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.preventDefault()

    window.addEventListener('mousemove', this.resizeFunc, false)

    window.addEventListener('mouseup', this.stopResize, false)
  }

  render() {
    const {
      id,
      className,
      resizable = false,
      minified = false,
      minWidth = 56,
      maxWidth = 240,
    } = this.props
    const { logoPositionLeft, textHidden } = this.state

    return (
      <Wrapper
        id={id}
        className={className}
        textOpacity={textHidden}
        data-menu
        minified={minified}
        minWidth={minWidth}
        maxWidth={maxWidth}
      >
        <ContentWrapper
          className="muk-side-menu-content-wrapper"
          maxWidth={maxWidth}
        >
          <Header className="muk-side-menu-header" left={logoPositionLeft}>
            <Icon name="mozenWhite" className="muk-side-menu-small-logo" />
            <Icon name="mozenText" className="muk-side-menu-mozen-text" />
          </Header>
          <List className="muk-side-menu-list">
            {this.renderMenuListItems()}
          </List>
        </ContentWrapper>
        <Trigger
          className="muk-side-menu-trigger"
          resizable={resizable}
          onMouseDown={(e) => (resizable ? this.resizeTrigger(e) : false)}
        />
      </Wrapper>
    )
  }
}
