export enum IntegrationType {
  ATOL = 'atol',
}

export type Integration = {
  integration_type: IntegrationType
  login: string
  password: string
  payment_address?: string
  group_code?: string
}

export type CloudCashBox = {
  cb_integration: Integration
  inn: string
  kpp: string
  name: string
  sno_id: string
}
