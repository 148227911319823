import React, { useState } from 'react'
import styled from 'styled-components'

import { ColorTypes, FontWeightTypes } from 'ui-kit'

import { SmallLoader, BodyText } from 'ui-kit/components'

import { Base, DefaultProps } from 'ui-kit/components/Buttons/Base/Base'

const Container = styled(Base)<{
  color: ColorTypes
  fullWidth?: boolean
  disabled?: boolean
  isLoading?: boolean
}>`
  width: ${({ fullWidth }) => fullWidth && '100%'};
  min-width: ${({ fullWidth }) => fullWidth && 'initial'};
  height: 56px;
  background-color: ${({ disabled, isLoading, color, theme }) =>
    disabled && !isLoading ? theme.pallete.gray3 : theme.pallete[color]};

  border-radius: 100px;

  &::before {
    border-radius: 100px;
  }
`

interface Props extends DefaultProps {
  color?: ColorTypes
  textColor?: ColorTypes
  fullWidth?: boolean
  fontWeight?: FontWeightTypes
  isLoading?: boolean
  /** deprecated */
  id?: never
  /** deprecated */
  className?: never
  /** deperecated */
  hovered?: never
  /** deperecated */
  active?: never
  /** deperecated */
  onMouseOver?: never
  /** deperecated */
  onMouseOut?: never
}

export const FloatButton: React.FC<Props> = ({
  children,
  type = 'button',
  disabled,
  isLoading,
  color = 'blue' as ColorTypes,
  textColor = 'white' as ColorTypes,
  fullWidth,
  fontWeight = 'semi-bold' as FontWeightTypes,
  onClick,
}) => {
  const bodyTextColor = disabled ? 'gray6' : textColor
  const [isHovered, setIsHovered] = useState(false)
  return (
    <Container
      type={type}
      color={color}
      fullWidth={fullWidth}
      isLoading={isLoading}
      disabled={disabled || isLoading}
      onClick={onClick}
      hovered={isHovered}
      onMouseEnter={disabled ? undefined : () => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isLoading ? (
        <SmallLoader white />
      ) : (
        <BodyText fontWeight={fontWeight} color={bodyTextColor}>
          {children}
        </BodyText>
      )}
    </Container>
  )
}
