import { createApiReducer } from 'common/utils/reduxApiFactory'
import { PostCloudCashBoxState } from 'api/receipt/cloud-cashbox/post/types'
import { postCloudCashBoxActions } from 'api/receipt/cloud-cashbox/post/actions'

export const initialState: PostCloudCashBoxState = {
  status: null,
  data: null,
}

export const postCloudCashBoxReducer = createApiReducer(
  postCloudCashBoxActions,
  initialState
)
