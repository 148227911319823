import { createApiReducer } from 'common/utils/reduxApiFactory'
import { GetModulState } from 'api/park/modul/get/types'
import { getModulActions } from 'api/park/modul/get/actions'

export const initialState: GetModulState = {
  status: null,
  data: null,
}

export const getModulReducer = createApiReducer(getModulActions, initialState)
