import { Action } from 'redux-actions'

import { WithdrawRestrictionResponse } from 'api/types'

export type TUpdate = Action<WithdrawRestrictionResponse>
export type TStart = Action<unknown>
export type TSuccess = Action<unknown>
export type TFailure = Action<{ error: any }>
export type TReset = Action<unknown>
export type TResetStatus = Action<unknown>

export type TWithdrawRestrictionAction =
  | TUpdate
  | TStart
  | TSuccess
  | TFailure
  | TReset
  | TResetStatus

export const UPDATE: TUpdate['type'] =
  'api/driversById/withdraw-restriction->UPDATE'
export const START: TStart['type'] =
  'api/driversById/withdraw-restriction->START'
export const SUCCESS: TSuccess['type'] =
  'api/driversById/withdraw-restriction->SUCCESS'
export const FAILURE: TFailure['type'] =
  'api/driversById/withdraw-restriction->FAILURE'
export const RESET: TReset['type'] =
  'api/driversById/withdraw-restriction->RESET'
export const RESET_STATUS: TResetStatus['type'] =
  'api/driversById/withdraw-restriction->RESET_STATUS'

export const update = (data: WithdrawRestrictionResponse): TUpdate => ({
  type: UPDATE,
  payload: data,
})
export const start = () => ({
  type: START,
})
export const success = () => ({
  type: SUCCESS,
})
export const failure = (error: any): TFailure => ({
  type: FAILURE,
  payload: error,
})
export const reset = () => ({
  type: RESET,
})
export const resetStatus = () => ({
  type: RESET_STATUS,
})

export type TPayloads = Pick<TUpdate & TFailure, 'payload'>['payload']
