import React from 'react'
import { connect } from 'react-redux'

import { State } from 'reducers'

import { apiActions, apiSelectors } from 'api'
import { MyThunkDispatch } from 'api/reducer'
import { toISOFormat } from 'common/utils/dates'
import { Aggregator } from 'api/types'
import { BankType } from 'common/enums'

type OwnProps = {
  children(childProps: ChildProps): React.ReactElement
}
type ChildProps = {
  email?: string | null
  aggregators: Aggregator[]
  isFetching: boolean
  isE2C: boolean
  sendReport1C: (period: {
    dateFrom: Date
    dateTo: Date
    aggregators: Omit<Aggregator, 'account'>[]
  }) => Promise<void>
  sendReportPayments: (period: {
    dateFrom: Date
    dateTo: Date
  }) => Promise<void>
}

const mapStateToProps = (state: State) => ({
  email: apiSelectors.employee.profile.selectEmail(state),
  aggregators: apiSelectors.park.aggregators.getAggregators(state),
  isFetching: apiSelectors.park.aggregators.getIsFetching(state),
  isE2C: apiSelectors.park.details.getBankType(state) === BankType.AlfaE2C,
})
const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  fetchSendReport1C: (
    dateFrom: string,
    dateTo: string,
    aggregators: Omit<Aggregator, 'account'>[]
  ) =>
    dispatch(
      apiActions.report.postReport.handleRequest(
        dateFrom,
        dateTo,
        '1c',
        aggregators
      )
    ),
  fetchSendReportPayments: (dateFrom: string, dateTo: string) =>
    dispatch(
      apiActions.park.postParkRevise.handleRequest({
        date_start: dateFrom,
        date_end: dateTo,
      })
    ),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps

export const PurePaymentsReportConnector: React.FC<ContainerProps> = ({
  children,
  email,
  aggregators,
  isFetching,
  isE2C,
  fetchSendReport1C,
  fetchSendReportPayments,
}) => {
  const sendReport1C = async (period: {
    dateFrom: Date
    dateTo: Date
    aggregators: Omit<Aggregator, 'account'>[]
  }) => {
    await fetchSendReport1C(
      toISOFormat(period.dateFrom),
      toISOFormat(period.dateTo),
      period.aggregators
    )
  }

  const sendReportPayments = async (period: {
    dateFrom: Date
    dateTo: Date
  }) => {
    await fetchSendReportPayments(
      toISOFormat(period.dateFrom),
      toISOFormat(period.dateTo)
    )
  }

  return children({
    email,
    aggregators,
    isFetching,
    sendReport1C,
    sendReportPayments,
    isE2C,
  })
}

export const PaymentsReportConnector = connect(
  mapStateToProps,
  mapDispatchToProps
)(PurePaymentsReportConnector)
