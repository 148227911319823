import React from 'react'
import styled from 'styled-components'

type ObjectFItTypes =
  | 'fill'
  | 'contain'
  | 'cover'
  | 'none'
  | 'scale-down'
  | 'initial'

export const Img = styled.img<{
  width: string
  height: string
  objectFit: ObjectFItTypes
}>`
  display: block;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  object-fit: ${({ objectFit }) => objectFit};
`

interface Props {
  src?: string
  alt?: string
  id?: string
  className?: string
  width?: string
  height?: string
  objectFit?: ObjectFItTypes
}

export const Image = React.forwardRef<HTMLImageElement, Props>(
  (
    {
      src,
      alt,
      width = '100%',
      height = 'auto',
      objectFit = 'initial' as ObjectFItTypes,
      className,
      id,
    },
    ref?
  ) => (
    <Img
      ref={ref}
      className={className}
      id={id}
      src={src}
      alt={alt}
      width={width}
      height={height}
      objectFit={objectFit}
    />
  )
)

Image.displayName = 'UiImage'
