import { handleActions } from 'redux-actions'

import { DriverStats } from 'common/types/api/models/driver'

import {
  Payloads,
  SET_STATS,
  SET_STATS_DATE_FROM,
  SET_STATS_DATE_TO,
} from 'drivers/features/driverStats/actions'

export interface DriverStatsState {
  stats: DriverStats | null
  dateFrom: Date | null
  dateTo: Date | null
}

const initialState: DriverStatsState = {
  stats: null,
  dateFrom: null,
  dateTo: null,
}

const handleSetStats = (
  state: DriverStatsState,
  action: SET_STATS
): DriverStatsState => ({
  ...state,
  stats: action.payload,
})

const handleSetStatsDateFrom = (
  state: DriverStatsState,
  action: SET_STATS_DATE_FROM
): DriverStatsState => ({
  ...state,
  dateFrom: action.payload,
})

const handleSetStatsDateTo = (
  state: DriverStatsState,
  action: SET_STATS_DATE_TO
): DriverStatsState => ({
  ...state,
  dateTo: action.payload,
})

export default handleActions<DriverStatsState, Payloads>(
  {
    [SET_STATS]: handleSetStats,
    [SET_STATS_DATE_FROM]: handleSetStatsDateFrom,
    [SET_STATS_DATE_TO]: handleSetStatsDateTo,
  },
  initialState
)
