import React, { useCallback, useMemo, useState } from 'react'

import { ContentCard } from 'shared/ui/contentCard'
import { HBox } from 'shared/ui/spacers'
import { NameSettingsModal } from 'shared/ui/paymentSettingsNameModal'

import {
  PaymentSettingsSelectionHeader,
  PaymentSettingsSelectionItem,
} from 'settings/features/paymentSettingsTab/molecules'
import { PaymentSettings } from 'api/types'
import {
  defaultSettingsIndex,
  individualSettingsIndex,
} from 'settings/features/paymentSettingsTab/constants'
import { PaymentSettingsDefaultNames } from 'common/enums/payments'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
`

type Props = {
  selectedSettingsIndex: number
  settingsList: PaymentSettings[]
  onCreate: (settingsName: string) => Promise<void>
  onSelect: (selectedSettingsIndex: number) => void
}

export const PaymentSettingsList = React.memo<Props>(
  ({ selectedSettingsIndex, settingsList, onCreate, onSelect }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)

    const handleSelect = useCallback(
      (newSelectedSettingsIndex: number) => () => {
        onSelect(newSelectedSettingsIndex)
      },
      [onSelect]
    )

    const handleResolve = useCallback(
      async (groupName: string) => {
        await onCreate(groupName)
        setIsModalOpen(false)
      },
      [onCreate]
    )

    const handleReject = useCallback(() => {
      setIsModalOpen(false)
    }, [])

    const handleCreate = useCallback(() => {
      setIsModalOpen(true)
    }, [])

    const {
      name: defaultOptionName,
      user_counter: defaultOptionCount,
    } = settingsList[defaultSettingsIndex]
    const defaultOption = useMemo(() => {
      return { name: defaultOptionName, count: defaultOptionCount || 0 }
    }, [defaultOptionCount, defaultOptionName])
    const { user_counter: individualOptionCount } = settingsList[
      individualSettingsIndex
    ]
    const individualOption = useMemo(() => {
      return {
        name: PaymentSettingsDefaultNames.INDIVIDUAL,
        count: individualOptionCount || 0,
      }
    }, [individualOptionCount])

    const groupOptions = useMemo(() => {
      const [, , ...rest] = settingsList
      return rest.map((item) => ({
        id: item.id,
        name: item.name,
        count: item.user_counter || 0,
      }))
    }, [settingsList])

    return (
      <>
        <ContentCard withMinHeight={false}>
          <Wrapper>
            <PaymentSettingsSelectionHeader onCreate={handleCreate} />
            <PaymentSettingsSelectionItem
              option={defaultOption}
              onClick={handleSelect(1)}
              isSelect={selectedSettingsIndex === 1}
            />
            {groupOptions.map((item, index) => (
              <PaymentSettingsSelectionItem
                key={item.id}
                option={item}
                onClick={handleSelect(index + 2)}
                isSelect={index + 2 === selectedSettingsIndex}
              />
            ))}
          </Wrapper>
        </ContentCard>
        <HBox x={2 / 3} />
        <ContentCard withMinHeight={false}>
          <Wrapper>
            <PaymentSettingsSelectionItem
              option={individualOption}
              onClick={handleSelect(0)}
              isSelect={selectedSettingsIndex === 0}
            />
          </Wrapper>
        </ContentCard>
        {isModalOpen && (
          <NameSettingsModal
            onResolve={handleResolve}
            onReject={handleReject}
          />
        )}
      </>
    )
  }
)

PaymentSettingsList.displayName = 'PaymentSettingsList'
