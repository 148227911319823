import React from 'react'
import styled from 'styled-components'

import { Icon } from 'ui-kit/components'
import { ColorTypes } from 'ui-kit'

export const Wrapper = styled.span<{
  white?: boolean
  loaderColor?: ColorTypes
}>`
  display: inline-block;
  width: 24px;
  height: 24px;
  animation: spinLoader 0.4s linear infinite;
  pointer-events: none;

  svg {
    width: 100%;
    height: 100%;

    path {
      fill: ${({ white, theme, loaderColor = 'white' as ColorTypes }) =>
        white && theme.pallete[loaderColor]};
    }
  }

  @keyframes spinLoader {
    100% {
      transform: rotate(360deg);
    }
  }
`

interface Props {
  white?: boolean
  loaderColor?: ColorTypes
}

export const SmallLoader: React.FC<Props> = ({ white, loaderColor }) => (
  <Wrapper white={white} loaderColor={loaderColor}>
    <Icon name="loading" />
  </Wrapper>
)
