import { handleActions } from 'redux-actions'

import { TApiStatus } from 'api/types'

import {
  FAILURE,
  RESET,
  START,
  SUCCESS,
  TFailure,
  TPayloads,
} from 'api/auth/logout/actions'

import { HANDLE_REQUEST } from 'api/auth/logout/thunks'

export interface LogoutState {
  status: TApiStatus
  error: any
}

const initialState: LogoutState = {
  status: null,
  error: null,
}

const handleStart = (state: LogoutState): LogoutState => ({
  ...state,
  status: 'loading',
})
const handleSuccess = (state: LogoutState): LogoutState => ({
  ...state,
  status: 'success',
})
const handleFailure = (state: LogoutState, action: TFailure): LogoutState => ({
  ...state,
  status: 'failure',
  error: action.payload,
})
const handleReset = (): LogoutState => initialState

const handleRequest = (state: LogoutState): LogoutState => ({
  ...state,
})

export const reducer = handleActions<LogoutState, TPayloads>(
  {
    [START]: handleStart,
    [SUCCESS]: handleSuccess,
    [FAILURE]: handleFailure,
    [RESET]: handleReset,
    [HANDLE_REQUEST]: handleRequest,
  },
  initialState
)
