import React from 'react'
import { PageTemplate } from 'ui-kit/templates'
import { FuelDeposit, BonusScore, FuelTransactions } from 'ui-kit/organisms'
import styled from 'styled-components'
import {
  FuelHistory,
  TableData,
  FuelDepositType,
} from 'ui-kit/organisms/FuelTransactions'
import { PaginationLimit } from 'ui-kit/components/Limitation/Limitation'

type Props = {
  onUpdateHistory: (
    limit: PaginationLimit,
    depositType?: FuelDepositType,
    offset?: number
  ) => Promise<void>
  onChangeCommission: () => void
  onDepositPay: () => void
  onCashBack: () => void
  onGoToPayments: () => void
  historyList: TableData<FuelHistory>
  depositBalance?: number
  bonusBalance?: number
  parkCommission: number
  maxCommission: number
}

const Header = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.paddings.main}px;
`
const FixedSection = styled.div`
  min-width: 257px;
  margin-right: 16px;
`
const StretchSection = styled.div`
  flex: 1;
`

export const FuelPage = ({
  onUpdateHistory,
  onChangeCommission,
  onDepositPay,
  onCashBack,
  onGoToPayments,
  historyList,
  depositBalance,
  bonusBalance,
  maxCommission,
  parkCommission,
}: Props) => {
  const pageTemplate = React.useRef<HTMLDivElement>(null)
  const fuelTransactions = React.useRef<HTMLDivElement>(null)

  const handleFetchStart = () => {
    const boundingClientRect = fuelTransactions.current?.getBoundingClientRect()
    const pageTemplatesTop = pageTemplate.current?.scrollTop || 0
    const fuelTransactionsTop = boundingClientRect?.top || 0

    if (fuelTransactionsTop < 0) {
      pageTemplate.current?.scrollTo({
        top: pageTemplatesTop + fuelTransactionsTop,
      })
    }
  }

  return (
    <PageTemplate ref={pageTemplate}>
      <Header>
        <FixedSection>
          <FuelDeposit currency={depositBalance} onPay={onDepositPay} />
        </FixedSection>
        <StretchSection>
          <BonusScore
            currency={bonusBalance}
            parkCommission={parkCommission}
            driverCommission={maxCommission - parkCommission}
            onGiveBack={onCashBack}
            onChangeCommission={onChangeCommission}
          />
        </StretchSection>
      </Header>
      <FuelTransactions
        ref={fuelTransactions}
        data={historyList}
        fetcher={onUpdateHistory}
        onFetchStart={handleFetchStart}
        onGoToPayments={onGoToPayments}
      />
    </PageTemplate>
  )
}
export default React.memo(FuelPage)
