import { connect } from 'react-redux'

import { State } from 'reducers'
import { apiActions, apiSelectors } from 'api'
import { MyThunkDispatch } from 'api/reducer'
import { StepEmployees } from 'steps/features/stepEmployees/organisms'

const mapStateToProps = (state: State) => ({
  isFetchingProfilePost: apiSelectors.employee.profilePost.getIsFetching(state),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  fetchProfilePost: ({ data }: any) =>
    dispatch(apiActions.employee.profilePost.handleRequest({ data })),
  fetchEmployees: () => dispatch(apiActions.employees.get.handleRequest()),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const StepEmployeesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StepEmployees)
