import React from 'react'
import { TransparentButton } from 'ui-kit/components'
import { VBox } from 'shared/ui/spacers'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
`

type Props = {
  onSubmit: () => void
  onCancel: () => void
  isLoading?: boolean
}

export const CancelOrSave: React.FC<Props> = ({
  isLoading,
  onCancel,
  onSubmit,
}) => (
  <Container>
    <TransparentButton
      iconName="close"
      textColor="gray6"
      fontWeight="medium"
      disabled={isLoading}
      onClick={onCancel}
    >
      Отменить
    </TransparentButton>
    <VBox />
    <TransparentButton
      iconName="check"
      fontWeight="semi-bold"
      type="submit"
      isLoading={isLoading}
      onClick={onSubmit}
    >
      Сохранить
    </TransparentButton>
  </Container>
)
