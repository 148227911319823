import { ValueAndSymbol } from 'common/types/local/values'
import { PaymentSettings } from 'api/types'

export const declension = (number: number, titles: string[]) => {
  const decCache: number[] = []
  const decCases = [2, 0, 1, 1, 1, 2]

  if (!decCache[number]) {
    decCache[number] =
      number % 100 > 4 && number % 100 < 20
        ? 2
        : decCases[Math.min(number % 10, 5)]
  }

  return titles[decCache[number]]
}

export const digits = (str: string): string =>
  str && str.replace(/[^0-9+]/g, '')

export const getWidthInPercent = (total: number, current: number) =>
  (100 - ((total - current) * 100) / total).toFixed(2)

export const currencyApart = (
  value: number,
  showSign = false,
  showSymbol = true,
  explicitlyShowNegativeSign = false
): [string, string, string] => {
  const output = []
  if (showSign) {
    output.push(value > 0 ? '+' : '−')
  } else if (explicitlyShowNegativeSign && value < 0) {
    output.push('−')
  } else {
    output.push(' ')
  }
  output.push(
    new Intl.NumberFormat('ru-RU').format(
      value >= 0 ? Math.floor(value / 100) : Math.abs(Math.ceil(value / 100))
    )
  )
  output.push(`0${Math.abs(value % 100)}`.slice(-2))
  output.push(showSymbol ? '₽' : '')
  const whole = output.slice(0, 2).join('')
  const remainder = output.slice(2).join(' ')
  return [`${whole},${remainder}`, whole, `,${remainder}`]
}

export const currencyToBase = (
  value: number,
  showSign = false,
  fractionDigits = 2
): string =>
  new Intl.NumberFormat('ru-RU', {
    minimumFractionDigits: fractionDigits,
    style: showSign ? 'currency' : undefined,
    currency: showSign ? 'RUB' : undefined,
  }).format(value / 100)

export const currencyToSub = (value: number): number => Math.trunc(value * 100)

export const declensionDays = (days: number): string =>
  declension(days, ['день', 'дня', 'дней'])

export const declensionHours = (hours: number): string =>
  declension(hours, ['час', 'часы', 'часа'])

export const declensionMinutes = (minutes: number): string =>
  declension(minutes, ['минута', 'минуты', 'минут'])

export const declensionSeconds = (seconds: number): string =>
  declension(seconds, ['секунда', 'секунды', 'секунд'])

const valuesPrefixes: ValueAndSymbol[] = [
  { value: 1, symbol: '' },
  { value: 1e3, symbol: 'тыс.' },
  { value: 1e6, symbol: 'млн.' },
  { value: 1e9, symbol: 'млрд.' },
]

export const formatValueToShort = (value: number) => {
  const dividedValue: number = value / 100

  if (dividedValue === 0) {
    return dividedValue
  }

  const filteredValue = valuesPrefixes
    .filter((val) => dividedValue >= val.value)
    .pop()

  if (filteredValue !== undefined) {
    const calcValue = dividedValue / filteredValue.value

    return `${Math.floor(calcValue)} ${filteredValue.symbol}`
  }
}

export const formatPhone = (value: string): string => {
  const parts = [
    value.slice(0, -10),
    value.slice(-10, -7),
    value.slice(-7, -4),
    value.slice(-4, -2),
    value.slice(-2),
  ]

  return parts.join(' ')
}

export const formatBankAccount = (value: string): string => {
  const parts = [
    value.slice(0, 5),
    value.slice(5, 10),
    value.slice(10, 15),
    value.slice(15),
  ]

  return parts.join(' ')
}

export const convertToCurrency = (value: number) => {
  return value ? currencyToBase(value) : null
}

export const transformToPaymentSettingsFormData = (
  settings: PaymentSettings
) => {
  return {
    ...settings,
    withdraw_restricted: settings.withdraw_restricted,
    auto_withdraw: settings.auto_withdraw,
    fair_rides: settings.fair_rides.toString(),
    withdraw_transactions_for_auto_withdraw: settings.withdraw_transactions_for_auto_withdraw.toString(),
    free_withdraws_count: settings.free_withdraws_count.toString(),
    withdraw_commission: settings.withdraw_commission.toString(),
    min_commission: (settings.min_commission / 100).toString(),
    non_resident_withdraw_commission: settings.non_resident_withdraw_commission.toString(),
    non_resident_min_commission: (
      settings.non_resident_min_commission / 100
    ).toString(),
    fuel_card_max_topup_amount: (
      settings.fuel_card_max_topup_amount / 100
    ).toString(),
    fuel_card_min_topup_amount: (
      settings.fuel_card_min_topup_amount / 100
    ).toString(),
    min_withdraw_amount: (settings.min_withdraw_amount / 100).toString(),
    max_withdraw_amount:
      settings.max_withdraw_amount === null
        ? null
        : (settings.max_withdraw_amount / 100).toString(),
    min_balance: (settings.min_balance / 100).toString(),
    day_withdraw_limit:
      settings.day_withdraw_limit === null
        ? null
        : (settings.day_withdraw_limit / 100).toString(),
    week_withdraw_limit:
      settings.week_withdraw_limit === null
        ? null
        : (settings.week_withdraw_limit / 100).toString(),
  }
}

export class NormalizedSettingsBuilder {
  settings: Partial<PaymentSettings> = {}

  constructor(id: string) {
    this.settings.id = id
  }

  withFairRides(value?: string) {
    if (value !== undefined) {
      this.settings.fair_rides = parseInt(value, 10)
    }
    return this
  }

  withWithdrawTransactionsForAutoWithdraw(value?: string) {
    if (value !== undefined) {
      this.settings.withdraw_transactions_for_auto_withdraw = parseInt(
        value,
        10
      )
    }
    return this
  }

  withFreeWithdrawsCount(value?: string) {
    if (value !== undefined) {
      this.settings.free_withdraws_count = parseInt(value, 10)
    }
    return this
  }

  withWithdrawCommission(value?: string) {
    if (value !== undefined) {
      this.settings.withdraw_commission = parseFloat(value)
    }
    return this
  }

  withMinCommission(value?: string) {
    if (value !== undefined) {
      this.settings.min_commission = currencyToSub(parseFloat(value))
    }
    return this
  }

  withNonResidentWithdrawCommission(value?: string) {
    if (value !== undefined) {
      this.settings.non_resident_withdraw_commission = parseFloat(value)
    }
    return this
  }

  withNonResidentMinCommission(value?: string) {
    if (value !== undefined) {
      this.settings.non_resident_min_commission = currencyToSub(
        parseFloat(value)
      )
    }
    return this
  }

  withMinWithdrawAmount(value?: string) {
    if (value !== undefined) {
      this.settings.min_withdraw_amount = currencyToSub(parseFloat(value))
    }
    return this
  }

  withMaxWithdrawAmount(value?: string | null) {
    if (value !== undefined) {
      this.settings.max_withdraw_amount = value
        ? currencyToSub(parseFloat(value))
        : null
    }
    return this
  }

  withMinBalance(value?: string) {
    if (value !== undefined) {
      this.settings.min_balance = currencyToSub(parseFloat(value))
    }
    return this
  }

  withDayWithdrawLimit(value?: string | null) {
    if (value !== undefined) {
      this.settings.day_withdraw_limit = value
        ? currencyToSub(parseFloat(value))
        : null
    }
    return this
  }

  withWeekWithdrawLimit(value?: string | null) {
    if (value !== undefined) {
      this.settings.week_withdraw_limit = value
        ? currencyToSub(parseFloat(value))
        : null
    }
    return this
  }

  withAutoWithdraw(value?: boolean) {
    if (value !== undefined) {
      this.settings.auto_withdraw = value
    }
    return this
  }

  withWithdrawRestricted(value?: boolean) {
    if (value !== undefined) {
      this.settings.withdraw_restricted = value
    }
    return this
  }

  withFuelCardAutoTopup(value?: boolean) {
    if (value !== undefined) {
      this.settings.fuel_card_auto_topup = value
    }
    return this
  }

  withFuelCardTopupRestricted(value?: boolean) {
    if (value !== undefined) {
      this.settings.fuel_card_topup_restricted = value
    }
    return this
  }

  withFuelCardMaxTopupAmount(value?: string) {
    if (value !== undefined) {
      this.settings.fuel_card_max_topup_amount = currencyToSub(
        parseFloat(value)
      )
    }
    return this
  }

  withFuelCardMinTopupAmount(value?: string) {
    if (value !== undefined) {
      this.settings.fuel_card_min_topup_amount = currencyToSub(
        parseFloat(value)
      )
    }
    return this
  }

  build() {
    return this.settings
  }
}
