import { combineReducers } from 'redux'

import {
  TransactionStatusActions,
  TransactionStatusState,
} from 'api/transactions/status-request/types'
import { transactionStatusReducer } from 'api/transactions/status-request/reducer'
import { postTransactionStatusRequest } from 'api/transactions/status-request/thunks'
import * as transactionStatusSelectors from 'api/transactions/status-request/selectors'

export interface TransactionsState {
  transactionStatus: TransactionStatusState
}

export type TransactionsAction = TransactionStatusActions

export const transactionsReducer = combineReducers({
  transactionStatus: transactionStatusReducer,
})

export const transactionsActions = {
  status: {
    handleRequest: postTransactionStatusRequest,
  },
}

export const transactionsSelectors = {
  status: transactionStatusSelectors,
}
