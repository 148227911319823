import React from 'react'
import { ListLead, TableText } from 'ui-kit/components'
import { Employee } from 'api/types'
import ListItemComponent from 'common/components/listItem/ListItem'
import { formatPhone } from 'common/utils/formatters'
import styled from 'styled-components'

const EmployeesWrapper = styled.div`
  overflow: auto;
  height: 336px;
`

const RowItem = styled.div<{ onClick?: () => void }>`
  display: flex;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  align-items: center;
  border-bottom: 0.5px solid ${({ theme }) => theme.pallete.gray3};

  .header-item {
    &:not(:first-child) {
      border-left-color: ${({ theme }) => theme.pallete.gray3};
    }
  }

  ${(props) =>
    !!props.onClick &&
    `
    &:hover {
      background-color: #EAEEF7;
    }
  `}
`

interface Props {
  employees: Employee[]
  onEmployeeClick?: (id: string) => void
}

export const CurrentListing: React.FC<Props> = ({
  employees,
  onEmployeeClick,
}) => (
  <>
    <RowItem>
      <ListItemComponent className="header-item" flex="50%" border="table">
        <TableText bold color="gray7">
          ФИО, должность
        </TableText>
      </ListItemComponent>
      <ListItemComponent className="header-item" flex="50%" border="table">
        <TableText bold color="gray7">
          Телефон, email
        </TableText>
      </ListItemComponent>
    </RowItem>

    <EmployeesWrapper>
      {employees.map((employee) => (
        <RowItem
          key={employee.id}
          onClick={
            onEmployeeClick ? () => onEmployeeClick(employee.id) : undefined
          }
        >
          <ListItemComponent flex="50%" border="table">
            <ListLead table subtitle={employee.position} reverse>
              {employee.full_name}
            </ListLead>
          </ListItemComponent>
          {employee.phone && (
            <ListItemComponent flex="50%" border="table">
              <ListLead table subtitle={employee.email} reverse>
                {formatPhone(employee.phone)}
              </ListLead>
            </ListItemComponent>
          )}
        </RowItem>
      ))}
    </EmployeesWrapper>
  </>
)
