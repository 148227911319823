import { ColorTypes } from 'ui-kit'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 40px 56px 56px;

  p {
    display: flex;
    align-items: center;
    text-align: center;
    min-height: 64px;
    max-width: 472px;
    padding: 8px;

    a {
      display: contents;
      color: ${({ theme }) => theme.pallete.blue};
      text-decoration: none;
    }
  }
`

export const TextWrapper = styled.div<{
  textColor: ColorTypes
  textContainerWidth: string
}>`
  display: flex;
  align-items: center;
  text-align: center;
  min-height: 64px;
  max-width: ${(props) => props.textContainerWidth};
  padding: 8px;
  font-size: 14px;
  line-height: 22px;
  font-family: 'Montserrat', sans-serif;
  color: ${({ theme, textColor }) => theme.pallete[textColor]};

  a {
    display: contents;
    color: ${({ theme }) => theme.pallete.blue};
    text-decoration: none;
  }
`
