import { createApiReducer } from 'common/utils/reduxApiFactory'
import { getPaymentOrderActions } from 'api/park/payment-order/get/actions'
import { GetPaymentOrderState } from 'api/park/payment-order/get/types'

export const initialState: GetPaymentOrderState = {
  status: null,
  data: null,
}

export const getPaymentOrderReducer = createApiReducer(
  getPaymentOrderActions,
  initialState
)
