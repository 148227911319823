import React from 'react'
import { Field, InjectedFormProps, SubmissionError } from 'redux-form'

import { BodyText, FloatButton, TitleText } from 'ui-kit/components'

import { history } from 'store'
import { BankType, RoutesPath } from 'common/enums'
import { required } from 'common/utils/fieldValidation'
import InputMask, {
  maskNumberWithSuffix,
} from 'common/components/formItems/InputMask'
import ListInput from 'common/components/formItems/ListInput'
import Checkbox from 'common/components/formItems/Checkbox'
import { HBox, Tooltip } from 'shared/ui'
import Alert from 'react-s-alert'

import { SkipButton } from 'steps/features/content'

import {
  ContainerProps,
  FormProps,
} from 'steps/features/stepPayments/containers/PaymentsQiwiContainer'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 563px;
`

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`
const ButtonWidth = styled.div`
  width: 320px;
`

const contentContainerComponent = styled.div`
  top: calc(100% + 8px) !important;
  bottom: initial !important;
  right: initial !important;
  left: calc(100% - 296px) !important;
  width: 296px !important;
  box-shadow: 0 3px 12px rgba(5, 16, 34, 0.06) !important;
`

export const PaymentsQiwi: React.FC<
  InjectedFormProps<FormProps, ContainerProps> & ContainerProps
> = ({ valid, submitting, formValues, handleSubmit, onSave }) => {
  const handleSave = async (values: FormProps) => {
    try {
      await onSave(values)
    } catch (e) {
      Alert.error('Ошибка', {
        customFields: {
          text: e?.[BankType.Qiwi] || 'что-то пошло не так',
        },
      })
      throw new SubmissionError(e)
    }
  }
  const handleSkip = (): void => {
    history.replace(RoutesPath.StepsDrivers)
  }

  return (
    <Wrapper>
      <TitleText>Счет QIWI</TitleText>
      <HBox />
      <BodyText color="gray9">
        Укажите данные для подключения счета QIWI.
      </BodyText>
      <HBox x={0.5} />
      <Field
        name="terminal_id"
        validate={[required]}
        label="Terminal ID"
        component={ListInput}
      >
        <Tooltip
          content="Эти данные вы получили при регистрации в QIWI Wallet"
          contentComponent={contentContainerComponent}
        />
      </Field>
      <Field
        name="password"
        label="Пароль"
        type="password"
        validate={required}
        component={ListInput}
      />

      <HBox x={2} />
      <BodyText color="gray9">
        Если на счете недостаточно денег, быстрые выплаты для водителей
        недоступны.
      </BodyText>
      <HBox />
      <Field
        name="email_notifications"
        label="Уведомить по SMS"
        component={Checkbox}
      >
        <BodyText>Предупреждать по email, если баланс ниже лимита</BodyText>
      </Field>

      {formValues && formValues.email_notifications ? (
        <>
          <HBox />
          <Field
            name="min_balance"
            label="Лимит, ₽"
            mask={maskNumberWithSuffix(5, '')}
            iconName="close"
            validate={required}
            component={InputMask}
          />
          <HBox />
          <BodyText color="gray9">
            Изменить сумму и email можно в настройках сервиса.
          </BodyText>
        </>
      ) : null}

      <HBox x={2} />
      <Actions>
        <ButtonWidth>
          <FloatButton
            type="submit"
            disabled={!valid}
            isLoading={submitting}
            fullWidth
            onClick={handleSubmit(handleSave)}
          >
            Подключить Qiwi
          </FloatButton>
        </ButtonWidth>
        <SkipButton
          onClick={handleSkip}
          tipText="Подключить QIWI вы сможете позже в разделе «Настройки»"
        >
          Пропустить
        </SkipButton>
      </Actions>
    </Wrapper>
  )
}
