import { createApiReducer } from 'common/utils/reduxApiFactory'
import { ReferralOffersState } from './types'
import { referralOffersActions } from './actions'

export const initialState: ReferralOffersState = {
  status: null,
  data: { offer: [] },
}

export const referralOffersReducer = createApiReducer(
  referralOffersActions,
  initialState
)
