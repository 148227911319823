import React from 'react'
import styled from 'styled-components'
import { Checkbox as DefaultCheckbox } from 'ui-kit/atoms'
import { CheckType } from 'ui-kit/atoms/Checkbox/Checkbox'
import Typography from 'ui-kit/typography'

export type Option = {
  label: string
  value: string
}

export type CheckboxOptions = {
  all: string
  list: Option[]
}

type Props = {
  caption: string
  options: CheckboxOptions
  value: string[]
  onChange: (options: string[]) => void
  isDisabled?: boolean
  error?: string | null
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

function getCheckType(selected: string[], options: Option[]) {
  const isOn = selected.length === options.length
  const isSemi = selected.length > 0 && !isOn

  if (isOn) {
    return CheckType.On
  }
  if (isSemi) {
    return CheckType.Semi
  }

  return CheckType.Off
}

const Checkbox = styled(DefaultCheckbox)<{ isImportant?: boolean }>`
  min-height: 48px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background-color: ${({ isImportant, theme }) =>
    isImportant && theme.pallete.surfaceBackgroundPrimary};
  padding-left: 16px;
  padding-right: 16px;
`

const Caption = styled(Typography).attrs({
  variant: 'Caption1',
  fontColor: 'gray6',
})<{ hasError: boolean }>`
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 8px;
  color: ${({ hasError, theme }) => hasError && theme.pallete.uiRed};
`

const ErrorMessage = styled.div`
  padding-left: 16px;
  padding-right: 16px;
`

export const CheckboxSelect = ({
  caption,
  options,
  value,
  onChange,
  isDisabled,
  error,
}: Props) => {
  const checkList = new Set(value)

  const listValue = options.list.reduce(
    (previousValue, currentValue) => [...previousValue, currentValue.value],
    [] as string[]
  )

  const handleChangeHeader = (type: CheckType) => {
    let newCheckList: string[] = []

    if (type === CheckType.On) {
      newCheckList = listValue
    }

    onChange(newCheckList)
  }

  const handleChangeCheckbox = (checkboxValue: string) => () => {
    const newValue = new Set(checkList)

    if (checkList.has(checkboxValue)) {
      newValue.delete(checkboxValue)
    } else {
      newValue.add(checkboxValue)
    }

    onChange(Array.from(newValue))
  }

  return (
    <Wrapper>
      <Caption hasError={Boolean(error)}>{caption}</Caption>
      <Checkbox
        value={getCheckType(value, options.list)}
        onChange={handleChangeHeader}
        isDisabled={isDisabled}
        isImportant
      >
        <Typography>{options.all}</Typography>
      </Checkbox>
      {options.list.map((option) => (
        <Checkbox
          key={option.label}
          value={checkList.has(option.value) ? CheckType.On : CheckType.Off}
          onChange={handleChangeCheckbox(option.value)}
          isDisabled={isDisabled}
        >
          <Typography>{option.label}</Typography>
        </Checkbox>
      ))}
      {error && (
        <ErrorMessage>
          <Typography variant="Caption2" fontColor="uiRed">
            {error}
          </Typography>
        </ErrorMessage>
      )}
    </Wrapper>
  )
}
