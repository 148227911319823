import { PaymentSettings } from 'api/types'
import { PaymentSettingsDefaultNames } from 'common/enums/payments'
import { Option } from 'common/types/local/option'

import { createSelector } from 'reselect'
import { apiSelectors } from 'api'
import { DriverSettingsData } from 'drivers/features/driverSettingsTab/types'

function getIndividualId(settings: PaymentSettings) {
  const isIndividual = !settings.is_park_default && !settings.is_group
  return isIndividual ? settings.id : null
}

export const selectIsDriverSettingsIndividual = createSelector(
  apiSelectors.driversById.paymentSettings.makeSelectData(),
  (driverSettings) => {
    if (!driverSettings) return false

    return !driverSettings.is_park_default && !driverSettings.is_group
  }
)

export const selectSettingsOptions = createSelector(
  apiSelectors.park.paymentSettingsAll.makeSelectData(),
  apiSelectors.driversById.paymentSettings.makeSelectData(),
  (parkSettings, driverSettings) => {
    if (!parkSettings || !driverSettings) return null

    const individualOption: Option<string | null> = {
      label: PaymentSettingsDefaultNames.INDIVIDUAL,
      value: getIndividualId(driverSettings),
    }

    const defaultOption: Option<string> = {
      label: parkSettings.park_default_payment_settings.name,
      value: parkSettings.park_default_payment_settings.id,
    }

    const groupOptions: Option<
      string
    >[] = parkSettings.group_payment_settings.map((item) => ({
      label: item.name,
      value: item.id,
    }))

    return [defaultOption, ...groupOptions, individualOption]
  }
)

export const selectDriverSettingsData = createSelector(
  selectSettingsOptions,
  apiSelectors.driversById.paymentSettings.makeSelectData(),
  selectIsDriverSettingsIndividual,
  apiSelectors.park.details.getAllowNonResident,
  apiSelectors.employee.profile.getCanChangePaymentsConditions,
  apiSelectors.park.details.getIsBankTypeManual,
  apiSelectors.park.details.selectIsFuelEnabled,
  (
    settingsOptions,
    formData,
    isDriverSettingsIndividual,
    allowNonResident,
    canChangePaymentsConditions,
    isBankTypeManual,
    isFuelEnabled
  ) => {
    if (!settingsOptions || !formData) return null
    return {
      settingsOptions,
      formData,
      isDriverSettingsIndividual,
      allowNonResident,
      isFuelEnabled,
      canChangePaymentsConditions,
      isBankTypeManual,
    } as DriverSettingsData
  }
)

export const selectIsFetching = createSelector(
  apiSelectors.park.paymentSettingsAll.selectIsFetching,
  apiSelectors.driversById.paymentSettings.selectIsGetFetching,
  (isParkSettingsFetching, isDriverSettingsFetching) =>
    isParkSettingsFetching || isDriverSettingsFetching
)
