import React, { useState } from 'react'
import Card from 'ui-kit/atoms/Card/Card'
import styled from 'styled-components'
import { ColorButton } from 'ui-kit/components/Buttons'
import Typography from 'ui-kit/typography'
import { CheckboxSelect, TextArea } from 'ui-kit/atoms'
import { CheckboxOptions } from 'ui-kit/atoms/CheckboxSelect/CheckboxSelect'
import { HBox } from 'shared/ui/spacers'
import Alert from 'react-s-alert'

export type Notification = {
  text: string
  recipients: string[]
}

type Props = {
  aggregators: CheckboxOptions
  onCreate: (data: Notification) => Promise<void>
}

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  min-height: 56px;
  box-sizing: border-box;
  border-bottom: 1px solid ${({ theme }) => theme.pallete.gray3};
  margin-bottom: 8px;
`
const CardBody = styled.div``
const CardAction = styled.div`
  padding: 16px;
  display: flex;
  justify-content: flex-end;
`

type Field = 'text' | 'aggregators'

type Errors = {
  [key in Field]: string | null
}

export const AddDriverNotification = ({ onCreate, aggregators }: Props) => {
  const [text, setText] = useState('')
  const [recipients, setRecipients] = useState<string[]>([])
  const [isLoading, setLoading] = useState(false)
  const [errors, setErrors] = useState<Errors>({
    text: null,
    aggregators: null,
  })

  const handleChangeAggregators = (newSelectedAggregators: string[]) => {
    setErrors((prevValue) => ({ ...prevValue, aggregators: null }))
    setRecipients(newSelectedAggregators)
  }

  const handleChangeText = (value: string) => {
    setErrors((prevValue) => ({ ...prevValue, text: null }))
    setText(value)
  }

  const handleSubmit = async () => {
    if (recipients.length === 0) {
      setErrors((prevValue) => ({
        ...prevValue,
        aggregators: 'Выберите агрегаторы',
      }))
      return
    }

    if (text.length === 0 || (text.length !== 0 && text.length > 350)) {
      setErrors((prevValue) => ({
        ...prevValue,
        text:
          'Сообщение должно быть не пустым, максимальная длинна сообщения 350 символов',
      }))
      return
    }

    setLoading(true)
    try {
      await onCreate({
        text,
        recipients,
      })
      setText('')
      setRecipients([])
      Alert.info('Сообщение отправлено')
    } catch (e) {
      console.error(e)
    }

    setLoading(false)
  }

  return (
    <Card>
      <CardHeader>
        <Typography fontSize={16} fontWeight="SemiBold">
          Новое уведомление
        </Typography>
      </CardHeader>
      <CardBody>
        <CheckboxSelect
          error={errors.aggregators}
          caption="Учетная запись агрегатора"
          options={aggregators}
          value={recipients}
          onChange={handleChangeAggregators}
          isDisabled={isLoading}
        />
        <HBox />
        <TextArea
          error={errors.text}
          caption="Сообщение"
          placeholder="Введите текст"
          value={text}
          onChange={handleChangeText}
          isDisabled={isLoading}
        />
      </CardBody>
      <CardAction>
        <ColorButton onClick={handleSubmit} isLoading={isLoading}>
          Отправить
        </ColorButton>
      </CardAction>
    </Card>
  )
}
