import { createSelector } from 'reselect'
import { State } from 'reducers'

export const selectGetDriverPaymentSettings = (state: State) =>
  state.api.driversById.getPaymentSettings

export const selectPostDriverPaymentSettings = (state: State) =>
  state.api.driversById.postPaymentSettings

export const selectPatchDriverPaymentSettings = (state: State) =>
  state.api.driversById.patchPaymentSettings

export const selectDeleteDriverPaymentSettings = (state: State) =>
  state.api.driversById.deletePaymentSettings

export const selectIsGetFetching = (state: State) =>
  state.api.driversById.getPaymentSettings.status === 'loading'

export const makeSelectGetIsFetching = () =>
  createSelector(
    selectGetDriverPaymentSettings,
    (state) => state.status === 'loading'
  )

export const makeSelectPostIsFetching = () =>
  createSelector(
    selectPostDriverPaymentSettings,
    (state) => state.status === 'loading'
  )

export const makeSelectPatchIsFetching = () =>
  createSelector(
    selectPatchDriverPaymentSettings,
    (state) => state.status === 'loading'
  )

export const makeSelectDeleteIsFetching = () =>
  createSelector(
    selectDeleteDriverPaymentSettings,
    (state) => state.status === 'loading'
  )

export const makeSelectGetData = () =>
  createSelector(selectGetDriverPaymentSettings, (state) => state.data)

export const makeSelectData = () =>
  createSelector(selectGetDriverPaymentSettings, (state) => {
    if (!state.data) return null
    return state.data
  })
