import axios, { AxiosResponse } from 'axios'
import { BaseAction } from 'redux-actions'
import { ThunkAction } from 'redux-thunk'

import { State } from 'reducers'
import { CalculateTopupGiftResponse } from 'api/types'
import {
  reset,
  start,
  success,
  update,
} from 'api/park/calculate-topup-gift/actions'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  BaseAction
>
export const HANDLE_REQUEST: BaseAction['type'] =
  'api/park/calculate-topup-gift->HANDLE_REQUEST'

export const handleRequest = (amount: number): THandleRequest => async (
  dispatch
) => {
  dispatch(start())
  try {
    const response: AxiosResponse<CalculateTopupGiftResponse> = await axios.get(
      'erp/1/park/calculate-topup-gift',
      { params: { amount } }
    )
    dispatch(update(response.data))
    dispatch(success())
  } catch (e) {
    dispatch(reset())
  }
}
