import { createSelector } from 'reselect'
import { State } from 'reducers'

export const getIsFetching = (state: State) =>
  state.api.driversById.paymentConfirmationStatus.status === 'loading'

const getData = (state: State) =>
  state.api.driversById.paymentConfirmationStatus.data

export const getAutoWithdrawStatus = createSelector(getData, (data) =>
  data ? data.auto_withdraw_status : null
)
