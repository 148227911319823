import { connect } from 'react-redux'
import { InjectedFormProps, reduxForm } from 'redux-form'
import { State } from 'reducers'
import { apiActions, apiSelectors } from 'api'
import { MyThunkDispatch } from 'api/reducer'
import { FormNames } from 'common/enums'
import { onUpload } from 'drivers/features/driversUploadContracts/thunks'
import { UploadCard } from '../molecules/UploadCard/UploadCard'

interface OwnProps {
  onUploadSuccess: () => void
}

export interface FormProps {
  file: File
}

const mapStateToProps = (state: State) => ({
  isFetching: apiSelectors.drivers.contractsNoContract.getIsFetching(
    state
  ),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  onUpload: (file: string) => dispatch(onUpload(file)),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps

export const UploadCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadCard)
