import { NavigationPayload } from 'common/types/local/navigation'
import { Permission, RoutesPath } from 'common/enums'

export const getNavigation: (
  permissions: Permission[]
) => NavigationPayload[] = (permissions) => {
  const nav: NavigationPayload[] = [
    {
      text: 'Водители',
      iconName: 'car2',
      href: RoutesPath.Drivers,
      state: { reset: true },
    },
  ]

  if (permissions.includes(Permission.ViewAllTransactions)) {
    nav.unshift({
      text: 'Платежи',
      iconName: 'chart',
      href: RoutesPath.Payments,
    })
  }

  if (permissions.includes(Permission.ViewEmployees)) {
    nav.push({
      text: 'Сотрудники',
      iconName: 'license',
      href: RoutesPath.Employees,
    })
  }

  if (
    permissions.includes(Permission.ViewTransactionInfo) &&
    permissions.includes(Permission.ViewAllTransactions)
  ) {
    nav.push({
      text: 'Аналитика',
      iconName: 'analytics',
      href: RoutesPath.Analytics,
    })
  }

  nav.push({
    text: 'Топливо',
    iconName: 'fuel',
    href: RoutesPath.Fuel,
  })

  return nav
}

export const getBottomNavigation: () => NavigationPayload[] = () => {
  return [
    {
      text: 'Настройки',
      iconName: 'settings',
      href: RoutesPath.Settings,
    },
    {
      text: 'Выйти',
      iconName: 'exit',
      href: RoutesPath.SignOut,
    },
  ]
}
