import React, { useEffect } from 'react'
import { Field, InjectedFormProps, SubmissionError } from 'redux-form'
import { Link } from 'react-router-dom'

import {
  BodyText,
  CaptionText,
  FloatButton,
  TitleText,
} from 'ui-kit/components'
import { RoutesPath } from 'common/enums'
import ListInput from 'common/components/formItems/ListInput'
import InputMask from 'common/components/formItems/InputMask'
import { phoneMask, phonePattern } from 'common/utils/patterns'
import {
  email,
  maxLength15,
  maxLength30,
  minLength13,
  minLength6,
  phone,
  required,
} from 'common/utils/fieldValidation'
import { addKTrackingData, useScript } from 'common/utils/helpers'
import carSrc from 'static/backgrounds/car.svg'

import { HBox, Image } from 'shared/ui'
import { AuthLayout } from 'shared/router'

import {
  ContainerProps,
  FormProps,
} from 'auth/features/signUp/containers/SignUpContainer'
import styled from 'styled-components'

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const RightContent = styled.div`
  position: relative;
  height: calc(100vh - 170px);

  .sign-up-right-title {
    max-width: 520px;
    right: 0;
    position: absolute;
  }
`

const Form = styled.form`
  max-width: 464px;
  width: 100%;
`

const AgreementLink = styled.a`
  color: ${({ theme }) => theme.pallete.gray6};
`

const Actions = styled.div`
  max-width: 320px;
`

const ActionLink = styled.div`
  a {
    text-decoration: none;
  }
`

const ImageHolder = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  img {
    max-width: 684px;
    min-width: 591px;
  }
`

export const SignUp: React.FC<
  ContainerProps & InjectedFormProps<FormProps, ContainerProps>
> = ({ submitting, handleSubmit, onSave }) => {
  useEffect(addKTrackingData, [])
  useScript('//tracker.mozen.io/js/k.min.js')

  const handleSave = async (values: FormProps) => {
    try {
      await onSave({ values })
    } catch (e) {
      throw new SubmissionError(e)
    }
  }
  const renderLeftSideContent = (): JSX.Element => {
    return (
      <LeftContent>
        <Form>
          <TitleText fontWeight="semi-bold">Подключите свой автопарк</TitleText>
          <HBox />
          <Field
            name="phone"
            label="Телефон"
            iconName="close"
            validate={[phone, required]}
            mask={phoneMask}
            pattern={phonePattern}
            component={InputMask}
          />
          <HBox x={0.5} />
          <Field
            name="email"
            label="Email"
            iconName="close"
            validate={[email, required]}
            component={ListInput}
          />
          <HBox x={0.5} />
          <Field
            name="password"
            label="Пароль"
            type="password"
            validate={[minLength6, maxLength30, required]}
            component={ListInput}
          />
          <HBox x={0.5} />
          <Field
            name="ogrn"
            label="ОГРН компании"
            iconName="close"
            type="number"
            validate={[minLength13, maxLength15, required]}
            component={ListInput}
          />

          <HBox x={1.5} />
          <CaptionText color="gray6">
            Оставляя заявку, я принимаю{' '}
            <AgreementLink
              href="https://432922.selcdn.ru/main/park/license-agreement.pdf"
              target="_blank"
            >
              условия пользовательского соглашения
            </AgreementLink>{' '}
            <HBox x={0} />и{' '}
            <AgreementLink
              href="https://432922.selcdn.ru/main/park/private-policy.pdf"
              target="_blank"
            >
              соглашения на обработку данных
            </AgreementLink>
          </CaptionText>
          <HBox />

          <Actions>
            <FloatButton
              onClick={handleSubmit(handleSave)}
              isLoading={submitting}
              fullWidth
            >
              Оставить заявку
            </FloatButton>

            <HBox />
            <ActionLink>
              <Link to={RoutesPath.SignIn}>
                <BodyText color="blue" fontWeight="semi-bold">
                  Уже есть аккаунт
                </BodyText>
              </Link>
            </ActionLink>
          </Actions>
        </Form>
      </LeftContent>
    )
  }

  const renderRightSideContent = (): JSX.Element => {
    return (
      <RightContent>
        <ImageHolder>
          <TitleText
            className="sign-up-right-title"
            fontWeight="semi-bold"
            color="white"
          >
            Автоматизированная система для&nbsp;управления автопарком
          </TitleText>
          <Image src={carSrc} width="50vw" />
        </ImageHolder>
      </RightContent>
    )
  }

  return (
    <AuthLayout
      leftSideContent={renderLeftSideContent()}
      rightSideContent={renderRightSideContent()}
    />
  )
}
