import { ThunkAction } from 'redux-thunk'
import { State } from 'reducers'
import axios, { AxiosResponse } from 'axios'
import { FailureError } from 'common/utils/reduxApiFactory'
import { TApiError } from 'api/types'
import { getNotificationsBotActions } from 'api/park/notifications/telegram/bot/get/actions'
import {
  GetNotificationsBotActions,
  TelegramBot,
} from 'api/park/notifications/telegram/bot/get/types'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  GetNotificationsBotActions
>

export const getNotificationsBotRequest = (): THandleRequest => async (
  dispatch
) => {
  const { start, success, failure, update } = getNotificationsBotActions
  dispatch(start())
  try {
    const response: AxiosResponse<TelegramBot> = await axios.get(
      `erp/1/park/notifications/telegram/bot`
    )
    dispatch(success())
    dispatch(update(response.data))
  } catch (e) {
    if (e.response) {
      const data = e.response.data as TApiError
      const error: FailureError = new Error(data.message)
      error.data = data
      dispatch(failure(error))
      throw error
    }
  }
}
