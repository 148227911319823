import React, { useState } from 'react'

import {
  BodyText,
  CaptionText,
  FloatButton,
  Icon,
  ListItem,
  TitleText,
} from 'ui-kit/components'

import { history } from 'store'
import { RoutesPath } from 'common/enums'
import { formatToRegularDate } from 'common/utils/dates'
import { ContentCard, HBox, VBox } from 'shared/ui'
import { AddCertificateModalContainer } from 'steps/features/stepPayments/shared'

import { SkipButton } from 'steps/features/content'
import {
  CardContent,
  CardLeftSide,
  CardLeftSideIcon,
  CardLeftSideText,
  CardLeftSideTextBottom,
  CardLeftSideTextTop,
  CardRightSide,
  DownloadLink,
  Link,
  Wrapper,
} from 'steps/features/stepPayments/organisms/PaymentsCertificate/styles'

import { ContainerProps } from 'steps/features/stepPayments/containers/PaymentsCertificateContainer'
import styled, { useTheme } from 'styled-components'

const Display = styled.div<{ display: boolean }>`
  display: ${({ display }) => !display && 'none'};
`

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`
const ButtonWidth = styled.div`
  width: 320px;
`

export const PaymentsCertificate: React.FC<ContainerProps> = ({
  certificate,
  isAddCertificateModalOpened,
  openAddcertificateModal,
}) => {
  const handleSkip = (): void => {
    history.replace(RoutesPath.StepsDrivers)
  }
  const [doneIntro, setDoneIntro] = useState(false)
  const theme = useTheme()
  return (
    <>
      <Wrapper>
        <Display display={!doneIntro}>
          <TitleText fontWeight="semi-bold">Cертификат безопасности</TitleText>
          <HBox />
          <BodyText color="gray9">
            Автопарки, работающие с Альфа-Банком, имеют возможность проводить
            платежные операции автоматически. К сожалению, мы не можем
            предоставить такие возможности всем автопаркам.
            <br />
            <br />О том, как получить сертификат H2H от Альфа-Банка и улучшить
            свой бизнес, можно узнать{' '}
            <Link href="https://alfabank.ru/sme/quick/invite/" target="_blank">
              на сайте.
            </Link>
          </BodyText>
          <HBox x={2} />
          <FloatButton type="submit" onClick={() => setDoneIntro(true)}>
            Далее
          </FloatButton>
        </Display>

        <Display display={doneIntro}>
          {certificate ? (
            <>
              <TitleText fontWeight="semi-bold">
                Cертификат безопасности
              </TitleText>
              <HBox />
              <BodyText color="gray9">
                Что-то пошло не так?
                <br />
                Не уверены в актуальности загруженных данных?
                <br />
                Не проблема — отредактируйте или удалите сертификат, чтобы
                добавить заново.
              </BodyText>
              <HBox />
              <ContentCard
                backgroundColor={theme.pallete.gray1}
                boxShadow="0 5px 10px rgba(5, 16, 34, 0.1)"
              >
                <CardContent>
                  <CardLeftSide>
                    <CardLeftSideIcon />
                    <CardLeftSideText>
                      <CardLeftSideTextTop>
                        <BodyText>Сертификат загружен</BodyText>
                      </CardLeftSideTextTop>
                      <CardLeftSideTextBottom>
                        <CaptionText color="gray8">
                          {`Действителен до ${formatToRegularDate(
                            certificate.expires_at
                          )} г.`}
                        </CaptionText>
                      </CardLeftSideTextBottom>
                    </CardLeftSideText>
                  </CardLeftSide>
                  <CardRightSide>
                    <ListItem>
                      <Icon name="edit" onClick={openAddcertificateModal} />
                    </ListItem>
                  </CardRightSide>
                </CardContent>
              </ContentCard>
              <HBox x={2} />
              <Actions>
                <VBox />
                <Icon
                  name="arrowRight"
                  color="blue"
                  reverse
                  onClick={handleSkip}
                >
                  Продолжить
                </Icon>
              </Actions>
            </>
          ) : (
            <>
              <TitleText fontWeight="semi-bold">
                Загрузите сертификат безопасности
              </TitleText>
              <HBox />
              <BodyText color="gray9">
                Это ваша электронная подпись, которая позволит автоматически
                отправлять все платежи в банк и обеспечит безопасное соединение
                между сервисом Mozen и банком, надёжно защитит данные от
                перехвата и подмены.
              </BodyText>
              <HBox />
              <DownloadLink href="/files/certificate.docx" download>
                <Icon name="download" className="download" color="blue">
                  Подробная инструкция создания сертификата
                </Icon>
              </DownloadLink>
              <HBox x={2} />
              <Actions>
                <ButtonWidth>
                  <FloatButton
                    type="submit"
                    fullWidth
                    onClick={openAddcertificateModal}
                  >
                    Добавить сертификат
                  </FloatButton>
                </ButtonWidth>
                <VBox />
                <SkipButton
                  onClick={handleSkip}
                  tipText="Загрузить сертификат безопасности вы сможете позже в разделе «Настройки»"
                >
                  Пропустить
                </SkipButton>
              </Actions>
            </>
          )}
        </Display>
      </Wrapper>
      {isAddCertificateModalOpened ? <AddCertificateModalContainer /> : null}
    </>
  )
}
