export enum FuelTransactionType {
  Bonus = 'bonus',
  Deposit = 'deposit',
}

export interface FuelTransaction {
  id: string
  date: number
  type: FuelTransactionType
  reason: string
  amount: number
}

export interface Filter {
  type: FuelTransactionType
  label: string
  isEnabled: boolean
  color: string
}

export type EventFilter = Pick<Filter, 'label' | 'type' | 'isEnabled'>

export interface TableChangeEvent {
  rowsPerPage: number
  pageIndex: number
  filters: EventFilter[]
}
