import { ThunkAction } from 'redux-thunk'
import { State } from 'reducers'
import axios, { AxiosResponse } from 'axios'
import { FailureError } from 'common/utils/reduxApiFactory'
import { TApiError } from 'api/types'
import {
  ParkReviseActions,
  ParkReviseRequest,
  ParkReviseResponse,
} from './types'
import { postParkReviseActions } from './actions'

export type THandleRequest = ThunkAction<
  Promise<ParkReviseResponse | void>,
  State,
  undefined,
  ParkReviseActions
>

export const postParkReviseRequest = (
  requestParams: ParkReviseRequest
): THandleRequest => async (dispatch) => {
  const { start, update, success, failure } = postParkReviseActions
  dispatch(start())
  try {
    const response: AxiosResponse<ParkReviseResponse> = await axios.post(
      `erp/1/park/revise`,
      requestParams
    )
    dispatch(update(response.data))
    dispatch(success())
  } catch (e) {
    if (e.response) {
      const data = e.response.data as TApiError
      const error: FailureError = new Error(data.message)
      error.data = data
      dispatch(failure(error))
    }
  }
}
