import React, { useEffect, useState } from 'react'

import { Col, HBox, Loader, Row as DefaultRow, VBox } from 'shared/ui'
import { StatisticsFeesContainer as StatisticsFees } from 'analytics/features/statisticsFees'
import { StatisticsWeekdayWithdrawContainer as WeekdayWithdraw } from 'analytics/features/statisticsWeekdayWithdraw'
import { IncomeReport } from 'analytics/features/incomeReport'
import { ContainerProps } from 'analytics/containers/AnalyticsContainer'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding: ${({ theme }) => theme.paddings.main}px;
`

const Row = styled(DefaultRow)`
  align-items: flex-start;
`

export const Analytics: React.FC<ContainerProps> = ({
  onDidMount,
  canView,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const init = async () => {
      if (canView) {
        await onDidMount()
      }
      setIsLoading(false)
    }
    init()
  }, [canView, onDidMount])

  if (!canView) {
    return null
  }

  return isLoading ? (
    <Loader />
  ) : (
    <Wrapper>
      <Row>
        <Col percentage={62}>
          <StatisticsFees />
          <HBox />
          <WeekdayWithdraw />
        </Col>
        <VBox />
        <Col percentage={38}>
          <IncomeReport />
        </Col>
      </Row>
    </Wrapper>
  )
}
