import React from 'react'

import { BodyText, Icon } from 'ui-kit/components'
import { Wrapper, Marker, Label } from 'ui-kit/components/Radio/styles'
import { SubBodyText } from '../Typography'

interface Props {
  name: string
  /** used to link input and label via id and htmlFor */
  inputId?: string
  alt?: () => React.ReactNode
  value?: string
  checked?: boolean
  defaultChecked?: boolean
  disabled?: boolean
  reverse?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  withMarker?: boolean
}

/**
 * A radio comp by itself or to make a radio button
 */
export const Radio: React.FC<Props> = ({
  children,
  name,
  inputId,
  alt,
  value,
  defaultChecked,
  checked,
  reverse,
  disabled,
  withMarker = true,
  onChange,
}) => {
  const renderContent = () => {
    if (children && alt) {
      return (
        <Label className="muk-radio-label">
          <BodyText>{children}</BodyText>
          {alt()}
        </Label>
      )
    }

    if (children) {
      return <SubBodyText className="muk-radio-label">{children}</SubBodyText>
    }

    return null
  }

  return (
    <Wrapper
      htmlFor={inputId}
      className="muk-radio-wrapper"
      disabled={disabled}
      reverse={reverse}
      data-testid={`${name}-${checked ? 'checked' : 'unchecked'}`}
    >
      <input
        hidden
        name={name}
        id={inputId}
        type="radio"
        disabled={disabled}
        defaultChecked={defaultChecked}
        checked={checked}
        onChange={onChange}
        value={value}
      />
      {renderContent()}
      {withMarker && (
        <Marker className="muk-radio-mark-wrapper">
          <Icon name="check" className="muk-radio-mark-wrapper-icon" />
        </Marker>
      )}
    </Wrapper>
  )
}
