import { Action, BaseAction } from 'redux-actions'

import { EmployeesGetResponse } from 'api/types'

export type TUpdate = Action<EmployeesGetResponse>
export type TStart = BaseAction
export type TSuccess = BaseAction
export type TFailure = Action<{ error: any }>
export type TReset = BaseAction
export type TResetStatus = BaseAction

export type TEmployeesGetAction =
  | TUpdate
  | TStart
  | TSuccess
  | TFailure
  | TReset
  | TResetStatus

export const UPDATE: TUpdate['type'] = 'api/employees/get->UPDATE'
export const START: TStart['type'] = 'api/employees/get->START'
export const SUCCESS: TSuccess['type'] = 'api/employees/get->SUCCESS'
export const FAILURE: TFailure['type'] = 'api/employees/get->FAILURE'
export const RESET: TReset['type'] = 'api/employees/get->RESET'
export const RESET_STATUS: TResetStatus['type'] =
  'api/employees/get->RESET_STATUS'

export const update = (data: EmployeesGetResponse): TUpdate => ({
  type: UPDATE,
  payload: data,
})
export const start = () => ({
  type: START,
})
export const success = () => ({
  type: SUCCESS,
})
export const failure = (error: any): TFailure => ({
  type: FAILURE,
  payload: error,
})
export const reset = () => ({
  type: RESET,
})
export const resetStatus = () => ({
  type: RESET_STATUS,
})

export type TPayloads = Pick<TUpdate & TFailure, 'payload'>['payload']
