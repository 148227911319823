export enum FormNames {
  SignUp = 'SignUp',

  StepRequisites = 'StepRequisites',
  StepPaymentsQiwi = 'StepPaymentsQiwi',
  StepPaymentsModul = 'StepPaymentsModul',
  StepPaymentsTinkoff = 'StepPaymentsTinkoff',
  StepPaymentsAlfaE2C = 'StepPaymentsAlfaE2C',
  StepDriversEdgvr = 'StepDriversEdgvr',

  UploadContracts = 'UploadContracts',
  AddDriverContract = 'AddDriverContract',
  DriverWithdrawSettings = 'DriverWithdrawSettings',

  AddEmployee = 'AddEmployee',

  ProfileInfo = 'ProfileInfo',
  ParkContacts = 'ParkContacts',
  ParkEdgvr = 'ParkEdgvr',
  ParkCertificate = 'ParkCertificate',
  ParkQiwi = 'ParkQiwi',
  ParkModul = 'ParkModul',
  ParkTinkoff = 'ParkTinkoff',
  ParkModulEdit = 'ParkModulEdit',
  ParkTinkoffEdit = 'ParkTinkoffEdit',
  ParkAzonEdit = 'ParkAzonEdit',
  AddAggregator = 'AddAggregator',
  PaymentsSettings = 'PaymentsSettings',
  PaymentsSettingsGroupName = 'PaymentsSettingsGroupName',
  PaymentsSwitches = 'PaymentsSwitches',
  DepositPay = 'DepositPay',
  NotificationsSettings = 'NotificationsSettings',
  NotificationsQiwiSettings = 'NotificationsQiwiSettings',
  FuelDownloadOrder = 'FuelDownloadOrder',
  FuelSettings = 'FuelSettings',
  TelegramBot = 'TelegramBot',
  OfdConnect = 'OfdConnect',
  OfdEdit = 'OfdEdit',
  EditE2C = 'EditE2C',
}
