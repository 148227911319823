interface Errors {
  week_withdraw_limit?: string
  min_withdraw_amount?: string
  max_withdraw_amount?: string
}

export const validate = (values: any) => {
  const errors = {} as Errors
  if (
    values.week_withdraw_limit &&
    values.day_withdraw_limit &&
    Number(values.week_withdraw_limit) < Number(values.day_withdraw_limit)
  ) {
    errors.week_withdraw_limit = 'Не может быть меньше, чем лимит в день'
  }

  if (
    (values.day_withdraw_limit &&
      Number(values.day_withdraw_limit) < Number(values.min_withdraw_amount)) ||
    (values.week_withdraw_limit &&
      Number(values.week_withdraw_limit) < Number(values.min_withdraw_amount))
  ) {
    errors.min_withdraw_amount =
      'Не может быть больше, чем лимит в день или неделю'
  }

  if (
    (values.day_withdraw_limit &&
      Number(values.day_withdraw_limit) < Number(values.max_withdraw_amount)) ||
    (values.week_withdraw_limit &&
      Number(values.week_withdraw_limit) < Number(values.max_withdraw_amount))
  ) {
    errors.max_withdraw_amount =
      'Не может быть больше, чем лимит в день или неделю'
  }

  if (
    values.min_withdraw_amount &&
    values.max_withdraw_amount &&
    Number(values.max_withdraw_amount) < Number(values.min_withdraw_amount)
  ) {
    errors.max_withdraw_amount = 'Не может быть меньше минимальной суммы вывода'
  }

  return errors
}
