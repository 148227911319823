import { batch, connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import { State } from 'reducers'
import { apiSelectors } from 'api'
import { MyThunkDispatch } from 'api/reducer'
import { FormNames, ModalsNames } from 'common/enums'
import { modalsActions } from 'shared/modals'
import { onSave } from 'employees/features/employeesList/thunks'

import { AddEmployeeModal } from 'employees/features/employeesList/pages'

type OwnProps = {
  id?: string
}

export type FormProps = {
  email?: string | null
  full_name: string | null
  password: string | null
  position: string | null
  phone?: string | null
}

const mapStateToProps = (state: State, ownProps: OwnProps) => ({
  isBlocked: apiSelectors.employees.employeesByIdGet.getIsBlocked(state),
  isParkCreator: apiSelectors.employees.employeesByIdGet.getIsParkCreator(
    state
  ),
  employeePermissions: ownProps.id
    ? apiSelectors.employees.employeesByIdGet.getPermissions(state)
    : null,
  canChangePermissions: ownProps.id
    ? apiSelectors.employee.profile.getCanChangePermissions(state) &&
      !apiSelectors.employees.employeesByIdGet.getIsParkCreator(state)
    : true,
  initialValues: ownProps.id
    ? apiSelectors.employees.employeesByIdGet.getEmployeeFormValues(state)
    : undefined,
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  closeModal: () => dispatch(modalsActions.closeModal(ModalsNames.AddEmployee)),
  onSave: ({ values, permissions, id }: any) =>
    dispatch(onSave({ values, permissions, id })),
  openBlockEmployeeModal: () =>
    batch(() => {
      dispatch(modalsActions.closeModal(ModalsNames.AddEmployee))
      dispatch(modalsActions.openModal(ModalsNames.BlockEmployee))
    }),
})

const Form = reduxForm<FormProps, ContainerProps>({
  form: FormNames.AddEmployee,
})(AddEmployeeModal)

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps

export const AddEmployeeModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Form)
