import { createApiReducer } from 'common/utils/reduxApiFactory'
import { getFuelSummaryActions } from 'api/fuel/summary/get/actions'
import { GetFuelSummaryState } from 'api/fuel/summary/get/types'

export const initialState: GetFuelSummaryState = {
  status: null,
  data: null,
}

export const getFuelSummaryReducer = createApiReducer(
  getFuelSummaryActions,
  initialState
)
