import styled from 'styled-components'

type Props = {
  isSelected?: boolean
}

export const PaymentSettingsListItem = styled.button<Props>`
  appearance: none;
  border: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  background-color: transparent;
  background-color: ${({ isSelected, theme }) =>
    isSelected && theme.pallete.secondary};

  &:hover {
    background-color: ${({ isSelected }) => !isSelected && '#f4f8ff'};
  }
`
