import { createSelector } from 'reselect'
import { State } from 'reducers'

export const getIsFetching = (state: State) =>
  state.api.park.subscriptionPayments.status === 'loading'

const getData = (state: State) => state.api.park.subscriptionPayments.data

export const getPayments = createSelector(getData, (data) =>
  data ? data.payments : []
)

export const getPaymentsTotal = createSelector(getData, (data) =>
  data ? data.payments_total : null
)
