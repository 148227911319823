import { createSelector } from 'reselect'
import { State } from 'reducers'
import { AggregatorType } from 'common/enums'
import { Aggregator } from 'api/types'

export const getIsFetching = (state: State) =>
  state.api.park.aggregators.status === 'loading'

export const getData = (state: State) => state.api.park.aggregators.data

export const getAggregators = createSelector(getData, (data) => {
  if (!data) {
    return [] as Aggregator[]
  }

  return [
    ...data[AggregatorType.Yandex].map((item) => ({
      ...item,
      type: AggregatorType.Yandex,
    })),
    ...data[AggregatorType.Citymobil].map((item) => ({
      ...item,
      type: AggregatorType.Citymobil,
    })),
    // ...data[AggregatorType.DiDi].map((item) => ({
    //   ...item,
    //   type: AggregatorType.DiDi,
    // })),
  ] as Aggregator[]
})

export const getAggregatorsList = createSelector(
  getAggregators,
  (aggregators) => {
    return aggregators.reduce((map, obj) => {
      // eslint-disable-next-line no-param-reassign
      map[obj.id] = obj
      return map
    }, {} as { [key: string]: Aggregator })
  }
)

export const hasYandexAggregators: (state: State) => boolean = createSelector(
  getAggregators,
  (aggregators) =>
    aggregators?.some(
      (aggregator) => aggregator.type === AggregatorType.Yandex
    ) ?? false
)
