import React, { useEffect, useState } from 'react'
import { Col, Loader, VBox } from 'shared/ui'
import { EmployeesListContainer } from 'employees/features/employeesList'
import { EmployeesJournalContainer } from 'employees/features/employeesJournal'
import { ContainerProps } from 'employees/features/employeesMain/containers/EmployeesMainContainer'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding: ${({ theme }) => theme.paddings.main}px;
  display: flex;
  /* min-height: 888px; */
`

export const EmployeesMain: React.FC<ContainerProps> = ({
  canViewLogs,
  isFetching,
  onDidMount,
}) => {
  const [firstRender, setFirstRender] = useState(false)
  useEffect(() => {
    onDidMount()
    setFirstRender(true)
  }, [onDidMount])
  return isFetching || !firstRender ? (
    <Loader />
  ) : (
    <Wrapper>
      <Col percentage={50}>
        <EmployeesListContainer />
      </Col>
      <VBox />

      {canViewLogs ? (
        <Col percentage={50}>
          <EmployeesJournalContainer />
        </Col>
      ) : null}
    </Wrapper>
  )
}
