import { combineReducers } from 'redux'

import {
  PostFuelSettingsActions,
  PostFuelSettingsState,
} from 'api/fuel/settings/post/types'
import { postFuelSettingsReducer } from 'api/fuel/settings/post/reducer'
import { postFuelSettingsRequest } from 'api/fuel/settings/post/thunks'
import * as postFuelSettingsSelectors from 'api/fuel/settings/post/selectors'

import {
  GetFuelSettingsActions,
  GetFuelSettingsState,
} from 'api/fuel/settings/get/types'
import { getFuelSettingsReducer } from 'api/fuel/settings/get/reducer'
import { getFuelSettingsRequest } from 'api/fuel/settings/get/thunks'
import * as getFuelSettingsSelectors from 'api/fuel/settings/get/selectors'

import {
  PostDepositHistoryActions,
  PostDepositHistoryState,
} from 'api/fuel/deposit-history/post/types'
import { postDepositHistoryReducer } from 'api/fuel/deposit-history/post/reducer'
import { postDepositHistoryRequest } from 'api/fuel/deposit-history/post/thunks'
import * as postDepositHistorySelectors from 'api/fuel/deposit-history/post/selectors'

import {
  GetFuelSummaryActions,
  GetFuelSummaryState,
} from 'api/fuel/summary/get/types'
import { getFuelSummaryReducer } from 'api/fuel/summary/get/reducer'
import { getFuelSummaryRequest } from 'api/fuel/summary/get/thunks'
import * as getFuelSummarySelectors from 'api/fuel/summary/get/selectors'

export interface FuelState {
  postFuelSettings: PostFuelSettingsState
  getFuelSettings: GetFuelSettingsState
  postDepositHistory: PostDepositHistoryState
  getFuelSummary: GetFuelSummaryState
}

export type TFuelAction =
  | PostFuelSettingsActions
  | GetFuelSettingsActions
  | PostDepositHistoryActions
  | GetFuelSummaryActions

export const fuelReducer = combineReducers({
  postFuelSettings: postFuelSettingsReducer,
  getFuelSettings: getFuelSettingsReducer,
  postDepositHistory: postDepositHistoryReducer,
  getFuelSummary: getFuelSummaryReducer,
})

export const fuelActions = {
  postFuelSettings: {
    handleRequest: postFuelSettingsRequest,
  },
  getFuelSettings: {
    handleRequest: getFuelSettingsRequest,
  },
  postDepositHistory: {
    handleRequest: postDepositHistoryRequest,
  },
  getFuelSummary: {
    handleRequest: getFuelSummaryRequest,
  },
}

export const fuelSelectors = {
  postFuelSettings: postFuelSettingsSelectors,
  getFuelSettings: getFuelSettingsSelectors,
  postDepositHistory: postDepositHistorySelectors,
  getFuelSummary: getFuelSummarySelectors,
}
