import React from 'react'
import { Field } from 'redux-form'

import { history } from 'store'

import { RoutesPath } from 'common/enums'
import ListInput from 'common/components/formItems/ListInput'
import {
  comparePasswords,
  maxLength30,
  minLength6,
  required,
} from 'common/utils/fieldValidation'

import { Image } from 'shared/ui'
import { AuthLayout } from 'shared/router'
import {
  Action,
  ActionLink,
  Description,
  Form,
  ImageHolder,
  LeftContent,
  RightContent,
} from 'auth/resetPassword/styles'

import { BodyText, FloatButton, ListItem, TitleText } from 'ui-kit/components'
import carSrc from 'static/backgrounds/car.svg'

interface Props {
  submitLoading?: boolean
  onSubmit: () => void
}

class ResetPasswordComponent extends React.Component<Props> {
  private renderLeftSideContent = (): JSX.Element => {
    const { onSubmit, submitLoading } = this.props
    return (
      <LeftContent>
        <Form>
          <TitleText fontWeight="semi-bold" className="form-title">
            Сброс пароля
          </TitleText>
          <Description>
            <ListItem>
              <BodyText>
                Пароль должен содержать хотя бы один символ или цифру и не менее
                6 знаков
              </BodyText>
            </ListItem>
          </Description>
          <Field
            name="newPassword"
            label="Пароль"
            type="password"
            validate={[minLength6, maxLength30, required]}
            component={ListInput}
          />
          <Field
            name="comparePassword"
            label="Подтвердите пароль"
            type="password"
            validate={comparePasswords}
            component={ListInput}
          />
          <Action>
            <FloatButton
              fullWidth
              type="submit"
              onClick={onSubmit}
              isLoading={submitLoading}
              color="blue"
              textColor="white"
            >
              Отправить
            </FloatButton>
          </Action>
          <ActionLink onClick={() => history.replace(RoutesPath.SignIn)}>
            <BodyText color="blue" fontWeight="semi-bold">
              Вернуться ко входу
            </BodyText>
          </ActionLink>
        </Form>
      </LeftContent>
    )
  }

  private renderRightSideContent = (): JSX.Element => {
    return (
      <RightContent>
        <ImageHolder>
          <TitleText
            className="sign-up-right-title"
            fontWeight="semi-bold"
            color="white"
          >
            Автоматизированная система для&nbsp;управления автопарком
          </TitleText>
          <Image src={carSrc} width="50vw" />
        </ImageHolder>
      </RightContent>
    )
  }

  render() {
    return (
      <AuthLayout
        leftSideContent={this.renderLeftSideContent()}
        rightSideContent={this.renderRightSideContent()}
      />
    )
  }
}

export default ResetPasswordComponent
