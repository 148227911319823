import { ApiState, createApiReducer } from 'common/utils/reduxApiFactory'
import { actions } from 'api/park/statistics/fees/actions'
import { StatisticsFees, StatisticsFeesSummary } from 'api/types'

export type StatisticsFeesData = {
  data: StatisticsFees[]
  summary: StatisticsFeesSummary
}

export type StatisticsFeesState = ApiState<StatisticsFeesData>

export const initialState: StatisticsFeesState = {
  status: null,
  data: null,
}

export const reducer = createApiReducer(actions, initialState)
