import React from 'react'
import { ListLead, SubBodyText } from 'ui-kit/components'
import { EmployeesLogs } from 'api/types'
import ListItemComponent from 'common/components/listItem/ListItem'
import { toDate, toTime } from 'common/utils/dates'
import { getWidthInPercent } from 'common/utils/formatters'
import styled from 'styled-components'

const Empty = styled.div`
  padding: ${({ theme }) => theme.paddings.main}px;
`

const RowItem = styled.div<{ onClick?: () => void }>`
  display: flex;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  align-items: center;
  border-bottom: 0.5px solid ${({ theme }) => theme.pallete.gray3};

  .header-item {
    &:not(:first-child) {
      border-left-color: ${({ theme }) => theme.pallete.gray3};
    }
  }

  ${(props) =>
    !!props.onClick &&
    `
    &:hover {
      background-color: #EAEEF7;
    }
  `}
`

interface Props {
  hasEmployeeId: boolean
  logs: EmployeesLogs[]
}

export const Listing: React.FC<Props> = ({ hasEmployeeId, logs }) => {
  const blockWidth = 428

  const renderSubtitle = (item: EmployeesLogs): string => {
    const name = item.extra?.employee_name ? item.extra.employee_name : ''
    const position = item.extra?.position ? item.extra.position : ''
    return `${name}, ${position}`
  }

  return (
    <>
      {logs.length ? (
        logs.map((item) => (
          <RowItem key={item.employee_id}>
            <ListItemComponent
              flex={`${getWidthInPercent(blockWidth, 104)}%`}
              border="table"
            >
              <ListLead subtitle={toTime(item.created_at)} reverse table>
                {toDate(item.created_at)}
              </ListLead>
            </ListItemComponent>
            <ListItemComponent
              flex={`${getWidthInPercent(blockWidth, 324)}%`}
              border="table"
            >
              <ListLead
                table
                subtitle={hasEmployeeId ? undefined : renderSubtitle(item)}
                reverse
              >
                {item.title}
              </ListLead>
            </ListItemComponent>
          </RowItem>
        ))
      ) : (
        <Empty>
          <SubBodyText color="gray6">Нет записей</SubBodyText>
        </Empty>
      )}
    </>
  )
}
