import { ReferralOffer } from 'api/referral/offer/types'
import { fixDecimalPrecisionErrors } from 'elastic-ui/utils'
import { ProgramStates } from '../organisms/DescriptionAndActionsPanel/types'
import { ProgramSettings } from '../types'
import mapInviterBonusFrequencyToInviterRewardPeriodType from './reverseMappers/mapInviterBonusFrequencyToInviterRewardPeriodType'
import mapInviterBonusTypeToInviterRewardType from './reverseMappers/mapInviterBonusTypeToInviterRewardType'

interface Input {
  settings: ProgramSettings
  state: ProgramStates | undefined
  telegramChatId: string | undefined
}

export default ({ settings, state, telegramChatId }: Input): ReferralOffer => ({
  aggregators: settings.selectedAggregatorAccounts.map((account) => account.id),
  id: settings.id,
  custom_full_description: settings.customFullTerms,
  custom_short_description: settings.customShortTerms,
  custom_title: settings.customTitle,
  invited_ride_count: settings.minimumAmountOfDrivesForInvitee,
  invited_ride_offset: settings.numberOfUnpaidDrives,
  invited_ride_period: settings.periodForMinimumAmountOfDrivesForInvitee,
  inviter_reward_period_limit: settings.paymentDurationForInviter,
  inviter_reward_period_type: mapInviterBonusFrequencyToInviterRewardPeriodType(
    settings.inviterBonusFrequency
  ),
  inviter_reward_type: mapInviterBonusTypeToInviterRewardType(
    settings.inviterBonusType
  ),
  inviter_reward_value: fixDecimalPrecisionErrors(settings.bonusAmount * 100),
  inviter_ride_count: settings.dontPayIfInviterIsNotTakingDrives
    ? 20
    : undefined,
  inviter_ride_period: settings.dontPayIfInviterIsNotTakingDrives
    ? 7
    : undefined,
  telegram_chat_id: telegramChatId,
  visible: state !== ProgramStates.Hidden,
  visible_period_end: undefined,
  visible_period_start: undefined,
})
