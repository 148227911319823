import { ThunkAction } from 'redux-thunk'
import { State } from 'reducers'
import axios, { AxiosResponse } from 'axios'
import { FailureError } from 'common/utils/reduxApiFactory'
import { TApiError } from 'api/types'
import { patchBlockCloudCashBoxActions as actions } from 'api/receipt/cloud-cashbox/block/patch/actions'
import {
  PatchBlockCloudCashBox,
  PatchBlockCloudCashBoxActions,
} from 'api/receipt/cloud-cashbox/block/patch/types'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  PatchBlockCloudCashBoxActions
>

export const patchBlockCloudCashBoxRequest = (): THandleRequest => async (
  dispatch
) => {
  dispatch(actions.start())
  try {
    const response: AxiosResponse<PatchBlockCloudCashBox> = await axios.patch(
      'erp/1/receipt/cloud-cashbox/block'
    )
    dispatch(actions.update(response.data))
    dispatch(actions.success())
  } catch (e) {
    if (e.response) {
      const data = e.response.data as TApiError
      const error: FailureError = new Error(data.message)
      error.data = data
      dispatch(actions.failure(error))
    }
    throw e
  }
}
