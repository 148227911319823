import { combineReducers } from 'redux'

import * as postReferralInviteListSelectors from './invite/list/post/selectors'
import {
  PostReferralInviteListState,
  PostReferralInviteListActions,
} from './invite/list/post/types'
import { postReferralInviteListReducer } from './invite/list/post/reducers'
import { postReferralInviteListActions } from './invite/list/post/actions'
import { postReferralInviterListRequest } from './invite/list/post/thunks'

import * as getReferralOffersSelectors from './offer/get/selectors'
import {
  GetReferralOffersActions,
  GetReferralOffersState,
} from './offer/get/types'
import { getReferralOffersReducer } from './offer/get/reducers'
import { getReferralOffersActions } from './offer/get/actions'
import { getReferralOffersRequest } from './offer/get/thunks'

import * as patchReferralOffersSelectors from './offer/patch/selectors'
import {
  PatchReferralOffersActions,
  PatchReferralOffersState,
} from './offer/patch/types'
import { patchReferralOffersReducer } from './offer/patch/reducers'
import { patchReferralOffersRequest } from './offer/patch/thunks'
import { patchReferralOffersActions } from './offer/patch/actions'

import * as postReferralOffersSelectors from './offer/post/selectors'
import {
  PostReferralOffersActions,
  PostReferralOffersState,
} from './offer/post/types'
import { postReferralOffersReducer } from './offer/post/reducers'
import { postReferralOffersActions } from './offer/post/actions'
import { postReferralOffersRequest } from './offer/post/thunks'

import * as referralOffersSelectors from './offer/selectors'
import { referralOffersReducer } from './offer/reducers'
import { referralOffersActions } from './offer/actions'
import { ReferralOffersActions, ReferralOffersState } from './offer/types'
import {
  DeleteReferralOfferActions,
  DeleteReferralOfferState,
} from './offer/delete/types'
import { deleteReferralOfferReducer } from './offer/delete/reducers'
import { deleteReferralOfferRequest } from './offer/delete/thunks'

export interface ReferralState {
  postInviteList: PostReferralInviteListState
  offers: ReferralOffersState
  getOffers: GetReferralOffersState
  patchOffers: PatchReferralOffersState
  postOffers: PostReferralOffersState
  deleteOffer: DeleteReferralOfferState
}

export type ReferralActions =
  | PostReferralInviteListActions
  | GetReferralOffersActions
  | PatchReferralOffersActions
  | PostReferralOffersActions
  | DeleteReferralOfferActions
  | ReferralOffersActions

export const referralReducer = combineReducers({
  postInviteList: postReferralInviteListReducer,
  offers: referralOffersReducer,
  getOffers: getReferralOffersReducer,
  patchOffers: patchReferralOffersReducer,
  postOffers: postReferralOffersReducer,
  deleteOffer: deleteReferralOfferReducer,
})

export const referralActions = {
  postInviteList: {
    ...postReferralInviteListActions,
    handleRequest: postReferralInviterListRequest,
  },
  offers: {
    update: referralOffersActions.update,
    handleGet: getReferralOffersRequest,
    handlePatch: patchReferralOffersRequest,
    handlePost: postReferralOffersRequest,
    handleDelete: deleteReferralOfferRequest,
  },
}

export const referralSelectors = {
  postInviteList: postReferralInviteListSelectors,
  offers: referralOffersSelectors,
}
