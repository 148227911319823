import React from 'react'

import { Icon } from 'ui-kit/components'

import {
  Wrapper,
  Input,
  InputWrapper,
} from 'ui-kit/components/Searchbar/styles'

interface Props {
  value?: string
  placeholder?: string
  isLoading?: boolean
  borderBottom?: boolean
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const Searchbar: React.FC<Props> = (props) => {
  const {
    value,
    onChange,
    onFocus,
    onBlur,
    isLoading,
    borderBottom,
    placeholder,
  } = props

  const onClickSearchIcon = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault()

    const input = document.querySelector<HTMLInputElement>(
      '[data-search-input]'
    )

    if (input) {
      input.focus()
    }
  }

  return (
    <Wrapper className="muk-searchbar" borderBottom={borderBottom}>
      <Icon name="search" onClick={onClickSearchIcon} />
      <InputWrapper isLoading={isLoading}>
        <Input
          data-search-input
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={placeholder || 'Поиск...'}
        />
        <div className="bar" />
      </InputWrapper>
      {isLoading && <Icon name="colorLoad" className="loader" />}
    </Wrapper>
  )
}
