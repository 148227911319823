import { apiActions } from 'api'
import { MyThunkDispatch, MyThunkResult } from 'api/reducer'

export const onDidMount: (employee_id: string) => MyThunkResult<void> = (
  employee_id
) => {
  return (dispatch: MyThunkDispatch) => {
    dispatch(apiActions.employees.employeesByIdGet.reset())
    dispatch(apiActions.employees.employeesByIdGet.handleRequest(employee_id))
  }
}
