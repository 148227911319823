import { connect } from 'react-redux'

import { State } from 'reducers'
import { apiSelectors } from 'api'

import { onDidMount } from 'auth/signOut/thunks'
import { SignOut } from 'auth/signOut/components'
import { MyThunkDispatch } from 'api/reducer'

const mapStateToProps = (state: State) => ({
  isFetching: apiSelectors.auth.logout.getIsFetching(state),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  onDidMount: () => dispatch(onDidMount()),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const SignOutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SignOut)
