import { createApiActions } from 'common/utils/reduxApiFactory'
import {
  StatisticsWeekdayWithdraw,
  StatisticsWeekdayWithdrawParams,
} from 'api/types'

export const actions = createApiActions<
  StatisticsWeekdayWithdraw[],
  StatisticsWeekdayWithdrawParams
>('park/statistics/weekday-withdraw')

export type Actions = ReturnType<typeof actions[keyof typeof actions]>
