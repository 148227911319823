import { connect } from 'react-redux'

import { State } from 'reducers'
import { apiActions, apiSelectors } from 'api'
import { MyThunkDispatch } from 'api/reducer'
import { PaymentTransaction } from 'api/types'
import {
  closeModalWithCallback,
  onConfirmTransaction,
} from 'payments/features/paymentDetailsModal/thunks'
import { PaymentDetailsModal } from 'payments/features/paymentDetailsModal/pages'
import { modalsActions } from 'shared/modals'
import { ModalsNames } from 'common/enums'
import { transactionStatusActions } from 'api/transactions/status-request/actions'

type OwnProps = {
  transaction: PaymentTransaction
  callbackFetch: () => void
}

const mapStateToProps = (state: State) => ({
  transactionStatus: apiSelectors.transactions.status.selectTransactionStatus(
    state
  ),
  isFetchingTransactionStatus: apiSelectors.transactions.status.selectIsFetching(
    state
  ),
  bankType: apiSelectors.park.details.getBankType(state),
  isFetchingConfirm: apiSelectors.park.transactionsConfirm.getIsFetching(state),
  canProcessTransactions: apiSelectors.employee.profile.getCanProcessTransactions(
    state
  ),
  canViewDriverDetails: apiSelectors.employee.profile.getCanViewDriverDetails(
    state
  ),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch, ownProps: OwnProps) => ({
  onConfirm: (confirmed: boolean) =>
    dispatch(
      onConfirmTransaction({
        confirmed,
        id: ownProps.transaction.id,
        callbackFetch: ownProps.callbackFetch,
      })
    ),
  updateTransactionStatus: () =>
    dispatch(
      apiActions.transactions.status.handleRequest(ownProps.transaction.id)
    ),
  resetStatus: () => dispatch(transactionStatusActions.reset()),
  closeModal: ({ withCallback }: { withCallback: boolean }) =>
    dispatch(closeModalWithCallback(withCallback, ownProps.callbackFetch)),
  saveData: (data?: PaymentTransaction) =>
    dispatch(modalsActions.setData(ModalsNames.PaymentDetails, data)),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps

export const PaymentDetailsModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentDetailsModal)
