import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { Icon, SubBodyText } from 'ui-kit/components'
import { Row, VBox } from 'shared/ui'
import { ColorTypes, IconNames } from 'ui-kit'

const StyledLink = styled(Link)`
  text-decoration: none;
  background-color: ${({ theme }) => `${theme.pallete.white}40`};
  padding: ${({ theme }) =>
    `${theme.paddings.half}px ${theme.paddings.threeQuarters}px`};
  border-radius: 5px;

  transition: background-color 0.25s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => `${theme.pallete.white}90`};
  }
`

type Props = {
  title: string
  iconName: IconNames
  url: string
  titleColor?: ColorTypes
}

export const DriversToolbarLink: React.FC<Props> = ({
  title,
  iconName,
  url,
  titleColor = 'blue',
}) => {
  return (
    <StyledLink to={url}>
      <Row>
        <Icon name={iconName} color={titleColor} />
        <VBox x={0.5} />
        <SubBodyText color={titleColor} fontWeight="medium">
          {title}
        </SubBodyText>
      </Row>
    </StyledLink>
  )
}
