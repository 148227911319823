import React from 'react'
import { Switch, useLocation } from 'react-router-dom'

import { RoutesPath } from 'common/enums'
import { PrivateRoute } from 'shared/router'

import { StepsContainer } from 'steps'
import { DriversContainer } from 'drivers'
import Dashboard from 'dashboard/containers/DashboardContainer'
import { AnalyticsContainer } from 'analytics'
import { PaymentsContainer } from 'payments'
import { Employees } from 'employees'
import { SettingsContainer } from 'settings'
import { NoticeContainer } from 'layout/features/notice'
import { Fuel } from 'fuel'
import styled from 'styled-components'

const Wrapper = styled.div<{ isSidebarVisible?: boolean }>`
  width: ${({ isSidebarVisible }) =>
    isSidebarVisible ? 'calc(100% - 240px)' : '100%'};
  background-color: #eaeef7;
  margin-left: auto;
  min-height: 100vh;
  position: relative;

  @media (max-width: 1024px) {
    width: ${({ isSidebarVisible }) =>
      isSidebarVisible ? 'calc(100% - 72px)' : '100%'};
  }
`

interface Props {
  isSidebarVisible?: boolean
  isNoticeVisible?: boolean
}

export const Content: React.FC<Props> = ({
  isSidebarVisible,
  isNoticeVisible,
}) => {
  return (
    <Wrapper isSidebarVisible={isSidebarVisible}>
      {isNoticeVisible ? <NoticeContainer /> : null}
      <Switch>
        <PrivateRoute path={RoutesPath.Steps} component={StepsContainer} />
        <PrivateRoute exact path={RoutesPath.MainPage} component={Dashboard} />
        <PrivateRoute
          exact
          path={RoutesPath.Analytics}
          component={AnalyticsContainer}
        />
        <PrivateRoute
          path={RoutesPath.Payments}
          component={PaymentsContainer}
        />
        <PrivateRoute path={RoutesPath.Drivers} component={DriversContainer} />
        <PrivateRoute path={RoutesPath.Employees} component={Employees} />
        <PrivateRoute
          path={RoutesPath.Settings}
          component={SettingsContainer}
        />
        <PrivateRoute path={RoutesPath.Fuel} component={Fuel} />
      </Switch>
    </Wrapper>
  )
}
