import React from 'react'
import { Limitation, Pagination as UIPagination } from 'ui-kit/components'
import { PaginationLimit } from 'api/types'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  padding-left: ${({ theme }) => theme.paddings.half}px;
  padding-right: ${({ theme }) => theme.paddings.main}px;
  justify-content: space-between;
  align-items: center;
`

type Props = {
  currentPage: number
  currentLimit: PaginationLimit
  total: number
  onChangePage: (offset: number) => void
  onChangeLimit: (value: PaginationLimit) => void
}
export const Pagination = React.memo<Props>(
  ({ currentPage, currentLimit, total, onChangePage, onChangeLimit }) => {
    const handleChangeOffset = React.useCallback(
      (offset: number) => () => {
        onChangePage(offset)
      },
      [onChangePage]
    )

    const handleChangeLimit = React.useCallback(
      (value: PaginationLimit) => {
        onChangeLimit(value)
      },
      [onChangeLimit]
    )

    return (
      <Wrapper>
        <UIPagination
          currentPage={currentPage}
          totalPages={total}
          onClickPrev={handleChangeOffset(-1)}
          onClickNext={handleChangeOffset(1)}
        />
        <Limitation
          currentLimit={currentLimit}
          limitAction={handleChangeLimit}
        />
      </Wrapper>
    )
  }
)

Pagination.displayName = 'Pagination'
