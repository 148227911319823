import { connect } from 'react-redux'

import { State } from 'reducers'
import { apiActions, apiSelectors } from 'api'
import { MyThunkDispatch } from 'api/reducer'
import { FormNames, ModalsNames } from 'common/enums'
import { modalsActions, modalsSelectors } from 'shared/modals'
import { ParkTinkoff } from 'settings/features/parkTinkoff/organisms'
import { TinkoffAuth } from 'api/park/tinkoff/types'
import { reduxForm } from 'redux-form'
import { selectInitialValues } from 'settings/features/parkTinkoff/selector'

const mapStateToProps = (state: State) => ({
  isFetching: apiSelectors.park.getTinkoff.selectIsFetching(state),
  data: apiSelectors.park.getTinkoff.selectData(state),
  isAddTinkoffModalOpened: modalsSelectors.getIsModalOpened(
    state,
    ModalsNames.AddTinkoff
  ),
  initialValues: selectInitialValues(state),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  fetchEdit: (values: FormProps) =>
    dispatch(apiActions.park.patchTinkoff.handleRequest(values)),
  openAddTinkoffModal: () =>
    dispatch(modalsActions.openModal(ModalsNames.AddTinkoff)),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export type FormProps = Partial<TinkoffAuth>

const Form = reduxForm<FormProps, ContainerProps>({
  form: FormNames.ParkTinkoffEdit,
  enableReinitialize: true,
})(ParkTinkoff)

export const ParkTinkoffContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Form)
