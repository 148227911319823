import React from 'react'

import { ContentCard } from 'shared/ui'
import { Line } from 'drivers/features/driverCards/molecules'
import { ContainerProps } from 'drivers/features/driverCards/containers/DriverCardsContainer'
import styled from 'styled-components'

const Container = styled.div`
  padding-top: ${({ theme }) => theme.paddings.half}px;
  padding-bottom: ${({ theme }) => theme.paddings.half}px;
`

const Divider = styled.div`
  height: 1px;
  margin-left: ${({ theme }) => theme.paddings.threeQuarters}px;
  margin-right: ${({ theme }) => theme.paddings.threeQuarters}px;
  background-color: ${({ theme }) => theme.pallete.gray3};
`

export const DriverCards: React.FC<ContainerProps> = ({ cards }) => {
  return (
    <ContentCard withMinHeight={false}>
      <Container>
        {cards.map((card, index, array) => (
          <React.Fragment key={card.user_id}>
            <Line card={card} />
            {index !== array.length - 1 ? <Divider /> : null}
          </React.Fragment>
        ))}
      </Container>
    </ContentCard>
  )
}
