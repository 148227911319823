import React, { useCallback } from 'react'
import { formatToRegularDate } from 'common/utils/dates'
import { TableText } from 'ui-kit/components'
import { FormatActions } from 'payments/features/manualConfirmationList/molecules'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 27px;
`

const ButtonLink = styled.button`
  background: none;
  border: none;
  padding: 0;
  color: #2979ff;
  text-decoration: underline;
  cursor: pointer;
`

const SubText = styled.span`
  font-size: 12px;
  color: #051022;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

type Props = {
  id: string
  lastDownloadingTime: string
  canProcessTransactions?: boolean
  onDownload: (id: string) => void
  onConfirmPayment: (isConfirmed: boolean, id: string) => void
}
export const TransactionBilling = React.memo<Props>(
  ({
    id,
    onDownload,
    lastDownloadingTime,
    canProcessTransactions,
    onConfirmPayment,
  }) => {
    const handleDownload = useCallback(() => {
      onDownload(id)
    }, [id, onDownload])

    const isFirstTimeDownload = !lastDownloadingTime

    return (
      <Wrapper>
        <TableText>
          {isFirstTimeDownload && (
            <ButtonLink onClick={handleDownload}>Скачать</ButtonLink>
          )}
          {!isFirstTimeDownload && (
            <ButtonWrapper>
              <ButtonLink onClick={handleDownload}>Скачать еще раз</ButtonLink>
              <SubText>
                Скачано {formatToRegularDate(lastDownloadingTime)}
              </SubText>
            </ButtonWrapper>
          )}
        </TableText>
        {canProcessTransactions && (
          <FormatActions id={id} onConfirmPayment={onConfirmPayment} />
        )}
      </Wrapper>
    )
  }
)

TransactionBilling.displayName = 'TransactionBilling'
