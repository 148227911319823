import React, { useState } from 'react'
import Card from 'ui-kit/atoms/Card/Card'
import { InlineTextSelect } from 'ui-kit/atoms/InlineTextSelect/InlineTextSelect'
import { ContentToggle } from 'ui-kit/molecules/ContentToggle/ContentToggle'
import { RadioGroup, Option } from 'ui-kit/molecules/RadioGroup/RadioGroup'

interface Props {
  color: string
  children?: string
  options: Option[]
  selected: string
  onChange(selectedOption: Option): void
}

export const InlineOutputFormatSelect: React.FC<Props> = ({
  children,
  color,
  options,
  selected,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const Trigger: React.FC = () => (
    <InlineTextSelect isSelecting={isOpen} color={color} withHover>
      {children}
    </InlineTextSelect>
  )

  return (
    <ContentToggle
      trigger={Trigger}
      onTriggerClick={() => {
        setIsOpen(true)
      }}
      onClickAway={() => setIsOpen(false)}
      isOpen={isOpen}
    >
      <Card style={{ right: 0, top: 32, position: 'absolute', width: 320 }}>
        <RadioGroup
          options={options}
          selected={selected}
          onChange={(selectedOption) => {
            onChange(selectedOption)
            setIsOpen(false)
          }}
        />
      </Card>
    </ContentToggle>
  )
}
