import { createApiReducer } from 'common/utils/reduxApiFactory'
import { PostModulState } from 'api/park/modul/post/types'
import { postModulActions } from 'api/park/modul/post/actions'

export const initialState: PostModulState = {
  status: null,
  data: null,
}

export const postModulReducer = createApiReducer(postModulActions, initialState)
