import React from 'react'
import { Link } from 'react-router-dom'

import { RoutesPath } from 'common/enums'
import { ListPlaceholder } from 'shared/ui'

export const NoDrivers: React.FC = () => (
  <ListPlaceholder image="driver" imageHeight="240px">
    Добавьте свои <Link to={RoutesPath.SettingsAggregators}>агрегаторы</Link>,
    чтобы увидеть
    <br />
    водителей, которые работают в&nbsp;вашем парке
  </ListPlaceholder>
)
