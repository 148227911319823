import { connect } from 'react-redux'

import { State } from 'reducers'
import { apiActions, apiSelectors } from 'api'
import { MyThunkDispatch } from 'api/reducer'
import { AzonPostRequestBody } from 'api/park/azon/types'
import { InjectedFormProps, reduxForm } from 'redux-form'
import { FormNames } from 'common/enums'
import { ParkAzon } from '../organisms'

const mapStateToProps = (state: State) => ({
  isFetching:
    apiSelectors.park.getParkAzon.selectIsFetching(state) ||
    apiSelectors.park.postParkAzon.selectIsFetching(state),
  isSubmitted: apiSelectors.park.getParkAzon.selectIsSubmitted(state),
  isConfirmed: apiSelectors.park.getParkAzon.selectIsConfirmed(state),
  phone: apiSelectors.park.getParkAzon.selectPhone(state),
  contractDate: apiSelectors.park.getParkAzon.selectContractDate(state),
  contractNumber: apiSelectors.park.getParkAzon.selectContractNumber(state),
  confirmationError: apiSelectors.park.getParkAzon.selectConfirmationError(
    state
  ),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  postSettings: async (settings: AzonPostRequestBody) => {
    await dispatch(apiActions.park.postParkAzon.handleRequest(settings))
    dispatch(apiActions.park.getParkAzon.handleRequest())
  },
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export type FormProps = Partial<{
  phone: string
  login: string
  password: string
  contract_date: string
  contract_num: string
}>

export type Props = InjectedFormProps<FormProps, ContainerProps> &
  ContainerProps

const form = reduxForm<FormProps, ContainerProps>({
  form: FormNames.ParkAzonEdit,
})(ParkAzon)

export const ParkAzonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(form)
