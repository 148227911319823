import React, { useCallback, useMemo, useState } from 'react'
import {
  ContentCard,
  ContentCardBody,
  ContentCardFooter,
  ContentCardHeader,
} from 'shared/ui/contentCard'
import styled from 'styled-components'
import { Dropdown } from 'shared/ui/dropdown'
import { DropdownData } from 'shared/ui/dropdown/types'
import { Loader } from 'ui-kit/atoms/loader'
import { SubBodyText } from 'ui-kit/components/Typography'
import { TransparentButton } from 'ui-kit/components/Buttons'
import { Icon } from 'ui-kit/components'
import { VBox } from 'ui-kit/atoms/spacers'
import { OfdData as OfdDataType } from '../container/OfdConnector'

type Props = {
  data: OfdDataType
  onEdit: () => void
  onBlock: () => Promise<void>
}

const Wrapper = styled.div`
  padding-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Card = styled(ContentCard)`
  width: 540px;
`
const Item = styled.div`
  display: flex;
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
`
const Caption = styled(SubBodyText)`
  width: 158px;
`
const Description = styled(SubBodyText)``
const BlockInfo = styled.div`
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.pallete.surfaceBackgroundPrimary};
`
const Link = styled.a`
  display: flex;
  color: ${({ theme }) => theme.pallete.blue};
  text-decoration: none;
`

const Footer = styled(ContentCardFooter)`
  border-top: none;
  height: 56px;
  margin-bottom: 8px;
`

export const OfdData = ({ data, onBlock, onEdit }: Props) => {
  const [key, setKey] = useState(Date.now())
  const [isFetch, setFetching] = useState(false)

  const handleBlock = useCallback(async () => {
    setKey(Date.now())
    setFetching(true)
    await onBlock()
    setFetching(false)
  }, [onBlock])

  const isBlocked = data.blocked
  const dropdownData = useMemo(() => {
    return [
      {
        label: 'Редактировать',
        iconName: 'edit',
        function: () => {
          onEdit()
          setKey(Date.now())
        },
      },
      {
        label: isBlocked ? 'Включить' : 'Отключить',
        iconName: 'off',
        function: handleBlock,
      },
    ] as DropdownData[]
  }, [handleBlock, isBlocked, onEdit])

  return (
    <Wrapper>
      <Card>
        <ContentCardHeader title="Касса">
          {!isFetch && <Dropdown key={key} data={dropdownData} right />}
        </ContentCardHeader>
        {isFetch && (
          <ContentCardBody>
            <Loader />
          </ContentCardBody>
        )}
        {!isFetch && isBlocked && (
          <BlockInfo>
            <SubBodyText color="textPrimary">
              Касса отключена. Чеки не выдаются
            </SubBodyText>
            <TransparentButton
              onClick={handleBlock}
              textColor="accentPrimary"
              fontWeight="semi-bold"
            >
              Включить
            </TransparentButton>
          </BlockInfo>
        )}
        {!isFetch && (
          <>
            <ContentCardBody>
              <Item>
                <Caption fontWeight="medium" color="textDisabled">
                  Организация
                </Caption>
                <Description color="textDisabled">{data.name}</Description>
              </Item>
              <Item>
                <Caption fontWeight="medium" color="textDisabled">
                  ИНН
                </Caption>
                <Description color="textDisabled">{data.inn}</Description>
              </Item>
              <Item>
                <Caption fontWeight="medium" color="textDisabled">
                  КПП
                </Caption>
                <Description color="textDisabled">{data.kpp}</Description>
              </Item>
              <Item>
                <Caption fontWeight="medium" color="textDisabled">
                  Налогообложение
                </Caption>
                <Description color="textDisabled">{data.snoName}</Description>
              </Item>
            </ContentCardBody>
            <Footer>
              <Link
                href="https://online.atol.ru/lk/ui/#/checkJournal/"
                target="_blank"
              >
                <Icon name="link" color="blue" />
                <VBox x={2 / 3} />
                <SubBodyText fontWeight="semi-bold" color="blue">
                  Журнал выданых чеков
                </SubBodyText>
              </Link>
            </Footer>
          </>
        )}
      </Card>
    </Wrapper>
  )
}
