import React from 'react'

import { Icon, SubBodyText } from 'ui-kit/components'

import { ContentCard, ContentCardBody, HBox, Row, VBox } from 'shared/ui'
import { CurrencyText } from 'shared/ui/currencyText/components/currencyText'
import { useTheme } from 'styled-components'

interface Props {
  amount: number
}

export const Balance: React.FC<Props> = ({ amount }) => {
  const theme = useTheme()

  return (
    <ContentCard backgroundColor={theme.pallete.blue}>
      <ContentCardBody height={126}>
        <Row>
          <Icon name="ruble" backgroundColor="blueMuted" />
          <VBox x={0.5} />
          <SubBodyText color="white" fontWeight="medium">
            Баланс:
          </SubBodyText>
        </Row>
        <HBox />
        <Row>
          <CurrencyText
            amount={amount}
            scale={150}
            color="white"
            fontWeight="semi-bold"
            explicitNegativeSign
          />
        </Row>
      </ContentCardBody>
    </ContentCard>
  )
}
