import React from 'react'
import styled from 'styled-components'

import { IconNames } from 'ui-kit'
import { Icon } from 'ui-kit/components'

export const Button = styled.div<{ disabled?: boolean }>`
  display: inline-block;
  width: 24px;
  height: 24px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  .muk-pagination-button-icon {
    width: 100% !important;
    height: 100% !important;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

    svg {
      path {
        fill: ${({ disabled, theme }) =>
          disabled ? theme.pallete.gray5 : theme.pallete.blue};
      }
    }
  }
`

type Props = {
  icon: IconNames
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
}

export const PaginationArrow: React.FC<Props> = ({
  icon,
  disabled,
  onClick,
}) => (
  <Button disabled={disabled} onClick={onClick}>
    <Icon className="muk-pagination-button-icon" name={icon} />
  </Button>
)
