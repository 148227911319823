export enum TariffTransactionType {
  TopUp = 'topup',
  Withdraw = 'withdraw',
}

export enum TariffTransactionStatus {
  Authorized = 'authorized',
  Pending = 'pending',
  Acknowledged = 'acknowledged',
  Processing = 'processing',
  Succeeded = 'succeeded',
  Declined = 'declined',
  Failed = 'failed',
  NotCompleted = 'not_completed',
}

export const tariffTransactionStatusToString = (
  value: TariffTransactionStatus
): string => {
  switch (value) {
    case TariffTransactionStatus.Authorized:
      return 'Разрешен'
    case TariffTransactionStatus.Pending:
      return 'Ожидает подтверждения'
    case TariffTransactionStatus.Acknowledged:
      return 'В очереди'
    case TariffTransactionStatus.Processing:
      return 'Обрабатывается'
    case TariffTransactionStatus.NotCompleted:
      return 'Не завершен'
    case TariffTransactionStatus.Succeeded:
      return 'Завершен'
    case TariffTransactionStatus.Declined:
      return 'Отклонен'
    case TariffTransactionStatus.Failed:
      return 'Ошибка'
    default:
      return value
  }
}
