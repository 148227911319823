import { createSelector } from 'reselect'
import { apiSelectors } from 'api'
import { mappedFuelDepositType } from 'api/fuel/deposit-history/types'
import { FuelHistory, TableData } from 'ui-kit/organisms/FuelTransactions'

export const selectFuelHistory = createSelector(
  apiSelectors.fuel.postDepositHistory.selectData,
  (fuelHistory) => {
    if (fuelHistory === null) {
      return null
    }
    const list = fuelHistory.history.map(
      (item) =>
        ({
          id: item.id,
          dateCreation: item.created_at,
          amount: {
            value: item.amount.amount,
            isPositive: item.is_positive,
          },
          description: item.description,
          depositType: mappedFuelDepositType[item.deposit_type],
        } as FuelHistory)
    )

    return {
      offset: fuelHistory.offset,
      limit: fuelHistory.limit,
      total: fuelHistory.total,
      list,
    } as TableData<FuelHistory>
  }
)
