import React from 'react'

import { Icon } from 'ui-kit/components'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: center;

  .muk-side-menu-small-logo {
    .muk-icon {
      width: 24px !important;
      height: 24px !important;
    }
  }
`

export const Logo: React.FC = () => (
  <Container>
    <Icon name="mozenWhite" className="muk-side-menu-small-logo" />
  </Container>
)
