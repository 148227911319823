import React, { useContext, useEffect, useRef } from 'react'
import Chart from 'chart.js'

import { StatisticsFees } from 'api/types'
import { deepEqual } from 'common/utils/helpers'
import { ThemeContext } from 'styled-components'

export interface StatisticsFeesChartData extends StatisticsFees {
  label_mozen_withdraw_fees_total: string
  label_ride_fees_total: string
}

type Props = {
  data: StatisticsFeesChartData[]
}

export const StatisticsFeesChart: React.FC<Props> = React.memo(({ data }) => {
  const chart = useRef<Chart>()
  const ctx = useRef<HTMLCanvasElement>(null)
  const theme = useContext(ThemeContext)

  const labels = [...data.map((item) => item.date)]
  const datasets = [
    {
      label: 'Комиссия за поездки',
      data: [...data.map((item) => item.ride_fees_total)],
      backgroundColor: theme.pallete.blueDataSet,
    },
    {
      label: 'Комиссия за вывод средств',
      data: [...data.map((item) => item.mozen_withdraw_fees_total)],
      backgroundColor: theme.pallete.yellowDataSet,
    },
  ]

  const initialRender = useRef(true)
  useEffect(() => {
    if (initialRender.current && ctx.current) {
      initialRender.current = false
      chart.current = new Chart(ctx.current, {
        type: 'bar',
        data: {
          labels,
          datasets,
        },
        options: {
          title: {
            display: false,
            text: 'Комиссия автопарка',
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          legend: {
            position: 'bottom',
          },
          responsive: true,
          scales: {
            xAxes: [
              {
                stacked: true,
              },
            ],
            yAxes: [
              {
                stacked: true,
              },
            ],
          },
        },
      })
      return
    }

    if (chart.current) {
      chart.current.data.labels = labels
      chart.current.data.datasets = datasets
      chart.current.update()
    }
  })

  return <canvas ref={ctx} />
}, deepEqual)
