import React from 'react'

import { LargeFilter as LargeFilterType } from 'common/types/local/filter'
import { LargeFilter, Search } from 'shared/table'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  padding-right: 8px;
`

interface Props {
  search: string
  searchLoading: boolean
  filterList: string[]
  onClearFilter: () => void
  onSearch: (value: string) => void
  filterData: LargeFilterType[]
  onApplyFilter: (filters: string[]) => void
}

export const Heading: React.FC<Props> = ({
  search,
  searchLoading,
  filterList,
  onClearFilter,
  onSearch,
  filterData,
  onApplyFilter,
}) => (
  <Container>
    <Search value={search} onSetSearch={onSearch} isLoading={searchLoading} />
    <LargeFilter
      filters={filterData}
      onClear={onClearFilter}
      onApply={onApplyFilter}
      initialValues={filterList}
    />
  </Container>
)
