import React from 'react'

import { AggregatorType } from 'common/enums'
import { Dropdown } from 'shared/ui'

type Props = {
  right?: boolean
  openAddAggregatorModal: (type: AggregatorType) => void
}

export const AddAggregatorDropdown: React.FC<Props> = ({
  right,
  openAddAggregatorModal,
}) => (
  <Dropdown
    data={[
      {
        label: 'Яндекс.Такси/Uber',
        iconName: 'yandex',
        function: () => openAddAggregatorModal(AggregatorType.Yandex),
      },
      {
        label: 'Ситимобил',
        iconName: 'citymobil',
        function: () => openAddAggregatorModal(AggregatorType.Citymobil),
      },
      // {
      //   label: 'DiDi',
      //   iconName: 'DiDi',
      //   function: () => openAddAggregatorModal(AggregatorType.DiDi),
      // },
    ]}
    right={right}
  >
    Добавить аккаунт
  </Dropdown>
)
