import { ThunkAction } from 'redux-thunk'
import { State } from 'reducers'
import axios, { AxiosResponse } from 'axios'
import { FailureError } from 'common/utils/reduxApiFactory'
import { TApiError } from 'api/types'
import { getFuelSummaryActions } from 'api/fuel/summary/get/actions'
import { GetFuelSummaryActions } from 'api/fuel/summary/get/types'
import { FuelSummary } from 'api/fuel/summary/types'
import { selectFuelPermission } from 'api/employee/profile/get/selectors'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  GetFuelSummaryActions
>

export const getFuelSummaryRequest = (): THandleRequest => async (
  dispatch,
  getState
) => {
  const state = getState()
  const accessAllowed = selectFuelPermission(state)
  if (!accessAllowed) {
    return
  }

  const { start, update, success, failure } = getFuelSummaryActions
  dispatch(start())
  try {
    const response: AxiosResponse<FuelSummary> = await axios.get(
      `erp/1/fuel/summary`
    )
    dispatch(update(response.data))
    dispatch(success())
  } catch (e) {
    if (e.response) {
      const data = e.response.data as TApiError
      const error: FailureError = new Error(data.message)
      error.data = data
      dispatch(failure(error))
      throw error
    }
  }
}
