import { ThunkAction } from 'redux-thunk'
import { State } from 'reducers'
import axios, { AxiosResponse } from 'axios'
import { FailureError } from 'common/utils/reduxApiFactory'
import { TApiError } from 'api/types'
import { getIncomeReportActions as actions } from 'api/park/income-report/get/actions'
import {
  GetIncomeReportParams,
  GetIncomeReportActions,
} from 'api/park/income-report/get/types'
import { IncomeReport } from 'api/park/income-report/types'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  GetIncomeReportActions
>

export const getIncomeReportRequest = (
  params: GetIncomeReportParams
): THandleRequest => async (dispatch) => {
  dispatch(actions.start())
  try {
    const response: AxiosResponse<IncomeReport> = await axios.get(
      'erp/1/park/income-report',
      {
        params,
      }
    )
    dispatch(actions.update(response.data))
    dispatch(actions.success())
  } catch (e) {
    if (e.response) {
      const data = e.response.data as TApiError
      const error: FailureError = new Error(data.message)
      error.data = data
      dispatch(actions.failure(error))
      throw error
    }
  }
}
