import { connect } from 'react-redux'

import { State } from 'reducers'
import { apiSelectors } from 'api'
import { MyThunkDispatch } from 'api/reducer'
import { modalsActions } from 'shared/modals'
import { ModalsNames } from 'common/enums'
import { onSaveStatus } from 'employees/features/employeeInfo/thunks'
import { BlockEmployeeModal } from 'employees/features/employeeInfo/pages'

const mapStateToProps = (state: State) => ({
  isFetching: apiSelectors.employees.employeesByIdStatus.getIsFetching(state),
  isBlocked: apiSelectors.employees.employeesByIdGet.getIsBlocked(state),
  id: apiSelectors.employees.employeesByIdGet.getId(state),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  onSave: ({ id, status }: any) => dispatch(onSaveStatus({ id, status })),
  closeModal: () =>
    dispatch(modalsActions.closeModal(ModalsNames.BlockEmployee)),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const BlockEmployeeModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BlockEmployeeModal)
