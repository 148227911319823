import { connect } from 'react-redux'

import { State } from 'reducers'
import { apiSelectors } from 'api'
import { MyThunkDispatch } from 'api/reducer'
import { onDidMount } from 'employees/features/employeesMain/thunks'
import { getIsFetching } from 'employees/features/employeesMain/selectors'
import { EmployeesMain } from 'employees/features/employeesMain/organisms'

const mapStateToProps = (state: State) => ({
  canViewLogs: apiSelectors.employee.profile.getCanViewLogs(state),
  isFetching: getIsFetching(state),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  onDidMount: () => dispatch(onDidMount()),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const EmployeesMainContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeesMain)
