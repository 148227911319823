import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Alert from 'react-s-alert'
import {
  FormErrors,
  InjectedFormProps,
  reduxForm,
  SubmissionError,
} from 'redux-form'

import { BankType, FormNames } from 'common/enums'

import {
  CancelChangesModal,
  ContentCard,
  ContentCardHeader,
} from 'shared/ui/index'

import { Col, Row as DefaultRow } from 'shared/ui/flex'
import { VBox } from 'shared/ui/spacers'

import { FormSubmitHandler } from 'redux-form/lib/reduxForm'
import {
  PaymentSettingsFields,
  PaymentSettingsFuelFields,
} from 'shared/ui/paymentSettingsEditor/molecules'
import { validate } from 'shared/ui/paymentSettingsEditor/organisms/PaymentSettingsForm/validate'
import { TransparentButton } from 'ui-kit/components'
import styled from 'styled-components'

type HeaderProps = {
  withBorderRadius?: boolean
}
const Header = styled(ContentCardHeader)<HeaderProps>`
  background-color: #fff;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  box-shadow: 0 3px 10px rgba(192, 206, 226, 0.5),
    0 0 4px rgba(192, 206, 226, 0.2);

  ${({ withBorderRadius }) =>
    withBorderRadius &&
    `
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    `}
`
type OwnProps = {
  bankType: BankType | null
  isLocked?: boolean
  isAllowNonResident?: boolean
  isFuelEnabled?: boolean
  canChangePaymentsConditions?: boolean
  hasAntiFraud?: boolean
  onSubmit: (values: FormProps) => Promise<void>
  onChangePristine?: (isPristine: boolean) => void
  withActions?: boolean
}

const Row = styled(DefaultRow)`
  align-items: flex-start;
`

const ActionBottomPanel = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 240px;
  z-index: 10;
  display: flex;
  padding: 8px 24px;
  height: 72px;
  justify-content: flex-end;
  align-items: center;
  background-color: ${({ theme }) => theme.pallete.blue};

  @media (max-width: 1025px) {
    left: 72px;
  }
`

const Wrapper = styled.div<{ withPaddingBottom?: boolean }>`
  padding-bottom: ${({ withPaddingBottom }) => withPaddingBottom && '72px'};
`

type Props = InjectedFormProps<FormProps, OwnProps> & OwnProps
const PaymentSettingForm = ({
  bankType,
  pristine,
  submitting,
  handleSubmit: handleFormSubmit,
  reset,
  isLocked,
  canChangePaymentsConditions,
  isAllowNonResident,
  isFuelEnabled,
  onSubmit,
  initialize,
  hasAntiFraud,
  onChangePristine,
  withActions = false,
}: Props) => {
  const initialRender = useRef(true)

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false
      return
    }
    if (onChangePristine) {
      onChangePristine(pristine)
    }
  }, [onChangePristine, pristine])

  const [isCancelChangesModalOpened, setIsCancelChangesModalOpened] = useState(
    false
  )
  const handleCloseModal = useCallback(
    (isCancelled: boolean) => {
      if (isCancelled) {
        reset()
        if (onChangePristine) {
          onChangePristine(true)
        }
      }
      setIsCancelChangesModalOpened(false)
    },
    [onChangePristine, reset]
  )
  const handleSubmit = useMemo(() => {
    const submit: FormSubmitHandler<FormProps> = async (values) => {
      try {
        await onSubmit(values)
        initialize(values)
      } catch (e) {
        throw new SubmissionError(e)
      }
    }

    return handleFormSubmit(submit)
  }, [handleFormSubmit, initialize, onSubmit])

  const handleCancel = useCallback(() => {
    setIsCancelChangesModalOpened(true)
  }, [])

  const isActionsVisible = !pristine || withActions

  return (
    <Wrapper withPaddingBottom={isActionsVisible}>
      <Row>
        <Col percentage={50}>
          <ContentCard backgroundColor="transparent" boxShadow="none">
            <Header title="Условия" withBorderRadius={hasAntiFraud} />
            <PaymentSettingsFields
              bankType={bankType}
              isLocked={isLocked}
              isAllowNonResident={isAllowNonResident}
              canChangePaymentsConditions={canChangePaymentsConditions}
              hasAntiFraud={hasAntiFraud}
            />
          </ContentCard>
        </Col>
        <VBox />
        {isFuelEnabled && (
          <Col percentage={50}>
            <ContentCard backgroundColor="transparent" boxShadow="none">
              <Header title="Условия" />
              <PaymentSettingsFuelFields
                isLocked={isLocked}
                canChangePaymentsConditions={canChangePaymentsConditions}
              />
            </ContentCard>
          </Col>
        )}
      </Row>

      {isCancelChangesModalOpened ? (
        <CancelChangesModal onClose={handleCloseModal} />
      ) : null}

      {isActionsVisible && (
        <ActionBottomPanel>
          <TransparentButton
            opacity={0.1}
            iconName="block"
            fontWeight="semi-bold"
            textColor="white"
            onClick={handleCancel}
          >
            Отменить
          </TransparentButton>
          <VBox />
          <TransparentButton
            opacity={0.1}
            iconName="save"
            fontWeight="semi-bold"
            textColor="white"
            isLoading={submitting}
            onClick={handleSubmit}
          >
            Сохранить
          </TransparentButton>
        </ActionBottomPanel>
      )}
    </Wrapper>
  )
}

export type FormProps = {
  id: string
  fair_rides: string
  withdraw_transactions_for_auto_withdraw: string
  free_withdraws_count: string
  min_commission: string
  withdraw_commission: string
  non_resident_withdraw_commission: string
  non_resident_min_commission: string
  min_withdraw_amount: string
  max_withdraw_amount: string | null
  min_balance: string
  day_withdraw_limit: string | null
  week_withdraw_limit: string | null
  fuel_card_max_topup_amount: string
  fuel_card_min_topup_amount: string
}

export default reduxForm<FormProps, OwnProps>({
  form: FormNames.PaymentsSettings,
  enableReinitialize: true,
  validate,
  onSubmitSuccess() {
    Alert.info('Изменения сохранены')
  },
  onSubmitFail(errors: FormErrors<FormProps> | undefined) {
    if (!errors) return

    const firstError = Object.keys(errors)[0]
    const root = document.querySelector(`[id="root"]`)
    const el = document.querySelector(`[name="${firstError}"]`)

    if (el && root) {
      const position =
        el.getBoundingClientRect().top + document.documentElement.scrollTop

      const offset = -200

      root.scrollTo({ top: position - offset, behavior: 'smooth' })
    }

    Alert.error('Ошибка', {
      customFields: {
        text: 'Не удалось сохранить',
      },
    })
  },
})(PaymentSettingForm)
