import React from 'react'

import { RejectResolveButtons } from 'ui-kit/components'

import { Modal } from 'shared/ui'

import { ContainerProps } from 'drivers/features/driverInfo/containers/DeleteDriverContractModalContainer'

export const DeleteDriverContractModal: React.FC<ContainerProps> = ({
  isFetching,
  driverId,
  fetchDelete,
  closeModal,
}) => {
  const handleResolve = async () => {
    try {
      if (driverId) {
        await fetchDelete({ driverId })
      }
    } catch (e) {
      console.error(e)
    }
  }
  return (
    <Modal
      open
      onClose={closeModal}
      closeButton
      title="Удалить договор?"
      width={528}
      withMinHeight={false}
    >
      <RejectResolveButtons
        fullWidth
        onResolve={handleResolve}
        onReject={closeModal}
        disableReject={isFetching}
        disableResolve={isFetching}
        isLoading={isFetching}
        resolveText="Удалить"
        resolveBgColor="red"
      />
    </Modal>
  )
}
