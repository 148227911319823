import React from 'react'
import { Icon as DefaultIcon } from 'ui-kit/components'
import { Select as DefaultSelect } from 'shared/ui/select'
import { Option } from 'common/types/local/option'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(192, 206, 226, 0.5),
    0 0 4px rgba(192, 206, 226, 0.2);
`
const Icon = styled(DefaultIcon)`
  padding-right: 16px;
`
const GroupConverter = styled.button`
  display: flex;
  appearance: none;
  border: none;
  background-color: #f4f8ff;
  width: 100%;
  color: #2979ff;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  line-height: 36px;
  font-weight: 600;
  cursor: pointer;
  padding: 18px 16px 10px;
  margin-top: -8px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  align-items: center;
`
const Select = styled(DefaultSelect)`
  .react-select__control {
    padding: 10px 16px;
    border-radius: 10px;

    .react-select__value-container {
      height: 36px;
    }

    &--menu-is-open {
      .select-chevron {
        transform: rotate(90deg);
      }
    }
  }

  .react-select__menu {
    border-radius: 10px;
    margin-top: 8px;

    .react-select__menu-list {
      padding-top: 8px;
      padding-bottom: 8px;

      & > *:hover {
        background-color: rgba(234, 238, 247, 0.3);
      }
    }
  }
`

type Props = {
  onChange: (option: Option<string>) => void
  options: Option<string>[]
  active?: Option<string>
  onCreateGroupSettings?: () => void
}
export const DriverSettingsSelection = React.memo<Props>(
  ({ onChange, onCreateGroupSettings, options, active }) => {
    const isGroupConverterVisible =
      onCreateGroupSettings && active && active.label === 'Индивидуальные'

    return (
      <Wrapper>
        <Select
          onChange={onChange}
          name="payment-settings-name"
          value={active || options[0]}
          options={options}
        />
        {isGroupConverterVisible && (
          <GroupConverter onClick={onCreateGroupSettings}>
            <Icon name="group" />
            Сохранить как настройку группы
          </GroupConverter>
        )}
      </Wrapper>
    )
  }
)

DriverSettingsSelection.displayName = 'DriverSettingsSelection'
