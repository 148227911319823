import { Action } from 'redux-actions'

import { QiwiDetailsResponse } from 'api/types'

export type TUpdate = Action<QiwiDetailsResponse>
export type TStart = Action<unknown>
export type TSuccess = Action<unknown>
export type TFailure = Action<{ error: any }>
export type TReset = Action<unknown>
export type TResetStatus = Action<unknown>

export const UPDATE: TUpdate['type'] = 'api/park/qiwi/details->UPDATE'
export const START: TStart['type'] = 'api/park/qiwi/details->START'
export const SUCCESS: TSuccess['type'] = 'api/park/qiwi/details->SUCCESS'
export const FAILURE: TFailure['type'] = 'api/park/qiwi/details->FAILURE'
export const RESET: TReset['type'] = 'api/park/qiwi/details->RESET'
export const RESET_STATUS: TResetStatus['type'] =
  'api/park/qiwi/details->RESET_STATUS'

export type TQiwiDetailsAction =
  | TUpdate
  | TStart
  | TSuccess
  | TFailure
  | TReset
  | TResetStatus

export const update = (data: QiwiDetailsResponse): TUpdate => ({
  type: UPDATE,
  payload: data,
})
export const start = () => ({
  type: START,
})
export const success = () => ({
  type: SUCCESS,
})
export const failure = (error: any): TFailure => ({
  type: FAILURE,
  payload: error,
})
export const reset = () => ({
  type: RESET,
})
export const resetStatus = () => ({
  type: RESET_STATUS,
})

export type TPayloads = Pick<TUpdate & TFailure, 'payload'>['payload']

export default {
  reset,
}
