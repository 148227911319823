import { createSelector } from 'reselect'
import { State } from 'reducers'

export const getIsFetching = (state: State) =>
  state.api.park.notificationsGet.status === 'loading'

const getData = (state: State) => state.api.park.notificationsGet.data

export const getContactEmail = createSelector(getData, (data) =>
  data ? data.contact_email : null
)

export const getIsNotificationsAvailable = createSelector(getData, (data) =>
  Boolean(
    data && Object.values(data).some(Boolean)
  )
)

export const getNotificationsSettings = createSelector(
  getData,
  (data) => data || null
)

export const getNotificationsQiwiSettings = createSelector(getData, (data) =>
  data ? data.qiwi_settings : null
)

export const selectTelegramBotEnabledStatus = (state: State) =>
  state.api.park.notificationsGet.data?.telegram_notifications_enabled
