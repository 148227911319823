import React from 'react'
import { Switch } from 'react-router-dom'

import { RoutesPath } from 'common/enums'
import { PrivateRoute } from 'shared/router'
import { ContentHeader } from 'shared/ui'
import { DriversListContainer } from 'drivers/features/driversList'
import { DriversUploadContractsContainer } from 'drivers/features/driversUploadContracts'
import { DriverDetailsContainer } from 'drivers/features/driverDetails'
import { DriversToolbarContainer } from 'drivers/features/driversToolbar'
import { DriversNotification } from 'drivers/features/driversNotification'

import { ContainerProps } from 'drivers/containers/DriversContainer'
import { DriversReferralProgramContainer } from 'drivers/features/driversReferralProgram'

import { DriversTemplate } from '../../templates'

export const Drivers: React.FC<ContainerProps> = ({ match: { isExact } }) => {
  return (
    <DriversTemplate
      toolbar={
        isExact ? <ContentHeader toolbar={<DriversToolbarContainer />} /> : null
      }
    >
      <Switch>
        <PrivateRoute
          exact
          path={RoutesPath.Drivers}
          component={DriversListContainer}
        />
        <PrivateRoute
          exact
          path={RoutesPath.DriversUploadContracts}
          component={DriversUploadContractsContainer}
        />
        <PrivateRoute
          exact
          path={RoutesPath.DriversReferralProgram}
          component={DriversReferralProgramContainer}
        />
        <PrivateRoute
          path={RoutesPath.DriverDetails}
          component={DriverDetailsContainer}
        />
        <PrivateRoute
          path={RoutesPath.DriversNotification}
          component={DriversNotification}
        />
      </Switch>
    </DriversTemplate>
  )
}
