import { connect } from 'react-redux'

import { State } from 'reducers'
import { getIsNoticeVisible, getIsSidebarVisible } from 'layout/selectors'
import { onDidMount } from 'layout/thunks'
import { Layout } from 'layout/components'
import { MyThunkDispatch } from 'api/reducer'
import { apiSelectors } from 'api'
import { promoRequest } from 'api/promo/thunks'

const mapStateToProps = (state: State) => ({
  isSidebarVisible: getIsSidebarVisible(state),
  isNoticeVisible: getIsNoticeVisible(state),
  bannerMessage: apiSelectors.promo.selectData(state)?.message,
  bannerUrl: apiSelectors.promo.selectData(state)?.url,
  bannerBackgroundColor: apiSelectors.promo.selectData(state)?.background_color,
  bannerTextColor: apiSelectors.promo.selectData(state)?.text_color,
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  onDidMount: () => dispatch(onDidMount()),
  fetchBanner: (...args: Parameters<typeof promoRequest>) =>
    dispatch(promoRequest(...args)),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const LayoutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Layout)
