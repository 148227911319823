import React from 'react'
import { currencyToBase } from 'common/utils/formatters'
import { TableText } from 'ui-kit/components'

type Props = {
  amount: number
}
export const TransactionAmount = React.memo<Props>(({ amount }) => {
  return <TableText>{currencyToBase(amount)} ₽</TableText>
})

TransactionAmount.displayName = 'TransactionAmount'
