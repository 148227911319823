import React from 'react'
import ReactModal, { Props } from 'react-modal'
import styled from 'styled-components'

interface ModalAdapter extends Props {
  className?: string
  children?: React.ReactNode
  withMinHeight?: boolean
  withTransparent?: boolean
}

const ReactModalAdapter: React.FC<ModalAdapter> = ({
  className,
  children,
  withTransparent,
  ...rest
}) => (
  <ReactModal
    {...rest}
    className={`${className}-content`}
    overlayClassName={`${className}-overlay`}
  >
    <div className={withTransparent ? undefined : `${className}-content-wrap`}>
      {children}
    </div>
  </ReactModal>
)

export const ModalComponent = styled(ReactModalAdapter)<{ overflow?: string }>`
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(5, 16, 34, 0.5);
    z-index: 100;
    overflow: auto;
  }

  &-content {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    padding: 32px 0;
  }

  &-content-wrap {
    position: relative;
    overflow: ${(props) => props.overflow};
    outline: none;
    background-color: ${({ theme }) => theme.pallete.white};
    box-shadow: 0 6px 21px rgba(5, 16, 34, 0.17);
    border-radius: 10px;
    min-height: ${({ withMinHeight }) => withMinHeight && '200px'};
  }
`

export const Header = styled.div<{ type?: 'success' | 'error' | 'group' }>`
  display: flex;
  align-items: center;
  padding: ${({ type }) => (type ? '16px' : '8px 16px 16px')};
  height: 56px;
  border-bottom: 1px solid ${({ theme }) => theme.pallete.gray3};
  border-top: 8px solid
    ${({ theme, type }) => {
      if (type === 'success') {
        return theme.pallete.green
      }
      if (type === 'error') {
        return theme.pallete.red
      }

      return 'transparent'
    }};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`
