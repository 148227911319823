import styled from 'styled-components'

export const ColumnWrapper = styled.div<{ sortable: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .sort-icon {
    ${({ sortable }) =>
      sortable &&
      `
    cursor: pointer;
  `}
  }
`
