import { createApiActions } from 'common/utils/reduxApiFactory'
import { DriverPaymentSettingsParams } from 'api/driversById/payment-settings/types'
import { PaymentSettings } from 'api/types'

export const getDriverPaymentSettingsActions = createApiActions<
  PaymentSettings,
  DriverPaymentSettingsParams
>('erp/driversById/payment-settings/get')

export const postDriverPaymentSettingsActions = createApiActions<
  PaymentSettings,
  DriverPaymentSettingsParams
>('erp/driversById/payment-settings/post')

export const patchDriverPaymentSettingsActions = createApiActions<
  PaymentSettings,
  DriverPaymentSettingsParams
>('erp/driversById/payment-settings/patch')

export const deleteDriverPaymentSettingsActions = createApiActions<
  PaymentSettings,
  DriverPaymentSettingsParams
>('erp/driversById/payment-settings/delete')
