import { connect } from 'react-redux'

import { State } from 'reducers'
import { apiSelectors } from 'api'
import { Deposit } from 'layout/features/sidebar/organisms'

const mapStateToProps = (state: State) => ({
  isFetching: apiSelectors.park.details.getIsFetching(state),
  balance: apiSelectors.park.details.getDepositBalance(state),
  fuelSummary: apiSelectors.fuel.getFuelSummary.selectData(state),
})

export type ContainerProps = ReturnType<typeof mapStateToProps>

export const DepositContainer = connect(mapStateToProps)(Deposit)
