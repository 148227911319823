import React from 'react'

import {
  ContentCard as DefaultContentCard,
  ContentCardBody,
} from 'shared/ui/contentCard'
import { CaptionText, Icon, SubBodyText } from 'ui-kit/components'
import { Col, Row } from 'shared/ui/flex'
import { Loader } from 'shared/ui/loader'
import styled, { css } from 'styled-components'

type Props = {
  name: string
  onClick: () => void
  isLoading: boolean
}
const clickableStyle = css`
  cursor: pointer;
  &:hover {
    background-color: #f4f8ff;
  }
`

const ContentCard = styled(DefaultContentCard)<{ isClickable: boolean }>`
  ${({ isClickable }) => isClickable && clickableStyle}
`

const Wrapper = styled.div``

export const PaymentSettingsName: React.FC<Props> = ({
  name,
  onClick,
  isLoading,
}) => {
  return (
    <Wrapper onClick={isLoading ? undefined : onClick}>
      <ContentCard withMinHeight={false} isClickable={!isLoading}>
        <ContentCardBody>
          <Row>
            <Col percentage={100}>
              <CaptionText color="gray6">Название группы</CaptionText>
              <SubBodyText>{name}</SubBodyText>
            </Col>
            <Col>
              {isLoading ? <Loader /> : <Icon color="blue" name="edit" />}
            </Col>
          </Row>
        </ContentCardBody>
      </ContentCard>
    </Wrapper>
  )
}
