import { createApiActions } from 'common/utils/reduxApiFactory'
import { StatisticsFeesParams } from 'api/types'
import { StatisticsFeesData } from 'api/park/statistics/fees/reducer'

export const actions = createApiActions<
  StatisticsFeesData,
  StatisticsFeesParams
>('park/statistics/fees')

export type Actions = ReturnType<typeof actions[keyof typeof actions]>
