import { connect } from 'react-redux'

import { State } from 'reducers'
import { apiActions, apiSelectors } from 'api'
import { MyThunkDispatch } from 'api/reducer'
import { ModalsNames } from 'common/enums'
import { modalsActions, modalsSelectors } from 'shared/modals'
import { AllList } from 'payments/features/allList/organisms'

const mapStateToProps = (state: State) => ({
  isFetching: apiSelectors.park.transactions.getIsFetching(state),
  transactions: apiSelectors.park.transactions.getTransactions(state),
  totalAmount: apiSelectors.park.transactions.getTotalAmount(state),
  canViewTransactionDetails: apiSelectors.employee.profile.getCanViewTransactionDetails(
    state
  ),
  isPaymentDetailsModalOpened: modalsSelectors.getIsModalOpened(
    state,
    ModalsNames.PaymentDetails
  ),
  modalData: modalsSelectors.getData(state, ModalsNames.PaymentDetails),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  fetchTransactions: ({ params }: any) =>
    dispatch(apiActions.park.transactions.handleRequest({ params })),
  openPaymentDetailsModal: () =>
    dispatch(modalsActions.openModal(ModalsNames.PaymentDetails)),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const AllListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AllList)
