import React from 'react'
import { TableText } from 'ui-kit/components'
import { FormatActions } from 'payments/features/confirmationList/molecules/FormatActions'
import { PaymentStatus, paymentStatusToString } from 'common/enums/payments'
import styled from 'styled-components'

export const StatusRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 27px;
`

type Props = {
  id: string
  status: PaymentStatus
  onConfirmPayment: (isConfirmed: boolean, id: string) => void
  canProcessTransactions?: boolean
}
export const TransactionStatus = React.memo<Props>(
  ({ id, status, onConfirmPayment, canProcessTransactions }) => {
    const statusText = React.useMemo(() => {
      return paymentStatusToString(status)
    }, [status])
    return (
      <StatusRow>
        <TableText>{statusText}</TableText>
        {canProcessTransactions && (
          <FormatActions id={id} onConfirmPayment={onConfirmPayment} />
        )}
      </StatusRow>
    )
  }
)

TransactionStatus.displayName = 'TransactionStatus'
