import { ThunkAction } from 'redux-thunk'
import { State } from 'reducers'
import axios from 'axios'
import { FailureError } from 'common/utils/reduxApiFactory'
import { TApiError } from 'api/types'
import { postSubscriptionDepositAutoTopupActions } from './actions'
import { PostSubscriptionDepositAutoTopupActions } from './types'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  PostSubscriptionDepositAutoTopupActions
>

export const postSubscriptionDepositAutoTopupRequest = (
  isEnabled: boolean
): THandleRequest => async (dispatch) => {
  const { start, success, failure } = postSubscriptionDepositAutoTopupActions
  dispatch(start())
  try {
    await axios.post(`erp/1/park/subscription/deposit/auto-topup`, {
      auto_topup_deposit: isEnabled,
    })
    dispatch(success())
  } catch (e) {
    if (e.response) {
      const data = e.response.data as TApiError
      const error: FailureError = new Error(data.message)
      error.data = data
      dispatch(failure(error))
      throw error
    }
  }
}
