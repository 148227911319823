import { Action } from 'redux-actions'

import { SuspectTransactionsResponse } from 'common/types/api/responses/antifraud'

type SetTransactionsPayload = SuspectTransactionsResponse
export type SET_TRANSACTIONS = Action<SetTransactionsPayload>
export const SET_TRANSACTIONS: SET_TRANSACTIONS['type'] =
  'anti-fraud/suspect/SET_TRANSACTIONS'
export const setTransactions = (
  transactions: SetTransactionsPayload
): SET_TRANSACTIONS => ({
  type: SET_TRANSACTIONS,
  payload: transactions,
})

export type SET_TRANSACTIONS_PAGE = Action<number>
export const SET_TRANSACTIONS_PAGE: SET_TRANSACTIONS_PAGE['type'] =
  'anti-fraud/suspect/SET_TRANSACTIONS_PAGE'
export const setTransactionsPage = (page: number): SET_TRANSACTIONS_PAGE => ({
  type: SET_TRANSACTIONS_PAGE,
  payload: page,
})

export type SET_TRANSACTIONS_DATE_FROM = Action<Date>
export const SET_TRANSACTIONS_DATE_FROM: SET_TRANSACTIONS_DATE_FROM['type'] =
  'anti-fraud/suspect/SET_TRANSACTIONS_DATE_FROM'
export const setTransactionsDateFrom = (
  date: Date
): SET_TRANSACTIONS_DATE_FROM => ({
  type: SET_TRANSACTIONS_DATE_FROM,
  payload: date,
})

export type SET_TRANSACTIONS_DATE_TO = Action<Date>
export const SET_TRANSACTIONS_DATE_TO: SET_TRANSACTIONS_DATE_TO['type'] =
  'anti-fraud/suspect/SET_TRANSACTIONS_DATE_TO'
export const setTransactionsDateTo = (
  date: Date
): SET_TRANSACTIONS_DATE_TO => ({
  type: SET_TRANSACTIONS_DATE_TO,
  payload: date,
})

export type SET_TRANSACTIONS_SEARCH = Action<string>
export const SET_TRANSACTIONS_SEARCH: SET_TRANSACTIONS_SEARCH['type'] =
  'anti-fraud/suspect/SET_TRANSACTIONS_SEARCH'
export const setTransactionsSearch = (
  searchValue: string
): SET_TRANSACTIONS_SEARCH => ({
  type: SET_TRANSACTIONS_SEARCH,
  payload: searchValue,
})

export type SET_TRANSACTIONS_FILTER = Action<string[]>
export const SET_TRANSACTIONS_FILTER: SET_TRANSACTIONS_FILTER['type'] =
  'anti-fraud/suspect/SET_TRANSACTIONS_FILTER'
export const setTransactionsFilter = (
  filters: string[]
): SET_TRANSACTIONS_FILTER => ({
  type: SET_TRANSACTIONS_FILTER,
  payload: filters,
})

export type Payloads = Pick<
  SET_TRANSACTIONS &
    SET_TRANSACTIONS_SEARCH &
    SET_TRANSACTIONS_PAGE &
    SET_TRANSACTIONS_FILTER &
    SET_TRANSACTIONS_DATE_TO &
    SET_TRANSACTIONS_DATE_FROM,
  'payload'
>['payload']
