import React from 'react'
import styled from 'styled-components'
import { currencyToBase } from 'common/utils/formatters'
import { HBox } from 'ui-kit/atoms/spacers'
import IncomeInfoButton from 'ui-kit/molecules/IncomeInfoButton'
import Typography from 'ui-kit/typography'

type IncomePlatformCommission = {
  perOrder: number
  perNDS: number
  perBonus: number
}

type IncomeWriteOffs = {
  total: number
  platformCommission: IncomePlatformCommission
  gasStation: number
  callCenter: number
  subscription: number
  subscriptionNDS: number
  childSeatRent: number
  childSeatRentNDS: number
}

type IncomeAccruals = {
  total: number
  cardPayment: number
  cardFleetPayment: number
  compensation: number
  discountCompensation: number
  promotionalCode: number
  corporatePayment: number
  bonus: number
  tips: number
}

export type IncomeDataSet = {
  yandexTransfers: number
  accruals: IncomeAccruals
  writeOffs: IncomeWriteOffs
}

type Props = {
  data: IncomeDataSet
  onClick: () => Promise<void>
}

const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 4px;
`

const Header = styled(Item)`
  align-items: flex-end;
  margin-top: 0;
  margin-bottom: 16px;
  min-height: 40px;
`

const Description = styled.div`
  border-radius: 8px;
  background-color: ${({ theme }) => theme.pallete.secondaryBG};
  padding: 16px;
  margin-bottom: 16px;
`

const DescriptionTitle = styled(Typography.withComponent('h3')).attrs({
  variant: 'Caption3',
})`
  margin: 0 0 8px;
`

const Title = styled(Item)`
  margin-top: 16px;
`

const ShiftItem = styled(Item)`
  margin-left: 24px;
`

const BlockItem = styled.div`
  margin-bottom: 8px;
`

export const IncomeInfo = ({ data, onClick }: Props) => {
  return (
    <>
      <Header>
        <Typography fontType="Table" fontSize={18} fontWeight="SemiBold">
          Яндекс перечислит
        </Typography>
        <Typography fontType="Table" fontSize={18} fontWeight="SemiBold">
          {currencyToBase(data.yandexTransfers, true)}
        </Typography>
      </Header>
      <Description>
        <DescriptionTitle>Точный расчет по запросу</DescriptionTitle>
        <Typography variant="Caption1">
          Отчет составлен для ознакомления и может содержать незначительные
          расхождения с реальными данными.
        </Typography>
        <HBox x={1 / 3} />
        <Typography variant="Caption1">
          Сделаем бухгалтерскую сверку по запросу.
        </Typography>
        <HBox x={2 / 3} />
        <IncomeInfoButton onClick={onClick} />
      </Description>
      <Title>
        <Typography fontType="Table" fontSize={14} fontWeight="Bold">
          Начисления
        </Typography>
        <Typography fontType="Table" fontSize={14} fontWeight="Bold">
          {currencyToBase(data.accruals.total, true)}
        </Typography>
      </Title>
      <Item>
        <Typography fontType="Table" fontSize={14}>
          Оплата картой
        </Typography>
        <Typography fontType="Table" fontSize={14}>
          {currencyToBase(data.accruals.cardPayment, true)}
        </Typography>
      </Item>
      <Item>
        <Typography fontType="Table" fontSize={14}>
          Оплата картой (заказы автопарка)
        </Typography>
        <Typography fontType="Table" fontSize={14}>
          {currencyToBase(data.accruals.cardFleetPayment, true)}
        </Typography>
      </Item>
      <Item>
        <Typography fontType="Table" fontSize={14}>
          Компенсация
        </Typography>
        <Typography fontType="Table" fontSize={14}>
          {currencyToBase(data.accruals.compensation, true)}
        </Typography>
      </Item>
      <Item>
        <Typography fontType="Table" fontSize={14}>
          Компенсация скидки
        </Typography>
        <Typography fontType="Table" fontSize={14}>
          {currencyToBase(data.accruals.discountCompensation, true)}
        </Typography>
      </Item>
      <Item>
        <Typography fontType="Table" fontSize={14}>
          Промокод
        </Typography>
        <Typography fontType="Table" fontSize={14}>
          {currencyToBase(data.accruals.promotionalCode, true)}
        </Typography>
      </Item>
      <Item>
        <Typography fontType="Table" fontSize={14}>
          Корпоративная оплата
        </Typography>
        <Typography fontType="Table" fontSize={14}>
          {currencyToBase(data.accruals.corporatePayment, true)}
        </Typography>
      </Item>
      <Item>
        <Typography fontType="Table" fontSize={14}>
          Бонус
        </Typography>
        <Typography fontType="Table" fontSize={14}>
          {currencyToBase(data.accruals.bonus, true)}
        </Typography>
      </Item>
      <Item>
        <Typography fontType="Table" fontSize={14}>
          Чаевые
        </Typography>
        <Typography fontType="Table" fontSize={14}>
          {currencyToBase(data.accruals.tips, true)}
        </Typography>
      </Item>
      <Title>
        <Typography fontType="Table" fontSize={14} fontWeight="Bold">
          Списания
        </Typography>
        <Typography fontType="Table" fontSize={14} fontWeight="Bold">
          {currencyToBase(data.writeOffs.total, true)}
        </Typography>
      </Title>
      <BlockItem>
        <Item>
          <Typography fontType="Table" fontSize={14}>
            Комиссия платформы
          </Typography>
        </Item>
        <ShiftItem>
          <Typography fontType="Table" fontSize={14}>
            за заказ
          </Typography>
          <Typography fontType="Table" fontSize={14}>
            {currencyToBase(data.writeOffs.platformCommission.perOrder, true)}
          </Typography>
        </ShiftItem>
        <ShiftItem>
          <Typography fontType="Table" fontSize={14}>
            за заказ, НДС
          </Typography>
          <Typography fontType="Table" fontSize={14}>
            {currencyToBase(data.writeOffs.platformCommission.perNDS, true)}
          </Typography>
        </ShiftItem>
        <ShiftItem>
          <Typography fontType="Table" fontSize={14}>
            за бонус
          </Typography>
          <Typography fontType="Table" fontSize={14}>
            {currencyToBase(data.writeOffs.platformCommission.perBonus, true)}
          </Typography>
        </ShiftItem>
      </BlockItem>
      <Item>
        <Typography fontType="Table" fontSize={14}>
          Заправки
        </Typography>
        <Typography fontType="Table" fontSize={14}>
          {currencyToBase(data.writeOffs.gasStation, true)}
        </Typography>
      </Item>
      <Item>
        <Typography fontType="Table" fontSize={14}>
          Сервисный сбор за колл-центр
        </Typography>
        <Typography fontType="Table" fontSize={14}>
          {currencyToBase(data.writeOffs.callCenter, true)}
        </Typography>
      </Item>
      <Item>
        <Typography fontType="Table" fontSize={14}>
          Покупка смены
        </Typography>
        <Typography fontType="Table" fontSize={14}>
          {currencyToBase(data.writeOffs.subscription, true)}
        </Typography>
      </Item>
      <Item>
        <Typography fontType="Table" fontSize={14}>
          Покупка смены, НДС
        </Typography>
        <Typography fontType="Table" fontSize={14}>
          {currencyToBase(data.writeOffs.subscriptionNDS, true)}
        </Typography>
      </Item>
      <Item>
        <Typography fontType="Table" fontSize={14}>
          Аренда детского кресла
        </Typography>
        <Typography fontType="Table" fontSize={14}>
          {currencyToBase(data.writeOffs.childSeatRent, true)}
        </Typography>
      </Item>
      <Item>
        <Typography fontType="Table" fontSize={14}>
          Аренда детского кресла, НДС
        </Typography>
        <Typography fontType="Table" fontSize={14}>
          {currencyToBase(data.writeOffs.childSeatRentNDS, true)}
        </Typography>
      </Item>
    </>
  )
}
export default IncomeInfo
