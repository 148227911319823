import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Button = styled.button`
  display: inline-flex;
  outline: none;
  border: none;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.pallete.blue};
  border-radius: 50%;
  cursor: pointer;

  &:disabled {
    background-color: ${({ theme }) => theme.pallete.gray3} !important;
    cursor: default !important;

    .muk-counter-icon {
      cursor: default !important;
    }
  }

  .muk-counter-icon {
    width: 20px !important;
    height: 20px !important;
    cursor: pointer;

    svg {
      path {
        fill: ${({ theme }) => theme.pallete.white};
      }
    }
  }
`

export const Input = styled.input`
  width: 32px;
  height: 24px;
  border: 1px solid ${({ theme }) => theme.pallete.gray4};
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 0 8px;
  outline: none;
`
