import React from 'react'

import { ColorTypes, FontWeightTypes } from 'ui-kit'
import { CaptionText, Icon } from 'ui-kit/components'

import { VBox } from 'shared/ui'
import { list } from 'steps/data'
import { TStep } from 'steps/types'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  align-items: center;
`

const Item = styled.div<{ active: boolean }>`
  padding-bottom: 4px;
  border-bottom: 2px solid
    ${({ theme, active }) => (active ? theme.pallete.blue : 'transparent')};
`

interface Props {
  current: TStep
}

export const Completion: React.FC<Props> = ({ current }) => {
  const setColorAndFontWeight = (
    item: TStep
  ): {
    fontWeight: FontWeightTypes
    color: ColorTypes
  } => {
    if (current.number === item.number) {
      return { color: 'blue', fontWeight: 'semi-bold' }
    }
    if (current.number > item.number) {
      return { color: 'black', fontWeight: 'medium' }
    }
    return { color: 'gray5', fontWeight: 'normal' }
  }

  return (
    <Container>
      {Object.values(list).map((item, index, array) => (
        <React.Fragment key={item.number}>
          <Item active={current.number === item.number}>
            <CaptionText
              fontWeight={setColorAndFontWeight(item).fontWeight}
              color={setColorAndFontWeight(item).color}
            >
              {index + 1}. {item.label}
            </CaptionText>
          </Item>

          {array.length - 1 !== index ? (
            <>
              <VBox x={0.25} />
              <Icon name="chevronRight" />
              <VBox x={0.25} />
            </>
          ) : null}
        </React.Fragment>
      ))}
    </Container>
  )
}
