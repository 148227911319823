import { handleActions } from 'redux-actions'

import {
  CLOSE_ALL_MODALS,
  CLOSE_MODAL,
  OPEN_MODAL,
  SET_DATA_MODAL,
  TCLOSE_ALL_MODALS,
  TCLOSE_MODAL,
  TOPEN_MODAL,
  TSET_DATA_MODAL,
  TPayloads,
} from 'shared/modals/actions'

export type TModalsAction =
  | TOPEN_MODAL
  | TCLOSE_MODAL
  | TCLOSE_ALL_MODALS
  | TSET_DATA_MODAL

type ModalProps = {
  isOpen: boolean
  data?: any
}

export interface ModalsState {
  [key: string]: ModalProps
}

const initialState: ModalsState = {}

const handleOpen = (state: ModalsState, action: TOPEN_MODAL): ModalsState => ({
  ...state,
  [action.payload]: {
    ...state[action.payload],
    isOpen: true,
  },
})
const handleClose = (
  state: ModalsState,
  action: TCLOSE_MODAL
): ModalsState => ({
  ...state,
  [action.payload]: {
    ...state[action.payload],
    data: undefined,
    isOpen: false,
  },
})
const handleSaveDataModal = (
  state: ModalsState,
  action: TSET_DATA_MODAL
): ModalsState => ({
  ...state,
  [action.payload.name]: {
    ...state[action.payload.name],
    data: action.payload.data,
  },
})
const handleCloseAll = (): ModalsState => initialState

export default handleActions<ModalsState, TPayloads>(
  {
    [OPEN_MODAL]: handleOpen,
    [CLOSE_MODAL]: handleClose,
    [CLOSE_ALL_MODALS]: handleCloseAll,
    [SET_DATA_MODAL]: handleSaveDataModal,
  },
  initialState
)
