import React, { Dispatch, SetStateAction, useState } from 'react'

import {
  CaptionText,
  Icon as DefaultIcon,
  SubBodyText,
} from 'ui-kit/components'
import { DriverAccount } from 'api/types'
import { Col, Flex1, VBox } from 'shared/ui'
import styled from 'styled-components'

const Minimal = styled.div<{ hovered: boolean }>`
  padding-left: ${({ theme }) => theme.paddings.threeQuarters}px;
  padding-right: ${({ theme }) => theme.paddings.threeQuarters}px;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 56px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.pallete.uiHover};
  }
`
const Display = styled.div<{ display: boolean }>`
  display: ${({ display }) => !display && 'none'};
  padding-top: ${({ theme }) => theme.paddings.quarter}px;
  padding-bottom: ${({ theme }) => theme.paddings.quarter}px;
`

const Item = styled.div`
  margin-left: ${({ theme }) => theme.paddings.double}px;
  padding-left: ${({ theme }) => theme.paddings.threeQuarters}px;
  padding-right: ${({ theme }) => theme.paddings.threeQuarters}px;
  min-height: 48px;
`

type Props = {
  index: number
  account: DriverAccount
  displayIndex: number | null
  setDisplayIndex: Dispatch<SetStateAction<number | null>>
}

const Icon = styled(DefaultIcon)`
  align-self: flex-start;
  padding-top: 16px;
`

export const Line: React.FC<Props> = ({
  index,
  account,
  displayIndex,
  setDisplayIndex,
}) => {
  const [hovered, setHovered] = useState(false)
  const active = displayIndex === index
  return (
    <>
      <Minimal
        hovered={hovered && !active}
        onClick={() => setDisplayIndex(active ? null : index)}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <Icon name="document" />
        <VBox />
        <Flex1>
          <Col>
            <SubBodyText>{account.bank_account}</SubBodyText>
            {!active ? (
              <CaptionText color="gray8">{account.owner_name}</CaptionText>
            ) : null}
          </Col>
        </Flex1>
        <VBox x={0.5} />
        <Icon name={active ? 'expandLess' : 'expandMore'} color="blue" />
      </Minimal>
      <Display display={active}>
        <Item>
          <CaptionText color="gray8">Владелец счета</CaptionText>
          <SubBodyText>{account.owner_name}</SubBodyText>
        </Item>
        <Item>
          <CaptionText color="gray8">Банк</CaptionText>
          <SubBodyText>{account.bank_name}</SubBodyText>
        </Item>
        <Item>
          <CaptionText color="gray8">БИК</CaptionText>
          <SubBodyText>{account.bank_bik}</SubBodyText>
        </Item>
        <Item>
          <CaptionText color="gray8">Корреспондентский счет</CaptionText>
          <SubBodyText>{account.bank_correspondent_account}</SubBodyText>
        </Item>
      </Display>
    </>
  )
}
