import React, { useEffect } from 'react'
import { Field, InjectedFormProps, SubmissionError } from 'redux-form'

import { BodyText, FloatButton, TitleText } from 'ui-kit/components'

import { history } from 'store'
import { RoutesPath } from 'common/enums'

import { required } from 'common/utils/fieldValidation'
import ListInput from 'common/components/formItems/ListInput'
import { useStepsContext } from 'shared/hooks'
import { HBox } from 'shared/ui'
import { list } from 'steps/data'
import { StepsActionType } from 'steps/types'
import { SkipButton } from 'steps/features/content'

import {
  ContainerProps,
  FormProps,
} from 'steps/features/stepDrivers/containers/StepDriversContainer'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 580px;
  width: 100%;
`

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`
const ButtonWidth = styled.div`
  width: 320px;
`

export const StepDrivers: React.FC<
  InjectedFormProps<FormProps, ContainerProps> & ContainerProps
> = ({ submitting, valid, handleSubmit, onSave }) => {
  const { stepsDispatch } = useStepsContext()
  useEffect(() => {
    stepsDispatch({
      type: StepsActionType.SET_CURRENT_STEP,
      payload: { current: list.drivers },
    })
  }, [stepsDispatch])
  const handleSave = async (values: FormProps) => {
    try {
      await onSave(values)
    } catch (e) {
      throw new SubmissionError(e)
    }
  }
  const handleSkip = (): void => {
    history.replace(RoutesPath.StepsEmployees)
  }
  return (
    <Wrapper>
      <TitleText fontWeight="semi-bold" className="form-title">
        Введите ключ API edgvr
      </TitleText>
      <HBox />
      <BodyText color="gray9">
        Чтобы все договоры, заключенные с водителями автоматически добавлялись в
        систему вам необходимо ввести ключ.
      </BodyText>
      <HBox x={2} />
      <Field
        name="edgvr_api_key"
        label="Ключ API edgvr"
        iconName="close"
        validate={required}
        component={ListInput}
      />

      <HBox x={2} />
      <Actions>
        <ButtonWidth>
          <FloatButton
            type="submit"
            fullWidth
            isLoading={submitting}
            disabled={!valid}
            onClick={handleSubmit(handleSave)}
          >
            Продолжить
          </FloatButton>
        </ButtonWidth>
        <SkipButton
          onClick={handleSkip}
          tipText="Вы можете пропустить этот шаг и добавить ключ API edgvr позже"
        >
          Пропустить
        </SkipButton>
      </Actions>
    </Wrapper>
  )
}
