import { ThunkAction } from 'redux-thunk'
import { State } from 'reducers'
import axios from 'axios'
import { FailureError } from 'common/utils/reduxApiFactory'
import { TApiError } from 'api/types'
import { getParkAzonActions as actions } from './actions'
import { GetParkAzonActions } from './types'
import { AzonResponse } from '../types'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  GetParkAzonActions
>

export const getParkAzonRequest = (): THandleRequest => async (dispatch) => {
  dispatch(actions.start())
  try {
    const response = await axios.get<AzonResponse>(`erp/1/park/azon`)
    dispatch(actions.update(response.data))
    dispatch(actions.success())
  } catch (e) {
    if (e.response) {
      const data = e.response.data as TApiError
      const error: FailureError = new Error(data.message)
      error.data = data
      dispatch(actions.failure(error))
      throw error
    }
  }
}
