import { createApiReducer } from 'common/utils/reduxApiFactory'
import { getFuelSettingsActions } from 'api/fuel/settings/get/actions'
import { GetFuelSettingsState } from 'api/fuel/settings/get/types'

export const initialState: GetFuelSettingsState = {
  status: null,
  data: null,
}

export const getFuelSettingsReducer = createApiReducer(
  getFuelSettingsActions,
  initialState
)
