import React from 'react'
import { Field } from 'redux-form'

import { history } from 'store'

import { RoutesPath } from 'common/enums'
import ListInput from 'common/components/formItems/ListInput'
import {
  email,
  maxLength30,
  minLength6,
  required,
} from 'common/utils/fieldValidation'

import { HBox, Image } from 'shared/ui'
import { AuthLayout } from 'shared/router'
import {
  Action,
  ActionLink,
  Description,
  Form,
  ImageHolder,
  LeftContent,
  ResetPassword,
  RightContent,
} from 'auth/signIn/styles'
import carSrc from 'static/backgrounds/car.svg'

import {
  BodyText,
  CaptionText,
  FloatButton,
  ListItem,
  TitleText,
} from 'ui-kit/components'

interface Props {
  submitLoading?: boolean
  isReset?: boolean
  onSetResetPassword: (isReset?: boolean) => void
  onSubmit: () => void
}

const SignInComponent: React.FC<Props> = (props) => {
  const { onSubmit, submitLoading, onSetResetPassword, isReset } = props

  const renderLeftSideContent = (): JSX.Element => {
    return (
      <LeftContent>
        <Form>
          <TitleText fontWeight="semi-bold">
            {!isReset ? 'Вход в аккаунт' : 'Сбросьте пароль'}
          </TitleText>
          <HBox x={0.5} />
          {isReset && (
            <Description>
              <ListItem>
                <BodyText>
                  Введите адрес электронной почты, связанный
                  <br />с вашим аккаунтом Mozen, и мы пришлем вам ссылку для
                  изменения пароля
                </BodyText>
              </ListItem>
            </Description>
          )}
          <Field
            name="email"
            label="Email"
            iconName="close"
            validate={[email, required]}
            component={ListInput}
          />
          {!isReset && (
            <>
              <Field
                name="password"
                label="Пароль"
                type="password"
                validate={[minLength6, maxLength30, required]}
                component={ListInput}
              />
              <HBox x={0.25} />
              <ResetPassword onClick={() => onSetResetPassword(true)}>
                <CaptionText color="blue" fontWeight="semi-bold">
                  Забыли пароль?
                </CaptionText>
              </ResetPassword>
            </>
          )}
          <HBox x={1.5} />
          <Action isReset={isReset}>
            <FloatButton
              fullWidth
              type="submit"
              onClick={onSubmit}
              isLoading={submitLoading}
            >
              {!isReset ? 'Войти' : 'Отправить ссылку'}
            </FloatButton>
          </Action>
          <HBox x={0.5} />
          <ActionLink
            onClick={() =>
              !isReset
                ? history.push(RoutesPath.SignUp)
                : onSetResetPassword(false)
            }
          >
            <BodyText color="blue" fontWeight="semi-bold">
              {!isReset ? 'Зарегистрироваться в Mozen' : 'Вернуться ко входу'}
            </BodyText>
          </ActionLink>
        </Form>
      </LeftContent>
    )
  }

  const renderRightSideContent = (): JSX.Element => {
    return (
      <RightContent>
        <ImageHolder>
          <TitleText
            className="sign-up-right-title"
            fontWeight="semi-bold"
            color="white"
          >
            Автоматизированная система для&nbsp;управления автопарком
          </TitleText>
          <Image src={carSrc} width="50vw" />
        </ImageHolder>
      </RightContent>
    )
  }

  return (
    <AuthLayout
      leftSideContent={renderLeftSideContent()}
      rightSideContent={renderRightSideContent()}
    />
  )
}

export default SignInComponent
