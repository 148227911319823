import React from 'react'

import { BodyText, CaptionText, TableText } from 'ui-kit/components/Typography'
import { TableCaptionText } from 'ui-kit/components/Typography/TableCaptionText/TableCaptionText'
import { Wrapper } from 'ui-kit/components/ListLead/styles'

interface Props {
  subtitle?: string | null
  className?: string
  strong?: boolean
  reverse?: boolean
  table?: boolean
}

export const ListLead: React.FC<Props> = (props) => {
  const { children, subtitle, className, strong, reverse, table } = props

  return (
    <Wrapper className={className} reverse={reverse}>
      {subtitle && table ? (
        <TableCaptionText>{subtitle}</TableCaptionText>
      ) : (
        <CaptionText color="gray8">{subtitle}</CaptionText>
      )}
      {table ? (
        <TableText bold={strong} color="black">
          {children}
        </TableText>
      ) : (
        <BodyText fontWeight={strong ? 'semi-bold' : 'normal'} color="black">
          {children}
        </BodyText>
      )}
    </Wrapper>
  )
}
