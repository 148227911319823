import { createApiReducer } from 'common/utils/reduxApiFactory'
import { PostParkAzonState } from './types'
import { postParkAzonActions } from './actions'

export const initialState: PostParkAzonState = {
  status: null,
  data: null,
}

export const postParkAzonReducer = createApiReducer(
  postParkAzonActions,
  initialState
)
