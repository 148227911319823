import React from 'react'
import { Field, InjectedFormProps, SubmissionError } from 'redux-form'

import { CaptionText, RejectResolveButtons } from 'ui-kit/components'

import ListInput from 'common/components/formItems/ListInput'
import { required } from 'common/utils/fieldValidation'
import { HBox, Modal } from 'shared/ui'

import {
  ContainerProps,
  FormProps,
} from 'settings/features/parkTinkoff/containers/AddTinkoffModalContainer'
import styled from 'styled-components'
import KnowledgeTips from 'ui-kit/atoms/KnowledgeTips/KnowledgeTips'

const Wrapper = styled.div`
  padding: 16px;
  width: 450px;
`

const knowledgeTips = [
  {
    name: 'Где взять Terminal Key',
    href:
      'https://help.tinkoff.ru/internet-acquiring/start-ia/working-terminal/',
  },
]

export const AddTinkoffModal: React.FC<
  InjectedFormProps<FormProps, ContainerProps> & ContainerProps
> = ({ submitting, error, handleSubmit, onSave, closeAddTinkoffModal }) => {
  const handleSave = async (values: FormProps) => {
    try {
      await onSave(values)
    } catch (e) {
      throw new SubmissionError(e)
    }
  }
  return (
    <Modal
      open
      closeButton
      onClose={closeAddTinkoffModal}
      noOverlayClick={false}
      title="Счет Tinkoff"
    >
      <Wrapper>
        <Field
          name="terminal_key"
          label="Terminal Key"
          validate={required}
          component={ListInput}
        />
        <HBox x={0.5} />
        <KnowledgeTips data={knowledgeTips} />

        {error && !submitting ? (
          <CaptionText color="red">{error}</CaptionText>
        ) : (
          <HBox x={0.5} />
        )}

        <HBox x={0.75} />
        <RejectResolveButtons
          fullWidth
          isLoading={submitting}
          onResolve={handleSubmit(handleSave)}
          onReject={closeAddTinkoffModal}
          rejectText="Закрыть"
          resolveText="Подключить"
          disableResolve={submitting}
          disableReject={submitting}
        />
      </Wrapper>
    </Modal>
  )
}
