import React from 'react'
import { Field, InjectedFormProps, SubmissionError } from 'redux-form'

import { BodyText, FloatButton, TitleText } from 'ui-kit/components'

import { history } from 'store'
import { BankType, RoutesPath } from 'common/enums'
import { required } from 'common/utils/fieldValidation'
import ListInput from 'common/components/formItems/ListInput'
import { HBox } from 'shared/ui'
import Alert from 'react-s-alert'

import { SkipButton } from 'steps/features/content'

import {
  ContainerProps,
  FormProps,
} from 'steps/features/stepPayments/containers/PaymentsTinkoffContainer'
import styled from 'styled-components'
import KnowledgeTips from 'ui-kit/atoms/KnowledgeTips/KnowledgeTips'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 563px;
`

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`
const ButtonWidth = styled.div`
  width: 320px;
`

const knowledgeTips = [
  {
    name: 'Где взять Terminal Key',
    href:
      'https://help.tinkoff.ru/internet-acquiring/start-ia/working-terminal/',
  },
]

export const PaymentsTinkoff: React.FC<
  InjectedFormProps<FormProps, ContainerProps> & ContainerProps
> = ({ valid, submitting, handleSubmit, onSave }) => {
  const handleSave = async (values: FormProps) => {
    try {
      await onSave(values)
    } catch (e) {
      Alert.error('Ошибка', {
        customFields: {
          text: e?.[BankType.Tinkoff] || 'что-то пошло не так',
        },
      })
      throw new SubmissionError(e)
    }
  }
  const handleSkip = (): void => {
    history.replace(RoutesPath.StepsDrivers)
  }

  return (
    <Wrapper>
      <TitleText>Счет в Тинькофф</TitleText>
      <HBox />
      <BodyText color="gray9">Укажите данные для подключения.</BodyText>
      <HBox x={0.5} />
      <Field
        name="terminal_key"
        validate={[required]}
        label="Terminal Key"
        component={ListInput}
      />
      <HBox x={0.5} />
      <KnowledgeTips data={knowledgeTips} />

      <HBox x={2} />
      <Actions>
        <ButtonWidth>
          <FloatButton
            type="submit"
            disabled={!valid}
            isLoading={submitting}
            fullWidth
            onClick={handleSubmit(handleSave)}
          >
            Подключить
          </FloatButton>
        </ButtonWidth>
        <SkipButton
          onClick={handleSkip}
          tipText="Подключить Tinkoff вы сможете позже в разделе «Настройки»"
        >
          Пропустить
        </SkipButton>
      </Actions>
    </Wrapper>
  )
}
