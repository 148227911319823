import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import { State } from 'reducers'
import { apiSelectors } from 'api'
import { MyThunkDispatch } from 'api/reducer'
import { FormNames, ModalsNames } from 'common/enums'
import { modalsActions } from 'shared/modals'
import { onSaveParkcertificate } from 'settings/features/parkCertificate/thunks'
import { AddCertificateModal } from 'settings/features/parkCertificate/pages'

export interface FormProps {
  alias: string
  password: string
  login: string
  basic_user: string
  basic_pass: string
  file: File
  expire_date: Date
}

const mapStateToProps = (state: State) => ({
  isFetching: apiSelectors.park.certificatePost.getIsFetching(state),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  onSave: (values: FormProps) => dispatch(onSaveParkcertificate({ values })),
  closeAddcertificateModal: () =>
    dispatch(modalsActions.closeModal(ModalsNames.AddCertificate)),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const Form = reduxForm<FormProps, ContainerProps>({
  form: FormNames.ParkCertificate,
})(AddCertificateModal)

export const AddCertificateModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Form)
