import React from 'react'

import { Icon, SubBodyText, SubTitleText } from 'ui-kit/components'
import { formatPhone } from 'common/utils/formatters'

import { HBox, NoContentImage } from 'shared/ui'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex: 1;
  padding-left: 82px;
  padding-right: 82px;
`
const Info = styled.div`
  flex: 5;
  align-self: flex-start;
`
const Image = styled.div`
  flex: 5;
`
const Empty = styled.div`
  flex: 1;
`

interface Props {
  phone: string
}

export const DriverNotRegistered: React.FC<Props> = ({ phone }) => (
  <>
    <HBox x={2} />
    <Container>
      <Info>
        <SubTitleText color="darkGray" fontWeight="semi-bold">
          Водителя нет в Mozen
        </SubTitleText>
        <HBox />
        <Icon name="phone" color="darkGray">
          {formatPhone(phone)}
        </Icon>
        <HBox x={0.5} />
        <SubBodyText color="darkGray">
          Автоматический вывод средств и история выплат станут доступны, когда
          водитель зарегистрируется{' '}
          <a
            href="https://play.google.com/store/apps/details?id=ru.appkode.mozen&hl=ru"
            target="_blank"
            rel="noopener noreferrer"
          >
            в&nbsp;мобильном&nbsp;приложении
          </a>
          .
        </SubBodyText>
      </Info>
      <Empty />
      <Image>
        <NoContentImage />
      </Image>
    </Container>
  </>
)
