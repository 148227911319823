import React from 'react'
import ReactModal from 'react-modal'

import { Icon, SubTitleText } from 'ui-kit/components'

import { Flex1, Loader, Row, VBox } from 'shared/ui'
import { Header, ModalComponent } from 'shared/ui/modal/components/styles'
import styled from 'styled-components'

ReactModal.setAppElement('#content')

const Content = styled.div<{
  width: number
  noPadding?: boolean
  isLoading?: boolean
}>`
  padding: ${({ noPadding }) => !noPadding && '16px'};
  width: ${({ width }) => width}px;
  min-height: ${({ isLoading }) => isLoading && '120px'};
`

interface Props {
  open?: boolean
  onClose?: () => void
  closeButton?: boolean
  noOverlayClick?: boolean
  overflow?: string
  closeButtonComponent?: React.FC
  title?: string
  type?: 'success' | 'error' | 'group'
  width?: number
  withMinHeight?: boolean
  withTransparent?: boolean
  noPadding?: boolean
  isLoading?: boolean
}

export const Modal: React.FC<Props> = ({
  open = false,
  onClose,
  noOverlayClick = true,
  children,
  closeButton,
  overflow = 'visible',
  closeButtonComponent,
  title,
  type,
  width,
  withMinHeight = true,
  withTransparent,
  noPadding,
  isLoading,
}) => {
  const CloseButton = styled(closeButtonComponent || 'div')`
    width: 24px;
    height: 24px;

    .close {
      p,
      svg path {
        transition: 0.3s ease-in-out;
      }

      &:hover {
        p {
          fill: ${({ theme }) => theme.pallete.blue};
        }

        svg {
          path {
            fill: ${({ theme }) => theme.pallete.blue};
          }
        }
      }
    }
  `

  const renderIcon = () => {
    switch (type) {
      case 'error':
        return (
          <>
            <Icon name="modalWarning" />
            <VBox x={0.75} />
          </>
        )
      case 'group':
        return (
          <>
            <Icon name="group" />
            <VBox x={0.75} />
          </>
        )
      case 'success':
        return (
          <>
            <Icon name="modalSuccess" />
            <VBox x={0.75} />
          </>
        )
      default:
        return null
    }
  }

  return (
    <ModalComponent
      overflow={overflow}
      isOpen={open}
      shouldCloseOnOverlayClick={!noOverlayClick}
      onRequestClose={noOverlayClick ? undefined : onClose}
      withMinHeight={withMinHeight}
      withTransparent={withTransparent}
    >
      {title || closeButton ? (
        <Header type={type}>
          <Flex1>
            <Row>
              {renderIcon()}
              <Flex1>
                <SubTitleText fontWeight="semi-bold">{title}</SubTitleText>
              </Flex1>
              {closeButton ? (
                <>
                  <VBox />
                  <CloseButton>
                    <Icon
                      name="close"
                      className="close"
                      pointer
                      onClick={onClose}
                    />
                  </CloseButton>
                </>
              ) : null}
            </Row>
          </Flex1>
        </Header>
      ) : null}

      {width ? (
        <Content width={width} noPadding={noPadding} isLoading={isLoading}>
          {isLoading ? <Loader absolute /> : children}
        </Content>
      ) : (
        children
      )}
    </ModalComponent>
  )
}
