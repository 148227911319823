import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  .picker-custom-input {
    height: initial;
    .bar {
      display: none;
    }
    input {
      border-bottom-color: transparent !important;
      padding: 0 !important;
      color: ${({ theme }) => theme.pallete.blue} !important;
      &:focus {
        border-bottom-color: ${({ theme }) => theme.pallete.blue} !important;
      }
    }
  }
  .range-picker-close {
    p,
    svg path {
      transition: 0.3s ease-in-out;
    }

    &:hover {
      p {
        fill: ${({ theme }) => theme.pallete.blue};
      }

      svg {
        path {
          fill: ${({ theme }) => theme.pallete.blue};
        }
      }
    }
  }
`

export const Pickers = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 16px;
`

export const Divider = styled.span`
  color: ${({ theme }) => theme.pallete.blue};
`

interface Activator {
  active: boolean
  triggerSpacing: boolean
}

export const Activator = styled.div<Activator>`
  display: flex;
  align-items: center;
  cursor: pointer;

  .picker-calendar {
    margin-right: ${(props) => (props.triggerSpacing ? '16px' : 0)};
    cursor: ${(props) => (!props.active ? 'pointer' : 'initial')};

    ${(props) =>
      props.active &&
      css`
        svg {
          path {
            fill: ${({ theme }) => theme.pallete.blue};
          }
        }
      `}
  }
`
