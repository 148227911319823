import { ProgramStates } from './types'

const getByState = <T>(
  state: ProgramStates,
  states: Record<ProgramStates, T>
): T => states[state]

export const getIconTypeAndColorByState = (state: ProgramStates) =>
  getByState(state, {
    firstTime: { type: 'minusInCircleFilled', color: 'subdued' },
    active: { type: 'checkInCircleFilled', color: 'success' },
    disabled: { type: 'minusInCircleFilled', color: 'danger' },
    hidden: { type: 'eyeClosed', color: 'subdued' },
  })

export const getTitleByState = (state: ProgramStates) =>
  getByState(state, {
    firstTime: 'Не подключена',
    active: 'Активна',
    disabled: 'Не работает',
    hidden: 'Скрыта',
  })

export const getDescriptionByState = (state: ProgramStates) =>
  getByState(state, {
    firstTime:
      'Выберите условия и запустите реферальную программу для отображения водителям через мобильное приложение.',
    active:
      'Водители видят условия о реферальной программе и могут создавать приглашения.',
    disabled:
      'Водители не видят условия в мобильном приложении, выплаты не производятся.',
    hidden:
      'Выплаты по старым приглашениям производятся, но водители не видят программу в мобильном приложении.',
  })
