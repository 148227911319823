import React from 'react'

import { HBox, Image, Tooltip } from 'shared/ui'
import styled from 'styled-components'
import macSrc from 'static/backgrounds/mac.png'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 56px;
  height: calc(100vh - 136px);
`

const ImageHolder = styled.div`
  img {
    max-height: 500px;
    object-position: right;
  }
`

interface Props {
  tooltip: string
}

export const RightSide: React.FC<Props> = ({ tooltip }) => (
  <Container>
    <Tooltip
      content={tooltip}
      textColor="white"
      iconOpacity={0.3}
      backgroundColor="rgba(234,238,247,.2)"
      alwaysOpen
    />
    <HBox x={2} />
    <ImageHolder>
      <Image src={macSrc} height="55vh" objectFit="contain" />
    </ImageHolder>
  </Container>
)
