import { createApiReducer } from 'common/utils/reduxApiFactory'
import { DeleteReferralOfferState } from './types'
import { deleteReferralOfferActions } from './actions'

export const initialState: DeleteReferralOfferState = {
  status: null,
  data: null,
}

export const deleteReferralOfferReducer = createApiReducer(
  deleteReferralOfferActions,
  initialState
)
