import React, { useState } from 'react'
import { FuelConnector } from 'fuel/containers'
import { PureCmsFuel } from 'fuel/organisms'
import { Loader } from 'shared/ui/loader'
import { FuelPageDataMapper } from 'elastic-ui/fuel/pages/FuelPage/FuelPageDataMapper'

export const Fuel = React.memo(() => {
  const [isDepositModalOpen, setIsDepositModalOpen] = useState(false)
  const [isBonusSettingsModalOpen, setIsBonusSettingsModalOpen] = useState(
    false
  )
  const [isBonusSettingsLoading, setIsBonusSettingsLoading] = useState(false)
  const [isOperationHistoryLoading, setIsOperationHistoryLoading] = useState(
    false
  )

  return (
    <FuelConnector>
      {({ landing, fuel }) => {
        if (landing) {
          return (
            <PureCmsFuel
              cmsContent={landing.data}
              onMoreDetails={landing.onMoreDetails}
            />
          )
        }

        if (fuel) {
          return (
            <FuelPageDataMapper
              bonusAmount={fuel.summary.cashback_balance}
              cashbackPercent={fuel.settings.cashback_percent}
              fuelDepositAmount={fuel.summary.deposit_balance}
              maxCashbackPercent={fuel.settings.max_percent}
              onCashbackSettingsSave={async (cashbackPercent) => {
                setIsBonusSettingsLoading(true)
                setIsBonusSettingsModalOpen(false)
                await fuel.updateSettings(cashbackPercent)
                setIsBonusSettingsLoading(false)
              }}
              onDownloadDepositPayment={async (amount) => {
                setIsDepositModalOpen(false)
                await fuel.downloadOrder(amount)
              }}
              operationHistoryOnPage={fuel.fuelHistory.list}
              totalTableItems={fuel.fuelHistory.total}
              onTableChange={async (event) => {
                setIsOperationHistoryLoading(true)
                await fuel.updateHistory(
                  event.limit,
                  event.depositType,
                  event.offset
                )
                setIsOperationHistoryLoading(false)
              }}
              isBonusSettingsModalOpen={isBonusSettingsModalOpen}
              isDepositModalOpen={isDepositModalOpen}
              onChangeBonusSettingsClick={() =>
                setIsBonusSettingsModalOpen(true)
              }
              onDepositClick={() => setIsDepositModalOpen(true)}
              onBonusSettingsModalClose={() =>
                setIsBonusSettingsModalOpen(false)
              }
              onDepositModalClose={() => setIsDepositModalOpen(false)}
              isBonusSettingsLoading={isBonusSettingsLoading}
              isOperationHistoryLoading={isOperationHistoryLoading}
            />
          )
        }

        return <Loader />
      }}
    </FuelConnector>
  )
})

Fuel.displayName = 'Fuel'
