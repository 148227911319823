import React from 'react'
import styled from 'styled-components'

import { BodyText } from 'ui-kit/components'

const Wrapper = styled.label<{ disabled?: boolean }>`
  position: relative;
  display: flex;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const VSpace = styled.div`
  width: 16px;
`

const SwitchBox = styled.div<{ disabled?: boolean; checked?: boolean }>`
  display: flex;
  flex-shrink: 0;
  padding: 0 1px;
  width: 40px;
  height: 24px;
  align-items: center;
  background-color: ${({ disabled, checked, theme }) => {
    if (disabled) {
      return theme.pallete.gray2
    }

    if (checked) {
      return theme.pallete.blue
    }

    return theme.pallete.gray3
  }};
  border-radius: 100px;
  transition: background-color 0.3s ease-in-out;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`

const SwitchIndicator = styled.div<{ checked?: boolean }>`
  width: 22px;
  height: 22px;
  background-color: ${({ theme }) => theme.pallete.white};
  border-radius: 50%;
  transition: transform 0.3s ease-in-out;

  ${({ checked }) => checked && `transform: translate3d(16px, 0, 0);`}
`

interface Props {
  name: string
  inputId?: string
  className?: string
  value?: string
  disabled?: boolean
  checked?: boolean
  defaultChecked?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const Switch: React.FC<Props> = ({
  children,
  name,
  inputId,
  disabled,
  checked,
  onChange,
  defaultChecked,
  value,
}) => {
  return (
    <Wrapper htmlFor={inputId} disabled={disabled}>
      {children ? (
        <>
          <Container>
            {typeof children === 'string' ? (
              <BodyText>{children}</BodyText>
            ) : (
              children
            )}
          </Container>
          <VSpace />
        </>
      ) : null}

      <input
        hidden
        id={inputId}
        name={name}
        type="checkbox"
        onChange={onChange}
        value={value}
        disabled={disabled}
        checked={checked}
        defaultChecked={defaultChecked}
      />
      <SwitchBox disabled={disabled} checked={checked || defaultChecked}>
        <SwitchIndicator checked={checked || defaultChecked} />
      </SwitchBox>
    </Wrapper>
  )
}
