import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { State } from 'reducers'
import { MyThunkDispatch } from 'api/reducer'
import { FormNames, ModalsNames } from 'common/enums'
import { modalsActions, modalsSelectors } from 'shared/modals'
import { onSaveNotificationsSettings } from 'settings/features/notificationsSettings/thunks'
import { getNotificationsSettingsValues } from 'settings/features/notificationsSettings/selectors'
import { NotificationsSettings } from 'settings/features/notificationsSettings/organisms'
import { apiSelectors } from 'api'

export type FormProps = {
  transactions_notify?: boolean
  insufficient_fuel_deposit?: boolean
  successful_payment_notify?: boolean
  failed_payment_notify?: boolean
  email_notifications?: boolean
  certificate_notify?: boolean
}

const mapStateToProps = (state: State) => ({
  notificationsSettings: apiSelectors.park.notificationsGet.getNotificationsSettings(
    state
  ),
  bankType: apiSelectors.park.details.getBankType(state),
  isAddNotificationsQiwiSettingsModalOpened: modalsSelectors.getIsModalOpened(
    state,
    ModalsNames.AddNotificationsQiwiSettings
  ),
  isFuelEnabled: apiSelectors.park.details.selectIsFuelEnabled(state),
  initialValues: getNotificationsSettingsValues(state),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  onSave: (values: Partial<FormProps>) =>
    dispatch(onSaveNotificationsSettings({ values })),
  openAddNotificationsQiwiSettingsModal: () =>
    dispatch(modalsActions.openModal(ModalsNames.AddNotificationsQiwiSettings)),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const Form = reduxForm<FormProps, ContainerProps>({
  form: FormNames.NotificationsSettings,
})(NotificationsSettings)

export const NotificationsSettingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Form)
