import { LargeFilter } from 'common/types/local/filter'

export const filterData: LargeFilter[] = [
  // {name: 'new', label: 'Новый в этом месяце', category: 'Водители'},
  { name: 'active', label: 'Активные', category: 'Водители' },
  // {name: 'no_contract', label: 'Без договора', category: 'Водители'},
  // {name: 'no_requisites', label: 'Без реквизитов', category: 'Водители'},
  {
    name: 'registered',
    label: 'Зарегистрирован в Мозен',
    category: 'Водители',
  },
]
