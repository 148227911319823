import { handleActions } from 'redux-actions'

import { DriverTransaction } from 'common/types/api/models/payment'

import {
  Payloads,
  SET_PAYMENTS,
  SET_PAYMENTS_DATE_FROM,
  SET_PAYMENTS_DATE_TO,
  SET_PAYMENTS_FILTER,
  SET_PAYMENTS_PAGE,
  SET_PAYMENTS_SEARCH,
} from 'drivers/features/driverPayments/actions'

export interface DriverPaymentsState {
  payments: DriverTransaction[]
  searchValue: string
  page: number
  total: number
  filters: string[]
  dateFrom: Date | null
  dateTo: Date | null
}

const initialState: DriverPaymentsState = {
  payments: [],
  searchValue: '',
  page: 1,
  total: 0,
  filters: [],
  dateFrom: null,
  dateTo: null,
}

const handleSetPayments = (
  state: DriverPaymentsState,
  action: SET_PAYMENTS
): DriverPaymentsState => ({
  ...state,
  payments: action.payload.items,
  total: action.payload.total,
})

const handleSetPaymentsSearch = (
  state: DriverPaymentsState,
  action: SET_PAYMENTS_SEARCH
): DriverPaymentsState => ({
  ...state,
  searchValue: action.payload,
})

const handleSetPaymentsPage = (
  state: DriverPaymentsState,
  action: SET_PAYMENTS_PAGE
): DriverPaymentsState => ({
  ...state,
  page: action.payload,
})

const handleSetPaymentsFilter = (
  state: DriverPaymentsState,
  action: SET_PAYMENTS_FILTER
): DriverPaymentsState => ({
  ...state,
  filters: action.payload,
})

const handleSetPaymentsDateFrom = (
  state: DriverPaymentsState,
  action: SET_PAYMENTS_DATE_FROM
): DriverPaymentsState => ({
  ...state,
  dateFrom: action.payload,
})

const handleSetPaymentsDateTo = (
  state: DriverPaymentsState,
  action: SET_PAYMENTS_DATE_TO
): DriverPaymentsState => ({
  ...state,
  dateTo: action.payload,
})

export default handleActions<DriverPaymentsState, Payloads>(
  {
    [SET_PAYMENTS]: handleSetPayments,
    [SET_PAYMENTS_SEARCH]: handleSetPaymentsSearch,
    [SET_PAYMENTS_PAGE]: handleSetPaymentsPage,
    [SET_PAYMENTS_FILTER]: handleSetPaymentsFilter,
    [SET_PAYMENTS_DATE_FROM]: handleSetPaymentsDateFrom,
    [SET_PAYMENTS_DATE_TO]: handleSetPaymentsDateTo,
  },
  initialState
)
