import React from 'react'
import styled from 'styled-components'
import type { ColorTypes } from 'ui-kit'

export type Props = {
  children: React.ReactNode
  className?: string
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  icon?: React.ReactNode
  action?: React.ReactNode
  topBorderWidth?: number
  topBorderColor?: ColorTypes
  bottomBorderWidth?: number
  bottomBorderColor?: ColorTypes
}

const InnerWrapper = styled.div`
  padding-top: 8px;
  padding-bottom: 7px;
  border-bottom: 1px solid ${({ theme }) => theme.pallete.gray3};
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`

const Wrapper = styled.div<{
  withHover?: boolean
  topBorderWidth?: number
  topBorderColor?: ColorTypes
  bottomBorderWidth?: number
  bottomBorderColor?: ColorTypes
}>`
  width: 100%;
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: ${({ withHover }) => withHover && 'none'};

  border-bottom-width: ${({ bottomBorderWidth }) =>
    bottomBorderWidth && `${bottomBorderWidth}px`};
  border-bottom-color: ${({
    theme,
    bottomBorderColor = 'secondaryLightBlue',
    bottomBorderWidth,
  }) => bottomBorderWidth && theme.pallete[bottomBorderColor]};
  border-bottom-style: ${({ bottomBorderWidth }) =>
    bottomBorderWidth && 'solid'};

  border-top-width: ${({ topBorderWidth }) =>
    topBorderWidth && `${topBorderWidth}px`};
  border-top-color: ${({
    theme,
    topBorderColor = 'secondaryLightBlue',
    topBorderWidth,
  }) => topBorderWidth && theme.pallete[topBorderColor]};
  border-top-style: ${({ topBorderWidth }) => topBorderWidth && 'solid'};

  &:hover {
    background-color: ${({ withHover, theme }) =>
      withHover && theme.pallete.uiHover};
    cursor: ${({ withHover }) => withHover && 'pointer'};
  }

  & ${InnerWrapper} {
    padding-top: ${({ topBorderWidth = 0 }) => 8 - topBorderWidth}px;
    padding-bottom: ${({ bottomBorderWidth = 0 }) => 7 - bottomBorderWidth}px;
  }

  &:last-child ${InnerWrapper} {
    border-bottom: none;
    padding-bottom: ${({ bottomBorderWidth = 0 }) => 8 - bottomBorderWidth}px;
  }
`

const IconWrapper = styled.div`
  display: flex;
  min-width: 24px;
  min-height: 24px;
  box-sizing: content-box;
  padding-right: 16px;
`

const ActionWrapper = styled.div`
  display: flex;
  min-width: 24px;
  min-height: 24px;
  box-sizing: content-box;
  padding-left: 16px;
`

const ListItem: React.FC<Props> = ({
  children,
  className,
  onClick,
  icon,
  action,
  topBorderWidth,
  topBorderColor,
  bottomBorderWidth,
  bottomBorderColor,
}) => (
  <Wrapper
    topBorderWidth={topBorderWidth}
    topBorderColor={topBorderColor}
    bottomBorderWidth={bottomBorderWidth}
    bottomBorderColor={bottomBorderColor}
    withHover={Boolean(onClick)}
    onClick={onClick}
    className={className}
  >
    {icon !== undefined && (
      <IconWrapper data-testid="list-item-icon-wrapper">{icon}</IconWrapper>
    )}
    <InnerWrapper>
      {children}
      {action !== undefined && (
        <ActionWrapper data-testid="list-item-action-wrapper">
          {action}
        </ActionWrapper>
      )}
    </InnerWrapper>
  </Wrapper>
)

export default React.memo<Props>(ListItem)
