import React from 'react'

import styled, { css } from 'styled-components'
import { IconNames } from 'ui-kit'

import { Icon } from 'ui-kit/components'

const Wrapper = styled.div<{
  showError?: boolean
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 56px;

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`

const Input = styled.input<{
  showError?: boolean
  hasPadding: boolean
  disabled?: boolean
}>`
  padding-top: 16px;
  padding-right: ${({ hasPadding }) => hasPadding && '50px'};
  width: 100%;
  height: 100%;
  font-family: 'Montserrat', sans-serif;
  color: ${({ theme }) => theme.pallete.black};
  font-size: 14px;
  line-height: 21px;
  font-weight: normal;
  background: none;
  border: none;
  border-radius: 0;
  border-bottom: 0.5px solid
    ${({ showError, theme }) =>
      showError ? 'transparent' : theme.pallete.gray3};
  outline: none;
  box-sizing: border-box;
  ${({ disabled }) => disabled && `opacity: 0.5;`};

  &:focus {
    border-bottom: 0.5px solid transparent;
  }

  &:focus + div {
    transform: scaleX(1);
    opacity: 1;
  }

  &:focus ~ label,
  &:not(:placeholder-shown) ~ label {
    top: 8px;
    font-size: 12px;
    line-height: 18px;
    color: ${({ theme }) => theme.pallete.gray8};
  }

  &:focus ~ div svg {
    opacity: 1;
  }
`

const Bar = styled.div<{ showError?: boolean }>`
  position: absolute;
  bottom: 4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: ${({ showError, theme }) =>
    showError ? theme.pallete.red : theme.pallete.blue};
  border-radius: 2px;
  opacity: ${({ showError }) => (showError ? '1' : '0')};
  transform: ${({ showError }) => (showError ? 'scaleX(1)' : 'scaleX(0)')};
  transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 2;
`

const Label = styled.label<{ showError?: boolean; disabled?: boolean }>`
  position: absolute;
  top: 16px;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  color: ${({ showError, theme }) =>
    showError ? theme.pallete.red : theme.pallete.gray6};
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: text;
  pointer-events: none;
  transform-origin: left top;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
  ${({ disabled }) => disabled && `opacity: 0.5;`}
`

const ErrorText = styled.span`
  margin-left: 10px;
  color: ${({ theme }) => theme.pallete.red};
`

const Content = styled.div`
  display: inline-flex;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  align-items: center;
`

const IconWrapper = styled.div<{ showError?: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  display: inline-flex;
  height: 100%;

  svg {
    opacity: 0;
    transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);

    path {
      ${({ showError }) =>
        showError &&
        css`
          fill: ${({ theme }) => theme.pallete.red};
        `}
    }
  }
`

interface Props {
  name?: string
  value?: string
  type?: string
  label?: string
  placeholder?: string
  iconName?: IconNames
  required?: boolean
  showError?: boolean
  error?: string
  disabled?: boolean
  onIconClick?: (e: React.MouseEvent<HTMLElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  children?: React.ReactNode
  readonly?: boolean
}

export const ListInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      value,
      type,
      label,
      iconName,
      showError,
      error,
      onIconClick,
      onChange,
      onFocus,
      onBlur,
      children,
      disabled,
      placeholder,
      readonly,
    },
    ref
  ) => {
    return (
      <Wrapper showError={showError}>
        <Input
          ref={ref}
          required
          name={name}
          value={value}
          type={type}
          showError={showError}
          hasPadding={Boolean(children || iconName)}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
          placeholder={placeholder || ' '}
          readOnly={readonly}
        />
        <Bar showError={showError} />
        <Label showError={showError} disabled={disabled}>
          {label}
          {error ? <ErrorText>{error}</ErrorText> : null}
        </Label>
        {iconName && (
          <IconWrapper showError={showError}>
            <Icon name={iconName} onClick={onIconClick} />
          </IconWrapper>
        )}
        {!iconName && children && <Content>{children}</Content>}
      </Wrapper>
    )
  }
)

ListInput.displayName = 'ListInput'

ListInput.defaultProps = {
  type: 'text',
}
