import React, { useContext } from 'react'

import { ColorTypes, FontWeightTypes } from 'ui-kit'

import { DefaultProps } from 'ui-kit/components/Typography/interfaces'
import { TitleTextWrapper } from 'ui-kit/components/Typography/styles'
import { ThemeContext } from 'styled-components'

interface Props extends DefaultProps {
  fontWeight?: FontWeightTypes
}

export const TitleText: React.FC<Props> = (props) => {
  const {
    children,
    id,
    className,
    fontWeight = 'normal' as FontWeightTypes,
    color = 'black' as ColorTypes,
  } = props
  const theme = useContext(ThemeContext)

  return (
    <TitleTextWrapper
      id={id}
      fontWeight={fontWeight}
      color={theme.pallete[color]}
      className={className}
    >
      {children}
    </TitleTextWrapper>
  )
}
