import React from 'react'
import Card from 'ui-kit/atoms/Card/Card'
import styled from 'styled-components'
import Typography from 'ui-kit/typography'
import { DriverNotification } from 'ui-kit/molecules'
import { Pagination } from 'ui-kit/components'
import { Loader } from 'ui-kit/atoms/loader'
import { ReactComponent as BottomBarNotifications } from 'static/placeholders/bottom-bar-notifications.svg'
import { HBox } from 'shared/ui'

export type Notification = {
  id: string
  aggregators: string[]
  text: string
  date: string
  viewCount: number
}

export type Notifications = {
  data: Notification[]
  total: number
  offset: number
}

type Props = {
  notifications?: Notifications
  aggregators: string[]
  onFetch: (offset: number) => Promise<void>
}

const CardHeader = styled.div`
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 56px;
  box-sizing: border-box;
  border-bottom: 1px solid ${({ theme }) => theme.pallete.gray3};
`
const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 80px;
`
const CardItem = styled(DriverNotification)`
  flex: 1;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.pallete.gray3};

  &:last-child {
    border-bottom: none;
  }
`
const CardFooter = styled.div`
  border-top: 1px solid ${({ theme }) => theme.pallete.gray3};
  min-height: 56px;
  padding: 0 16px;
  box-sizing: border-box;
`

const PlaceholderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 32px;
  padding-bottom: 228px;
`

const PlaceholderContentContainer = styled.div`
  width: 100%;
  max-width: 360px;
`

const LIMIT = 5

export const DriverNotificationsList = ({
  notifications,
  aggregators,
  onFetch,
}: Props) => {
  const totalPage = Math.ceil((notifications?.total || 0) / LIMIT)
  const currentPage = Math.ceil((notifications?.offset || 0) / LIMIT)

  const handleClickPrev = async () => {
    if (currentPage < 1) {
      return
    }

    const prevPage = currentPage - 1
    await onFetch(prevPage * LIMIT)
  }

  const handleClickNext = async () => {
    if (currentPage > totalPage - 1) {
      return
    }

    const nextPage = currentPage + 1
    await onFetch(nextPage * LIMIT)
  }

  const totalNotifications = notifications?.total || 0
  const notificationsList = notifications ? (
    notifications.data.map((notification) => (
      <CardItem
        key={notification.id}
        markedAggregators={notification.aggregators}
        availableAggregators={aggregators}
        viewCount={notification.viewCount}
        text={notification.text}
        utcDate={notification.date}
      />
    ))
  ) : (
    <Loader />
  )

  return (
    <Card>
      <CardHeader>
        <Typography as="h2" fontSize={16} fontWeight="SemiBold">
          Отправленные
        </Typography>
      </CardHeader>
      <CardBody>
        {totalNotifications > 0 ? (
          notificationsList
        ) : (
          <PlaceholderContainer>
            <PlaceholderContentContainer>
              <BottomBarNotifications />
              <HBox x={0.5} />
              <Typography fontColor="textDisabled">
                Водители получат уведомление и увидят сообщение в отдельном
                разделе мобильного приложения.
              </Typography>
            </PlaceholderContentContainer>
          </PlaceholderContainer>
        )}
      </CardBody>
      {totalPage > 1 && (
        <CardFooter>
          <Pagination
            currentPage={currentPage + 1}
            totalPages={totalPage}
            onClickPrev={handleClickPrev}
            onClickNext={handleClickNext}
          />
        </CardFooter>
      )}
    </Card>
  )
}
