import React from 'react'

import { BodyText, FloatButton, RejectResolveButtons } from 'ui-kit/components'
import { EmployeeStatus } from 'common/enums'
import { HBox, Modal } from 'shared/ui'

import { ContainerProps } from 'employees/features/employeeInfo/containers/BlockEmployeeModalContainer'
import styled from 'styled-components'

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    max-width: 320px;
    width: 100%;
  }
`

export const BlockEmployeeModal: React.FC<ContainerProps> = ({
  isFetching,
  isBlocked,
  id,
  onSave,
  closeModal,
}) => {
  return (
    <Modal
      open
      onClose={closeModal}
      closeButton
      noOverlayClick={false}
      title={
        isBlocked ? 'Разблокировать сотрудника' : 'Заблокировать доступ к Mozen'
      }
      width={528}
    >
      {isBlocked ? (
        <>
          <BodyText color="gray8">
            Хотите вернуть этому сотруднику доступ в систему?
            <HBox x={0} />
            Все данные будут восстановлены.
          </BodyText>
          <HBox x={2} />
          <RejectResolveButtons
            fullWidth
            resolveText="Разблокировать"
            onResolve={() => onSave({ id, status: EmployeeStatus.Active })}
            onReject={closeModal}
            isLoading={isFetching}
          />
        </>
      ) : (
        <>
          <BodyText color="gray8">
            Этому сотруднику будет заблокирован доступ&nbsp; в систему, но вы
            всегда сможете посмотреть&nbsp; историю его действий
          </BodyText>
          <HBox x={2} />
          <Actions>
            <FloatButton
              color="red"
              isLoading={isFetching}
              onClick={() => onSave({ id, status: EmployeeStatus.Inactive })}
            >
              Заблокировать
            </FloatButton>
          </Actions>
        </>
      )}
    </Modal>
  )
}
