import { Action } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import Alert from 'react-s-alert'
import { State, TRootAction } from 'reducers'
import { apiActions } from 'api'
import { declension } from 'common/utils/formatters'

type ThunkResult<R> = ThunkAction<R, State, undefined, TRootAction>

export const handleContracts: () => ThunkResult<void> = () => (
  dispatch: ThunkDispatch<State, undefined, Action>
) =>
  dispatch(apiActions.park.contracts.handleRequest())
    .then((response) => {
      Alert.info('Успех', {
        customFields: {
          text: response
            ? `${declension(response, [
                'Обновлен',
                'Обновлено',
                'Обновлено',
              ])} ${response} ${declension(response, [
                'договор',
                'договора',
                'договоров',
              ])}`
            : `Все договоры актуальны`,
        },
      })
    })
    .catch(() => {
      Alert.error('Ошибка', {
        customFields: {
          text: 'Не удалось обновить договоры',
        },
      })
    })
