import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { format } from 'date-fns'
import DefaultCard from 'ui-kit/atoms/Card/Card'
import ListItem from 'ui-kit/atoms/ListItem/ListItem'
import {
  Icon,
  RejectResolveButtons,
  TransparentButton,
} from 'ui-kit/components'
import Typography from 'ui-kit/typography'
import { currencyToBase } from 'common/utils/formatters'
import { PaymentReasonHumanReadable } from 'common/enums/payments'
import CardItem from './Item/Item'
import Participant from './Participant/Participant'
import BankRequisites from './BankRequisites/BankRequisites'
import Status, { StatusType } from './Status/Status'

export type BankRequisites = {
  name: string
  accountNumber: string
  accountOwner: string
}

export type CardRequisites = {
  number: string
}

export type Details = {
  bankRequisites?: BankRequisites
  cardRequisites?: CardRequisites
  timestamp: number
  fromParticipant: string
  toParticipant: string
  reason: string
  amount: number
  status: StatusType
}

type Props = {
  data: Details
  isManageable: boolean
  onClose: () => void
  onResolve: () => Promise<void>
  onReject: () => Promise<void>
  onComplete: () => Promise<void>
  onRefund: () => Promise<void>
  onClickNext?: () => void
  onClickPrev?: () => void
  onClickToParticipant?: () => void
  onClickFromParticipant?: () => void
  onRefreshStatus?: () => Promise<void>
}

const Wrapper = styled.div``

const Card = styled(DefaultCard)`
  width: 480px;
  margin-bottom: 24px;
  margin-left: 16px;
  margin-right: 16px;
`

const HeaderWrapper = styled.div`
  margin-bottom: 4px;
`

const BodyWrapper = styled.div``
const FooterWrapper = styled.div`
  padding-bottom: 8px;
`

const StatusAction = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Caption = styled(Typography).attrs({ variant: 'Caption1' })`
  padding-bottom: 16px;
`
const Paragraph = styled.div`
  display: inline-block;
  padding-bottom: 8px;

  &:last-child {
    padding-bottom: 0;
  }
`

const CardActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const CardActionButton = styled(TransparentButton)`
  display: flex;
  padding: 8px 16px;
  border-radius: 20px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`

const HeaderItem = styled(ListItem)`
  min-height: 56px;
`

const MiddleIcon = styled(Icon)`
  display: inline-block;
  vertical-align: middle;
`

const Spacer = styled.div`
  display: inline-block;
  width: 8px;
`

const PaymentDetailsCard = ({
  data,
  isManageable,
  onClose,
  onRefreshStatus,
  onResolve,
  onReject,
  onComplete,
  onRefund,
  onClickToParticipant,
  onClickFromParticipant,
  onClickNext,
  onClickPrev,
}: Props) => {
  const [isFetch, setFetchingTo] = useState(false)
  const headerAction = useMemo(
    () => (
      <Icon data-testid="icon-close" name="close" pointer onClick={onClose} />
    ),
    [onClose]
  )
  const allowedToRefreshStatuses = [
    StatusType.Completed,
    StatusType.NotCompleted,
  ]
  const canRefreshStatus =
    allowedToRefreshStatuses.some((status) => data.status === status) &&
    data.reason === PaymentReasonHumanReadable.PARK_DRIVER_WITHDRAW

  const handleClickResolve = useCallback(async () => {
    setFetchingTo(true)
    await onResolve()
    setFetchingTo(false)
  }, [onResolve])

  const handleClickReject = useCallback(async () => {
    setFetchingTo(true)
    await onReject()
    setFetchingTo(false)
  }, [onReject])

  const handleClickComplete = useCallback(async () => {
    setFetchingTo(true)
    await onComplete()
    setFetchingTo(false)
  }, [onComplete])

  const handleClickRefund = useCallback(async () => {
    setFetchingTo(true)
    await onRefund()
    setFetchingTo(false)
  }, [onRefund])

  const getStatusActions = () => {
    return (
      <>
        {data.status === StatusType.Pending && (
          <ListItem icon={null}>
            <StatusAction>
              <RejectResolveButtons
                fullWidth
                isLoading={isFetch}
                rejectText="Отклонить"
                onReject={handleClickReject}
                disableReject={isFetch}
                resolveText="Подтвердить"
                onResolve={handleClickResolve}
                disableResolve={isFetch}
              />
            </StatusAction>
          </ListItem>
        )}
        {data.status === StatusType.NotCompleted && (
          <ListItem icon={null}>
            <StatusAction>
              <Caption>
                <Paragraph>
                  <strong>Проверьте выписку из банка</strong>
                </Paragraph>
                <Paragraph>
                  Если платежа нет в выписке, верните водителю деньги. Он сможет
                  еще раз подать заявку на вывод средств.
                </Paragraph>
                <Paragraph>
                  Если платеж есть в выписке, деньги уже отправлены водителю. Мы
                  завершим работу над этим платежом.
                </Paragraph>
              </Caption>
              <RejectResolveButtons
                fullWidth
                isLoading={isFetch}
                rejectText="Завершить платеж"
                onReject={handleClickComplete}
                disableReject={isFetch}
                resolveText="Вернуть деньги"
                onResolve={handleClickRefund}
                disableResolve={isFetch}
              />
            </StatusAction>
          </ListItem>
        )}
      </>
    )
  }

  return (
    <Wrapper>
      <Card>
        <HeaderWrapper>
          <HeaderItem
            icon={null}
            action={headerAction}
            bottomBorderWidth={1}
            bottomBorderColor="secondaryLightBlue"
          >
            <Typography fontSize={16} fontWeight="SemiBold">
              Подробности платежа
            </Typography>
          </HeaderItem>
        </HeaderWrapper>
        <BodyWrapper>
          <CardItem caption="Дата и время">
            {format(data.timestamp, 'dd.MM.yyyy в HH:mm')}
          </CardItem>
          <Participant
            name="Отправитель"
            value={data.fromParticipant}
            onClick={onClickFromParticipant}
          />
          <Participant
            name="Получатель"
            value={data.toParticipant}
            onClick={onClickToParticipant}
          />
          <CardItem caption="Основание">{data.reason}</CardItem>
          <CardItem caption="Сумма">
            {currencyToBase(data.amount, true)}
          </CardItem>
          {data.bankRequisites && (
            <BankRequisites
              bankName={data.bankRequisites.name}
              bankAccountOwner={data.bankRequisites.accountOwner}
              bankAccountNumber={data.bankRequisites.accountNumber}
            />
          )}
          {data.cardRequisites && (
            <CardItem caption="Карта">{data.cardRequisites.number}</CardItem>
          )}
        </BodyWrapper>
        <FooterWrapper>
          <Status
            value={data.status}
            onRefresh={
              onRefreshStatus && canRefreshStatus ? onRefreshStatus : undefined
            }
          />
          {isManageable ? getStatusActions() : null}
        </FooterWrapper>
      </Card>
      {(onClickNext || onClickPrev) && (
        <CardActions>
          {onClickPrev && (
            <CardActionButton
              onClick={onClickPrev}
              textColor="white"
              fontWeight="semi-bold"
            >
              <MiddleIcon name="squareLeftArrow" />
              <Spacer />
              Предыдущий платеж
            </CardActionButton>
          )}
          {onClickNext && (
            <CardActionButton
              onClick={onClickNext}
              textColor="white"
              fontWeight="semi-bold"
            >
              Следующий платеж
              <Spacer />
              <MiddleIcon name="squareRightArrow" />
            </CardActionButton>
          )}
        </CardActions>
      )}
    </Wrapper>
  )
}
export default React.memo<Props>(PaymentDetailsCard)
