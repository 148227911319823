import { batch, connect } from 'react-redux'

import { State } from 'reducers'
import { apiActions, apiSelectors } from 'api'
import { MyThunkDispatch } from 'api/reducer'
import { modalsActions } from 'shared/modals'
import { reset } from 'settings/features/aggregatorsList/actions'
import { getCurrentId } from 'settings/features/aggregatorsList/selectors'
import { onDeleteAggregator } from 'settings/features/aggregatorsList/thunks'
import { DeleteAggregatorModal } from 'settings/features/aggregatorsList/pages'
import { ModalsNames } from 'common/enums'

const mapStateToProps = (state: State) => ({
  currentId: getCurrentId(state),
  aggregatorsList: apiSelectors.park.aggregators.getAggregatorsList(state),
  isFetching: apiSelectors.park.aggregatorByIdDelete.getIsFetching(state),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  closeModal: () =>
    batch(() => {
      dispatch(modalsActions.closeModal(ModalsNames.DeleteAggregator))
      dispatch(reset())
      dispatch(apiActions.park.aggregatorByIdGet.reset())
    }),
  onDelete: ({ type, id }: any) => dispatch(onDeleteAggregator({ type, id })),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const DeleteAggregatorModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteAggregatorModal)
