import { Permission } from 'common/enums/permissions'

export enum Roles {
  PARK_CREATOR = 'park_creator',
}

export const permissionsByRoles: { [key: string]: Permission[] } = {
  [Roles.PARK_CREATOR]: [
    Permission.ViewDriverDetails,
    Permission.ChangeDriverContracts,
    Permission.ViewDriverRequisites,
    Permission.ViewTransactionInfo,
    Permission.ViewAllTransactions,
    Permission.ViewTransactionDetails,
    Permission.ProcessTransactions,
    Permission.ViewConfirmationTransactions,
    Permission.ViewNotCompletedTransactions,
    Permission.ProcessNotCompletedTransactions,
    Permission.ViewSuspectTransactions,
    Permission.ProcessSuspectTransactions,
    Permission.ViewEmployees,
    Permission.ViewEmployeeDetails,
    Permission.ViewLogs,
    Permission.ChangeEmployee,
    Permission.ChangePermissions,
    Permission.ViewParkRequisites,
    Permission.ChangeParkRequisites,
    Permission.ChangeAggregators,
    Permission.ChangeAutoTransfer,
    Permission.ChangePaymentsConditions,
    Permission.ViewTariff,
    Permission.ReplenishDepositBalance,
  ],
}
