import { ThunkAction } from 'redux-thunk'
import { State } from 'reducers'
import axios from 'axios'
import { FailureError } from 'common/utils/reduxApiFactory'
import { TApiError } from 'api/types'
import { postNotifyBotActions } from 'api/park/notify-bot/post/actions'
import {
  PostNotifyBotActions,
  PostNotifyBotParams,
} from 'api/park/notify-bot/post/types'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  PostNotifyBotActions
>

export const postNotifyBotRequest = (
  params: PostNotifyBotParams
): THandleRequest => async (dispatch) => {
  const { start, success, failure } = postNotifyBotActions
  dispatch(start())
  try {
    await axios.post(`erp/1/park/notify-bot`, params)
    dispatch(success())
  } catch (e) {
    if (e.response) {
      const data = e.response.data as TApiError
      const error: FailureError = new Error(data.message)
      error.data = data
      dispatch(failure(error))
      throw error
    }
  }
}
