import React, { useEffect, useState } from 'react'

import { BodyText, Icon, TransparentButton } from 'ui-kit/components'
import ListInput from 'common/components/formItems/ListInput'

import {
  CancelOrSave,
  ContentCard,
  ContentCardBody,
  ContentCardHeader,
  HBox,
} from 'shared/ui'

import { Props } from 'settings/features/parkAzon/containers/ParkAzonContainer'
import { Field, SubmissionError } from 'redux-form'
import {
  maxLength255,
  minLength3,
  required,
} from 'common/utils/fieldValidation'
import Alert from 'react-s-alert'
import Typography from 'ui-kit/typography'
import RenderGate from 'elastic-ui/shared/atoms/RenderGate'
import styled from 'styled-components'
import { Link } from 'ui-kit/atoms/Link'

const statusMap = {
  success: 'statusSuccess',
  error: 'statusError',
  inProgress: 'statusWait',
  pending: 'semiCheck',
} as const

const StatusIcon: React.FC<{
  status: 'success' | 'error' | 'inProgress' | 'pending'
}> = ({ children, status }) => (
  <Icon name={statusMap[status]} height={18} width={18}>
    <Typography fontColor={status === 'error' ? 'uiRed' : undefined}>
      {children}
    </Typography>
  </Icon>
)

const PhoneContainer = styled.div`
  border-bottom: 0.5px solid ${({ theme }) => theme.pallete.gray3};
  padding: 0 32px 8px 32px;
  margin-bottom: 18px;
`

const HelpLink = styled.span`
  font-size: 14px;
  text-decoration: underline;
  transform: translateX(-8px);
  position: relative;
  display: inline-block;
`

export const ParkAzon: React.FC<Props> = ({
  isConfirmed,
  isSubmitted,
  phone,
  isFetching,
  contractDate,
  contractNumber,
  confirmationError,
  initialize,
  handleSubmit,
  reset,
  postSettings,
}) => {
  const [isEdit, setIsEdit] = useState(false)
  const toggleEdit = () => {
    setIsEdit((prevState) => !prevState)
  }

  const phonePretty = `+ ${phone?.[0]} ${phone?.slice(1, 4)} ${phone?.slice(
    4,
    7
  )} ${phone?.slice(7, 9)} ${phone?.slice(9)}`

  useEffect(() => {
    initialize({
      phone: phonePretty,
      contract_date: contractDate,
      contract_num: contractNumber,
    })
  }, [initialize, contractDate, contractNumber, phonePretty])

  const handleEdit = async (values: any) => {
    try {
      await postSettings({
        login: values.login,
        password: values.password,
        park_contract: {
          contract_date: values.contract_date,
          contract_num: values.contract_num,
        },
      } as any)
      setIsEdit(false)
    } catch (e) {
      Alert.error('Ошибка', {
        customFields: {
          text:
            'При обработке запроса произошла ошибка. Проверьте данные и попробуйте еще раз',
        },
      })
      throw new SubmissionError(e)
    }
  }

  const handleCancel = () => {
    toggleEdit()
    reset()
  }

  return (
    <>
      <ContentCard>
        <ContentCardHeader
          title="Выплаты через АЗОН"
          icon="alfa"
          iconHackyMarginLeft={-5}
        >
          {!isEdit && (
            <TransparentButton
              onClick={toggleEdit}
              fontWeight="semi-bold"
              disabled={isFetching}
            >
              Настроить подключение
            </TransparentButton>
          )}
          {isEdit && (
            <CancelOrSave
              onSubmit={handleSubmit(handleEdit)}
              onCancel={handleCancel}
              isLoading={false}
            />
          )}
        </ContentCardHeader>
        <ContentCardBody isLoading={isFetching}>
          {isEdit ? (
            <>
              <Field
                name="phone"
                label="Номер телефона"
                component={ListInput}
                readonly
              />
              <Field
                name="login"
                label="Логин"
                validate={[required, minLength3, maxLength255]}
                component={ListInput}
              />
              <Field
                name="password"
                label="Пароль"
                validate={[required, minLength3, maxLength255]}
                component={ListInput}
                type="password"
              />
              <Field
                name="contract_num"
                label="Номер договора с банком"
                validate={required}
                component={ListInput}
              />
              <Field
                name="contract_date"
                label="Дата договора с банком"
                validate={required}
                component={ListInput}
              />
              <HBox x={0.5} />
              <Link href="https://958f9c0f-9435-497a-ac41-2ace0bdc57d6.selcdn.net/park/azon-add-user-instruction.pdf">
                <Icon name="book" color="blue" pointer width={16} height={16}>
                  <HelpLink>Как настроить учетную запись</HelpLink>
                </Icon>
              </Link>
            </>
          ) : (
            <>
              <PhoneContainer>
                <BodyText fontWeight="medium">{phonePretty}</BodyText>
                <Typography>
                  Номер телефона, выданный вам для регистрации на сервисе.
                </Typography>
              </PhoneContainer>
              {isSubmitted ? (
                <StatusIcon status="success">
                  Учетная запись добавлена
                </StatusIcon>
              ) : (
                <StatusIcon status="pending">
                  Учетная запись не добавлена
                </StatusIcon>
              )}
              <HBox />
              {confirmationError ? (
                <StatusIcon status="error">{confirmationError}</StatusIcon>
              ) : (
                <>
                  <RenderGate isOpen={Boolean(isSubmitted && isConfirmed)}>
                    <StatusIcon status="success">
                      Учетная запись подтверждена
                    </StatusIcon>
                  </RenderGate>
                  <RenderGate isOpen={Boolean(isSubmitted && !isConfirmed)}>
                    <StatusIcon status="inProgress">
                      Учетная запись на подтверждении
                    </StatusIcon>
                  </RenderGate>
                  <RenderGate isOpen={Boolean(!isSubmitted && !isConfirmed)}>
                    <StatusIcon status="pending">
                      Учетная запись не подтверждена
                    </StatusIcon>
                  </RenderGate>
                </>
              )}
            </>
          )}
        </ContentCardBody>
      </ContentCard>
    </>
  )
}
