import { ThunkAction } from 'redux-thunk'
import { State } from 'reducers'
import axios, { AxiosResponse } from 'axios'
import { FailureError } from 'common/utils/reduxApiFactory'
import { TApiError } from 'api/types'
import { getFuelSettingsActions } from 'api/fuel/settings/get/actions'
import { GetFuelSettingsActions } from 'api/fuel/settings/get/types'
import { FuelSettings } from 'api/fuel/settings/types'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  GetFuelSettingsActions
>

export const getFuelSettingsRequest = (): THandleRequest => async (
  dispatch
) => {
  const { start, update, success, failure } = getFuelSettingsActions
  dispatch(start())
  try {
    const response: AxiosResponse<FuelSettings> = await axios.get(
      `erp/1/fuel/settings`
    )
    dispatch(update(response.data))
    dispatch(success())
  } catch (e) {
    if (e.response) {
      const data = e.response.data as TApiError
      const error: FailureError = new Error(data.message)
      error.data = data
      dispatch(failure(error))
      throw error
    }
  }
}
