import { history } from 'store'
import { State } from 'reducers'
import { apiActions, apiSelectors } from 'api'
import { MyThunkDispatch, MyThunkResult } from 'api/reducer'
import { RoutesPath } from 'common/enums'
import { addUsedeskWidget } from 'common/utils/helpers'

export const onDidMount: () => MyThunkResult<void> = () => {
  return async (dispatch: MyThunkDispatch, getState: () => State) => {
    await Promise.all([
      dispatch(apiActions.employee.profile.handleRequest()),
      dispatch(apiActions.park.details.handleRequest()),
    ])

    try {
      await dispatch(apiActions.fuel.getFuelSummary.handleRequest())
    } catch (e) {
      console.error(e)
    }

    const profile = apiSelectors.employee.profile.getProfile(getState())
    if (!profile) return

    const hasProfileFullName = !!profile.full_name
    const isStepsRoute = history.location.pathname.includes(RoutesPath.Steps)

    if (!hasProfileFullName && !isStepsRoute) {
      history.replace(RoutesPath.StepsAggregators)
      return
    }

    const canViewAllTransaction = apiSelectors.employee.profile.getCanViewAllTransactions(
      getState()
    )
    const isPaymentsRoute = ([
      RoutesPath.MainPage,
      RoutesPath.Payments,
    ] as string[]).includes(history.location.pathname)

    if (!canViewAllTransaction && isPaymentsRoute) {
      history.replace(RoutesPath.Drivers)
    }

    // FIXME: найти способ вынести добавление виджета в другое место
    addUsedeskWidget()

    await Promise.resolve()
  }
}
