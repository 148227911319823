import React from 'react'
import { Switch } from 'react-router-dom'

import { history } from 'store'

import { RouteTab } from 'common/types/local/routeTab'
import { Permission, RoutesPath } from 'common/enums'

import { PrivateRoute } from 'shared/router'
import { HBox, Tabs } from 'shared/ui'
import { RequisitesTabContainer } from 'settings/features/requisitesTab'
import { ProfileTabContainer } from 'settings/features/profileTab'
import { AggregatorsTabContainer } from 'settings/features/aggregatorsTab'
import { PaymentSettingsTab } from 'settings/features/paymentSettingsTab'
import { OfdTab } from 'settings/features/ofdTab'

import { TariffTabContainer } from 'settings/features/tariffTab'
import { NotificationsTabContainer } from 'settings/features/notificationsTab'

import { ContainerProps } from 'settings/containers/SettingsContainer'

export class Settings extends React.Component<ContainerProps> {
  componentDidMount() {
    this.updateHistoryPath()
  }

  componentDidUpdate() {
    this.updateHistoryPath()
  }

  private getRouteTabs(): RouteTab[] {
    const routeTabs: RouteTab[] = [
      { label: 'Личные данные', path: RoutesPath.SettingsProfile, exact: true },
    ]

    if (this.checkPermission(Permission.ViewParkRequisites)) {
      routeTabs.unshift({
        label: 'Реквизиты',
        path: RoutesPath.Settings,
        exact: true,
      })
    }

    if (this.checkPermission(Permission.ChangeAggregators)) {
      routeTabs.push({
        label: 'Агрегаторы',
        path: RoutesPath.SettingsAggregators,
        exact: true,
      })
    }

    if (
      (this.checkPermission(Permission.ChangeAutoTransfer) ||
        this.checkPermission(Permission.ChangePaymentsConditions)) &&
      this.checkPermission(Permission.ViewPaymentsSettings)
    ) {
      routeTabs.push({
        label: 'Платежи и комиссии',
        path: RoutesPath.SettingsPayments,
        exact: true,
      })
    }

    if (this.checkPermission(Permission.ViewTariff)) {
      routeTabs.push({
        label: 'Тариф Mozen',
        path: RoutesPath.SettingsTariff,
        exact: false,
      })
    }

    if (this.checkPermission(Permission.ChangeAggregators)) {
      routeTabs.push({
        label: 'ОФД',
        path: RoutesPath.SettingsOfd,
        exact: true,
      })
    }

    if (this.checkPermission(Permission.ChangePaymentsConditions)) {
      routeTabs.push({
        label: 'Уведомления',
        path: RoutesPath.SettingsNotifications,
        exact: true,
      })
    }

    return routeTabs
  }

  private checkPermission(permission: Permission) {
    const { permissions } = this.props
    return permissions?.includes(permission)
  }

  private updateHistoryPath(): void {
    if (!this.checkCurrentTabVisibility()) {
      history.replace(this.getRouteTabs()[0].path)
    }
  }

  private checkCurrentTabVisibility(): boolean {
    const {
      match: { url },
    } = this.props
    const location: string = history.location.pathname

    return !(
      !this.checkPermission(Permission.ViewParkRequisites) && location === url
    )
  }

  render() {
    const routeTabs: RouteTab[] = this.getRouteTabs()
    return (
      <>
        <HBox />
        <Tabs list={routeTabs} />
        {this.checkCurrentTabVisibility() && (
          <Switch>
            <PrivateRoute
              exact
              path={RoutesPath.Settings}
              component={RequisitesTabContainer}
            />
            <PrivateRoute
              exact
              path={RoutesPath.SettingsProfile}
              component={ProfileTabContainer}
            />
            <PrivateRoute
              exact
              path={RoutesPath.SettingsAggregators}
              component={AggregatorsTabContainer}
            />
            <PrivateRoute
              exact
              path={RoutesPath.SettingsPayments}
              component={PaymentSettingsTab}
            />
            <PrivateRoute
              exact
              path={RoutesPath.SettingsOfd}
              component={OfdTab}
            />
            <PrivateRoute
              exact
              path={RoutesPath.SettingsTariff}
              component={TariffTabContainer}
            />
            <PrivateRoute
              exact
              path={RoutesPath.DepositTransactions}
              component={TariffTabContainer}
            />
            <PrivateRoute
              exact
              path={RoutesPath.SettingsNotifications}
              component={NotificationsTabContainer}
            />
          </Switch>
        )}
      </>
    )
  }
}
