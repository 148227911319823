import React from 'react'
import { TableText } from 'ui-kit/components'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  height: 32px;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.pallete.secondary};
`

export const Colgroup: React.FC = ({ children }) => {
  return (
    <Container>
      {typeof children === 'string' ? (
        <TableText bold color="darkGray">
          {children}
        </TableText>
      ) : (
        children
      )}
    </Container>
  )
}
