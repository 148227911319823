import { Action } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { State, TRootAction } from 'reducers'
import { resetAllStates } from 'actions'
import { history } from 'store'
import { RoutesPath } from 'common/enums'
import { apiActions } from 'api'
import { clearAuthTokens } from 'jwtAuth'

type ThunkResult<R> = ThunkAction<R, State, undefined, TRootAction>

export const onDidMount: () => ThunkResult<void> = () => {
  return (dispatch: ThunkDispatch<State, undefined, Action>) => {
    return dispatch(apiActions.auth.logout.handleRequest()).finally(() => {
      clearAuthTokens()
      dispatch(resetAllStates())
      history.replace(RoutesPath.SignIn)
    })
  }
}
