import React, { useEffect, useState } from 'react'
import { Col, HBox, Loader, Row, VBox } from 'shared/ui'
import { EmployeeInfoContainer } from 'employees/features/employeeInfo'
import { EmployeeRightsContainer } from 'employees/features/employeeRights'
import { EmployeesJournalContainer } from 'employees/features/employeesJournal'
import { ContainerProps } from 'employees/features/employeeDetails/containers/EmployeeDetailsContainer'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding: ${({ theme }) => theme.paddings.main}px;
`

const FlexStartRow = styled(Row)`
  align-items: flex-start;
`

export const EmployeeDetails: React.FC<ContainerProps> = ({
  isFetching,
  employee_id,
  canViewLogs,
  onDidMount,
}) => {
  const [firstRender, setFirstRender] = useState(false)
  useEffect(() => {
    onDidMount()
    setFirstRender(true)
  }, [onDidMount])

  if (isFetching || !firstRender) {
    return <Loader />
  }

  if (!employee_id) {
    return null
  }

  return (
    <>
      <Wrapper>
        <EmployeeInfoContainer />
        <HBox x={2} />

        <FlexStartRow>
          <Col percentage={50}>
            <EmployeeRightsContainer />
          </Col>
          <VBox />
          {canViewLogs ? (
            <Col percentage={50}>
              <EmployeesJournalContainer employee_id={employee_id} />
            </Col>
          ) : null}
        </FlexStartRow>
      </Wrapper>
    </>
  )
}
