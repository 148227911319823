import React from 'react'

import { RejectResolveButtons, SubBodyText } from 'ui-kit/components'
import { AggregatorType } from 'common/enums'
import { HBox, Modal } from 'shared/ui'
import { ContainerProps } from 'settings/features/aggregatorsList/containers/DeleteAggregatorModalContainer'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 16px;
  width: 392px;
`

export const DeleteAggregatorModal: React.FC<ContainerProps> = ({
  currentId,
  aggregatorsList,
  isFetching,
  closeModal,
  onDelete,
}) => {
  const aggregator = currentId ? aggregatorsList[currentId] : null
  const type = aggregator ? aggregator.type : AggregatorType.Yandex
  const handleDelete = () => {
    onDelete({ type, id: currentId })
  }
  return (
    <Modal
      open
      onClose={closeModal}
      noOverlayClick={false}
      closeButton
      overflow="initial"
      title="Удалить аккаунт"
      type="error"
    >
      <Wrapper>
        <SubBodyText color="gray8">
          Водители, подключенные к этому аккаунту не смогут видеть свой баланс в
          приложении и выводить деньги. Вам нужно будет завершить все заявки на
          вывод средств в разделе «Платежи»
        </SubBodyText>

        <HBox />
        <RejectResolveButtons
          rejectText="Не сейчас"
          resolveText="Удалить"
          onReject={closeModal}
          onResolve={handleDelete}
          disableReject={isFetching}
          disableResolve={isFetching}
          isLoading={isFetching}
          fullWidth
        />
      </Wrapper>
    </Modal>
  )
}
