import { setType } from 'settings/features/aggregatorsList/actions'

export {
  AggregatorsListContainer,
  AddAggregatorModalContainer,
} from 'settings/features/aggregatorsList/containers'

export const aggregatorsListActions = {
  setType,
}
