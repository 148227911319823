import { connect } from 'react-redux'

import { apiActions, apiSelectors } from 'api'
import { MyThunkDispatch } from 'api/reducer'
import { StatisticsWeekdayWithdraw } from 'analytics/features/statisticsWeekdayWithdraw/organisms'
import { State } from 'reducers'

const mapStateToProps = (state: State) => ({
  isStatisticsWeekdayWithdrawFetching: apiSelectors.park.statisticsWeekdayWithdraw.makeSelectIsFetching()(
    state
  ),
  statisticsWeekdayWithdraw: apiSelectors.park.statisticsWeekdayWithdraw.makeSelectCharData()(
    state
  ),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  fetchStatisticsWeekdayWithdraw: (from: string, to: string, id?: string) =>
    dispatch(
      apiActions.park.statisticsWeekdayWithdraw.handleRequest({
        date_start: from,
        date_end: to,
        park_aggregator_id: id,
      })
    ),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const StatisticsWeekdayWithdrawContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StatisticsWeekdayWithdraw)
