import React from 'react'
import styled from 'styled-components'

import { Icon, TransparentButton } from 'ui-kit/components'
import {
  ContentCard,
  ContentCardBody,
  ContentCardHeader as DefaultContentCardHeader,
} from 'shared/ui'

import Typography from 'ui-kit/typography'
import { currencyToBase } from 'common/utils/formatters'
import { BankType } from 'common/enums'
import { IconNames } from 'ui-kit'
import { ContainerProps } from 'settings/features/parkE2C/containers/ParkE2CContainer'
import { BankIntegrationModalContainer } from 'settings/features/bankIntegrationModal'

type E2CParks = BankType.AlfaE2C | BankType.Modul | BankType.Qiwi

type Payload = {
  title: string
  icon: IconNames
  iconWidth: number
  iconHeight: number
  iconHackyMarginLeft: number
  iconHackyMarginRight: number
}

type PayloadList = {
  [key in BankType]: Payload
}

type E2CPayloadList = Pick<PayloadList, E2CParks>

const alfaE2CPayload: Payload = {
  title: 'Счет в Альфа-Банке',
  icon: 'alfa',
  iconWidth: 42,
  iconHeight: 42,
  iconHackyMarginLeft: -9,
  iconHackyMarginRight: -5,
}
const modulPayload: Payload = {
  title: 'Счет в Модульбанке',
  icon: 'modul',
  iconWidth: 42,
  iconHeight: 42,
  iconHackyMarginLeft: -9,
  iconHackyMarginRight: -5,
}
const qiwiPayload: Payload = {
  title: 'Счет в QIWI',
  icon: 'qiwi',
  iconWidth: 42,
  iconHeight: 42,
  iconHackyMarginLeft: -9,
  iconHackyMarginRight: -5,
}

const e2cPayload: E2CPayloadList = {
  [BankType.AlfaE2C]: alfaE2CPayload,
  [BankType.Modul]: modulPayload,
  [BankType.Qiwi]: qiwiPayload,
}

function isE2CBank(type?: BankType | null): type is E2CParks {
  switch (type) {
    case BankType.AlfaE2C:
    case BankType.Modul:
    case BankType.Qiwi:
      return true
    default:
      return false
  }
}

const BalanceText = styled(Typography)`
  padding-left: 40px;
`

const ContentCardHeader = styled(DefaultContentCardHeader)`
  height: 56px;
  padding: 0 16px;
`

export const ParkE2C: React.FC<ContainerProps> = ({
  data,
  bankType,
  openEditE2CModal,
  isEditE2CModalOpened,
}) => {
  const isConnected = Boolean(data?.masked_login)

  if (!isE2CBank(bankType)) {
    return null
  }

  const bankPayload = e2cPayload[bankType]

  return (
    <>
      {!isConnected && (
        <ContentCard zeroState withMinHeight={false}>
          <ContentCardHeader
            title={bankPayload.title}
            icon={bankPayload.icon}
            iconWidth={bankPayload.iconWidth}
            iconHeight={bankPayload.iconHeight}
            iconHackyMarginLeft={bankPayload.iconHackyMarginLeft}
            iconHackyMarginRight={bankPayload.iconHackyMarginRight}
            border={false}
          >
            <TransparentButton
              onClick={openEditE2CModal}
              fontWeight="semi-bold"
            >
              Подключить
            </TransparentButton>
          </ContentCardHeader>
        </ContentCard>
      )}
      {isConnected && (
        <ContentCard>
          <ContentCardHeader
            title={bankPayload.title}
            icon={bankPayload.icon}
            iconWidth={bankPayload.iconWidth}
            iconHeight={bankPayload.iconHeight}
            iconHackyMarginLeft={bankPayload.iconHackyMarginLeft}
            iconHackyMarginRight={bankPayload.iconHackyMarginRight}
          >
            <TransparentButton
              onClick={openEditE2CModal}
              fontWeight="semi-bold"
            >
              Настроить подключение
            </TransparentButton>
          </ContentCardHeader>
          <ContentCardBody>
            {data?.balance !== undefined && data?.balance !== null ? (
              <BalanceText variant="Body1">
                {currencyToBase(data.balance.amount, true)}
              </BalanceText>
            ) : (
              <Icon name="statusBig">Подключен</Icon>
            )}
          </ContentCardBody>
        </ContentCard>
      )}
      {isEditE2CModalOpened && <BankIntegrationModalContainer />}
    </>
  )
}
