import styled from 'styled-components'
import Typography from 'ui-kit/typography'
import React from 'react'

type Props = {
  caption: React.ReactNode
  className?: string
  children?: React.ReactNode
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Mark: React.FC<Props> = ({ caption, children, className }) => {
  return (
    <Wrapper className={className}>
      <Typography variant="Caption1" fontColor="gray8">
        {caption}
      </Typography>
      {children}
    </Wrapper>
  )
}

export default React.memo<Props>(Mark)
