import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingContent,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'
import { EuiIconEnhanced } from 'elastic-ui/shared/atoms/EnhancedIcon'
import { EnhancedPanel } from 'elastic-ui/shared/atoms/EnhancedPanel/EnhancedPanel'
import { LimitContainer } from 'elastic-ui/shared/atoms/LimitContainer'
import React from 'react'
import Title from './atoms/Title'

interface Props {
  iconType: string
  iconColor: string
  isLoading: boolean
  title: string
  description: string
  children: JSX.Element
  isFirstTime: boolean
}

const DescriptionAndActionsTemplate: React.FC<Props> = ({
  iconType,
  iconColor,
  isLoading,
  title,
  description,
  children,
  isFirstTime,
}) => (
  <EnhancedPanel>
    <EuiFlexGroup gutterSize="s">
      <EuiFlexItem grow={false}>
        <EuiSpacer size="xs" />
        <EuiIconEnhanced
          type={isLoading ? 'empty' : iconType}
          color={iconColor}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <LimitContainer maxWidth={527}>
          {isLoading ? (
            <EuiLoadingContent lines={3} />
          ) : (
            <>
              <EuiTitle size="xs">
                <Title>{title}</Title>
              </EuiTitle>
              <EuiText>{description}</EuiText>
            </>
          )}
        </LimitContainer>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        {isLoading || isFirstTime ? null : children}
      </EuiFlexItem>
    </EuiFlexGroup>
  </EnhancedPanel>
)

export default DescriptionAndActionsTemplate
