import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { State } from 'reducers'
import { MyThunkDispatch } from 'api/reducer'
import { FormNames, ModalsNames } from 'common/enums'
import { modalsActions } from 'shared/modals'
import { onSaveQiwiSettingsMinBalance } from 'settings/features/notificationsSettings/thunks'
import { getNotificationsQiwiSettingsValues } from 'settings/features/notificationsSettings/selectors'
import { AddNotificationsQiwiSettingsModal } from 'settings/features/notificationsSettings/pages'

export type FormProps = {
  min_balance: string | null
}

const mapStateToProps = (state: State) => ({
  initialValues: getNotificationsQiwiSettingsValues(state),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  onSave: (values: FormProps) =>
    dispatch(onSaveQiwiSettingsMinBalance({ values })),
  closeModal: () =>
    dispatch(
      modalsActions.closeModal(ModalsNames.AddNotificationsQiwiSettings)
    ),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const Form = reduxForm<FormProps, ContainerProps>({
  form: FormNames.NotificationsQiwiSettings,
})(AddNotificationsQiwiSettingsModal)

export const AddNotificationsQiwiSettingsModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Form)
