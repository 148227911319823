import React from 'react'

import { Icon, TableText } from 'ui-kit/components'
import { IconNames, ColorTypes } from 'ui-kit'

import { VBox } from 'shared/ui'
import {
  TariffTransactionStatus,
  tariffTransactionStatusToString,
} from 'common/enums'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  align-items: center;
`

interface Props {
  status: TariffTransactionStatus | boolean
}

function switchColor(status: TariffTransactionStatus): ColorTypes | undefined {
  switch (status) {
    case TariffTransactionStatus.Authorized:
    case TariffTransactionStatus.Succeeded:
      return 'green'
    case TariffTransactionStatus.Acknowledged:
    case TariffTransactionStatus.Pending:
    case TariffTransactionStatus.Processing:
      return 'gray7'
    case TariffTransactionStatus.NotCompleted:
    case TariffTransactionStatus.Declined:
    case TariffTransactionStatus.Failed:
      return 'red'
    default:
      return undefined
  }
}

function switchIconName(
  status: TariffTransactionStatus
): IconNames | undefined {
  switch (status) {
    case TariffTransactionStatus.Authorized:
    case TariffTransactionStatus.Succeeded:
      return 'statusSuccess'
    case TariffTransactionStatus.Acknowledged:
    case TariffTransactionStatus.Pending:
    case TariffTransactionStatus.Processing:
      return 'statusWait'
    case TariffTransactionStatus.NotCompleted:
    case TariffTransactionStatus.Declined:
    case TariffTransactionStatus.Failed:
      return 'statusError'
    default:
      return undefined
  }
}

export const StatusCell: React.FC<Props> = ({ status }) => {
  const iconName =
    typeof status !== 'boolean' ? switchIconName(status) : undefined

  return typeof status === 'boolean' ? (
    <Container>
      <Icon name={status ? 'statusSuccess' : 'statusError'} />
      <VBox x={0.5} />
      <TableText color={status ? 'green' : 'red'}>
        {status ? 'Оплачено' : 'Не оплачено'}
      </TableText>
    </Container>
  ) : (
    <Container>
      {iconName && <Icon name={iconName} />}
      <VBox x={0.5} />
      <TableText color={switchColor(status)}>
        {tariffTransactionStatusToString(status)}
      </TableText>
    </Container>
  )
}
