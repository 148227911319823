import { createApiReducer } from 'common/utils/reduxApiFactory'
import { deleteNotificationsBotActions } from 'api/park/notifications/telegram/bot/delete/actions'
import { DeleteNotificationsBotState } from 'api/park/notifications/telegram/bot/delete/types'

export const initialState: DeleteNotificationsBotState = {
  status: null,
  data: null,
}

export const deleteNotificationsBotReducer = createApiReducer(
  deleteNotificationsBotActions,
  initialState
)
