export enum AggregatorType {
  Yandex = 'yandex',
  Gett = 'gett',
  Citymobil = 'citymobil',
  DiDi = 'didi',
}

export const aggregatorTypeToString = (aggregator: AggregatorType): string => {
  switch (aggregator) {
    case AggregatorType.Yandex:
      return 'Яндекс.Такси/Uber'
    case AggregatorType.Gett:
      return 'Gett'
    case AggregatorType.Citymobil:
      return 'Ситимобил'
    case AggregatorType.DiDi:
      return 'DiDi'
    default:
      return aggregator
  }
}
