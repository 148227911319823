import { combineReducers } from 'redux'
import logoutActions, { TLogoutAction } from 'api/auth/logout/actions'
import { LogoutState, reducer as logout } from 'api/auth/logout/reducer'
import * as logoutSelectors from 'api/auth/logout/selectors'
import { handleRequest as logoutRequest } from 'api/auth/logout/thunks'

export interface AuthState {
  logout: LogoutState
}

export type TAuthAction = TLogoutAction

export const authReducer = combineReducers({
  logout,
})

export const authActions = {
  logout: {
    handleRequest: logoutRequest,
    reset: logoutActions.reset,
  },
}

export const authSelectors = {
  logout: logoutSelectors,
}
