import { createSelector } from 'reselect'
import { State } from 'reducers'
import { EmployeeStatus } from 'common/enums'

export const getIsFetching = (state: State) =>
  state.api.employees.employeesByIdGet.status === 'loading'

const getData = (state: State) => state.api.employees.employeesByIdGet.data

export const getId = createSelector(getData, (data) => (data ? data.id : null))

export const getInfo = createSelector(getData, (data) => data || null)

export const getIsBlocked = createSelector(getData, (data) =>
  Boolean(data && data.status === EmployeeStatus.Inactive)
)

export const getIsParkCreator = createSelector(getData, (data) =>
  Boolean(data && data.park_creator)
)

export const getEmployeeFormValues = createSelector(getInfo, (info) =>
  info
    ? {
        full_name: info.full_name,
        position: info.position,
        phone: info.phone,
        email: info.email,
      }
    : undefined
)

export const getPermissions = createSelector(getInfo, (info) =>
  info ? info.permissions : []
)
