import { EuiLoadingContent, EuiText } from '@elastic/eui'
import { TextSize } from '@elastic/eui/src/components/text/text'
import styled from 'styled-components'

interface FontInfo {
  size: number
  lineHeight: number
}

const sizes: Record<TextSize, FontInfo> = {
  xs: { size: 0.75, lineHeight: 1.125 },
  s: { size: 0.875, lineHeight: 1.3125 },
  m: { size: 1, lineHeight: 1.5 },
}

const mapTextSizeToRem = (size: TextSize) => sizes[size]

const EnhancedLoadingContent = styled(EuiLoadingContent)<{ size?: TextSize }>`
  & .euiLoadingContent__singleLine {
    height: ${({ size = 'm' }) => mapTextSizeToRem(size).size}rem;
    margin-button: ${({ size = 'm' }) => mapTextSizeToRem(size).lineHeight}rem;
  }
`

export default EnhancedLoadingContent
