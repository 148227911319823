import { handleActions } from 'redux-actions'

import { Payloads, SET_LOADING } from 'auth/signIn/actions'

export interface SignInState {
  isFetching?: boolean
}

const initialState: SignInState = {
  isFetching: false,
}

const handleSetLoading = (
  state: SignInState,
  action: SET_LOADING
): SignInState => ({
  ...state,
  isFetching: action.payload,
})

export default handleActions<SignInState, Payloads>(
  {
    [SET_LOADING]: handleSetLoading,
  },
  initialState
)
