import { connect } from 'react-redux'
import { State } from 'reducers'
import { apiSelectors } from 'api'
import { DriverAccounts } from 'drivers/features/driverAccounts/organisms'

const mapStateToProps = (state: State) => ({
  accounts: apiSelectors.driversById.get.getAccounts(state),
})

const mapDispatchToProps = () => ({})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const DriverAccountsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DriverAccounts)
