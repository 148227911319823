import React from 'react'
import styled from 'styled-components'
import { BodyText } from 'ui-kit/components'

interface Props {
  children: string
  href?: string
  backgroundColor: string
  textColor: string
}

const Link = styled.a`
  text-decoration: none;
  cursor: default;
`

const Panel = styled.div<{
  backgroundColor: string
  textColor: string
  clickable: boolean
}>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  cursor: ${({ clickable }) => (clickable ? 'pointer;' : 'default')};
  text-align: center;
  padding: 9px 0;
  text-decoriation: none;
  color: ${({ textColor }) => textColor};

  & * {
    color: ${({ textColor }) => textColor};
  }

  &:hover {
    ${({ clickable }) => (clickable ? 'text-decoration: underline;' : '')}
  }
`

const InfoBanner: React.FC<Props> = ({
  children,
  backgroundColor,
  textColor,
  href,
}) => (
  <Link href={href}>
    <Panel
      backgroundColor={backgroundColor}
      textColor={textColor}
      clickable={!!href}
    >
      <BodyText>{children}</BodyText>
    </Panel>
  </Link>
)

export default InfoBanner
