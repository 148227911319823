import { ThunkAction } from 'redux-thunk'
import { State } from 'reducers'
import axios from 'axios'
import { FailureError } from 'common/utils/reduxApiFactory'
import { TApiError } from 'api/types'
import { parkPaymentSettingsDeleteActions } from 'api/park/payment-settings/delete/actions'
import {
  ParkPaymentSettingsDeleteActions,
  ParkPaymentSettingsDeleteParams,
} from 'api/park/payment-settings/delete/types'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  ParkPaymentSettingsDeleteActions
>

export const deleteParkPaymentSettingsRequest = ({
  payment_settings_id,
}: ParkPaymentSettingsDeleteParams): THandleRequest => async (dispatch) => {
  const { start, success, failure } = parkPaymentSettingsDeleteActions
  dispatch(start())
  try {
    await axios.delete(`erp/1/park/payment-settings/${payment_settings_id}`)
    dispatch(success())
  } catch (e) {
    if (e.response) {
      const data = e.response.data as TApiError
      const error: FailureError = new Error(data.message)
      error.data = data
      dispatch(failure(error))
    }
    throw e
  }
}
