import { EuiBasicTable, EuiSpacer, EuiText, formatDate } from '@elastic/eui'
import IconTitle from 'elastic-ui/referral-program/molecules/IconTitle'
import { EnhancedPanel } from 'elastic-ui/shared/atoms/EnhancedPanel/EnhancedPanel'
import { LimitContainer } from 'elastic-ui/shared/atoms/LimitContainer'
import RenderGate from 'elastic-ui/shared/atoms/RenderGate'
import React from 'react'
import { Application } from './types'

interface Props {
  isLoading: boolean
  items: Application[]
  onItemDeleteClick: (id: string) => void
}

const NewApplications: React.FC<Props> = ({
  items,
  isLoading,
  onItemDeleteClick,
}) => (
  <EnhancedPanel isLoading={items.length === 0 && isLoading}>
    <IconTitle size="xs" iconType="magic">
      Новые заявки
    </IconTitle>
    <EuiSpacer size="s" />
    <LimitContainer maxWidth={520}>
      <EuiText>
        Заявки появятся здесь, когда приглашенные заполнят анкету
        по&nbsp;реферальной ссылке, и исчезнут, когда вы зарегистрируете их в
        диспетчерской.
      </EuiText>
    </LimitContainer>
    <RenderGate isOpen={items.length > 0}>
      <EuiSpacer size="m" />
      <EuiBasicTable
        loading={isLoading}
        noItemsMessage="Загрузка..."
        columns={[
          {
            name: 'Дата',
            width: '84px',
            render: (item: Application) => formatDate(item.date, 'DD.MM.YY'),
          },
          {
            name: 'Заявка',
            render: (item: Application) => `${item.phoneNumber}, ${item.name}`,
          },
          {
            actions: [
              // They said we might need this in the future
              //
              // {
              //   name: 'Удалить',
              //   description: 'Удалить заявку',
              //   icon: 'trash',
              //   type: 'icon',
              //   color: 'danger',
              //   onClick: (item) => onItemDeleteClick(item.id),
              // },
            ],
          },
        ]}
        items={isLoading ? [] : items}
      />
    </RenderGate>
  </EnhancedPanel>
)

export default NewApplications
