import styled from 'styled-components'

import { ColorTypes } from 'ui-kit'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 56px;
  padding: 11px 16px;
  box-sizing: border-box;
`

interface IconWrapperProps {
  color: ColorTypes
}

export const IconWrapper = styled('span')<IconWrapperProps>`
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, color }) => theme.pallete[color]};
  border-radius: 50%;
  margin-right: 16px;

  .muk-icon {
    width: 20px;
    height: 20px;
  }
`
