export const phoneMask = '+7 999 999-99-99'

export const dateMask = '99.99.9999'

export const bankAccountMask = '99999-999-9-9999-9999999'

export const phonePattern = /^\+([0-9])\s\d{3}\s\d{3}(-\d{2}){2}$/

export const withoutDigits = /^\D*$/

export const checkSpacesPattern = /^$|.*\S+.*/

export const integerPattern = /^[0-9]*$/

export const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const datePattern = /^([0-2][0-9]|(3)[0-1])(\.)(((0)[0-9])|((1)[0-2]))(\.)\d{4}$/

export const driverContractPattern = /^[0-9a-zа-яё/-]*$/i

export const namePattern = /^[-а-яА-Яa-zA-ZёЁ\s]{1,90}$/i

export const uzaPattern = /^[-а-яА-Яa-zA-ZёЁ\s\d.@_+]{1,50}$/i
