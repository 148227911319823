import React, { useEffect, useMemo } from 'react'
import { Switch } from 'react-router-dom'

import { RoutesPath } from 'common/enums'
import { RouteTab } from 'common/types/local/routeTab'
import { PrivateRoute } from 'shared/router'
import { HBox, Loader, Tabs } from 'shared/ui'
import { AllListContainer } from 'payments/features/allList'
import { ConfirmationListContainer } from 'payments/features/confirmationList'
import { ManualConfirmationListContainer } from 'payments/features/manualConfirmationList'
import { NotCompletedListContainer } from 'payments/features/notCompletedList'
import { PaymentsReportTab } from 'payments/features/reportsTab'

import { ContainerProps } from 'payments/containers/PaymentsContainer'

export const Payments: React.FC<ContainerProps> = ({
  isBankTypeManual,
  canViewConfirmationTransactions,
  canViewNotCompletedTransactions,
  fetchTransactionsCounters,
  transactionsCounters,
}) => {
  useEffect(() => {
    const getTransactionsCounters = async () => {
      await fetchTransactionsCounters()
    }
    getTransactionsCounters()
  }, [fetchTransactionsCounters])

  const routeTabs: RouteTab[] = useMemo(() => {
    const result = [
      { label: 'Все', path: RoutesPath.Payments, exact: true },
    ] as RouteTab[]

    if (canViewConfirmationTransactions) {
      result.push({
        label: isBankTypeManual ? 'Ожидают выплаты' : 'Требуют подтверждения',
        path: RoutesPath.PaymentsConfirmation,
        exact: true,
        badge: transactionsCounters?.pending,
      })
    }

    if (canViewNotCompletedTransactions && !isBankTypeManual) {
      result.push({
        label: 'Возвраты из банка',
        path: RoutesPath.PaymentsNotCompleted,
        exact: true,
        badge: transactionsCounters?.not_completed,
      })
    }

    result.push({
      label: 'Экспорт',
      path: RoutesPath.PaymentsReport,
      exact: true,
      align: 'right',
    })

    return result
  }, [
    canViewConfirmationTransactions,
    canViewNotCompletedTransactions,
    isBankTypeManual,
    transactionsCounters,
  ])

  if (!transactionsCounters) {
    return <Loader absolute />
  }

  return (
    <>
      <HBox />
      <Tabs list={routeTabs} />
      <Switch>
        <PrivateRoute
          exact
          path={RoutesPath.Payments}
          component={AllListContainer}
        />
        {canViewConfirmationTransactions && (
          <PrivateRoute
            exact
            path={RoutesPath.PaymentsConfirmation}
            component={
              isBankTypeManual
                ? ManualConfirmationListContainer
                : ConfirmationListContainer
            }
          />
        )}
        {canViewNotCompletedTransactions && !isBankTypeManual && (
          <PrivateRoute
            exact
            path={RoutesPath.PaymentsNotCompleted}
            component={NotCompletedListContainer}
          />
        )}
        <PrivateRoute
          exact
          path={RoutesPath.PaymentsReport}
          component={PaymentsReportTab}
        />
      </Switch>
    </>
  )
}
