import { connect } from 'react-redux'
import { State } from 'reducers'
import { apiSelectors } from 'api'
import { MyThunkDispatch } from 'api/reducer'
import { onDidMount } from 'settings/features/notificationsTab/thunks'
import { getIsFetching } from 'settings/features/notificationsTab/selectors'
import { NotificationsTab } from 'settings/features/notificationsTab/organisms'

export const mapStateToProps = (state: State) => ({
  isFetching: getIsFetching(state),
  isNotificationsAvailable: apiSelectors.park.notificationsGet.getIsNotificationsAvailable(
    state
  ),
})

export const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  onDidMount: () => dispatch(onDidMount()),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const NotificationsTabContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsTab)
