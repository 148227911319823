import React from 'react'
import styled from 'styled-components'

import { RoutesPath } from 'common/enums'
import { HBox } from 'shared/ui'

import { DriversToolbarLink } from '../../molecules'

import { ContainerProps } from '../../containers/DriversToolbarContainer'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const DriversToolbar: React.FC<ContainerProps> = () => (
  <Container>
    <DriversToolbarLink
      title="Реферальная программа"
      url={RoutesPath.DriversReferralProgram}
      iconName="refferal"
      titleColor="blue"
    />
    <HBox x={0.3} />
    <DriversToolbarLink
      title="Загрузка договоров"
      url={RoutesPath.DriversUploadContracts}
      iconName="renewEdgvr"
      titleColor="blue"
    />
    <HBox x={0.3} />
    <DriversToolbarLink
      title="Уведомления водителям"
      url={RoutesPath.DriversNotification}
      iconName="mail"
      titleColor="blue"
    />
  </Container>
)
