import React, { useState } from 'react'
import styled from 'styled-components'

import { FontWeightTypes, IconNames } from 'ui-kit'

import { Icon, SubBodyText } from 'ui-kit/components'

import { Base, DefaultProps } from 'ui-kit/components/Buttons/Base/Base'

const Container = styled(Base)<{
  hovered?: boolean
  first?: boolean
  last?: boolean
}>`
  display: flex;
  width: 100%;
  height: 48px;
  justify-content: flex-start;

  background-color: ${({ hovered, theme }) => hovered && theme.pallete.gray2};

  &::before {
    background-color: transparent;
  }

  border-radius: 0;
  border-top-left-radius: ${({ first }) => first && '10px'};
  border-top-right-radius: ${({ first }) => first && '10px'};
  border-bottom-left-radius: ${({ last }) => last && '10px'};
  border-bottom-right-radius: ${({ last }) => last && '10px'};
`

const IconSpacer = styled.div`
  width: 24px;
  height: 24px;
`
const VSpace = styled.div`
  width: 16px;
`

interface Props extends DefaultProps {
  fontWeight?: FontWeightTypes
  iconName?: IconNames
  critical?: boolean
  first?: boolean
  last?: boolean
}

export const OptionsButton: React.FC<Props> = ({
  children,
  disabled,
  fontWeight,
  iconName,
  critical,
  first,
  last,
  onClick,
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const hoveredColor = critical ? 'red' : 'blue'
  const subBodyTextColor = isHovered ? hoveredColor : 'black'
  const iconColor = isHovered ? hoveredColor : 'gray5'
  return (
    <Container
      first={first}
      last={last}
      onClick={onClick}
      hovered={isHovered}
      onMouseEnter={disabled ? undefined : () => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {iconName ? (
        <IconSpacer>
          <Icon
            pointer={!disabled}
            name={iconName}
            color={iconColor || subBodyTextColor}
          />
        </IconSpacer>
      ) : null}
      {iconName && children ? <VSpace /> : null}
      {children ? (
        <SubBodyText color={subBodyTextColor} fontWeight={fontWeight}>
          {children}
        </SubBodyText>
      ) : null}
    </Container>
  )
}
