export enum RoutesPath {
  MainPage = '/',
  SignIn = '/auth',
  SignUp = '/auth/sign-up',
  SignOut = '/auth/sign-out',
  SignUpSuccess = '/auth/sign-up/success',
  EmailConfirmationLost = '/auth/sign-up/email-confirmation-lost',

  EmailConfirmation = '/email/verification',

  Steps = '/steps',
  StepsPayments = '/steps/payments',
  StepsAggregators = '/steps/aggregators',
  StepsDrivers = '/steps/drivers',
  StepsEmployees = '/steps/employees',

  ResetPassword = '/password/reset/confirm',
  Profile = '/profile',

  Analytics = '/analytics',
  Payments = '/payments',
  PaymentsConfirmation = '/payments/confirmation',
  PaymentsNotCompleted = '/payments/not-completed',
  PaymentsReport = '/payments/report',
  Drivers = '/drivers',
  DriversUploadContracts = '/drivers/upload-contracts',
  DriversNotification = '/drivers/notification',
  DriverDetails = '/drivers/:park_id/:driver_id',
  DriverSettings = '/drivers/:park_id/:driver_id/settings',
  DriverAccounts = '/drivers/:park_id/:driver_id/accounts',
  DriverCards = '/drivers/:park_id/:driver_id/cards',
  DriversReferralProgram = '/drivers/referral-program',
  AntiFraud = '/antifraud',
  Employees = '/employees',
  EmployeeDetails = '/employees/:employee_id',
  Settings = '/settings',
  SettingsProfile = '/settings/profile',
  SettingsAggregators = '/settings/aggregators',
  SettingsPayments = '/settings/payments',
  SettingsTariff = '/settings/tariff',
  SettingsOfd = '/settings/ofd',
  DepositTransactions = '/settings/tariff/transactions',
  SettingsNotifications = '/settings/notifications',
  Fuel = '/fuel',
}
