import React, { useCallback, useMemo, useState } from 'react'

import { Icon, SubBodyText, TransparentButton } from 'ui-kit/components'
import { formatWithLocale } from 'common/utils/dates'
import {
  ContentCard,
  ContentCardBody,
  HBox,
  Row,
  ToggleOptions,
  VBox,
} from 'shared/ui'
import styled from 'styled-components'

const StyledRow = styled(Row)`
  justify-content: space-between;
`
const NoWrap = styled.div`
  white-space: nowrap;
`

interface Props {
  contract: {
    contract_number: string
    contract_date: string
  } | null
  openAddDriverContractModal: () => void
  openDeleteDriverContractModal: () => void
}

export const DriverContract: React.FC<Props> = ({
  contract,
  openAddDriverContractModal,
  openDeleteDriverContractModal,
}) => {
  const [isToggleOption, setIsToggleOption] = useState(false)

  const handleClickEdit = useCallback(() => {
    openAddDriverContractModal()
    setIsToggleOption(false)
  }, [openAddDriverContractModal])

  const handleClickDelete = useCallback(() => {
    openDeleteDriverContractModal()
    setIsToggleOption(false)
  }, [openDeleteDriverContractModal])

  const handleClickToggle = useCallback(
    () => setIsToggleOption((prevValue) => !prevValue),
    []
  )

  const toggleOptions = useMemo(() => {
    return [
      {
        text: 'Редактировать',
        icon: 'edit',
        action: handleClickEdit,
      },
      {
        text: 'Удалить',
        icon: 'deletion',
        critical: true,
        action: handleClickDelete,
      },
    ]
  }, [handleClickDelete, handleClickEdit])

  return (
    <ContentCard>
      <ContentCardBody height={126}>
        <StyledRow>
          <Row>
            <Icon
              name={contract ? 'contract' : 'modalWarning'}
              color={contract ? 'lightBlue' : undefined}
              backgroundColor={contract ? 'lightBlueMuted' : 'red'}
            />
            <VBox x={0.5} />
            <SubBodyText fontWeight="medium">Договор</SubBodyText>
            <VBox x={0.5} />
          </Row>
          {contract ? (
            <ToggleOptions
              position="right"
              active={isToggleOption}
              onClick={handleClickToggle}
              options={toggleOptions}
            />
          ) : (
            <TransparentButton
              fontWeight="semi-bold"
              onClick={openAddDriverContractModal}
            >
              Добавить
            </TransparentButton>
          )}
        </StyledRow>
        <HBox />
        <Row>
          {contract ? (
            <SubBodyText>
              <NoWrap>{`№ ${contract.contract_number}`}</NoWrap>{' '}
              <NoWrap>{`от ${formatWithLocale(
                contract.contract_date
              )} г.`}</NoWrap>
            </SubBodyText>
          ) : (
            <SubBodyText>Договор не загружен</SubBodyText>
          )}
          <VBox x={0.5} />
        </Row>
      </ContentCardBody>
    </ContentCard>
  )
}
