import axios from 'axios'
import { BaseAction } from 'redux-actions'
import { ThunkAction } from 'redux-thunk'

import { State } from 'reducers'
import {
  failure,
  start,
  success,
} from 'api/park/allow-auto-transfer/post/actions'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  BaseAction
>

export const HANDLE_REQUEST: BaseAction['type'] =
  'api/park/allow-auto-transfer/post->HANDLE_REQUEST'

export const handleRequest = ({ values }: any): THandleRequest => async (
  dispatch
) => {
  dispatch(start())
  try {
    await axios.post(`erp/1/park/allow-auto-transfer`, {
      ...values,
    })
    dispatch(success())
  } catch (e) {
    if (e.response) {
      dispatch(failure(e.response.data))
    }
    throw e
  }
}
