import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import ListItem from 'ui-kit/atoms/ListItem/ListItem'
import Mark from 'ui-kit/atoms/Mark/Mark'
import { Icon, SmallLoader } from 'ui-kit/components'
import gsap from 'gsap'
import type { TimelineLite as TimelineLiteType } from 'gsap'
import Typography from 'ui-kit/typography'

export enum StatusType {
  Authorized,
  Pending,
  Acknowledged,
  Processed,
  Completed,
  NotCompleted,
  Declined,
  Failed,
}

export type Props = {
  value: StatusType
  onRefresh?: () => Promise<void>
}

function getStatusText(status: StatusType) {
  switch (status) {
    case StatusType.Completed:
      return 'Завершен'
    case StatusType.NotCompleted:
      return 'Не завершен'
    case StatusType.Pending:
      return 'Ждет подтверждения'
    case StatusType.Processed:
      return 'Обрабатывается в банке'
    case StatusType.Acknowledged:
      return 'В очереди'
    case StatusType.Authorized:
      return 'Разрешен'
    case StatusType.Declined:
      return 'Отклонен'
    case StatusType.Failed:
      return 'Ошибка'
    default:
      return 'Неизвестный статус'
  }
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const HoveredIcon = styled(Icon)`
  border-radius: 40px;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: -8px;

  &:hover {
    background-color: ${({ theme }) => theme.pallete.secondaryTableButton};
  }
`

const StatusItem = styled(ListItem)`
  padding-top: 8px;
`

const Status: React.FC<Props> = ({ value, onRefresh }) => {
  const theme = useTheme()
  const statusTextRef = useRef<HTMLDivElement>(null)
  const statusTextRefreshCompletedAnimation = useRef<TimelineLiteType>()
  const [isRefreshing, setIsRefreshing] = useState(false)

  const isFirstRender = useRef(true)
  useEffect(() => {
    if (isFirstRender.current) {
      statusTextRefreshCompletedAnimation.current = gsap
        .timeline({ paused: true })
        .from(statusTextRef.current, {
          immediateRender: false,
          duration: 2,
          color: theme.pallete.uiGreen,
          ease: 'expo.in',
          clearProps: 'color',
        })
      isFirstRender.current = false
    }
    return () => {
      statusTextRefreshCompletedAnimation.current?.clear()
    }
  }, [theme.pallete.uiGreen])

  const handleRefresh = useCallback(async () => {
    setIsRefreshing(true)
    try {
      statusTextRefreshCompletedAnimation.current?.progress(1, false)
      if (onRefresh) {
        await onRefresh()
      }
      statusTextRefreshCompletedAnimation.current?.restart()
    } catch (e) {
      console.error(e)
    }
    setIsRefreshing(false)
  }, [onRefresh])

  const refreshAction = useMemo(() => {
    if (!onRefresh) {
      return undefined
    }
    if (isRefreshing) {
      return <SmallLoader />
    }
    return (
      <HoveredIcon
        name="update"
        onClick={handleRefresh}
        pointer
        color="accentPrimary"
      />
    )
  }, [handleRefresh, isRefreshing, onRefresh])

  const isWarning = value === StatusType.NotCompleted

  return (
    <Wrapper>
      <StatusItem
        icon={null}
        action={refreshAction}
        topBorderWidth={1}
        topBorderColor="secondaryLightBlue"
      >
        <Mark caption="Статус">
          <Typography
            ref={statusTextRef}
            variant={isWarning ? 'Body2' : 'Body1'}
            fontColor={isWarning ? 'uiRed' : undefined}
          >
            {getStatusText(value)}
          </Typography>
        </Mark>
      </StatusItem>
    </Wrapper>
  )
}

export default React.memo<Props>(Status)
