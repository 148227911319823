import React from 'react'
import { ListLead } from 'ui-kit/components'
import { TransactionParticipant } from 'common/types/api/models/payment'
import { renderParticipant } from 'common/utils/helpers'
import { PaymentReason, paymentReasonToString } from 'common/enums/payments'

type Props = {
  from: TransactionParticipant
  to: TransactionParticipant
  reason: PaymentReason
}
export const TransactionReason = React.memo<Props>(({ from, to, reason }) => {
  const subtitle = React.useMemo(() => {
    return `${renderParticipant(from)} ${renderParticipant(to)}`
  }, [from, to])

  const reasonText = React.useMemo(() => {
    return paymentReasonToString(reason)
  }, [reason])

  return (
    <ListLead table reverse subtitle={subtitle}>
      {reasonText}
    </ListLead>
  )
})

TransactionReason.displayName = 'TransactionReason'
