import React from 'react'

import { Icon, SubBodyText } from 'ui-kit/components'
import { PaymentSettingsListHeader } from 'settings/features/paymentSettingsTab/atoms'

type Props = {
  onCreate: () => void
}

export const PaymentSettingsSelectionHeader = React.memo<Props>(
  ({ onCreate }) => {
    return (
      <PaymentSettingsListHeader onClick={onCreate}>
        <SubBodyText color="textSecondary" fontWeight="bold">
          Групповые настройки
        </SubBodyText>
        <Icon name="add" />
      </PaymentSettingsListHeader>
    )
  }
)

PaymentSettingsSelectionHeader.displayName = 'PaymentSettingsSelectionHeader'
