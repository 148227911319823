import React from 'react'
import { connect } from 'react-redux'

import { State } from 'reducers'
import { MyThunkDispatch } from 'api/reducer'
import { PaymentSettings } from 'api/types'

import { apiActions, apiSelectors } from 'api'

type OwnProps = {
  settingsId: string
  children(
    data: PaymentSettings | null | undefined,
    editSettings: (updates: Partial<PaymentSettings>) => Promise<void>,
    allowNonResident?: boolean | null,
    canChangePaymentsConditions?: boolean,
    isBankTypeManual?: boolean,
    isFuelEnabled?: boolean
  ): React.ReactElement
}

const mapStateToProps = (state: State, ownProps: OwnProps) => ({
  data: apiSelectors.park.paymentSettingsAll.selectSettingsById(
    state,
    ownProps.settingsId
  ),
  allowNonResident: apiSelectors.park.details.getAllowNonResident(state),
  canChangePaymentsConditions: apiSelectors.employee.profile.getCanChangePaymentsConditions(
    state
  ),
  isFuelEnabled: apiSelectors.park.details.selectIsFuelEnabled(state),
  isBankTypeManual: apiSelectors.park.details.getIsBankTypeManual(state),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  fetchEditSettings: (updates: Partial<PaymentSettings>) =>
    dispatch(apiActions.park.paymentSettingsPatch.handleRequest(updates)),
  fetchAllSettings: () =>
    dispatch(apiActions.park.paymentSettingsAll.handleRequest()),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps

export const PurePaymentSettingsEditionConnector: React.FC<ContainerProps> = ({
  children,
  data,
  allowNonResident,
  isBankTypeManual,
  canChangePaymentsConditions,
  fetchAllSettings,
  isFuelEnabled,
  fetchEditSettings,
}) => {
  const editSettings = async (updates: Partial<PaymentSettings>) => {
    await fetchEditSettings(updates)
    await fetchAllSettings()
  }

  return children(
    data,
    editSettings,
    allowNonResident,
    canChangePaymentsConditions,
    isBankTypeManual,
    isFuelEnabled
  )
}

export const PaymentSettingsEditionConnector = connect(
  mapStateToProps,
  mapDispatchToProps
)(PurePaymentSettingsEditionConnector)
