import { authActions, authSelectors } from 'api/auth'
import { driversActions, driversSelectors } from 'api/drivers'
import { driversByIdActions, driversByIdSelectors } from 'api/driversById'
import { employeeActions, employeeSelectors } from 'api/employee'
import { employeesActions, employeesSelectors } from 'api/employees'
import { parkActions, parkSelectors } from 'api/park'
import { transactionsActions, transactionsSelectors } from 'api/transactions'
import { reportActions, reportSelectors } from 'api/report'
import { fuelActions, fuelSelectors } from 'api/fuel'
import { receiptActions, receiptSelectors } from 'api/receipt'
import { referralActions, referralSelectors } from './referral'
import { promoActions, promoSelectors } from './promo'

export const apiActions = {
  auth: authActions,
  drivers: driversActions,
  driversById: driversByIdActions,
  employee: employeeActions,
  employees: employeesActions,
  park: parkActions,
  referral: referralActions,
  transactions: transactionsActions,
  report: reportActions,
  fuel: fuelActions,
  receipt: receiptActions,
  promo: promoActions,
}

export const apiSelectors = {
  auth: authSelectors,
  drivers: driversSelectors,
  driversById: driversByIdSelectors,
  employee: employeeSelectors,
  employees: employeesSelectors,
  park: parkSelectors,
  referral: referralSelectors,
  transactions: transactionsSelectors,
  report: reportSelectors,
  fuel: fuelSelectors,
  receipt: receiptSelectors,
  promo: promoSelectors,
}
