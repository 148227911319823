import React from 'react'
import { change, untouch, WrappedFieldProps } from 'redux-form'

import { Icon, ListInput } from 'ui-kit/components'
import { IconNames } from 'ui-kit'

import { store } from 'store'

interface Props {
  iconName?: IconNames
  label?: string
  value?: string
  type?: string
  required?: boolean
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  clearOnFocus?: boolean
  readonly?: boolean
}

const ListInputComponent: React.FC<Props & WrappedFieldProps> = ({
  input,
  iconName,
  onClick,
  meta,
  label,
  value,
  required,
  type,
  children,
  disabled,
  clearOnFocus,
  readonly,
}) => {
  const [isVisible, setIsVisible] = React.useState(false)
  const { onBlur, onFocus, ...inputRest } = input

  const showError = !!(meta.touched && meta.error)
  const inputValue = value !== undefined ? value : input.value

  const onIconClick = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault()

    if (type === 'password') {
      setIsVisible((prevValue) => !prevValue)
    } else if (iconName === 'close') {
      store.dispatch(change(meta.form, input.name, null))
      store.dispatch(untouch(meta.form, input.name))
    }

    const item: HTMLElement = document.getElementsByName(input.name).item(0)

    if (item) {
      item.focus()
    }

    if (onClick) {
      onClick(e)
    }
  }

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onFocus) {
      onFocus(e)
    }
    if (clearOnFocus && meta.initial === e.target.value) {
      store.dispatch(change(meta.form, input.name, null))
      store.dispatch(untouch(meta.form, input.name))
    }
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(e)
    }
    if (!e.target.value.length && clearOnFocus) {
      store.dispatch(change(meta.form, input.name, meta.initial))
      store.dispatch(untouch(meta.form, input.name))
    }
  }

  return (
    <ListInput
      label={label}
      showError={showError}
      error={showError && input.value ? meta.error : undefined}
      onIconClick={onIconClick}
      {...inputRest}
      value={inputValue}
      required={required}
      disabled={disabled}
      iconName={iconName}
      type={isVisible ? 'text' : type}
      onFocus={handleFocus}
      onBlur={handleBlur}
      readonly={readonly}
    >
      {type === 'password' && inputValue ? (
        <Icon
          name={isVisible ? 'visibilityOffRounded' : 'visibilityRounded'}
          onClick={onIconClick}
        />
      ) : (
        children
      )}
    </ListInput>
  )
}

export default ListInputComponent
