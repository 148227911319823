import { ThunkAction } from 'redux-thunk'
import { State } from 'reducers'
import axios, { AxiosResponse } from 'axios'
import { FailureError } from 'common/utils/reduxApiFactory'
import { Aggregator, TApiError } from 'api/types'
import { postReportActions } from 'api/report/post/actions'
import { PostReportActions, PostReport } from 'api/report/post/types'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  PostReportActions
>

export const postReportRequest = (
  dateStart: string,
  dateEnd: string,
  type: string,
  aggregators?: Omit<Aggregator, 'account'>[]
): THandleRequest => async (dispatch) => {
  const { start, update, success, failure } = postReportActions
  dispatch(start())
  try {
    const response: AxiosResponse<PostReport> = await axios.post(
      `erp/1/report`,
      {
        date_start: dateStart,
        date_end: dateEnd,
        report_type: type,
        aggregators,
      }
    )
    dispatch(update(response.data))
    dispatch(success())
  } catch (e) {
    if (e.response) {
      const data = e.response.data as TApiError
      const error: FailureError = new Error(data.message)
      error.data = data
      dispatch(failure(error))
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw data
    }
  }
}
