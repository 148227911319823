import React, { useState } from 'react'
import { CaptionText, ColorButton, SubBodyText } from 'ui-kit/components'
import ListInput from 'common/components/formItems/ListInput'
import {
  Field,
  FormSubmitHandler,
  InjectedFormProps,
  reduxForm,
  SubmissionError,
} from 'redux-form'
import { FormNames } from 'common/enums'
import { required } from 'common/utils/fieldValidation'
import { HBox } from 'shared/ui/spacers'
import styled from 'styled-components'
import UploadOfXml, {
  OfdXmlData,
} from 'ui-kit/organisms/UploadOfdXml/UploadOfXml'
import { RadioGroup } from 'common/components/formItems/RadioGroup'
import { CloudCashBox, IntegrationType } from 'api/receipt/cloud-cashbox/types'
import { Sno } from 'api/receipt/sno/types'
import { Link } from 'ui-kit/atoms/Link'

type OwnProps = {
  onClose: () => void
  taxOptions: Sno[]
  onSubmit: (values: CloudCashBox) => Promise<void>
}

const Wrapper = styled.div`
  padding: 8px 16px 16px;
  max-width: 540px;
`

type Props = InjectedFormProps<CloudCashBox, OwnProps> & OwnProps

export const OfdConnectForm = ({
  submitting,
  handleSubmit,
  initialize,
  onSubmit,
  onClose,
  taxOptions,
}: Props) => {
  const [isValidXml, setValidXml] = useState(false)
  const handleParse = (data: OfdXmlData) => {
    setValidXml(true)
    initialize({
      inn: data.inn,
      kpp: data.kpp,
      name: data.name,
      cb_integration: {
        login: data.login,
        password: data.password,
        group_code: data.groupCode,
        payment_address: data.hostname,
        integration_type: IntegrationType.ATOL,
      },
      sno_id: taxOptions[0].code,
    })
  }
  const handleDownload = () => {
    const submit: FormSubmitHandler<CloudCashBox, OwnProps> = async (
      values
    ) => {
      try {
        await onSubmit(values)
        onClose()
      } catch (e) {
        throw new SubmissionError(e)
      }
    }

    return handleSubmit(submit)
  }

  return (
    <Wrapper>
      <SubBodyText>
        Если вы подключаете существующую кассу, стоимость подписки увеличится на
        200 рублей в месяц к тарифу.
      </SubBodyText>
      <HBox x={2 / 3} />
      <SubBodyText>
        Чтобы пользоваться бесплатно,{' '}
        <Link
          href="https://online.atol.ru/lk/Account/Register?partnerUid=6898a80f-2415-438e-a4cd-a7ba3e9f1b32"
          target="_blank"
        >
          зарегистрируйте новую кассу через Mozen
        </Link>
        .
      </SubBodyText>
      <HBox x={2 / 3} />
      <SubBodyText>
        <strong>Шаг 1.</strong> Скачайте файл с настройками интегратора в личном
        кабинете АТОЛ и загрузите.
      </SubBodyText>
      <HBox x={2 / 3} />
      <UploadOfXml onParse={handleParse} />

      {isValidXml && (
        <>
          <HBox x={2 / 3} />
          <SubBodyText>
            <strong>Шаг 2.</strong> Проверьте данные организации. Убедитесь, что
            они совпадают с данными, которые указаны в настройках кассы.
          </SubBodyText>
          <HBox x={2 / 3} />

          <Field
            name="name"
            label="Название юридического лица"
            iconName="close"
            validate={[required]}
            component={ListInput}
          />

          <HBox x={2 / 3} />

          <Field
            name="inn"
            label="ИНН"
            iconName="close"
            validate={[required]}
            component={ListInput}
          />

          <HBox x={2 / 3} />

          <Field
            name="kpp"
            label="КПП"
            iconName="close"
            validate={[required]}
            component={ListInput}
          />

          <HBox x={2 / 3} />

          <CaptionText color="gray7">Налогообложение</CaptionText>
          <HBox x={0.5} />
          <Field
            name="sno_id"
            component={RadioGroup}
            options={taxOptions.map((option) => ({
              label: option.name,
              value: option.code,
            }))}
          />
        </>
      )}
      <HBox x={2 / 3} />

      <ColorButton
        disabled={!isValidXml}
        isLoading={submitting}
        onClick={handleDownload()}
      >
        Сохранить
      </ColorButton>
    </Wrapper>
  )
}

export default reduxForm<CloudCashBox, OwnProps>({
  form: FormNames.OfdConnect,
})(OfdConnectForm)
