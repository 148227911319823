import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import axios, { AxiosResponse } from 'axios'

import { DriverTransaction } from 'common/types/api/models/payment'
import { toISOFormat } from 'common/utils/dates'
import { separateFilter } from 'common/utils/helpers'

import {
  setPayments as setPaymentsAction,
  setPaymentsDateFrom as setPaymentsDateFromAction,
  setPaymentsDateTo as setPaymentsDateToAction,
  setPaymentsFilter as setPaymentsFilterAction,
  setPaymentsPage as setPaymentsPageAction,
  setPaymentsSearch as setPaymentsSearchAction,
  SetPaymentsPayload,
} from 'drivers/features/driverPayments/actions'

import { DriverPayments } from 'drivers/features/driverPayments/organisms'
import { filtersData } from 'drivers/features/driverPayments/data'
import { MyThunkDispatch } from 'api/reducer'
import { State } from 'reducers'

interface LocalState {
  isFetching: boolean
  searchLoading: boolean
}

type PropsGroup = ContainerProps & RouteComponentProps<{ driver_id: string }>

class Container extends React.Component<PropsGroup, LocalState> {
  constructor(props: PropsGroup) {
    super(props)
    this.state = {
      isFetching: false,
      searchLoading: false,
    }
  }

  componentDidMount() {
    this.handleRequestPayments()
  }

  componentWillUnmount() {
    const { setPayments } = this.props
    setPayments({ total: 0, items: [] })
  }

  private handleRequestPayments = async (): Promise<void> => {
    const {
      page,
      searchValue,
      setPayments,
      filters,
      match,
      dateFrom,
      dateTo,
    } = this.props
    const limit = 6

    const data = {
      limit,
      offset: limit * (page - 1),
      date_from: dateFrom ? toISOFormat(dateFrom) : null,
      date_to: dateTo ? toISOFormat(dateTo) : null,
      tx_types: separateFilter(filters, filtersData, 'Тип транзакций'),
      tx_reasons: separateFilter(filters, filtersData, 'Основание'),
      tx_statuses: separateFilter(filters, filtersData, 'Статус платежа'),
      search: searchValue || null,
    }

    this.setState({ isFetching: true })

    try {
      const response: AxiosResponse<{
        transactions: DriverTransaction[]
        total_amount: number
      }> = await axios.get(
        `erp/1/drivers/${match.params.driver_id}/transactions`,
        { params: data }
      )

      setPayments({
        total: Math.ceil(response.data.total_amount / limit),
        items: response.data.transactions,
      })
    } catch (error) {
      console.error(error)
    } finally {
      this.setState({ isFetching: false, searchLoading: false })
    }
  }

  private handleRefreshPaymentsData = (): void => {
    const { setPaymentsPage } = this.props
    setPaymentsPage(1)
    setTimeout(() => this.handleRequestPayments(), 0)
  }

  private handleApplyFilter = (filters: string[]): void => {
    const { setPaymentsFilter } = this.props
    setPaymentsFilter(filters)

    this.handleRefreshPaymentsData()
  }

  private handleClearFilter = (): void => {
    const { setPaymentsFilter } = this.props
    setPaymentsFilter([])

    this.handleRefreshPaymentsData()
  }

  private handleSearch = (value: string): void => {
    const { setPaymentsSearch } = this.props
    setPaymentsSearch(value)

    this.setState({ searchLoading: true })

    this.handleRefreshPaymentsData()
  }

  private handleClearDates = (): void => {
    const {
      dateFrom,
      dateTo,
      setPaymentsDateTo,
      setPaymentsDateFrom,
    } = this.props
    setPaymentsDateTo(null)
    setPaymentsDateFrom(null)

    if (dateFrom || dateTo) {
      this.handleRefreshPaymentsData()
    }
  }

  private handleChangeDate = (from: Date, to: Date): void => {
    const { setPaymentsDateTo, setPaymentsDateFrom } = this.props
    setPaymentsDateFrom(from)
    setPaymentsDateTo(to)

    if (from && to) {
      this.handleRefreshPaymentsData()
    }
  }

  private handleChangePage = (direction: 'prev' | 'next'): void => {
    const { page, setPaymentsPage } = this.props
    const { isFetching } = this.state

    if (!isFetching) {
      if (direction === 'prev') {
        setPaymentsPage(page - 1)

        setTimeout(() => this.handleRequestPayments(), 0)
      } else if (direction === 'next') {
        setPaymentsPage(page + 1)

        setTimeout(() => this.handleRequestPayments(), 0)
      }
    }
  }

  render() {
    const {
      page,
      payments,
      total,
      filters,
      dateTo,
      dateFrom,
      searchValue,
    } = this.props
    const { searchLoading, isFetching } = this.state

    return (
      <DriverPayments
        payments={payments}
        page={page}
        search={searchValue}
        total={total}
        isFetching={isFetching}
        filterList={filters}
        dateFrom={dateFrom}
        dateTo={dateTo}
        searchLoading={searchLoading}
        onChangePage={this.handleChangePage}
        onSearch={this.handleSearch}
        onApplyFilter={this.handleApplyFilter}
        onClearFilter={this.handleClearFilter}
        onClearDates={this.handleClearDates}
        onChangeDate={this.handleChangeDate}
      />
    )
  }
}

const mapStateToProps = (state: State) => ({
  payments: state.driverPaymentsState.payments,
  searchValue: state.driverPaymentsState.searchValue,
  page: state.driverPaymentsState.page,
  filters: state.driverPaymentsState.filters,
  dateFrom: state.driverPaymentsState.dateFrom,
  dateTo: state.driverPaymentsState.dateTo,
  total: state.driverPaymentsState.total,
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  setPayments: (data: SetPaymentsPayload) => dispatch(setPaymentsAction(data)),
  setPaymentsSearch: (data: string) => dispatch(setPaymentsSearchAction(data)),
  setPaymentsPage: (data: number) => dispatch(setPaymentsPageAction(data)),
  setPaymentsFilter: (data: string[]) =>
    dispatch(setPaymentsFilterAction(data)),
  setPaymentsDateFrom: (data: Date | null) =>
    dispatch(setPaymentsDateFromAction(data)),
  setPaymentsDateTo: (data: Date | null) =>
    dispatch(setPaymentsDateToAction(data)),
})

type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const DriverPaymentsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container)
