import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { State, TRootAction } from 'reducers'
import { apiActions, apiSelectors } from 'api'
import { DepositTransactions } from 'settings/features/depositTransactions/organisms'

const mapStateToProps = (state: State) => ({
  isFetching: apiSelectors.park.balanceLog.getIsFetching(state),
  transactions: apiSelectors.park.balanceLog.getTransactions(state),
  totalAmount: apiSelectors.park.balanceLog.getTotalAmount(state),
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<State, undefined, TRootAction>
) => ({
  fetch: (limit: number, page: number) =>
    dispatch(apiActions.park.balanceLog.handleRequest(limit, page)),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const DepositTransactionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DepositTransactions)
