import React from 'react'
import Alert from 'react-s-alert'
import { Field, InjectedFormProps, SubmissionError } from 'redux-form'

import { BodyText, FloatButton, TitleText } from 'ui-kit/components'

import { history } from 'store'
import { BankType, RoutesPath } from 'common/enums'
import { required } from 'common/utils/fieldValidation'
import InputMask, {
  maskNumberWithSuffix,
} from 'common/components/formItems/InputMask'
import ListInput from 'common/components/formItems/ListInput'
import Checkbox from 'common/components/formItems/Checkbox'
import { HBox, Tooltip } from 'shared/ui'

import { SkipButton } from 'steps/features/content'

import {
  ContainerProps,
  FormProps,
} from 'steps/features/stepPayments/containers/PaymentsAlfaE2CContainer'
import styled from 'styled-components'
import { AlfaE2CFields } from 'settings/features/bankIntegrationModal/components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 563px;
`

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`
const ButtonWidth = styled.div`
  width: 320px;
`

const contentContainerComponent = styled.div`
  top: calc(100% + 8px) !important;
  bottom: initial !important;
  right: initial !important;
  left: calc(100% - 296px) !important;
  width: 296px !important;
  box-shadow: 0 3px 12px rgba(5, 16, 34, 0.06) !important;
`

export const PaymentsAlfaE2C: React.FC<
  InjectedFormProps<FormProps, ContainerProps> & ContainerProps
> = ({ valid, submitting, formValues, handleSubmit, onSave }) => {
  const handleSave = async (values: FormProps) => {
    try {
      await onSave(values)
    } catch (e) {
      Alert.error('Ошибка', {
        customFields: {
          text: e?.[BankType.AlfaE2C] || 'что-то пошло не так',
        },
      })
      throw new SubmissionError(e)
    }
  }
  const handleSkip = (): void => {
    history.replace(RoutesPath.StepsDrivers)
  }

  return (
    <Wrapper>
      <TitleText>Счёт в Альфа-Банке</TitleText>
      <AlfaE2CFields />

      <HBox x={2} />
      <Actions>
        <ButtonWidth>
          <FloatButton
            type="submit"
            disabled={!valid}
            isLoading={submitting}
            fullWidth
            onClick={handleSubmit(handleSave)}
          >
            Подключить
          </FloatButton>
        </ButtonWidth>
        <SkipButton onClick={handleSkip}>Пропустить</SkipButton>
      </Actions>
    </Wrapper>
  )
}
