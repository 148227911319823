import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiSpacer,
  EuiButton,
  EuiLoadingContent,
  EuiHideFor,
  EuiShowFor,
} from '@elastic/eui'
import Column from 'elastic-ui/referral-program/atoms/Column'
import {
  ProgramSettings,
  ProgramTerms,
} from 'elastic-ui/referral-program/types'
import { LimitContainer } from 'elastic-ui/shared/atoms/LimitContainer'
import React from 'react'
import TelegramNotifications from '../TelegramNotifications/TelegramNotifications'
import TermsPreviewForDriver from '../TermsPreviewForDriver/TermsPreviewForDriver'
import LimitedFlexItem from './atoms/LimitedFlexItem'

interface Props {
  onEditTermsClick: () => void
  onShowTermsToDriversClick: () => void
  onSubscribeToTelegramNotificationsClick: (key: string) => void
  onCancelTelegramSubscription: () => void
  isTelegramNotificationsConnected: boolean
  isTelegramNotificationsLoading: boolean
  company: string
  isLoading: boolean
  isProgramHidden: boolean
  title: string
  shortDescription: string
  fullDescription: string
}

const TermsPreview: React.FC<Props> = ({
  onEditTermsClick,
  onShowTermsToDriversClick,
  onSubscribeToTelegramNotificationsClick,
  onCancelTelegramSubscription,
  isTelegramNotificationsConnected,
  isTelegramNotificationsLoading,
  isLoading,
  isProgramHidden,
  company,
  title,
  shortDescription,
  fullDescription,
}) => {
  const terms = (
    <LimitedFlexItem>
      <Column>
        {isLoading ? (
          <EuiLoadingContent lines={7} />
        ) : (
          <EuiText>{fullDescription}</EuiText>
        )}
        <EuiSpacer size="m" />
        <EuiButton onClick={onEditTermsClick} isDisabled={isLoading}>
          Редактировать
        </EuiButton>
        <EuiSpacer size="l" />

        <LimitContainer maxWidth={318}>
          <TelegramNotifications
            telegramChatLink="https://t.me/MozenParksBot/"
            isConnected={isTelegramNotificationsConnected}
            isLoading={isTelegramNotificationsLoading}
            onSubscribeClick={onSubscribeToTelegramNotificationsClick}
            onCancelSubscriptionClick={onCancelTelegramSubscription}
            isDisabled={isLoading}
          />
        </LimitContainer>
      </Column>
    </LimitedFlexItem>
  )

  const card = (
    <EuiFlexItem>
      <TermsPreviewForDriver
        previewTitle={title}
        previewDescription={shortDescription}
        company={company}
        isLoading={isLoading}
        isProgramHidden={isProgramHidden}
        onShowTermsToDriversClick={onShowTermsToDriversClick}
      />
    </EuiFlexItem>
  )

  return (
    <EuiFlexGroup gutterSize="l">
      <EuiShowFor sizes={['xs', 's']}>
        {card}
        {terms}
      </EuiShowFor>
      <EuiHideFor sizes={['xs', 's']}>
        {terms}
        {card}
      </EuiHideFor>
    </EuiFlexGroup>
  )
}

export default TermsPreview
