import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled, { css, useTheme } from 'styled-components'
import { CaptionText, SubBodyText } from 'ui-kit/components/Typography'
import DropZone from 'react-dropzone'
import gsap from 'gsap'
import { Icon } from 'ui-kit/components'
import { VBox } from 'ui-kit/atoms/spacers'

type ChildProps = {
  isDragActive: boolean
  isDragAccept: boolean
  isDragReject: boolean
  isHover: boolean
  isFocused: boolean
}

type Props = {
  children: (props: ChildProps) => React.ReactNode
  acceptOnly?: string[]
  errorMessage?: string
  onChange?: (file: string) => void
  readAsArrayBuffer?: boolean
}

const Placeholder = styled.div`
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;

  background-color: ${({ theme }) => theme.pallete.surfaceLightPrimary};
`

const DropZoneInput = styled.div<{
  isDrag?: boolean
  isDragAccept?: boolean
  isDragReject?: boolean
  isDisable?: boolean
}>`
  z-index: 0;
  display: flex;
  position: relative;
  border: 1px dashed
    ${({ theme, isDrag }) =>
      isDrag ? theme.pallete.uiGreen : theme.pallete.uiSecondary};
  box-sizing: border-box;
  border-radius: 8px;
  min-height: 70px;
  transition: border-color 0.5s;

  user-select: ${({ isDisable }) => !isDisable && 'none'};
  cursor: ${({ isDisable }) => !isDisable && 'pointer'};

  &:focus {
    outline: none;
    border-color: ${({ theme, isDrag }) =>
      isDrag ? theme.pallete.uiGreen : theme.pallete.accentPrimary};
  }

  ${Placeholder} {
    background-color: ${({ theme, isDragReject, isDragAccept }) =>
      (isDragReject || isDragReject) && theme.pallete.secondaryTableButton};
  }

  &:hover ${Placeholder} {
    background-color: ${({ theme }) => theme.pallete.secondaryTableButton};
  }

  ${({ isDragAccept, isDragReject, theme }) =>
    css`
      border-style: ${isDragAccept && 'solid'};
      border-width: ${isDragAccept && '2px'};
      border-color: ${isDragAccept && theme.pallete.accentPrimary};
      border-color: ${isDragReject && theme.pallete.uiRed};
    `};
`

const FileList = styled.div`
  border-radius: 8px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 200, 83, 0.2);
`
const File = styled.div`
  display: flex;
  align-items: center;
`
const FileCloseButton = styled(Icon)`
  width: 16px;
  height: 16px;
  svg {
    width: 16px;
    height: 16px;
  }
`

const Wrapper = styled.div``

const ErrorList = styled.div`
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 4px;
`

type DragAndDropError = {
  key: number
  message: string
}

const DragAndDropInput = ({
  acceptOnly,
  children,
  onChange,
  errorMessage = 'Файл не читается.',
  readAsArrayBuffer = false,
}: Props) => {
  const theme = useTheme()
  const [errors, setErrors] = useState<DragAndDropError[]>([])
  const [isHover, setHover] = useState(false)
  const [dropZoneState, updateDropZoneState] = useState({
    key: Date.now(),
    disabled: false,
  })
  const dropZoneInput = useRef<HTMLDivElement>(null)
  const errorTimeLine = useRef(gsap.timeline({ paused: true }))
  useEffect(() => {
    const currentErrorTimeLine = errorTimeLine.current
    currentErrorTimeLine.from(dropZoneInput.current, {
      immediateRender: false,
      duration: 2,
      borderColor: theme.pallete.uiRed,
      ease: 'expo.in',
      clearProps: 'borderColor',
    })
  }, [theme.pallete.uiRed, theme.pallete.uiSecondary])

  const handleMouseOver = useCallback(() => {
    setHover(true)
  }, [])
  const handleMouseOut = useCallback(() => {
    setHover(false)
  }, [])
  const handleDropAccepted = useCallback(
    async (files: Blob[]) => {
      setErrors([])
      updateDropZoneState((prevState) => ({ ...prevState, disabled: true }))

      const file = files[0]
      const fileType = file.type
      if (acceptOnly && !acceptOnly?.includes(fileType)) {
        setErrors([{ key: Date.now(), message: errorMessage }])
        updateDropZoneState({
          key: Date.now(),
          disabled: false,
        })
        dropZoneInput.current?.blur()
        errorTimeLine.current?.restart()
        return
      }

      const fr = new FileReader()
      fr.addEventListener('load', () => {
        if (onChange) {
          onChange(fr.result as string)
        }
      })

      if (readAsArrayBuffer) {
        fr.readAsArrayBuffer(file)
      } else {
        fr.readAsText(file)
      }
    },
    [acceptOnly, errorMessage, onChange, readAsArrayBuffer]
  )
  const handleDropRejected = useCallback(() => {
    setErrors([{ key: Date.now(), message: errorMessage }])
    dropZoneInput.current?.blur()
    errorTimeLine.current?.restart()
  }, [errorMessage])
  const handleRemoveFileClick = useCallback(() => {
    updateDropZoneState({
      key: Date.now(),
      disabled: false,
    })
  }, [])
  const handleDragEnter = useCallback(() => {
    setErrors([])
  }, [])
  const handleFileDialogCancel = useCallback(() => {
    dropZoneInput.current?.blur()
  }, [])

  return (
    <DropZone
      key={dropZoneState.key}
      disabled={dropZoneState.disabled}
      multiple={false}
      accept={acceptOnly}
      onDropAccepted={handleDropAccepted}
      onDropRejected={handleDropRejected}
      onDragEnter={handleDragEnter}
      onFileDialogCancel={handleFileDialogCancel}
    >
      {({
        acceptedFiles,
        isDragActive,
        isDragAccept,
        isDragReject,
        isFocused,
        getRootProps,
        getInputProps,
      }) => {
        return (
          <Wrapper>
            <DropZoneInput
              {...getRootProps({
                isDisable: dropZoneState.disabled,
                isDragAccept,
                isDragReject,
              })}
              ref={dropZoneInput}
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
            >
              <input {...getInputProps()} />
              {!acceptedFiles.length && (
                <Placeholder>
                  {children({
                    isDragActive,
                    isDragAccept,
                    isDragReject,
                    isHover,
                    isFocused,
                  })}
                </Placeholder>
              )}
              {Boolean(acceptedFiles.length) && (
                <FileList>
                  {acceptedFiles.map((file) => (
                    <File key={file.name}>
                      <SubBodyText>{file.name}</SubBodyText>
                      <VBox x={1 / 6} />
                      <FileCloseButton
                        name="close"
                        onClick={handleRemoveFileClick}
                        pointer
                      />
                    </File>
                  ))}
                </FileList>
              )}
            </DropZoneInput>
            {Boolean(errors.length) && (
              <ErrorList>
                {errors.map((error) => (
                  <CaptionText key={error.key} color="accentAttention">
                    {error.message}
                  </CaptionText>
                ))}
              </ErrorList>
            )}
          </Wrapper>
        )
      }}
    </DropZone>
  )
}
export default React.memo<Props>(DragAndDropInput)
