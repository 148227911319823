import { createApiReducer } from 'common/utils/reduxApiFactory'
import { parkPaymentSettingsAssignActions } from 'api/park/payment-settings/assign/actions'
import { ParkPaymentSettingsAssignState } from 'api/park/payment-settings/assign/types'

export const initialState: ParkPaymentSettingsAssignState = {
  status: null,
  data: null,
}

export const parkPaymentSettingsAssignReducer = createApiReducer(
  parkPaymentSettingsAssignActions,
  initialState
)
