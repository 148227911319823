import { ReferralOfferResponse } from 'api/referral/offer/types'
import { InviterBonusFrequencies } from 'elastic-ui/referral-program/organisms/TermsEdit/types'

type RewardPeriodType = ReferralOfferResponse['inviter_reward_period_type']

const map: Record<RewardPeriodType, InviterBonusFrequencies> = {
  daily: InviterBonusFrequencies.Daily,
  weekly: InviterBonusFrequencies.Weekly,
  monthly: InviterBonusFrequencies.Monthly,
  once: InviterBonusFrequencies.OneTime,
}

export default (rewardPeriodType: RewardPeriodType): InviterBonusFrequencies =>
  map[rewardPeriodType]
