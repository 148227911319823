export enum TripStatus {
  Success = 'success',
  Canceled = 'canceled',
}

export const tripStatusToString = (value: TripStatus): string => {
  switch (value) {
    case TripStatus.Success:
      return 'успешно'
    case TripStatus.Canceled:
      return 'отменено'
    default:
      return 'Unknown'
  }
}
