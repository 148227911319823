import React from 'react'

import {
  ContentCard,
  ContentCardBody,
  ContentCardHeader,
} from 'shared/ui/contentCard'
import { SubBodyText } from 'ui-kit/components'
import { PaymentSettingsDefaultNames } from 'common/enums/payments'

export const PaymentSettingsIndividualCard = React.memo(() => {
  return (
    <ContentCard>
      <ContentCardHeader title={PaymentSettingsDefaultNames.INDIVIDUAL} />
      <ContentCardBody>
        <SubBodyText>
          Водителям назначены уникальные настройки выплат.
          <br />
          Перейдите в карточку водителя, чтобы отредактировать их.
        </SubBodyText>
      </ContentCardBody>
    </ContentCard>
  )
})

PaymentSettingsIndividualCard.displayName = 'PaymentSettingsIndividualCard'
