import React from 'react'

import {
  CaptionText as DefaultCaptionText,
  TransparentButton,
} from 'ui-kit/components'
import { HBox } from 'shared/ui/spacers'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 214px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const CaptionText = styled(DefaultCaptionText)`
  text-align: center;
`

type Props = {
  isLoading: boolean
  onClick: () => void
}

export const PaymentSettingsDeleteButton = React.memo<Props>(
  ({ onClick, isLoading }) => {
    return (
      <Wrapper>
        <TransparentButton
          isLoading={isLoading}
          iconName="deletion"
          iconColor="red"
          textColor="red"
          fontWeight="bold"
          onClick={onClick}
        >
          Удалить группу
        </TransparentButton>
        <HBox />
        <CaptionText color="textSecondary">
          Всем водителям в группе будут установлены стандартные условия выплат.
        </CaptionText>
      </Wrapper>
    )
  }
)

PaymentSettingsDeleteButton.displayName = 'PaymentSettingsDeleteButton'
