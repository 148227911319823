import { handleActions } from 'redux-actions'

import { QiwiDetailsResponse, TApiStatus } from 'api/types'

import {
  FAILURE,
  RESET,
  RESET_STATUS,
  START,
  SUCCESS,
  TFailure,
  TPayloads,
  TUpdate,
  UPDATE,
} from 'api/park/qiwi/details/actions'

export interface QiwiDetailsState {
  status: TApiStatus
  data: QiwiDetailsResponse | null
  error: any
}

const initialState: QiwiDetailsState = {
  status: null,
  data: null,
  error: null,
}

const handleUpdate = (
  state: QiwiDetailsState,
  action: TUpdate
): QiwiDetailsState => ({
  ...state,
  data: action.payload,
})
const handleStart = (state: QiwiDetailsState): QiwiDetailsState => ({
  ...state,
  status: 'loading',
})
const handleSuccess = (state: QiwiDetailsState): QiwiDetailsState => ({
  ...state,
  status: 'success',
})
const handleFailure = (
  state: QiwiDetailsState,
  action: TFailure
): QiwiDetailsState => ({
  ...state,
  status: 'failure',
  error: action.payload,
})
const handleReset = (): QiwiDetailsState => initialState
const handleResetStatus = (state: QiwiDetailsState): QiwiDetailsState => ({
  ...state,
  status: null,
})

export const reducer = handleActions<QiwiDetailsState, TPayloads>(
  {
    [UPDATE]: handleUpdate,
    [START]: handleStart,
    [SUCCESS]: handleSuccess,
    [FAILURE]: handleFailure,
    [RESET]: handleReset,
    [RESET_STATUS]: handleResetStatus,
  },
  initialState
)
