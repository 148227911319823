import React, { useEffect, useMemo } from 'react'
import { history } from 'store'

import { RoutesPath } from 'common/enums'
import { useDriverNotifications } from 'api/driverNotification/useDriverNotification'
import { DriverNotificationsPage } from 'ui-kit/pages'

import { useSelector, useDispatch } from 'react-redux'
import { apiActions, apiSelectors } from 'api'
import { Icon } from 'ui-kit/components'

export const DriversNotification: React.FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(apiActions.park.aggregators.handleRequest())
  }, [dispatch])

  const aggregatorsList = useSelector(
    apiSelectors.park.aggregators.getAggregators
  )
  const {
    driverNotifications,
    getNotification,
    createNotification,
  } = useDriverNotifications(aggregatorsList)

  const goBack = useMemo(() => {
    return (
      <Icon
        color="darkGray"
        name="chevronLeft"
        pointer
        onClick={() => history.push(RoutesPath.Drivers)}
      >
        Водители
      </Icon>
    )
  }, [])

  return (
    <DriverNotificationsPage
      navigationComponent={goBack}
      aggregatorsList={{
        all: 'Все',
        list: aggregatorsList.map((aggregator) => ({
          value: aggregator.account,
          label: aggregator.account,
        })),
      }}
      notificationsList={driverNotifications}
      createNotification={createNotification}
      getNotifications={getNotification}
    />
  )
}
