import {
  closeAllModals,
  closeModal,
  openModal,
  setData,
} from 'shared/modals/actions'
import { getIsModalOpened, getData } from 'shared/modals/selectors'

export const modalsActions = {
  setData,
  openModal,
  closeModal,
  closeAllModals,
}

export const modalsSelectors = {
  getData,
  getIsModalOpened,
}
