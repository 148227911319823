import axios, { AxiosResponse } from 'axios'
import { BaseAction } from 'redux-actions'
import { ThunkAction } from 'redux-thunk'

import { State } from 'reducers'
import {
  failure,
  start,
  success,
} from 'api/park/topup-deposit/payment-order/actions'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  BaseAction
>
export const HANDLE_REQUEST: BaseAction['type'] =
  'api/park/topup-deposit/payment-order->HANDLE_REQUEST'

// https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
export const handleRequest = (amount: number): THandleRequest => async (
  dispatch
) => {
  dispatch(start())
  try {
    const response: AxiosResponse<BlobPart> = await axios({
      url: 'erp/1/park/topup-deposit/payment-order',
      method: 'GET',
      responseType: 'blob',
      params: { amount },
    })
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: response.headers['content-type'] })
    )
    const link = document.createElement('a')
    link.href = url

    const contentDisposition = response.headers['content-disposition']
    let fileName = 'payment-order.txt'
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
      if (fileNameMatch.length === 2) {
        const newFileName = fileNameMatch[1]
        fileName = newFileName
      }
    }
    link.setAttribute('download', fileName)

    document.body.appendChild(link)
    link.click()
    link.remove()
    window.URL.revokeObjectURL(url)
    dispatch(success())
  } catch (e) {
    if (e.response) {
      dispatch(failure(e.response.data))
    }
  }
}
