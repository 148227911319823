import React, { useMemo } from 'react'

import { makeDefaultDateRange } from 'common/utils/helpers'
import { toISOFormat } from 'common/utils/dates'
import DateRangePicker from 'common/components/dateRangePicker/DateRangePicker'
import {
  Col,
  ContentCard,
  ContentCardBody,
  ContentCardHeader,
  Row,
  VBox,
} from 'shared/ui'
import {
  StatisticsFeesChart,
  StatisticsFeesSummary,
} from 'analytics/features/statisticsFees/molecules'
import { ContainerProps } from 'analytics/features/statisticsFees/container/StatisticsFeesContainer'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const now = new Date()
now.setDate(now.getDate() - 1)

const minDate = new Date(now)
minDate.setMonth(minDate.getMonth() - 12)

export const StatisticsFees: React.FC<ContainerProps> = ({
  statisticsFees,
  isStatisticsFeesFetching,
  fetchStatisticsFees,
  statisticsFeesSummary,
}) => {
  const { from, to, dateTo, dateFrom } = makeDefaultDateRange()
  const isChartVisible = statisticsFees.length > 0

  const datePicker = useMemo(() => {
    const handleClearDateRange = () => {
      fetchStatisticsFees(from, to)
    }

    const handleChangeDateRange = (newFrom: Date, newTo: Date) => {
      const isChangeCompleted = newFrom !== null && newTo !== null
      if (isChangeCompleted) {
        fetchStatisticsFees(toISOFormat(newFrom), toISOFormat(newTo))
      }
    }

    return (
      <DateRangePicker
        hasCloseButton={false}
        maxDate={now}
        minDate={minDate}
        dateFrom={dateFrom}
        dateTo={dateTo}
        onChangeDate={handleChangeDateRange}
        onClearDates={handleClearDateRange}
        disabled={!isChartVisible}
      />
    )
  }, [dateFrom, dateTo, fetchStatisticsFees, from, to, isChartVisible])

  return (
    <Wrapper>
      <ContentCard>
        <ContentCardHeader title="Доход автопарка с комиссий">
          {datePicker}
        </ContentCardHeader>
        <Row>
          <Col percentage={100}>
            <ContentCardBody
              isLoading={!isChartVisible || isStatisticsFeesFetching}
            >
              <StatisticsFeesChart data={statisticsFees} />
            </ContentCardBody>
          </Col>
          {statisticsFeesSummary && (
            <>
              <StatisticsFeesSummary
                feesTotal={statisticsFeesSummary.fees_total}
                mozenWithdrawFeesTotal={
                  statisticsFeesSummary.mozen_withdraw_fees_total
                }
                mozenWithdrawTotal={statisticsFeesSummary.mozen_withdraw_total}
                rideFeesTotal={statisticsFeesSummary.ride_fees_total}
              />
              <VBox />
            </>
          )}
        </Row>
      </ContentCard>
    </Wrapper>
  )
}
