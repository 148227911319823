import { connect } from 'react-redux'
import { apiActions, apiSelectors } from 'api'

import { State } from 'reducers'
import { MyThunkDispatch } from 'api/reducer'

import { StatisticsFees } from 'analytics/features/statisticsFees/organisms'

const mapStateToProps = (state: State) => ({
  isStatisticsFeesFetching: apiSelectors.park.statisticsFees.selectIsFetching(
    state
  ),
  statisticsFees: apiSelectors.park.statisticsFees.selectCharData(state),
  statisticsFeesSummary: apiSelectors.park.statisticsFees.selectCharSummary(
    state
  ),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  fetchStatisticsFees: (from: string | null, to: string | null) =>
    dispatch(
      apiActions.park.statisticsFees.handleRequest({
        date_start: from,
        date_end: to,
      })
    ),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const StatisticsFeesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StatisticsFees)
