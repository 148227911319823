import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'
import { ContainerProps } from 'payments/features/confirmationList/containers/ConfirmationListContainer'

import { ActionBottomPanel, ContentCard } from 'shared/ui'

import {
  ConfirmationHeader,
  ConfirmationPagination,
  ConfirmationTable,
} from 'payments/features/confirmationList/organisms/index'
import { Placeholder } from 'payments/features/confirmationList/atoms'
import { PaymentDetailsModalContainer } from 'payments/features/paymentDetailsModal'
import { PaginationLimit, PaymentTransaction } from 'api/types'
import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: ${({ theme }) => theme.paddings.main}px;
`

type StateType = {
  limit: PaginationLimit
  page: number
  search: string
}

export const initialState: StateType = {
  limit: 20,
  page: 1,
  search: '',
}

type ActionType = {
  type: 'changePage' | 'changeLimit' | 'changeSearch'
  pageOffset?: number
  limit?: PaginationLimit
  search?: string
}

function reducer(state: StateType, action: ActionType): StateType {
  switch (action.type) {
    case 'changePage':
      return {
        ...state,
        page: state.page + (action.pageOffset ? action.pageOffset : 0),
      }
    case 'changeLimit':
      return {
        ...state,
        limit: action.limit ? action.limit : state.limit,
        page: 1,
      }
    case 'changeSearch':
      return {
        ...state,
        search: action.search !== undefined ? action.search : state.search,
        page: 1,
      }
    default:
      return state
  }
}

export const ConfirmationList: React.FC<ContainerProps> = ({
  isTransactionFetching,
  isTransactionConfirmFetching,
  transactions,
  totalAmount,
  fetchTransactions,
  canViewTransactionDetails,
  canProcessTransactions,
  isPaymentDetailsModalOpened,
  openPaymentDetailsModal,
  fetchTransactionsConfirm,
  modalData,
}) => {
  const [records, setRecords] = useState<Set<string>>(new Set())
  const [state, dispatch] = useReducer(reducer, initialState)
  const [transaction, setTransaction] = useState<PaymentTransaction>()

  const totalPages = useMemo(
    () => totalAmount && Math.ceil(totalAmount / state.limit),
    [totalAmount, state.limit]
  )

  const isPlaceholderVisible = !isTransactionFetching && !totalPages
  const isPaginationVisible = totalPages > 0

  const handleFetch = useCallback(() => {
    fetchTransactions({
      params: {
        limit: state.limit,
        offset: state.limit * (state.page - 1),
        search: state.search,
      },
    })
    setRecords(new Set())
  }, [fetchTransactions, state.limit, state.page, state.search])

  useEffect(() => {
    const handleFetchWithModal = async () => {
      if (modalData) {
        setTransaction(modalData)
        openPaymentDetailsModal()
      } else {
        handleFetch()
      }
    }
    handleFetchWithModal()
  }, [handleFetch, openPaymentDetailsModal, modalData])

  const handleChangePage = useCallback(
    (pageOffset: number) => {
      dispatch({ type: 'changePage', pageOffset })
    },
    [dispatch]
  )

  const handleChangeLimit = useCallback(
    (limit: PaginationLimit) => {
      dispatch({ type: 'changeLimit', limit })
    },
    [dispatch]
  )

  const handleChangeSearch = useCallback(
    (search: string) => {
      dispatch({ type: 'changeSearch', search })
    },
    [dispatch]
  )

  const handleClearPayment = useCallback(() => {
    setRecords(new Set())
  }, [setRecords])

  const handleConfirmPayment = useCallback(
    async (confirmed: boolean, id?: string): Promise<void> => {
      const transactionIds = typeof id === 'string' ? [id] : Array.from(records)

      try {
        await fetchTransactionsConfirm({ confirmed, transactionIds })
        await handleFetch()
        handleClearPayment()
      } catch (e) {
        console.error(e)
      }
    },
    [fetchTransactionsConfirm, handleClearPayment, handleFetch, records]
  )

  const handleChangeCheckbox = useCallback(
    (newRecords: Set<string>) => {
      setRecords(newRecords)
    },
    [setRecords]
  )

  const handleRowClick = useCallback(
    (value: PaymentTransaction) => {
      setTransaction(value)
      openPaymentDetailsModal()
    },
    [openPaymentDetailsModal]
  )

  return (
    <>
      <Wrapper>
        <ContentCard>
          <ConfirmationHeader onChangeSearch={handleChangeSearch} />
          <ConfirmationTable
            data={transactions}
            records={records}
            isFetching={isTransactionFetching}
            canViewTransactionDetails={canViewTransactionDetails}
            canProcessTransactions={canProcessTransactions}
            onChangeCheckbox={handleChangeCheckbox}
            onConfirmPayment={handleConfirmPayment}
            onRowClick={handleRowClick}
          />
          {isPaginationVisible && (
            <ConfirmationPagination
              currentLimit={state.limit}
              currentPage={state.page}
              total={totalPages}
              onChangePage={handleChangePage}
              onChangeLimit={handleChangeLimit}
            />
          )}
          {isPlaceholderVisible && <Placeholder />}
        </ContentCard>
      </Wrapper>
      <ActionBottomPanel
        count={records.size}
        isLoading={isTransactionConfirmFetching}
        onConfirm={handleConfirmPayment}
        doTitle="Подтвердить платежи"
        dontTitle="Отклонить платежи"
        onCancel={handleClearPayment}
      />
      {isPaymentDetailsModalOpened && transaction ? (
        <PaymentDetailsModalContainer
          transaction={transaction}
          callbackFetch={handleFetch}
        />
      ) : null}
    </>
  )
}
