import { ThunkAction } from 'redux-thunk'
import { State } from 'reducers'
import axios, { AxiosResponse } from 'axios'
import { FailureError } from 'common/utils/reduxApiFactory'
import { TApiError } from 'api/types'
import { transactionStatusActions } from 'api/transactions/status-request/actions'
import {
  TransactionStatusActions,
  TransactionStatus,
} from 'api/transactions/status-request/types'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  TransactionStatusActions
>

export const postTransactionStatusRequest = (
  transactionId: string
): THandleRequest => async (dispatch) => {
  const { start, update, success, failure } = transactionStatusActions
  dispatch(start())
  try {
    const response: AxiosResponse<TransactionStatus> = await axios.post(
      `erp/1/transactions/${transactionId}/status-request`
    )
    dispatch(update(response.data))
    dispatch(success())
  } catch (e) {
    if (e.response) {
      const data = e.response.data as TApiError
      const error: FailureError = new Error(data.message)
      error.data = data
      dispatch(failure(error))
    }
  }
}
