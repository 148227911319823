import { handleActions } from 'redux-actions'

import { PaymentTransactionsResponse, TApiStatus } from 'api/types'

import {
  FAILURE,
  RESET,
  RESET_STATUS,
  START,
  SUCCESS,
  TFailure,
  TPayloads,
  TStart,
  TUpdate,
  UPDATE,
} from 'api/park/transactions/get/actions'
import { HANDLE_REQUEST } from 'api/park/transactions/get/thunks'

export interface PaymentTransactionsState {
  status: TApiStatus
  data: PaymentTransactionsResponse | null
  error: any
  timestamp: number
}

const initialState: PaymentTransactionsState = {
  status: null,
  data: null,
  error: null,
  timestamp: 0,
}

const handleUpdate = (
  state: PaymentTransactionsState,
  action: TUpdate
): PaymentTransactionsState => {
  if (state.timestamp <= action.payload.timestamp) {
    return {
      ...state,
      data: action.payload.data,
    }
  }
  return state
}

const handleStart = (
  state: PaymentTransactionsState,
  action: TStart
): PaymentTransactionsState => ({
  ...state,
  status: 'loading',
  timestamp: action.payload,
})

const handleSuccess = (
  state: PaymentTransactionsState
): PaymentTransactionsState => ({
  ...state,
  status: 'success',
})
const handleFailure = (
  state: PaymentTransactionsState,
  action: TFailure
): PaymentTransactionsState => ({
  ...state,
  status: 'failure',
  error: action.payload,
})
const handleReset = (): PaymentTransactionsState => initialState
const handleResetStatus = (
  state: PaymentTransactionsState
): PaymentTransactionsState => ({
  ...state,
  status: null,
})

const handleRequest = (
  state: PaymentTransactionsState
): PaymentTransactionsState => ({
  ...state,
})

export const reducer = handleActions<PaymentTransactionsState, TPayloads>(
  {
    [UPDATE]: handleUpdate,
    [START]: handleStart,
    [SUCCESS]: handleSuccess,
    [FAILURE]: handleFailure,
    [RESET]: handleReset,
    [RESET_STATUS]: handleResetStatus,
    [HANDLE_REQUEST]: handleRequest,
  },
  initialState
)
