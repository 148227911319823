import React from 'react'
import { Field } from 'redux-form'

import { CaptionText, SubBodyText } from 'ui-kit/components'
import Switch from 'common/components/formItems/Switch'

import { HBox } from 'shared/ui/index'
import styled from 'styled-components'

const Wrapper = styled.div<{ overlay?: boolean }>`
  position: relative;

  ${({ overlay }) =>
    overlay &&
    `
      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 100;
        background-color: rgba(255, 255, 255, .7);
      }
  `}
`

type Props = {
  isLocked?: boolean
  canChangePaymentsConditions?: boolean
  onSave: (newValue: boolean, prevValue: boolean, name: string) => Promise<void>
}

export const PaymentSettingsSwitchesFuelFields: React.FC<Props> = ({
  isLocked,
  canChangePaymentsConditions,
  onSave,
}) => {
  const handleChange = async (
    _: any,
    newValue: boolean,
    prevValue: boolean,
    name: string
  ) => {
    await onSave(newValue, prevValue, name)
  }

  return (
    <Wrapper overlay={isLocked}>
      <Field<any>
        name="fuel_card_topup_restricted"
        component={Switch}
        onChange={handleChange}
        disabled={!canChangePaymentsConditions}
      >
        <SubBodyText>Запретить</SubBodyText>
        <CaptionText color="gray6">
          Водитель не сможет пополнить топливную карту.
        </CaptionText>
      </Field>
      <HBox x={2 / 3} />
      <Field<any>
        name="fuel_card_auto_topup"
        component={Switch}
        onChange={handleChange}
        disabled={!canChangePaymentsConditions}
      >
        <SubBodyText>Автоподтверждение</SubBodyText>
        <CaptionText color="gray6">
          Выполнять большинство платежей без необходимости их подтверждать.
        </CaptionText>
      </Field>
    </Wrapper>
  )
}
