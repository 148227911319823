import React from 'react'
import { connect } from 'react-redux'
import axios, { AxiosResponse } from 'axios'

import { history } from 'store'
import { apiSelectors } from 'api'

import { Driver } from 'common/types/api/models/driver'
import { DriverListResponse } from 'common/types/api/responses/driver'
import { separateFilter } from 'common/utils/helpers'
import { getAggregators } from 'common/dataReceivers/aggregators'
import { Aggregator } from 'common/types/api/models/aggregator'
import { LargeFilter } from 'common/types/local/filter'
import { AggregatorType, Permission, SortDirection } from 'common/enums'

import {
  setDriversList as setDriversListAction,
  setDriversListFilter as setDriversListFilterAction,
  setDriversListPage as setDriversListPageAction,
  setDriversListSearch as setDriversListSearchAction,
  setDriversSort as setDriversSortAction,
  setInfo as setInfoAction,
  SortableColumns,
} from 'drivers/features/driversList/actions'
import { DriversList } from 'drivers/features/driversList/organisms'
import { filterData as initialFilterData } from 'drivers/features/driversList/data'
import { State } from 'reducers'

interface Props {
  permissions?: Permission[]
  drivers: Driver[]
  searchValue: string | null
  page: number
  totalPages: number
  filters: string[]
  sortColumn: SortableColumns
  sortDirection: SortDirection
}

interface DispatchProps {
  setInfo: typeof setInfoAction
  setDriversList: typeof setDriversListAction
  setDriversListFilter: typeof setDriversListFilterAction
  setDriversListPage: typeof setDriversListPageAction
  setDriversListSearch: typeof setDriversListSearchAction
  setDriversSort: typeof setDriversSortAction
}

interface LocalState {
  driversIsLoading: boolean
  searchLoading: boolean
  filterData: LargeFilter[]
}

interface DataDriversPayload {
  limit: number
  offset: number
  statuses: string[]
  work_statuses: string[]
  park_accounts: string[]
  search: string | null
  sort_direction: SortDirection
  sort_column: SortableColumns
}

function isSortColumn(value: string): value is SortableColumns {
  return value === 'name'
}

class Container extends React.Component<Props & DispatchProps, LocalState> {
  constructor(props: Props & DispatchProps) {
    super(props)

    this.state = {
      driversIsLoading: false,
      searchLoading: false,
      filterData: initialFilterData,
    }
  }

  componentDidMount() {
    const {
      permissions,
      setDriversListFilter,
      setDriversListSearch,
      setDriversListPage,
      setDriversSort,
    } = this.props

    const getDrivers = async () => {
      if (permissions?.includes(Permission.ChangeAggregators)) {
        await this.addAggregatorsInFilter()
      }

      if (history.action === 'PUSH' && (history.location.state as any)?.reset) {
        setDriversListFilter([])
        setDriversListSearch('')
        setDriversListPage(1)
        setDriversSort({
          column: 'name',
          direction: SortDirection.ASC,
        })

        setTimeout(() => {
          this.handleRequestDrivers()
        }, 0)
      } else {
        this.handleRequestDrivers()
      }
    }
    getDrivers()
  }

  componentWillUnmount() {
    const { setDriversList } = this.props
    setDriversList({ total_pages: 0, items: [] })
  }

  private addAggregatorsInFilter = async (): Promise<void> => {
    const aggregators: Aggregator[] = await getAggregators()

    const aggregatorsToFilter: LargeFilter[] = aggregators
      .filter(({ type }) => type !== AggregatorType.DiDi)
      .map(({ account }) => ({
        category: 'Агрегаторы',
        name: account,
        label: account,
      }))

    this.setState({
      filterData: [...initialFilterData, ...aggregatorsToFilter],
    })
  }

  private handleRequestDrivers = async (): Promise<void> => {
    const {
      page,
      filters,
      searchValue,
      setDriversList,
      sortDirection,
      sortColumn,
    } = this.props
    const { filterData } = this.state
    const limit = 12

    const data: DataDriversPayload = {
      limit,
      offset: limit * (page - 1),
      sort_direction: sortDirection,
      sort_column: sortColumn,
      statuses: separateFilter(filters, filterData, 'Водители'),
      work_statuses: [],
      park_accounts: separateFilter(filters, filterData, 'Агрегаторы'),
      search: searchValue || null,
    }

    this.setState({ driversIsLoading: true })

    try {
      const response: AxiosResponse<DriverListResponse> = await axios.get(
        'erp/1/park/drivers',
        { params: data }
      )

      setDriversList({
        total_pages: Math.ceil(response.data.total_amount / limit),
        items: response.data.drivers,
      })
    } finally {
      this.setState({ driversIsLoading: false, searchLoading: false })
    }
  }

  private handleRowClick = (driver: Driver): void =>
    history.push(`/drivers/${driver.park_id}/${driver.id}`)

  private handleRefreshDriversListData = (): void => {
    const { setDriversListPage } = this.props
    setDriversListPage(1)

    setTimeout(() => this.handleRequestDrivers(), 0)
  }

  private handleApplyFilter = (filters: string[]): void => {
    const { setDriversListFilter } = this.props
    setDriversListFilter(filters)

    this.handleRefreshDriversListData()
  }

  private handleClearFilter = (): void => {
    const { setDriversListFilter } = this.props
    setDriversListFilter([])

    this.handleRefreshDriversListData()
  }

  private handleSearch = (value: string): void => {
    const { setDriversListSearch } = this.props
    setDriversListSearch(value)

    this.setState({ searchLoading: true })

    this.handleRefreshDriversListData()
  }

  private handleChangePage = (direction: 'prev' | 'next'): void => {
    const { page, setDriversListPage } = this.props
    const { driversIsLoading } = this.state

    if (!driversIsLoading) {
      if (direction === 'prev') {
        setDriversListPage(page - 1)

        setTimeout(() => this.handleRequestDrivers(), 0)
      } else if (direction === 'next') {
        setDriversListPage(page + 1)

        setTimeout(() => this.handleRequestDrivers(), 0)
      }
    }
  }

  private handleChangeSort = (
    column: string,
    direction: SortDirection
  ): void => {
    const { setDriversSort } = this.props
    if (isSortColumn(column)) {
      setDriversSort({ column, direction })
      setTimeout(() => this.handleRequestDrivers(), 0)
    }
  }

  render() {
    const {
      permissions,
      drivers,
      page,
      totalPages,
      filters,
      searchValue,
      sortColumn,
      sortDirection,
    } = this.props

    const { searchLoading, driversIsLoading, filterData } = this.state

    return (
      <DriversList
        permissions={permissions}
        drivers={drivers}
        search={searchValue}
        filterData={filterData}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        onChangeSort={this.handleChangeSort}
        onRowClick={this.handleRowClick}
        page={page}
        totalPages={totalPages}
        filterList={filters}
        onSearch={this.handleSearch}
        onChangePage={this.handleChangePage}
        onApplyFilter={this.handleApplyFilter}
        onClearFilter={this.handleClearFilter}
        searchLoading={searchLoading}
        driversIsLoading={driversIsLoading}
      />
    )
  }
}

const mapStateToProps = (state: State) => ({
  permissions: apiSelectors.employee.profile.getPermissions(state),
  drivers: state.driversListState.drivers,
  searchValue: state.driversListState.searchValue,
  page: state.driversListState.page,
  totalPages: state.driversListState.totalPages,
  filters: state.driversListState.filters,
  sortColumn: state.driversListState.sort.column,
  sortDirection: state.driversListState.sort.direction,
})

export const DriversListContainer = connect(mapStateToProps, {
  setInfo: setInfoAction,
  setDriversList: setDriversListAction,
  setDriversListFilter: setDriversListFilterAction,
  setDriversListPage: setDriversListPageAction,
  setDriversListSearch: setDriversListSearchAction,
  setDriversSort: setDriversSortAction,
})(Container)
