import { handleActions } from 'redux-actions'

import { Trip } from 'common/types/api/models/trip'

import {
  Payloads,
  SET_TRIPS,
  SET_TRIPS_DATE_FROM,
  SET_TRIPS_DATE_TO,
  SET_TRIPS_FILTER,
  SET_TRIPS_PAGE,
  SET_TRIPS_SEARCH,
} from 'drivers/features/driverTrips/actions'

export interface DriverTripsState {
  trips: Trip[]
  searchValue: string
  page: number
  total: number
  filters: string[]
  dateFrom: Date | null
  dateTo: Date | null
}

const initialState: DriverTripsState = {
  trips: [],
  searchValue: '',
  page: 1,
  total: 0,
  filters: [],
  dateFrom: null,
  dateTo: null,
}

const handleSetTrips = (
  state: DriverTripsState,
  action: SET_TRIPS
): DriverTripsState => ({
  ...state,
  trips: action.payload.items,
  total: action.payload.total,
})

const handleSetTripsSearch = (
  state: DriverTripsState,
  action: SET_TRIPS_SEARCH
): DriverTripsState => ({
  ...state,
  searchValue: action.payload,
})

const handleSetTripsPage = (
  state: DriverTripsState,
  action: SET_TRIPS_PAGE
): DriverTripsState => ({
  ...state,
  page: action.payload,
})

const handleSetTripsFilter = (
  state: DriverTripsState,
  action: SET_TRIPS_FILTER
): DriverTripsState => ({
  ...state,
  filters: action.payload,
})

const handleSetTripsDateFrom = (
  state: DriverTripsState,
  action: SET_TRIPS_DATE_FROM
): DriverTripsState => ({
  ...state,
  dateFrom: action.payload,
})

const handleSetTripsDateTo = (
  state: DriverTripsState,
  action: SET_TRIPS_DATE_TO
): DriverTripsState => ({
  ...state,
  dateTo: action.payload,
})

export default handleActions<DriverTripsState, Payloads>(
  {
    [SET_TRIPS]: handleSetTrips,
    [SET_TRIPS_SEARCH]: handleSetTripsSearch,
    [SET_TRIPS_PAGE]: handleSetTripsPage,
    [SET_TRIPS_FILTER]: handleSetTripsFilter,
    [SET_TRIPS_DATE_FROM]: handleSetTripsDateFrom,
    [SET_TRIPS_DATE_TO]: handleSetTripsDateTo,
  },
  initialState
)
