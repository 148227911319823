import { State } from 'reducers'
import { apiActions, apiSelectors } from 'api'
import { MyThunkDispatch, MyThunkResult } from 'api/reducer'
import { BankType } from 'common/enums'

export const onDidMount: () => MyThunkResult<void> = () => {
  return (dispatch: MyThunkDispatch, getState: () => State) => {
    dispatch(apiActions.park.technicalSupportContactsGet.handleRequest())
    dispatch(apiActions.park.edgvrApiKey.handleRequest())
    return dispatch(apiActions.park.details.handleRequest()).then(() => {
      const bankType = apiSelectors.park.details.getBankType(getState())
      if (bankType === BankType.Alfa) {
        dispatch(apiActions.park.certificateGet.handleRequest())
      } else if (bankType === BankType.Tinkoff) {
        dispatch(apiActions.park.getTinkoff.handleRequest())
      } else if (bankType === BankType.Azon) {
        dispatch(apiActions.park.getParkAzon.handleRequest())
      } else if (bankType !== BankType.Manual) {
        dispatch(apiActions.park.getParkPayoutCredentials.handleRequest())
      }
    })
  }
}
