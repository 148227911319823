import React, { useState } from 'react'
import {
  ContentCard,
  ContentCardBody,
  ContentCardHeader,
  HBox,
} from 'shared/ui'
import {
  CaptionText,
  ColorButton as DefaultColorButton,
} from 'ui-kit/components'
import { ConfirmationForm } from 'settings/features/notificationsTelegramBot/organisms/index'
import styled from 'styled-components'

type Props = {
  link?: string
  onConnect: () => Promise<void>
  onConfirm: (secretKey: string) => Promise<void>
}

const ColorButton = styled(DefaultColorButton)`
  align-self: baseline;
`

export const EnableNotificationsTelegramBot: React.FC<Props> = ({
  link,
  onConnect,
  onConfirm,
}) => {
  const [isFetch, setIsFetch] = useState(false)

  const handleClick = async () => {
    setIsFetch(true)
    await onConnect()
    setIsFetch(false)
  }

  return (
    <ContentCard withMinHeight={false}>
      <ContentCardHeader title="Telegram" />
      <ContentCardBody>
        {!link ? (
          <>
            <CaptionText color="textSecondary">
              Получайте уведомления о важных событиях через Telegram
            </CaptionText>
            <HBox x={2 / 3} />
            <ColorButton
              onClick={handleClick}
              isLoading={isFetch}
              disabled={isFetch}
            >
              Подключить
            </ColorButton>
          </>
        ) : (
          <ConfirmationForm onSave={onConfirm} link={link} />
        )}
      </ContentCardBody>
    </ContentCard>
  )
}
