import React, { useContext, useMemo } from 'react'
import SelectComponent, { components } from 'react-select'

import { BodyText, Icon, ListItem, Radio } from 'ui-kit/components'

import { Option as LocalOption } from 'common/types/local/option'
import { ActionMeta, ValueType } from 'react-select/src/types'
import styled, { ThemeContext } from 'styled-components'

const Wrapper = styled.div<{ isDisabled?: boolean }>`
  .select-chevron {
    transform: rotate(-90deg);
    cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  }

  .muk-radio-wrapper {
    justify-content: space-between;
    width: 100%;
  }

  .react-select {
    &__control {
      &:hover {
        border-color: transparent;
      }

      &--is-focused {
        box-shadow: none;
      }
    }

    &__option {
      background-color: transparent !important;
    }

    &__value-container {
      padding: 0;
    }

    &__single-value {
      margin: 0;
    }
  }
`

interface Props<T> {
  options: LocalOption<T>[]
  name: string
  onChange: (value: ValueType<T>, actionMeta: ActionMeta<any>) => void
  selected?: LocalOption<T>
  value: LocalOption<T>
  className?: string
  placeholder?: string
  isMulti?: boolean
  isClearable?: boolean
  isSearchable?: boolean
  isDisabled?: boolean
  closeMenuOnSelect?: boolean
  hideSelectedOptions?: boolean
  menuIsOpen?: boolean
  menuStyles?: React.CSSProperties
  menuPlacement?: 'top' | 'bottom' | 'auto'
  defaultValue?: { label: any; value: any }
  onBlur?: (selectOption: any) => void
  noOptionsMessage?: () => string
}

const ControlComponent = (props: any): JSX.Element => (
  <components.Control {...props} />
)

const SingleValue = ({ children, ...props }: any): JSX.Element => (
  <BodyText>
    <components.SingleValue {...props}>{children}</components.SingleValue>
  </BodyText>
)

const DropdownIndicator = (props: any): JSX.Element => (
  <components.DropdownIndicator {...props}>
    <Icon className="select-chevron" name="chevronLeft" />
  </components.DropdownIndicator>
)

const Placeholder = ({ children, ...props }: any): JSX.Element => (
  <components.Placeholder {...props}>
    <BodyText color="gray6">{children}</BodyText>
  </components.Placeholder>
)

export const Select: React.FC<Props<any>> = ({
  options,
  isMulti,
  selected,
  onChange,
  onBlur,
  defaultValue,
  isSearchable,
  isClearable,
  className,
  isDisabled,
  closeMenuOnSelect,
  name,
  menuPlacement,
  hideSelectedOptions,
  menuIsOpen,
  noOptionsMessage,
  placeholder,
  value,
  menuStyles,
}) => {
  const theme = useContext(ThemeContext)

  const styles = useMemo(() => {
    return {
      dropdownIndicator: (base: React.CSSProperties) => ({
        ...base,
        padding: 0,
        marginLeft: '16px',
      }),
      control: (base: React.CSSProperties) => ({
        ...base,
        minWidth: '146px',
        width: '100%',
        border: 'none',
        outline: 'none',
        cursor: 'pointer',
      }),
      option: (base: React.CSSProperties) => ({
        ...base,
        padding: 0,
        backgroundColor: 'transparent',
      }),
      menu: (base: React.CSSProperties) => ({
        ...base,
        backgroundColor: theme.pallete.white,
        boxShadow: '0 3px 10px rgba(5, 16, 34, 0.1)',
        margin: 0,
        ...menuStyles,
      }),
      menuList: (base: React.CSSProperties) => ({
        ...base,
        padding: 0,
      }),
    }
  }, [menuStyles, theme.pallete.white])

  const Option = ({ isSelected, children, ...rest }: any): JSX.Element => (
    <ListItem>
      <components.Option {...rest}>
        <Radio name={name} checked={isSelected}>
          {children}
        </Radio>
      </components.Option>
    </ListItem>
  )

  return (
    <Wrapper isDisabled={isDisabled}>
      <SelectComponent
        options={options}
        name={name}
        value={value}
        selected={selected}
        isMulti={isMulti}
        onChange={onChange}
        onBlur={onBlur}
        defaultValue={defaultValue}
        isClearable={isSearchable}
        isSearchable={isClearable}
        className={className}
        isDisabled={isDisabled}
        closeMenuOnSelect={closeMenuOnSelect}
        classNamePrefix="react-select"
        menuPlacement={menuPlacement}
        hideSelectedOptions={hideSelectedOptions}
        menuIsOpen={menuIsOpen}
        noOptionsMessage={noOptionsMessage}
        placeholder={placeholder}
        styles={styles}
        components={{
          Control: ControlComponent,
          SingleValue,
          DropdownIndicator,
          IndicatorSeparator: null,
          Placeholder,
          Option,
        }}
      />
    </Wrapper>
  )
}
