import React from 'react'
import {
  CaptionText,
  ColorButton as DefaultColorButton,
} from 'ui-kit/components'
import {
  Field,
  FormSubmitHandler,
  InjectedFormProps,
  reduxForm,
  SubmissionError,
} from 'redux-form'
import { FormNames } from 'common/enums'
import ListInput from 'common/components/formItems/ListInput'
import { required } from 'common/utils/fieldValidation'
import { HBox } from 'shared/ui/spacers'
import styled from 'styled-components'

type OwnProps = {
  link: string
  onSave: (secret: string) => Promise<void>
}

const Wrapper = styled.div``

const BotName = styled.span``

const ColorButton = styled(DefaultColorButton)`
  align-self: baseline;
`

type Props = InjectedFormProps<FormProps, OwnProps> & OwnProps

export const ConfirmationForm = ({
  link,
  onSave,
  submitting,
  handleSubmit,
  pristine,
}: Props) => {
  const handleSave = () => {
    const submit: FormSubmitHandler<FormProps, OwnProps> = async (values) => {
      try {
        await onSave(values.secret)
      } catch (e) {
        throw new SubmissionError(e)
      }
    }

    return handleSubmit(submit)
  }

  const botName = link.split('t.me/')[1]

  return (
    <Wrapper>
      <CaptionText color="textSecondary">
        <a href={link} target="_blank" rel="noopener noreferrer">
          Перейдите в чат с ботом
        </a>{' '}
        или найдите его по имени <BotName>{botName}</BotName>. Напишите боту.
        Скопируйте ключ, который он пришлет, в поле ниже
      </CaptionText>

      <Field
        name="secret"
        label="Ключ"
        iconName="close"
        validate={[required]}
        component={ListInput}
      />

      <HBox x={2 / 3} />

      <ColorButton
        onClick={handleSave()}
        disabled={pristine}
        isLoading={submitting}
      >
        Отправить
      </ColorButton>
    </Wrapper>
  )
}

export type FormProps = {
  secret: string
}

export default reduxForm<FormProps, OwnProps>({
  form: FormNames.TelegramBot,
})(ConfirmationForm)
