import React from 'react'

import { BodyText, Icon } from 'ui-kit/components'
import { IconNames } from 'ui-kit'

import { Row } from 'shared/ui/flex'
import { VBox } from 'shared/ui/spacers'
import { Tooltip } from 'shared/ui/tooltip'
import styled from 'styled-components'

const Container = styled.div<{ border: boolean }>`
  display: flex;
  height: 72px;
  padding: 0 ${({ theme }) => theme.paddings.threeQuarters}px;

  border-bottom: 1px solid
    ${({ theme, border }) => (border ? theme.pallete.gray3 : 'transparent')};
`

const Right = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

interface Props {
  className?: string
  children?: React.ReactNode
  title?: string
  icon?: IconNames
  border?: boolean
  tooltip?: {
    content: string
    component: React.FC
  }
  iconWidth?: number
  iconHeight?: number
  iconHackyMarginLeft?: number
  iconHackyMarginRight?: number
}

export const ContentCardHeader: React.FC<Props> = ({
  children,
  title,
  icon,
  border = true,
  tooltip,
  className,
  iconWidth,
  iconHeight,
  iconHackyMarginLeft,
  iconHackyMarginRight,
}) => (
  <Container border={border} className={className}>
    {title || icon ? (
      <Row>
        {icon ? (
          <>
            <Icon
              className="icon"
              name={icon}
              width={iconWidth}
              height={iconHeight}
              marginLeft={iconHackyMarginLeft}
              marginRight={iconHackyMarginRight}
            />
            <VBox x={0.5} />
          </>
        ) : null}
        {title ? <BodyText fontWeight="semi-bold">{title}</BodyText> : null}
      </Row>
    ) : null}

    {children || tooltip ? (
      <Right>
        {children ||
          (tooltip ? (
            <>
              <VBox x={0.5} />
              <Tooltip
                content={tooltip.content}
                contentComponent={tooltip.component}
              />
            </>
          ) : null)}
      </Right>
    ) : null}
  </Container>
)
