import React from 'react'
import styled from 'styled-components'

const Container = styled.div<{ border: boolean }>`
  display: flex;
  height: 72px;
  padding: 0 ${({ theme }) => theme.paddings.threeQuarters}px;
  align-items: center;
  border-top: 1px solid
    ${({ theme, border }) => (border ? theme.pallete.gray3 : 'transparent')};
`

interface Props {
  className?: string
  children?: React.ReactNode
  border?: boolean
}

export const ContentCardFooter: React.FC<Props> = ({
  children,
  border = true,
  className,
}) => (
  <Container border={border} className={className}>
    {children}
  </Container>
)
