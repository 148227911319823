import React from 'react'
import { CaptionText, SubBodyText } from 'ui-kit/components'
import { ContentCard, ContentCardBody } from 'shared/ui'
import { ContainerProps } from 'settings/features/notificationsContacts/containers/NotificationsContactsContainer'

export const NotificationsContacts: React.FC<ContainerProps> = ({
  contactEmail,
}) => (
  <ContentCard withMinHeight={false}>
    <ContentCardBody>
      <CaptionText color="gray8">Получатель</CaptionText>
      <SubBodyText>{contactEmail}</SubBodyText>
    </ContentCardBody>
  </ContentCard>
)
