import { createSelector } from 'reselect'
import { State } from 'reducers'

export const getIsFetching = (state: State) =>
  state.api.park.calculateTopupGift.status === 'loading'

export const getStatus = (state: State) =>
  state.api.park.calculateTopupGift.status

const getData = (state: State) => state.api.park.calculateTopupGift.data

export const getGiftAmount = createSelector(getData, (data) =>
  data ? data.gift_amount : 0
)
