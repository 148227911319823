import { PaginationLimit } from 'api/types'

type StateType = {
  limit: PaginationLimit
  page: number
  search: string
}

export const initialState: StateType = {
  limit: 20,
  page: 1,
  search: '',
}

type ActionType = {
  type: 'changePage' | 'changeLimit' | 'changeSearch'
  pageOffset?: number
  limit?: PaginationLimit
  search?: string
}

export function reducer(state: StateType, action: ActionType): StateType {
  switch (action.type) {
    case 'changePage':
      return {
        ...state,
        page: state.page + (action.pageOffset ? action.pageOffset : 0),
      }
    case 'changeLimit':
      return {
        ...state,
        limit: action.limit ? action.limit : state.limit,
        page: 1,
      }
    case 'changeSearch':
      return {
        ...state,
        search: action.search !== undefined ? action.search : state.search,
        page: 1,
      }
    default:
      return state
  }
}
