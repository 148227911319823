import React from 'react'

import { BodyText, Icon, TableText } from 'ui-kit/components'
import { Wrapper } from 'ui-kit/components/Checkbox/styles'

interface Props {
  name: string
  className?: string
  inputId?: string
  value?: string
  checked?: boolean
  disabled?: boolean
  defaultChecked?: boolean
  reverse?: boolean
  table?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
}

export const Checkbox: React.FC<Props> = (props) => {
  const {
    name,
    inputId,
    onChange,
    onFocus,
    onBlur,
    checked,
    disabled,
    reverse,
    value,
    defaultChecked,
    table,
    children,
  } = props

  return (
    <Wrapper
      htmlFor={inputId}
      className="muk-checkbox-wrapper"
      disabled={disabled}
      reverse={reverse}
    >
      <input
        hidden
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        name={name}
        type="checkbox"
        defaultChecked={defaultChecked}
        id={inputId}
        checked={checked}
        disabled={disabled}
        value={value}
      />
      <span className="muk-checkbox-mark">
        <Icon name="check" className="muk-checkbox-mark-icon" />
      </span>
      {children &&
        (table ? (
          <TableText className="muk-checkbox-label">{children}</TableText>
        ) : (
          <BodyText className="muk-checkbox-label">{children}</BodyText>
        ))}
    </Wrapper>
  )
}
