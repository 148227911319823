import React from 'react'
import { Field } from 'redux-form'

import { CaptionText, Icon, SubBodyText } from 'ui-kit/components'
import Switch from 'common/components/formItems/Switch'

import { HBox } from 'shared/ui/index'
import styled from 'styled-components'

const Wrapper = styled.div<{ overlay?: boolean }>`
  position: relative;

  ${({ overlay }) =>
    overlay &&
    `
      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 100;
        background-color: rgba(255, 255, 255, .7);
      }
  `}
`

type Props = {
  isLocked?: boolean
  canChangePaymentsConditions?: boolean
  withAllowAutoTransfer?: boolean
  onSave: (newValue: boolean, prevValue: boolean, name: string) => Promise<void>
}

const VerticalIcon = styled(Icon)`
  vertical-align: middle;
`

export const PaymentSettingsSwitchesFields: React.FC<Props> = ({
  isLocked,
  canChangePaymentsConditions,
  withAllowAutoTransfer,
  onSave,
}) => {
  const handleChange = async (
    _: any,
    newValue: boolean,
    prevValue: boolean,
    name: string
  ) => {
    await onSave(newValue, prevValue, name)
  }

  return (
    <Wrapper overlay={isLocked}>
      <Field<any>
        name="withdraw_restricted"
        component={Switch}
        onChange={handleChange}
        disabled={!canChangePaymentsConditions}
      >
        <SubBodyText>Запретить</SubBodyText>
        <CaptionText color="gray6">
          Водитель не сможет выводить деньги.
        </CaptionText>
      </Field>

      {!withAllowAutoTransfer && (
        <>
          <HBox x={2 / 3} />
          <Field<any>
            name="auto_withdraw"
            component={Switch}
            onChange={handleChange}
            disabled={!canChangePaymentsConditions}
          >
            <SubBodyText>Автоподтверждение</SubBodyText>
            <CaptionText color="gray6">
              Отправлять платежи в банк без необходимости их подтверждать.
              Настройка начнет действовать, когда водитель получит статус
              надежного
              <VerticalIcon name="successful" />
            </CaptionText>
          </Field>
        </>
      )}
    </Wrapper>
  )
}
