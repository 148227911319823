import React from 'react'

import { BodyText, CaptionText, Icon } from 'ui-kit/components'
import { DriverCard } from 'api/types'
import { Col, Flex1, VBox } from 'shared/ui'
import styled from 'styled-components'

const Minimal = styled.div`
  padding-left: ${({ theme }) => theme.paddings.threeQuarters}px;
  padding-right: ${({ theme }) => theme.paddings.threeQuarters}px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
`

type Props = {
  card: DriverCard
}

export const Line: React.FC<Props> = ({ card }) => (
  <>
    <Minimal>
      <Icon name="card" />
      <VBox />
      <Flex1>
        <Col>
          <BodyText>{`•••• •••• •••• ${card.pan}`}</BodyText>
          <CaptionText color="gray8" />
        </Col>
      </Flex1>
    </Minimal>
  </>
)
