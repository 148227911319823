import React from 'react'
import InputMask, { ReactInputMask } from 'react-input-mask'

import { ListInput } from 'ui-kit/components'

const dateMask = '99.99.9999'

interface Props {
  label?: string
  value?: string
  type?: string
  required?: boolean
  showError?: boolean
  error?: string
  alwaysShowMask?: boolean
}

type OwnProps = ReactInputMask & Props & any

const CustomTrigger = React.forwardRef<HTMLInputElement, OwnProps>(
  (props, ref) => (
    <InputMask {...props} mask={dateMask} maskChar={null}>
      {(inputProps: any) => (
        <ListInput
          ref={ref}
          {...inputProps}
          className="picker-custom-input"
          showError={props.showError}
          error={props.error}
          alwaysShowMask={props.alwaysShowMask}
          required={props.required}
        >
          {props.children}
        </ListInput>
      )}
    </InputMask>
  )
)

CustomTrigger.displayName = 'CustomTrigger'

export default CustomTrigger
