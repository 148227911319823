export enum Activities {
  ChangeCompanyDetails = 'change_company_details',
  ChangeChannelSettings = 'change_channel_settings',
  ChangePaymentSettings = 'change_payment_settings',
  ChangePaymentCertificate = 'change_payment_certificate',
  ChangeEmployee = 'change_employee',
  ChangeEmployeePermissions = 'change_employee_permissions',
  ChangeEmployeeContract = 'change_employee_contract',
  CreateEmployee = 'create_employee',
  CreateTransaction = 'create_transaction',
  CreateReport = 'create_report',
  BlockEmployee = 'block_employee',
  UnblockEmployee = 'unblock_employee',
  TopupDeposit = 'topup_deposit',
  ConfirmTransaction = 'confirm_transaction',
  RejectTransaction = 'reject_transaction',
  ChangeCompanyEdgvrApiKey = 'change_company_edgvr_api_key',
  ChargeToTheDriver = 1,
}

// Больше не актуально, т.к. с сервера приходит поле title.
export const activityToString = (activity: Activities): string => {
  switch (activity) {
    case Activities.ChangeCompanyDetails:
      return 'Изменение данных компании'
    case Activities.ChangeChannelSettings:
      return 'Изменение настроек канала'
    case Activities.ChangePaymentSettings:
      return 'Изменение настроек оплаты'
    case Activities.ChangePaymentCertificate:
      return 'Изменение платежного сертификата'
    case Activities.ChangeEmployee:
      return 'Изменение сотрудника'
    case Activities.ChangeEmployeePermissions:
      return 'Изменение прав сотрудника'
    case Activities.ChangeEmployeeContract:
      return 'Изменение контракта сотрудника'
    case Activities.CreateEmployee:
      return 'Создание сотрудника'
    case Activities.CreateTransaction:
      return 'Создание транзакции'
    case Activities.CreateReport:
      return 'Создание отчета'
    case Activities.BlockEmployee:
      return 'Блокирование сотрудника'
    case Activities.UnblockEmployee:
      return 'Разблокирование сотрудника'
    case Activities.TopupDeposit:
      return 'Пополнение баланса'
    case Activities.ConfirmTransaction:
      return 'Подтверждение транзакции'
    case Activities.RejectTransaction:
      return 'Отклонение транзакции'
    case Activities.ChargeToTheDriver:
      return 'Начисление средств водителю'
    case Activities.ChangeCompanyEdgvrApiKey:
      return 'Изменение ключа api для edgvr'
    default:
      return 'Unknown'
  }
}
