import styled from 'styled-components'

export const Form = styled.form`
  max-width: 464px;
  width: 100%;

  > div {
    margin-bottom: 8px;
  }
`

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const RightContent = styled.div`
  position: relative;
  height: calc(100vh - 170px);

  .sign-up-right-title {
    max-width: 520px;
    right: 0;
    position: absolute;
  }
`

export const ImageHolder = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  img {
    max-width: 684px;
    min-width: 591px;
  }
`

export const ResetPassword = styled.div`
  margin-bottom: 0 !important;
  display: inline-block;
  cursor: pointer;
`

export const Action = styled.div<{ isReset?: boolean }>`
  margin-bottom: 0 !important;

  button {
    max-width: 320px;
  }
`

export const ActionLink = styled.div`
  display: inline-block;
  cursor: pointer;
`

export const Description = styled.div`
  min-height: 96px;
`
