import React from 'react'

import { NavigationPayload } from 'common/types/local/navigation'
import { Link } from 'layout/features/sidebar/molecules'
import styled from 'styled-components'

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const ListItem = styled.li`
  width: 100%;
`

type Props = {
  menu: NavigationPayload[]
}

export const Nav: React.FC<Props> = ({ menu }) => (
  <List>
    {menu.map((item) => (
      <ListItem className="muk-side-menu-item" key={item.iconName}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link item={item} />
      </ListItem>
    ))}
  </List>
)
