import Alert from 'react-s-alert'
import { apiActions } from 'api'
import { MyThunkDispatch, MyThunkResult } from 'api/reducer'
import { ModalsNames } from 'common/enums'
import { modalsActions } from 'shared/modals'

export const onSaveStatus: ({ id, status }: any) => MyThunkResult<void> = ({
  id,
  status,
}) => (dispatch: MyThunkDispatch) => {
  return dispatch(
    apiActions.employees.employeesByIdStatus.handleRequest({ id, status })
  ).then(
    () => {
      dispatch(modalsActions.closeModal(ModalsNames.BlockEmployee))
      dispatch(apiActions.employees.employeesByIdGet.handleRequest(id))
    },
    () => {
      Alert.error('Ошибка', {
        customFields: {
          text: 'Не удалось',
        },
      })
    }
  )
}
