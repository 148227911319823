import { createApiReducer } from 'common/utils/reduxApiFactory'
import { GetIncomeReportState } from 'api/park/income-report/get/types'
import { getIncomeReportActions } from 'api/park/income-report/get/actions'

export const initialState: GetIncomeReportState = {
  status: null,
  data: null,
}

export const getIncomeReportReducer = createApiReducer(
  getIncomeReportActions,
  initialState
)
