import React from 'react'
import TableSectionContext from 'ui-kit/atoms/Table/TableContext'
import styled, { FlattenSimpleInterpolation, css } from 'styled-components'

type TableCellAlign = 'left' | 'center' | 'right' | 'justify'
type TextAlignStyle = {
  [key in TableCellAlign]: FlattenSimpleInterpolation
}

type TableCellComponentProps = {
  className?: string
  children: React.ReactNode
  align?: TableCellAlign
  width?: number
}

const Table = styled.table`
  background-color: white;
  width: 100%;
  min-width: 650px;
  display: table;
  border-spacing: 0;
  border-collapse: collapse;
`

const TableHead = styled.thead`
  display: table-header-group;
`

const TableBody = styled.tbody`
  display: table-row-group;

  &:before {
    content: '';
    display: block;
    height: 8px;
  }
  &:after {
    content: '';
    display: block;
    height: 16px;
  }
`

const TableCellContent = styled.span<{ isHead?: boolean }>`
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
`

const TableRow = styled.tr<{
  isSelected?: boolean
  withBorder?: boolean
  withHover?: boolean
  minHeight?: number
}>`
  display: table-row;
  outline: 0;
  vertical-align: baseline;
  background-color: ${({ theme, isSelected }) =>
    isSelected && theme.pallete.accentPrimary};

  &:hover {
    background-color: ${({ theme, isSelected, withHover }) =>
      !isSelected && withHover && theme.pallete.uiHover};
  }

  ${({ withBorder }) =>
    withBorder &&
    css`
      border-bottom: 1px solid
        ${({ theme }) => theme.pallete.secondaryLightBlue};
    `};

  ${({ minHeight, withBorder }) =>
    minHeight !== undefined &&
    css`
      & ${TableCellContent} {
        min-height: ${withBorder ? minHeight - 18 : minHeight - 18}px;
      }
    `};
`

const textAlignStyle: TextAlignStyle = {
  left: css`
    text-align: left;
  `,
  center: css`
    text-align: center;
  `,
  right: css`
    text-align: right;
    flex-direction: row-reverse;
  `,
  justify: css`
    text-align: justify;
  `,
}

const TableCell = styled.td<{ align?: TableCellAlign; width?: number }>`
  width: ${({ width }) => (width !== undefined ? `${width}px` : 'auto')};

  display: table-cell;
  vertical-align: baseline;
  padding-left: 16px;
  box-sizing: border-box;
  padding-top: 8px;
  padding-bottom: 8px;

  &:last-child {
    padding-right: 16px;
  }

  ${({ align = 'left' }) => textAlignStyle[align]}
`

const TableComponent = ({ className, children }: TableComponentProps) => {
  return <Table className={className}>{children}</Table>
}

const TableHeadComponent = ({
  className,
  children,
}: TableHeadComponentProps) => {
  return (
    <TableSectionContext.Provider value="head">
      <TableHead className={className}>{children}</TableHead>
    </TableSectionContext.Provider>
  )
}

const TableBodyComponent = ({
  className,
  children,
}: TableBodyComponentProps) => {
  return (
    <TableSectionContext.Provider value="body">
      <TableBody className={className}>{children}</TableBody>
    </TableSectionContext.Provider>
  )
}

const TableRowComponent = ({
  className,
  children,
  withBorder,
  minHeight,
  withHover,
}: TableRowComponentProps) => {
  const section = React.useContext(TableSectionContext)

  return (
    <TableRow
      className={className}
      withBorder={section === 'head' || withBorder}
      minHeight={minHeight}
      withHover={section === 'body' || withHover}
    >
      {children}
    </TableRow>
  )
}

const TableCellComponent = ({
  className,
  children,
  align,
  width,
}: TableCellComponentProps) => {
  const section = React.useContext(TableSectionContext)
  return (
    <TableCell
      className={className}
      as={section === 'head' ? 'th' : 'td'}
      align={align}
      width={width}
    >
      <TableCellContent isHead={section === 'head'}>
        {children}
      </TableCellContent>
    </TableCell>
  )
}

type TableComponentProps = {
  className?: string
  children: (
    | React.ReactElement<typeof TableHeadComponent>
    | React.ReactElement<typeof TableBodyComponent>[]
    | boolean
  )[]
}

type TableHeadComponentProps = {
  className?: string
  children: React.ReactElement<typeof TableRowComponent>
}

type TableBodyComponentProps = {
  className?: string
  children: React.ReactElement<typeof TableRowComponent>[]
}

type TableRowComponentProps = {
  className?: string
  children: React.ReactElement<typeof TableCellComponent>[]
  withBorder?: boolean
  withHover?: boolean
  minHeight?: number
}

export default Object.assign(React.memo(TableComponent), {
  Head: React.memo(TableHeadComponent),
  Body: React.memo(TableBodyComponent),
  Row: React.memo(TableRowComponent),
  Cell: React.memo(TableCellComponent),
})
