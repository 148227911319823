import React from 'react'
import { debounce } from 'lodash'

import { Wrapper } from 'shared/table/components/Search/styles'

import { Searchbar } from 'ui-kit/components'

interface Props {
  onSetSearch: (value: string) => void
  delay?: number
  isLoading?: boolean
  borderBottom?: boolean
  value?: string
  placeholder?: string
  disabled?: boolean
}

interface LocalState {
  value?: string
}

export class Search extends React.Component<Props, LocalState> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    delay: 300,
  }

  private searchFunc = ((props) => {
    const { onSetSearch, delay } = props

    return debounce((query: string): void => {
      onSetSearch(query)
    }, delay)
  })(this.props)

  constructor(props: Props) {
    super(props)

    this.state = {
      value: props.value || '',
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { value } = this.props
    if (value !== prevProps.value) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ value })
    }
  }

  private onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { disabled } = this.props
    if (disabled) {
      return
    }

    this.setState({ value: e.currentTarget.value })

    this.searchFunc(e.currentTarget.value)
  }

  render() {
    const { disabled, placeholder, isLoading, borderBottom } = this.props
    const { value } = this.state

    return (
      <Wrapper withLockOverlay={disabled}>
        <Searchbar
          onChange={this.onChange}
          value={value}
          placeholder={placeholder}
          isLoading={isLoading}
          borderBottom={borderBottom}
        />
      </Wrapper>
    )
  }
}
