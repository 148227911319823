import React, { useState } from 'react'
import styled from 'styled-components'

import { ColorTypes, FontWeightTypes } from 'ui-kit'

import {
  SmallLoader,
  SubBodyText as DefaultSubBodyText,
} from 'ui-kit/components'

import { Base, DefaultProps } from 'ui-kit/components/Buttons/Base/Base'

const Container = styled(Base)<{
  color: ColorTypes
  fullWidth?: boolean
  disabled?: boolean
  isLoading?: boolean
}>`
  width: ${({ fullWidth }) => fullWidth && '100%'};
  background-color: ${({ disabled, isLoading, color, theme }) =>
    disabled && !isLoading ? theme.pallete.gray3 : theme.pallete[color]};
`

interface Props extends DefaultProps {
  color?: ColorTypes
  textColor?: ColorTypes
  loaderColor?: ColorTypes
  fullWidth?: boolean
  fontWeight?: FontWeightTypes
  isLoading?: boolean
  /** deprecated */
  id?: never
  /** deprecated */
  className?: never
  /** deperecated */
  hovered?: never
  /** deperecated */
  active?: never
  /** deperecated */
  onMouseOver?: never
  /** deperecated */
  onMouseOut?: never
}

const SubBodyText = styled(DefaultSubBodyText)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const ColorButton: React.FC<Props> = ({
  children,
  type = 'button',
  disabled,
  isLoading,
  color = 'blue' as ColorTypes,
  textColor = 'white' as ColorTypes,
  loaderColor = 'white' as ColorTypes,
  fullWidth,
  fontWeight,
  onClick,
  className,
}) => {
  const subBodyTextColor = disabled ? 'gray6' : textColor
  const [isHovered, setIsHovered] = useState(false)
  return (
    <Container
      className={className}
      type={type}
      color={color}
      fullWidth={fullWidth}
      isLoading={isLoading}
      disabled={disabled || isLoading}
      onClick={onClick}
      hovered={isHovered}
      onMouseEnter={disabled ? undefined : () => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isLoading ? (
        <SmallLoader white loaderColor={loaderColor} />
      ) : (
        <SubBodyText color={subBodyTextColor} fontWeight={fontWeight}>
          {children}
        </SubBodyText>
      )}
    </Container>
  )
}
