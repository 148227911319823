import React, { useState } from 'react'

import { Icon, TransparentButton } from 'ui-kit/components'
import ListInput from 'common/components/formItems/ListInput'

import {
  CancelOrSave,
  ContentCard,
  ContentCardBody,
  ContentCardHeader,
  HBox,
} from 'shared/ui'
import { AddTinkoffModalContainer } from 'settings/features/parkTinkoff/containers'

import {
  ContainerProps,
  FormProps,
} from 'settings/features/parkTinkoff/containers/ParkTinkoffContainer'
import { Field, InjectedFormProps, SubmissionError } from 'redux-form'
import { required } from 'common/utils/fieldValidation'
import Alert from 'react-s-alert'

const tinkoffProps = {
  title: 'Счет в Тинькофф',
  iconWidth: 42,
  iconHeight: 42,
  iconHackyMarginLeft: -9,
  iconHackyMarginRight: -5,
}

export const ParkTinkoff: React.FC<
  InjectedFormProps<FormProps, ContainerProps> & ContainerProps
> = ({
  isFetching,
  submitting,
  handleSubmit,
  fetchEdit,
  data,
  isAddTinkoffModalOpened,
  openAddTinkoffModal,
  reset,
}) => {
  const [isEdit, setIsEdit] = useState(false)
  const toggleEdit = () => {
    setIsEdit((prevState) => !prevState)
  }

  const handleEdit = async (values: FormProps) => {
    try {
      await fetchEdit(values)
      setIsEdit(false)
    } catch (e) {
      Alert.error('Ошибка', {
        customFields: {
          text:
            'При обработке запроса произошла ошибка. Проверьте данные и попробуйте еще раз',
        },
      })
      throw new SubmissionError(e)
    }
  }

  const handleCancel = () => {
    toggleEdit()
    reset()
  }

  const isConnected = Boolean(data)

  return (
    <>
      {!isConnected && (
        <ContentCard
          zeroState
          isLoading={isFetching}
          withMinHeight={isFetching}
        >
          <ContentCardHeader
            title={tinkoffProps.title}
            icon="tinkoff"
            iconWidth={tinkoffProps.iconWidth}
            iconHeight={tinkoffProps.iconHeight}
            iconHackyMarginLeft={tinkoffProps.iconHackyMarginLeft}
            iconHackyMarginRight={tinkoffProps.iconHackyMarginRight}
            border={false}
          >
            <TransparentButton
              onClick={openAddTinkoffModal}
              fontWeight="semi-bold"
            >
              Подключить
            </TransparentButton>
          </ContentCardHeader>
        </ContentCard>
      )}
      {isConnected && (
        <ContentCard>
          <ContentCardHeader
            title={tinkoffProps.title}
            icon="tinkoff"
            iconWidth={tinkoffProps.iconWidth}
            iconHeight={tinkoffProps.iconHeight}
            iconHackyMarginLeft={tinkoffProps.iconHackyMarginLeft}
            iconHackyMarginRight={tinkoffProps.iconHackyMarginRight}
          >
            {!isEdit && (
              <TransparentButton onClick={toggleEdit} fontWeight="semi-bold">
                Настроить подключение
              </TransparentButton>
            )}
            {isEdit && (
              <CancelOrSave
                onSubmit={handleSubmit(handleEdit)}
                onCancel={handleCancel}
                isLoading={submitting}
              />
            )}
          </ContentCardHeader>
          <ContentCardBody>
            {isEdit ? (
              <>
                <Field
                  name="terminal_key"
                  label="Terminal Key"
                  validate={required}
                  component={ListInput}
                  clearOnFocus
                />
                <HBox x={0.5} />
              </>
            ) : (
              <Icon name="statusBig">Подключен</Icon>
            )}
          </ContentCardBody>
        </ContentCard>
      )}
      {isAddTinkoffModalOpened && <AddTinkoffModalContainer />}
    </>
  )
}
