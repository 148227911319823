import { EuiSpacer, EuiSwitch } from '@elastic/eui'
import React from 'react'
import { Filter } from '../../types'

interface Props {
  filters: Filter[]
  onFilterChange(changedFilter: Filter): void
}

export const Filters: React.FC<Props> = ({ filters, onFilterChange }) => {
  const lastIndex = filters.length - 1
  return (
    <>
      {filters.map((filter, index) => (
        <div key={filter.type}>
          <EuiSwitch
            name={filter.type}
            label={filter.label}
            checked={filter.isEnabled}
            onChange={() => onFilterChange(filter)}
          />
          {index !== lastIndex ? <EuiSpacer /> : null}
        </div>
      ))}
    </>
  )
}
