import React, { useCallback, useEffect, useRef } from 'react'
import { connect } from 'react-redux'

import { State } from 'reducers'
import { MyThunkDispatch } from 'api/reducer'
import { apiActions, apiSelectors } from 'api'
import { selectPaymentSettings } from 'settings/features/paymentSettingsTab/selectors'
import { PaymentSettings } from 'api/types'
import { BankType } from 'common/enums'

type OwnProps = {
  children(childrenProps: ChildrenProps): React.ReactElement
}

type ChildrenProps = {
  bankType: BankType | null
  isPreload: boolean
  settingsList: PaymentSettings[] | null
  createSettings: (settingsName: string) => Promise<void>
  deleteSettings: (settingsId: string) => Promise<void>
}

const mapStateToProps = (state: State) => ({
  bankType: apiSelectors.park.details.getBankType(state),
  paymentSettings: selectPaymentSettings(state),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  fetchPaymentSettings: () =>
    dispatch(apiActions.park.paymentSettingsAll.handleRequest()),
  fetchCreatePaymentSettings: (settingsName: string) =>
    dispatch(
      apiActions.park.paymentSettingsPost.handleRequest({ name: settingsName })
    ),
  fetchDeletePaymentSettings: (settingsId: string) =>
    dispatch(
      apiActions.park.paymentSettingsDelete.handleRequest({
        payment_settings_id: settingsId,
      })
    ),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps

export const PurePaymentSettingsTabConnector: React.FC<ContainerProps> = ({
  bankType,
  children,
  paymentSettings,
  fetchPaymentSettings,
  fetchCreatePaymentSettings,
  fetchDeletePaymentSettings,
}) => {
  const isPreload = useRef(true)
  useEffect(() => {
    const loadData = async () => {
      await fetchPaymentSettings()
    }
    loadData()
    isPreload.current = false
  }, [fetchPaymentSettings])

  const createSettings = useCallback(
    async (settingsName: string) => {
      await fetchCreatePaymentSettings(settingsName)
      await fetchPaymentSettings()
    },
    [fetchCreatePaymentSettings, fetchPaymentSettings]
  )

  const deleteSettings = useCallback(
    async (settingsId: string) => {
      await fetchDeletePaymentSettings(settingsId)
      await fetchPaymentSettings()
    },
    [fetchDeletePaymentSettings, fetchPaymentSettings]
  )

  return children({
    isPreload: isPreload.current,
    settingsList: paymentSettings,
    createSettings,
    deleteSettings,
    bankType,
  })
}

export const PaymentSettingsTabConnector = connect(
  mapStateToProps,
  mapDispatchToProps
)(PurePaymentSettingsTabConnector)
