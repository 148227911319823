import React from 'react'

import ListItemComponent from 'common/components/listItem/ListItem'
import { ColumnProps } from 'shared/table/interfaces'

import { ListItem, ListLead } from 'ui-kit/components'

interface Props {
  row: Record<string, unknown>
  columns: ColumnProps[]
  onClickRow?: (item: Record<string, unknown>) => void
}

export const Row: React.FC<Props> = ({ row, columns, onClickRow }) => {
  const onComponentClick = (column: ColumnProps) =>
    onClickRow
      ? (e: React.MouseEvent<HTMLDivElement>) =>
          column.disableRowClick ? e.stopPropagation() : false
      : undefined

  return (
    <ListItem
      className="row-item"
      border="table"
      onClick={onClickRow ? () => onClickRow(row) : undefined}
      pointer={!!onClickRow}
    >
      {columns.map((column) => (
        <ListItemComponent
          key={column.data.toString()}
          border="table"
          flex={column.flex}
          className={
            column.className
              ? `list-item-inside ${column.className}`
              : 'list-item-inside'
          }
          onClick={onComponentClick(column)}
        >
          {typeof column.data === 'string' ? (
            <ListLead table>{(row as any)[column.data as string]}</ListLead>
          ) : (
            column.data(row)
          )}
        </ListItemComponent>
      ))}
    </ListItem>
  )
}
