import React from 'react'
import {
  clearFields,
  InjectedFormProps,
  reduxForm,
  SubmissionError,
} from 'redux-form'
import axios, { AxiosResponse } from 'axios'

import { history, store } from 'store'

import { RoutesPath } from 'common/enums'

import SignInComponent from 'auth/signIn/components/SignInComponent'
import { AuthTokens, setAuthTokens } from 'jwtAuth'
import { transformToAuthTokens } from 'bootstrap'

const FORM_NAME = 'SignIn'

interface FormProps {
  email: string
  password?: string
}

interface LocalState {
  submitLoading?: boolean
  isReset?: boolean
}

class SignInContainer extends React.Component<
  InjectedFormProps<FormProps>,
  LocalState
> {
  constructor(props: InjectedFormProps<FormProps>) {
    super(props)

    this.state = {
      submitLoading: false,
      isReset: false,
    }
  }

  private handleSetResetPassword = (isReset?: boolean): void => {
    this.setState({ isReset })
    store.dispatch(clearFields(FORM_NAME, false, false, 'email', 'password'))
  }

  private handleSubmit = async (values: FormProps) => {
    this.setState({ submitLoading: true })
    const { isReset } = this.state

    if (!isReset) {
      try {
        const response: AxiosResponse<AuthTokens> = await axios.post(
          'auth/1/login/email',
          values,
          {
            transformResponse: transformToAuthTokens,
          }
        )

        setAuthTokens(response.data)
        this.setState({ submitLoading: false })
        history.replace(RoutesPath.Payments)
      } catch (error) {
        this.setState({ submitLoading: false })
        if (error.response && error.response.status === 403) {
          throw new SubmissionError({
            email: 'Неверный логин',
            password: 'Неверный пароль',
          })
        }
      }
    } else {
      try {
        await axios.post('auth/1/password/reset', { email: values.email })

        this.handleSetResetPassword(false)
      } catch (error) {
        if (error.response && error.response.status === 403) {
          throw new SubmissionError({
            email: 'Email не зарегистрирован в системе',
          })
        }
      } finally {
        this.setState({ submitLoading: false })
      }
    }
  }

  render() {
    const { submitLoading, isReset } = this.state
    const { handleSubmit } = this.props

    return (
      <SignInComponent
        onSubmit={handleSubmit(this.handleSubmit)}
        isReset={isReset}
        submitLoading={submitLoading}
        onSetResetPassword={this.handleSetResetPassword}
      />
    )
  }
}

const Form = reduxForm<FormProps>({
  form: FORM_NAME,
})(SignInContainer)

export default Form
