import { Action } from 'redux-actions'

import { Driver, DriverListInfo } from 'common/types/api/models/driver'
import { Sort } from 'common/types/local/sort'
import { PaginationLimit } from 'api/types'

export type SET_INFO = Action<DriverListInfo>
export const SET_INFO: SET_INFO['type'] = 'drivers_list_settings/SET_INFO'
export const setInfo = (info: DriverListInfo): SET_INFO => ({
  type: SET_INFO,
  payload: info,
})

export interface SetDriversListPayload {
  total_pages: number
  items: Driver[]
  cursor: string | null
}
export type SET_DRIVERS_LIST = Action<SetDriversListPayload>
export const SET_DRIVERS_LIST: SET_DRIVERS_LIST['type'] =
  'drivers_list_settings/SET_DRIVERS_LIST'
export const setDriversList = (
  drivers: SetDriversListPayload
): SET_DRIVERS_LIST => ({
  type: SET_DRIVERS_LIST,
  payload: drivers,
})

export type RESET_DRIVERS_LIST_PAGE = Action<null>
export const RESET_DRIVERS_LIST_PAGE: RESET_DRIVERS_LIST_PAGE['type'] =
  'drivers_list_settings/RESET_DRIVERS_LIST_PAGE'
export const resetDriversListPage = (): RESET_DRIVERS_LIST_PAGE => ({
  type: RESET_DRIVERS_LIST_PAGE,
  payload: null,
})

export type SET_DRIVERS_LIST_PREV_PAGE = Action<null>
export const SET_DRIVERS_LIST_PREV_PAGE: SET_DRIVERS_LIST_PREV_PAGE['type'] =
  'drivers_list_settings/SET_DRIVERS_LIST_PREV_PAGE'
export const setDriversListPrevPage = (): SET_DRIVERS_LIST_PREV_PAGE => ({
  type: SET_DRIVERS_LIST_PREV_PAGE,
  payload: null,
})

export type SET_DRIVERS_LIST_NEXT_PAGE = Action<null>
export const SET_DRIVERS_LIST_NEXT_PAGE: SET_DRIVERS_LIST_NEXT_PAGE['type'] =
  'drivers_list_settings/SET_DRIVERS_LIST_NEXT_PAGE'
export const setDriversListNextPage = (): SET_DRIVERS_LIST_NEXT_PAGE => ({
  type: SET_DRIVERS_LIST_NEXT_PAGE,
  payload: null,
})

export type SET_DRIVERS_LIST_LIMIT = Action<PaginationLimit>
export const SET_DRIVERS_LIST_LIMIT: SET_DRIVERS_LIST_LIMIT['type'] =
  'drivers_list_settings/SET_DRIVERS_LIST_LIMIT'
export const setDriversListLimit = (
  limit: PaginationLimit
): SET_DRIVERS_LIST_LIMIT => ({
  type: SET_DRIVERS_LIST_LIMIT,
  payload: limit,
})

export type SET_DRIVERS_LIST_SEARCH = Action<string>
export const SET_DRIVERS_LIST_SEARCH: SET_DRIVERS_LIST_SEARCH['type'] =
  'drivers_list_settings/SET_DRIVERS_LIST_SEARCH'
export const setDriversListSearch = (
  searchValue: string
): SET_DRIVERS_LIST_SEARCH => ({
  type: SET_DRIVERS_LIST_SEARCH,
  payload: searchValue,
})

export type SET_DRIVERS_LIST_FILTER = Action<string[]>
export const SET_DRIVERS_LIST_FILTER: SET_DRIVERS_LIST_FILTER['type'] =
  'drivers_list_settings/SET_DRIVERS_LIST_FILTER'
export const setDriversListFilter = (
  filters: string[]
): SET_DRIVERS_LIST_FILTER => ({
  type: SET_DRIVERS_LIST_FILTER,
  payload: filters,
})

export type SortableColumns = 'name'
export type SORT_DRIVERS = Action<Sort<SortableColumns>>
export const SORT_DRIVERS: SORT_DRIVERS['type'] =
  'drivers_list_settings/SORT_DRIVERS'
export const setDriversSort = (data: Sort<SortableColumns>): SORT_DRIVERS => ({
  type: SORT_DRIVERS,
  payload: data,
})

export type Payloads = Pick<
  SET_INFO &
    SET_DRIVERS_LIST &
    SET_DRIVERS_LIST_NEXT_PAGE &
    SET_DRIVERS_LIST_PREV_PAGE &
    SET_DRIVERS_LIST_SEARCH &
    SET_DRIVERS_LIST_FILTER &
    SORT_DRIVERS,
  'payload'
>['payload']
