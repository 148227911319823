import { State } from 'reducers'

export const selectMessage = (state: State) =>
  state.api.park.postParkRevise.data?.message

export const selectIsSuccess = (state: State) =>
  state.api.park.postParkRevise.data?.success

export const selectIsFetching = (state: State) =>
  state.api.park.postParkRevise.status === 'loading'
