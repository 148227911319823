import { createSelector } from 'reselect'
import { State } from 'reducers'

export const getIsFetching = (state: State) =>
  state.api.park.subscriptionSummary.status === 'loading'

const getData = (state: State) => state.api.park.subscriptionSummary.data

export const getPlan = createSelector(getData, (data) => {
  if (!data) {
    return null
  }
  const {
    current_plan_name: currentPlanName,
    future_plan_name: futurePlanName,
    status,
    next_status: nextStatus,
    payment_date: paymentDate,
  } = data
  return {
    current_plan_name: currentPlanName,
    future_plan_name: futurePlanName,
    status,
    next_status: nextStatus,
    payment_date: paymentDate,
  }
})

export const getParkTurnover = createSelector(getData, (data) =>
  data ? data.park_turnover.amount : 0
)

export const getTariffs = createSelector(getData, (data) =>
  data ? data.tariffs : []
)

const getTariff = createSelector(getData, (data) => (data ? data.tariff : null))

export const getCurrentTariffIndex = createSelector(
  getTariffs,
  getTariff,
  (tariffs, tariff) =>
    tariffs && tariff
      ? tariffs.findIndex(
          (t) =>
            t.money_turnover.amount === tariff.money_turnover.amount &&
            t.price.amount === tariff.price.amount
        )
      : -1
)

export const getCurrentTariff = (state: State) =>
  state.api.park.subscriptionSummary.data?.tariff

export const getIsAutoTopupEnabled = (state: State) =>
  Boolean(state.api.park.subscriptionSummary.data?.auto_topup_deposit)
