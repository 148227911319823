import { Action } from 'redux-actions'

export type RESET_ALL_STATES = Action<null>

export const RESET_ALL_STATES: RESET_ALL_STATES['type'] = 'RESET_ALL_STATES'

export const resetAllStates = (): RESET_ALL_STATES => ({
  type: RESET_ALL_STATES,
  payload: null,
})
