import React from 'react'
import Alert from 'react-s-alert'

import { AlertMessage } from 'shared/ui/alertTemplate/components/styles'

import { BodyText, CaptionText, Icon } from 'ui-kit/components'
import { lightTheme } from 'ui-kit/themes'
import { ThemeProvider } from 'styled-components'

interface Props {
  id: string
  classNames: string
  condition: 'error' | 'info'
  message: string
  customFields: {
    text: string
  }
  styles: React.CSSProperties
}

export const AlertTemplate: React.FC<Props> = ({
  id,
  classNames,
  condition,
  message,
  customFields,
  styles,
}) => {
  const handleClose = (): void => Alert.close(id as any)
  return (
    // FIXME: переопределение шаблона у библиотеки react-s-alert не прокидывает
    //  пропсы styled-components теряется тема у всех дочерних компонентов.
    //  Заменить библиотеку или будут проблемы с переключением на
    //  другие темы (например темную)
    <ThemeProvider theme={lightTheme}>
      <AlertMessage id={id} className={classNames} style={styles}>
        {condition === 'error' && (
          <Icon className="icon" name="warning2" pointer={false} />
        )}
        {condition === 'info' && (
          <Icon className="icon" name="warning2Blue" pointer={false} />
        )}
        <Icon className="close" name="close" onClick={handleClose} />
        <BodyText fontWeight="semi-bold">{message}</BodyText>
        {customFields.text && (
          <CaptionText color="gray8">{customFields.text}</CaptionText>
        )}
      </AlertMessage>
    </ThemeProvider>
  )
}
