import React from 'react'
import { TableText } from 'ui-kit/components'
import { PaymentStatus, paymentStatusToString } from 'common/enums/payments'
import styled from 'styled-components'

export const StatusRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 27px;
`

type Props = {
  status: PaymentStatus
}
export const TransactionStatus = React.memo<Props>(({ status }) => {
  const statusText = React.useMemo(() => {
    return paymentStatusToString(status)
  }, [status])
  return (
    <StatusRow>
      <TableText>{statusText}</TableText>
    </StatusRow>
  )
})

TransactionStatus.displayName = 'TransactionStatus'
