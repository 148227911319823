import React from 'react'

import { SubBodyText } from 'ui-kit/components'
import { PaymentSettingsListItem } from 'settings/features/paymentSettingsTab/atoms'

type SelectionOption = {
  name: string
  count: number
}

type Props = {
  option: SelectionOption
  onClick?: () => void
  isSelect: boolean
}

export const PaymentSettingsSelectionItem = React.memo<Props>(
  ({ option, onClick, isSelect }) => {
    return (
      <PaymentSettingsListItem onClick={onClick} isSelected={isSelect}>
        <SubBodyText>{option.name}</SubBodyText>
        <SubBodyText>{option.count}</SubBodyText>
      </PaymentSettingsListItem>
    )
  }
)

PaymentSettingsSelectionItem.displayName = 'PaymentSettingsSelectionItem'
