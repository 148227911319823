import { createSelector } from 'reselect'
import { State } from 'reducers'

export const getIsFetching = (state: State) =>
  state.api.park.allowAutoTransferGet.status === 'loading'

const getData = (state: State) => state.api.park.allowAutoTransferGet.data

export const getAllowAutoTransfer = createSelector(getData, (data) =>
  data ? data.allow_auto_transfer : false
)
