import { handleActions } from 'redux-actions'

import { TApiStatus } from 'api/types'

import {
  FAILURE,
  RESET,
  RESET_STATUS,
  START,
  SUCCESS,
  TFailure,
  TPayloads,
} from 'api/park/transactions/paymentOrder/actions'
import { HANDLE_REQUEST } from 'api/park/transactions/paymentOrder/thunks'

export interface PaymentTransactionsPaymentOrderState {
  status: TApiStatus
  error: any
}

const initialState: PaymentTransactionsPaymentOrderState = {
  status: null,
  error: null,
}

const handleStart = (
  state: PaymentTransactionsPaymentOrderState
): PaymentTransactionsPaymentOrderState => ({
  ...state,
  status: 'loading',
})

const handleSuccess = (
  state: PaymentTransactionsPaymentOrderState
): PaymentTransactionsPaymentOrderState => ({
  ...state,
  status: 'success',
})

const handleFailure = (
  state: PaymentTransactionsPaymentOrderState,
  action: TFailure
): PaymentTransactionsPaymentOrderState => ({
  ...state,
  status: 'failure',
  error: action.payload,
})

const handleReset = (): PaymentTransactionsPaymentOrderState => initialState

const handleResetStatus = (
  state: PaymentTransactionsPaymentOrderState
): PaymentTransactionsPaymentOrderState => ({
  ...state,
  status: null,
})

const handleRequest = (
  state: PaymentTransactionsPaymentOrderState
): PaymentTransactionsPaymentOrderState => ({
  ...state,
})

export const reducer = handleActions<
  PaymentTransactionsPaymentOrderState,
  TPayloads
>(
  {
    [START]: handleStart,
    [SUCCESS]: handleSuccess,
    [FAILURE]: handleFailure,
    [RESET]: handleReset,
    [RESET_STATUS]: handleResetStatus,
    [HANDLE_REQUEST]: handleRequest,
  },
  initialState
)
