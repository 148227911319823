import React, { useCallback } from 'react'

import {
  EuiPageBody,
  EuiPageContentBody,
  EuiPageContentHeader,
  EuiPageContentHeaderSection,
  EuiPageSideBar,
  EuiTitle,
  EuiPanel,
  EuiStat,
  EuiButton,
  EuiSpacer,
  EuiText,
  EuiLink,
  EuiShowFor,
  EuiLoadingContent,
} from '@elastic/eui'
import PageTemplate from 'elastic-ui/templates/PageTemplate/PageTemplate'
import styled from 'styled-components'
import { fixDecimalPrecisionErrors } from 'elastic-ui/utils'
import { Table } from '../../organisms/Table/Table'
import { FuelTransaction, TableChangeEvent } from '../../types'
import numberToCurrencyString from '../../../../common/utils/numberToCurrencyString'
import { DepositModal } from '../../organisms/DepositModal/DepositModal'
import { BonusSettingsModal } from '../../organisms/BonusSettingsModal/BonusSettingsModal'
import { EnhancedPanel } from '../../../shared/atoms/EnhancedPanel/EnhancedPanel'

const PageSideBar = styled(EuiPageSideBar)`
  min-width: 330px;
`

const HistoryTitle = styled(EuiTitle).attrs({ size: 's' })`
  @media (max-width: 767px) {
    padding-left: 8px;
  }
`

export interface FuelPageProps {
  rowsPerPageOptions: number[]
  defaultRowsPerPage: number
  fuelDepositAmount: number
  bonusAmount: number
  operationHistoryOnPage: FuelTransaction[]
  totalTableItems: number
  isOperationHistoryLoading: boolean
  isBonusSettingsLoading: boolean
  isBonusSettingsModalOpen: boolean
  isDepositModalOpen: boolean
  cashbackPercent: number
  maxCashbackPercent: number
  onDepositClick: () => void
  onChangeBonusSettingsClick: () => void
  onTableChange: (event: TableChangeEvent) => void
  onDownloadDepositPayment: (amount: number) => void
  onCashbackSettingsSave: (cashbackPercent: number) => void
  onBonusSettingsModalClose: () => void
  onDepositModalClose: () => void
}

export const FuelPage: React.FC<FuelPageProps> = ({
  rowsPerPageOptions,
  defaultRowsPerPage,
  fuelDepositAmount,
  bonusAmount,
  operationHistoryOnPage,
  totalTableItems,
  onChangeBonusSettingsClick,
  isBonusSettingsModalOpen,
  onDepositClick,
  isDepositModalOpen,
  isOperationHistoryLoading,
  isBonusSettingsLoading,
  cashbackPercent,
  maxCashbackPercent,
  onTableChange,
  onDownloadDepositPayment,
  onCashbackSettingsSave,
  onBonusSettingsModalClose,
  onDepositModalClose,
}) => {
  const prettifiedDepositAmount = numberToCurrencyString(fuelDepositAmount, {
    withCurrencySign: true,
  })

  const prettifiedBonusAmount = numberToCurrencyString(bonusAmount, {
    withCurrencySign: true,
  })

  const getBonusTextByPartiesAmount = useCallback(() => {
    const driverPercent = fixDecimalPrecisionErrors(
      maxCashbackPercent - cashbackPercent,
      { fixedNumbersAfterDecimalPoint: 2 }
    )

    if (driverPercent === 0) return `${cashbackPercent}% от суммы вам`
    if (cashbackPercent === 0) return `${driverPercent}% от суммы водителю`
    return `${cashbackPercent}% от суммы вам, ${driverPercent}% водителю`
  }, [cashbackPercent, maxCashbackPercent])

  return (
    <PageTemplate>
      <PageSideBar>
        <EuiPanel>
          <EuiStat
            title={prettifiedDepositAmount}
            description="Топливный депозит"
            titleColor={fuelDepositAmount <= 0 ? 'danger' : ''}
          >
            <EuiSpacer size="s" />
            <EuiButton
              fill
              color="primary"
              iconType="plusInCircle"
              onClick={onDepositClick}
            >
              Пополнить
            </EuiButton>
          </EuiStat>
        </EuiPanel>
        <EuiSpacer size="m" />
        <EuiPanel>
          <EuiStat
            title={prettifiedBonusAmount}
            description="Бонусный счёт">
            <EuiSpacer size="s" />
          </EuiStat>
        </EuiPanel>  
      </PageSideBar>
      <EuiPageBody>
        <EuiPageContentHeader>
          <EuiPageContentHeaderSection>
            <EuiShowFor sizes={['xs', 's']}>
              <EuiSpacer size="l" />
            </EuiShowFor>
            <HistoryTitle>
              <h2>История операций</h2>
            </HistoryTitle>
            <EuiShowFor sizes={['xs', 's']}>
              <EuiSpacer size="s" />
            </EuiShowFor>
          </EuiPageContentHeaderSection>
        </EuiPageContentHeader>
        <EuiPageContentBody>
          <Table
            itemsOnPage={operationHistoryOnPage}
            rowsPerPageOptions={rowsPerPageOptions}
            defaultRowsPerPagePage={defaultRowsPerPage}
            onChange={onTableChange}
            totalItems={totalTableItems}
            isLoading={isOperationHistoryLoading}
            isRowsPerPageOptionsHidden
          />
        </EuiPageContentBody>
      </EuiPageBody>

      <DepositModal
        isOpen={isDepositModalOpen}
        onClose={onDepositModalClose}
        onAccept={onDownloadDepositPayment}
      />
      <BonusSettingsModal
        initialCashbackPercent={cashbackPercent}
        maxCashbackPercent={maxCashbackPercent}
        isOpen={isBonusSettingsModalOpen}
        onClose={onBonusSettingsModalClose}
        onAccept={onCashbackSettingsSave}
      />
    </PageTemplate>
  )
}
