import Alert from 'react-s-alert'
import { apiActions } from 'api'
import { MyThunkDispatch, MyThunkResult } from 'api/reducer'
import { ApiCode } from 'common/types/api/codes'
import { ModalsNames } from 'common/enums'
import { modalsActions } from 'shared/modals'

import { FormProps } from './containers/AddTinkoffModalContainer'

export const onSaveParkTinkoff: ({
  values,
}: {
  values: FormProps
}) => MyThunkResult<void> = ({ values }) => (dispatch: MyThunkDispatch) => {
  return dispatch(apiActions.park.patchTinkoff.handleRequest(values)).then(
    async () => {
      await dispatch(apiActions.park.getTinkoff.handleRequest())
      dispatch(modalsActions.closeModal(ModalsNames.AddTinkoff))
      Alert.info('Тинькофф подключен')
    },
    (reason) => {
      if (
        reason.response?.data.code === ApiCode.InputValidationError &&
        reason.response?.data.payload
      ) {
        throw reason.response.data.payload
      }
    }
  )
}
