import React from 'react'
import { Field } from 'redux-form'

import {
  moreThan,
  moreThanZero,
  noMoreThan,
  required,
} from 'common/utils/fieldValidation'
import InputMask, {
  maskNumberWithSuffix,
} from 'common/components/formItems/InputMask'

import { HBox } from 'shared/ui/index'
import styled from 'styled-components'

const Box = styled.div`
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 3px 10px rgba(192, 206, 226, 0.5),
    0 0 4px rgba(192, 206, 226, 0.2);
`

const Wrapper = styled.div<{ overlay?: boolean }>`
  position: relative;

  ${({ overlay }) =>
    overlay &&
    `
      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 100;
        background-color: rgba(255, 255, 255, .7);
      }
  `};

  ${Box}:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`

const Group = styled.div`
  padding: 8px 16px;
`

type Props = {
  isLocked?: boolean
  canChangePaymentsConditions?: boolean
  onChange?: () => void
}

export const PaymentSettingsFuelFields: React.FC<Props> = ({
  isLocked,
  canChangePaymentsConditions,
  onChange,
}) => {
  const moreThanNine = React.useCallback(
    (value: string | number) => moreThan(value, 9),
    []
  )

  return (
    <Wrapper overlay={isLocked}>
      <Box>
        <Group>
          <Field
            name="fuel_card_min_topup_amount"
            label="Минимальная сумма одного пополнения, ₽"
            mask={maskNumberWithSuffix(5, '', 2)}
            iconName="close"
            validate={[required, moreThanNine]}
            component={InputMask}
            disabled={!canChangePaymentsConditions}
            onChange={onChange}
          />
          <HBox x={0.5} />
          <Field
            name="fuel_card_max_topup_amount"
            label="Максимальная, ₽"
            mask={maskNumberWithSuffix(5, '', 2)}
            iconName="close"
            component={InputMask}
            disabled={!canChangePaymentsConditions}
            onChange={onChange}
          />
          <HBox x={0.5} />
        </Group>
      </Box>
    </Wrapper>
  )
}
