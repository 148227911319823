import React from 'react'
import { EuiIcon, EuiIconProps } from '@elastic/eui'
import AlfaBankIcon from 'ui-kit/static/icons/alfa.svg'
import CompanyIcon from '../icons/company.svg'
import MagicIcon from '../icons/magic.svg'
import KeyIcon from '../icons/key.svg'
import WalletIcon from '../icons/wallet.svg'
import PhoneIcon from '../icons/phone.svg'
import ReceiptIcon from '../icons/receipt.svg'

const customIcons = {
  company: CompanyIcon,
  magic: MagicIcon,
  key: KeyIcon,
  wallet: WalletIcon,
  phone: PhoneIcon,
  receipt: ReceiptIcon,
  alfaBank: AlfaBankIcon,
}

const getCustomIcon = (
  iconNameOrReactComponent: string | React.ComponentType
) => {
  if (
    typeof iconNameOrReactComponent === 'object' ||
    typeof iconNameOrReactComponent === 'function'
  ) {
    return iconNameOrReactComponent
  }

  return (
    customIcons[iconNameOrReactComponent as keyof typeof customIcons] ||
    iconNameOrReactComponent
  )
}

export const EuiIconEnhanced: React.FC<EuiIconProps> = ({ type, ...rest }) => (
  <EuiIcon type={getCustomIcon(type)} {...rest} />
)
