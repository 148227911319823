import { combineReducers } from 'redux'

import {
  GetCloudCashBoxActions,
  GetCloudCashBoxState,
} from 'api/receipt/cloud-cashbox/get/types'
import { getCloudCashBoxReducer } from 'api/receipt/cloud-cashbox/get/reducer'
import { getCloudCashBoxRequest } from 'api/receipt/cloud-cashbox/get/thunks'
import * as getCloudCashBoxSelectors from 'api/receipt/cloud-cashbox/get/selectors'

import {
  PostCloudCashBoxActions,
  PostCloudCashBoxState,
} from 'api/receipt/cloud-cashbox/post/types'
import { postCloudCashBoxReducer } from 'api/receipt/cloud-cashbox/post/reducer'
import { postCloudCashBoxRequest } from 'api/receipt/cloud-cashbox/post/thunks'
import * as postCloudCashBoxSelectors from 'api/receipt/cloud-cashbox/post/selectors'

import {
  PatchCloudCashBoxActions,
  PatchCloudCashBoxState,
} from 'api/receipt/cloud-cashbox/patch/types'
import { patchCloudCashBoxReducer } from 'api/receipt/cloud-cashbox/patch/reducer'
import { patchCloudCashBoxRequest } from 'api/receipt/cloud-cashbox/patch/thunks'
import * as patchCloudCashBoxSelectors from 'api/receipt/cloud-cashbox/patch/selectors'

import {
  PatchBlockCloudCashBoxActions,
  PatchBlockCloudCashBoxState,
} from 'api/receipt/cloud-cashbox/block/patch/types'
import { patchBlockCloudCashBoxReducer } from 'api/receipt/cloud-cashbox/block/patch/reducer'
import { patchBlockCloudCashBoxRequest } from 'api/receipt/cloud-cashbox/block/patch/thunks'
import * as patchBlockCloudCashBoxSelectors from 'api/receipt/cloud-cashbox/block/patch/selectors'

import { GetSnoActions, GetSnoState } from 'api/receipt/sno/get/types'
import { getSnoReducer } from 'api/receipt/sno/get/reducer'
import { getSnoRequest } from 'api/receipt/sno/get/thunks'
import * as getSnoSelectors from 'api/receipt/sno/get/selectors'

export interface ReceiptState {
  getCloudCashBox: GetCloudCashBoxState
  postCloudCashBox: PostCloudCashBoxState
  patchCloudCashBox: PatchCloudCashBoxState
  patchBlockCloudCashBox: PatchBlockCloudCashBoxState
  getSno: GetSnoState
}

export type ReceiptAction =
  | GetCloudCashBoxActions
  | PostCloudCashBoxActions
  | PatchCloudCashBoxActions
  | PatchBlockCloudCashBoxActions
  | GetSnoActions

export const receiptReducer = combineReducers({
  getCloudCashBox: getCloudCashBoxReducer,
  postCloudCashBox: postCloudCashBoxReducer,
  patchCloudCashBox: patchCloudCashBoxReducer,
  patchBlockCloudCashBox: patchBlockCloudCashBoxReducer,
  getSno: getSnoReducer,
})

export const receiptActions = {
  getCloudCashBox: {
    handleRequest: getCloudCashBoxRequest,
  },
  postCloudCashBox: {
    handleRequest: postCloudCashBoxRequest,
  },
  patchCloudCashBox: {
    handleRequest: patchCloudCashBoxRequest,
  },
  patchBlockCloudCashBox: {
    handleRequest: patchBlockCloudCashBoxRequest,
  },
  getSno: {
    handleRequest: getSnoRequest,
  },
}

export const receiptSelectors = {
  getCloudCashBox: getCloudCashBoxSelectors,
  postCloudCashBox: postCloudCashBoxSelectors,
  patchCloudCashBox: patchCloudCashBoxSelectors,
  patchBlockCloudCashBox: patchBlockCloudCashBoxSelectors,
  getSno: getSnoSelectors,
}
