import React from 'react'

import { RejectResolveButtons } from 'ui-kit/components'
import { Modal } from 'shared/ui/modal'
import styled from 'styled-components'

const Actions = styled.div`
  padding: 16px;
`

interface Props {
  onReject: () => void
  onResolve: () => void
}

export const ResetIndividualSettingsModal = React.memo<Props>(
  ({ onReject, onResolve }) => {
    return (
      <Modal
        open
        closeButton
        onClose={onReject}
        overflow="initial"
        title="Сбросить индивидуальные?"
        withMinHeight={false}
      >
        <Actions>
          <RejectResolveButtons
            className="actions"
            resolveText="Сбросить"
            rejectText="Отмена"
            onResolve={onResolve}
            onReject={onReject}
            fullWidth
          />
        </Actions>
      </Modal>
    )
  }
)

ResetIndividualSettingsModal.displayName = 'ResetIndividualSettingsModal'
