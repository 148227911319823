import React from 'react'

import { Loader } from 'shared/ui/loader'
import { PaymentSettingsEditionConnector } from 'settings/features/paymentSettingsEdition/containers'
import { PurePaymentSettingsEdition } from 'settings/features/paymentSettingsEdition/organisms'
import { BankType } from 'common/enums'

type Props = {
  bankType: BankType | null
  settingsId: string
  onBack: () => void
}

export const PaymentSettingsEdition = React.memo<Props>(
  ({ bankType, settingsId, onBack }) => (
    <PaymentSettingsEditionConnector settingsId={settingsId}>
      {(
        data,
        editSettings,
        allowNonResident,
        canChangePaymentsConditions,
        isBankTypeManual,
        isFuelEnabled
      ) => {
        if (!data) return <Loader />

        return (
          <PurePaymentSettingsEdition
            bankType={bankType}
            settings={data}
            isFuelEnabled={isFuelEnabled}
            onEdit={editSettings}
            onBack={onBack}
            allowNonResident={allowNonResident || undefined}
            canChangePaymentsConditions={canChangePaymentsConditions}
            isBankTypeManual={isBankTypeManual}
          />
        )
      }}
    </PaymentSettingsEditionConnector>
  )
)

PaymentSettingsEdition.displayName = 'PaymentSettingsEdition'
