import axios, { AxiosResponse } from 'axios'
import { BaseAction } from 'redux-actions'
import { ThunkAction } from 'redux-thunk'

import { State } from 'reducers'
import { ContractsResponse } from 'api/types'
import { failure, start, success } from 'api/park/contracts/actions'

export type THandleRequest = ThunkAction<
  Promise<number>,
  State,
  undefined,
  BaseAction
>
export const HANDLE_REQUEST: BaseAction['type'] =
  'api/park/contracts->HANDLE_REQUEST'

export const handleRequest = (): THandleRequest => async (dispatch) => {
  dispatch(start())
  try {
    const response: AxiosResponse<ContractsResponse> = await axios.get(
      'erp/1/park/contracts'
    )
    dispatch(success())
    return response.data.edgvrs.length
  } catch (e) {
    if (e.response) {
      dispatch(failure(e.response.data))
    }
    throw e
  }
}
