import React from 'react'

import { CaptionText, Icon, SubBodyText } from 'ui-kit/components'
import { AggregatorType, aggregatorTypeToString } from 'common/enums'
import { formatPhone } from 'common/utils/formatters'
import { Col, ContentCard, ContentCardBody, HBox, Row, VBox } from 'shared/ui'
import styled from 'styled-components'
import { IconNames } from 'ui-kit'

interface Props {
  phone: string
  aggregator_type: AggregatorType
  aggregator_account: string
}

const MaxWidthCol = styled(Col)`
  max-width: calc(100% - 44px);
`

const EllipsisSubBodyText = styled(SubBodyText)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

type AggregatorTypeIconNames = {
  [key in AggregatorType]: IconNames
}

const iconNames: AggregatorTypeIconNames = {
  [AggregatorType.Yandex]: 'yandexLarge',
  [AggregatorType.Gett]: 'yandexLarge',
  [AggregatorType.Citymobil]: 'citymobilLarge',
  [AggregatorType.DiDi]: 'DiDi',
}

export const DriverContacts: React.FC<Props> = ({
  phone,
  aggregator_type,
  aggregator_account,
}) => {
  return (
    <ContentCard>
      <ContentCardBody height={126}>
        <Row>
          <Icon name="phone" color="green" backgroundColor="greenMuted" />
          <VBox x={0.5} />
          <SubBodyText fontWeight="medium">{formatPhone(phone)}</SubBodyText>
        </Row>
        <HBox x={0.75} />

        <Row>
          <Icon name={iconNames[aggregator_type]} />
          <Col>
            <VBox x={0.5} />
          </Col>
          <MaxWidthCol>
            <CaptionText color="darkGray">
              {aggregatorTypeToString(aggregator_type)}
            </CaptionText>
            <EllipsisSubBodyText>{aggregator_account}</EllipsisSubBodyText>
          </MaxWidthCol>
        </Row>
      </ContentCardBody>
    </ContentCard>
  )
}
