import styled from 'styled-components'

const BadgeWrapper = styled.div`
  display: flex;
  width: 100%;
  & span {
    flex: 1;
    text-align: center;
  }
`

export default BadgeWrapper
