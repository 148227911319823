import React from 'react'

import { Loader } from 'shared/ui/loader'
import styled, { css } from 'styled-components'

const Container = styled.div<{
  noPadding?: boolean
  height?: number
  isLoading?: boolean
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: ${({ theme, noPadding }) =>
    !noPadding && `${theme.paddings.threeQuarters}px`};
  height: ${({ height }) => height && `${height}px`};
  min-height: ${({ isLoading }) => isLoading && '120px'};
  justify-content: flex-start;
`

const activeStyle = css`
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.pallete.white};
    opacity: 0.4;
    z-index: 10;
  }
`

const Overlay = styled.div<{ active?: boolean }>`
  position: relative;
  width: 100%;
  ${({ active }) => active && activeStyle}
`

type Props = {
  noPadding?: boolean
  height?: number
  isLoading?: boolean
  withOverlay?: boolean
}

export const ContentCardBody: React.FC<Props> = ({
  children,
  noPadding,
  height,
  isLoading,
  withOverlay,
}) => (
  <Container noPadding={noPadding} height={height} isLoading={isLoading}>
    {withOverlay ? (
      <Overlay active={isLoading}>
        {children}
        {isLoading && <Loader absolute />}
      </Overlay>
    ) : (
      <>{isLoading ? <Loader absolute /> : children}</>
    )}
  </Container>
)
