import { connect } from 'react-redux'

import { State } from 'reducers'
import { apiSelectors } from 'api'
import { MyThunkDispatch } from 'api/reducer'
import { modalsActions, modalsSelectors } from 'shared/modals'
import { EmployeeInfo } from 'employees/features/employeeInfo/organisms'
import { ModalsNames } from 'common/enums'

const mapStateToProps = (state: State) => ({
  employee: apiSelectors.employees.employeesByIdGet.getInfo(state),
  isBlocked: apiSelectors.employees.employeesByIdGet.getIsBlocked(state),
  canChangeEmployee: apiSelectors.employee.profile.getCanChangeEmployee(state),
  isAddEmployeeModalOpened: modalsSelectors.getIsModalOpened(
    state,
    ModalsNames.AddEmployee
  ),
  isBlockEmployeeModalOpened: modalsSelectors.getIsModalOpened(
    state,
    ModalsNames.BlockEmployee
  ),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  openAddEmployeeModal: () =>
    dispatch(modalsActions.openModal(ModalsNames.AddEmployee)),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const EmployeeInfoContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeInfo)
