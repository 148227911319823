import React, { forwardRef } from 'react'

import { Icon, OptionsButton } from 'ui-kit/components'
import { IconNames } from 'ui-kit'
import styled, { css } from 'styled-components'

const Container = styled.div`
  position: relative;
`
const Toggler = styled.div<{ active: boolean }>`
  width: 24px;
  height: 24px;
  background-color: ${({ theme, active }) =>
    active ? theme.pallete.gray2 : 'transparent'};
  border-radius: 4px;
  cursor: pointer;
`

const cssPosition = {
  left: css`
    right: 0;
  `,
  right: css`
    left: 100%;
  `,
}

const OptionsCard = styled.div<{ position: ToggleOptionPosition }>`
  position: absolute;
  ${({ position }) => cssPosition[position]};

  display: flex;
  flex-direction: column;
  margin-top: 4px;
  background-color: ${({ theme }) => theme.pallete.white};
  box-shadow: 0 2px 5px rgba(192, 206, 226, 0.4),
    0px -2px 3px rgba(192, 206, 226, 0.25);
  border-radius: 10px;
  z-index: 99;
`

const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.pallete.secondary};
`
type ToggleOptionPosition = 'left' | 'right'

interface Props {
  position?: ToggleOptionPosition
  toggleIcon?: IconNames
  options: {
    text?: string
    icon?: string
    critical?: boolean
    action: () => void
  }[]
  active: boolean
  onClick: () => void
}

export const ToggleOptions = forwardRef<HTMLDivElement, Props>(
  (
    { options, toggleIcon = 'options', active, onClick, position = 'left' },
    ref
  ) => (
    <Container ref={ref}>
      <Toggler active={active} onClick={onClick}>
        <Icon name={toggleIcon} color="blue" />
      </Toggler>
      {active ? (
        <OptionsCard position={position as ToggleOptionPosition}>
          {options.map((option, index, array) => (
            <React.Fragment key={`${option.icon}-${option.text}`}>
              <OptionsButton
                iconName={option.icon as IconNames}
                critical={option.critical}
                first={index === 0}
                last={index === array.length - 1}
                onClick={option.action}
              >
                {option.text}
              </OptionsButton>
              {index !== array.length - 1 ? <Divider /> : null}
            </React.Fragment>
          ))}
        </OptionsCard>
      ) : null}
    </Container>
  )
)

ToggleOptions.displayName = 'ToggleOptions'
