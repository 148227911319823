import { createApiReducer } from 'common/utils/reduxApiFactory'
import { transactionsCountersActions } from 'api/park/transactions/counters/actions'
import { TransactionsCountersState } from 'api/park/transactions/counters/types'

export const initialState: TransactionsCountersState = {
  status: null,
  data: null,
}

export const transactionsCountersReducer = createApiReducer(
  transactionsCountersActions,
  initialState
)
