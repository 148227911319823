import { Action } from 'redux-actions'

import { Referral } from 'common/types/api/models/referral'

interface SetReferralsPayload {
  total: number
  items: Referral[]
}
export type SET_REFERRALS = Action<SetReferralsPayload>
export const SET_REFERRALS: SET_REFERRALS['type'] =
  'driver_details/SET_REFERRALS'
export const setReferrals = (payments: SetReferralsPayload): SET_REFERRALS => ({
  type: SET_REFERRALS,
  payload: payments,
})

export type SET_REFERRALS_PAGE = Action<number>
export const SET_REFERRALS_PAGE: SET_REFERRALS_PAGE['type'] =
  'driver_details/SET_REFERRALS_PAGE'
export const setReferralsPage = (page: number): SET_REFERRALS_PAGE => ({
  type: SET_REFERRALS_PAGE,
  payload: page,
})

export type SET_REFERRALS_SEARCH = Action<string>
export const SET_REFERRALS_SEARCH: SET_REFERRALS_SEARCH['type'] =
  'driver_details/SET_REFERRALS_SEARCH'
export const setReferralsSearch = (
  searchValue: string
): SET_REFERRALS_SEARCH => ({
  type: SET_REFERRALS_SEARCH,
  payload: searchValue,
})

export type Payloads = Pick<
  SET_REFERRALS & SET_REFERRALS_SEARCH & SET_REFERRALS_PAGE,
  'payload'
>['payload']
