import { ReferralOffer } from 'api/referral/offer/types'
import { InviterBonusTypes } from 'elastic-ui/referral-program/organisms/TermsEdit/types'

type InviterRewardType = ReferralOffer['inviter_reward_type']

const map: Record<InviterBonusTypes, InviterRewardType> = {
  [InviterBonusTypes.FixedBonus]: 'amount',
  [InviterBonusTypes.MoneyForDriving]: 'amount_per_ride',
  [InviterBonusTypes.ParkCommisionShare]: 'commission_percent',
}

export default (bonusType: InviterBonusTypes): InviterRewardType =>
  map[bonusType]
