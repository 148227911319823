import React from 'react'

import { Modal } from 'shared/ui/modal'
import { BodyText } from 'ui-kit/components'

import { SettingsNameForm } from 'shared/ui/paymentSettingsNameModal/molecules'
import { HBox } from 'shared/ui/spacers'
import styled from 'styled-components'

interface Props {
  name?: string
  isEdition?: boolean
  onResolve: (name: string) => void
  onReject: () => void
}

const Wrapper = styled.div`
  padding: 16px;
  max-width: 528px;
`

export const NameSettingsModal = React.memo<Props>(
  ({ onReject, onResolve, isEdition, name }) => {
    const handleResolve = ({ name: newName }: { name: string }) => {
      onResolve(newName)
    }

    return (
      <Modal
        open
        closeButton
        onClose={onReject}
        overflow="initial"
        type="group"
        title={isEdition ? 'Настройка группы' : 'Новая настройка группы'}
      >
        <Wrapper>
          {!isEdition && (
            <>
              <BodyText color="gray9">
                Сохраните настройку и применяйте её для множества водителей.
                Отредактировать условия можно будет разом для всех в разделе
                «Настройки».
              </BodyText>
              <HBox x={0.5} />
            </>
          )}
          <SettingsNameForm
            initialValues={{ name }}
            isEdition={isEdition}
            onSubmit={handleResolve}
            onCancel={onReject}
          />
        </Wrapper>
      </Modal>
    )
  }
)

NameSettingsModal.displayName = 'NameSettingsModal'
