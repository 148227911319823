import React, { useCallback } from 'react'

import styled, { css } from 'styled-components'

import { CaptionText } from 'ui-kit/components'

const Wrapper = styled.div<{ withLockOverlay?: boolean }>`
  height: 56px;
  display: flex;
  align-items: center;
  user-select: none;
  ${({ withLockOverlay }) =>
    withLockOverlay &&
    css`
      opacity: 0.4;
    `}
`

const Button = styled.button<{ active?: boolean }>`
  display: inline-flex;
  margin-left: 10px;
  padding: 0 ${({ active }) => (active ? '6px' : '0')};
  height: 24px;
  align-items: center;
  color: ${({ active, theme }) => active && theme.pallete.white};
  background-color: ${({ active, theme }) =>
    active ? theme.pallete.blue : 'transparent'};
  border-radius: 2px;
  border: none;
  outline: none;
  cursor: ${({ active }) => (active ? 'default' : 'pointer')};
`

export type PaginationLimit = 20 | 50

interface Props {
  isLocked?: boolean
  currentLimit: PaginationLimit
  limitAction: (limit: PaginationLimit) => void
  values?: PaginationLimit[]
}

export const Limitation = React.memo<Props>(
  ({
    currentLimit,
    limitAction,
    isLocked,
    values = [20, 50] as PaginationLimit[],
  }) => {
    const makeHandleClick = useCallback(
      (value: PaginationLimit) => () => {
        limitAction(value)
      },
      [limitAction]
    )

    return (
      <Wrapper withLockOverlay={isLocked}>
        <CaptionText color="gray8" fontWeight="medium">
          Показывать по
          {values.map((value) => (
            <Button
              key={value}
              disabled={isLocked}
              active={currentLimit === value}
              onClick={
                currentLimit === value ? undefined : makeHandleClick(value)
              }
            >
              {value}
            </Button>
          ))}
        </CaptionText>
      </Wrapper>
    )
  }
)

Limitation.displayName = 'Limitation'
