import React, { useEffect } from 'react'
import { AggregatorsListContainer } from 'settings/features/aggregatorsList'
import { Col, Loader } from 'shared/ui'
import { ContainerProps } from 'settings/features/aggregatorsTab/containers/AggregatorsTabContainer'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.paddings.main}px;
`

export const AggregatorsTab: React.FC<ContainerProps> = ({
  isFetching,
  fetchAggregators,
}) => {
  useEffect(() => {
    fetchAggregators()
  }, [fetchAggregators])

  return isFetching ? (
    <Loader />
  ) : (
    <Wrapper>
      <Col percentage={60}>
        <AggregatorsListContainer />
      </Col>
    </Wrapper>
  )
}
