import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'
import { ContainerProps } from 'payments/features/notCompletedList/containers/NotCompletedListContainer'

import { ContentCard } from 'shared/ui'

import {
  Header,
  Pagination,
  Table,
} from 'payments/features/notCompletedList/organisms/index'
import { InfoText, Placeholder } from 'payments/features/notCompletedList/atoms'
import { PaymentDetailsModalContainer } from 'payments/features/paymentDetailsModal'
import { PaginationLimit, PaymentTransaction } from 'api/types'
import {
  initialState,
  reducer,
} from 'payments/features/notCompletedList/reducer'
import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: ${({ theme }) => theme.paddings.main}px;
`

export const NotCompletedList: React.FC<ContainerProps> = ({
  isTransactionFetching,
  transactions,
  totalAmount,
  fetchTransactions,
  canViewTransactionDetails,
  isPaymentDetailsModalOpened,
  openPaymentDetailsModal,
  modalData,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [transaction, setTransaction] = useState<PaymentTransaction>()

  const totalPages = useMemo(
    () => totalAmount && Math.ceil(totalAmount / state.limit),
    [totalAmount, state.limit]
  )

  const isPlaceholderVisible = !isTransactionFetching && !totalPages
  const isPaginationVisible = totalPages > 0

  const handleFetch = useCallback(
    () =>
      fetchTransactions({
        params: {
          limit: state.limit,
          offset: state.limit * (state.page - 1),
          search: state.search,
        },
      }),
    [fetchTransactions, state.limit, state.page, state.search]
  )

  useEffect(() => {
    const handleFetchWithModal = async () => {
      if (modalData) {
        setTransaction(modalData)
        openPaymentDetailsModal()
      } else {
        await handleFetch()
      }
    }
    handleFetchWithModal()
  }, [handleFetch, openPaymentDetailsModal, modalData])

  const handleChangePage = useCallback(
    (pageOffset: number) => {
      dispatch({ type: 'changePage', pageOffset })
    },
    [dispatch]
  )

  const handleChangeLimit = useCallback(
    (limit: PaginationLimit) => {
      dispatch({ type: 'changeLimit', limit })
    },
    [dispatch]
  )

  const handleChangeSearch = useCallback(
    (search: string) => {
      dispatch({ type: 'changeSearch', search })
    },
    [dispatch]
  )

  const handleRowClick = useCallback(
    (value: PaymentTransaction) => {
      setTransaction(value)
      openPaymentDetailsModal()
    },
    [openPaymentDetailsModal]
  )

  return (
    <>
      <Wrapper>
        <ContentCard>
          <Header onChangeSearch={handleChangeSearch} />
          {isPaginationVisible && <InfoText />}
          <Table
            data={transactions}
            isFetching={isTransactionFetching}
            canViewTransactionDetails={canViewTransactionDetails}
            onRowClick={handleRowClick}
          />
          {isPaginationVisible && (
            <Pagination
              currentLimit={state.limit}
              currentPage={state.page}
              total={totalPages}
              onChangePage={handleChangePage}
              onChangeLimit={handleChangeLimit}
            />
          )}
          {isPlaceholderVisible && <Placeholder />}
        </ContentCard>
      </Wrapper>
      {isPaymentDetailsModalOpened && transaction ? (
        <PaymentDetailsModalContainer
          transaction={transaction}
          callbackFetch={handleFetch}
        />
      ) : null}
    </>
  )
}
