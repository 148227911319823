import React, { useCallback, useState } from 'react'
import Alert from 'react-s-alert'

import { PaymentSettings } from 'api/types'

import { PaymentSettingsEditor } from 'shared/ui/paymentSettingsEditor'
import { NameSettingsModal } from 'shared/ui/paymentSettingsNameModal'
import { HBox, VBox } from 'shared/ui/spacers'
import { Col, Row } from 'shared/ui/flex'
import { LinkButton } from 'ui-kit/components'
import { PaymentSettingsName } from 'settings/features/paymentSettingsEdition/molecules'
import styled from 'styled-components'
import { BankType } from 'common/enums'

const Wrapper = styled.div`
  padding: ${({ theme }) => theme.paddings.main}px;
`

type Props = {
  bankType: BankType | null
  settings: PaymentSettings
  onBack: () => void
  onEdit: (updates: Partial<PaymentSettings>) => Promise<void>
  allowNonResident?: boolean
  canChangePaymentsConditions?: boolean
  isBankTypeManual?: boolean
  isFuelEnabled?: boolean
}

export const PurePaymentSettingsEdition = React.memo<Props>(
  ({
    bankType,
    settings,
    onEdit,
    onBack,
    allowNonResident,
    isFuelEnabled,
    canChangePaymentsConditions,
    isBankTypeManual,
  }) => {
    const [isChangeNameModalOpen, setIsChangeNameModalOpen] = useState(false)
    const [isUpdatingName, setIsUpdatingName] = useState(false)

    const handleClickName = useCallback(() => {
      setIsChangeNameModalOpen(true)
    }, [])

    const handleResolve = useCallback(
      async (settingsName: string) => {
        setIsChangeNameModalOpen(false)
        setIsUpdatingName(true)
        try {
          await onEdit({ id: settings.id, name: settingsName })
          Alert.info('Изменения сохранены')
        } catch (e) {
          Alert.error('Ошибка', {
            customFields: {
              text: 'Не удалось сохранить',
            },
          })
        }
        setIsUpdatingName(false)
      },
      [onEdit, settings.id]
    )

    const handleReject = useCallback(() => {
      setIsChangeNameModalOpen(false)
    }, [])

    const handleSubmit = useCallback(
      async (newSettings: Partial<PaymentSettings>) => {
        await onEdit(newSettings)
      },
      [onEdit]
    )

    return (
      <>
        <Wrapper>
          <Row>
            <Col percentage={50}>
              <LinkButton
                iconName="chevronLeft"
                iconColor="blue"
                onClick={onBack}
              >
                Назад
              </LinkButton>
            </Col>
            <VBox />
            <Col percentage={50} />
          </Row>
          <HBox />
          <Row>
            <Col percentage={50}>
              <PaymentSettingsName
                isLoading={isUpdatingName}
                name={settings.name}
                onClick={handleClickName}
              />
            </Col>
            <VBox />
            <Col percentage={50} />
          </Row>
          <HBox />
          <PaymentSettingsEditor
            bankType={bankType}
            settings={settings}
            onSubmit={handleSubmit}
            allowNonResident={allowNonResident}
            isFuelEnabled={isFuelEnabled}
            canChangePaymentsConditions={canChangePaymentsConditions}
            isBankTypeManual={isBankTypeManual}
          />
        </Wrapper>
        {isChangeNameModalOpen && (
          <NameSettingsModal
            name={settings.name}
            onResolve={handleResolve}
            onReject={handleReject}
            isEdition
          />
        )}
      </>
    )
  }
)

PurePaymentSettingsEdition.displayName = 'PurePaymentSettingsEdition'
