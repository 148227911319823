import { Action } from 'redux-actions'

export type SetPaymentOutputFormatAction = Action<string>
export const setPaymentOutputFormatActionType =
  'manualConfirmationListState/setPaymentOutputFormat'
export const setPaymentOutputFormat = (
  outputFormat: string
): Action<string> => ({
  type: setPaymentOutputFormatActionType,
  payload: outputFormat,
})

export type Payloads = SetPaymentOutputFormatAction['payload']
