import { createApiReducer } from 'common/utils/reduxApiFactory'
import { transactionStatusActions } from 'api/transactions/status-request/actions'
import { TransactionStatusState } from 'api/transactions/status-request/types'

export const initialState: TransactionStatusState = {
  status: null,
  data: null,
}

export const transactionStatusReducer = createApiReducer(
  transactionStatusActions,
  initialState
)
