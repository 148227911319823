import { format, isToday, isYesterday, subDays } from 'date-fns'
import moment from 'moment'
import { formatToTimeZone } from 'date-fns-timezone'
import ru from 'date-fns/locale/ru'

import {
  declensionDays,
  declensionHours,
  declensionMinutes,
  declensionSeconds,
} from 'common/utils/formatters'

export const dateFormat = 'dd.MM.yyyy'
export const isoFormat = 'yyyy-MM-dd'

export const toISOFormat = (date: Date | string) => {
  if (typeof date === 'string') {
    const [d, m, y] = date.split('.')

    return format(new Date(`${m}.${d}.${y}`), isoFormat)
  }
  return format(date, isoFormat)
}

export const isoToDate = (iso: string) => {
  const aDate = /^(\d+)-(\d+)-(\d+)$/.exec(iso)
  return !aDate ? null : new Date(+aDate[1], +aDate[2] - 1, +aDate[3])
}

export const subtractDays = (date = new Date(), newSubtractDays = 7): Date =>
  subDays(date, newSubtractDays)

export const toDate = (date: string): string => {
  if (isToday(new Date(date))) {
    return 'Сегодня'
  }
  if (isYesterday(new Date(date))) {
    return 'Вчера'
  }
  return format(new Date(date), dateFormat)
}

export const toLastActivity = (date: string): string => {
  const lastActivity = new Date(date) as any
  const days = Math.floor((Date.now() - lastActivity) / 1000 / 86400)

  if (isToday(lastActivity)) {
    return 'сегодня'
  }
  if (isYesterday(lastActivity)) {
    return 'вчера'
  }
  if (days > 365) {
    return 'более года назад'
  }
  return `${days} ${declensionDays(days)} назад`
}

export const formatToRegularDate = (date: string | Date): string =>
  moment(date).format('DD.MM.YYYY')

export const formatToChartDate = (date: string | Date) =>
  format(new Date(date), 'dd.MM')

const weekdays = [
  'Понедельник',
  'Вторник',
  'Среда',
  'Четверг',
  'Пятница',
  'Суббота',
  'Воскресенье',
]
export const localizeWeekday = (weekday: number) => weekdays[weekday - 1]

export const formatWithLocale = (
  date: string,
  pattern = 'd MMMM yyyy'
): string => format(new Date(date), pattern, { locale: ru })

export const toTime = (date: string, seconds = false): string =>
  formatToTimeZone(new Date(date), seconds ? 'HH:mm:ss' : 'HH:mm', {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  })

export const formatHuman = (seconds: number): string => {
  if (!seconds) {
    return ''
  }

  let hours = Math.floor(seconds / 3600)
  const hoursRemainder = seconds % 3600

  let days = 0

  if (hours >= 24) {
    days = Math.floor(hours / 24)
    hours %= 24
  }

  const minutes = Math.floor(hoursRemainder / 60)

  const result = []

  if (days) {
    result.push(`${days} ${declensionDays(days)}`)
  }

  if (hours) {
    result.push(`${hours} ${declensionHours(hours)}`)
  }

  if (minutes) {
    result.push(`${minutes} ${declensionMinutes(minutes)}`)
  }

  if (result.length === 0) {
    result.push(`${seconds} ${declensionSeconds(seconds)}`)
  }

  return result.join(' ')
}
