import { connect } from 'react-redux'
import { State } from 'reducers'
import { apiSelectors } from 'api'
import { MyThunkDispatch } from 'api/reducer'
import { onCommit } from 'drivers/features/driversUploadContracts/thunks'
import { DriversUploadContracts } from 'drivers/features/driversUploadContracts/organisms'

const mapStateToProps = (state: State) => ({
  contractsList: apiSelectors.drivers.contractsUpload.getContractsList(state),
  contractsListLength: apiSelectors.drivers.contractsUpload.getContractsListLength(
    state
  ),
  isFetchingCommit: apiSelectors.drivers.contractsCommit.getIsFetching(state),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  onCommit: () => dispatch(onCommit()),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const DriversUploadContractsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DriversUploadContracts)
