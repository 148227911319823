import { css, FlattenSimpleInterpolation } from 'styled-components'
import { ColorTypes } from 'ui-kit'

export type Variant =
  | 'Caption1'
  | 'Caption2'
  | 'Caption3'
  | 'Caption4'
  | 'Body1'
  | 'Body2'
  | 'Body3'
  | 'Body4'
export type Size = 12 | 14 | 16 | 18 | 20 | 22 | 24
export type Weight = 'Regular' | 'Medium' | 'SemiBold' | 'Bold'
export type FontType = 'Main' | 'Table'

type StyleValues<T extends string | number, R = FlattenSimpleInterpolation> = {
  [key in T]: R
}

export const mainFontFamily = css`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
`

export const tableFontFamily = css`
  font-family: 'PT Sans', serif;
  font-style: normal;
`

export const largeCaption = css`
  font-size: 12px;
  line-height: 16px;
`

export const smallBody = css`
  font-size: 14px;
  line-height: 24px;
`

export const mediumBody = css`
  font-size: 16px;
  line-height: 24px;
`

export const largeBody = css`
  font-size: 18px;
  line-height: 24px;
`

export const smallTitle = css`
  font-size: 20px;
  line-height: 32px;
`

export const mediumTitle = css`
  font-size: 22px;
  line-height: 32px;
`

export const largeTitle = css`
  font-size: 24px;
  line-height: 32px;
`

const fontTypeValues: StyleValues<FontType> = {
  Main: mainFontFamily,
  Table: tableFontFamily,
}

const sizeValues: StyleValues<Size> = {
  12: largeCaption,
  14: smallBody,
  16: mediumBody,
  18: largeBody,
  20: smallTitle,
  22: mediumTitle,
  24: largeTitle,
}

const weightValues: StyleValues<Weight, number> = {
  Regular: 400,
  Medium: 500,
  SemiBold: 600,
  Bold: 700,
}

const variantValues: StyleValues<Variant> = {
  Caption1: css`
    ${largeCaption};
    font-weight: ${weightValues.Regular};
  `,
  Caption2: css`
    ${largeCaption};
    font-weight: ${weightValues.Medium};
  `,
  Caption3: css`
    ${largeCaption};
    font-weight: ${weightValues.SemiBold};
  `,
  Caption4: css`
    ${largeCaption};
    font-weight: ${weightValues.Bold};
  `,
  Body1: css`
    ${smallBody};
    font-weight: ${weightValues.Regular};
  `,
  Body2: css`
    ${smallBody};
    font-weight: ${weightValues.Medium};
  `,
  Body3: css`
    ${smallBody};
    font-weight: ${weightValues.SemiBold};
  `,
  Body4: css`
    ${smallBody};
    font-weight: ${weightValues.Bold};
  `,
}

export const makeVariantStyle = (value: Variant) => variantValues[value]

export const makeFontSize = (value: Size = 14) => sizeValues[value]

export const makeWeightStyle = (value: Weight = 'Regular') => css`
  font-weight: ${weightValues[value]};
`

export const makeColorStyle = (value: ColorTypes = 'black') => css`
  color: ${({ theme }) => theme.pallete[value]};
`

export const makeFontStyle = (value: FontType = 'Main') => fontTypeValues[value]
