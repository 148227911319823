import { ReferralOffer } from 'api/referral/offer/types'
import { InviterBonusFrequencies } from 'elastic-ui/referral-program/organisms/TermsEdit/types'

type InviterRewardPeriodType = ReferralOffer['inviter_reward_period_type']

const map: Record<InviterBonusFrequencies, InviterRewardPeriodType> = {
  'каждую неделю (в понедельник)': 'weekly',
  'каждый месяц (1 числа)': 'monthly',
  'только один раз': 'once',
  ежедневно: 'daily',
}

export default (
  bonusFrequency: InviterBonusFrequencies
): InviterRewardPeriodType => map[bonusFrequency]
