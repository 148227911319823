import Alert from 'react-s-alert'
import { State } from 'reducers'
import { apiActions, apiSelectors } from 'api'
import { MyThunkDispatch, MyThunkResult } from 'api/reducer'
import { ModalsNames, Permission } from 'common/enums'
import { digits } from 'common/utils/formatters'
import { ApiCode } from 'common/types/api/codes'
import { modalsActions } from 'shared/modals'
import { FormProps } from 'employees/features/employeesList/containers/AddEmployeeModalContainer'

export const onSave: ({
  values,
  permissions,
  id,
}: {
  values: FormProps
  permissions: Set<string>
  id?: string
}) => MyThunkResult<void> = ({ values, permissions, id }) => (
  dispatch: MyThunkDispatch,
  getState: () => State
) => {
  if (!permissions.size) {
    Alert.error('Ошибка', {
      customFields: {
        text: 'Укажите права и доступ для сотрудника',
      },
    })
    return
  }

  const data = {
    ...values,
    phone: values.phone ? digits(values.phone) : undefined,
    permissions: [] as Permission[],
  }

  if (
    !id ||
    (apiSelectors.employee.profile.getCanChangePermissions(getState()) &&
      !apiSelectors.employees.employeesByIdGet.getIsParkCreator(getState()))
  ) {
    data.permissions = Array.from(permissions) as Permission[]
  }

  return (id
    ? dispatch(
        apiActions.employees.employeesByIdPatch.handleRequest({ id, data })
      )
    : dispatch(apiActions.employees.post.handleRequest({ data }))
  ).then(
    () => {
      if (id) {
        dispatch(apiActions.employees.employeesByIdGet.handleRequest(id))
        if (id === apiSelectors.employee.profile.getId(getState())) {
          dispatch(apiActions.employee.profile.patch(data))
        }
      } else {
        dispatch(apiActions.employees.get.handleRequest())
      }

      dispatch(modalsActions.closeModal(ModalsNames.AddEmployee))
      Alert.info('Изменения сохранены')
    },
    (reason) => {
      if (
        reason.response &&
        reason.response.data.code === ApiCode.InputValidationError &&
        reason.response.data.payload
      ) {
        throw reason.response.data.payload
      }
    }
  )
}
