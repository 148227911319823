import { ThunkAction } from 'redux-thunk'
import { State } from 'reducers'
import axios, { AxiosResponse } from 'axios'
import { FailureError } from 'common/utils/reduxApiFactory'
import { TApiError } from 'api/types'
import { postFuelSettingsActions } from 'api/fuel/settings/post/actions'
import {
  PostFuelSettingsActions,
  PostFuelSettingsParams,
} from 'api/fuel/settings/post/types'
import { FuelSettings } from 'api/fuel/settings/types'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  PostFuelSettingsActions
>

export const postFuelSettingsRequest = (
  params: PostFuelSettingsParams
): THandleRequest => async (dispatch) => {
  const { start, update, success, failure } = postFuelSettingsActions
  dispatch(start())
  try {
    const response: AxiosResponse<FuelSettings> = await axios.post(
      `erp/1/fuel/settings`,
      params
    )
    dispatch(update(response.data))
    dispatch(success())
  } catch (e) {
    if (e.response) {
      const data = e.response.data as TApiError
      const error: FailureError = new Error(data.message)
      error.data = data
      dispatch(failure(error))
      throw error
    }
  }
}
