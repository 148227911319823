export enum EmployeeStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export const employeeStatusToString = (status: EmployeeStatus): string => {
  switch (status) {
    case EmployeeStatus.Active:
      return 'Активен'
    case EmployeeStatus.Inactive:
      return 'Неактивен'
    default:
      return 'Unknown'
  }
}
