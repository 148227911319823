import React from 'react'
import { Field } from 'redux-form'
import ListInput from 'common/components/formItems/ListInput'
import { required } from 'common/utils/fieldValidation'
import { HBox } from 'shared/ui'

export const AlfaE2CFields: React.FC = () => (
  <>
    <Field
      name="username"
      label="Логин в Альфа-Банке"
      validate={required}
      component={ListInput}
    />
    <HBox x={0.5} />
    <Field
      name="password"
      type="password"
      label="Пароль"
      validate={required}
      component={ListInput}
    />
  </>
)
