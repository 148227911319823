import { history } from 'store'
import { apiActions } from 'api'
import { MyThunkDispatch, MyThunkResult } from 'api/reducer'
import { RoutesPath } from 'common/enums'
import { ApiCode } from 'common/types/api/codes'
import { digits } from 'common/utils/formatters'

import { FormProps } from 'steps/features/stepRequisites/containers/StepRequisitesContainer'

export const onSaveRequisites: ({
  values,
}: {
  values: FormProps
}) => MyThunkResult<void> = ({ values }) => (dispatch: MyThunkDispatch) => {
  return dispatch(
    apiActions.park.organizationDetails.handleRequest({
      values: {
        bank_type: values.bank_type,
        legal_name: values.legal_name,
        bik: values.bik,
        bank_account: digits(values.bank_account),
      },
    })
  )
    .then(() => {
      const next = () =>
        values.phone && values.email
          ? dispatch(
              apiActions.park.technicalSupportContactsPatch.handleRequest({
                values: { email: values.email, phone: digits(values.phone) },
              })
            )
          : Promise.resolve()
      return next().finally(() => {
        history.replace(RoutesPath.StepsAggregators)
      })
    })
    .catch((error) => {
      if (error.response) {
        if (
          (error.response.data.code === ApiCode.InputValidationError ||
            error.response.data.code === ApiCode.ValidationError) &&
          error.response.data.payload
        ) {
          throw error.response.data.payload
        }
        if (
          error.response.data.code === ApiCode.OrganizationDetailsAlreadyFilled
        ) {
          history.replace(RoutesPath.StepsAggregators)
        }
      }
    })
}
