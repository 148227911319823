import { EnhancedPanel } from 'elastic-ui/shared/atoms/EnhancedPanel/EnhancedPanel'
import styled from 'styled-components'
import React from 'react'
import { LIMIT_CONTAINER_MAX_WIDTH } from '../constants'
import PreviewCardBackground from '../assets/preview-card-background.svg'

const Container = styled(EnhancedPanel)`
  width: ${LIMIT_CONTAINER_MAX_WIDTH}px;
  height: 152px;
  border-radius: 16px;
  padding: 12px 16px;
  background-image: url(${PreviewCardBackground});
  background-repeat: no-repeat;
  background-position: top right;
`

const VerticalFlexbox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const PreviewCard: React.FC = ({ children }) => (
  <Container>
    <VerticalFlexbox>{children}</VerticalFlexbox>
  </Container>
)

export default PreviewCard
