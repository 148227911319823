import { PaymentSettingsListItem } from 'settings/features/paymentSettingsTab/atoms'
import styled from 'styled-components'

export const PaymentSettingsListHeader = styled(PaymentSettingsListItem)`
  padding-bottom: 18px;
  border-bottom: 1px solid ${({ theme }) => theme.pallete.gray3};
  &:hover {
    background-color: transparent;
  }
`
