import React from 'react'
import { Field, InjectedFormProps, SubmissionError } from 'redux-form'

import { BodyText, FloatButton, ListGroup } from 'ui-kit/components'

import ListInput from 'common/components/formItems/ListInput'
import { FormFile } from 'common/components/formItems/FormFile'
import FormDatePicker from 'common/components/formItems/FormDatepicker'
import { maxLength30, minLength6, required } from 'common/utils/fieldValidation'

import { HBox, Modal, Tooltip, VBox } from 'shared/ui'

import {
  ContainerProps,
  FormProps,
} from 'settings/features/parkCertificate/containers/AddCertificateModalContainer'
import styled from 'styled-components'

const contentContainerComponent = styled.div`
  top: calc(100% + 8px) !important;
  bottom: initial !important;
  right: initial !important;
  left: 0 !important;
  width: 296px !important;
  box-shadow: 0 3px 12px rgba(5, 16, 34, 0.06) !important;
`

const Wrapper = styled.div`
  padding: 16px 0;
  width: 528px;

  .remind {
    color: ${({ theme }) => theme.pallete.blue};
    font-weight: 500;
  }
`

const Group = styled.div<{ withoutMargin?: boolean }>`
  padding: 0 16px;
`

const FileHolder = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`
const Actions = styled.div`
  display: flex;
  justify-content: center;

  button {
    max-width: 360px;
  }
`

export const AddCertificateModal: React.FC<
  InjectedFormProps<FormProps, ContainerProps> & ContainerProps
> = ({ valid, submitting, handleSubmit, onSave, closeAddcertificateModal }) => {
  const handleSave = async (values: FormProps) => {
    try {
      await onSave(values)
    } catch (e) {
      throw new SubmissionError(e)
    }
  }
  return (
    <Modal
      open
      closeButton
      noOverlayClick={false}
      overflow="initial"
      title="Добавление сертификата безопасности"
      onClose={closeAddcertificateModal}
    >
      <Wrapper>
        <Group>
          <Field
            name="alias"
            validate={[required]}
            label="Имя ключевого контейнера"
            component={ListInput}
          >
            <Tooltip
              content={`Имя и пароль, придуманные\n вами во время создания\n сертификата безопасности`}
              contentComponent={contentContainerComponent}
            />
          </Field>
          <HBox x={0.5} />
          <Field
            name="password"
            type="password"
            validate={[required, minLength6]}
            label="Пароль от контейнера"
            component={ListInput}
          />
          <HBox x={0.5} />
          <FileHolder>
            <BodyText>Сертификат, *.zip</BodyText>
            <VBox />
            <Field
              name="file"
              validate={[required]}
              component={FormFile}
              fileType={['application/x-zip-compressed', 'application/zip']}
              accept=".zip"
            />
          </FileHolder>
          <HBox x={0.5} />
        </Group>

        <ListGroup>Данные из Альфа-Банка</ListGroup>

        <Group>
          <Field
            name="basic_user"
            validate={[required]}
            label="Логин"
            component={ListInput}
          >
            <Tooltip
              content={`Логин и пароль, полученные\n вами от банка, после\n подтверждения сертификата\n безопасности`}
              contentComponent={contentContainerComponent}
            />
          </Field>
          <HBox x={0.5} />
          <Field
            name="basic_pass"
            type="password"
            label="Пароль"
            validate={[required, minLength6, maxLength30]}
            component={ListInput}
          />
          <HBox x={0.5} />
        </Group>

        <ListGroup>Дата окончания сертификата</ListGroup>

        <Group>
          <Field
            name="expire_date"
            validate={[required]}
            component={FormDatePicker}
            disableOnBlur
          >
            <Tooltip
              content={`Укажите дату, которая указана\n на ключе-токене`}
              contentComponent={contentContainerComponent}
            />
          </Field>
          <HBox x={0.5} />
          <BodyText>
            Мы напомним об окончании сертификата за{' '}
            <span className="remind">2 недели</span>
          </BodyText>
        </Group>
        <HBox />

        <Actions>
          <FloatButton
            disabled={!valid}
            isLoading={submitting}
            fullWidth
            onClick={handleSubmit(handleSave)}
          >
            Загрузить
          </FloatButton>
        </Actions>
        <HBox x={0.5} />
      </Wrapper>
    </Modal>
  )
}
