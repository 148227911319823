import { Dispatch, useContext } from 'react'

import { StepsContext } from 'steps/components/Steps/Steps'
import { TStepsAction, TStepsState } from 'steps/types'

export const useStepsContext = () => {
  const ctx = useContext(StepsContext)
  if (!ctx) {
    return {
      steps: {} as TStepsState,
      stepsDispatch: (() => undefined) as Dispatch<TStepsAction>,
    }
  }
  const [steps, stepsDispatch] = ctx
  return { steps, stepsDispatch }
}
