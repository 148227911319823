import React, { useEffect, useRef, useState } from 'react'
import { MyThunkDispatch } from 'api/reducer'
import { State } from 'reducers'
import { connect } from 'react-redux'
import GhostContentAPI, { PostOrPage } from '@tryghost/content-api'
import { apiActions, apiSelectors } from 'api'
import { CloudCashBox } from 'api/receipt/cloud-cashbox/types'
import { Sno } from 'api/receipt/sno/types'

const api = new GhostContentAPI({
  url: 'https://mozen.io',
  key: '0208c582905e5b97bafd17d1af',
  version: 'v3',
})

export type OfdData = {
  inn: string
  kpp: string
  name: string
  blocked: boolean
  snoName: string
}

type LandingData = {
  data: PostOrPage
  sendRegistrationRequest: () => Promise<void>
  connectOfd: () => Promise<void>
}

type ChildProps = {
  isPreload?: boolean
  landing?: LandingData
  data?: OfdData
  snoList?: Sno[]
  block: () => Promise<void>
  add: (ofd: CloudCashBox) => Promise<void>
  edit: (ofd: Partial<CloudCashBox>) => Promise<void>
}

type OwnProps = {
  children(props: ChildProps): React.ReactElement
}

export const PureOfdConnector = ({
  children,
  fetchAddOfdData,
  fetchBlockOfd,
  fetchEditOfdData,
  fetchOfdData,
  fetchOfdSnoList,
  ofdData,
  ofdSnoList,
}: ConnectorProps) => {
  const [ghostContent, setGhostContent] = useState<PostOrPage>()

  const needCheckGhost = useRef(false)
  useEffect(() => {
    const loadData = async () => {
      await fetchOfdData()
      await fetchOfdSnoList()
      needCheckGhost.current = true
    }

    loadData()
  }, [fetchOfdData, fetchOfdSnoList])

  useEffect(() => {
    const fetchGhostContent = async () => {
      const response = await api.pages.read({
        id: '615af48bca0a3f06d473c262',
      })
      setGhostContent(response)
    }

    if (needCheckGhost.current && !ofdData) {
      fetchGhostContent()
      needCheckGhost.current = false
    }
  })

  let landing: LandingData | undefined
  let data: OfdData | undefined
  let snoList: Sno[] | undefined

  if (ofdData) {
    data = {
      inn: ofdData.inn,
      kpp: ofdData.kpp,
      name: ofdData.name,
      blocked: ofdData.blocked,
      snoName: ofdData.sno_name,
    }
  }

  if (ghostContent) {
    landing = {
      data: ghostContent,
      sendRegistrationRequest: async () => {},
      connectOfd: async () => {},
    }
  }

  const block = async () => {
    await fetchBlockOfd()
    await fetchOfdData()
  }

  const edit = async (ofd: Partial<CloudCashBox>) => {
    await fetchEditOfdData(ofd)
    await fetchOfdData()
  }

  const add = async (ofd: CloudCashBox) => {
    await fetchAddOfdData(ofd)
    await fetchOfdData()
  }

  if (ofdSnoList) {
    snoList = ofdSnoList.data
  }

  return children({ landing, data, snoList, block, edit, add })
}

const mapStateToProps = (state: State) => ({
  ofdData: apiSelectors.receipt.getCloudCashBox.selectData(state),
  ofdSnoList: apiSelectors.receipt.getSno.selectData(state),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  fetchOfdData: () =>
    dispatch(apiActions.receipt.getCloudCashBox.handleRequest()),
  fetchOfdSnoList: () => dispatch(apiActions.receipt.getSno.handleRequest()),
  fetchEditOfdData: (data: Partial<CloudCashBox>) =>
    dispatch(apiActions.receipt.patchCloudCashBox.handleRequest(data)),
  fetchAddOfdData: (data: CloudCashBox) =>
    dispatch(apiActions.receipt.postCloudCashBox.handleRequest(data)),
  fetchBlockOfd: () =>
    dispatch(apiActions.receipt.patchBlockCloudCashBox.handleRequest()),
})

export type ConnectorProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps

export const OfdConnector = connect(
  mapStateToProps,
  mapDispatchToProps
)(PureOfdConnector)
