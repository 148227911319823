import { number, string } from 'yup'

import { clamp } from 'elastic-ui/utils'

import { InviterBonusTypes } from './types'

export const getBonusAmountLabelFormBonusType = (
  bonusType: InviterBonusTypes
) => {
  switch (bonusType) {
    case InviterBonusTypes.MoneyForDriving:
      return '₽ за 1 поездку'
    case InviterBonusTypes.ParkCommisionShare:
      return '% с комиссии парка'
    case InviterBonusTypes.FixedBonus:
      return '₽ фиксировано'
    default:
      return ''
  }
}

interface Options {
  label: string
  isDisabled: boolean
  onClick: () => void
}

const max = 10000000
const valueToNumber = (value: string, limit = max) =>
  clamp(parseInt(value, 10), { min: 1, max: limit })

const numberSchema = number().min(1).integer()
const isPositiveInteger = (numberToValidate: number, limit = max) =>
  numberSchema.max(limit).isValidSync(numberToValidate)

export const clampAnddoIfPositiveInteger = (
  value: string,
  limit = max,
  callback: (integer: number) => void
) => {
  const valueAsNumber = valueToNumber(value, limit)
  const isValid = isPositiveInteger(valueAsNumber, limit)

  if (isValid) callback(valueAsNumber)
}

const stringSchema = string()
export const doIfStringNoMoreThan = (
  value: string,
  limit: number,
  callback: (value: string) => void
) => {
  const isValid = stringSchema.max(limit).isValidSync(value)
  if (isValid) callback(value)
}
