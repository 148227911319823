import React, { useEffect, useReducer } from 'react'

import { Limitation, Pagination } from 'ui-kit/components'
import { ContentCard } from 'shared/ui'
import {
  EmptyListing,
  Heading,
  Listing,
} from 'settings/features/depositTransactions/molecules'

import { ContainerProps } from 'settings/features/depositTransactions/containers/DepositTransactionsContainer'
import { PaginationLimit } from 'api/types'
import styled from 'styled-components'

const PaginatorWrapper = styled.div`
  display: flex;
  padding-left: ${({ theme }) => theme.paddings.half}px;
  padding-right: ${({ theme }) => theme.paddings.main}px;
  justify-content: space-between;
  align-items: center;
`

type StateType = {
  limit: PaginationLimit
  page: number
}

type ActionType = {
  type: 'prev' | 'next' | 'limit'
  limit?: PaginationLimit
}

const initialState: StateType = {
  limit: 20,
  page: 1,
}

function reducer(state: StateType, action: ActionType): StateType {
  switch (action.type) {
    case 'prev':
      return { ...state, page: state.page - 1 }
    case 'next':
      return { ...state, page: state.page + 1 }
    case 'limit':
      return {
        ...state,
        limit: action.limit ? action.limit : state.limit,
        page: 1,
      }
    default:
      return state
  }
}

export const DepositTransactions: React.FC<ContainerProps> = ({
  totalAmount,
  transactions,
  isFetching,
  fetch,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const totalPages = totalAmount ? Math.ceil(totalAmount / state.limit) : 0
  useEffect(() => {
    fetch(state.limit, state.page)
  }, [fetch, state.limit, state.page])

  const onNextPage = () => {
    dispatch({ type: 'next' })
  }
  const onPrevPage = () => {
    dispatch({ type: 'prev' })
  }
  const onLimit = (limit: PaginationLimit) => {
    dispatch({ type: 'limit', limit })
  }
  return (
    <ContentCard>
      <Heading />
      <Listing isFetching={isFetching} transactions={transactions} />
      {totalPages ? (
        <PaginatorWrapper>
          <Pagination
            currentPage={state.page}
            totalPages={totalPages}
            onClickPrev={isFetching ? () => undefined : onPrevPage}
            onClickNext={isFetching ? () => undefined : onNextPage}
          />
          <Limitation currentLimit={state.limit} limitAction={onLimit} />
        </PaginatorWrapper>
      ) : null}
      {!totalPages && !isFetching ? <EmptyListing /> : null}
    </ContentCard>
  )
}
