import React from 'react'
import { InjectedFormProps } from 'redux-form'
import Alert from 'react-s-alert'

import { CaptionText, RejectResolveButtons } from 'ui-kit/components'
import { HBox, Loader, Modal } from 'shared/ui'

import {
  ContainerProps,
  FormProps,
} from 'settings/features/bankIntegrationModal/containers/BankIntegrationModalContiner'
import styled from 'styled-components'
import { BankType } from 'common/enums'
import { PostPayoutCredentialsRequestSchema } from 'api/park/payout/credentials/post/types'
import { AlfaE2CFields, ModulFields, QiwiFields } from '../components'

const Wrapper = styled.div`
  padding: 16px;
  width: 450px;
`

const getFieldsFromBankType = (type: BankType) => {
  switch (type) {
    case BankType.AlfaE2C:
      return <AlfaE2CFields />
    case BankType.Modul:
      return <ModulFields />
    case BankType.Qiwi:
      return <QiwiFields />
    default:
      return null
  }
}

const modalTitle = {
  [BankType.AlfaE2C]: 'Счет Альфа-Банка',
  [BankType.Qiwi]: 'Счет QIWI',
  [BankType.Modul]: 'Счет Модульбанка',
}

type E2CBanks = BankType.Modul | BankType.Qiwi | BankType.AlfaE2C

export const BankIntegrationModal: React.FC<
  InjectedFormProps<FormProps, ContainerProps> & ContainerProps
> = ({
  submitting,
  error,
  handleSubmit,
  onSave,
  closeModal,
  bankType,
  data,
  updateParkPayoutCredentials,
}) => {
  const handleSave = async (values: FormProps) => {
    try {
      const requestBody: PostPayoutCredentialsRequestSchema = {}

      if (bankType === BankType.AlfaE2C) {
        requestBody.alfa_e2c = values as PostPayoutCredentialsRequestSchema['alfa_e2c']
      } else if (bankType === BankType.Modul) {
        requestBody.modul = values as PostPayoutCredentialsRequestSchema['modul']
      } else if (bankType === BankType.Qiwi) {
        requestBody.qiwi = values as PostPayoutCredentialsRequestSchema['qiwi']
      }

      if (bankType) {
        await onSave(bankType, values)
      }
      await updateParkPayoutCredentials()
      closeModal()
    } catch (e) {
      Alert.error('Ошибка', {
        customFields: {
          text:
            e?.response?.data?.payload?.[String(bankType)] ||
            'что-то пошло не так',
        },
      })
    }
  }

  const isEdit = data?.masked_login !== undefined

  const title = modalTitle[bankType as E2CBanks]

  return (
    <Modal
      open
      closeButton
      onClose={closeModal}
      noOverlayClick={false}
      title={title}
    >
      {bankType ? (
        <Wrapper>
          {getFieldsFromBankType(bankType)}
          <HBox x={0.5} />

          {error && !submitting ? (
            <CaptionText color="red">{error}</CaptionText>
          ) : (
            <HBox x={0.5} />
          )}

          <HBox x={0.75} />
          <RejectResolveButtons
            fullWidth
            isLoading={submitting}
            onResolve={handleSubmit(handleSave)}
            onReject={closeModal}
            rejectText="Закрыть"
            resolveText={isEdit ? 'Сохранить' : 'Подключить'}
            disableResolve={submitting}
            disableReject={submitting}
          />
        </Wrapper>
      ) : (
        <Loader />
      )}
    </Modal>
  )
}
