import React, { useCallback, useMemo, useRef, useState } from 'react'

import { PaymentSettings } from 'api/types'
import { PaymentSettingsDefaultNames } from 'common/enums/payments'
import { Option } from 'common/types/local/option'
import { DriverSettingsData } from 'drivers/features/driverSettingsTab/types'

import { Col, HBox, Row, VBox } from 'shared/ui'

import { ResetIndividualSettingsModal } from 'drivers/features/driverSettingsTab/pages'
import { DriverSettingsSelection } from 'drivers/features/driverSettingsTab/molecules'
import { PaymentSettingsEditor } from 'shared/ui/paymentSettingsEditor'
import { NameSettingsModal } from 'shared/ui/paymentSettingsNameModal'
import { BankType } from 'common/enums'

const fakeIndividualOption: Option<string> = {
  label: PaymentSettingsDefaultNames.INDIVIDUAL,
  value: 'individual',
}

type Props = {
  bankType: BankType | null
  data: DriverSettingsData
  onEditIndividualSettings: (values: Partial<PaymentSettings>) => Promise<void>
  onCreateIndividualSettings: (
    values: Partial<PaymentSettings>
  ) => Promise<void>
  onAssignSettings: (settingsId: string) => Promise<void>
  onCreateGroupSettings: (settingsName: string) => Promise<void>
}

export const PureDriverSettingsTab: React.FC<Props> = ({
  bankType,
  data,
  onEditIndividualSettings,
  onCreateIndividualSettings,
  onAssignSettings,
  onCreateGroupSettings,
}) => {
  const [isResetModalOpen, setIsResetModalOpen] = useState(false)
  const [isCreateGroupModalOpen, setIsCreateGroupModalOpen] = useState(false)
  const [withActions, setWithActions] = useState(false)

  const driverOption = useMemo(
    () => data.settingsOptions.find((item) => item.value === data.formData.id),
    [data.formData.id, data.settingsOptions]
  )

  const [activeOption, setActiveOption] = useState(driverOption)

  const handleCreateGroupSettings = useCallback(() => {
    setIsCreateGroupModalOpen(true)
  }, [])

  const setOption = useCallback(
    async (option: Option<string>) => {
      setWithActions(false)
      await onAssignSettings(option.value)
      setActiveOption(option)
    },
    [onAssignSettings]
  )

  const setFakeIndividualOption = useCallback(() => {
    setActiveOption(fakeIndividualOption)
    setWithActions(true)
  }, [])

  const temporaryOption = useRef<Option<string>>()
  const handleChangeOption = useCallback(
    async (option: Option<string>) => {
      if (option.value && data.isDriverSettingsIndividual) {
        temporaryOption.current = option
        setIsResetModalOpen(true)
      } else if (!option.value) {
        setFakeIndividualOption()
      } else {
        await setOption(option)
      }
    },
    [data.isDriverSettingsIndividual, setFakeIndividualOption, setOption]
  )

  const handleSubmit = useCallback(
    async (values: Partial<PaymentSettings>) => {
      setWithActions(false)
      if (data.isDriverSettingsIndividual) {
        await onEditIndividualSettings(values)
      } else {
        await onCreateIndividualSettings(values)
      }
    },
    [
      data.isDriverSettingsIndividual,
      onCreateIndividualSettings,
      onEditIndividualSettings,
    ]
  )

  const setIndividualOption = useCallback(() => {
    const individualOption = data.settingsOptions.find(
      (item) => item.label === PaymentSettingsDefaultNames.INDIVIDUAL
    )
    if (individualOption) {
      setActiveOption(individualOption)
    } else {
      setActiveOption(fakeIndividualOption)
    }
  }, [data.settingsOptions])

  const handleChangePristine = useCallback(
    (isPristine: boolean) => {
      if (isPristine) {
        setActiveOption(driverOption)
      } else {
        setIndividualOption()
      }
      setWithActions(false)
    },
    [driverOption, setIndividualOption]
  )

  const handleResetModalReject = useCallback(() => {
    setIsResetModalOpen(false)
  }, [])

  const handleResetModalResolve = useCallback(async () => {
    if (temporaryOption.current) {
      await setOption(temporaryOption.current)
    }
    temporaryOption.current = undefined
    setIsResetModalOpen(false)
  }, [setOption])

  const handleCreateGroupModalReject = useCallback(() => {
    setIsCreateGroupModalOpen(false)
  }, [])

  const handleCreateGroupModalResolve = useCallback(
    async (groupName: string) => {
      await onCreateGroupSettings(groupName)
      setIsCreateGroupModalOpen(false)
    },
    [onCreateGroupSettings]
  )

  return (
    <>
      <Row>
        <Col percentage={50}>
          <DriverSettingsSelection
            options={data.settingsOptions}
            active={activeOption}
            onChange={handleChangeOption}
            onCreateGroupSettings={
              data.isDriverSettingsIndividual
                ? handleCreateGroupSettings
                : undefined
            }
          />
        </Col>
        <VBox />
        <Col percentage={50} />
      </Row>
      <HBox />
      <PaymentSettingsEditor
        bankType={bankType}
        isFuelEnabled={data.isFuelEnabled}
        withActions={withActions}
        settings={data.formData}
        onSubmit={handleSubmit}
        onChangePristine={handleChangePristine}
        allowNonResident={data.allowNonResident}
        canChangePaymentsConditions={data.canChangePaymentsConditions}
        isBankTypeManual={data.isBankTypeManual}
      />
      {isResetModalOpen && (
        <ResetIndividualSettingsModal
          onResolve={handleResetModalResolve}
          onReject={handleResetModalReject}
        />
      )}
      {isCreateGroupModalOpen && (
        <NameSettingsModal
          onResolve={handleCreateGroupModalResolve}
          onReject={handleCreateGroupModalReject}
        />
      )}
    </>
  )
}
