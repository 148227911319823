import React from 'react'
import { Field } from 'redux-form'

import { CaptionText, ListGroup } from 'ui-kit/components'

import { moreThan, noMoreThan, required } from 'common/utils/fieldValidation'
import InputMask, {
  maskNumberWithSuffix,
} from 'common/components/formItems/InputMask'

import { HBox } from 'shared/ui/index'
import styled from 'styled-components'
import { BankType } from 'common/enums'

const Box = styled.div`
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 3px 10px rgba(192, 206, 226, 0.5),
    0 0 4px rgba(192, 206, 226, 0.2);
`

const ImportantBox = styled(Box)`
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 10px;
`

const Wrapper = styled.div<{ overlay?: boolean }>`
  position: relative;

  ${({ overlay }) =>
    overlay &&
    `
      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 100;
        background-color: rgba(255, 255, 255, .7);
      }
  `};

  ${Box}:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  ${ImportantBox} + ${Box} {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
`

const Group = styled.div`
  padding: 8px 16px;
`

const AntifraudListGroup = styled(ListGroup)`
  background-color: #12cdf3;
  color: #ffffff;
`

const minWithdrawAmountByBankTypeMap: Record<BankType, number> = {
  alfa: 1,
  manual: 1,
  qiwi: 20,
  modul: 10,
  tinkoff: 10,
  alfa_e2c: 10,
  azon: 10,
}

const maxWithdrawAmountByBankTypeMap: Record<BankType, number> = {
  alfa: 99999,
  manual: 99999,
  qiwi: 14850,
  modul: 30000,
  tinkoff: 15000,
  alfa_e2c: 15000,
  azon: 15000,
}

type Props = {
  bankType: BankType | null
  isLocked?: boolean
  isAllowNonResident?: boolean
  canChangePaymentsConditions?: boolean
  hasAntiFraud?: boolean
  onChange?: () => void
}

export const PaymentSettingsFields: React.FC<Props> = ({
  bankType,
  isLocked,
  canChangePaymentsConditions,
  isAllowNonResident,
  hasAntiFraud,
  onChange,
}) => {
  const parkMoreThanMinWithdraw = React.useCallback(
    (value: string | number) =>
      moreThan(value, minWithdrawAmountByBankTypeMap[bankType!] - 1),
    [bankType]
  )

  const parkNoMoreThanMaxWithdraw = React.useCallback(
    (value: string | number) =>
      noMoreThan(value, maxWithdrawAmountByBankTypeMap[bankType!]),
    [bankType]
  )

  if (bankType) {
    return (
      <Wrapper overlay={isLocked}>
        {hasAntiFraud && (
          <ImportantBox>
            <AntifraudListGroup>
              <CaptionText color="white">Контроль новых водителей</CaptionText>
            </AntifraudListGroup>

            <Group>
              <CaptionText color="gray6">
                Все платежи водителя будут подтверждаться вручную до тех пор,
                пока он не совершит:
              </CaptionText>
              <Field
                name="fair_rides"
                label="Безналичных поездок"
                mask="999"
                iconName="close"
                component={InputMask}
                disabled={!canChangePaymentsConditions}
                validate={required}
                onChange={onChange}
              />
              <HBox x={0.5} />
              <Field
                name="withdraw_transactions_for_auto_withdraw"
                label="Подтвержденных выводов"
                mask="999"
                iconName="close"
                component={InputMask}
                disabled={!canChangePaymentsConditions}
                validate={required}
                onChange={onChange}
              />
              <HBox x={0.5} />
            </Group>
          </ImportantBox>
        )}

        <Box>
          <ListGroup>Лимит</ListGroup>
          <Group>
            <Field
              name="day_withdraw_limit"
              label="В день, ₽"
              mask={maskNumberWithSuffix(5, '', 2)}
              iconName="close"
              component={InputMask}
              disabled={!canChangePaymentsConditions}
              onChange={onChange}
            />
            <HBox x={0.5} />
            <Field
              name="week_withdraw_limit"
              label="В неделю, ₽"
              mask={maskNumberWithSuffix(5, '', 2)}
              iconName="close"
              component={InputMask}
              disabled={!canChangePaymentsConditions}
              onChange={onChange}
            />
            <HBox x={0.5} />
            <Field
              name="min_balance"
              label="Неснижаемый остаток, ₽"
              mask={maskNumberWithSuffix(5, '', 2)}
              iconName="close"
              validate={[required]}
              component={InputMask}
              disabled={!canChangePaymentsConditions}
              onChange={onChange}
            />
            <HBox x={0.5} />
          </Group>
        </Box>

        <Box>
          <ListGroup>Сумма одного вывода</ListGroup>
          <Group>
            <Field
              name="min_withdraw_amount"
              label="Минимальная, ₽"
              mask={maskNumberWithSuffix(5, '', 2)}
              iconName="close"
              validate={[required, parkMoreThanMinWithdraw]}
              component={InputMask}
              disabled={!canChangePaymentsConditions}
              onChange={onChange}
            />
            <HBox x={0.5} />
            <Field
              name="max_withdraw_amount"
              label="Максимальная, ₽"
              mask={maskNumberWithSuffix(5, '', 2)}
              validate={[parkNoMoreThanMaxWithdraw]}
              iconName="close"
              component={InputMask}
              disabled={!canChangePaymentsConditions}
              onChange={onChange}
            />
            <HBox x={0.5} />
          </Group>
        </Box>

        <Box>
          <ListGroup>Комиссия парка</ListGroup>
          <Group>
            <Field
              name="min_commission"
              label="Минимальная, ₽"
              mask={maskNumberWithSuffix(3, '', 2)}
              iconName="close"
              validate={[required]}
              component={InputMask}
              disabled={!canChangePaymentsConditions}
              onChange={onChange}
            />
            <HBox x={0.5} />
            <Field
              name="withdraw_commission"
              label="Стандартная, %"
              mask={maskNumberWithSuffix(2, '', 1)}
              iconName="close"
              validate={[required]}
              component={InputMask}
              disabled={!canChangePaymentsConditions}
              onChange={onChange}
            />
            <HBox x={0.5} />
            {isAllowNonResident && (
              <>
                <Field
                  name="non_resident_withdraw_commission"
                  label="Для нерезидентов, %"
                  mask={maskNumberWithSuffix(2, '', 1)}
                  iconName="close"
                  validate={[required]}
                  component={InputMask}
                  disabled={!canChangePaymentsConditions}
                  onChange={onChange}
                />
                <HBox x={0.5} />
                <Field
                  name="non_resident_min_commission"
                  label="Минимальная для нерезидентов"
                  mask={maskNumberWithSuffix(3, '', 2)}
                  iconName="close"
                  validate={[required]}
                  component={InputMask}
                  disabled={!canChangePaymentsConditions}
                  onChange={onChange}
                />
                <HBox x={0.5} />
              </>
            )}
            <Field
              name="free_withdraws_count"
              label="Бесплатных выводов у водителя в неделю"
              mask="999"
              iconName="close"
              validate={[required]}
              component={InputMask}
              disabled={!canChangePaymentsConditions}
              onChange={onChange}
            />
            <HBox x={0.5} />
          </Group>
        </Box>
      </Wrapper>
    )
  }

  return <></>
}
