import React from 'react'
import { MyThunkDispatch } from 'api/reducer'
import { State } from 'reducers'
import { connect } from 'react-redux'
import { apiActions, apiSelectors } from 'api'

type ChildProps = {
  isPreload?: boolean
  link?: string
  getLink: () => Promise<void>
  disableNotifications: () => Promise<void>
  sendSecret: (secretKey: string) => Promise<void>
  isEnabled?: boolean
}

type OwnProps = {
  children(props: ChildProps): React.ReactElement
}

export const PureNotificationsTelegramBotConnector = React.memo<ConnectorProps>(
  ({
    children,
    isNotificationsEnabled,
    telegramBot,
    fetchDisableNotifications,
    fetchNotificationsSettings,
    fetchEnableNotifications,
    fetchTelegramLink,
  }) => {
    const getLink = async () => {
      await fetchTelegramLink()
    }

    const disableNotifications = async () => {
      await fetchDisableNotifications()
      await fetchNotificationsSettings()
    }

    const sendSecret = async (secretKey: string) => {
      await fetchEnableNotifications(secretKey)
      await fetchNotificationsSettings()
    }

    return children({
      isEnabled: isNotificationsEnabled,
      link: telegramBot?.link,
      getLink,
      sendSecret,
      disableNotifications,
    })
  }
)

PureNotificationsTelegramBotConnector.displayName =
  'PureNotificationsTelegramBotConnector'

const mapStateToProps = (state: State) => ({
  isNotificationsEnabled: apiSelectors.park.notificationsGet.selectTelegramBotEnabledStatus(
    state
  ),
  telegramBot: apiSelectors.park.getNotificationsBot.selectData(state),
})
const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  fetchNotificationsSettings: () =>
    dispatch(apiActions.park.notificationsGet.handleRequest()),
  fetchTelegramLink: () =>
    dispatch(apiActions.park.getNotificationsBot.handleRequest()),
  fetchDisableNotifications: () =>
    dispatch(apiActions.park.deleteNotificationsBot.handleRequest()),
  fetchEnableNotifications: (secret: string) =>
    dispatch(
      apiActions.park.postNotificationsBot.handleRequest({
        secret,
      })
    ),
})

export type ConnectorProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps

export const NotificationsTelegramBotConnector = connect(
  mapStateToProps,
  mapDispatchToProps
)(PureNotificationsTelegramBotConnector)
