import React, { useCallback } from 'react'
import { FuelDepositType, FuelHistory } from 'ui-kit/organisms/FuelTransactions'
import { fixDecimalPrecisionErrors } from 'elastic-ui/utils'
import { FuelPage, FuelPageProps } from './FuelPage'
import { FuelTransactionType, EventFilter } from '../../types'

interface MappedTableChangeEvent {
  limit: number
  offset?: number
  depositType?: FuelDepositType
}

interface Props
  extends Omit<
    FuelPageProps,
    | 'operationHistoryOnPage'
    | 'onTableChange'
    | 'defaultRowsPerPage'
    | 'rowsPerPageOptions'
  > {
  operationHistoryOnPage: FuelHistory[]
  onTableChange: (event: MappedTableChangeEvent) => void
}

const depositType = [FuelTransactionType.Deposit, FuelTransactionType.Bonus]

const getDepositTypeFormFilters = (
  filters: EventFilter[]
): FuelDepositType | undefined => {
  if (filters.every((filter) => filter.isEnabled)) {
    return undefined
  }
  if (
    filters.some(
      (filter) =>
        filter.type === FuelTransactionType.Deposit && filter.isEnabled
    )
  ) {
    return FuelDepositType.Fuel
  }
  if (
    filters.some(
      (filter) => filter.type === FuelTransactionType.Bonus && filter.isEnabled
    )
  ) {
    return FuelDepositType.CashBack
  }
}

export const FuelPageDataMapper: React.FC<Props> = ({
  bonusAmount,
  cashbackPercent,
  fuelDepositAmount,
  maxCashbackPercent,
  onCashbackSettingsSave,
  onDownloadDepositPayment,
  onTableChange,
  operationHistoryOnPage,
  totalTableItems,
  isBonusSettingsModalOpen,
  isBonusSettingsLoading,
  isDepositModalOpen,
  isOperationHistoryLoading,
  onBonusSettingsModalClose,
  onChangeBonusSettingsClick,
  onDepositClick,
  onDepositModalClose,
}) => (
  <FuelPage
    fuelDepositAmount={fuelDepositAmount / 100}
    bonusAmount={bonusAmount / 100}
    rowsPerPageOptions={[20, 50]}
    defaultRowsPerPage={50}
    onTableChange={useCallback(
      ({ filters, pageIndex, rowsPerPage }) =>
        onTableChange({
          limit: rowsPerPage,
          depositType: getDepositTypeFormFilters(filters),
          offset: pageIndex * rowsPerPage,
        }),
      [onTableChange]
    )}
    totalTableItems={totalTableItems}
    isOperationHistoryLoading={isOperationHistoryLoading}
    isBonusSettingsLoading={isBonusSettingsLoading}
    cashbackPercent={cashbackPercent / 100}
    maxCashbackPercent={maxCashbackPercent / 100}
    onCashbackSettingsSave={useCallback(
      (percent) => {
        const percentMultiplied = fixDecimalPrecisionErrors(percent * 100)
        onCashbackSettingsSave(percentMultiplied)
      },
      [onCashbackSettingsSave]
    )}
    onDownloadDepositPayment={useCallback(
      (amount) => {
        const amountInKopek = fixDecimalPrecisionErrors(amount * 100)
        onDownloadDepositPayment(amountInKopek)
      },
      [onDownloadDepositPayment]
    )}
    operationHistoryOnPage={operationHistoryOnPage.map((item) => ({
      amount:
        (item.amount.isPositive ? item.amount.value : -item.amount.value) / 100,
      date: new Date(item.dateCreation).getTime(),
      id: item.id,
      reason: item.description,
      type: depositType[item.depositType],
    }))}
    isBonusSettingsModalOpen={isBonusSettingsModalOpen}
    isDepositModalOpen={isDepositModalOpen}
    onChangeBonusSettingsClick={onChangeBonusSettingsClick}
    onDepositClick={onDepositClick}
    onBonusSettingsModalClose={onBonusSettingsModalClose}
    onDepositModalClose={onDepositModalClose}
  />
)
