import React from 'react'

import { Loader } from 'shared/ui/loader'
import { PaymentSettingsTabConnector } from 'settings/features/paymentSettingsTab/containers'
import { PurePaymentSettingsTab } from 'settings/features/paymentSettingsTab/organisms'

export const PaymentSettingsTab = React.memo(() => (
  <PaymentSettingsTabConnector>
    {({
      isPreload,
      settingsList,
      createSettings,
      deleteSettings,
      bankType,
    }) => {
      if (isPreload || !settingsList) {
        return <Loader />
      }

      return (
        <PurePaymentSettingsTab
          bankType={bankType}
          isPaymentLocked={false} // TODO: Собрать информацию по этому полю
          settingsList={settingsList}
          onCreateSettings={createSettings}
          onDeleteSettings={deleteSettings}
        />
      )
    }}
  </PaymentSettingsTabConnector>
))

PaymentSettingsTab.displayName = 'PaymentSettingsTab'
