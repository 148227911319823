import axios, { AxiosResponse } from 'axios'

import { AggregatorsResponse } from 'common/types/api/responses/aggregators'
import { Aggregator } from 'common/types/api/models/aggregator'
import { AggregatorType } from 'common/enums'

const combineAggregators = (data: AggregatorsResponse): Aggregator[] => {
  const combined: Aggregator[] = []

  Object.keys(data).forEach((key) => {
    const aggregatorsList = data[key as keyof AggregatorsResponse]

    combined.push(
      ...aggregatorsList.map((item) => ({
        ...item,
        type: key as AggregatorType,
      }))
    )
  })

  return combined
}

export const getAggregators = async (): Promise<Aggregator[]> => {
  const aggregators: AxiosResponse<AggregatorsResponse> = await axios.get(
    'erp/1/park/aggregators'
  )

  return combineAggregators(aggregators.data)
}
