import { EuiForm, EuiFormProps } from '@elastic/eui'
import React from 'react'
import styled from 'styled-components'

const Fieldset = styled.fieldset`
  padding: 0;
  margin: 0;
  border: none;
`

interface EnhancedProps<T extends HTMLElement> {
  isDisabled?: boolean
  onCustomValidation?: (e: React.FormEvent<HTMLFormElement>) => T | boolean
  onCustomInvalid?: (htmlElement?: T) => void
}

type Props = EuiFormProps & EnhancedProps<HTMLElement>

const EuiFormEnhanced: React.FC<Props> = ({
  children,
  isDisabled,
  onSubmit,
  onCustomValidation,
  onCustomInvalid,
  onInvalid,
  component = 'form',
  ...props
}) => {
  const handleValidation = (
    e: React.FormEvent<HTMLFormElement> | React.InvalidEvent<HTMLFormElement>
  ) => {
    if (onCustomValidation) {
      const htmlElementOrBoolean = onCustomValidation(e)
      if (typeof htmlElementOrBoolean === 'object') {
        htmlElementOrBoolean?.focus()

        onCustomInvalid?.(htmlElementOrBoolean)
        return false
      }
      if (!htmlElementOrBoolean) {
        onCustomInvalid?.()
        return false
      }
    }

    return true
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.persist()
    e.preventDefault()

    if (!handleValidation(e)) {
      return
    }

    onSubmit?.(e as any)
  }

  const handleInvalid = (e: React.InvalidEvent<HTMLFormElement>) => {
    e.persist()
    handleValidation(e)
    onInvalid?.(e as any)
  }

  return (
    <EuiForm
      onSubmit={handleSubmit}
      component={component}
      onInvalid={handleInvalid}
      {...(props as any)}
    >
      <Fieldset disabled={isDisabled}>{children}</Fieldset>
    </EuiForm>
  )
}

export default EuiFormEnhanced
