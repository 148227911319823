import { createApiReducer } from 'common/utils/reduxApiFactory'
import { deleteEdgvrApiKeyActions } from 'api/park/edgvr-api-key/delete/actions'
import { DeleteEdgvrApiKeyState } from 'api/park/edgvr-api-key/delete/types'

export const initialState: DeleteEdgvrApiKeyState = {
  status: null,
  data: null,
}

export const deleteEdgvrApiKeyReducer = createApiReducer(
  deleteEdgvrApiKeyActions,
  initialState
)
