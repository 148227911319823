import { createSelector } from 'reselect'
import { apiSelectors } from 'api'
import { BankType } from 'common/enums'
import { FormProps } from 'settings/features/notificationsSettings/containers/NotificationsSettingsContainer'

export const getNotificationsSettingsValues = createSelector(
  apiSelectors.park.notificationsGet.getNotificationsSettings,
  apiSelectors.park.details.getBankType,
  (notificationsSettings, bankType) => {
    if (!notificationsSettings) {
      return undefined
    }
    const initialValues = {
      transactions_notify: notificationsSettings.transactions_notify,
      insufficient_fuel_deposit:
        notificationsSettings.insufficient_fuel_deposit,
      successful_payment_notify:
        notificationsSettings.park_subscription_settings
          .successful_payment_notify,
      failed_payment_notify:
        notificationsSettings.park_subscription_settings.failed_payment_notify,
    } as FormProps
    if (bankType === BankType.Qiwi) {
      initialValues.email_notifications =
        notificationsSettings.qiwi_settings?.email_notifications
    }
    if (bankType === BankType.Alfa) {
      initialValues.certificate_notify =
        notificationsSettings.certificate_settings?.certificate_notify
    }
    return initialValues
  }
)

export const getNotificationsQiwiSettingsValues = createSelector(
  apiSelectors.park.notificationsGet.getNotificationsQiwiSettings,
  (notificationsQiwiSettings) =>
    notificationsQiwiSettings
      ? {
          min_balance: notificationsQiwiSettings.min_balance
            ? (notificationsQiwiSettings.min_balance / 100).toString()
            : null,
        }
      : undefined
)
