import { ApiActionTypes, ApiState } from 'common/utils/reduxApiFactory'
import { IncomeReport } from 'api/park/income-report/types'

export type GetIncomeReportState = ApiState<IncomeReport>

export type GetIncomeReportActions = ApiActionTypes<IncomeReport>

export enum IncomeReportFormat {
  Json = 'json',
  Csv = 'csv',
}

export type GetIncomeReportParams = {
  date_start: string
  date_end: string
  report_format: IncomeReportFormat
}
