import { createSelector } from 'reselect'
import { State } from 'reducers'
import { Permission } from 'common/enums'

export const getIsFetching = (state: State) =>
  state.api.employee.profile.status === 'loading'

const getData = (state: State) => state.api.employee.profile.data
export const selectEmail = (state: State) =>
  state.api.employee.profile.data?.email

export const getProfile = createSelector(getData, (data) => data)

export const getId = createSelector(getData, (data) => data?.id)

export const getPermissions = createSelector(
  getData,
  (data) => data?.permissions
)

export const selectFuelPermission = createSelector(
  getPermissions,
  (permissions) => permissions?.includes(Permission.Fuel)
)

export const getCanViewAllTransactions = createSelector(
  getPermissions,
  (permissions) => permissions?.includes(Permission.ViewAllTransactions)
)

export const getCanViewParkStatistics = createSelector(
  getPermissions,
  (permissions) => permissions?.includes(Permission.ViewTransactionInfo)
)

export const getCanViewConfirmationTransactions = createSelector(
  getPermissions,
  (permissions) =>
    permissions?.includes(Permission.ViewConfirmationTransactions)
)

export const getCanViewNotCompletedTransactions = createSelector(
  getPermissions,
  (permissions) =>
    permissions?.includes(Permission.ViewNotCompletedTransactions)
)

export const getCanViewTransactionDetails = createSelector(
  getPermissions,
  (permissions) => permissions?.includes(Permission.ViewTransactionDetails)
)

export const getCanProcessTransactions = createSelector(
  getPermissions,
  (permissions) => permissions?.includes(Permission.ProcessTransactions)
)

export const getCanNonConfirmedProcessTransactions = createSelector(
  getPermissions,
  (permissions) =>
    permissions?.includes(Permission.NotConfirmedProcessTransactions)
)

export const getCanViewTariff = createSelector(getPermissions, (permissions) =>
  permissions?.includes(Permission.ViewTariff)
)

export const getCanViewDriverDetails = createSelector(
  getPermissions,
  (permissions) => permissions?.includes(Permission.ViewDriverDetails)
)

export const getCanViewDriverRequisites = createSelector(
  getPermissions,
  (permissions) => permissions?.includes(Permission.ViewDriverRequisites)
)

export const getCanViewParkRequisites = createSelector(
  getPermissions,
  (permissions) => permissions?.includes(Permission.ViewParkRequisites)
)
export const getCanChangeParkRequisites = createSelector(
  getPermissions,
  (permissions) => permissions?.includes(Permission.ChangeParkRequisites)
)

export const getCanChangeAutoTransfer = createSelector(
  getPermissions,
  (permissions) => permissions?.includes(Permission.ChangeAutoTransfer)
)

export const getCanChangePaymentsConditions = createSelector(
  getPermissions,
  (permissions) => permissions?.includes(Permission.ChangePaymentsConditions)
)

export const getCanViewPaymentsSettings = createSelector(
  getPermissions,
  (permissions) => permissions?.includes(Permission.ViewPaymentsSettings)
)

export const getCanViewEmployeeDetails = createSelector(
  getPermissions,
  (permissions) => permissions?.includes(Permission.ViewEmployeeDetails)
)

export const getCanViewLogs = createSelector(getPermissions, (permissions) =>
  permissions?.includes(Permission.ViewLogs)
)

export const getCanChangeEmployee = createSelector(
  getPermissions,
  (permissions) => permissions?.includes(Permission.ChangeEmployee)
)

export const getCanChangePermissions = createSelector(
  getPermissions,
  (permissions) => permissions?.includes(Permission.ChangePermissions)
)
