import { connect } from 'react-redux'

import { State } from 'reducers'
import { apiSelectors } from 'api'
import { handleContracts } from 'drivers/features/driversToolbar/thunks'
import { DriversToolbar } from 'drivers/features/driversToolbar/organisms'
import { MyThunkDispatch } from 'api/reducer'

const mapStateToProps = (state: State) => ({
  isFetching: apiSelectors.park.contracts.getIsFetching(state),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  handleContracts: () => dispatch(handleContracts()),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const DriversToolbarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DriversToolbar)
