import React from 'react'
import { EuiPanel } from '@elastic/eui'
import { EuiPanelProps } from '@elastic/eui/src/components/panel/panel'
import styled from 'styled-components'

interface EnhancedProps {
  isLoading: boolean
  display: string
  maxWidth: number
  maxHeight: number
}

type Props = Partial<EnhancedProps> & EuiPanelProps
type EnhancedComponent = React.FC<Props>

export const EnhancedPanel = styled<EnhancedComponent>(
  ({ isLoading, display, maxWidth, maxHeight, ...rest }) => (
    <EuiPanel {...rest} />
  )
)`
  display: ${({ display }) => display || null};
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : null)};
  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : null)};
  position: relative;
  ${({ isLoading }) =>
    isLoading
      ? `
        &::before {
            top: 0;
            position: absolute;
            content: '';
            width: 100%;
            height: 2px;
            background-color: #006BB4;
            animation: euiBasicTableLoading 1000ms linear infinite;
        }
      `
      : ''}
`
