export enum InviterBonusTypes {
  MoneyForDriving = 'MoneyForDriving',
  ParkCommisionShare = 'ParkCommisionShare',
  FixedBonus = 'FixedBonus',
}

export interface AggregatorAccount {
  name: string
  id: string
}

export enum InviterBonusFrequencies {
  OneTime = 'только один раз',
  Daily = 'ежедневно',
  Weekly = 'каждую неделю (в понедельник)',
  Monthly = 'каждый месяц (1 числа)',
}
