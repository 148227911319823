import { PaymentTransactionParticipant, RequisiteTransaction } from 'api/types'

export enum PaymentStatus {
  AUTHORIZED = 'authorized',
  PENDING = 'pending',
  ACKNOWLEDGED = 'acknowledged',
  PROCESSING = 'processing',
  NOT_COMPLETED = 'not_completed',
  SUCCEEDED = 'succeeded',
  DECLINED = 'declined',
  FAILED = 'failed',
}

export const paymentStatusToString = (status: PaymentStatus): string => {
  switch (status) {
    case PaymentStatus.AUTHORIZED:
      return 'Разрешен'
    case PaymentStatus.PENDING:
      return 'Ожидает подтверждения'
    case PaymentStatus.ACKNOWLEDGED:
      return 'В очереди'
    case PaymentStatus.PROCESSING:
      return 'Обрабатывается'
    case PaymentStatus.NOT_COMPLETED:
      return 'Не завершен'
    case PaymentStatus.SUCCEEDED:
      return 'Завершен'
    case PaymentStatus.DECLINED:
      return 'Отклонен'
    case PaymentStatus.FAILED:
      return 'Ошибка'
    default:
      return status
  }
}

export enum PaymentReason {
  INITIAL_DRIVER = 'initial_driver',
  TAXI_RIDE_DRIVER = 'taxi_ride_driver',
  TAXI_RIDE_AGGREGATOR_COMMISSION = 'taxi_ride_aggregator_commission',
  TAXI_RIDE_PARK = 'taxi_ride_park',
  PARK_DRIVER_WITHDRAW = 'park_driver_withdraw',
  PARK_DRIVER_WITHDRAW_COMMISSION = 'park_driver_withdraw_commission',
  PARK_DEPOSIT_PAY = 'park_deposit_pay',
  PARK_MOZEN_DEPOSIT_PAY = 'park_mozen_deposit_pay',
  PARK_MOZEN_DEPOSIT_WITHDRAW = 'park_mozen_deposit_withdraw',
  PARK_SUBSCRIPTION_PAY = 'park_subscription_pay',
  MARKET_DRIVER_PAY = 'market_driver_pay',
  MARKET_BONUS_PAY = 'market_bonus_pay',
  MARKET_CASH_PAY = 'market_cash_pay',
  MOZEN_BONUS_TOPUP = 'mozen_bonus_topup',
  MOZEN_BONUS_WITHDRAW = 'mozen_bonus_withdraw',
  DRIVER_FUEL_CARD_TOPUP = 'driver_fuel_card_topup',
  TAXOMETER_MANUAL_TOPUP = 'taxometer_manual_topup',
  TAXOMETER_REFERRAL_TOPUP = 'taxometer_referral_topup',
}

export enum PaymentReasonHumanReadable {
  INITIAL_DRIVER = 'Создание аккаунта Mozen',
  TAXI_RIDE_DRIVER = 'Перевод водителю за поездку',
  TAXI_RIDE_AGGREGATOR_COMMISSION = 'Комиссия автопарка и агрегатора',
  TAXI_RIDE_PARK = 'Перевод автопарку за поездку',
  PARK_DRIVER_WITHDRAW = 'Вывод средств водителем',
  PARK_DRIVER_WITHDRAW_COMMISSION = 'Комиссия за вывод средств',
  PARK_DEPOSIT_PAY = 'Оплата депозита автопарком',
  PARK_MOZEN_DEPOSIT_PAY = 'Пополнение депозита парка Мозеном',
  PARK_MOZEN_DEPOSIT_WITHDRAW = 'Списание с депозита парка Мозеном',
  PARK_SUBSCRIPTION_PAY = 'Оплата подписки автопарком',
  MARKET_DRIVER_PAY = 'Оплата водителем в маркетплейсе',
  MARKET_BONUS_PAY = 'Оплата в маркетплейсе бонусами',
  MARKET_CASH_PAY = 'Оплата в маркетплейсе картой',
  MOZEN_BONUS_TOPUP = 'Пополнение бонусного счета',
  MOZEN_BONUS_WITHDRAW = 'Вывод денег с бонусного счета',
  DRIVER_FUEL_CARD_TOPUP = 'Пополнение топливной карты',
  TAXOMETER_MANUAL_TOPUP = 'Пополнение баланса водителя',
  TAXOMETER_REFERRAL_TOPUP = 'Платеж за приглашенного друга',
}

const paymentReasonMap: Record<PaymentReason, PaymentReasonHumanReadable> = {
  initial_driver: PaymentReasonHumanReadable.INITIAL_DRIVER,
  taxi_ride_driver: PaymentReasonHumanReadable.TAXI_RIDE_DRIVER,
  taxi_ride_aggregator_commission:
    PaymentReasonHumanReadable.TAXI_RIDE_AGGREGATOR_COMMISSION,
  taxi_ride_park: PaymentReasonHumanReadable.TAXI_RIDE_PARK,
  park_driver_withdraw: PaymentReasonHumanReadable.PARK_DRIVER_WITHDRAW,
  park_driver_withdraw_commission:
    PaymentReasonHumanReadable.PARK_DRIVER_WITHDRAW_COMMISSION,
  park_deposit_pay: PaymentReasonHumanReadable.PARK_DEPOSIT_PAY,
  park_mozen_deposit_pay: PaymentReasonHumanReadable.PARK_MOZEN_DEPOSIT_PAY,
  park_mozen_deposit_withdraw:
    PaymentReasonHumanReadable.PARK_MOZEN_DEPOSIT_WITHDRAW,
  park_subscription_pay: PaymentReasonHumanReadable.PARK_SUBSCRIPTION_PAY,
  market_driver_pay: PaymentReasonHumanReadable.MARKET_DRIVER_PAY,
  market_bonus_pay: PaymentReasonHumanReadable.MARKET_BONUS_PAY,
  market_cash_pay: PaymentReasonHumanReadable.MARKET_CASH_PAY,
  mozen_bonus_topup: PaymentReasonHumanReadable.MOZEN_BONUS_TOPUP,
  mozen_bonus_withdraw: PaymentReasonHumanReadable.MOZEN_BONUS_WITHDRAW,
  driver_fuel_card_topup: PaymentReasonHumanReadable.DRIVER_FUEL_CARD_TOPUP,
  taxometer_manual_topup: PaymentReasonHumanReadable.TAXOMETER_MANUAL_TOPUP,
  taxometer_referral_topup: PaymentReasonHumanReadable.TAXOMETER_REFERRAL_TOPUP,
}

export const paymentReasonToString = (reason: PaymentReason) =>
  paymentReasonMap[reason]

export enum PaymentOperationType {
  TOPUP = 'topup',
  WITHDRAW = 'withdraw',
}

export const paymentOperationTypeToString = (
  type: PaymentOperationType
): string => {
  switch (type) {
    case PaymentOperationType.TOPUP:
      return 'Доходная'
    case PaymentOperationType.WITHDRAW:
      return 'Расходная'
    default:
      return type
  }
}

export enum PaymentSystem {
  MIR = 'mir',
  VISA = 'visa',
  MASTERCARD = 'mastercard',
}

export const paymentSystemToString = (type: PaymentSystem): string => {
  switch (type) {
    case PaymentSystem.MASTERCARD:
      return 'MasterCard'
    case PaymentSystem.MIR:
      return 'MIR'
    case PaymentSystem.VISA:
      return 'VISA'
    default:
      return type
  }
}

export const renderParticipant = (
  participant: PaymentTransactionParticipant
): string =>
  participant.participant ? participant.participant : 'Водитель без договора'

export const renderBankName = (requisite: RequisiteTransaction): string =>
  requisite.requisite_bank_name || 'Неизвестный банк'

export const renderAccountNumber = (requisite: RequisiteTransaction): string =>
  requisite.requisite

export const renderCardNumber = (requisite: RequisiteTransaction): string =>
  `•••• ${requisite.requisite}`

export const renderAccountOwner = (requisite: RequisiteTransaction): string =>
  requisite.requisite_owner || 'Неизвестный владелец'

export enum PaymentSettingsDefaultNames {
  INDIVIDUAL = 'Индивидуальные',
  DEFAULT = 'Стандартные',
}
