import React from 'react'

import { BorderType } from 'ui-kit'

import { Wrapper } from 'ui-kit/components/ListItem/styles'

interface Props {
  className?: string
  border?: BorderType
  centered?: boolean
  pointer?: boolean
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
  withPadding?: boolean
}

export const ListItem: React.FC<Props> = (props) => {
  const {
    className,
    border,
    centered,
    children,
    onClick,
    pointer,
    withPadding,
  } = props

  return (
    <Wrapper
      className={className}
      border={border}
      pointer={Boolean(pointer)}
      centered={Boolean(centered)}
      onClick={onClick}
      withPadding={withPadding}
    >
      {children}
    </Wrapper>
  )
}
