import styled from 'styled-components'
import { TransparentButton } from 'ui-kit/components/Buttons'

export const LinkButton = styled(TransparentButton)`
  display: inline-flex;
  align-self: baseline;
`

LinkButton.defaultProps = {
  textColor: 'blue',
  fontWeight: 'bold',
}
