import React from 'react'

import { Switch } from 'react-router-dom'
import { ApmRoute } from '@elastic/apm-rum-react'

import { Icon } from 'ui-kit/components'
import { RoutesPath } from 'common/enums'
import { HBox } from 'shared/ui'
import { StepRequisitesContainer } from 'steps/features/stepRequisites'
import { StepPaymentsContainer } from 'steps/features/stepPayments'
import { StepAggregatorsContainer } from 'steps/features/stepAggregators'
import { StepDriversContainer } from 'steps/features/stepDrivers'
import { Completion } from 'steps/features/content/molecules'

import { TStep } from 'steps/types'
import styled from 'styled-components'
import { StepEmployeesContainer } from '../../../stepEmployees'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Container = styled.div`
  padding: ${({ theme }) => theme.paddings.double}px;
`

interface Props {
  current: TStep
}

export const LeftSide: React.FC<Props> = ({ current }) => (
  <Wrapper>
    <Container>
      <Icon name="mozen" />
      <HBox x={0.5} />
      <Completion current={current} />

      <HBox x={3} />
      <Switch>
        <ApmRoute
          exact
          path={RoutesPath.Steps}
          component={StepRequisitesContainer}
        />
        <ApmRoute
          exact
          path={RoutesPath.StepsPayments}
          component={StepPaymentsContainer}
        />

        <ApmRoute
          exact
          path={RoutesPath.StepsAggregators}
          component={StepAggregatorsContainer}
        />
        <ApmRoute
          exact
          path={RoutesPath.StepsDrivers}
          component={StepDriversContainer}
        />
        <ApmRoute
          exact
          path={RoutesPath.StepsEmployees}
          component={StepEmployeesContainer}
        />
      </Switch>
    </Container>
  </Wrapper>
)
