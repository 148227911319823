import { combineReducers } from 'redux'

import { TAggregatorsListAction } from 'settings/features/aggregatorsList/actions'
import aggregatorsListReducer, {
  AggregatorsListState,
} from 'settings/features/aggregatorsList/reducer'

import { TSubscriptionEventsAction } from 'settings/features/subscriptionEvents/actions'
import subscriptionEventsReducer, {
  SubscriptionEventsState,
} from 'settings/features/subscriptionEvents/reducer'

export type TSettingsAction = TAggregatorsListAction | TSubscriptionEventsAction

export interface SettingsState {
  aggregatorsList: AggregatorsListState
  subscriptionEvents: SubscriptionEventsState
}

export const settingsReducer = combineReducers<SettingsState>({
  aggregatorsList: aggregatorsListReducer,
  subscriptionEvents: subscriptionEventsReducer,
})
