import Alert from 'react-s-alert'
import { apiActions } from 'api'
import { MyThunkDispatch, MyThunkResult } from 'api/reducer'
import { ApiCode } from 'common/types/api/codes'
import { toISOFormat } from 'common/utils/dates'
import { ModalsNames } from 'common/enums'
import { modalsActions } from 'shared/modals'
import { FormProps } from 'settings/features/parkCertificate/containers/AddCertificateModalContainer'

export const onSaveParkcertificate: ({
  values,
}: {
  values: FormProps
}) => MyThunkResult<void> = ({ values }) => (dispatch: MyThunkDispatch) => {
  const formData: FormData = new FormData()

  formData.append('alias', values.alias)
  formData.append('password', values.password)
  formData.append('file', values.file)
  const expireDate = toISOFormat(values.expire_date)
  if (expireDate) {
    formData.append('expire_date', expireDate)
  }
  formData.append('basic_pass', values.basic_pass)
  formData.append('basic_user', values.basic_user)
  formData.append('notify_delta', '14')

  return dispatch(
    apiActions.park.certificatePost.handleRequest({
      formData,
    })
  ).then(
    () => {
      dispatch(apiActions.park.certificateGet.handleRequest())
      dispatch(modalsActions.closeModal(ModalsNames.AddCertificate))
      Alert.info('Изменения сохранены')
    },
    (reason) => {
      if (
        reason.response &&
        reason.response.data.code === ApiCode.InputValidationError &&
        reason.response.data.payload
      ) {
        throw reason.response.data.payload
      }
    }
  )
}
