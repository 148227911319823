import { EuiSpacer } from '@elastic/eui'
import { EuiIconEnhanced } from 'elastic-ui/shared/atoms/EnhancedIcon'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  background-color: #f5f7fa;
  border-radius: 4px;
  padding: 12px;
`

interface Props {
  iconType: string | React.ComponentType
}

export const IconBlock: React.FC<Props> = ({ iconType, children }) => (
  <Wrapper>
    <EuiIconEnhanced type={iconType} />
    <EuiSpacer size="s" />
    {children}
  </Wrapper>
)
