import { State } from 'reducers'

export const selectIsConfirmed = (state: State) =>
  state.api.park.getParkAzon.data?.confirmed

export const selectIsSubmitted = (state: State) =>
  state.api.park.getParkAzon.data?.submitted

export const selectContractDate = (state: State) =>
  state.api.park.getParkAzon.data?.park_contract?.contract_date

export const selectContractNumber = (state: State) =>
  state.api.park.getParkAzon.data?.park_contract?.contract_num

export const selectPhone = (state: State) =>
  state.api.park.getParkAzon.data?.phone

export const selectConfirmationError = (state: State) =>
  state.api.park.getParkAzon.data?.confirmation_error

export const selectIsFetching = (state: State) =>
  state.api.park.getParkAzon.status === 'loading'
