import React from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'
import styled from 'styled-components'

export const ListWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const List = styled.ul`
  border-bottom: 0.5px solid #c4c4c4;
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
`

export const ListItemComponent = styled.li`
  margin-right: ${({ theme }) => theme.paddings.double}px;
  &:last-child {
    margin-right: 0;
  }
`

export const Link = styled((props: NavLinkProps) => (
  <NavLink {...props}>{props.children}</NavLink>
))`
  position: relative;
  text-decoration: none;
  display: block;

  color: ${({ theme }) => theme.pallete.black};
  font-size: 16px;
  line-height: 24px;
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;

  &.active {
    color: ${({ theme }) => theme.pallete.blue};

    &:after {
      background-color: ${({ theme }) => theme.pallete.blue};
    }

    p {
      font-weight: 600;
      color: ${({ theme }) => theme.pallete.blue};
    }
  }

  &:after {
    display: block;
    height: 2px;
    border-radius: 2px;
    content: '';
    position: absolute;
    bottom: -1px;
    background-color: transparent;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  .link-list-item {
    border: none;
    min-height: 36px;
    padding: 12px 0;
  }
`
