import { createSelector } from 'reselect'
import { State } from 'reducers'
import { initialState } from 'api/park/payment-settings/delete/reducer'

export const selectParkPaymentSettingsDelete = (state: State) =>
  state.api.park.paymentSettingsDelete || initialState

export const makeSelectIsFetching = () =>
  createSelector(
    selectParkPaymentSettingsDelete,
    (state) => state.status === 'loading'
  )
