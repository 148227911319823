import React from 'react'
import { connect } from 'react-redux'

import { history } from 'store'

import { RoutesPath } from 'common/enums'

import { setServerError as setServerErrorAction } from 'shared/router/actions'
import {
  EngineeringWorksLayout,
  RouterComponent,
  ServerErrorLayout,
} from 'shared/router/components'
import { State } from 'reducers'

interface DispatchProps {
  setServerError: typeof setServerErrorAction
}

const mapStateToProps = (state: State) => ({
  serverError: state.routerState.serverError,
  engineeringWorks: state.routerState.engineeringWorks,
})

type Props = DispatchProps & ReturnType<typeof mapStateToProps>

class Container extends React.Component<Props> {
  componentWillUnmount() {
    const { setServerError } = this.props
    setServerError(false)
  }

  private handleReturnClick = () => {
    const { setServerError } = this.props
    setServerError(false)
    history.push(RoutesPath.MainPage)
  }

  render() {
    const { serverError, engineeringWorks } = this.props

    if (engineeringWorks) {
      return (
        <EngineeringWorksLayout
          title={engineeringWorks.title}
          message={engineeringWorks.message}
        />
      )
    }

    if (serverError) {
      return <ServerErrorLayout onReturnClick={this.handleReturnClick} />
    }

    return <RouterComponent />
  }
}

export const RouterContainer = connect(mapStateToProps, {
  setServerError: setServerErrorAction,
})(Container)
