import { createSelector } from 'reselect'
import { State } from 'reducers'
import { DetailsResponse } from 'api/types'
import { BankType } from 'common/enums'

export const getIsFetching = (state: State) =>
  state.api.park.details.status === 'loading'

const getData = (state: State) => state.api.park.details.data

export const selectIsFuelEnabled = (state: State) =>
  state.api.park.details.data?.fuel_enabled

export const selectYmParams = createSelector(getData, (data) => {
  return {
    parkId: data?.park_id,
    subscriptionStatus: data?.subscription_status,
    bankType: data?.bank_type,
  }
})

export const getDetails: (
  state: State
) => DetailsResponse | null = createSelector(getData, (data) => data || null)

export const getBankType: (
  state: State
) => BankType | null = createSelector(getDetails, (details) =>
  details ? details.bank_type : null
)

export const getIsBankTypeManual = createSelector(
  getBankType,
  (bankType) => bankType === BankType.Manual
)

export const getSubscriptionStatus = createSelector(getDetails, (details) =>
  details ? details.subscription_status : null
)

export const getDepositBalance = createSelector(getDetails, (detials) =>
  detials ? detials.deposit_balance : null
)

export const getAllowNonResident = createSelector(getDetails, (details) =>
  details ? details.allow_non_resident : null
)
