import { createSelector } from 'reselect'
import { State } from 'reducers'

export const getIsFetching = (state: State) =>
  state.api.park.edgvrApiKey.status === 'loading'

const getData = (state: State) => state.api.park.edgvrApiKey.data

export const getKey = createSelector(getData, (data) =>
  data ? data.edgvr_api_key : null
)
