import {
  AggregatorType,
  BankType,
  DriverAutoWithdrawStatus,
  DriverBalanceType,
  EmployeeStatus,
  PaymentOperationType,
  PaymentReason,
  PaymentStatus,
  Permission,
  SubscriptionStatus,
  TariffTransactionStatus,
  TariffTransactionType,
} from 'common/enums'
import { ApiCode } from 'common/types/api/codes'

export type TApiError<RequestParams = never> = {
  code: ApiCode
  message: string
  payload: RequestParams | null
  debug?: string | null
  title?: string | null
}

export type TApiStatus = null | 'loading' | 'success' | 'failure'

type ApiCurrency = 'RUR'

export interface ApiMoney {
  amount: number
  currency: ApiCurrency
}

export interface ContractUpload {
  [key: string]: string | null
  aggregator_account: string
  contract_date: string
  contract_number: string
  driver_name_aggregator: string
  first_name: string
  last_name: string
  second_name: string | null
  phone: string
}

export interface DriversContractsUploadResponse {
  contracts: ContractUpload[]
}

export interface DriverBalance {
  type: DriverBalanceType
  amount: number
  currency: string
  updated_date: string
}

export interface DriverContract {
  contract_number: string
  contract_date: string
  first_name: string
  last_name: string
  second_name: string | null
  contract_suffix: string | null
  is_non_resident?: boolean
}

export interface DriverAccount {
  bank_account: string
  bank_bik: string
  bank_correspondent_account: string
  bank_name: string
  owner_name: string | null
  user_id: string
  is_deleted: boolean
}

export interface DriverCard {
  pan: string
  user_id: string
  is_deleted: boolean
}

export interface DriversByIdGetResponse {
  id: string
  user_id: string | null
  name: string
  last_ride_date: string | null
  contract: DriverContract | null
  aggregator_account: string
  aggregator_type: string
  aggregator_url: string
  phone: string
  balances?: DriverBalance[]
  auto_withdraw_status: DriverAutoWithdrawStatus
  security_check_passed: boolean
  withdraw_restricted: boolean
  requisites: {
    accounts?: DriverAccount[]
    cards?: DriverCard[]
  }
}

export interface PaymentConfirmationStatusResponse {
  auto_withdraw_status: DriverAutoWithdrawStatus
}

export interface WithdrawRestrictionResponse {
  withdraw_restriction_status: boolean
}

export interface ProfileResponse {
  id: string
  park_creator: boolean
  full_name: string | null
  email: string | null
  phone: string
  position: string | null
  permissions: Permission[]
  status: EmployeeStatus
}

export interface EmployeesByIdGetResponse {
  id: string
  park_creator: boolean
  full_name: string | null
  position: string | null
  email?: string | null
  phone?: string | null
  status: EmployeeStatus
  blocked_at: string | null
  user_id: string
  park_id: string
  permissions: Permission | null
}

export interface EmployeesByIdPatchResponse extends ProfileResponse {
  blocked_at: string
  user_id: string
  park_id: string
}

export interface Employee {
  id: string
  park_creator: boolean
  full_name: string
  position: string
  email: string | null
  phone: string | null
  status: EmployeeStatus
  blocked_at: string | null
}

export interface EmployeesGetResponse {
  employees: Employee[]
}

export interface EmployeesLogs {
  created_at: string
  employee_id: string
  title: string
  type: string
  extra?: {
    employee_name?: string
    position?: string
  }
}

export interface EmployeesLogsResponse {
  total_amount: number
  logs: EmployeesLogs[]
}

export interface AggregatorByIdYandex {
  db: string
  api_client_id: string
  account: string
  api_key: string
}

export interface AggregatorByIdCitymobil {
  account: string
  api_key: string
  park_id_aggregator: string
}

export interface AggregatorByIdGett {
  api_password: string
  admin_login: string
  api_login: string
  admin_password: string
}

export interface AggregatorByIdDiDi {
  client_id: string
  client_secret: string
}

export interface AggregatorByIdGetResponse {
  [AggregatorType.Yandex]?: AggregatorByIdYandex
  [AggregatorType.Citymobil]?: AggregatorByIdCitymobil
  [AggregatorType.Gett]?: AggregatorByIdGett
  [AggregatorType.DiDi]?: AggregatorByIdDiDi
}

interface AggregatorMinimum {
  id: string
  account: string
}

export interface Aggregator extends AggregatorMinimum {
  type: AggregatorType
}

export interface AggregatorsResponse {
  [AggregatorType.Yandex]: AggregatorMinimum[]
  [AggregatorType.Citymobil]: AggregatorMinimum[]
  [AggregatorType.DiDi]: AggregatorMinimum[]
}

export interface AllowAutoTransferGetResponse {
  allow_auto_transfer: boolean
}

export interface BalanceLogTransaction {
  [key: string]: any
  id: string
  title: string
  comment: string | null
  registered_at: string
  status: TariffTransactionStatus
  operation_type: TariffTransactionType
  transaction_amount: ApiMoney
  account_balance: ApiMoney
}

export interface BalanceLogResponse {
  total_amount: number
  transactions: BalanceLogTransaction[]
}

export interface CalculateTopupGiftResponse {
  gift_amount: number
}

export interface CertificateGetResponse {
  expires_at: Date | string
}

export interface ContractsResponse {
  edgvrs: {
    contract_date: string
    contract_number: number
    contract_owner: string
  }[]
}

export interface DepositResponse {
  balance: number
}

export interface DetailsResponse {
  bank_account: string
  bank_name: string
  bank_type: BankType
  bik: string
  correspondent_account: string
  deposit_balance: number
  inn: string
  kpp: string
  name: string
  subscription_status: SubscriptionStatus
  allow_non_resident: boolean
  fuel_enabled: boolean
  park_id: string
}

export interface EdgvrApiKeyResponse {
  edgvr_api_key: string
}

export interface NotificationsGetResponse {
  contact_email: string
  insufficient_fuel_deposit: boolean
  telegram_notifications_enabled: boolean
  transactions_notify: boolean
  qiwi_settings: {
    email_notifications: boolean
    min_balance: number
  }
  certificate_settings: {
    certificate_notify: boolean
  }
  park_subscription_settings: {
    failed_payment_notify: boolean
    successful_payment_notify: boolean
    upcoming_payment_notify: boolean
  }
}

export interface PaymentSettingsGetResponse {
  first_withdraw_comission: boolean
  free_withdraws_count: number
  max_withdraw_amount: number | null
  min_balance: number
  min_commission: number
  min_withdraw_amount: number
  non_resident_withdraw_commission: number
  withdraw_commission: number
  withdraw_restricted: boolean
  week_withdraw_limit: number | null
  day_withdraw_limit: number | null
}

export interface QiwiDetailsResponse {
  balance: ApiMoney
  min_balance: ApiMoney
  is_below_limit: boolean
}

export interface SubscriptionPaymentsEvent {
  [key: string]: any
  created_at: string
  is_paid: boolean
  park_id: string
  park_revenue: ApiMoney
  payment_id: string
  payment_amount: ApiMoney
  plan_id: string
  plan_name: string
  subscription_id: string
  week_number: number
}

export interface SubscriptionPaymentsResponse {
  payments_total: number
  payments: SubscriptionPaymentsEvent[]
}

export interface Tariff {
  money_turnover: ApiMoney
  price: ApiMoney
}

export interface SubscriptionSummaryResponse {
  current_plan_id: string
  current_plan_name: string
  future_plan_id: string | null
  future_plan_name: string | null
  status: SubscriptionStatus
  next_status: SubscriptionStatus | null
  payment_date: string | null
  park_turnover: ApiMoney
  manager_email: string
  tariff: Tariff
  tariffs: Tariff[]
  auto_topup_deposit: boolean
  criterion_type: CriterionType
  is_trial: boolean
  trial_ends_at?: string
  rate_value: number | null
}

export type CriterionType =
  | 'fixed_price'
  | 'withdrawn_amount'
  | 'active_drivers'
  | 'park_revenue'
  | 'park_cashless_revenue'

export interface TechnicalSupportContactsGetResponse {
  email: string | null
  phone: string | null
}

export interface TopupDepositPostResponse {
  amount: number
  gift: number
}

export interface PaymentTransactionParticipant {
  participant: string
  contract?: boolean
  driver_id: string
  park_id: string
}

export enum RequisiteTransactionType {
  Bank = 'bank',
  Card = 'card',
}

export type RequisiteTransaction = {
  is_deleted: boolean
  requisite: string
  requisite_bank_name: string | null
  requisite_id: string | null
  requisite_owner: string | null
  requisite_type: RequisiteTransactionType | null
}

export interface PaymentTransaction {
  [key: string]: any
  last_payment_order_request: string
  created_at: string
  status: PaymentStatus
  amount: number
  operation_type: PaymentOperationType
  legs: {
    to_participant: PaymentTransactionParticipant
    from_participant: PaymentTransactionParticipant
  }
  reason: PaymentReason
  id: string
  is_manageable: boolean
  currency: 'RUR'
  requisite: RequisiteTransaction | null
}

export interface PaymentTransactionsResponse {
  transactions: PaymentTransaction[]
  total_amount: number
}

export type PaymentSettings = {
  id: string
  name: string
  is_group: boolean
  is_park_default: boolean
  first_withdraw_commission: boolean
  auto_withdraw: boolean
  withdraw_commission: number
  min_commission: number
  min_withdraw_amount: number
  max_withdraw_amount: number | null
  free_withdraws_count: number
  min_balance: number
  non_resident_withdraw_commission: number
  non_resident_min_commission: number
  day_withdraw_limit: number | null
  week_withdraw_limit: number | null
  fair_rides: number
  withdraw_transactions_for_auto_withdraw: number
  fuel_card_auto_topup: boolean
  fuel_card_max_topup_amount: number
  fuel_card_min_topup_amount: number
  fuel_card_topup_restricted: boolean
  user_counter?: number
  withdraw_restricted?: boolean
}

export type StatisticsFees = {
  date: string
  mozen_withdraw_fees_total: number
  ride_fees_total: number
}

export type StatisticsFeesSummary = {
  mozen_withdraw_fees_total: number
  mozen_withdraw_total: number
  fees_total: number
  ride_fees_total: number
}

export type StatisticsFeesParams = {
  date_start: string | null
  date_end: string | null
}

export type StatisticsWeekdayWithdraw = {
  total_amount: number
  avg_amount: number
  count: number
  weekday: number
}

export type StatisticsWeekdayWithdrawParams = {
  date_start: string | null
  date_end: string | null
  park_aggregator_id?: string
}

export type PaginationLimit = 20 | 50

export type PaginationParams = {
  limit: PaginationLimit
  offset?: number
}

export enum SortDirections {
  DESC = 'desc',
  ASC = 'asc',
}

export type SortParams<T extends string> = {
  sort_column?: T
  sort_direction?: SortDirections
}

export type SearchParams = {
  search?: string
}

export type Pagination = {
  total: number
  offset?: number
  limit?: PaginationLimit
}

export enum DriverWorkStatus {
  working = 'working',
  fired = 'fired',
}

export type Driver = {
  aggregator_type: string
  has_payment_details: boolean
  id: string
  name: string
  park_account: string
  park_id: string
  phone: string
  user_id: string
  work_status: DriverWorkStatus
  is_new?: boolean | null
  has_contract?: boolean | null
}
