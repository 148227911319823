import React, { useEffect } from 'react'

import { Col, HBox, Loader } from 'shared/ui'
import { NotificationsContactsContainer } from 'settings/features/notificationsContacts'
import { NotificationsSettingsContainer } from 'settings/features/notificationsSettings'
import { NotificationsTelegramBot } from 'settings/features/notificationsTelegramBot'
import { NotificationsNotAvailable } from 'settings/features/notificationsTab/molecules'
import { ContainerProps } from 'settings/features/notificationsTab/containers/NotificationsTabContainer'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.paddings.main}px;
`

export const NotificationsTab: React.FC<ContainerProps> = ({
  isFetching,
  isNotificationsAvailable,
  onDidMount,
}) => {
  useEffect(() => {
    onDidMount()
  }, [onDidMount])
  return isFetching ? (
    <Loader />
  ) : (
    <Wrapper>
      <Col percentage={50}>
        {isNotificationsAvailable ? (
          <>
            <NotificationsContactsContainer />
            <HBox />
            <NotificationsSettingsContainer />
            <HBox />
            <NotificationsTelegramBot />
          </>
        ) : (
          <NotificationsNotAvailable />
        )}
      </Col>
    </Wrapper>
  )
}
