import React from 'react'
import styled from 'styled-components'

interface OverlayProps {
  backgroundColor?: string
}

const Overlay = styled.div<OverlayProps>`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: ${({ backgroundColor }) => backgroundColor};
`

// To make ESLint shut up
const Wrapper = styled.span``

interface Props {
  trigger: React.ComponentType
  isOpen?: boolean
  overlayColor?: string
  onTriggerClick?: () => void
  onClickAway?: () => void
}

export const ContentToggle: React.FC<Props> = ({
  children,
  trigger: Trigger,
  isOpen,
  onTriggerClick,
  onClickAway,
  overlayColor,
}) => {
  const TriggerChildrenComponent: React.FC = () => {
    return (
      <span style={{ position: 'relative' }}>
        <Wrapper onClick={onTriggerClick}>
          <Trigger />
        </Wrapper>
        {isOpen ? (
          <span style={onClickAway && { position: 'absolute', zIndex: 1 }}>
            {children}
          </span>
        ) : null}
      </span>
    )
  }

  return (
    <>
      {isOpen && onClickAway ? (
        <Overlay
          onClick={onClickAway}
          backgroundColor={overlayColor}
          data-testid="overlay"
        />
      ) : null}
      <TriggerChildrenComponent />
    </>
  )
}
