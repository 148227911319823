import React from 'react'
import axios from 'axios'
import { InjectedFormProps, reduxForm, SubmissionError } from 'redux-form'

import { ApiCode } from 'common/types/api/codes'

import EmailConfirmationLostComponent from 'auth/emailConfirmationLost/components/EmailConfirmationLostComponent'

interface FormProps {
  email: string | null
}

interface TimerOutput {
  minutes: number
  seconds: number
  total: number
}

interface LocalState extends TimerOutput {
  isSubmitLoading: boolean
}

const TIMER_VALUE = 1

class EmailConfirmationLostContainer extends React.Component<
  InjectedFormProps<FormProps>,
  LocalState
> {
  private timer: number | undefined

  constructor(props: InjectedFormProps<FormProps>) {
    super(props)

    this.state = {
      isSubmitLoading: false,
      seconds: 0,
      minutes: 0,
      total: 0,
    }
  }

  componentDidMount() {
    const { initialize } = this.props
    if (localStorage.getItem('registration-email')) {
      initialize({
        email: localStorage.getItem('registration-email'),
      })
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  private deadlineTimer = (time: number): number => {
    const curTime: number = Date.parse(new Date().toString())

    return Date.parse(new Date(curTime + time * 60 * 1000).toString())
  }

  private calcTime = (endTime: number): TimerOutput => {
    const time: number = endTime - Date.parse(new Date().toString())

    const s: number = Math.floor(time / 1000)

    const m: number = Math.floor(s / 60)

    return {
      seconds: s % 60,
      minutes: m % 60,
      total: time,
    }
  }

  private resetTimer = (): void => {
    clearInterval(this.timer)

    this.setState({ minutes: 0, seconds: 0, total: 0 })
  }

  private initializeTimer = (endTime: number): void => {
    const { minutes, seconds, total } = this.calcTime(endTime)

    this.setState({ seconds, minutes, total })
  }

  private updateTimer = (endTime: number): void => {
    this.initializeTimer(endTime)

    this.timer = window.setInterval(() => {
      const { minutes, seconds, total } = this.calcTime(endTime)

      if (total <= 0) {
        this.resetTimer()
      } else {
        this.setState({ seconds, minutes, total })
      }
    }, 1000)
  }

  private handleSubmit = async (values: FormProps): Promise<void> => {
    const { total } = this.state
    if (total <= 0) {
      this.setState({ isSubmitLoading: true })

      try {
        await axios.post('/auth/1/signup/email/retry', values)

        this.updateTimer(this.deadlineTimer(TIMER_VALUE))
      } catch (error) {
        if (
          error.response &&
          error.response.data.code === ApiCode.UserNotFound
        ) {
          throw new SubmissionError({
            email: 'Email не зарегистрирован в системе',
          })
        }
      } finally {
        this.setState({ isSubmitLoading: false })
      }
    }
  }

  render() {
    const { handleSubmit } = this.props
    const { isSubmitLoading, seconds, minutes, total } = this.state

    return (
      <EmailConfirmationLostComponent
        isSubmitLoading={isSubmitLoading}
        minutes={minutes}
        seconds={seconds}
        total={total}
        onSubmit={handleSubmit(this.handleSubmit)}
      />
    )
  }
}

const Form = reduxForm<FormProps>({ form: 'NotReceivedLetterForm' })(
  EmailConfirmationLostContainer
)

export default Form
