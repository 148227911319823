import { createSelector } from 'reselect'
import { State } from 'reducers'

export const getIsFetching = (state: State) =>
  state.api.park.topupDepositPost.status === 'loading'

export const getStatus = (state: State) =>
  state.api.park.topupDepositPost.status

const getData = (state: State) => state.api.park.topupDepositPost.data

export const getTopupSum = createSelector(getData, (data) =>
  data ? data.amount + data.gift : 0
)
