import { createSelector } from 'reselect'
import { State } from 'reducers'
import { DriverBalanceType } from 'common/enums'
import { isoToDate } from 'common/utils/dates'

export const getIsFetching = (state: State) =>
  state.api.driversById.get.status === 'loading'

const getData = (state: State) => state.api.driversById.get.data

export const getHasUserId = createSelector(getData, (data) =>
  Boolean(data && data.user_id)
)

export const getUserId = createSelector(getData, (data) => data && data.user_id)

export const getId = createSelector(getData, (data) => (data ? data.id : null))

export const getInfo = createSelector(getData, (data) => data || null)

export const getContractPreview = createSelector(getData, (data) =>
  data && data.contract
    ? {
        contract_number: data.contract.is_non_resident
          ? data.contract.contract_number + data.contract.contract_suffix
          : data.contract.contract_number,
        contract_date: data.contract.contract_date,
      }
    : null
)

export const getDriverName = createSelector(getData, (data) =>
  data ? data.name : null
)

export const getContractFormValues = createSelector(getData, (data) =>
  data && data.contract
    ? {
        ...data.contract,
        contract_date: isoToDate(data.contract.contract_date),
      }
    : undefined
)

export const getBalanceParkAmount = createSelector(getData, (data) => {
  if (!data) return null
  if (!data.balances) return null

  const balance = data.balances.find(
    (item) => item.type === DriverBalanceType.Park
  )

  if (balance !== undefined) {
    return balance.amount
  }

  return null
})

export const getBalanceBonusAmount = createSelector(getData, (data) => {
  if (!data) return null
  if (!data.balances) return null

  const balance = data.balances.find(
    (item) => item.type === DriverBalanceType.Bonus
  )

  if (balance !== undefined) {
    return balance.amount
  }

  return null
})

export const getSecurityCheckPassed = createSelector(getData, (data) =>
  data ? data.security_check_passed : false
)

export const getWithdrawRestricted = createSelector(getData, (data) =>
  data ? data.withdraw_restricted : false
)

export const getAutoWithdrawStatus = createSelector(getData, (data) =>
  data ? data.auto_withdraw_status : null
)

export const getAccounts = createSelector(getData, (data) =>
  data && data.requisites && data.requisites.accounts
    ? data.requisites.accounts.filter((account) => !account.is_deleted)
    : []
)

export const getHasAccounts = createSelector(
  getAccounts,
  (accounts) => !!accounts.length
)

export const getCards = createSelector(getData, (data) => {
  if (!data) return []
  if (!data.requisites) return []
  if (!data.requisites.cards) return []

  return data.requisites.cards.filter((card) => !card.is_deleted)
})

export const getHasCards = createSelector(getCards, (cards) => !!cards.length)
