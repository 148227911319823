import React from 'react'
import { RouteComponentProps, Switch } from 'react-router-dom'
import { ApmRoute } from '@elastic/apm-rum-react'

import { RoutesPath } from 'common/enums'

import { SignUpContainer } from 'auth/features/signUp'

import SignInContainer from 'auth/signIn/containers/SignInContainer'
import SignUpSuccessComponent from 'auth/signUpSuccess/components/SignUpSuccessComponent'
import { SignOutContainer } from 'auth/signOut/containers/SignOutContainer'
import EmailConfirmationLost from 'auth/emailConfirmationLost/containers/EmailConfirmationLostContainer'

export const Auth: React.FC<RouteComponentProps> = () => (
  <Switch>
    <ApmRoute exact path={RoutesPath.SignIn} component={SignInContainer} />
    <ApmRoute exact path={RoutesPath.SignUp} component={SignUpContainer} />
    <ApmRoute
      exact
      path={RoutesPath.SignUpSuccess}
      component={SignUpSuccessComponent}
    />
    <ApmRoute exact path={RoutesPath.SignOut} component={SignOutContainer} />
    <ApmRoute
      exact
      path={RoutesPath.EmailConfirmationLost}
      component={EmailConfirmationLost}
    />
  </Switch>
)
