import { createSelector } from 'reselect'
import { State } from 'reducers'

export const getIsFetching = (state: State) =>
  state.api.employees.logs.status === 'loading'

const getData = (state: State) => state.api.employees.logs.data

export const getLogs = createSelector(getData, (data) =>
  data ? data.logs : []
)

export const getTotalAmount = createSelector(getData, (data) =>
  data ? data.total_amount : null
)
