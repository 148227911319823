import { createSelector } from 'reselect'
import { State } from 'reducers'
import { initialState } from 'api/park/statistics/weekday-withdraw/reducer'
import { currencyToBase } from 'common/utils/formatters'
import { localizeWeekday } from 'common/utils/dates'

export const selectStatisticsWeekdayWithdraw = (state: State) =>
  state.api.park.statisticsWeekdayWithdraw || initialState

export const makeSelectIsFetching = () =>
  createSelector(
    selectStatisticsWeekdayWithdraw,
    (statisticsWeekdayWithdrawState) =>
      statisticsWeekdayWithdrawState.status === 'loading'
  )

export const makeSelectCharData = () =>
  createSelector(
    selectStatisticsWeekdayWithdraw,
    (statisticsWeekdayWithdrawState) => {
      if (!statisticsWeekdayWithdrawState.data) return []

      return statisticsWeekdayWithdrawState.data.map((item) => ({
        ...item,
        amount: item.total_amount / 100,
        label_weekday: localizeWeekday(item.weekday),
        avg_amount: currencyToBase(item.avg_amount),
        total_amount: currencyToBase(item.total_amount),
      }))
    }
  )
