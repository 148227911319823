export const list = {
  requisites: {
    number: 1,
    label: 'Реквизиты',
    tooltip:
      'Все данные о финансовых операциях находятся в разделе Платежи. Настраивайте выплаты, подтверждайте транзакции, решайте вопросы.',
  },
  aggregators: {
    number: 2,
    label: 'Агрегаторы',
    tooltip:
      'Все данные о финансовых операциях находятся в разделе Платежи. Настраивайте выплаты, подтверждайте транзакции, решайте вопросы.',
  },
  payments: {
    number: 3,
    label: 'Платежи',
    tooltip:
      'Все данные о финансовых операциях находятся в разделе Платежи. Настраивайте выплаты, подтверждайте транзакции, решайте вопросы.',
  },
  drivers: {
    number: 4,
    label: 'Водители',
    tooltip:
      'Все данные о финансовых операциях находятся в разделе Платежи. Настраивайте выплаты, подтверждайте транзакции, решайте вопросы.',
  },
  employees: {
    number: 5,
    label: 'Профиль',
    tooltip:
      'Все данные о финансовых операциях находятся в разделе Платежи. Настраивайте выплаты, подтверждайте транзакции, решайте вопросы.',
  },
}
