import styled, { css } from 'styled-components'

interface Props {
  centered?: boolean
  border?: string
  pointer?: boolean
  onClick?: any
  withPadding?: boolean
}

export const Wrapper = styled('div')<Props>`
  padding: ${({ withPadding = true }) => (withPadding ? '3.5px 15.5px' : 0)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 56px;
  box-sizing: border-box;
  border: 0.5px solid transparent;

  ${(props) =>
    (props.pointer || !!props.onClick) &&
    `
    cursor: pointer;

    > * {
      cursor: pointer;
    }
  `}

  ${(props) =>
    props.centered &&
    `
    justify-content: center;
    text-align: center;
  `}

  ${(props) =>
    props.border === 'table' &&
    `
    min-height: 55.5px;
    border-bottom: none;
  `}

  ${(props) =>
    (props.border === 'top' ||
      props.border === 'icon-top' ||
      props.border === 'left-top' ||
      props.border === 'margin-top' ||
      props.border === 'icon-margin-top' ||
      props.border === 'both' ||
      props.border === 'icon-both' ||
      props.border === 'left-both' ||
      props.border === 'margin-both' ||
      props.border === 'icon-margin-both') &&
    css`
      border-top: 0.5px solid ${({ theme }) => theme.pallete.gray3};
    `}

  ${(props) =>
    (props.border === 'bottom' ||
      props.border === 'icon-bottom' ||
      props.border === 'left-bottom' ||
      props.border === 'margin-bottom' ||
      props.border === 'icon-margin-bottom' ||
      props.border === 'both' ||
      props.border === 'icon-both' ||
      props.border === 'left-both' ||
      props.border === 'margin-both' ||
      props.border === 'icon-margin-both') &&
    css`
      border-bottom: 0.5px solid ${({ theme }) => theme.pallete.gray3};
    `}

  ${(props) =>
    (props.border === 'margin-top' ||
      props.border === 'margin-bottom' ||
      props.border === 'margin-both' ||
      props.border === 'icon-margin-top' ||
      props.border === 'icon-margin-bottom' ||
      props.border === 'icon-margin-both') &&
    `
    margin: 0 16px;
    padding: 3.5px 0;
  `}

  ${(props) =>
    (props.border === 'icon-top' ||
      props.border === 'icon-bottom' ||
      props.border === 'icon-both' ||
      props.border === 'icon-margin-top' ||
      props.border === 'icon-margin-both') &&
    `
    margin-left: 56px;

    > *:first-child {
      margin-left: -56px;
    }
  `}

  ${(props) =>
    (props.border === 'icon-margin-top' ||
      props.border === 'icon-margin-both') &&
    `
    > *:first-child {
      margin-left: -40px;
    }
  `}
`
