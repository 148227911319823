/* eslint-disable react/no-unused-prop-types */
import React from 'react'
import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle,
  EuiBadge,
  EuiBasicTable,
  EuiLoadingContent,
  EuiCallOut,
  EuiHealth,
  EuiRadioGroup,
  EuiRadioGroupOption,
  EuiSwitch,
} from '@elastic/eui'

import styled from 'styled-components'
import { EuiIconEnhanced } from 'elastic-ui/shared/atoms/EnhancedIcon'
import numberToCurrencyString from 'common/utils/numberToCurrencyString'
import RenderGate from 'elastic-ui/shared/atoms/RenderGate'
import { Modal } from 'elastic-ui/shared/molecules/Modal/Modal'
import EuiFormEnhanced from 'elastic-ui/shared/atoms/EuiFormEnhanced'
import EuiFormRowEnhanced from 'elastic-ui/shared/atoms/EuiFormRowEnhanced'
import EuiFieldNumberEnhanced from 'elastic-ui/shared/atoms/EuiFieldNumberEnhanced'
import {
  BankType,
  PaymentStatus,
  SubscriptionStatus,
  TariffTransactionStatus,
  tariffTransactionStatusToString,
} from 'common/enums'
import { TariffProps } from 'elastic-ui/tariff/containers/TariffContainer'
import {
  Criteria,
  CriteriaWithPagination,
} from '@elastic/eui/src/components/basic_table/basic_table'
import moment from 'moment'
import MozenIcon from '../../shared/icons/mozen.svg'

const WalletIcon = styled(EuiIconEnhanced).attrs({
  type: 'wallet',
})`
  width: 56px;
  height: 56px;
`

const ReceiptIcon = styled(EuiIconEnhanced).attrs({
  type: 'receipt',
})`
  width: 40px;
  height: 40px;
`

const Balance = styled(EuiText)`
  font-size: 28px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: -1.12 px;
`

const TopupButton = styled(EuiButton).attrs({
  fill: true,
  color: 'primary',
})`
  padding: 8px 26px;
`

const RightContainer = styled.div`
  width: 240px;
`

const SubscriptionPricePanel = styled(EuiPanel)`
  background-color: #e6f0f8;
  background-image: url(${MozenIcon});
  background-repeat: no-repeat;
  background-position: top right;
  min-height: 111px;
`

const SupportPanel = styled(EuiPanel)`
  background-color: #e6f2f1;
`

type TopupMethod = 'auto' | 'download'

interface Transaction {
  date: Date
  reason: string
  status: string
  statusColor: string
  amount: number
}

const AmountLoader = styled(EuiLoadingContent).attrs({
  lines: 1,
})`
  margin: 0;
  & > * {
    width: 70px;
    height: 1rem;
  }
`

const TopupMethodRadioGroup = styled(EuiRadioGroup)`
  padding: 0;
`

const bankTypeToHumanReadable: Record<BankType, string> = {
  alfa: 'Альфа-Банк',
  alfa_e2c: 'Альфа-Банк',
  azon: 'Альфа-Банк',
  manual: '',
  modul: 'Модульбанк',
  qiwi: 'Qiwi',
  tinkoff: 'Тинькофф Банк',
}

const TariffPage: React.FC<TariffProps> = ({
  isBalanceLoading,
  isSubscriptionLoading,
  isDepositTransactionsLoading,
  isBankTypeManual,
  balance,
  subscriptionPrice,
  subscriptionStatus,
  depositTransactions,
  depositTransactionsTotalAmount,
  onPageChange,
  onDepositTopup,
  onPaymentStatementDownload,
  bankType,
  isAutoTopupEnabled,
  setAutoTopup,
}) => {
  const [isTopupModalOpen, setIsTopupModalOpen] = React.useState(false)
  const openTopupModal = () => setIsTopupModalOpen(true)
  const closeTopupModal = () => setIsTopupModalOpen(false)

  const [isSuccessModalOpen, setIsSuccessModalOpen] = React.useState(false)
  const openSuccessModal = () => setIsSuccessModalOpen(true)
  const closeSuccessModal = () => setIsSuccessModalOpen(false)

  const [isTopupLoading, setIsTopupLoading] = React.useState(false)

  const [pageIndex, setPageIndex] = React.useState(0)

  const [selectedTopupMethod, setSelectedTopupMethod] = React.useState<
    TopupMethod
  >('download')
  /* (isBankTypeManual ? 'download' : 'auto') */

  const topupOptions: EuiRadioGroupOption[] = [
    /* {
      id: 'auto',
      label: `Автоматически со счета в ${
        bankTypeToHumanReadable[bankType as BankType]
      }`,
    }, */
    { id: 'download', label: 'Скачать счет на оплату' },
  ]

  const [topupAmount, setTopupAmount] = React.useState(2500)

  const [isAutoTopupChecked, setIsAutoTopupChecked] = React.useState(
    isAutoTopupEnabled
  )

  React.useEffect(() => {
    setIsAutoTopupChecked(isAutoTopupEnabled)
  }, [isAutoTopupEnabled])

  const isSubscriptionBlocked =
    subscriptionStatus === SubscriptionStatus.Blocked
  const isSubscriptionActive = subscriptionStatus === SubscriptionStatus.Active
  const isSubscriptionUnpaid = subscriptionStatus === SubscriptionStatus.Unpaid
  const isSubscriptionSuspended =
    subscriptionStatus === SubscriptionStatus.Suspended

  const [formErrors, setFormErrors] = React.useState({
    amount: null as null | string,
  })

  const balanceCurrencyString = numberToCurrencyString(balance, {
    withCurrencySign: true,
  })

  const subscriptionPriceCurrencyString = numberToCurrencyString(
    subscriptionPrice,
    {
      withCurrencySign: true,
    }
  )

  const handleTopup = async (amount: number) => {
    setIsTopupLoading(true)
    try {
      if (selectedTopupMethod === 'auto') {
        await onDepositTopup(amount)
      } else {
        await onPaymentStatementDownload(amount)
      }
      closeTopupModal()
      setIsTopupLoading(false)
    } catch (e) {
      setIsTopupLoading(false)
      throw new Error(e)
    }

    openSuccessModal()
  }

  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem grow={10}>
          <EuiPanel paddingSize="l" grow={false}>
            <EuiFlexGroup alignItems="center">
              <EuiFlexItem grow={10}>
                <EuiFlexGroup alignItems="center">
                  <EuiFlexItem grow={false}>
                    <WalletIcon />
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiFlexGroup direction="column" gutterSize="none">
                      <EuiFlexItem>
                        <EuiText size="s" color="subdued">
                          Ваш баланс
                        </EuiText>
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <Balance>
                          {isBalanceLoading ? (
                            <AmountLoader />
                          ) : (
                            balanceCurrencyString
                          )}
                        </Balance>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>

              <EuiFlexItem>
                <TopupButton
                  onClick={openTopupModal}
                  isDisabled={isSubscriptionBlocked || isSubscriptionLoading}
                >
                  Пополнить баланс
                </TopupButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>

          <EuiSpacer />

          <EuiPanel grow={false}>
            <header>
              <EuiFlexGroup alignItems="center">
                <EuiFlexItem>
                  <EuiTitle size="s">
                    <h3>История</h3>
                  </EuiTitle>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <RenderGate isOpen={!isSubscriptionLoading}>
                    <RenderGate isOpen={isSubscriptionBlocked}>
                      <EuiBadge color="black">Подписка заблокирована</EuiBadge>
                    </RenderGate>
                    <RenderGate isOpen={isSubscriptionUnpaid}>
                      <EuiBadge color="danger">Подписка не оплачена</EuiBadge>
                    </RenderGate>
                    <RenderGate isOpen={isSubscriptionActive}>
                      <EuiBadge color="secondary">Подписка активна</EuiBadge>
                    </RenderGate>
                    <RenderGate isOpen={isSubscriptionSuspended}>
                      <EuiBadge color="black">Подписка приостановлена</EuiBadge>
                    </RenderGate>
                  </RenderGate>
                </EuiFlexItem>
              </EuiFlexGroup>
            </header>

            <EuiSpacer size="l" />

            <EuiBasicTable
              columns={[
                { field: 'date', name: 'Дата' },
                { field: 'reason', name: 'Причина' },
                {
                  name: 'Сумма',
                  // eslint-disable-next-line react/display-name
                  render: ({ amount }: Transaction) => (
                    <EuiText>{`${numberToCurrencyString(-amount, {
                      withCurrencySign: true,
                    })}`}</EuiText>
                  ),
                },
                {
                  name: 'Статус',
                  // eslint-disable-next-line react/display-name
                  render: ({ status, statusColor }: Transaction) => (
                    <EuiHealth color={statusColor}>{status}</EuiHealth>
                  ),
                },
              ]}
              items={
                isDepositTransactionsLoading
                  ? []
                  : depositTransactions.map((item) => ({
                      amount: item.payment_amount.amount / 100,
                      // fuck safari
                      date: new Date(moment(item.created_at).toISOString()),
                      reason: 'Списание подписки',
                      status: item.is_paid ? 'Завершен' : 'Ошибка',
                      statusColor: item.is_paid ? 'secondary' : 'danger',
                    }))
              }
              loading={isDepositTransactionsLoading}
              noItemsMessage={
                isDepositTransactionsLoading
                  ? 'Загрузка...'
                  : 'Нет данных для отображения'
              }
              pagination={{
                totalItemCount: depositTransactionsTotalAmount || 0,
                pageIndex,
                pageSize: 20,
                hidePerPageOptions: true,
              }}
              onChange={(e: Criteria<Transaction>) => {
                if (e?.page) {
                  setPageIndex(e.page.index)
                  onPageChange(e.page.index + 1)
                }
              }}
            />
          </EuiPanel>
        </EuiFlexItem>

        <EuiFlexItem>
          <RightContainer>
            <SubscriptionPricePanel>
              <EuiTitle size="s">
                <h3>Cтоимость подписки</h3>
              </EuiTitle>
              <EuiFlexGroup gutterSize="xs" direction="row" alignItems="center">
                <EuiFlexItem grow={false}>
                  {isSubscriptionLoading ? (
                    <AmountLoader />
                  ) : (
                    <EuiTitle size="s">
                      <h4>{subscriptionPriceCurrencyString}</h4>
                    </EuiTitle>
                  )}
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiText size="s">- за месяц</EuiText>
                </EuiFlexItem>
              </EuiFlexGroup>
            </SubscriptionPricePanel>

            <EuiSpacer />

            <SupportPanel>
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiTitle size="s">
                    <h3>Техподдержка</h3>
                  </EuiTitle>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <ReceiptIcon />
                </EuiFlexItem>
              </EuiFlexGroup>

              <EuiSpacer size="m" />
              <EuiText>Нужна помощь? Напишите нам </EuiText>

              <EuiSpacer size="m" />
              <EuiFlexGroup alignItems="center" gutterSize="s">
                <EuiFlexItem grow={false}>
                  <EuiIconEnhanced type="phone" />
                </EuiFlexItem>
                <EuiFlexItem>8 (812) 612-35-00</EuiFlexItem>
              </EuiFlexGroup>

              <EuiSpacer size="m" />
              <EuiFlexGroup alignItems="center" gutterSize="s">
                <EuiFlexItem grow={false}>
                  <EuiIconEnhanced type="email" />
                </EuiFlexItem>
                <EuiFlexItem>help@mozen.ru</EuiFlexItem>
              </EuiFlexGroup>
            </SupportPanel>
          </RightContainer>
        </EuiFlexItem>
      </EuiFlexGroup>

      <Modal
        onClickAway={isTopupLoading ? undefined : closeTopupModal}
        isOpen={isTopupModalOpen}
        onClose={isTopupLoading ? () => {} : closeTopupModal}
        title="Пополнить баланс"
        cancelLabel="Отмена"
        acceptLabel={selectedTopupMethod === 'auto' ? 'Пополнить' : 'Скачать'}
        acceptIcon={selectedTopupMethod === 'download' ? 'download' : undefined}
        onCancel={closeTopupModal}
        onAccept={() => handleTopup(topupAmount)}
        isAcceptDisabled={
          !selectedTopupMethod ||
          Object.values(formErrors).some(Boolean) ||
          isTopupLoading
        }
        isCancelDisabled={isTopupLoading}
        isAcceptLoading={isTopupLoading}
        maxWidth={346}
      >
        <EuiFormEnhanced>
          <RenderGate isOpen={!isBankTypeManual}>
            <EuiFormRowEnhanced label="Способ оплаты">
              <EuiFlexGroup>
                <EuiFlexItem>
                  <TopupMethodRadioGroup
                    idSelected={selectedTopupMethod}
                    onChange={(option) =>
                      setSelectedTopupMethod(option as TopupMethod)
                    }
                    options={topupOptions}
                  />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  {/* <EuiIconEnhanced type="alfaBank" size="l" /> */}
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFormRowEnhanced>
            <EuiSpacer />
          </RenderGate>
          <EuiFormRowEnhanced
            label="Пополнить на сумму"
            error={formErrors.amount}
          >
            <EuiFieldNumberEnhanced
              value={topupAmount}
              onChange={(e) => setTopupAmount(e.currentTarget.value as any)}
              min={0}
              max={999999}
              step={0.01}
              append="₽"
              onValidation={(e) => {
                const { validationMessage } = e.currentTarget
                setFormErrors((prev) => ({
                  ...prev,
                  amount: validationMessage,
                }))
              }}
            />
          </EuiFormRowEnhanced>

          <EuiSpacer />
          
          <EuiText color="subdued" size="s">
          <>
          Обращаем ваше внимание, что ручное изменение
          <br />
          значений в полях сформированного документа
          <br />
          может привести к более длительной обработке
          <br />
          платежа или невозможности автоматической
          <br />
          обработки
          </>
          </EuiText>
        </EuiFormEnhanced>
      </Modal>

      <Modal
        isOpen={isSuccessModalOpen}
        onClose={closeSuccessModal}
        onClickAway={closeSuccessModal}
        title="Платеж создан"
        acceptLabel="Хорошо"
        cancelLabel="Пополнить еще"
        acceptColor="secondary"
        onAccept={closeSuccessModal}
        onCancel={() => {
          closeSuccessModal()
          openTopupModal()
        }}
        maxWidth={328}
      >
        <EuiText>
          {selectedTopupMethod === 'auto'
            ? `Спасибо.На ваш баланс Mozen будет начислено ${numberToCurrencyString(
                topupAmount,
                { withCurrencySign: true }
              )}`
            : 'Спасибо. Должно автоматически начаться скачивание файла '}
        </EuiText>
      </Modal>
    </>
  )
}

export default TariffPage
