import React from 'react'
import { ListPlaceholder } from 'shared/ui'

export const Placeholder = React.memo(() => (
  <ListPlaceholder image="wallet" imageHeight="176px">
    Здесь будут отображаться транзакции,
    <br />
    требующие подтверждения
  </ListPlaceholder>
))

Placeholder.displayName = 'Placeholder'
