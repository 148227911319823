import { createSelector } from 'reselect'
import { State } from 'reducers'
import { EmployeeStatus } from 'common/enums'

export const getIsFetching = (state: State) =>
  state.api.employees.get.status === 'loading'

const getData = (state: State) => state.api.employees.get.data

const getEmployees = createSelector(getData, (data) => {
  if (!data) {
    return []
  }
  return data.employees
})

export const getActiveEmployees = createSelector(getEmployees, (employees) =>
  employees.filter((employee) => employee.status === EmployeeStatus.Active)
)

export const getInactiveEmployees = createSelector(getEmployees, (employees) =>
  employees.filter((employee) => employee.status === EmployeeStatus.Inactive)
)
