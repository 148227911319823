import { createApiReducer } from 'common/utils/reduxApiFactory'
import { contractDeleteActions } from 'api/driversById/contracts/delete/actions'
import { ContractDeleteState } from 'api/driversById/contracts/delete/types'

export const initialState: ContractDeleteState = {
  status: null,
  data: null,
}

export const contractDeleteReducer = createApiReducer(
  contractDeleteActions,
  initialState
)
