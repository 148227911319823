import React, { useCallback, useMemo, useState } from 'react'

import { PaymentSettings } from 'api/types'
import { PaymentSettingsFormData } from 'shared/ui/paymentSettingsEditor/types'

import {
  NormalizedSettingsBuilder,
  transformToPaymentSettingsFormData,
} from 'common/utils/formatters'

import { Col, Row } from 'shared/ui/flex'
import { HBox, VBox } from 'shared/ui/spacers'
import {
  PaymentSettingsForm,
  PaymentSwitchSettingsForm,
  PaymentSwitchSettingsFuelForm,
} from 'shared/ui/paymentSettingsEditor/organisms/index'
import { BodyText, Icon } from 'ui-kit/components'
import styled from 'styled-components'
import { BankType } from 'common/enums'

const normalizeUpdates = (values: PaymentSettingsFormData) => {
  return new NormalizedSettingsBuilder(values.id)
    .withAutoWithdraw(values.auto_withdraw)
    .withWithdrawRestricted(values.withdraw_restricted)
    .withFairRides(values.fair_rides)
    .withWithdrawTransactionsForAutoWithdraw(
      values.withdraw_transactions_for_auto_withdraw
    )
    .withFreeWithdrawsCount(values.free_withdraws_count)
    .withWithdrawCommission(values.withdraw_commission)
    .withMinCommission(values.min_commission)
    .withNonResidentWithdrawCommission(values.non_resident_withdraw_commission)
    .withNonResidentMinCommission(values.non_resident_min_commission)
    .withMinWithdrawAmount(values.min_withdraw_amount)
    .withMaxWithdrawAmount(values.max_withdraw_amount)
    .withMinBalance(values.min_balance)
    .withDayWithdrawLimit(values.day_withdraw_limit)
    .withWeekWithdrawLimit(values.week_withdraw_limit)
    .withFuelCardAutoTopup(values.fuel_card_auto_topup)
    .withFuelCardTopupRestricted(values.fuel_card_topup_restricted)
    .withFuelCardMaxTopupAmount(values.fuel_card_max_topup_amount)
    .withFuelCardMinTopupAmount(values.fuel_card_min_topup_amount)
    .build()
}

type Props = {
  bankType: BankType | null
  settings: PaymentSettings
  onSubmit: (values: Partial<PaymentSettings>) => Promise<void>
  onChangePristine?: (isPristine: boolean) => void
  withActions?: boolean
  allowNonResident?: boolean
  isFuelEnabled?: boolean
  canChangePaymentsConditions?: boolean
  isBankTypeManual?: boolean
}

const PaymentSettingsHeader = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: row;
`

const CircleIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.pallete.uiSecondary};
`

const CircleIconCard = styled(CircleIcon)`
  padding: 5px;

  svg {
    width: 100%;
  }
`

export const PaymentSettingsEditor: React.FC<Props> = ({
  bankType,
  settings,
  withActions,
  allowNonResident,
  isFuelEnabled,
  canChangePaymentsConditions,
  isBankTypeManual,
  onSubmit,
  onChangePristine,
}) => {
  const [isSubmitting, setSubmitting] = useState(false)
  const handleSubmit = async (updates: PaymentSettingsFormData) => {
    setSubmitting(true)
    await onSubmit(normalizeUpdates(updates))
    setSubmitting(false)
  }

  const handleChangeSettingsPristine = useCallback(
    (isPristine: boolean) => {
      if (onChangePristine) {
        onChangePristine(isPristine)
      }
    },
    [onChangePristine]
  )

  const formData = useMemo(() => {
    const newFormData = transformToPaymentSettingsFormData(settings)
    const {
      id,
      withdraw_restricted: withdrawRestricted,
      auto_withdraw: autoWithdraw,
      fuel_card_auto_topup: fuelCardAutoTopup,
      fuel_card_topup_restricted: fuelCardTopupRestricted,
      ...rest
    } = newFormData

    return {
      common: { id, ...rest },
      switches: {
        id,
        withdraw_restricted: withdrawRestricted,
        auto_withdraw: autoWithdraw,
        fuel_card_auto_topup: fuelCardAutoTopup,
        fuel_card_topup_restricted: fuelCardTopupRestricted,
      },
    }
  }, [settings])

  return (
    <>
      <Row>
        <Col percentage={50}>
          <PaymentSettingsHeader>
            <CircleIconCard name="card" color="white" />
            <VBox x={1 / 3} />
            <BodyText fontWeight="semi-bold" color="textSecondary">
              Вывод средств
            </BodyText>
          </PaymentSettingsHeader>
          <HBox x={2 / 3} />
          <PaymentSwitchSettingsForm
            initialValues={formData.switches}
            isLocked={isSubmitting}
            withAllowAutoTransfer={isBankTypeManual}
            canChangePaymentsConditions={canChangePaymentsConditions}
            onSubmit={handleSubmit}
          />
        </Col>
        <VBox />
        {isFuelEnabled && (
          <Col percentage={50} withAlignStart>
            <PaymentSettingsHeader>
              <CircleIcon name="drop" color="white" />
              <VBox x={1 / 3} />
              <BodyText fontWeight="semi-bold" color="textSecondary">
                Пополнение топливных карт
              </BodyText>
            </PaymentSettingsHeader>
            <HBox x={2 / 3} />
            <PaymentSwitchSettingsFuelForm
              initialValues={formData.switches}
              isLocked={isSubmitting}
              canChangePaymentsConditions={canChangePaymentsConditions}
              onSubmit={handleSubmit}
            />
          </Col>
        )}
      </Row>
      <HBox x={2 / 3} />
      <PaymentSettingsForm
        bankType={bankType}
        withActions={withActions}
        initialValues={formData.common}
        isLocked={false}
        isAllowNonResident={allowNonResident}
        isFuelEnabled={isFuelEnabled}
        canChangePaymentsConditions={canChangePaymentsConditions}
        onChangePristine={handleChangeSettingsPristine}
        onSubmit={handleSubmit}
        hasAntiFraud
      />
    </>
  )
}
