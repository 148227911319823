import React from 'react'
import { Link } from 'react-router-dom'
import { BodyText, Icon, ListItem } from 'ui-kit/components'
import { RoutesPath } from 'common/enums'
import { VBox } from 'shared/ui'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  height: 72px;
  align-items: center;
`

const BackLink = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
`

export const Heading: React.FC = () => (
  <Container>
    <VBox x={0.5} />
    <Link to={RoutesPath.SettingsTariff}>
      <BackLink>
        <Icon name="arrowLeft" />
      </BackLink>
    </Link>
    <ListItem>
      <BodyText fontWeight="semi-bold">История пополнений и списаний</BodyText>
    </ListItem>
  </Container>
)
