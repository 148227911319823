import { ThunkAction } from 'redux-thunk'
import { State } from 'reducers'
import axios from 'axios'
import { FailureError } from 'common/utils/reduxApiFactory'
import { TApiError } from 'api/types'
import { deleteReferralOfferActions as actions } from './actions'
import { DeleteReferralOfferActions } from './types'

export type HandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  DeleteReferralOfferActions
>

export const deleteReferralOfferRequest = (id: number): HandleRequest => async (
  dispatch
) => {
  dispatch(actions.start())
  try {
    await axios.delete(`erp/1/referral/offer/${id}`)
    dispatch(actions.success())
  } catch (e) {
    if (e.response) {
      const data = e.response.data as TApiError
      const error: FailureError = new Error(data.message)
      error.data = data
      dispatch(actions.failure(error))
    }

    throw e
  }
}
