import { connect } from 'react-redux'

import { State } from 'reducers'
import { PaymentSettingsAssignDriversModal } from 'settings/features/paymentSettingsAssignDriverModal/pages'

type OwnProps = {
  onAdd: (userIds: string[]) => void
  onClose: () => void
}

const mapStateToProps = (_state: State, ownProps: OwnProps) => ({
  ...ownProps,
})

const mapDispatchToProps = () => ({})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps

export const PaymentSettingsAssignDriversModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentSettingsAssignDriversModal)
