import React from 'react'

import { CaptionText, RejectResolveButtons } from 'ui-kit/components'

import { Modal } from 'shared/ui/modal'
import { ContentCardBody } from 'shared/ui/contentCard'
import { HBox } from 'shared/ui/spacers'

interface Props {
  onReject: () => void
  onResolve: () => void
}

export const PaymentSettingsDeleteModal = React.memo<Props>(
  ({ onReject, onResolve }) => {
    return (
      <Modal
        open
        closeButton
        onClose={onReject}
        overflow="initial"
        title="Удалить группу?"
        withMinHeight={false}
      >
        <ContentCardBody>
          <CaptionText color="textSecondary">
            Всем водителям в группе будут установлены стандартные условия
            выплат.
          </CaptionText>
          <HBox x={2 / 3} />
          <RejectResolveButtons
            resolveBgColor="red"
            className="actions"
            resolveText="Удалить"
            rejectText="Отмена"
            onResolve={onResolve}
            onReject={onReject}
            fullWidth
          />
        </ContentCardBody>
      </Modal>
    )
  }
)

PaymentSettingsDeleteModal.displayName = 'PaymentSettingsDeleteModal'
