import { batch, connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import { State } from 'reducers'
import { apiActions, apiSelectors } from 'api'
import { MyThunkDispatch } from 'api/reducer'
import { modalsActions } from 'shared/modals'
import { reset } from 'settings/features/aggregatorsList/actions'
import {
  getAddType,
  getCurrentId,
  selectInitialValues,
} from 'settings/features/aggregatorsList/selectors'
import { onSaveAggregator } from 'settings/features/aggregatorsList/thunks'
import { AddAggregatorModal } from 'settings/features/aggregatorsList/pages'
import { FormNames, ModalsNames } from 'common/enums'
import {
  AggregatorByIdCitymobil,
  AggregatorByIdGett,
  AggregatorByIdYandex,
  AggregatorByIdDiDi,
} from 'api/types'

export type FormProps =
  | AggregatorByIdYandex
  | AggregatorByIdCitymobil
  | AggregatorByIdGett
  | AggregatorByIdDiDi

const mapStateToProps = (state: State) => ({
  currentId: getCurrentId(state),
  addType: getAddType(state),
  aggregatorsList: apiSelectors.park.aggregators.getAggregatorsList(state),
  fetchedAggregator: apiSelectors.park.aggregatorByIdGet.getAggregator(state),
  isFetching: apiSelectors.park.aggregatorByIdGet.getIsFetching(state),
  initialValues: selectInitialValues(state),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  fetchAggregator: (id: string) =>
    dispatch(apiActions.park.aggregatorByIdGet.handleRequest(id)),
  closeModal: () =>
    batch(() => {
      dispatch(modalsActions.closeModal(ModalsNames.AddAggregator))
      dispatch(reset())
      dispatch(apiActions.park.aggregatorByIdGet.reset())
    }),
  onSave: ({ type, id, values }: any) =>
    dispatch(onSaveAggregator({ type, id, values })),
  openDeleteAggregatorModal: () =>
    batch(() => {
      dispatch(modalsActions.closeModal(ModalsNames.AddAggregator))
      dispatch(modalsActions.openModal(ModalsNames.DeleteAggregator))
    }),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const Form = reduxForm<FormProps, ContainerProps>({
  form: FormNames.AddAggregator,
  enableReinitialize: true,
})(AddAggregatorModal)

export const AddAggregatorModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Form)
