import React from 'react'

import { SortDirection } from 'common/enums'
import { Loader } from 'shared/ui'

import { Column, InjectedProps } from 'shared/table/components/Column/Column'
import { Colgroup } from 'shared/table/components/Colgroup/Colgroup'
import { Row } from 'shared/table/components/Row/Row'
import { Body, Wrapper } from 'shared/table/components/Table/styles'
import { ColumnProps } from 'shared/table/interfaces'

import { ListItem } from 'ui-kit/components'

interface Props {
  children:
    | (React.ReactElement | null | undefined)[]
    | (React.ReactElement | null | undefined)
  source?: Record<string, unknown>[]
  preview?: boolean
  isLoading?: boolean
  sortColumn?: string
  bodyMinHeight?: string
  sortDirection?: SortDirection
  hideHeaderOnEmpty?: boolean
  withHeader?: boolean
  onChangeSort?: (column: string, direction: SortDirection) => void
  onClickRow?: (item: any) => void
}

export const Table: React.FC<Props> = ({
  children,
  onClickRow,
  source,
  preview,
  sortColumn,
  sortDirection,
  onChangeSort,
  isLoading,
  bodyMinHeight = '400px',
  hideHeaderOnEmpty,
  withHeader = true,
}) => {
  const [columns, setColumns] = React.useState([] as ColumnProps[])

  React.useEffect(() => {
    const effectColumns: ColumnProps[] = []

    if (children) {
      React.Children.forEach(
        children,
        (child: React.ReactElement | null | undefined) => {
          if (child && child.type === Column) {
            effectColumns.push({
              data: child.props.data,
              flex: child.props.flex,
              className: child.props.className,
              disableRowClick: child.props.disableRowClick,
            })

            setColumns(effectColumns)
          }
        }
      )
    }
  }, [children])

  const handleSortableClick = (name: string): void => {
    function getDirection() {
      if (sortColumn !== name) {
        return sortDirection
      }
      if (sortDirection !== SortDirection.ASC) {
        return SortDirection.ASC
      }

      return SortDirection.DESC
    }

    const direction = getDirection()

    if (onChangeSort && direction) {
      onChangeSort(name, direction)
    }
  }

  const header = (): JSX.Element => (
    <ListItem border="both" className="row-item row-item-head">
      {hideHeaderOnEmpty && source && !source.length
        ? null
        : React.Children.map(
            children,
            (child: React.ReactElement | null | undefined) =>
              child &&
              child.type === Column &&
              React.cloneElement(child, {
                onSortableClick: handleSortableClick,
                sortColumn,
                sortDirection,
              } as InjectedProps)
          )}
    </ListItem>
  )

  const colgroup = (): JSX.Element => (
    <>
      {React.Children.map(
        children,
        (child: React.ReactElement | null | undefined) =>
          child &&
          child.type === Colgroup &&
          React.cloneElement(child, {
            onSortableClick: handleSortableClick,
            sortColumn,
            sortDirection,
          } as InjectedProps)
      )}
    </>
  )

  const renderRows = (rowSource: Record<string, unknown>[]) => {
    if (rowSource.length !== 0) {
      return rowSource.map((row) => (
        <Row
          key={row.id as string}
          row={row}
          columns={columns}
          onClickRow={onClickRow}
        />
      ))
    }
  }

  return (
    <Wrapper>
      {withHeader && header()}
      {colgroup()}
      <Body
        clickable={!!onClickRow}
        bodyMinHeight={bodyMinHeight}
        isEmpty={!isLoading && source && !source.length}
      >
        {!isLoading && source ? (
          columns.length !== 0 &&
          renderRows(preview ? source.slice(0, 5) : source)
        ) : (
          <Loader absolute />
        )}
      </Body>
    </Wrapper>
  )
}
