import { ThunkAction } from 'redux-thunk'
import { State } from 'reducers'
import axios from 'axios'
import { FailureError } from 'common/utils/reduxApiFactory'
import { TApiError } from 'api/types'
import { deleteNotificationsBotActions } from 'api/park/notifications/telegram/bot/delete/actions'
import { DeleteNotificationsBotActions } from 'api/park/notifications/telegram/bot/delete/types'
import { getNotificationsBotActions } from 'api/park/notifications/telegram/bot/get/actions'
import { GetNotificationsBotActions } from 'api/park/notifications/telegram/bot/get/types'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  DeleteNotificationsBotActions | GetNotificationsBotActions
>

export const deleteNotificationsBotRequest = (): THandleRequest => async (
  dispatch
) => {
  const { start, success, failure } = deleteNotificationsBotActions
  dispatch(start())
  try {
    await axios.delete(`erp/1/park/notifications/telegram/bot`)
    dispatch(success())
    dispatch(getNotificationsBotActions.reset())
  } catch (e) {
    if (e.response) {
      const data = e.response.data as TApiError
      const error: FailureError = new Error(data.message)
      error.data = data
      dispatch(failure(error))
      throw error
    }
  }
}
