import React, { useState } from 'react'
import styled from 'styled-components'

import { ColorTypes, FontWeightTypes, IconNames } from 'ui-kit'
import { SmallLoader, Icon, SubBodyText } from 'ui-kit/components'

import { Base, DefaultProps } from 'ui-kit/components/Buttons/Base/Base'

const getBorderRadiusFromPosition = (
  position?: 'start' | 'middle' | 'end',
  radius = 8
) => {
  const borderRadius = `${radius}px`
  switch (position) {
    case 'start':
      return `${borderRadius} 0 0 ${borderRadius}`
    case 'middle':
      return '0'
    case 'end':
      return `0 ${borderRadius} ${borderRadius} 0`
    default:
      return borderRadius
  }
}

const Container = styled(Base)<{
  opacity?: number
  position?: 'start' | 'middle' | 'end'
}>`
  position: relative;
  display: flex;
  padding: ${({ opacity }) => !opacity && 0};
  height: auto;
  min-width: auto;
  border-radius: ${({ position }) => getBorderRadiusFromPosition(position)};

  &::before {
    background-color: transparent;
  }
`
const Opacity = styled.div<{
  opacity: number
  position?: 'start' | 'middle' | 'end'
}>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${({ theme }) => theme.pallete.white};
  opacity: ${({ opacity }) => opacity};
  border-radius: ${({ position }) => getBorderRadiusFromPosition(position)};
`
const VSpace = styled.div`
  width: 16px;
`

const FixForIconAbsence = styled.div`
  display: inline-block;
  width: 0px;
  height: 32px;
`

export interface Props extends DefaultProps {
  className?: string
  textColor?: ColorTypes
  fontWeight?: FontWeightTypes
  iconName?: IconNames
  iconColor?: ColorTypes
  opacity?: number
  isLoading?: boolean
  groupPosition?: 'start' | 'middle' | 'end'
  isSizeLikeWithIcon?: boolean
}

export const TransparentButton: React.FC<Props> = ({
  children,
  isLoading,
  disabled,
  textColor = 'blue',
  iconColor = textColor,
  fontWeight,
  iconName,
  opacity,
  onClick,
  onClickCapture,
  className,
  groupPosition,
  isSizeLikeWithIcon,
}) => {
  const [isHovered, setIsHovered] = useState(false)
  return (
    <Container
      className={className}
      opacity={opacity}
      disabled={disabled || isLoading}
      onClick={onClick}
      onClickCapture={onClickCapture}
      hovered={isHovered}
      onMouseEnter={disabled ? undefined : () => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      position={groupPosition}
    >
      {opacity ? <Opacity opacity={opacity} position={groupPosition} /> : null}
      {iconName ? (
        <>
          {isLoading ? (
            <SmallLoader />
          ) : (
            <Icon
              pointer={!isLoading && !disabled}
              name={iconName}
              color={iconColor}
              width={32}
              height={32}
            />
          )}
        </>
      ) : null}
      {iconName && children ? <VSpace /> : null}
      {isSizeLikeWithIcon ? <FixForIconAbsence /> : null}
      <SubBodyText color={textColor} fontWeight={fontWeight}>
        {children}
      </SubBodyText>
    </Container>
  )
}
