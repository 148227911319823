import { createSelector } from 'reselect'
import { State } from 'reducers'
import { ContractsResponse } from 'api/types'

export const getIsFetching = (state: State) =>
  state.api.park.contracts.status === 'loading'

const getData = (state: State) => state.api.park.contracts.data

export const getContracts: (
  state: State
) => ContractsResponse | null = createSelector(getData, (data) => data || null)
