import Alert from 'react-s-alert'
import { apiActions } from 'api'
import { MyThunkDispatch, MyThunkResult } from 'api/reducer'
import { ApiCode } from 'common/types/api/codes'
import { FormProps } from 'settings/features/parkContacts/containers/ParkContactsContainer'

export const onSaveParkContacts: ({
  values,
}: {
  values: FormProps
}) => MyThunkResult<void> = ({ values }) => (dispatch: MyThunkDispatch) => {
  return dispatch(
    apiActions.park.technicalSupportContactsPatch.handleRequest({
      values: { email: values.email, phone: values.phone },
    })
  ).then(
    () => {
      dispatch(apiActions.park.technicalSupportContactsGet.handleRequest())
      Alert.info('Изменения сохранены')
    },
    (reason) => {
      if (
        reason.response &&
        reason.response.data.code === ApiCode.InputValidationError &&
        reason.response.data.payload
      ) {
        throw reason.response.data.payload
      }
    }
  )
}
