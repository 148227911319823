import { handleActions } from 'redux-actions'

import {
  EngineeringWorksPayload,
  Payloads,
  SET_ENGINEERING_WORKS,
  SET_SERVER_ERROR,
} from 'shared/router/actions'

export interface RouterState {
  serverError?: boolean
  engineeringWorks?: EngineeringWorksPayload
}

const initialState: RouterState = {
  serverError: false,
}

const handleSetServerError = (
  state: RouterState,
  action: SET_SERVER_ERROR
): RouterState => ({
  ...state,
  serverError: action.payload,
})

const handleSetEngineeringWorks = (
  state: RouterState,
  action: SET_ENGINEERING_WORKS
): RouterState => ({
  ...state,
  engineeringWorks: action.payload,
})

export default handleActions<RouterState, any>(
  {
    [SET_SERVER_ERROR]: handleSetServerError,
    [SET_ENGINEERING_WORKS]: handleSetEngineeringWorks,
  },
  initialState
)
