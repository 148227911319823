import React, { useEffect, useState } from 'react'

import { Loader } from 'shared/ui'
import { SidebarContainer } from 'layout/features/sidebar'
import { Content } from 'layout/features/content'

import { ContainerProps } from 'layout/containers/LayoutContainer'
import { useSelector } from 'react-redux'
import { apiSelectors } from 'api'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { RoutesPath } from 'common/enums'
import Typography from 'ui-kit/typography'
import { VBox } from 'ui-kit/atoms/spacers'
import { Icon as DefaultIcon } from 'ui-kit/components'
import InfoBanner from './InfoBanner'

const Wrapper = styled.div`
  display: flex;
  position: relative;
`

export const Layout: React.FC<ContainerProps> = ({
  isSidebarVisible,
  isNoticeVisible,
  bannerMessage,
  bannerUrl,
  bannerBackgroundColor,
  bannerTextColor,
  onDidMount,
  fetchBanner,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const { bankType, subscriptionStatus, parkId } = useSelector(
    apiSelectors.park.details.selectYmParams
  )
  const hasParams = !!(bankType || subscriptionStatus || parkId)

  useEffect(() => {
    const ym = (window as any)?.ym

    if (hasParams && ym) {
      ym(55824049, 'userParams', {
        park_id: parkId,
        subscription_status: subscriptionStatus,
        bank_type: bankType,
      })
    }
  }, [parkId, bankType, subscriptionStatus, hasParams])

  useEffect(() => {
    const initLayout = async () => {
      await onDidMount()
      setIsLoading(false)
    }
    initLayout()
  }, [onDidMount])

  useEffect(() => {
    if (hasParams) {
      fetchBanner({
        bankType,
        parkId,
        subscriptionStatus,
      })
    }
  }, [bankType, parkId, subscriptionStatus, fetchBanner, hasParams])

  const location = useLocation()
  const isRegistrations = location.pathname.match(RoutesPath.Steps)
  return isLoading ? (
    <Loader absolute />
  ) : (
    <>
      {bannerBackgroundColor && bannerTextColor && bannerMessage && (
        <InfoBanner
          backgroundColor={bannerBackgroundColor}
          textColor={bannerTextColor}
          href={bannerUrl}
        >
          {bannerMessage}
        </InfoBanner>
      )}
      <Wrapper>
        {isSidebarVisible ? <SidebarContainer /> : null}
        <Content
          isSidebarVisible={isSidebarVisible}
          isNoticeVisible={isNoticeVisible || undefined}
        />
      </Wrapper>
    </>
  )
}
