import React from 'react'
import styled from 'styled-components'
import { Icon as DefaultIcon } from 'ui-kit/components'
import Typography from 'ui-kit/typography'

type Props = {
  data: KnowledgeTipsLink[]
}

export type KnowledgeTipsLink = {
  name: string
  href: string
}

const Wrapper = styled.div`
  padding-left: 16px;
  padding-right: 24px;
  display: flex;
  align-items: flex-start;
`

const Link = styled(Typography.withComponent('a')).attrs({
  variant: 'Caption2',
  fontColor: 'accentPrimary',
})`
  padding-bottom: 8px;

  &:last-child {
    padding-bottom: 0;
  }
`

const LinkWrapper = styled.div`
  padding-left: 8px;
  display: flex;
  flex-direction: column;
`

const Icon = styled(DefaultIcon)`
  svg {
    width: 16px;
    height: 16px;
  }
`

const KnowledgeTips: React.FC<Props> = ({ data }) => {
  return (
    <Wrapper>
      <Icon name="book" color="accentPrimary" />
      <LinkWrapper>
        {data.map((item) => (
          <Link
            key={item.name}
            href={item.href}
            target="_blank"
            rel="noreferrer"
          >
            {item.name}
          </Link>
        ))}
      </LinkWrapper>
    </Wrapper>
  )
}

export default React.memo<Props>(KnowledgeTips)
