import { Action, combineReducers } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { State, TRootAction } from 'reducers'
import { authReducer, AuthState, TAuthAction } from 'api/auth'
import { driversReducer, DriversState, TDriversAction } from 'api/drivers'
import {
  driversByIdReducer,
  DriversByIdState,
  TDriversByIdAction,
} from 'api/driversById'
import { employeeReducer, EmployeeState, TEmployeeAction } from 'api/employee'
import {
  employeesReducer,
  EmployeesState,
  TEmployeesAction,
} from 'api/employees'
import { parkReducer, ParkState, TParkAction } from 'api/park'
import {
  transactionsReducer,
  TransactionsState,
  TransactionsAction,
} from 'api/transactions'
import { reportReducer, ReportState, ReportAction } from 'api/report'
import { fuelReducer, FuelState, TFuelAction } from 'api/fuel'
import { receiptReducer, ReceiptState, ReceiptAction } from 'api/receipt'
import { ReferralActions, referralReducer, ReferralState } from './referral'
import { PromoActions, PromoState } from './promo/types'
import { promoReducer } from './promo/reducers'

export type MyThunkResult<R> = ThunkAction<R, State, undefined, TRootAction>
export type MyThunkDispatch = ThunkDispatch<State, undefined, Action>

export type TApiAction =
  | TAuthAction
  | TDriversAction
  | TDriversByIdAction
  | TEmployeeAction
  | TEmployeesAction
  | TParkAction
  | ReferralActions
  | TransactionsAction
  | ReportAction
  | TFuelAction
  | ReceiptAction
  | PromoActions

export interface ApiState {
  auth: AuthState
  drivers: DriversState
  driversById: DriversByIdState
  employee: EmployeeState
  employees: EmployeesState
  park: ParkState
  referral: ReferralState
  transactions: TransactionsState
  report: ReportState
  fuel: FuelState
  receipt: ReceiptState
  promo: PromoState
}

export const apiReducer = combineReducers({
  auth: authReducer,
  drivers: driversReducer,
  driversById: driversByIdReducer,
  employee: employeeReducer,
  employees: employeesReducer,
  park: parkReducer,
  referral: referralReducer,
  transactions: transactionsReducer,
  report: reportReducer,
  fuel: fuelReducer,
  receipt: receiptReducer,
  promo: promoReducer,
})
