import { Action, BaseAction } from 'redux-actions'

export type TStart = BaseAction
export type TSuccess = BaseAction
export type TFailure = Action<{ error: any }>
export type TReset = BaseAction
export type TResetStatus = BaseAction

export type TCertificatePostAction =
  | TStart
  | TSuccess
  | TFailure
  | TReset
  | TResetStatus

export const START: TStart['type'] = 'api/park/h2h-certificate/post->START'
export const SUCCESS: TSuccess['type'] =
  'api/park/h2h-certificate/post->SUCCESS'
export const FAILURE: TFailure['type'] =
  'api/park/h2h-certificate/post->FAILURE'
export const RESET: TReset['type'] = 'api/park/h2h-certificate/post->RESET'
export const RESET_STATUS: TResetStatus['type'] =
  'api/park/h2h-certificate/post->RESET_STATUS'

export const start = () => ({
  type: START,
})
export const success = () => ({
  type: SUCCESS,
})
export const failure = (error: any): TFailure => ({
  type: FAILURE,
  payload: error,
})
export const reset = () => ({
  type: RESET,
})
export const resetStatus = () => ({
  type: RESET_STATUS,
})

export type TPayloads = Pick<TFailure, 'payload'>['payload']

export default {
  reset,
}
