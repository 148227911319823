import axios from 'axios'
import { BaseAction } from 'redux-actions'
import { ThunkAction } from 'redux-thunk'

import { State } from 'reducers'

import { failure, start, success } from 'api/auth/logout/actions'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  BaseAction
>

export const HANDLE_REQUEST: BaseAction['type'] =
  'api/auth/logout->HANDLE_REQUEST'

export const handleRequest = (): THandleRequest => async (
  dispatch
): Promise<any> => {
  dispatch(start())
  try {
    await axios.get('auth/1/logout')
    dispatch(success())
  } catch (e) {
    if (e.response) {
      dispatch(failure(e.response.data))
    }
  }
}
