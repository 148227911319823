import { ThunkAction } from 'redux-thunk'
import { State } from 'reducers'
import axios, { AxiosResponse } from 'axios'
import { FailureError } from 'common/utils/reduxApiFactory'
import { PaymentSettings, TApiError } from 'api/types'
import { postParkPaymentSettingsActions } from 'api/park/payment-settings/post/actions'
import { ParkPaymentSettingsActions } from 'api/park/payment-settings/post/types'

export type THandleRequest = ThunkAction<
  Promise<PaymentSettings | void>,
  State,
  undefined,
  ParkPaymentSettingsActions
>

export const postParkPaymentSettingsRequest = (
  parkPaymentSettings: Partial<PaymentSettings>
): THandleRequest => async (dispatch) => {
  const { start, update, success, failure } = postParkPaymentSettingsActions
  dispatch(start())
  try {
    const response: AxiosResponse<PaymentSettings> = await axios.post(
      `erp/1/park/payment-settings`,
      parkPaymentSettings
    )
    dispatch(update(response.data))
    dispatch(success())
  } catch (e) {
    if (e.response) {
      const data = e.response.data as TApiError
      const error: FailureError = new Error(data.message)
      error.data = data
      dispatch(failure(error))
    }
  }
}
