import { ThunkAction } from 'redux-thunk'
import { State } from 'reducers'
import axios from 'axios'
import { FailureError } from 'common/utils/reduxApiFactory'
import { TApiError } from 'api/types'
import { postNotificationsBotActions } from 'api/park/notifications/telegram/bot/post/actions'
import {
  PostNotificationsBotActions,
  PostNotificationsBotParams,
} from 'api/park/notifications/telegram/bot/post/types'
import { getNotificationsBotActions } from 'api/park/notifications/telegram/bot/get/actions'
import { GetNotificationsBotActions } from 'api/park/notifications/telegram/bot/get/types'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  PostNotificationsBotActions | GetNotificationsBotActions
>

export const postNotificationsBotRequest = (
  params: PostNotificationsBotParams
): THandleRequest => async (dispatch) => {
  const { start, success, failure } = postNotificationsBotActions
  dispatch(start())
  try {
    await axios.post(`erp/1/park/notifications/telegram/bot`, params)
    dispatch(success())
    dispatch(getNotificationsBotActions.reset())
  } catch (e) {
    if (e.response) {
      const data = e.response.data as TApiError
      const error: FailureError = new Error(data.message)
      error.data = data
      dispatch(failure(error))
      throw error
    }
  }
}
