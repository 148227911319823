import { State } from 'reducers'
import { apiActions, apiSelectors } from 'api'
import { MyThunkDispatch, MyThunkResult } from 'api/reducer'

export const onDidMount: () => MyThunkResult<void> = () => {
  return (dispatch: MyThunkDispatch, getState: () => State) => {
    dispatch(apiActions.park.notificationsGet.handleRequest())
    if (
      apiSelectors.employee.profile.getCanViewParkRequisites(getState()) &&
      !apiSelectors.park.details.getBankType(getState())
    ) {
      dispatch(apiActions.park.details.handleRequest())
    }
  }
}
