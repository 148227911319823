import React from 'react'
import { WrappedFieldProps } from 'redux-form'

import { Checkbox } from 'ui-kit/components'

interface Props {
  className?: string
  inputId?: string
  disabled?: boolean
  reverse?: boolean
  checked?: boolean
}

const CheckboxComponent: React.FC<Props & WrappedFieldProps> = (props) => {
  const { children, inputId, reverse, disabled, checked, input } = props

  return (
    <Checkbox
      name={input.name}
      inputId={inputId}
      reverse={reverse}
      onChange={input.onChange}
      disabled={disabled}
      checked={checked !== undefined ? checked : !!input.value}
    >
      {children}
    </Checkbox>
  )
}

export default CheckboxComponent
