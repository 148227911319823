import React from 'react'

import { Loader } from 'shared/ui/loader'
import { PaymentSettingsCardConnector } from 'settings/features/paymentSettingsCard/containers'
import { PurePaymentSettingCard } from 'settings/features/paymentSettingsCard/molecules'

type Props = {
  settingsId: string
  onEdit: () => void
}

export const PaymentSettingsCard = React.memo<Props>(
  ({ settingsId, onEdit }) => (
    <PaymentSettingsCardConnector settingsId={settingsId}>
      {(data) => {
        if (!data) return <Loader />

        return <PurePaymentSettingCard settings={data} onEdit={onEdit} />
      }}
    </PaymentSettingsCardConnector>
  )
)

PaymentSettingsCard.displayName = 'PaymentSettingsCard'
