import Alert from 'react-s-alert'

import { apiActions } from 'api'
import { MyThunkDispatch, MyThunkResult } from 'api/reducer'
import { modalsActions } from 'shared/modals'
import { ApiCode } from 'common/types/api/codes'
import { AggregatorType, ModalsNames } from 'common/enums'
import { reset } from 'settings/features/aggregatorsList/actions'
import { FormProps } from 'settings/features/aggregatorsList/containers/AddAggregatorModalContainer'

export const onSaveAggregator: ({
  type,
  id,
  values,
}: {
  type: AggregatorType
  id: string
  values: FormProps
}) => MyThunkResult<void> = ({ type, id, values }) => (
  dispatch: MyThunkDispatch
) => {
  dispatch(apiActions.park.aggregatorPost.reset())
  return dispatch(
    id
      ? apiActions.park.aggregatorByIdPatch.handleRequest({
          type,
          id,
          values,
        })
      : apiActions.park.aggregatorPost.handleRequest({
          type,
          values,
        })
  ).then(
    () => {
      dispatch(apiActions.park.aggregators.handleRequest())
      dispatch(modalsActions.closeModal(ModalsNames.AddAggregator))
      dispatch(reset())
      dispatch(apiActions.park.aggregatorByIdGet.reset())
    },
    (reason) => {
      if (
        reason.response &&
        reason.response.data.code === ApiCode.InputValidationError
      ) {
        if (reason.response.data.payload) {
          throw reason.response.data.payload
        } else if (reason.response.data.message) {
          Alert.error('Ошибка', {
            customFields: {
              text: reason.response.data.message,
            },
          })
        }
      }
    }
  )
}

export const onDeleteAggregator: ({
  type,
  id,
}: {
  type: AggregatorType
  id: string
}) => MyThunkResult<void> = ({ type, id }) => (dispatch: MyThunkDispatch) =>
  dispatch(
    apiActions.park.aggregatorByIdDelete.handleRequest({
      type,
      id,
    })
  )
    .then(
      () => undefined,
      () => {
        Alert.error('Ошибка', {
          customFields: {
            text: 'Не удалось удалить',
          },
        })
      }
    )
    .finally(() => {
      dispatch(apiActions.park.aggregators.handleRequest())
      dispatch(modalsActions.closeModal(ModalsNames.DeleteAggregator))
      dispatch(reset())
      dispatch(apiActions.park.aggregatorByIdGet.reset())
    })
