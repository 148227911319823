import { connect } from 'react-redux'
import { getFormValues, reduxForm } from 'redux-form'

import { State } from 'reducers'
import { MyThunkDispatch } from 'api/reducer'
import { FormNames } from 'common/enums'
import { onSaveQiwi } from 'steps/features/stepPayments/thunks'
import { PaymentsQiwi } from 'steps/features/stepPayments/organisms'

export interface FormProps {
  terminal_id: string
  password: string
  min_balance: string
  email_notifications: boolean
}

const mapStateToProps = (state: State) => ({
  formValues: getFormValues(FormNames.StepPaymentsQiwi)(state) as FormProps,
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  onSave: (values: FormProps) => dispatch(onSaveQiwi({ values })),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const Form = reduxForm<FormProps, ContainerProps>({
  form: FormNames.StepPaymentsQiwi,
  initialValues: {
    email_notifications: true,
  },
})(PaymentsQiwi)

export const PaymentsQiwiContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Form)
