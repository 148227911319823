import { Action } from 'redux-actions'

export type SET_PAGE = Action<number>

export type TSubscriptionEventsAction = SET_PAGE

export const SET_PAGE: SET_PAGE['type'] = 'settings/subscriptionEvents/SET_PAGE'

export const setPage = (page: number): SET_PAGE => ({
  type: SET_PAGE,
  payload: page,
})

export type Payloads = Pick<SET_PAGE, 'payload'>['payload']
