import React, { useEffect, useRef, useState } from 'react'

import { Col, ContentCard, HBox, Loader, Modal } from 'shared/ui'
import { ContainerProps } from 'settings/features/paymentSettingsAssignDriverModal/containers/PaymentSettingsAssignDriversModalContainer'
import { Driver } from 'common/types/api/models/driver'
import {
  CaptionText,
  Icon,
  ListInput,
  RejectResolveButtons,
  SubBodyText,
} from 'ui-kit/components'
import { Column, Table } from 'shared/table/components'
import { formatPhone } from 'common/utils/formatters'
import axios, { AxiosResponse } from 'axios'
import { DriverListResponse } from 'common/types/api/responses/driver'
import styled from 'styled-components'

const initialDrivers: Driver[] = []

const SearchWrapper = styled.div`
  position: relative;
`

const SearchResultContentCard = styled(ContentCard)`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  width: 100%;
  max-height: 300px;
  min-height: 70px;
  overflow: scroll;
`

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  cursor: pointer;

  &:hover {
    background-color: ${({ onClick }) => onClick && '#f4f8ff'};
  }
`
type SearchResultProps = {
  name: string
  phone: string
  onClick?: () => void
  onRemove?: () => void
}
const SearchResult: React.FC<SearchResultProps> = ({
  name,
  phone,
  onClick,
  onRemove,
}) => {
  return (
    <Wrapper onClick={onClick}>
      <Col percentage={100}>
        <SubBodyText>{name}</SubBodyText>
        <CaptionText color="gray6">{formatPhone(phone)}</CaptionText>
      </Col>
      {onRemove !== undefined && (
        <Icon name="circleMinus" pointer onClick={onRemove} />
      )}
    </Wrapper>
  )
}

export const PaymentSettingsAssignDriversModal: React.FC<ContainerProps> = ({
  onAdd,
  onClose,
}) => {
  const [drivers, setDrivers] = useState<Driver[]>(initialDrivers)
  const [searchValue, setSearchValue] = useState('')
  const [searchResult, setSearchResult] = useState<Driver[]>()
  const [isSearchFetching, setIsSearchFetching] = useState(false)
  const [isAddDriverFetching, setIsAddDriverFetching] = useState(false)

  const timeoutHandler = useRef<number>()
  const initialRender = useRef(true)
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false
      return
    }

    const onDidMount = async () => {
      if (searchValue !== '') {
        setIsSearchFetching(true)

        const response: AxiosResponse<DriverListResponse> = await axios.get(
          'erp/1/park/drivers',
          {
            params: {
              limit: 20,
              search: searchValue,
              statuses: ['registered'],
            },
          }
        )

        setSearchResult(response.data.drivers)

        setIsSearchFetching(false)
      }
    }

    clearTimeout(timeoutHandler.current)
    timeoutHandler.current = setTimeout(onDidMount, 1000)
  }, [searchValue])

  const handleResolve = async () => {
    try {
      setIsAddDriverFetching(true)
      await onAdd(
        drivers.reduce((prev, next) => {
          if (next.user_id) {
            return [...prev, next.user_id]
          }
          return prev
        }, [] as string[])
      )
    } catch (e) {
      console.error(e)
    }
    setIsAddDriverFetching(false)
  }

  const handleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const handleClearSearch = () => {
    setSearchValue('')
    setSearchResult(undefined)
  }

  const formatDriver = (driver: Driver) => {
    const handleClickRemoveItem = () => {
      setDrivers((prevValue) =>
        prevValue.filter((item) => item.id !== driver.id)
      )
    }

    return (
      <SearchResult
        name={driver.name}
        phone={driver.phone}
        onRemove={handleClickRemoveItem}
      />
    )
  }

  const hasSearch = !!searchResult

  const handleClickSearchResult = (newSearchResult: Driver) => () => {
    const hasValue = !!drivers.find((item) => item.id === newSearchResult.id)
    if (!hasValue) {
      setDrivers((prevState) => [...prevState, newSearchResult])
      setSearchValue('')
      setSearchResult(undefined)
    }
  }

  return (
    <Modal
      open
      onClose={onClose}
      closeButton
      title="Добавить водителей"
      width={528}
    >
      <SearchWrapper>
        <ListInput
          label="ФИО или номер водителя"
          iconName="close"
          onIconClick={handleClearSearch}
          value={searchValue}
          onChange={handleSearch}
        />
        {(hasSearch || isSearchFetching) && (
          <SearchResultContentCard withMinHeight={false}>
            {isSearchFetching && <Loader absolute />}

            {!isSearchFetching &&
              searchResult &&
              searchResult.map((searchResultItem) => (
                <SearchResult
                  key={searchResultItem.id}
                  name={searchResultItem.name}
                  phone={searchResultItem.phone}
                  onClick={handleClickSearchResult(searchResultItem)}
                />
              ))}
          </SearchResultContentCard>
        )}
      </SearchWrapper>
      <HBox />
      {drivers.length > 0 && (
        <Table source={drivers} bodyMinHeight="none">
          <Column data={formatDriver} />
        </Table>
      )}
      <HBox />
      <RejectResolveButtons
        isLoading={isAddDriverFetching}
        resolveText="Добавить"
        rejectText="Отмена"
        onResolve={handleResolve}
        onReject={onClose}
        disableReject={isAddDriverFetching}
        disableResolve={drivers.length < 1}
        fullWidth
      />
    </Modal>
  )
}
