import { handleActions } from 'redux-actions'

import {
  Payloads,
  SET_PAGE,
} from 'settings/features/subscriptionEvents/actions'

export interface SubscriptionEventsState {
  limit: number
  page: number
}

const initialState: SubscriptionEventsState = {
  limit: 20,
  page: 1,
}

const handleSetPage = (
  state: SubscriptionEventsState,
  action: SET_PAGE
): SubscriptionEventsState => ({
  ...state,
  page: action.payload,
})

export default handleActions<SubscriptionEventsState, Payloads>(
  {
    [SET_PAGE]: handleSetPage,
  },
  initialState
)
