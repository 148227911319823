import React from 'react'
import { connect } from 'react-redux'

import { PaymentSettings } from 'api/types'
import { State } from 'reducers'

import { apiSelectors } from 'api'

type OwnProps = {
  settingsId: string
  children(data?: PaymentSettings | null): React.ReactElement
}

const mapStateToProps = (state: State, ownProps: OwnProps) => {
  return {
    data: apiSelectors.park.paymentSettingsAll.selectSettingsById(
      state,
      ownProps.settingsId
    ),
  }
}

export type ContainerProps = ReturnType<typeof mapStateToProps> & OwnProps

export const PurePaymentSettingsCardConnector: React.FC<ContainerProps> = ({
  children,
  data,
}) => children(data)

export const PaymentSettingsCardConnector = connect(mapStateToProps)(
  PurePaymentSettingsCardConnector
)
