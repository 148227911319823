import React, { useEffect } from 'react'
import { Field, InjectedFormProps, SubmissionError } from 'redux-form'

import {
  CaptionText,
  ListGroup,
  RejectResolveButtons,
  SubCaptionText,
  TransparentButton,
} from 'ui-kit/components'
import { AggregatorType, aggregatorTypeToString } from 'common/enums'
import ListInput from 'common/components/formItems/ListInput'
import { maxLength20, required, uza } from 'common/utils/fieldValidation'
import { HBox, Modal, Row, Tooltip } from 'shared/ui'
import {
  ContainerProps,
  FormProps,
} from 'settings/features/aggregatorsList/containers/AddAggregatorModalContainer'
import { Dispatch } from 'redux'
import styled from 'styled-components'

const contentContainer = styled.div`
  top: calc(100% + 8px) !important;
  bottom: auto !important;
  right: auto !important;
  left: 0 !important;
  width: 296px !important;
  box-shadow: 0 3px 12px rgba(5, 16, 34, 0.06) !important;
`

const Container = styled.div`
  padding: 16px;
`

const SingleContainer = styled(Container)`
  padding-top: 0;
  padding-bottom: 0;
`

const addMozenAddressToClipBoard = () => {
  const el = document.createElement('textarea')
  el.value =
    '92.53.80.67\n' +
    '92.53.80.68\n' +
    '92.53.80.69\n' +
    '31.172.135.154\n' +
    '78.155.203.154\n' +
    '78.155.203.157'

  el.setAttribute('readonly', '')
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

export const AddAggregatorModal: React.FC<
  InjectedFormProps<FormProps, ContainerProps> & ContainerProps
> = ({
  valid,
  submitting,
  handleSubmit,
  currentId,
  addType,
  aggregatorsList,
  isFetching,
  fetchAggregator,
  closeModal,
  onSave,
  openDeleteAggregatorModal,
}) => {
  const aggregator = currentId ? aggregatorsList[currentId] : null
  const aggregatorType = aggregator?.type
    ? aggregator.type
    : AggregatorType.Yandex
  const type = addType || aggregatorType

  useEffect(() => {
    if (currentId) {
      fetchAggregator(currentId)
    }
  }, [currentId, fetchAggregator])

  const handleSave = (newType: AggregatorType) => async (
    values: FormProps,
    _dispatch: Dispatch<never>,
    props: ContainerProps
  ) => {
    try {
      await onSave({ type: newType, id: props.currentId, values })
    } catch (e) {
      throw new SubmissionError(e)
    }
  }

  return (
    <Modal
      open
      onClose={closeModal}
      noOverlayClick={false}
      closeButton
      overflow="initial"
      title={
        currentId
          ? 'Редактирование аккаунта'
          : `Новый аккаунт ${aggregatorTypeToString(type)}`
      }
      width={390}
      isLoading={isFetching}
      noPadding
    >
      {type === AggregatorType.Yandex && (
        <Container>
          <Field
            name="api_client_id"
            label="Client-ID"
            validate={required}
            iconName="close"
            component={ListInput}
            autoComplete="off"
          />
          <HBox x={0.5} />
          <Field
            name="api_key"
            label="Ключ API"
            validate={required}
            component={ListInput}
            autoComplete="off"
          >
            <Tooltip
              content="Ключ API и Client-ID высылает служба поддержки Яндекс.Такси или Uber или вы можете найти их в своей диспетчерской"
              contentComponent={contentContainer}
            />
          </Field>
          <HBox x={0.5} />
          <Field
            name="db"
            label="Park-ID"
            validate={required}
            component={ListInput}
            disabled={!!currentId}
            autoComplete="off"
          >
            <Tooltip
              content="Часть ссылки в диспетчерской (park = PARK-ID)"
              contentComponent={contentContainer}
            />
          </Field>
          <HBox x={0.5} />
          <Field
            name="account"
            label="Описание"
            validate={[required, uza]}
            component={ListInput}
            autoComplete="off"
          >
            <Tooltip
              content="Поможет различать аккаунты Yandex, если у вас их несколько."
              contentComponent={contentContainer}
            />
          </Field>
        </Container>
      )}
      {type === AggregatorType.Citymobil && (
        <>
          <ListGroup>1. Предупредите Ситимобил</ListGroup>
          <Container>
            <CaptionText>
              Напишите письмо в техническую поддержку Ситимобил. Предупредите,
              что используете Mozen, и вставьте в письмо наши адреса в
              интернете:
            </CaptionText>
            <HBox x={2 / 3} />
            <TransparentButton
              iconName="copy"
              onClick={addMozenAddressToClipBoard}
            >
              Скопировать адреса Mozen
            </TransparentButton>
            <HBox x={2 / 3} />
            <CaptionText>
              Дождитесь ответа. Мы не сможем подключить аккаунт, пока Ситимобил
              не ответит вам на письмо.
            </CaptionText>

            <HBox x={0.5} />
            <CaptionText color="gray6">
              Читать подробнее:
              <br />
              <a
                href="https://help.mozen.io/dobavlieniie-akkaunta-sitimobil/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Добавление аккаунта Ситимобил
              </a>
            </CaptionText>
          </Container>

          <HBox x={1 / 3} />
          <ListGroup>2. Добавьте данные аккаунта</ListGroup>
          <Container>
            <Field
              name="api_key"
              label="Ключ API"
              validate={required}
              iconName="close"
              component={ListInput}
              autoComplete="off"
            />
            <HBox x={0.5} />
            <Field
              name="account"
              label="Описание"
              validate={[required, maxLength20]}
              component={ListInput}
              autoComplete="off"
            >
              <Tooltip
                content="Поможет различать аккаунты Ситимобил, если у вас их несколько."
                contentComponent={contentContainer}
              />
            </Field>
          </Container>
        </>
      )}
      {type === AggregatorType.DiDi && (
        <SingleContainer>
          <Field
            name="client_id"
            label="Телефон, указанный в DiDi"
            validate={required}
            iconName="close"
            component={ListInput}
            autoComplete="off"
          />
          <Field
            name="client_secret"
            label="Пароль от личного кабинета DiDi"
            validate={required}
            iconName="close"
            component={ListInput}
            autoComplete="off"
          />
          <Field
            name="api_key"
            label="API Ключ"
            validate={required}
            component={ListInput}
            autoComplete="off"
          />
          <Field
            name="account"
            label="Описание"
            validate={[required, maxLength20]}
            component={ListInput}
            autoComplete="off"
          >
            <Tooltip
              content="Поможет различать аккаунты DiDi, если у вас их несколько."
              contentComponent={contentContainer}
            />
          </Field>
        </SingleContainer>
      )}

      <Container>
        {currentId && (
          <>
            <Row>
              <TransparentButton
                iconName="deletion"
                textColor="red"
                onClick={() => openDeleteAggregatorModal()}
              >
                Удалить аккаунт
              </TransparentButton>
            </Row>
            <HBox x={2 / 3} />
          </>
        )}

        <SubCaptionText color="gray6">
          Персональные данные, которые вы предоставляете Mozen, защищены
          SSL-протоколом шифрования данных.
        </SubCaptionText>

        <HBox x={2 / 3} />
        <RejectResolveButtons
          fullWidth
          rejectText={currentId ? 'Отменить' : 'Закрыть'}
          resolveText={currentId ? 'Сохранить' : 'Добавить'}
          onReject={closeModal}
          onResolve={handleSubmit(handleSave(type))}
          disableReject={submitting}
          disableResolve={!valid || submitting}
          isLoading={submitting}
        />
      </Container>
    </Modal>
  )
}
