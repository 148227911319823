import React, { useEffect, useRef, useState } from 'react'
import { MyThunkDispatch } from 'api/reducer'
import { State } from 'reducers'
import { connect } from 'react-redux'
import GhostContentAPI, { PostOrPage } from '@tryghost/content-api'
import { apiActions, apiSelectors } from 'api'
import { PaymentOrderType } from 'api/park/payment-order/get/types'
import { BotType } from 'api/park/notify-bot/post/types'
import { PaginationLimit } from 'api/types'
import { DepositType, mappedDepositType } from 'api/fuel/deposit-history/types'
import { FuelSettings } from 'api/fuel/settings/types'
import { FuelSummary } from 'api/fuel/summary/types'
import {
  FuelDepositType,
  FuelHistory,
  TableData,
} from 'ui-kit/organisms/FuelTransactions'
import { selectFuelHistory } from 'fuel/selectors'

const api = new GhostContentAPI({
  url: 'https://mozen.io',
  key: '0208c582905e5b97bafd17d1af',
  version: 'v3',
})

type FuelData = {
  summary: FuelSummary
  settings: FuelSettings
  fuelHistory: TableData<FuelHistory>
  updateHistory: (
    limit: number,
    depositType?: FuelDepositType,
    offset?: number
  ) => Promise<void>
  downloadOrder: (amount: number) => Promise<void>
  updateSettings: (percent: number) => Promise<void>
}

type LandingData = {
  data: PostOrPage
  onMoreDetails?: () => Promise<void>
}

type ChildProps = {
  isPreload?: boolean
  landing?: LandingData
  fuel?: FuelData
}

type OwnProps = {
  children(props: ChildProps): React.ReactElement
}

export const PureFuelConnector = React.memo<ConnectorProps>(
  ({
    children,
    settings,
    fuelHistory,
    summary,
    fetchFuelHistory,
    fetchFuelSettings,
    fetchFuelSummary,
    fetchNotifyBot,
    fetchPaymentOrder,
    fetchUpdateSettings,
  }) => {
    const [cmsContent, setCmsContent] = useState<PostOrPage>()

    const initialRender = useRef(true)
    useEffect(() => {
      if (!initialRender.current) {
        return
      }

      const fetchCmsContent = async () => {
        const response = await api.pages.read({
          id: '615af48bca0a3f06d473c263',
        })
        setCmsContent(response)
      }

      const loadData = async () => {
        if (summary) {
          await Promise.all([fetchFuelHistory(50), fetchFuelSettings()])
        } else {
          await fetchCmsContent()
        }
      }

      loadData()
      initialRender.current = false
    }, [fetchFuelHistory, fetchFuelSettings, fetchFuelSummary, summary])

    const getMoreDetails = async () => {
      await fetchNotifyBot()
    }
    const updateHistory = async (
      limit: number,
      depositType?: FuelDepositType,
      offset?: number
    ) => {
      await fetchFuelHistory(
        limit as PaginationLimit,
        offset,
        depositType !== undefined ? mappedDepositType[depositType] : undefined
      )
    }
    const downloadOrder = async (amount: number) => {
      await fetchPaymentOrder(amount)
    }
    const updateSettings = async (cashBack: number) => {
      await fetchUpdateSettings(cashBack)
      await fetchFuelSettings()
    }

    let landingData: LandingData | undefined
    let fuelData: FuelData | undefined

    if (summary && fuelHistory && settings) {
      fuelData = {
        settings,
        summary,
        fuelHistory,
        updateHistory,
        downloadOrder,
        updateSettings,
      }
    }

    if (cmsContent) {
      landingData = {
        data: cmsContent,
        onMoreDetails: getMoreDetails,
      }
    }

    return children({ landing: landingData, fuel: fuelData })
  }
)

PureFuelConnector.displayName = 'PureFuelConnector'

const mapStateToProps = (state: State) => ({
  summary: apiSelectors.fuel.getFuelSummary.selectData(state),
  isSummaryFetching: apiSelectors.fuel.getFuelSummary.selectIsFetching(state),
  settings: apiSelectors.fuel.getFuelSettings.selectData(state),
  fuelHistory: selectFuelHistory(state),
})
const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  fetchFuelSummary: () =>
    dispatch(apiActions.fuel.getFuelSummary.handleRequest()),
  fetchFuelSettings: () =>
    dispatch(apiActions.fuel.getFuelSettings.handleRequest()),
  fetchUpdateSettings: (cashbackPercent: number) =>
    dispatch(
      apiActions.fuel.postFuelSettings.handleRequest({
        cashback_percent: cashbackPercent,
      })
    ),
  fetchPaymentOrder: (amount: number) =>
    dispatch(
      apiActions.park.getPaymentOrder.handleRequest({
        payment_order_type: PaymentOrderType.Fuel,
        amount,
      })
    ),
  fetchNotifyBot: () =>
    dispatch(
      apiActions.park.postNotifyBot.handleRequest({
        notification_type: BotType.Fuel,
      })
    ),
  fetchFuelHistory: (
    limit: PaginationLimit,
    offset?: number,
    depositType?: DepositType
  ) =>
    dispatch(
      apiActions.fuel.postDepositHistory.handleRequest({
        limit,
        offset,
        deposit_types_filter: depositType,
      })
    ),
})

export type ConnectorProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps

export const FuelConnector = connect(
  mapStateToProps,
  mapDispatchToProps
)(PureFuelConnector)
