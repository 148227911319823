import React from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'

import { NavigationPayload } from 'common/types/local/navigation'
import { BodyText, Icon } from 'ui-kit/components'
import styled from 'styled-components'

interface LinkProps extends NavLinkProps {
  className: string
  children: JSX.Element | string | number
}

const Container = styled((props: LinkProps) => (
  <NavLink {...props}>{props.children}</NavLink>
))`
  position: relative;
  text-decoration: none;
  width: 100%;
  display: block;
  &.muk-side-menu-link-active {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: ${({ theme }) => theme.pallete.darkGray};
      opacity: 0.1;
    }
    &:before {
      display: block;
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 3px;
      background-color: ${({ theme }) => theme.pallete.blue};
    }

    .muk-side-menu-link-icon {
      svg {
        path {
          fill: ${({ theme }) => theme.pallete.blue};
        }
      }
    }

    .muk-icon-title {
      color: ${({ theme }) => theme.pallete.white};
    }
  }

  @media (max-width: 1024px) {
    .muk-icon-title {
      display: none;
    }
  }
`

const LinkContent = styled.span`
  display: flex;
  align-items: center;
  padding: 16px 16px 16px 24px;
  word-break: break-word;

  .muk-side-menu-link-icon {
    pointer-events: none;
    margin-right: 16px;

    svg {
      path {
        fill: ${({ theme }) => theme.pallete.darkGray};
      }
    }
  }

  .muk-icon-title {
    font-weight: 600;
    transition: 0.3s ease;
    color: ${({ theme }) => theme.pallete.darkGray};
    font-size: 12px;
    line-height: 24px;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
`

type Props = {
  item: NavigationPayload
}

export const Link: React.FC<Props> = ({ item }) => (
  <Container
    exact={item.exact}
    to={item.state ? { pathname: item.href, state: item.state } : item.href}
    className="muk-side-menu-link"
    activeClassName="muk-side-menu-link-active"
  >
    <LinkContent>
      <Icon className="muk-side-menu-link-icon" name={item.iconName} />
      <BodyText className="muk-icon-title">{item.text}</BodyText>
    </LinkContent>
  </Container>
)
