import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  overflow-x: scroll;
`

const Content = styled.div`
  display: inline-block;
  margin: ${({ theme }) => theme.paddings.main}px;
  width: calc(100% - ${({ theme }) => theme.paddings.main * 2}px);
  min-width: calc(1024px - 72px - ${({ theme }) => theme.paddings.main * 2}px);
  min-height: calc(100vh - ${({ theme }) => theme.paddings.main * 2}px);
`

type Props = {
  children: React.ReactNode
  margin?: number
}

export const PageTemplate = React.forwardRef<HTMLDivElement, Props>(
  ({ children, margin }, ref) => {
    return (
      <Wrapper ref={ref}>
        <Content>{children}</Content>
      </Wrapper>
    )
  }
)
PageTemplate.displayName = 'PageTemplate'

export default React.memo(PageTemplate)
