import React from 'react'

import { Icon, Limitation } from 'ui-kit/components'
import { Driver } from 'common/types/api/models/driver'

import {
  ContentCard,
  ContentCardFooter as DefaultContentCardFooter,
  ContentCardHeader,
} from 'shared/ui'
import {
  Listing,
  NoDrivers,
  Pagination,
} from 'settings/features/paymentSettingsDriversList/molecules'
import { PaginationLimit } from 'api/types'
import styled from 'styled-components'

const ContentCardFooter = styled(DefaultContentCardFooter)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

interface Props {
  drivers: Driver[]
  page: number
  limit: PaginationLimit
  driversIsLoading: boolean
  totalPages: number
  onRowClick: (driver: Driver) => void
  onRemove?: (driver: Driver) => void
  onAddDriver?: () => void
  onChangePage: (direction: 'prev' | 'next') => void
  onChangeLimit: (limit: PaginationLimit) => void
}

export const DriversList: React.FC<Props> = (props) => {
  const {
    onRowClick,
    onAddDriver,
    onChangePage,
    onChangeLimit,
    page,
    limit,
    totalPages,
    drivers,
    driversIsLoading,
    onRemove,
  } = props

  return (
    <ContentCard>
      <ContentCardHeader title="Водители">
        {onAddDriver !== undefined && (
          <Icon name="add" color="blue" pointer onClick={onAddDriver}>
            Добавить
          </Icon>
        )}
      </ContentCardHeader>
      <Listing
        onRowClick={onRowClick}
        onRemove={onRemove}
        drivers={drivers}
        driversIsLoading={driversIsLoading}
      />
      {totalPages ? (
        <ContentCardFooter>
          <Pagination
            totalPages={totalPages}
            page={page}
            onChangePage={onChangePage}
          />
          <Limitation currentLimit={limit} limitAction={onChangeLimit} />
        </ContentCardFooter>
      ) : null}
      {!driversIsLoading && !totalPages ? <NoDrivers /> : null}
    </ContentCard>
  )
}
