import React from 'react'
import { HBox, VBox, Col, Row } from 'shared/ui'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

export interface Props {
  toolbar?: React.ReactNode
}

export const DriversTemplate: React.FC<Props> = ({ toolbar, children }) => {
  return (
    <Wrapper>
      <div style={{ flex: 1, minHeight: '100vh' }}>{children}</div>
      {toolbar ? (
        <Col>
          <HBox />
          <Row>
            {toolbar}
            <VBox x={0.6} />
          </Row>
        </Col>
      ) : null}
    </Wrapper>
  )
}
