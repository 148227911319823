import React from 'react'
import { Icon } from 'ui-kit/components'
import styled, { css } from 'styled-components'

const absoluteStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

const Wrapper = styled.div<{ absolute?: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) => props.absolute && absoluteStyle};

  .main-loader {
    animation: spinLoader 0.4s linear infinite;

    @keyframes spinLoader {
      100% {
        transform: rotate(360deg);
      }
    }
  }
`

interface Props {
  absolute?: boolean
}

export const Loader: React.FC<Props> = ({ absolute }) => (
  <Wrapper absolute={absolute}>
    <Icon name="colorLoad" className="main-loader" />
  </Wrapper>
)
