import React from 'react'
import axios from 'axios'
import { RouteComponentProps } from 'react-router'

import { history } from 'store'

import { RoutesPath } from 'common/enums'

import EmailConfirmationComponent from 'auth/emailConfirmation/components/EmailConfirmationComponent'

import queryString from 'query-string'

interface LocalState {
  isLoading?: boolean
}

class EmailConfirmationContainer extends React.Component<
  RouteComponentProps,
  LocalState
> {
  constructor(props: RouteComponentProps) {
    super(props)

    this.state = {
      isLoading: false,
    }
  }

  componentDidMount() {
    const { location } = this.props
    const parsedData = queryString.parse(location.search)

    if (!parsedData.code) {
      return
    }

    this.setState({ isLoading: true })

    axios
      .post('auth/1/signup/email/confirm', {
        confirmCode: parsedData.code,
      })
      .catch(() => history.push(RoutesPath.MainPage))
      .then(() => this.setState({ isLoading: false }))
  }

  render() {
    const { isLoading } = this.state
    return !isLoading && <EmailConfirmationComponent />
  }
}

export default EmailConfirmationContainer
