import React from 'react'
import { Link } from 'react-router-dom'

import { RoutesPath } from 'common/enums'
import { Image } from 'shared/ui'
import { AuthLayout } from 'shared/router'

import {
  BackToMain,
  ImageHolder,
  LeftContent,
  LeftContentWrapper,
  RightContent,
} from 'auth/emailConfirmation/styles'
import peopleSrc from 'static/backgrounds/people.svg'

import { BodyText, ListItem, ListText, TitleText } from 'ui-kit/components'

const EmailConfirmationComponent: React.FC = () => {
  const renderLeftSideContent = (): JSX.Element => {
    return (
      <LeftContentWrapper>
        <LeftContent>
          <ListItem>
            <TitleText className="title" fontWeight="semi-bold">
              Почта успешно подтверждена
            </TitleText>
          </ListItem>
          <ListText className="description">
            Менеджер свяжется с вами в ближайшее время
          </ListText>
          <BackToMain>
            <Link to={RoutesPath.SignIn}>
              <BodyText color="blue" fontWeight="semi-bold">
                Вернуться на главную
              </BodyText>
            </Link>
          </BackToMain>
        </LeftContent>
      </LeftContentWrapper>
    )
  }

  const renderRightSideContent = (): JSX.Element => {
    return (
      <RightContent>
        <ImageHolder>
          <Image
            src={peopleSrc}
            height="50vh"
            width="calc(100% + 78px);"
            objectFit="contain"
          />
        </ImageHolder>
      </RightContent>
    )
  }

  return (
    <AuthLayout
      leftSideContent={renderLeftSideContent()}
      rightSideContent={renderRightSideContent()}
    />
  )
}

export default EmailConfirmationComponent
