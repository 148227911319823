import { handleActions } from 'redux-actions'

import { EdgvrApiKeyResponse, TApiStatus } from 'api/types'

import {
  FAILURE,
  RESET,
  RESET_STATUS,
  START,
  SUCCESS,
  TFailure,
  TPayloads,
  TUpdate,
  UPDATE,
} from 'api/park/edgvr-api-key/actions'

export interface EdgvrApiKeyState {
  status: TApiStatus
  data: EdgvrApiKeyResponse | null
  error: any
}

const initialState: EdgvrApiKeyState = {
  status: null,
  data: null,
  error: null,
}

const handleUpdate = (
  state: EdgvrApiKeyState,
  action: TUpdate
): EdgvrApiKeyState => ({
  ...state,
  data: action.payload,
})
const handleStart = (state: EdgvrApiKeyState): EdgvrApiKeyState => ({
  ...state,
  status: 'loading',
})
const handleSuccess = (state: EdgvrApiKeyState): EdgvrApiKeyState => ({
  ...state,
  status: 'success',
})
const handleFailure = (
  state: EdgvrApiKeyState,
  action: TFailure
): EdgvrApiKeyState => ({
  ...state,
  status: 'failure',
  error: action.payload,
})
const handleReset = (): EdgvrApiKeyState => initialState
const handleResetStatus = (state: EdgvrApiKeyState): EdgvrApiKeyState => ({
  ...state,
  status: null,
})

export const reducer = handleActions<EdgvrApiKeyState, TPayloads>(
  {
    [UPDATE]: handleUpdate,
    [START]: handleStart,
    [SUCCESS]: handleSuccess,
    [FAILURE]: handleFailure,
    [RESET]: handleReset,
    [RESET_STATUS]: handleResetStatus,
  },
  initialState
)
