import React from 'react'
import {
  FormErrors,
  InjectedFormProps,
  reduxForm,
  SubmissionError,
} from 'redux-form'

import { ContentCard, ContentCardBody } from 'shared/ui/index'
import { FormNames } from 'common/enums'
import Alert from 'react-s-alert'
import { FormSubmitHandler } from 'redux-form/lib/reduxForm'
import { PaymentSettingsSwitchesFields } from 'shared/ui/paymentSettingsEditor/molecules'

interface OwnProps {
  isLocked?: boolean
  withAllowAutoTransfer?: boolean
  canChangePaymentsConditions?: boolean
  onSubmit: (values: FormProps) => Promise<void>
  onChangePristine?: (isPristine: boolean) => void
}
export type FormProps = {
  id: string
  withdraw_restricted?: boolean
  auto_withdraw?: boolean
}
type Props = InjectedFormProps<FormProps, OwnProps> & OwnProps
export const PaymentSwitchSettingsForm = ({
  handleSubmit,
  initialize,
  change,
  isLocked,
  withAllowAutoTransfer,
  canChangePaymentsConditions,
  onSubmit,
}: Props) => {
  const handleSave = async (
    newValue: boolean,
    prevValue: boolean,
    name: string
  ) => {
    const submit: FormSubmitHandler<FormProps> = async (values) => {
      try {
        await onSubmit({ id: values.id, [name]: newValue })
        initialize({ ...values, [name]: newValue })
      } catch (e) {
        change(name, prevValue)
        throw new SubmissionError(e)
      }
    }

    handleSubmit(submit)()
  }

  return (
    <ContentCard withMinHeight={false}>
      <ContentCardBody>
        <PaymentSettingsSwitchesFields
          isLocked={isLocked}
          canChangePaymentsConditions={canChangePaymentsConditions}
          withAllowAutoTransfer={withAllowAutoTransfer}
          onSave={handleSave}
        />
      </ContentCardBody>
    </ContentCard>
  )
}

export default reduxForm<FormProps, OwnProps>({
  form: FormNames.PaymentsSwitches,
  enableReinitialize: true,
  onSubmitSuccess() {
    Alert.info('Изменения сохранены')
  },
  onSubmitFail(errors: FormErrors<FormProps> | undefined) {
    if (!errors) return

    Alert.error('Ошибка', {
      customFields: {
        text: 'Не удалось сохранить',
      },
    })
  },
})(PaymentSwitchSettingsForm)
