import { handleActions } from 'redux-actions'

import { BalanceLogResponse, TApiStatus } from 'api/types'

import {
  FAILURE,
  RESET,
  RESET_STATUS,
  START,
  SUCCESS,
  TFailure,
  TPayloads,
  TUpdate,
  UPDATE,
} from 'api/park/balance/log/actions'
import { HANDLE_REQUEST } from 'api/park/balance/log/thunks'

export interface BalanceLogState {
  status: TApiStatus
  data: BalanceLogResponse | null
  error: any
}

const initialState: BalanceLogState = {
  status: null,
  data: null,
  error: null,
}

const handleUpdate = (
  state: BalanceLogState,
  action: TUpdate
): BalanceLogState => ({
  ...state,
  data: action.payload,
})
const handleStart = (state: BalanceLogState): BalanceLogState => ({
  ...state,
  status: 'loading',
})
const handleSuccess = (state: BalanceLogState): BalanceLogState => ({
  ...state,
  status: 'success',
})
const handleFailure = (
  state: BalanceLogState,
  action: TFailure
): BalanceLogState => ({
  ...state,
  status: 'failure',
  error: action.payload,
})
const handleReset = (): BalanceLogState => initialState
const handleResetStatus = (state: BalanceLogState): BalanceLogState => ({
  ...state,
  status: null,
})

const handleRequest = (state: BalanceLogState): BalanceLogState => ({
  ...state,
})

export const reducer = handleActions<BalanceLogState, TPayloads>(
  {
    [UPDATE]: handleUpdate,
    [START]: handleStart,
    [SUCCESS]: handleSuccess,
    [FAILURE]: handleFailure,
    [RESET]: handleReset,
    [RESET_STATUS]: handleResetStatus,
    [HANDLE_REQUEST]: handleRequest,
  },
  initialState
)
