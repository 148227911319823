import Alert from 'react-s-alert'
import { apiActions } from 'api'
import { MyThunkDispatch, MyThunkResult } from 'api/reducer'
import { ModalsNames } from 'common/enums'
import { modalsActions } from 'shared/modals'

export const onConfirmTransaction: ({
  confirmed,
  id,
  callbackFetch,
}: any) => MyThunkResult<Promise<void | boolean>> = ({
  confirmed,
  id,
  callbackFetch,
}) => async (dispatch: MyThunkDispatch) => {
  try {
    await dispatch(
      apiActions.park.transactionsConfirm.handleRequest({
        data: {
          confirmed,
          transaction_ids: [id],
        },
      })
    )
    dispatch(modalsActions.closeModal(ModalsNames.PaymentDetails))
    callbackFetch()
  } catch (e) {
    Alert.error('Ошибка', {
      customFields: {
        text: 'Что-то пошло не так',
      },
    })
  }
}

export const closeModalWithCallback: (
  withCallback: boolean,
  callbackFetch: () => void
) => MyThunkResult<Promise<void>> = (withCallback, callbackFetch) => async (
  dispatch
) => {
  if (withCallback) {
    callbackFetch()
  }
  dispatch(modalsActions.closeModal(ModalsNames.PaymentDetails))
}
