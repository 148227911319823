import styled from 'styled-components'

interface Wrapper {
  borderBottom?: boolean
}

export const Wrapper = styled('div')<Wrapper>`
  position: relative;
  height: 56px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  box-sizing: border-box;

  .bar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    width: 100%;
    margin: 0 auto;
    background-color: ${(props) =>
      props.borderBottom ? props.theme.pallete.gray3 : 'transparent'};
    transition: background-color 0.3s ease-in-out;
  }

  .loader {
    animation: spinLoader 0.4s linear infinite;

    @keyframes spinLoader {
      100% {
        transform: rotate(360deg);
      }
    }
  }
`

interface InputWrapper {
  isLoading?: boolean
}

export const InputWrapper = styled('div')<InputWrapper>`
  margin-left: 16px;
  width: ${(props) => (!props.isLoading ? '100%' : 'calc(100% - 24px)')};
  margin-right: ${(props) => (!props.isLoading ? '0' : '16px')};
`

export const Input = styled('input')`
  font-family: 'Montserrat', sans-serif;
  background: none;
  color: ${({ theme }) => theme.pallete.black};
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  outline: none;

  &:focus ~ .bar {
    width: calc(100% - 32px);
    background-color: ${({ theme }) => theme.pallete.blue} !important;
  }
`
