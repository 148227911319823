import React from 'react'

import { BodyText } from 'ui-kit/components'
import { Image } from 'shared/ui'
import styled from 'styled-components'

import handSrc from 'static/icons/hand.svg'

const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 38px;
`
const TextWrapper = styled.div`
  text-align: center;
  width: 472px;
  padding: 8px;
`
const ImageWrapper = styled.div`
  width: 116px;
  height: 116px;
`

export const EmptyListing: React.FC = () => (
  <Container>
    <TextWrapper>
      <BodyText color="gray8" fontWeight="medium">
        Добавьте ваш первый аккаунт, нажав на&nbsp;кнопку Добавить аккаунт
      </BodyText>
    </TextWrapper>
    <ImageWrapper>
      <Image
        src={handSrc}
        alt="hand"
        width="100%"
        height="100%"
        objectFit="cover"
      />
    </ImageWrapper>
  </Container>
)
