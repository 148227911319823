import React, { useCallback } from 'react'

import { Column, Table } from 'shared/table'
import { Checkbox } from 'ui-kit/components'
import { getWidthInPercent } from 'common/utils/formatters'
import { difference } from 'lodash'
import {
  TransactionAmount,
  TransactionDate,
  TransactionReason,
  TransactionStatus,
} from 'payments/features/confirmationList/molecules'
import { PaymentTransaction } from 'api/types'

export const formatDate = (transaction: PaymentTransaction) => (
  <TransactionDate created={transaction.created_at} />
)

export const formatReason = (transaction: PaymentTransaction) => (
  <TransactionReason
    from={transaction.legs.from_participant}
    to={transaction.legs.to_participant}
    reason={transaction.reason}
  />
)

export const formatAmount = (transaction: PaymentTransaction) => (
  <TransactionAmount amount={transaction.amount} />
)

const blockWidth = 1064

type Props = {
  data: PaymentTransaction[]
  records: Set<string>
  isFetching?: boolean
  canViewTransactionDetails?: boolean
  canProcessTransactions?: boolean
  onRowClick: (transaction: PaymentTransaction) => void
  onChangeCheckbox: (changed: Set<string>) => void
  onConfirmPayment: (isConfirmed: boolean, id: string) => void
}
export const ConfirmationTable: React.FC<Props> = ({
  data,
  records,
  isFetching,
  canViewTransactionDetails,
  canProcessTransactions,
  onChangeCheckbox,
  onConfirmPayment,
  onRowClick,
}) => {
  const formatStatus = React.useCallback(
    (transaction: PaymentTransaction) => (
      <TransactionStatus
        id={transaction.id}
        status={transaction.status}
        onConfirmPayment={onConfirmPayment}
        canProcessTransactions={canProcessTransactions}
      />
    ),
    [canProcessTransactions, onConfirmPayment]
  )

  const handleChangeCheckbox = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const id = e.currentTarget.name
      const newRecords = new Set(records)

      if (e.currentTarget.checked) {
        newRecords.add(id)
      } else {
        newRecords.delete(id)
      }

      onChangeCheckbox(newRecords)
    },
    [onChangeCheckbox, records]
  )

  const handleChangeAllCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const allRecords: Set<string> = new Set()

    if (e.target.checked) {
      data.forEach((transaction: PaymentTransaction) => {
        allRecords.add(transaction.id)
      })
    }

    onChangeCheckbox(allRecords)
  }

  const isAllCheckboxChecked = !difference(
    data.map((item) => item.id),
    Array.from(records)
  ).length

  const renderCheckbox = React.useCallback(
    (transaction: PaymentTransaction) => {
      const isChecked = records.has(transaction.id) || false
      return (
        <div>
          <Checkbox
            name={transaction.id}
            checked={isChecked}
            onChange={handleChangeCheckbox}
          />
        </div>
      )
    },
    [records, handleChangeCheckbox]
  )

  return (
    <Table
      source={data}
      bodyMinHeight="336px"
      isLoading={isFetching}
      onClickRow={canViewTransactionDetails ? onRowClick : undefined}
      hideHeaderOnEmpty
    >
      {canProcessTransactions ? (
        <Column
          data={renderCheckbox}
          flex={`${getWidthInPercent(blockWidth, 56)}%`}
          className="checkbox"
          disableRowClick
        >
          <Checkbox
            name="all"
            checked={isAllCheckboxChecked}
            onChange={handleChangeAllCheckbox}
          />
        </Column>
      ) : null}
      <Column data={formatDate} flex={`${getWidthInPercent(blockWidth, 126)}%`}>
        Дата платежа
      </Column>
      <Column
        data={formatReason}
        flex={`${getWidthInPercent(blockWidth, 404)}%`}
      >
        Описание
      </Column>
      <Column
        data={formatAmount}
        flex={`${getWidthInPercent(blockWidth, 156)}%`}
      >
        Сумма, ₽
      </Column>
      <Column
        data={formatStatus}
        className="actions-wrap"
        disableRowClick
        flex={`${getWidthInPercent(blockWidth, 322)}%`}
      >
        Статус
      </Column>
    </Table>
  )
}
