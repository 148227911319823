import styled from 'styled-components'

interface Props {
  disabled?: boolean
  reverse?: boolean
}

export const Wrapper = styled('label')<Props>`
  position: relative;
  display: inline-flex;
  cursor: ${(props) => (!props.disabled ? 'pointer' : 'default')};
  align-items: center;

  input {
    &:disabled ~ .muk-check-mark {
      background-color: ${({ theme }) => theme.pallete.gray2} !important;
    }

    &:checked ~ .muk-checkbox-mark {
      background-color: ${({ theme }) => theme.pallete.blue};

      .muk-checkbox-mark-icon {
        opacity: 1;
      }
    }
  }

  .muk-checkbox-mark {
    width: 20px;
    height: 20px;
    margin: 2px;
    background-color: ${({ theme }) => theme.pallete.gray3};
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.2s ease-in-out;
    position: relative;

    &-icon {
      width: 18px !important;
      height: 18px !important;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
      cursor: ${(props) => (!props.disabled ? 'pointer' : 'default')};

      svg {
        width: 100%;
        height: 100%;

        path {
          fill: ${({ theme }) => theme.pallete.white};
        }
      }
    }
  }

  .muk-checkbox-label {
    margin-left: 16px;
  }

  ${(props) =>
    props.reverse &&
    `
        flex-direction: row-reverse;

        .muk-checkbox-label {
          margin-right: 8px;
          margin-left: 0;
        }
    `}
`
