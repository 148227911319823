import { connect } from 'react-redux'

import { State } from 'reducers'
import { MyThunkDispatch } from 'api/reducer'
import { apiSelectors } from 'api'
import { onDidMount } from 'settings/features/requisitesTab/thunks'
import { getIsFetching } from 'settings/features/requisitesTab/selectors'
import { RequisitesTab } from 'settings/features/requisitesTab/organisms'

const mapStateToProps = (state: State) => ({
  isFetching: getIsFetching(state),
  bankType: apiSelectors.park.details.getBankType(state),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  onDidMount: () => dispatch(onDidMount()),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const RequisitesTabContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RequisitesTab)
