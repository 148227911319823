import styled from 'styled-components'

export const Form = styled.form`
  max-width: 464px;
  width: 100%;

  .form-title {
    padding: 12px 16px;
    margin-bottom: 8px;
  }

  > div {
    margin-bottom: 8px;
  }
`

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const RightContent = styled.div`
  position: relative;
  height: calc(100vh - 170px);

  .sign-up-right-title {
    max-width: 520px;
    right: 0;
    position: absolute;
  }
`

export const ImageHolder = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  img {
    max-width: 684px;
    min-width: 591px;
  }
`

export const Action = styled.div`
  margin-bottom: 0 !important;
  margin-top: 24px;

  button {
    max-width: 320px;
  }
`

export const ActionLink = styled.div`
  margin-top: 16px;
  display: inline-block;

  p {
    cursor: pointer;
  }
`

export const Description = styled.div`
  min-height: 64px;
`
