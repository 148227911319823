import React, { useCallback, useState } from 'react'
import ListItem, {
  Props as ListItemProps,
} from 'ui-kit/atoms/ListItem/ListItem'

type OwnProps = {
  children: (isToggle?: boolean) => React.ReactNode
  onChange?: (isToggle?: boolean) => void
  initialState?: boolean
  toggleIcon?: React.ReactNode
}

type Props = ListItemProps & OwnProps

const ToggleListItem: React.FC<Props> = ({
  children,
  onClick,
  onChange,
  toggleIcon,
  action,
  initialState = false,
  ...cardItemProps
}) => {
  const [isToggle, setToggled] = useState(initialState)

  const toggle = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (onClick) {
        onClick(e)
      }
      setToggled((prevValue) => {
        return !prevValue
      })
      if (onChange) {
        onChange(!isToggle)
      }
    },
    [isToggle, onChange, onClick]
  )

  return (
    <ListItem
      {...cardItemProps}
      onClick={toggle}
      action={isToggle ? toggleIcon : action}
    >
      {children(isToggle)}
    </ListItem>
  )
}

export default React.memo<Props>(ToggleListItem)
