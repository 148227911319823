import React, { useCallback, useMemo, useState } from 'react'
import ToggleListItem from 'ui-kit/atoms/ToggleListItem/ToggleListItem'
import { Icon } from 'ui-kit/components'
import Mark from 'ui-kit/atoms/Mark/Mark'
import Typography from 'ui-kit/typography'
import CardItem from '../Item/Item'

type Props = {
  bankAccountNumber: string
  bankAccountOwner: string
  bankName: string
}

const BankRequisites = ({
  bankAccountNumber,
  bankAccountOwner,
  bankName,
}: Props) => {
  const [isHide, setHidden] = useState<boolean | undefined>(true)

  const handleChange = useCallback((isToggle?: boolean) => {
    setHidden(!isToggle)
  }, [])

  const render = useCallback(
    (isToggle?: boolean) => {
      if (isToggle) {
        return (
          <Typography variant="Caption3" fontColor="secondaryDarkBlue">
            Реквизиты
          </Typography>
        )
      }

      return (
        <Mark caption="Реквизиты">
          <Typography variant="Body1">
            Счет № {bankAccountNumber}, <br />
            {bankAccountOwner}
          </Typography>
        </Mark>
      )
    },
    [bankAccountNumber, bankAccountOwner]
  )

  return (
    <>
      <ToggleListItem
        icon={useMemo(
          () => (
            <Icon name="document" />
          ),
          []
        )}
        action={useMemo(
          () => (
            <Icon name="expandBoth" />
          ),
          []
        )}
        toggleIcon={useMemo(
          () => (
            <Icon name="expandBothUp" />
          ),
          []
        )}
        onChange={handleChange}
      >
        {render}
      </ToggleListItem>
      {!isHide && (
        <>
          <CardItem caption="Номер счета">{bankAccountNumber}</CardItem>
          <CardItem caption="Банк">{bankName}</CardItem>
          <CardItem caption="Владелец">{bankAccountOwner}</CardItem>
        </>
      )}
    </>
  )
}

export default React.memo<Props>(BankRequisites)
