import React from 'react'

import { HBox } from 'shared/ui'
import { Divider, Footer, Logo } from 'layout/features/sidebar/atoms'
import { UserInfo } from 'layout/features/sidebar/molecules'
import { Nav } from 'layout/features/sidebar/organisms'
import { DepositContainer } from 'layout/features/sidebar/containers'
import {
  getBottomNavigation,
  getNavigation,
} from 'layout/features/sidebar/data'
import { ContainerProps } from 'layout/features/sidebar/containers/SidebarContainer'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 240px;
  background-color: ${({ theme }) => theme.pallete.black};
  overflow-x: hidden;
  z-index: 99;

  @media (max-width: 1024px) {
    width: 72px;
  }
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 20px 0;
`

const Flex1 = styled.div`
  display: flex;
  flex: 1 1;
`

export const Sidebar: React.FC<ContainerProps> = ({
  profile,
  canViewTariff,
}) => (
  <Wrapper>
    <Header>
      <Logo />
      <HBox x={0.5} />
      <UserInfo
        full_name={profile?.full_name || undefined}
        email={profile?.email || undefined}
      />
    </Header>
    <Divider />
    {canViewTariff ? <DepositContainer /> : null}
    {profile?.permissions && <Nav menu={getNavigation(profile.permissions)} />}
    <Flex1 />
    <Divider />
    <Nav menu={getBottomNavigation()} />
    <Footer />
  </Wrapper>
)
