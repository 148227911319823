import React from 'react'
import { TableText } from 'ui-kit/components'
import { PaymentTransaction } from 'api/types'
import { currencyToBase, getWidthInPercent } from 'common/utils/formatters'
import { formatToRegularDate } from 'common/utils/dates'
import {
  PaymentReason,
  paymentReasonToString,
  paymentStatusToString,
} from 'common/enums'
import { Column, Table } from 'shared/table'
import { Col } from 'shared/ui'
import { renderParticipant } from 'common/utils/helpers'
import { TableCaptionText } from 'ui-kit/components/Typography/TableCaptionText/TableCaptionText'

interface Props {
  transactions: PaymentTransaction[]
  isFetching?: boolean
  handleRowClick?: (transaction: PaymentTransaction) => void
}

export const Listing: React.FC<Props> = ({
  transactions,
  isFetching,
  handleRowClick,
}) => {
  const blockWidth = 1064

  const formatDate = (transaction: PaymentTransaction): JSX.Element => (
    <TableText>{formatToRegularDate(transaction.created_at)}</TableText>
  )

  const formatReason = (transaction: PaymentTransaction): JSX.Element => {
    const {
      legs: { from_participant, to_participant },
    } = transaction

    const subtitle =
      transaction.reason !== PaymentReason.INITIAL_DRIVER
        ? `${renderParticipant(from_participant)}
          →
          ${renderParticipant(to_participant)}`
        : ''

    return (
      <Col>
        <TableText>{paymentReasonToString(transaction.reason)}</TableText>
        <TableCaptionText>{subtitle}</TableCaptionText>
      </Col>
    )
  }

  const formatStatus = (transaction: PaymentTransaction): JSX.Element => (
    <TableText>{paymentStatusToString(transaction.status)}</TableText>
  )

  const formatAmount = (transaction: PaymentTransaction): JSX.Element => (
    <TableText>{currencyToBase(transaction.amount)}</TableText>
  )
  return (
    <Table
      source={transactions}
      bodyMinHeight="336px"
      isLoading={isFetching}
      onClickRow={handleRowClick}
      hideHeaderOnEmpty
    >
      <Column data={formatDate} flex={`${getWidthInPercent(blockWidth, 136)}%`}>
        Дата платежа
      </Column>
      <Column
        data={formatReason}
        flex={`${getWidthInPercent(blockWidth, 544)}%`}
      >
        Описание
      </Column>
      <Column
        data={formatAmount}
        flex={`${getWidthInPercent(blockWidth, 224)}%`}
      >
        Сумма, ₽
      </Column>
      <Column
        data={formatStatus}
        flex={`${getWidthInPercent(blockWidth, 160)}%`}
      >
        Статус
      </Column>
    </Table>
  )
}
