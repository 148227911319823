import { createSelector } from 'reselect'
import { State } from 'reducers'

export const getIsFetching = (state: State) =>
  state.api.park.certificateGet.status === 'loading'

const getData = (state: State) => state.api.park.certificateGet.data

export const getCertificate = createSelector(getData, (data) => data || null)

export const getHasData = createSelector(getData, (data) => Boolean(data))
