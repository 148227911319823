import { connect } from 'react-redux'

import { State } from 'reducers'
import { apiSelectors } from 'api'
import { MyThunkDispatch } from 'api/reducer'
import { modalsActions, modalsSelectors } from 'shared/modals'
import { ModalsNames } from 'common/enums'
import { ParkCertificate } from 'settings/features/parkCertificate/organisms'

const mapStateToProps = (state: State) => ({
  canChange: apiSelectors.employee.profile.getCanChangeParkRequisites(state),
  isFetching: apiSelectors.park.certificateGet.getIsFetching(state),
  certificate: apiSelectors.park.certificateGet.getCertificate(state),
  isAddCertificateModalOpened: modalsSelectors.getIsModalOpened(
    state,
    ModalsNames.AddCertificate
  ),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  openAddCertificateModal: () =>
    dispatch(modalsActions.openModal(ModalsNames.AddCertificate)),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const ParkCertificateContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ParkCertificate)
