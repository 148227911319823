import { connect } from 'react-redux'
import { State } from 'reducers'
import { apiSelectors } from 'api'
import { DriverCards } from 'drivers/features/driverCards/organisms'

const mapStateToProps = (state: State) => ({
  cards: apiSelectors.driversById.get.getCards(state),
})

const mapDispatchToProps = () => ({})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const DriverCardsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DriverCards)
