import React, { useEffect, useReducer } from 'react'
import { Limitation, Pagination } from 'ui-kit/components'
import DateRangePicker from 'common/components/dateRangePicker/DateRangePicker'
import { toISOFormat } from 'common/utils/dates'
import { ContentCard, ContentCardBody, ContentCardHeader } from 'shared/ui'
import { Search } from 'shared/table'
import { Listing } from 'employees/features/employeesJournal/molecules'
import { ContainerProps } from 'employees/features/employeesJournal/containers/EmployeesJournalContainer'
import { PaginationLimit } from 'api/types'
import styled from 'styled-components'

const PaginatorWrapper = styled.div`
  display: flex;
  padding-left: ${({ theme }) => theme.paddings.half}px;
  padding-right: ${({ theme }) => theme.paddings.main}px;
  justify-content: space-between;
  align-items: center;
`

type StateType = {
  limit: PaginationLimit
  page: number
  search: string
  from: Date | null
  to: Date | null
}

type ActionType = {
  type: 'prev' | 'next' | 'limit' | 'search' | 'changeDates' | 'clearDates'
  limit?: PaginationLimit
  search?: string
  from?: Date
  to?: Date
}

const initialState: StateType = {
  limit: 20,
  page: 1,
  search: '',
  from: null,
  to: null,
}

function reducer(state: StateType, action: ActionType): StateType {
  switch (action.type) {
    case 'prev':
      return { ...state, page: state.page - 1 }
    case 'next':
      return { ...state, page: state.page + 1 }
    case 'limit':
      return {
        ...state,
        limit: action.limit ? action.limit : state.limit,
        page: 1,
      }
    case 'search':
      return {
        ...state,
        search: action.search ? action.search : state.search,
        page: 1,
      }
    case 'changeDates':
      return {
        ...state,
        from: action.from ? action.from : state.from,
        to: action.to ? action.to : state.to,
        page: 1,
      }
    case 'clearDates':
      return { ...state, from: null, to: null, page: 1 }
    default:
      return state
  }
}

export const EmployeesJournal: React.FC<ContainerProps> = ({
  employee_id,
  isFetching,
  logs,
  totalAmount,
  fetch,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const totalPages = totalAmount ? Math.ceil(totalAmount / state.limit) : 0
  useEffect(() => {
    fetch({
      params: {
        employee_id: employee_id || null,
        limit: state.limit,
        offset: state.limit * (state.page - 1),
        date_from: state.from ? toISOFormat(state.from) : null,
        date_to: state.to ? toISOFormat(state.to) : null,
        search: state.search,
        // filter:
      },
    })
  }, [
    employee_id,
    fetch,
    state.from,
    state.limit,
    state.page,
    state.search,
    state.to,
  ])

  const onNextPage = () => {
    dispatch({ type: 'next' })
  }
  const onPrevPage = () => {
    dispatch({ type: 'prev' })
  }
  const onLimit = (limit: PaginationLimit) => {
    dispatch({ type: 'limit', limit })
  }
  const onSetSearch = (search: string) => {
    dispatch({ type: 'search', search })
  }
  const onChangeDates = (from: Date, to: Date) => {
    if (from && to) {
      dispatch({ type: 'changeDates', from, to })
    }
  }
  const onClearDates = () => {
    dispatch({ type: 'clearDates' })
  }

  return (
    <ContentCard>
      <ContentCardHeader
        title={employee_id ? 'Активности сотрудника' : 'Все активности'}
      >
        <DateRangePicker
          className="date-range-picker"
          onChangeDate={onChangeDates}
          dateFrom={state.from || undefined}
          dateTo={state.to || undefined}
          onClearDates={onClearDates}
          withoutText
        />
      </ContentCardHeader>
      <Search borderBottom value={state.search} onSetSearch={onSetSearch} />
      <ContentCardBody isLoading={isFetching} noPadding>
        <Listing hasEmployeeId={Boolean(employee_id)} logs={logs} />

        {totalPages ? (
          <PaginatorWrapper>
            <Pagination
              currentPage={state.page}
              totalPages={totalPages}
              onClickPrev={onPrevPage}
              onClickNext={onNextPage}
            />
            <Limitation currentLimit={state.limit} limitAction={onLimit} />
          </PaginatorWrapper>
        ) : null}
      </ContentCardBody>
    </ContentCard>
  )
}
