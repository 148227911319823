import { AnyAction, combineReducers } from 'redux'
import { FormStateMap, reducer as formReducer } from 'redux-form'

import { RESET_ALL_STATES } from 'actions'

import { apiReducer, ApiState, TApiAction } from 'api/reducer'
import {
  settingsReducer,
  SettingsState,
  TSettingsAction,
} from 'settings/reducer'
import modalsReducer, {
  ModalsState,
  TModalsAction,
} from 'shared/modals/reducer'

import DriversListReducer, {
  DriversListState,
} from 'drivers/features/driversList/reducers'
import DriversListSettingsReducer, {
  DriversListSettingsState,
} from 'settings/features/paymentSettingsDriversList/reducers'

import DriverStatsReducer, {
  DriverStatsState,
} from 'drivers/features/driverStats/reducers'
import DriverTripsReducer, {
  DriverTripsState,
} from 'drivers/features/driverTrips/reducers'
import DriverPaymentsReducer, {
  DriverPaymentsState,
} from 'drivers/features/driverPayments/reducers'
import DriverReferralsReducer, {
  DriverReferralsState,
} from 'drivers/features/driverReferrals/reducers'

import AntiFraudSuspectReducer, {
  AntiFraudSuspectState,
} from 'antifraud/suspect/reducers'
import AntiFraudBlockedReducer, {
  AntiFraudBlockedState,
} from 'antifraud/blocked/reducers'
import AntiFraudDetailsModalReducer, {
  AntiFraudDetailsModalState,
} from 'antifraud/modals/paymentDetails/reducers'
import RouterReducer, { RouterState } from 'shared/router/reducers'
import SignInReducer, { SignInState } from 'auth/signIn/reducers'
import ManualConfirmationListReducer, {
  ManualConfirmationListState,
} from 'payments/features/manualConfirmationList/reducers'

export type TRootAction = TApiAction | TSettingsAction | TModalsAction

export interface State {
  api: ApiState
  settings: SettingsState
  modals: ModalsState
  form: FormStateMap
  driversListState: DriversListState
  driversListSettingsState: DriversListSettingsState
  driverStatsState: DriverStatsState
  driverTripsState: DriverTripsState
  driverPaymentsState: DriverPaymentsState
  driverReferralsState: DriverReferralsState
  antifraudSuspectState: AntiFraudSuspectState
  antifraudBlockedState: AntiFraudBlockedState
  antifraudDetailsModalState: AntiFraudDetailsModalState
  routerState: RouterState
  signInState: SignInState
  manualConfirmationListState: ManualConfirmationListState
}

const appReducer = combineReducers<State>({
  api: apiReducer,
  settings: settingsReducer,
  modals: modalsReducer,
  form: formReducer,
  driversListState: DriversListReducer,
  driversListSettingsState: DriversListSettingsReducer,
  driverStatsState: DriverStatsReducer,
  driverTripsState: DriverTripsReducer,
  driverPaymentsState: DriverPaymentsReducer,
  driverReferralsState: DriverReferralsReducer,
  antifraudSuspectState: AntiFraudSuspectReducer,
  antifraudBlockedState: AntiFraudBlockedReducer,
  antifraudDetailsModalState: AntiFraudDetailsModalReducer,
  routerState: RouterReducer,
  signInState: SignInReducer,
  manualConfirmationListState: ManualConfirmationListReducer,
})

export const rootReducer = (state: State | undefined, action: AnyAction) => {
  const appState = action.type === RESET_ALL_STATES ? undefined : state
  return appReducer(appState, action)
}
