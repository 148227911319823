import axios, { AxiosResponse } from 'axios'
import { BaseAction } from 'redux-actions'
import { ThunkAction } from 'redux-thunk'

import { State } from 'reducers'
import { DriversByIdGetResponse } from 'api/types'
import { failure, start, success, update } from 'api/driversById/get/actions'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  BaseAction
>
export const HANDLE_REQUEST: BaseAction['type'] =
  'api/driversById/get->HANDLE_REQUEST'

export const handleRequest = (driverId: string): THandleRequest => async (
  dispatch
) => {
  dispatch(start())
  try {
    const response: AxiosResponse<DriversByIdGetResponse> = await axios.get(
      `erp/1/drivers/${driverId}`
    )
    dispatch(update(response.data))
    dispatch(success())
  } catch (e) {
    if (e.response) {
      dispatch(failure(e.response.data))
    }
  }
}
