import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
`

export const ErrorLayoutWrapper = styled.div`
  background-color: ${({ theme }) => theme.pallete.white};
  width: 100%;
  height: 100vh;
  padding: 42px;
  overflow: hidden;

  .muk-button-transparent {
    min-height: 56px;
  }
`

export const ErrorLayoutHeader = styled.div`
  margin-bottom: 84px;

  .mozen-logo {
    width: 32px !important;
    height: 32px !important;

    .muk-icon {
      width: 100%;
      height: 100%;
    }
  }
`

export const ErrorLayoutSides = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

export const ErrorLayoutLeft = styled.div`
  max-width: 580px;
  padding-left: 74px;
  margin: 0 auto;
`

export const ErrorLayoutRight = styled.div`
  position: relative;
  left: -65px;
  max-width: 730px;
`

export const ErrorLayoutDescription = styled.div`
  padding: 0 16px;
  margin: 24px 0 48px 0;
  min-height: 194px;
`
