import { createSelector } from 'reselect'
import { State } from 'reducers'

export const getIsFetching = (state: State) =>
  state.api.park.paymentSettingsGet.status === 'loading'

const getData = (state: State) => state.api.park.paymentSettingsGet.data

export const getWithdrawRestricted = createSelector(getData, (data) =>
  data ? data.withdraw_restricted : false
)

export const getPaymentSettings = createSelector(
  getData,
  (data) => data || null
)
