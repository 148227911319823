import styled from 'styled-components'

interface Wrapper {
  small?: boolean
  centered?: boolean
}

export const Wrapper = styled.div<Wrapper>`
  width: ${(props) => (props.small ? '11ch' : '100%')};
  padding-bottom: 12px;

  .react-datepicker {
    font-family: 'Montserrat', sans-serif;
    background-color: ${({ theme }) => theme.pallete.white};
    border-radius: 10px;
    box-shadow: 0 3px 10px rgba(5, 16, 34, 0.17);
    border: none;
    font-size: 12px;
    line-height: 18px;
    padding: 16px;

    &__input-container {
      input {
        text-align: ${({ centered = true }) => (centered ? 'center' : 'left')};
      }
    }

    &-wrapper {
      width: 100%;
    }

    &-popper {
      z-index: 100;
    }

    &__input-container {
      width: 100%;
    }

    &__triangle {
      display: none;
    }

    &__header {
      background-color: ${({ theme }) => theme.pallete.white};
      border-bottom: none;
      padding: 0;
    }

    &__month {
      margin: 0;
    }

    &__navigation {
      top: 16px;
      outline: none;

      &--next,
      &--previous {
        transform: none;
        background-image: none;
      }
    }

    &__current-month {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      color: ${({ theme }) => theme.pallete.black};
      display: block;
    }

    &__day-name {
      color: ${({ theme }) => theme.pallete.gray7};
      width: 1.5rem;
      line-height: 1.5rem;
      margin: 0.625rem;
    }

    &__day {
      border-radius: 50%;
      color: ${({ theme }) => theme.pallete.black};
      width: 1.5rem;
      height: 1.5rem;
      line-height: 1.5rem;
      margin: 0.625rem;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      &--keyboard-selected {
        background-color: ${({ theme }) => theme.pallete.blue};
        color: ${({ theme }) => theme.pallete.white} !important;
        font-weight: 600;
      }

      &:hover {
        background-color: ${({ theme }) => theme.pallete.blue};
        color: ${({ theme }) => theme.pallete.white};
        border-radius: 50%;
        text-decoration: none;
      }

      &--selected {
        color: ${({ theme }) => theme.pallete.white} !important;
        background-color: ${({ theme }) => theme.pallete.blue};
        font-weight: 600;
        border-color: ${({ theme }) => theme.pallete.blue};

        &:hover {
          background-color: ${({ theme }) => theme.pallete.blue};
          border-radius: 50%;
        }
      }

      &--disabled {
        color: ${({ theme }) => theme.pallete.gray7} !important;

        &:hover {
          background-color: transparent;
        }
      }

      &--today {
        color: ${({ theme }) => theme.pallete.blue};
      }

      &--outside-month {
        color: ${({ theme }) => theme.pallete.gray5};
      }
    }
  }
`
