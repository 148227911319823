import { history } from 'store'
import { apiActions } from 'api'
import { MyThunkDispatch, MyThunkResult } from 'api/reducer'
import { RoutesPath } from 'common/enums'
import { ApiCode } from 'common/types/api/codes'

import { FormProps } from 'steps/features/stepDrivers/containers/StepDriversContainer'

export const onSaveEdgvr: ({
  values,
}: {
  values: FormProps
}) => MyThunkResult<void> = ({ values }) => (dispatch: MyThunkDispatch) => {
  return dispatch(
    apiActions.park.changeEdgvrApiKey.handleRequest({
      values: { edgvr_api_key: values.edgvr_api_key },
    })
  ).then(
    () => {
      history.replace(RoutesPath.StepsEmployees)
    },
    (reason) => {
      if (
        reason.response &&
        reason.response.data.code === ApiCode.EdgvrApiKeyIncorrect
      ) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw {
          edgvr_api_key: 'Неправильный Ключ API edgvr',
        }
      }
    }
  )
}
