import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import { MyThunkDispatch } from 'api/reducer'
import { FormNames } from 'common/enums'
import { onSave } from 'auth/features/signUp/thunks'
import { SignUp } from 'auth/features/signUp/organisms'

export type FormProps = {
  phone: string
  ogrn: string
  email: string
  password: string
  is_legal: boolean
}

const mapStateToProps = () => ({
  initialValues: {
    is_legal: true,
  },
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  onSave: ({ values }: any) => dispatch(onSave({ values })),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const Form = reduxForm<FormProps, ContainerProps>({
  form: FormNames.SignUp,
})(SignUp)

export const SignUpContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Form)
