import { createSelector } from 'reselect'
import { apiSelectors } from 'api'

export const selectInitialValues = createSelector(
  apiSelectors.park.getTinkoff.selectData,
  (data) => {
    return {
      terminal_key: data?.terminal_key,
    }
  }
)
