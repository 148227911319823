import { ThunkAction } from 'redux-thunk'
import { State } from 'reducers'
import axios, { AxiosResponse } from 'axios'
import { FailureError } from 'common/utils/reduxApiFactory'
import { TApiError } from 'api/types'
import { getPaymentOrderActions } from 'api/park/payment-order/get/actions'
import {
  GetPaymentOrderActions,
  GetPaymentOrderParams,
} from 'api/park/payment-order/get/types'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  GetPaymentOrderActions
>

export const getPaymentOrderRequest = (
  params: GetPaymentOrderParams
): THandleRequest => async (dispatch) => {
  const { start, success, failure } = getPaymentOrderActions
  dispatch(start())
  try {
    const response: AxiosResponse<BlobPart> = await axios({
      method: 'GET',
      url: `erp/1/park/payment-order`,
      params,
      responseType: 'blob',
    })
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: response.headers['content-type'] })
    )
    const link = document.createElement('a')
    link.href = url

    const contentDisposition = response.headers['content-disposition']
    let fileName = 'order.csv'
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
      if (fileNameMatch.length === 2) {
        const newFileName = fileNameMatch[1]
        fileName = newFileName
      }
    }
    link.setAttribute('download', fileName)

    document.body.appendChild(link)
    link.click()
    link.remove()
    window.URL.revokeObjectURL(url)
    dispatch(success())
  } catch (e) {
    if (e.response) {
      const data = e.response.data as TApiError
      const error: FailureError = new Error(data.message)
      error.data = data
      dispatch(failure(error))
      throw error
    }
  }
}
