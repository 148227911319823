import React from 'react'
import { SubscriptionNotice } from 'layout/features/notice/molecules'
import { ContainerProps } from 'layout/features/notice/containers/NoticeContainer'

export const Notice: React.FC<ContainerProps> = ({
  subscriptionStatus,
  openDepositPayModal,
}) => (
  <SubscriptionNotice
    status={subscriptionStatus}
    openDepositPayModal={openDepositPayModal}
  />
)
