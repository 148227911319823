import { ThunkAction } from 'redux-thunk'
import { State } from 'reducers'
import axios, { AxiosResponse } from 'axios'
import { FailureError } from 'common/utils/reduxApiFactory'
import { TApiError } from 'api/types'
import { getModulActions as actions } from 'api/park/modul/get/actions'
import { ModulBank } from 'api/park/modul/types'
import { GetModulActions } from 'api/park/modul/get/types'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  GetModulActions
>

export const getModulRequest = (): THandleRequest => async (dispatch) => {
  dispatch(actions.start())
  try {
    const response: AxiosResponse<ModulBank> = await axios.get(
      'erp/1/park/modul'
    )
    dispatch(actions.update(response.data))
    dispatch(actions.success())
  } catch (e) {
    if (e.response) {
      const data = e.response.data as TApiError
      const error: FailureError = new Error(data.message)
      error.data = data
      dispatch(actions.failure(error))
    }
    throw e
  }
}
