import React from 'react'

import { CaptionText, ListItem } from 'ui-kit/components'
import { RouteTab } from 'common/types/local/routeTab'

import {
  Link,
  List,
  ListItemComponent,
  ListWrapper,
} from 'shared/ui/tabs/components/styles'
import styled from 'styled-components'

const Wrapper = styled.div<{ padding?: number }>`
  padding: 0 ${({ theme, padding }) => padding ?? theme.paddings.main}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

interface Props {
  list: RouteTab[]
  padding?: number
}

const Badge = styled(CaptionText)`
  margin-left: 8px;
  background-color: ${({ theme }) => theme.pallete.accentPrimary};
  border-radius: 18px;
  padding: 0 6px;
  min-width: 18px;
  text-align: center;
`

const RightDivider = styled.div`
  margin-left: auto;
`

export const Tabs: React.FC<Props> = ({ list, padding }) => {
  const renderLeftListItems = (): JSX.Element[] =>
    list
      .filter((item) => item.align !== 'right')
      .map((item) => (
        <ListItemComponent key={item.path}>
          <Link activeClassName="active" to={item.path} exact={item.exact}>
            <ListItem className="link-list-item">
              {item.label}
              {item.badge !== undefined && item.badge > 0 && (
                <Badge color="white" fontWeight="semi-bold">
                  {item.badge.toLocaleString('ru')}
                </Badge>
              )}
            </ListItem>
          </Link>
        </ListItemComponent>
      ))

  const renderRightListItems = (): JSX.Element[] =>
    list
      .filter((item) => item.align === 'right')
      .map((item) => (
        <ListItemComponent key={item.path}>
          <Link activeClassName="active" to={item.path} exact={item.exact}>
            <ListItem className="link-list-item">
              {item.label}
              {item.badge !== undefined && item.badge > 0 && (
                <Badge color="white" fontWeight="semi-bold">
                  {item.badge.toLocaleString('ru')}
                </Badge>
              )}
            </ListItem>
          </Link>
        </ListItemComponent>
      ))

  return (
    <Wrapper padding={padding}>
      <ListWrapper>
        <List>
          {renderLeftListItems()}
          <RightDivider />
          {renderRightListItems()}
        </List>
      </ListWrapper>
    </Wrapper>
  )
}
