import { createSelector } from 'reselect'
import { State } from 'reducers'
import { ContractUpload } from 'api/types'

export const getIsFetching = (state: State) =>
  state.api.drivers.contractsUpload.status === 'loading'

const getData = (state: State) => state.api.drivers.contractsUpload.data

export const getContracts = createSelector(getData, (data) =>
  data ? data.contracts : []
)

export const getContractsList = createSelector(getContracts, (contracts) => {
  const result: { [key: string]: ContractUpload[] } = {}

  contracts.forEach((contract) => {
    if (!(contract.aggregator_account in result)) {
      result[contract.aggregator_account] = contracts.filter(
        (c) => c.aggregator_account === contract.aggregator_account
      )
    }
  })
  return result
})

export const getContractsListLength = createSelector(
  getContractsList,
  (list) =>
    Object.keys(list).reduce(
      (res, key) => [...res, ...list[key]],
      [] as ContractUpload[]
    ).length
)
