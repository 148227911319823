import { Option } from 'common/types/local/option'

export enum Permission {
  ViewDriverDetails = 'view_driver_details',
  ChangeDriverContracts = 'change_driver_contracts',
  ViewDriverRequisites = 'view_driver_requisites',
  ViewTransactionInfo = 'view_park_statistics',
  ViewAllTransactions = 'view_transactions',
  ViewTransactionDetails = 'view_transaction_details',
  ProcessTransactions = 'process_transactions',
  NotConfirmedProcessTransactions = 'process_nonconfirmed_transactions',
  ViewConfirmationTransactions = 'view_need_confirmation_transactions',
  ViewNotCompletedTransactions = 'view_refund_transactions',
  ProcessNotCompletedTransactions = 'process_refund_transactions',
  ViewSuspectTransactions = 'view_suspicious_transactions',
  ProcessSuspectTransactions = 'process_suspicious_transactions',
  ViewEmployees = 'view_employees',
  ViewEmployeeDetails = 'view_employee_details',
  ViewLogs = 'view_logs',
  ChangeEmployee = 'change_employee',
  ChangePermissions = 'change_permissions',
  ViewParkRequisites = 'view_park_requisites',
  ChangeParkRequisites = 'change_park_requisites',
  ChangeAggregators = 'change_aggregator',
  ChangeAutoTransfer = 'auto_confirmation_transactions',
  ChangePaymentsConditions = 'change_payments_settings',
  ViewTariff = 'view_tariff',
  ReplenishDepositBalance = 'topup_deposit',
  ViewPaymentsSettings = 'view_payments_settings',
  Fuel = 'fuel',
}

export const PermissionGroup: { [key: string]: Permission[] } = {
  system: [
    Permission.ViewParkRequisites,
    Permission.ChangeParkRequisites,
    Permission.ChangeAggregators,
    Permission.ChangePaymentsConditions,
    Permission.ChangeAutoTransfer,
    Permission.ReplenishDepositBalance,
    Permission.ViewTariff,
  ],
  drivers: [
    Permission.ViewDriverDetails,
    Permission.ViewDriverRequisites,
    Permission.ChangeDriverContracts,
  ],
  payments: [
    Permission.ViewTransactionInfo,
    Permission.ViewAllTransactions,
    Permission.ViewTransactionDetails,
    Permission.ViewConfirmationTransactions,
    Permission.ProcessTransactions,
    Permission.ViewNotCompletedTransactions,
    Permission.ViewPaymentsSettings,
  ],
  employees: [
    Permission.ViewEmployees,
    Permission.ViewEmployeeDetails,
    Permission.ViewLogs,
    Permission.ChangeEmployee,
    Permission.ChangePermissions,
  ],
  fuel: [Permission.Fuel],
}

export const permissionToString = (permission: Permission): string => {
  switch (permission) {
    case Permission.ViewParkRequisites:
      return 'Просмотр реквизитов автопарка'
    case Permission.ChangeParkRequisites:
      return 'Редактирование реквизитов автопарка'
    case Permission.ChangeAggregators:
      return 'Добавление/редактирование агрегатора'
    case Permission.ChangePaymentsConditions:
      return 'Настройка комиссии моментальных выводов'
    case Permission.ChangeAutoTransfer:
      return 'Автоподтверждение платежей'
    case Permission.ViewTariff:
      return 'Просмотр тарифа'
    case Permission.ReplenishDepositBalance:
      return 'Пополнение депозита'
    case Permission.ViewDriverDetails:
      return 'Просмотр карточки водителя'
    case Permission.ViewDriverRequisites:
      return 'Просмотр реквизитов водителя'
    case Permission.ChangeDriverContracts:
      return 'Работа с договорами'
    case Permission.ViewTransactionInfo:
      return 'Просмотр статистики парка'
    case Permission.ViewAllTransactions:
      return 'Просмотр всех транзакций'
    case Permission.ViewTransactionDetails:
      return 'Просмотр подробностей транзакции'
    case Permission.ViewConfirmationTransactions:
      return 'Просмотр транзакций, требующих подтверждения'
    case Permission.ProcessTransactions:
      return 'Подтверждение/отклонение транзакций'
    case Permission.ViewNotCompletedTransactions:
      return 'Просмотр транзакций на возврат'
    case Permission.ProcessNotCompletedTransactions:
      return 'Подтверждение/отклонение транзакций на возврат'
    case Permission.ViewSuspectTransactions:
      return 'Просмотр подозрительных транзакций'
    case Permission.ProcessSuspectTransactions:
      return 'Подтверждение/отклонение подозрительных транзакций'

    case Permission.ViewEmployees:
      return 'Просмотр списка сотрудников'
    case Permission.ViewEmployeeDetails:
      return 'Просмотр карточки сотрудника'
    case Permission.ViewLogs:
      return 'Просмотр всех активностей сотрудников'
    case Permission.ChangeEmployee:
      return 'Создание/редактирование/блокировка сотрудников'
    case Permission.ChangePermissions:
      return 'Назначение доступов и прав сотрудникам'
    case Permission.ViewPaymentsSettings:
      return 'Просмотр настроек вывода'
    case Permission.Fuel:
      return 'Работа с топливом'

    default:
      return permission
  }
}

export enum PermissionTemplate {
  PARK = 'park',
  FINANCE = 'finance',
  DRIVERS = 'drivers',
  CUSTOM = 'custom',
}

export const permissionsByTemplate: { [key: string]: Permission[] } = {
  park: [
    Permission.ViewDriverDetails,
    Permission.ViewDriverRequisites,
    Permission.ViewTransactionInfo,
    Permission.ViewAllTransactions,
    Permission.ViewTransactionDetails,
    Permission.ViewConfirmationTransactions,
    Permission.ViewEmployees,
    Permission.ViewEmployeeDetails,
    Permission.ChangeEmployee,
    Permission.ChangePermissions,
    Permission.ViewLogs,
    Permission.ViewTariff,
    Permission.ReplenishDepositBalance,
    Permission.ViewParkRequisites,
    Permission.ChangeParkRequisites,
    Permission.ChangeAggregators,
    Permission.ChangeAutoTransfer,
    Permission.ChangePaymentsConditions,
  ],
  finance: [
    Permission.ViewTransactionInfo,
    Permission.ViewAllTransactions,
    Permission.ViewTransactionDetails,
    Permission.ViewConfirmationTransactions,
    Permission.ProcessTransactions,
    Permission.ViewNotCompletedTransactions,
    Permission.ProcessNotCompletedTransactions,
    Permission.ViewSuspectTransactions,
    Permission.ProcessSuspectTransactions,
    Permission.ViewParkRequisites,
    Permission.ChangeAutoTransfer,
    Permission.ViewDriverDetails,
    Permission.ChangeDriverContracts,
    Permission.ViewDriverRequisites,
    Permission.ViewTariff,
    Permission.ReplenishDepositBalance,
  ],
  drivers: [
    Permission.ViewDriverDetails,
    Permission.ViewDriverRequisites,
    Permission.ViewAllTransactions,
    Permission.ViewTransactionDetails,
    Permission.ChangeAggregators,
  ],
}

export const templateOptions: Option<PermissionTemplate>[] = [
  { label: 'Настроить вручную...', value: PermissionTemplate.CUSTOM },
  { label: 'Управление автопарком', value: PermissionTemplate.PARK },
  { label: 'Работа с финансами', value: PermissionTemplate.FINANCE },
  { label: 'Работа с водителями', value: PermissionTemplate.DRIVERS },
]
