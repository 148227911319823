import React, { useEffect, useRef } from 'react'

import Datepicker from 'common/components/datepicker/Datepicker'

import {
  Activator,
  Divider,
  Pickers,
  Wrapper,
} from 'common/components/dateRangePicker/styles'

import { BodyText, Icon } from 'ui-kit/components'

interface Props {
  maxDate?: Date
  minDate?: Date
  dateFrom?: Date
  dateTo?: Date
  withoutText?: boolean
  className?: string
  onChangeDate?: (from: Date, to: Date) => void
  onClearDates?: () => void
  hasCloseButton?: boolean
  disabled?: boolean
}

const DateRangePicker: React.FC<Props> = (props) => {
  const {
    minDate = new Date('2019'),
    maxDate = new Date(),
    dateTo,
    dateFrom,
    className,
    onChangeDate,
    onClearDates,
    hasCloseButton = true,
    withoutText,
    disabled,
  } = props
  const [active, setActive] = React.useState(!!(dateTo || dateFrom))
  const [dateFromValue, setDateFromValue] = React.useState(dateFrom || null)
  const [dateToValue, setDateToValue] = React.useState(dateTo || null)
  const dateFromEffect = `${dateFrom?.getDate()}/${dateFrom?.getMonth()}/${dateFrom?.getFullYear()}`
  const dateToEffect = `${dateTo?.getDate()}/${dateTo?.getMonth()}/${dateTo?.getFullYear()}`

  const firstRender = useRef(true)
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }
    if (dateFrom) {
      setDateFromValue(dateFrom)
    }
    if (dateTo) {
      setDateToValue(dateTo)
    }
    // TODO: fix dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFromEffect, dateToEffect])

  const handleChangeDateFrom = (date: Date): void => {
    setDateFromValue(date)

    if (onChangeDate && dateToValue) {
      onChangeDate(date, dateToValue)
    }
  }

  const handleChangeDateTo = (date: Date): void => {
    setDateToValue(date)

    if (onChangeDate && dateFromValue) {
      onChangeDate(dateFromValue, date)
    }
  }

  const activatePicker = (): void => setActive(true)

  const handleClearDates = (): void => {
    setActive(false)

    setDateToValue(null)

    setDateFromValue(null)

    if (onClearDates) {
      onClearDates()
    }
  }

  const handleFocus = (ev: React.FocusEvent<HTMLInputElement>): void => {
    const mdEvent = document.createEvent('MouseEvents')
    mdEvent.initEvent('mousedown', true, true)
    ev.target.dispatchEvent(mdEvent)
  }

  return (
    <Wrapper className={className}>
      <Activator
        onClick={activatePicker}
        triggerSpacing={!withoutText || active}
        active={active}
      >
        <Icon name="calendar" className="picker-calendar" color="gray8" />
        {!withoutText && !active && <BodyText color="gray8">Период</BodyText>}
      </Activator>
      {active && (
        <Pickers>
          <Datepicker
            small
            selected={dateFromValue}
            maxDate={dateToValue || maxDate}
            minDate={minDate}
            className="range-from"
            onChange={handleChangeDateFrom}
            popperPlacement="bottom-end"
            onFocus={handleFocus}
            disabled={disabled}
          />
          {dateFromValue && <Divider>—</Divider>}
          {dateFromValue && (
            <Datepicker
              small
              selected={dateToValue}
              minDate={dateFromValue}
              className="range-to"
              onChange={handleChangeDateTo}
              maxDate={maxDate}
              popperPlacement="bottom-end"
              onFocus={handleFocus}
              disabled={disabled}
            />
          )}
        </Pickers>
      )}
      {active && hasCloseButton && (
        <Icon
          className="range-picker-close"
          name="close"
          onClick={handleClearDates}
        />
      )}
    </Wrapper>
  )
}

export default React.memo<Props>(DateRangePicker)
