import React from 'react'
import { Field } from 'redux-form'
import ListInput from 'common/components/formItems/ListInput'
import { required } from 'common/utils/fieldValidation'
import { HBox } from 'shared/ui'
import KnowledgeTips from 'ui-kit/atoms/KnowledgeTips/KnowledgeTips'

const knowledgeTips = [
  { name: 'Как получить токены', href: 'https://api.modulbank.ru/auth.html' },
]

export const ModulFields: React.FC = () => (
  <>
    <Field
      name="account_token"
      label="Токен авторизации"
      validate={required}
      component={ListInput}
    />
    <HBox x={0.5} />
    <Field
      name="payment_token"
      label="Ключ для платежей"
      validate={required}
      component={ListInput}
    />
    <KnowledgeTips data={knowledgeTips} />
  </>
)
