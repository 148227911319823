import axios, { AxiosResponse } from 'axios'
import { BaseAction } from 'redux-actions'
import { ThunkAction } from 'redux-thunk'

import { State } from 'reducers'
import { PaymentTransactionsResponse } from 'api/types'
import {
  failure,
  start,
  success,
  update,
} from 'api/park/transactions/get/actions'
import { transactionStatusActions } from 'api/transactions/status-request/actions'
import { getTransactionsCountersRequest } from 'api/park/transactions/counters/thunks'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  BaseAction
>
export const HANDLE_REQUEST: BaseAction['type'] =
  'api/park/transactions->HANDLE_REQUEST'

export const handleRequest = ({ params }: any): THandleRequest => async (
  dispatch
) => {
  const timestamp = Date.now()
  dispatch(transactionStatusActions.reset())
  dispatch(start(timestamp))
  try {
    const response: AxiosResponse<PaymentTransactionsResponse> = await axios.get(
      'erp/1/park/transactions',
      {
        params: {
          payment_status: [
            'authorized',
            'pending',
            'acknowledged',
            'succeeded',
            'declined',
            'failed',
            'processing',
          ],
          reason_type: [
            'park_driver_withdraw',
            'park_driver_withdraw_commission',
            'driver_fuel_card_topup',
            'taxometer_manual_topup',
            'taxometer_referral_topup',
          ],
          ...params,
        },
      }
    )
    dispatch(update(response.data, timestamp))
    dispatch(success())
    await dispatch(getTransactionsCountersRequest())
  } catch (e) {
    if (e.response) {
      dispatch(failure(e.response.data))
    }
  }
}

export const handleRequestPending = ({ params }: any): THandleRequest => async (
  dispatch
) => {
  const timestamp = Date.now()
  dispatch(transactionStatusActions.reset())
  dispatch(start(timestamp))
  try {
    const response: AxiosResponse<PaymentTransactionsResponse> = await axios.get(
      'erp/1/park/transactions',
      {
        params: {
          payment_status: ['pending'],
          reason_type: [
            'park_driver_withdraw',
            'driver_fuel_card_topup',
            'taxometer_referral_topup',
          ],
          ...params,
        },
      }
    )
    dispatch(update(response.data, timestamp))
    dispatch(success())
    await dispatch(getTransactionsCountersRequest())
  } catch (e) {
    if (e.response) {
      dispatch(failure(e.response.data))
    }
  }
}

export const handleRequestNotCompleted = ({
  params,
}: any): THandleRequest => async (dispatch) => {
  const timestamp = Date.now()
  dispatch(transactionStatusActions.reset())
  dispatch(start(timestamp))
  try {
    const response: AxiosResponse<PaymentTransactionsResponse> = await axios.get(
      'erp/1/park/transactions',
      {
        params: {
          payment_status: ['not_completed'],
          reason_type: ['park_driver_withdraw', 'driver_fuel_card_topup'],
          ...params,
        },
      }
    )
    dispatch(update(response.data, timestamp))
    dispatch(success())
    await dispatch(getTransactionsCountersRequest())
  } catch (e) {
    if (e.response) {
      dispatch(failure(e.response.data))
    }
  }
}
