import { connect } from 'react-redux'
import { State } from 'reducers'
import { apiSelectors } from 'api'
import { NotificationsContacts } from 'settings/features/notificationsContacts/organisms'

const mapStateToProps = (state: State) => ({
  contactEmail: apiSelectors.park.notificationsGet.getContactEmail(state),
})

export type ContainerProps = ReturnType<typeof mapStateToProps>

export const NotificationsContactsContainer = connect(mapStateToProps)(
  NotificationsContacts
)
