import { EuiTitle, EuiTitleSize } from '@elastic/eui'
import { EuiIconEnhanced } from 'elastic-ui/shared/atoms/EnhancedIcon'
import HorizontalSpacer from 'elastic-ui/shared/atoms/HorizontalSpacer'
import React from 'react'
import styled from 'styled-components'

const Flexbox = styled.div`
  display: flex;
  align-items: center;
`

interface IconTitleProps {
  iconType: string | React.ComponentType
  iconColor?: string
  children?: string
  size?: EuiTitleSize
}

const IconTitle: React.FC<IconTitleProps> = ({
  iconType,
  iconColor,
  children,
  size,
}) => {
  return (
    <Flexbox>
      <EuiIconEnhanced type={iconType} color={iconColor} />
      <HorizontalSpacer size="xs" />
      <EuiTitle size={size}>
        <h4>{children}</h4>
      </EuiTitle>
    </Flexbox>
  )
}

export default IconTitle
