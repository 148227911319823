import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 0 32px 32px 32px;
`

export const Inner = styled.div`
  padding: ${({ theme }) => theme.paddings.main}px;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  padding-right: 8px;
  border-bottom: 0.5px solid ${({ theme }) => theme.pallete.gray3};
`

export const PaginatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LinkWrapper = styled.div`
  p {
    font-size: 12px;
    line-height: 16px;
  }
`

export const FilterAndPicker = styled.div`
  display: flex;
  align-items: center;

  .date-range-picker {
    margin-right: 16px;
  }
`
