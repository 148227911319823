import { ThunkAction } from 'redux-thunk'
import { State } from 'reducers'
import axios from 'axios'
import { FailureError } from 'common/utils/reduxApiFactory'
import { TApiError } from 'api/types'
import { deleteEdgvrApiKeyActions as actions } from 'api/park/edgvr-api-key/delete/actions'
import { DeleteEdgvrApiKeyActions } from 'api/park/edgvr-api-key/delete/types'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  DeleteEdgvrApiKeyActions
>

export const deleteEdgvrApiKeyRequest = (): THandleRequest => async (
  dispatch
) => {
  dispatch(actions.start())
  try {
    await axios.delete('erp/1/park/edgvr-api-key')
    dispatch(actions.success())
  } catch (e) {
    if (e.response) {
      const data = e.response.data as TApiError
      const error: FailureError = new Error(data.message)
      error.data = data
      dispatch(actions.failure(error))
      throw error
    }
  }
}
