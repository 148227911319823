import React, { useEffect } from 'react'
import { State } from 'reducers'
import { connect } from 'react-redux'
import useSWR from 'swr'

import { MyThunkDispatch } from 'api/reducer'
import { apiActions, apiSelectors } from 'api'
import { IncomeReportFormat } from 'api/park/income-report/get/types'
import { IncomeDataSet } from 'ui-kit/molecules/IncomeInfo'
import { selectIncomeDataSet } from 'analytics/features/incomeReport/selectors'
import { isoToDate } from 'common/utils/dates'
import { BotType } from 'api/park/notify-bot/post/types'

type ChildProps = {
  canView: boolean
  updateData: (dateStart: string, dateEnd: string) => Promise<void>
  notifyBot: () => Promise<void>
  data?: IncomeDataSet | null
  initialRange?: {
    from: Date
    to: Date
  }
  isFetching?: boolean
}

type OwnProps = {
  children(props: ChildProps): React.ReactElement | null
}

export const PureIncomeReportConnector = React.memo<ConnectorProps>(
  ({
    children,
    isFetching,
    hasYandexAggregators,
    incomeDataSet,
    fetchIncomeReport,
    fetchNotifyBot,
    fetchAggregators,
  }) => {
    useEffect(() => {
      fetchAggregators()
    }, [fetchAggregators])

    const { data: incomeReportDateRange, mutate } = useSWR<{
      from: Date
      to: Date
    }>('IncomeReportDateRange')

    const updateData = async (dateStart: string, dateEnd: string) => {
      await fetchIncomeReport(dateStart, dateEnd)
      await mutate({
        from: isoToDate(dateStart) as Date,
        to: isoToDate(dateEnd) as Date,
      })
    }

    const notifyBot = async () => {
      await fetchNotifyBot()
    }

    return children({
      canView: hasYandexAggregators,
      data: incomeDataSet,
      isFetching,
      initialRange: incomeReportDateRange,
      updateData,
      notifyBot,
    })
  }
)

PureIncomeReportConnector.displayName = 'PureIncomeReportConnector'

const mapStateToProps = (state: State) => ({
  isFetching: apiSelectors.park.getIncomeReport.selectIsFetching(state),
  incomeDataSet: selectIncomeDataSet(state),
  hasYandexAggregators: apiSelectors.park.aggregators.hasYandexAggregators(
    state
  ),
})
const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  fetchAggregators: () => dispatch(apiActions.park.aggregators.handleRequest()),
  fetchIncomeReport: (dateStart: string, dateEnd: string) =>
    dispatch(
      apiActions.park.getIncomeReport.handleRequest({
        date_start: dateStart,
        date_end: dateEnd,
        report_format: IncomeReportFormat.Json,
      })
    ),
  fetchNotifyBot: () =>
    dispatch(
      apiActions.park.postNotifyBot.handleRequest({
        notification_type: BotType.Yandex,
      })
    ),
})

export type ConnectorProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps

export const IncomeReportConnector = connect(
  mapStateToProps,
  mapDispatchToProps
)(PureIncomeReportConnector)
