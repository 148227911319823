import React from 'react'
import styled from 'styled-components'

import { CaptionText } from 'ui-kit/components/Typography'

const Wrapper = styled.div`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  background-color: ${({ theme }) => theme.pallete.gray10};
`

interface Props {
  className?: string
}

export const ListGroup: React.FC<Props> = ({ children, className }) => (
  <Wrapper className={className}>
    {typeof children === 'string' ? (
      <CaptionText color="darkGray" fontWeight="semi-bold">
        {children}
      </CaptionText>
    ) : (
      children
    )}
  </Wrapper>
)
