import { EuiSpacer, EuiTitle } from '@elastic/eui'
import {
  ProgramSettings,
  ProgramTerms,
} from 'elastic-ui/referral-program/types'
import { EnhancedPanel } from 'elastic-ui/shared/atoms/EnhancedPanel/EnhancedPanel'
import React from 'react'
import TermsEdit from '../TermsEdit/TermsEdit'
import { AggregatorAccount } from '../TermsEdit/types'
import TermsPreview from '../TermsPreview/TermsPreview'

interface Props {
  aggregatorAccounts: AggregatorAccount[]
  company: string
  programSettings: ProgramSettings
  programTerms: ProgramTerms
  isLoading: boolean
  isEditing: boolean
  isFirstTime: boolean
  isProgramHidden: boolean
  onEditClick: () => void
  onUpdateClick: (settings: ProgramSettings) => void
  onCancelEditingClick: () => void
  onShowTermsToDriversClick: () => void
  isTelegramNotificationsConnected: boolean
  isTelegramNotificationsLoading: boolean
  onSubscribeToTelegramNotificationsClick: (key: string) => void
  onCancelTelegramSubscription: () => void
}

const Terms: React.FC<Props> = ({
  aggregatorAccounts,
  company,
  programSettings,
  programTerms,
  isLoading,
  isEditing,
  isFirstTime,
  isProgramHidden,
  onUpdateClick,
  onCancelEditingClick,
  onEditClick,
  onShowTermsToDriversClick,
  isTelegramNotificationsConnected,
  isTelegramNotificationsLoading,
  onCancelTelegramSubscription,
  onSubscribeToTelegramNotificationsClick,
}) => {
  const isCustomText =
    programSettings.customTitle &&
    programSettings.customShortTerms &&
    programSettings.customFullTerms

  return (
    <EnhancedPanel>
      <EuiTitle size="xs">
        <h4>Условия</h4>
      </EuiTitle>
      <EuiSpacer size="s" />
      {isFirstTime || isEditing ? (
        <TermsEdit
          company={company}
          aggregatorAccounts={aggregatorAccounts}
          onUpdateTermsClick={onUpdateClick}
          isLoading={isLoading}
          onCancelClick={onCancelEditingClick}
          isFirstTime={isFirstTime}
          programSettings={programSettings}
        />
      ) : (
        <TermsPreview
          isTelegramNotificationsConnected={isTelegramNotificationsConnected}
          isTelegramNotificationsLoading={isTelegramNotificationsLoading}
          onCancelTelegramSubscription={onCancelTelegramSubscription}
          onSubscribeToTelegramNotificationsClick={
            onSubscribeToTelegramNotificationsClick
          }
          onEditTermsClick={onEditClick}
          isLoading={isLoading}
          isProgramHidden={isProgramHidden}
          company={company}
          title={
            programSettings.customTitle && isCustomText
              ? programSettings.customTitle
              : programTerms.title
          }
          shortDescription={
            programSettings.customShortTerms && isCustomText
              ? programSettings.customShortTerms
              : programTerms.shortDescription
          }
          fullDescription={
            programSettings.customFullTerms && isCustomText
              ? programSettings.customFullTerms
              : programTerms.fullDescription
          }
          onShowTermsToDriversClick={onShowTermsToDriversClick}
        />
      )}
    </EnhancedPanel>
  )
}

export default Terms
