import React, { useEffect, useState } from 'react'

import { BodyText, FloatButton, ListInput, TitleText } from 'ui-kit/components'

import { history } from 'store'
import { RoutesPath } from 'common/enums'
import { HBox } from 'shared/ui'
import { useStepsContext } from 'shared/hooks'
import { list } from 'steps/data'
import { StepsActionType } from 'steps/types'
import { ContainerProps } from 'steps/features/stepEmployees/containers/StepEmployeesContainer'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 563px;
`

const ButtonWidth = styled.div`
  width: 320px;
`

export const StepEmployees: React.FC<ContainerProps> = ({
  isFetchingProfilePost,
  fetchProfilePost,
  fetchEmployees,
}) => {
  const { stepsDispatch } = useStepsContext()
  useEffect(() => {
    stepsDispatch({
      type: StepsActionType.SET_CURRENT_STEP,
      payload: { current: list.employees },
    })
  }, [stepsDispatch])

  const [name, setName] = useState('')

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setName(e.currentTarget.value)

  const handleSetProfile = async () => {
    await fetchProfilePost({
      data: { full_name: name, position: 'Директор' },
    })
    await fetchEmployees()
    history.replace(RoutesPath.Payments)
  }

  return (
    <Wrapper>
      <TitleText fontWeight="semi-bold" className="form-title">
        Представьтесь
      </TitleText>
      <HBox />
      <BodyText color="gray9">
        Введите фамилию и&nbsp;имя, чтобы мы&nbsp;знали как к&nbsp;вам
        обращаться. <br />
        Если понадобится дать доступ в&nbsp;систему другим сотрудникам,
        вы&nbsp;в&nbsp;любой момент сможете создать для них отдельный аккаунт
        в&nbsp;разделе «Сотрудники».
      </BodyText>
      <HBox x={2} />

      <ListInput label="ФИО" onChange={handleChangeName} value={name} />
      <HBox />
      <ButtonWidth>
        <FloatButton
          fullWidth
          isLoading={isFetchingProfilePost}
          disabled={!name.length}
          onClick={handleSetProfile}
        >
          Продолжить
        </FloatButton>
      </ButtonWidth>
    </Wrapper>
  )
}
