import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import { State } from 'reducers'
import { apiActions, apiSelectors } from 'api'
import { MyThunkDispatch } from 'api/reducer'
import { FormNames } from 'common/enums'
import { onSaveParkEdgvr } from 'settings/features/parkEdgvr/thunks'
import { ParkEdgvr } from 'settings/features/parkEdgvr/organisms'

export interface FormProps {
  edgvr_api_key: string | null
}

const mapStateToProps = (state: State) => ({
  canChange: apiSelectors.employee.profile.getCanChangeParkRequisites(state),
  edgvrApiKey: apiSelectors.park.edgvrApiKey.getKey(state),
  isFetching: apiSelectors.park.edgvrApiKey.getIsFetching(state),
  initialValues: {
    edgvr_api_key: apiSelectors.park.edgvrApiKey.getKey(state),
  },
  isFetchingDeleteEdgvrApiKey: apiSelectors.park.deleteEdgvrApiKey.selectIsFetching(
    state
  ),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  onSave: (values: FormProps) => dispatch(onSaveParkEdgvr({ values })),
  getEdgvrApiKey: () => dispatch(apiActions.park.edgvrApiKey.handleRequest()),
  deleteEdgvrApiKey: () =>
    dispatch(apiActions.park.deleteEdgvrApiKey.handleRequest()),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const Form = reduxForm<FormProps, ContainerProps>({
  form: FormNames.ParkEdgvr,
  enableReinitialize: true,
})(ParkEdgvr)

export const ParkEdgvrContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Form)
