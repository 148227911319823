import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Icon } from 'ui-kit/components'
import { IconNames } from 'ui-kit/index'

export enum CheckType {
  Off,
  On,
  Semi,
}

type Props = {
  value: CheckType
  onChange: (type: CheckType) => void
  className?: string
  children?: React.ReactNode
  isDisabled?: boolean
}

const Wrapper = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  cursor: ${({ isDisabled }) => !isDisabled && 'pointer'};
`

const IconWrapper = styled.div<{ isCheck: boolean; isDisabled?: boolean }>`
  border-radius: 2px;
  min-height: 24px;
  min-width: 24px;
  box-sizing: border-box;
  display: flex;
  background-color: ${({ isDisabled, isCheck, theme }) =>
    isCheck && !isDisabled ? theme.pallete.blue : theme.pallete.gray4};
  margin-right: 16px;
`

const ChildrenWrapper = styled.div``

type IconVariant = {
  [key in CheckType]: IconNames | null
}
const iconVariant: IconVariant = {
  [CheckType.Off]: null,
  [CheckType.On]: 'check',
  [CheckType.Semi]: 'semiCheck',
}

export const Checkbox = ({
  children,
  value,
  isDisabled,
  onChange,
  className,
}: Props) => {
  const handleClick = useCallback(() => {
    onChange(value === CheckType.On ? CheckType.Off : CheckType.On)
  }, [onChange, value])

  const iconName = iconVariant[value]

  return (
    <Wrapper
      className={className}
      onClick={isDisabled ? undefined : handleClick}
      isDisabled={isDisabled}
    >
      <IconWrapper isCheck={value !== CheckType.Off} isDisabled={isDisabled}>
        {iconName && <Icon name={iconName} color="white" />}
      </IconWrapper>
      {Boolean(children) && <ChildrenWrapper>{children}</ChildrenWrapper>}
    </Wrapper>
  )
}
