import { createSelector } from 'reselect'
import { State } from 'reducers'
import { apiSelectors } from 'api'

export const getLimit = (state: State) =>
  state.settings.subscriptionEvents.limit

export const getTotalPages = createSelector(
  apiSelectors.park.subscriptionPayments.getPaymentsTotal,
  getLimit,
  (paymentsTotal, limit) =>
    paymentsTotal ? Math.ceil(paymentsTotal / limit) : 0
)

export const getPage = (state: State) => state.settings.subscriptionEvents.page
