import React from 'react'
import { BodyText, TransparentButton } from 'ui-kit/components'
import { IconNames } from 'ui-kit'
import { VBox } from 'shared/ui/spacers'
import styled from 'styled-components'
import { Option } from 'ui-kit/molecules/RadioGroup/RadioGroup'
import { TransparentButtonOutputFormatSelect } from 'payments/features/manualConfirmationList/molecules/TransparentButtonOutputFormatSelect/TransparentButtonOutputFormatSelect'

const Wrapper = styled.div<{ count: number }>`
  position: fixed;
  bottom: 0;
  width: calc(100% - 240px);
  max-height: ${({ count }) => (count ? 72 : 0)}px;
  transition: ${({ count }) => count && 'max-height 0.25s ease'};
`

const Empty = styled.div`
  height: 72px;
`
const Content = styled.div<{ backgroundColor?: string }>`
  display: flex;
  padding: 8px 24px;
  height: 72px;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.pallete.blue};
  transition: 150ms background-color ease-in-out;
`

const Main = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding-right: ${({ theme }) => theme.paddings.main}px;
`

const Count = styled.div`
  min-width: auto;
  padding-right: ${({ theme }) => theme.paddings.main}px;
`

const Spacer = styled.div`
  flex: 1;
`

const TransparentButtonSeparator = styled.div`
  width: 1px;
`

interface Props {
  count: number
  countTitle?: string
  isLoading?: boolean
  onConfirm: (confirmed: boolean, id?: string) => void
  onDownload?: () => void
  doTitle?: string
  dontTitle?: string
  doDownload?: string
  downloadOptions?: Option[]
  selectedDownloadOption?: string
  onDownloadOptionChange?: (selectedOption: Option) => void
  backgroundColor?: string
  onCancel?: () => void
  cancelTitle?: string
  cancelIcon?: IconNames
}

export const ActionBottomPanel: React.FC<Props> = ({
  count,
  countTitle,
  isLoading,
  onConfirm,
  doTitle,
  dontTitle,
  doDownload,
  onDownload,
  downloadOptions,
  selectedDownloadOption,
  onDownloadOptionChange,
  onCancel,
  cancelTitle,
  cancelIcon,
  backgroundColor,
}) => (
  <>
    {count ? <Empty /> : null}
    <Wrapper count={count}>
      <Content backgroundColor={backgroundColor}>
        <Main>
          <Count>
            <BodyText color="white">{`${count} ${
              countTitle || 'выбрано'
            }`}</BodyText>
          </Count>
          {doDownload ? (
            <>
              <TransparentButton
                opacity={0.1}
                iconName="download"
                fontWeight="semi-bold"
                textColor="white"
                isLoading={isLoading}
                onClick={onDownload}
                groupPosition={
                  downloadOptions && downloadOptions.length > 1
                    ? 'start'
                    : undefined
                }
              >
                {doDownload}
              </TransparentButton>
              {downloadOptions && downloadOptions.length > 0 ? (
                <>
                  <TransparentButtonSeparator />
                  <TransparentButtonOutputFormatSelect
                    options={downloadOptions}
                    selected={selectedDownloadOption || ''}
                    onChange={(option) => onDownloadOptionChange?.(option)}
                    opacity={0.1}
                    isLoading={isLoading}
                    groupPosition="end"
                  >
                    {
                      downloadOptions.find(
                        (x) => x.name === selectedDownloadOption
                      )?.value
                    }
                  </TransparentButtonOutputFormatSelect>
                </>
              ) : null}
              <Spacer />
            </>
          ) : null}
          {dontTitle ? (
            <>
              <VBox />
              <TransparentButton
                opacity={0.1}
                iconName="block"
                fontWeight="semi-bold"
                textColor="white"
                isLoading={isLoading}
                onClick={() => onConfirm(false)}
              >
                {dontTitle}
              </TransparentButton>
            </>
          ) : null}
          {doTitle ? (
            <>
              <VBox />
              <TransparentButton
                opacity={0.1}
                iconName="check"
                fontWeight="semi-bold"
                textColor="white"
                isLoading={isLoading}
                onClick={() => onConfirm(true)}
              >
                {doTitle}
              </TransparentButton>
            </>
          ) : null}
        </Main>
        {onCancel ? (
          <TransparentButton
            iconName={cancelIcon}
            opacity={cancelTitle ? 0.1 : undefined}
            fontWeight="semi-bold"
            textColor="white"
            onClick={onCancel}
          >
            {cancelTitle || 'Отменить'}
          </TransparentButton>
        ) : null}
      </Content>
    </Wrapper>
  </>
)
