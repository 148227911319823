import React from 'react'
import { EuiPage } from '@elastic/eui'
import '@elastic/eui/dist/eui_theme_light.css'
import styled from 'styled-components'

const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
`

const Page = styled(EuiPage)`
  background-color: transparent;
`

interface Props {
  children: React.ReactNode
}

const PageTemplate: React.FC<Props> = ({ children }) => (
  <Wrapper>
    <Page>{children}</Page>
  </Wrapper>
)

export default React.memo(PageTemplate)
