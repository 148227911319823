import { batch, connect } from 'react-redux'

import { State } from 'reducers'
import { apiActions, apiSelectors } from 'api'
import { MyThunkDispatch } from 'api/reducer'
import { AggregatorType, ModalsNames } from 'common/enums'
import { modalsActions, modalsSelectors } from 'shared/modals'
import { StepAggregators } from 'steps/features/stepAggregators/organisms'
import { aggregatorsListActions } from 'steps/features/stepAggregators/shared'

const mapStateToProps = (state: State) => ({
  aggregators: apiSelectors.park.aggregators.getAggregators(state),
  isAddAggregatorModalOpened: modalsSelectors.getIsModalOpened(
    state,
    ModalsNames.AddAggregator
  ),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  fetchAggregators: () => dispatch(apiActions.park.aggregators.handleRequest()),
  openAddAggregatorModal: (type: AggregatorType) =>
    batch(() => {
      dispatch(aggregatorsListActions.setType(type))
      dispatch(modalsActions.openModal(ModalsNames.AddAggregator))
    }),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const StepAggregatorsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StepAggregators)
