import React, { useMemo } from 'react'

import {
  BankType,
  paymentReasonToString,
  PaymentStatus,
  RoutesPath,
} from 'common/enums'
import { Modal } from 'shared/ui'
import { ContainerProps } from 'payments/features/paymentDetailsModal/containers/PaymentDetailsModalContainer'
import PaymentDetailsCard, {
  Details,
} from 'ui-kit/molecules/PaymentDetailsCard/PaymentDetailsCard'
import { StatusType } from 'ui-kit/molecules/PaymentDetailsCard/Status/Status'
import {
  PaymentTransactionParticipant,
  RequisiteTransactionType,
} from 'api/types'
import { history } from 'store'
import { renderParticipant } from 'common/utils/helpers'
import {
  renderAccountNumber,
  renderAccountOwner,
  renderBankName,
  renderCardNumber,
} from 'common/enums/payments'

function transactionStatusMapper(status: PaymentStatus): StatusType {
  switch (status) {
    case PaymentStatus.ACKNOWLEDGED:
      return StatusType.Acknowledged
    case PaymentStatus.AUTHORIZED:
      return StatusType.Authorized
    case PaymentStatus.DECLINED:
      return StatusType.Declined
    case PaymentStatus.FAILED:
      return StatusType.Failed
    case PaymentStatus.NOT_COMPLETED:
      return StatusType.NotCompleted
    case PaymentStatus.PENDING:
      return StatusType.Pending
    case PaymentStatus.PROCESSING:
      return StatusType.Processed
    case PaymentStatus.SUCCEEDED:
      return StatusType.Completed
    default:
      return StatusType.Processed
  }
}

export const PaymentDetailsModal: React.FC<ContainerProps> = ({
  transaction,
  canProcessTransactions,
  canViewDriverDetails,
  onConfirm,
  closeModal,
  bankType,
  updateTransactionStatus,
  transactionStatus,
  saveData,
  resetStatus,
}) => {
  const isBankTypeAlpha = bankType === BankType.Alfa
  const isManageable = !!canProcessTransactions && transaction.is_manageable

  const handleClose = () => {
    closeModal({ withCallback: false })
    resetStatus()
  }

  const handleClickDriver = (
    participant: PaymentTransactionParticipant
  ) => () => {
    history.push(
      `${RoutesPath.Drivers}/${participant.park_id}/${participant.driver_id}`
    )
    saveData(transaction)
  }

  const fetchedTransactionStatus = transactionStatus?.status as PaymentStatus
  const data = useMemo(() => {
    const result: Details = {
      timestamp: new Date(transaction.created_at).getTime(),
      fromParticipant: renderParticipant(transaction.legs.from_participant),
      toParticipant: renderParticipant(transaction.legs.to_participant),
      reason: paymentReasonToString(transaction.reason),
      amount: transaction.amount,
      status: transactionStatusMapper(
        fetchedTransactionStatus || transaction.status
      ),
    }
    if (
      transaction.requisite &&
      transaction.requisite.requisite_type === RequisiteTransactionType.Bank
    ) {
      result.bankRequisites = {
        name: renderBankName(transaction.requisite),
        accountNumber: renderAccountNumber(transaction.requisite),
        accountOwner: renderAccountOwner(transaction.requisite),
      }
    }
    if (
      transaction.requisite &&
      transaction.requisite.requisite_type === RequisiteTransactionType.Card
    ) {
      result.cardRequisites = {
        number: renderCardNumber(transaction.requisite),
      }
    }
    return result
  }, [
    transaction.amount,
    transaction.created_at,
    transaction.legs.from_participant,
    transaction.legs.to_participant,
    transaction.reason,
    transaction.requisite,
    transaction.status,
    fetchedTransactionStatus,
  ])

  return (
    <Modal open withTransparent noOverlayClick={false}>
      <PaymentDetailsCard
        onClickToParticipant={
          transaction.legs.to_participant.driver_id && canViewDriverDetails
            ? handleClickDriver(transaction.legs.to_participant)
            : undefined
        }
        onClickFromParticipant={
          transaction.legs.from_participant.driver_id && canViewDriverDetails
            ? handleClickDriver(transaction.legs.from_participant)
            : undefined
        }
        onClickNext={undefined}
        onClickPrev={undefined}
        onRefund={async () => {
          await onConfirm(false)
        }}
        onRefreshStatus={isBankTypeAlpha ? updateTransactionStatus : undefined}
        onComplete={async () => {
          await onConfirm(true)
        }}
        onReject={async () => {
          await onConfirm(false)
        }}
        onResolve={async () => {
          await onConfirm(true)
        }}
        onClose={handleClose}
        data={data}
        isManageable={isManageable}
      />
    </Modal>
  )
}
