import React from 'react'

import { SubBodyText, SubTitleText } from 'ui-kit/components'
import { Col, HBox, NoContentImage, Row } from 'shared/ui'
import { DriverAccountsContainer } from 'drivers/features/driverAccounts'
import { ContainerProps } from 'drivers/features/driverAccountsTab/containers/DriverAccountsTabContainer'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex: 1;
`
const Info = styled.div`
  flex: 5;
  align-self: flex-start;
`
const Image = styled.div`
  flex: 5;
`
const Empty = styled.div`
  flex: 1;
`

export const DriverAccountsTab: React.FC<ContainerProps> = ({ hasAccounts }) =>
  hasAccounts ? (
    <Row>
      <Col percentage={50}>
        <DriverAccountsContainer />
      </Col>
    </Row>
  ) : (
    <>
      <HBox x={2.5} />
      <Container>
        <Empty />
        <Info>
          <SubTitleText color="darkGray" fontWeight="semi-bold">
            Водитель еще не привязал счёт
          </SubTitleText>
          <HBox x={0.5} />
          <SubBodyText color="darkGray">
            Чтобы получать деньги, водителю нужно указать в мобильном приложении
            номер рассчетного счета и БИК.
            <HBox x={0} />
            Он может сделать это во время вывода средств или в профиле.
          </SubBodyText>
        </Info>
        <Empty />
        <Image>
          <NoContentImage />
        </Image>
        <Empty />
      </Container>
    </>
  )
