import React from 'react'

import { Driver } from 'common/types/api/models/driver'

import { Column, Table } from 'shared/table'
import { PaymentSettingsDriverListItem } from 'settings/features/paymentSettingsDriversList/molecules/PaymentSettingsDriverListItem/PaymentSettingsDriverListItem'

interface Props {
  drivers: Driver[]
  driversIsLoading: boolean
  onRowClick?: (driver: Driver) => void
  onRemove?: (driver: Driver) => void
}

export const Listing: React.FC<Props> = ({
  drivers,
  onRowClick,
  onRemove,
  driversIsLoading,
}) => {
  const formatDriver = (driver: Driver) => {
    const handleClickRemoveItem = () => {
      if (onRemove) {
        onRemove(driver)
      }
    }

    return (
      <PaymentSettingsDriverListItem
        name={driver.driver_name}
        phone={driver.phone}
        onRemove={onRemove ? handleClickRemoveItem : undefined}
      />
    )
  }

  return (
    <Table
      source={drivers}
      onClickRow={onRowClick}
      bodyMinHeight="1110px"
      isLoading={driversIsLoading}
    >
      <Column data={formatDriver} />
    </Table>
  )
}
