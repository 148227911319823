import React from 'react'

import { BodyText } from 'ui-kit/components'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  width: 150px;
  height: 56px;
  align-self: flex-end;

  &:hover {
    [data-skip-tip] {
      opacity: 1;
      pointer-events: initial;
    }
  }
`

const Button = styled.button`
  display: flex;
  padding: 10px 16px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
  color: ${({ theme }) => theme.pallete.darkGray};
  border-radius: 32px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  div {
    transition: color 0.3s ease-in-out;
  }

  &:hover {
    background-color: rgba(234, 238, 247, 0.3);
  }

  &:active,
  &:hover {
    div {
      color: ${({ theme }) => theme.pallete.blue};
    }
  }
`

const Tip = styled.div`
  position: absolute;
  right: 0;
  bottom: calc(100% + 8px);
  padding: 16px;
  width: 288px;
  background-color: ${({ theme }) => theme.pallete.white};
  border-radius: 10px;
  box-shadow: 2px 4px 12px rgba(5, 16, 34, 0.06);
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  pointer-events: none;
`

interface Props {
  tipText?: React.ReactNode
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export const SkipButton: React.FC<Props> = ({ children, tipText, onClick }) => (
  <Wrapper>
    {tipText ? (
      <Tip data-skip-tip>
        <BodyText>{tipText}</BodyText>
      </Tip>
    ) : null}
    <Button onClick={onClick}>
      <BodyText color="darkGray" fontWeight="semi-bold">
        {children}
      </BodyText>
    </Button>
  </Wrapper>
)
