import React, { useContext } from 'react'

import { ColorTypes } from 'ui-kit'

import { DefaultProps } from 'ui-kit/components/Typography/interfaces'
import { TableTextWrapper } from 'ui-kit/components/Typography/styles'
import { ThemeContext } from 'styled-components'

interface Props extends DefaultProps {
  bold?: boolean
}

export const TableText: React.FC<Props> = (props) => {
  const { children, id, className, bold, color = 'black' as ColorTypes } = props
  const theme = useContext(ThemeContext)

  return (
    <TableTextWrapper
      id={id}
      bold={bold}
      color={theme.pallete[color]}
      className={className}
    >
      {children}
    </TableTextWrapper>
  )
}
