import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'
import { ContainerProps } from 'payments/features/manualConfirmationList/containers/ManualConfirmationListContainer'

import { ActionBottomPanel, ContentCard, HBox, Modal } from 'shared/ui'

import {
  ConfirmationHeader,
  ConfirmationPagination,
  ConfirmationTable,
} from 'payments/features/manualConfirmationList/organisms'
import { Placeholder } from 'payments/features/manualConfirmationList/atoms'
import { PaymentDetailsModalContainer } from 'payments/features/paymentDetailsModal'
import { PaginationLimit, PaymentTransaction } from 'api/types'
import {
  initialState,
  reducer,
} from 'payments/features/manualConfirmationList/reducer'
import styled from 'styled-components'
import { ColorButton, SubBodyText, Icon } from 'ui-kit/components'
import { Link } from 'ui-kit/atoms/Link'

export const Wrapper = styled.div`
  padding: ${({ theme }) => theme.paddings.main}px;
`

const outputFormatOptions = [
  { value: '.txt', description: 'зарплатный проект', name: 'txt' },
  { value: '.xml', description: 'зарплатный проект', name: 'xml' },
  { value: '.xlsx', description: 'для загрузки в АЗОН', name: 'xlsx' },
]

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const ModalLink = styled.span`
  font-size: 14px;
  text-decoration: underline;
  transform: translateX(-8px);
  position: relative;
  display: inline-block;
`

export const ManualConfirmationList: React.FC<ContainerProps> = ({
  isTransactionsFetching,
  isTransactionsConfirmFetching,
  transactions,
  totalAmount,
  fetchTransactions,
  fetchTransactionsPaymentOrder,
  canViewTransactionDetails,
  canProcessTransactions,
  isPaymentDetailsModalOpened,
  openPaymentDetailsModal,
  fetchTransactionsConfirm,
  modalData,
  selectedOutputFormatDownloadOption,
  setSelectedOutputFormatDownloadOption,
}) => {
  const [records, setRecords] = useState<Set<string>>(new Set())
  const [state, dispatch] = useReducer(reducer, initialState)
  const [transaction, setTransaction] = useState<PaymentTransaction>()
  const [isXMLWarningOpen, setIsXMLWarningOpen] = useState(false)

  const totalPages = useMemo(
    () => totalAmount && Math.ceil(totalAmount / state.limit),
    [totalAmount, state.limit]
  )

  const isPlaceholderVisible = !isTransactionsFetching && !totalPages
  const isPaginationVisible = totalPages > 0

  const handleFetch = useCallback(() => {
    fetchTransactions({
      params: {
        limit: state.limit,
        offset: state.limit * (state.page - 1),
        search: state.search,
      },
    })
    setRecords(new Set())
  }, [fetchTransactions, state.limit, state.page, state.search])

  useEffect(() => {
    const handleFetchWithModal = async () => {
      if (modalData) {
        setTransaction(modalData)
        openPaymentDetailsModal()
      } else {
        handleFetch()
      }
    }
    handleFetchWithModal()
  }, [handleFetch, openPaymentDetailsModal, modalData])

  const handleChangePage = useCallback(
    (pageOffset: number) => {
      dispatch({ type: 'changePage', pageOffset })
    },
    [dispatch]
  )

  const handleChangeLimit = useCallback(
    (limit: PaginationLimit) => {
      dispatch({ type: 'changeLimit', limit })
    },
    [dispatch]
  )

  const handleChangeSearch = useCallback(
    (search: string) => {
      dispatch({ type: 'changeSearch', search })
    },
    [dispatch]
  )

  const handleClearPayment = useCallback(() => {
    setRecords(new Set())
  }, [setRecords])

  const handleConfirmPayment = useCallback(
    async (confirmed: boolean, id?: string): Promise<void> => {
      const transactionIds = typeof id === 'string' ? [id] : Array.from(records)

      try {
        await fetchTransactionsConfirm({ confirmed, transactionIds })
        await handleFetch()
        handleClearPayment()
      } catch (e) {
        console.error(e)
      }
    },
    [fetchTransactionsConfirm, handleClearPayment, handleFetch, records]
  )

  const handleChangeCheckbox = useCallback(
    (newRecords: Set<string>) => {
      setRecords(newRecords)
    },
    [setRecords]
  )

  const handleRowClick = useCallback(
    (value: PaymentTransaction) => {
      setTransaction(value)
      openPaymentDetailsModal()
    },
    [openPaymentDetailsModal]
  )

  const handleDownloadBilling = useCallback(
    async (id?: string) => {
      try {
        const transactionIds =
          typeof id === 'string' ? [id] : Array.from(records)
        await fetchTransactionsPaymentOrder({
          transactionIds,
          fileFormat: selectedOutputFormatDownloadOption,
        })
        await handleFetch()
      } catch (e) {
        console.error(e)
      }
    },
    [
      fetchTransactionsPaymentOrder,
      handleFetch,
      records,
      selectedOutputFormatDownloadOption,
    ]
  )

  const handleDownloadWithXMLPopup = useCallback((id?: string) => {
      handleDownloadBilling(id)
      if (selectedOutputFormatDownloadOption === "xml") {
        setIsXMLWarningOpen(true)
      }
  }, [handleDownloadBilling, selectedOutputFormatDownloadOption])

  return (
    <>
      <Modal
        open={isXMLWarningOpen}
        title="Дополните XML-файл вручную"
        width={500}
      >
        <SubBodyText>
          Скачивание начнется автоматически. Перед загрузкой XML в 1С впишите
          номер договора с банком, дату заключения, номер и дату реестра.
        </SubBodyText>
        <HBox x={0.5} />
        <Link href="http://help.mozen.io/kak-zapolnit-polia-v-xml/">
          <Icon name="book" color="blue" pointer width={16} height={16}>
            <ModalLink>Как заполнить XML файл</ModalLink>
          </Icon>
        </Link>
        <HBox x={1} />
        <ButtonContainer>
          <ColorButton
            onClick={() => setIsXMLWarningOpen(false)}
            type="button"
            color="accentPrimary"
            fullWidth
          >
            Понятно
          </ColorButton>
        </ButtonContainer>
      </Modal>
      <Wrapper>
        <ContentCard>
          <ConfirmationHeader onChangeSearch={handleChangeSearch} />
          <ConfirmationTable
            data={transactions}
            records={records}
            isFetching={isTransactionsFetching}
            canViewTransactionDetails={canViewTransactionDetails}
            canProcessTransactions={canProcessTransactions}
            onChangeCheckbox={handleChangeCheckbox}
            onRowClick={handleRowClick}
            onDownload={handleDownloadWithXMLPopup}
            onConfirmPayment={handleConfirmPayment}
            selectedOutputFormat={selectedOutputFormatDownloadOption}
            onSelectedOutputFormatChange={({ name }) =>
              setSelectedOutputFormatDownloadOption(name)
            }
            downloadOptions={outputFormatOptions}
          />
          {isPaginationVisible && (
            <ConfirmationPagination
              currentLimit={state.limit}
              currentPage={state.page}
              total={totalPages}
              onChangePage={handleChangePage}
              onChangeLimit={handleChangeLimit}
            />
          )}
          {isPlaceholderVisible && <Placeholder />}
        </ContentCard>
      </Wrapper>
      <ActionBottomPanel
        count={records.size}
        isLoading={isTransactionsConfirmFetching}
        onConfirm={handleConfirmPayment}
        doTitle="Выплачено"
        dontTitle="Отклонить платежи"
        doDownload="Скачать поручение"
        onDownload={handleDownloadWithXMLPopup}
        downloadOptions={outputFormatOptions}
        selectedDownloadOption={selectedOutputFormatDownloadOption}
        onDownloadOptionChange={(selectedOption) =>
          setSelectedOutputFormatDownloadOption(selectedOption.name)
        }
        backgroundColor={
          selectedOutputFormatDownloadOption === 'xlsx' ? '#008F27' : undefined
        }
      />
      {isPaymentDetailsModalOpened && transaction ? (
        <PaymentDetailsModalContainer
          transaction={transaction}
          callbackFetch={handleFetch}
        />
      ) : null}
    </>
  )
}
