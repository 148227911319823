import React, { useEffect, useState } from 'react'
import { InjectedFormProps, SubmissionError } from 'redux-form'

import axios, { AxiosResponse, AxiosError } from 'axios'
import Alert from 'react-s-alert'

import { ApiCode } from 'common/types/api/codes'
import { digits } from 'common/utils/formatters'

import {
  CancelChangesModal,
  CancelOrSave,
  ContentCard,
  ContentCardHeader,
} from 'shared/ui'

import { ProfileInfoForm } from 'settings/features/profileInfo/molecules'

import {
  ContainerProps,
  FormProps,
} from 'settings/features/profileInfo/containers/ProfileInfoContainer'
import { transformToAuthTokens } from 'bootstrap'
import { AuthTokens, setAuthTokens } from 'jwtAuth'

export const ProfileInfo: React.FC<
  InjectedFormProps<FormProps, ContainerProps> & ContainerProps
> = ({
  pristine,
  initialize,
  handleSubmit,
  submitting,
  profile,
  patchProfile,
}) => {
  useEffect(() => {
    initialize({
      full_name: profile?.full_name,
      phone: profile?.phone,
    })
  }, [initialize, profile])

  const [showPassword, setShowPassword] = useState(false)
  const [cancelChangesIsOpen, setCancelChangesIsOpen] = useState(false)

  const handleSave = async (values: FormProps): Promise<void> => {
    const profileChangeData = {
      full_name: values.full_name,
      phone: digits(values.phone),
    }

    try {
      if (values.oldPassword) {
        const response: AxiosResponse<AuthTokens> = await axios.post(
          `auth/1/common/password`,
          {
            email: profile?.email,
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
          },
          {
            transformResponse: transformToAuthTokens,
          }
        )
        setAuthTokens(response.data)

        setShowPassword(false)
      }

      await axios.patch(`erp/1/employees/${profile?.id}`, profileChangeData)

      patchProfile({
        ...profileChangeData,
      })

      initialize({
        full_name: values.full_name,
        phone: values.phone,
      })

      Alert.info('Изменения сохранены')
    } catch (error) {
      const { response } = error as AxiosError
      if (response && response.data.code === ApiCode.InputValidationError) {
        throw new SubmissionError(response.data.payload)
      }
    }
  }

  const handleCancelModalClose = (isCancelled: boolean): void => {
    setCancelChangesIsOpen(false)

    if (isCancelled) {
      initialize({
        full_name: profile?.full_name,
        phone: profile?.phone,
      })

      setShowPassword(false)
    }
  }

  const toggleShowPassword = () => setShowPassword((pewValue) => !pewValue)

  return (
    <>
      <ContentCard>
        <ContentCardHeader title="Личные данные">
          {!pristine ? (
            <CancelOrSave
              isLoading={submitting}
              onCancel={() => setCancelChangesIsOpen(true)}
              onSubmit={handleSubmit(handleSave)}
            />
          ) : null}
        </ContentCardHeader>
        <ProfileInfoForm
          showPassword={showPassword}
          toggleShowPassword={toggleShowPassword}
        />
      </ContentCard>

      {cancelChangesIsOpen ? (
        <CancelChangesModal onClose={handleCancelModalClose} />
      ) : null}
    </>
  )
}
