import React from 'react'
import { TransparentButton } from 'ui-kit/components'
import { history } from 'store'
import { RoutesPath } from 'common/enums'
import { ContentCard, ContentCardBody, ContentCardHeader } from 'shared/ui'
import {
  CurrentListing,
  FormerListing,
} from 'employees/features/employeesList/molecules'
import { AddEmployeeModalContainer } from 'employees/features/employeesList/containers'
import { ContainerProps } from 'employees/features/employeesList/containers/EmployeesListContainer'

export const EmployeesList: React.FC<ContainerProps> = ({
  canViewEmployeeDetails,
  canChangeEmployee,
  isFetching,
  activeEmployees,
  inactiveEmployees,
  isAddEmployeeModalOpened,
  openAddEmployeeModal,
}) => {
  const handleEmployeeClick = (id: string) =>
    canViewEmployeeDetails
      ? history.push(`${RoutesPath.Employees}/${id}`)
      : undefined
  return (
    <>
      <ContentCard>
        <ContentCardHeader title="Сотрудники автопарка">
          {canChangeEmployee ? (
            <TransparentButton
              iconName="add"
              fontWeight="semi-bold"
              onClick={openAddEmployeeModal}
            >
              Добавить сотрудника
            </TransparentButton>
          ) : null}
        </ContentCardHeader>
        <ContentCardBody isLoading={isFetching} noPadding>
          <CurrentListing
            employees={activeEmployees}
            onEmployeeClick={handleEmployeeClick}
          />
          <FormerListing
            employees={inactiveEmployees}
            onEmployeeClick={handleEmployeeClick}
          />
        </ContentCardBody>
      </ContentCard>

      {isAddEmployeeModalOpened ? <AddEmployeeModalContainer /> : null}
    </>
  )
}
