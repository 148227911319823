import { history } from 'store'
import { apiActions } from 'api'
import { MyThunkDispatch, MyThunkResult } from 'api/reducer'
import { digits } from 'common/utils/formatters'
import { ApiCode } from 'common/types/api/codes'
import { RoutesPath } from 'common/enums'
import { FormProps } from 'auth/features/signUp/containers/SignUpContainer'

export const onSave: ({
  values,
}: {
  values: FormProps
}) => MyThunkResult<void> = ({ values }) => (dispatch: MyThunkDispatch) => {
  return dispatch(
    apiActions.park.register.handleRequest({
      values: {
        ...values,
        phone: digits(values.phone),
      },
    })
  ).then(
    () => {
      localStorage.setItem('registration-email', values.email)
      history.push(RoutesPath.SignUpSuccess)
    },
    (reason) => {
      if (
        reason.response &&
        reason.response.data.code === ApiCode.InputValidationError &&
        reason.response.data.payload
      ) {
        throw reason.response.data.payload
      }
    }
  )
}
