import { createApiReducer } from 'common/utils/reduxApiFactory'
import { getParkAzonActions } from './actions'
import { GetParkAzonState } from './types'

export const initialState: GetParkAzonState = {
  status: null,
  data: null,
}

export const getParkAzonReducer = createApiReducer(
  getParkAzonActions,
  initialState
)
