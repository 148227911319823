export enum SuspectTransactionStatus {
  REFUNDED = 'refunded',
  HELD = 'held',
  HOLD_FAILED = 'hold_failed',
  CANCELLED = 'cancelled',
}

export const suspectTransactionStatusToString = (
  status: SuspectTransactionStatus
): string => {
  switch (status) {
    case SuspectTransactionStatus.REFUNDED:
      return 'Поездка подтверждена'
    case SuspectTransactionStatus.HELD:
      return 'Средства списаны'
    case SuspectTransactionStatus.HOLD_FAILED:
      return 'Средства не списаны'
    case SuspectTransactionStatus.CANCELLED:
      return 'Поездка заблокирована'
    default:
      return status
  }
}
