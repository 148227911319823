import React from 'react'
import styled from 'styled-components'
import { initTimelines } from 'ui-kit/components/Currency/animations'
import { currencyApart } from 'common/utils/formatters'
import { ColorTypes, FontWeightTypes } from 'ui-kit'
import { BodyText, CaptionText } from 'ui-kit/components/Typography'

type AnimationProps = {
  isIncrement: boolean
}

type Props = {
  value?: number
  regularFontSize?: number
  regularFontWidth?: FontWeightTypes
  decimalFontSize?: number
  decimalFontWidth?: FontWeightTypes
  color?: ColorTypes
  explicitNegativeSign?: boolean
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
`

const OddValue = styled.div`
  will-change: transform;
  position: absolute;
  z-index: 10;
`

const EvenValue = styled.div`
  will-change: transform;
  position: absolute;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
`

const Hidden = styled.div`
  opacity: 0;
  overflow: hidden;
`

const ScaleCaption = styled.span<{ scale?: number }>`
  font-size: ${({ scale }) => scale && `${scale}px`};
`

const ScaleBody = styled.span<{ scale?: number }>`
  font-size: ${({ scale }) => scale && `${scale}px`};
  line-height: 150%;
`

export const Currency = React.memo<Props>(
  ({
    value,
    color,
    regularFontWidth = 'bold',
    decimalFontWidth = 'bolder',
    decimalFontSize,
    regularFontSize,
    explicitNegativeSign,
  }) => {
    const odd = React.useRef<HTMLDivElement>(null)
    const oddValue = React.useRef<number | undefined | null>(value)
    const even = React.useRef<HTMLDivElement>(null)
    const evenValue = React.useRef<number | undefined | null>(value)
    const isParityToggled = React.useRef(false)
    const animations = React.useRef<ReturnType<typeof initTimelines>>()

    React.useEffect(() => {
      animations.current = initTimelines(odd.current, even.current)
    }, [])

    const runOddAnimation = React.useCallback(
      ({ isIncrement }: AnimationProps) => {
        animations.current?.incrementOddTimeline.kill()
        animations.current?.decrementOddTimeline.kill()
        animations.current?.incrementEvenTimeline.kill()
        animations.current?.decrementEvenTimeline.kill()
        if (isIncrement) {
          animations.current?.incrementOddTimeline.restart()
        } else {
          animations.current?.decrementOddTimeline.restart()
        }
      },
      [animations]
    )

    const runEvenAnimation = React.useCallback(
      ({ isIncrement }: AnimationProps) => {
        animations.current?.incrementOddTimeline.kill()
        animations.current?.decrementOddTimeline.kill()
        animations.current?.incrementEvenTimeline.kill()
        animations.current?.decrementEvenTimeline.kill()
        if (isIncrement) {
          animations.current?.incrementEvenTimeline.restart()
        } else {
          animations.current?.decrementEvenTimeline.restart()
        }
      },
      [animations]
    )

    if (isParityToggled.current) {
      oddValue.current = value
    } else {
      evenValue.current = value
    }

    const isInitialRender = React.useRef(true)
    React.useEffect(() => {
      if (isInitialRender.current) {
        isInitialRender.current = false
        initTimelines(odd.current, even.current)
        return
      }

      const runAnimation = () => {
        if (isParityToggled.current) {
          runOddAnimation({
            isIncrement: (value || 0) - (evenValue.current || 0) > 0,
          })
        } else {
          runEvenAnimation({
            isIncrement: (value || 0) - (oddValue.current || 0) > 0,
          })
        }

        isParityToggled.current = !isParityToggled.current
      }

      runAnimation()
    }, [runEvenAnimation, runOddAnimation, value])

    const getCurrencyApart = (amount: number) => {
      return currencyApart(amount, undefined, undefined, explicitNegativeSign)
    }

    return (
      <BodyText color={color} fontWeight={regularFontWidth}>
        <ScaleBody scale={regularFontSize}>
          <Wrapper>
            <OddValue ref={odd}>
              {oddValue.current !== undefined && oddValue.current !== null
                ? getCurrencyApart(oddValue.current)[1]
                : '—'}
              {oddValue.current !== undefined && oddValue.current !== null && (
                <CaptionText color={color} fontWeight={decimalFontWidth}>
                  <ScaleCaption scale={decimalFontSize}>
                    {getCurrencyApart(oddValue.current)[2]}
                  </ScaleCaption>
                </CaptionText>
              )}
            </OddValue>
            <EvenValue ref={even}>
              {evenValue.current !== undefined && evenValue.current !== null
                ? getCurrencyApart(evenValue.current)[1]
                : '—'}
              {evenValue.current !== undefined && evenValue.current !== null && (
                <CaptionText color={color} fontWeight={decimalFontWidth}>
                  <ScaleCaption scale={decimalFontSize}>
                    {getCurrencyApart(evenValue.current)[2]}
                  </ScaleCaption>
                </CaptionText>
              )}
            </EvenValue>
            <Hidden>
              {
                getCurrencyApart(
                  (oddValue.current || 0) >= (evenValue.current || 0)
                    ? oddValue.current || 0
                    : evenValue.current || 0
                )[1]
              }
              <CaptionText color={color} fontWeight={decimalFontWidth}>
                <ScaleCaption scale={decimalFontSize}>
                  {
                    getCurrencyApart(
                      (oddValue.current || 0) >= (evenValue.current || 0)
                        ? oddValue.current || 0
                        : evenValue.current || 0
                    )[2]
                  }
                </ScaleCaption>
              </CaptionText>
            </Hidden>
          </Wrapper>
        </ScaleBody>
      </BodyText>
    )
  }
)
Currency.displayName = 'Currency'
