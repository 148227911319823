import { createApiReducer } from 'common/utils/reduxApiFactory'
import { postFuelSettingsActions } from 'api/fuel/settings/post/actions'
import { PostFuelSettingsState } from 'api/fuel/settings/post/types'

export const initialState: PostFuelSettingsState = {
  status: null,
  data: null,
}

export const postFuelSettingsReducer = createApiReducer(
  postFuelSettingsActions,
  initialState
)
