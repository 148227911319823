import { State } from 'reducers'
import { apiActions, apiSelectors } from 'api'
import { MyThunkDispatch, MyThunkResult } from 'api/reducer'

export const onDidMount: (driver_id: string) => MyThunkResult<void> = (
  driver_id
) => {
  return (dispatch: MyThunkDispatch, getState: () => State) => {
    dispatch(apiActions.driversById.get.handleRequest(driver_id))
    if (
      apiSelectors.employee.profile.getCanViewParkRequisites(getState()) &&
      !apiSelectors.park.details.getBankType(getState())
    ) {
      dispatch(apiActions.park.details.handleRequest())
    }
  }
}
