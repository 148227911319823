import React from 'react'

import { BodyText, Icon } from 'ui-kit/components'
import { HBox, VBox } from 'shared/ui/spacers'
import { Col } from 'shared/ui/flex'
import styled from 'styled-components'

const Warning = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.paddings.main}px;
  align-items: center;

  a {
    display: contents;
    text-decoration: none;
    color: ${({ theme }) => theme.pallete.blue};
    font-weight: 600;
  }
`

export const PaymentSettingsLockPlaceholder = React.memo(() => {
  return (
    <Warning>
      <Icon name="warning" color="red" />
      <VBox x={2} />
      <Col>
        <BodyText>
          Настройка платежей доступна только для клиентов, которые полностью
          заполнили профиль парка.
          <HBox x={0} />
          Пожалуйста, добавьте на вкладке Реквизиты сертификат безопасности
          Альфа-Линк (если вы клиент Альфа-Банка) или данные Qiwi-счёта (если вы
          клиент банка Точка), чтобы управлять настройками вывода средств.
          <HBox x={0} />О том, как получить данные сертификата безопасности или
          данные Qiwi-счёта, вы можете узнать у вашего менеджера.
          {/* О том как получить сертификат H2H
                от Альфа-Банка и улучшить свой бизнес можно узнать{' '}
                <a href="https://alfabank.ru/sme/quick/invite/" target="_blank">
                  на сайте.
                </a> */}
        </BodyText>
      </Col>
    </Warning>
  )
})

PaymentSettingsLockPlaceholder.displayName = 'PaymentSettingsLockPlaceholder'
