import styled from 'styled-components'

export const LeftContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const LeftContent = styled.div`
  width: 100%;
  max-width: 464px;

  .title {
    margin-bottom: 16px;
  }

  .description {
    min-height: 64px;
    margin-bottom: 8px;

    p {
      color: ${({ theme }) => theme.pallete.gray9};
    }
  }

  .queue-count {
    svg {
      opacity: 0.8;
    }

    p {
      color: ${({ theme }) => theme.pallete.black};
    }
  }
`

export const Progress = styled.div`
  background-color: ${({ theme }) => theme.pallete.gray2};
  height: 32px;
  border-radius: 19px;
  margin: 8px 0;

  div {
    width: 60%;
    height: 100%;
    background-color: ${({ theme }) => theme.pallete.blue};
    border-radius: 19px;
    animation: load 1s ease-out;
  }

  @keyframes load {
    0% {
      width: 0;
    }
    100% {
      width: 60%;
    }
  }
`
export const BackToMain = styled.div`
  margin-top: 8px;

  a {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    min-height: 56px;
    padding: 4px 16px;
  }
`

export const RightContent = styled.div`
  display: flex;
  align-items: center;
  height: calc(100vh - 170px);

  .right-title {
    padding: 0 16px 0 72px;
    margin-bottom: 15px;
  }
`

export const ImageHolder = styled.div`
  img {
    position: relative;
    left: -78px;
    object-position: right;
  }
`
