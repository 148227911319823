import { connect } from 'react-redux'

import { ProfileTab } from 'settings/features/profileTab/organisms'

const mapStateToProps = () => ({})

const mapDispatchToProps = {}

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps

export const ProfileTabContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileTab)
