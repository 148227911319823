import React from 'react'

import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

interface SideMenuWrapper {
  textOpacity?: boolean
  minified?: boolean
  minWidth?: number
  maxWidth?: number
}

export const Wrapper = styled('div')<SideMenuWrapper>`
  position: relative;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.pallete.black};
  height: 100vh;
  width: ${(props) =>
    props.minified ? `${props.minWidth}px` : `${props.maxWidth}px`};
  overflow-x: hidden;

  ${(props) =>
    props.textOpacity &&
    `.muk-side-menu-mozen-text, .muk-side-menu-link-icon .muk-icon-title {opacity: 0;}`};

  &::before {
    content: '';
    border-top: 7px solid transparent;
    border-right: 12px solid ${({ theme }) => theme.pallete.white};
    border-bottom: 7px solid transparent;
    position: absolute;
    right: 0;
    top: 25px;
  }
`

interface Trigger {
  resizable?: boolean
}

export const Trigger = styled('div')<Trigger>`
  position: absolute;
  right: -5px;
  bottom: 0;
  top: 0;
  width: 10px;
  height: 100%;
  background-color: transparent;
  cursor: ${(props) => (props.resizable ? 'e-resize' : 'default')};
`

interface ContentWrapper {
  maxWidth?: number
}

export const ContentWrapper = styled('div')<ContentWrapper>`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  height: 100%;
  width: ${(props) => props.maxWidth && `${props.maxWidth}px`};
  padding: 40px 0;
  box-sizing: border-box;
`

interface Header {
  left?: boolean
}

export const Header = styled('div')<Header>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (!props.left ? 'center' : 'flex-start')};
  padding: 0 16px;
  margin-bottom: 66px;

  .muk-side-menu-small-logo {
    .muk-icon {
      width: 24px !important;
      height: 24px !important;
      margin-right: 16px;
    }
  }

  .muk-side-menu-mozen-text {
    .muk-icon {
      width: 100px !important;
      height: 24px !important;
      transition: 0.3s ease;
    }
  }
`

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

export const ListItem = styled.li`
  width: 100%;
`

interface LinkProps {
  to: string
  className: string
  activeClassName: string
  children: React.ReactNode
}

export const Link = styled(
  ({ children, to, className, activeClassName }: LinkProps) => (
    <NavLink
      to={to}
      exact
      className={className}
      activeClassName={activeClassName}
    >
      {children}
    </NavLink>
  )
)`
  text-decoration: none;
  width: 100%;
  display: block;

  &.muk-side-menu-link-active {
    .muk-side-menu-link-icon {
      svg {
        path {
          fill: ${({ theme }) => theme.pallete.blue};
        }
      }

      .muk-icon-title {
        color: ${({ theme }) => theme.pallete.white};
      }
    }
  }
`

export const LinkContent = styled.span`
  display: flex;
  align-items: center;
  padding: 10px 16px;
  box-sizing: border-box;
  word-break: break-word;

  .muk-side-menu-link-icon {
    pointer-events: none;

    svg {
      path {
        fill: ${({ theme }) => theme.pallete.darkGray};
      }
    }

    .muk-icon-title {
      font-weight: 600;
      transition: 0.3s ease;
      color: ${({ theme }) => theme.pallete.darkGray};
      font-size: 12px;
      line-height: 24px;
      text-transform: uppercase;
    }
  }
`
