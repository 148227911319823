import {
  EuiSpacer,
  EuiFieldNumber,
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiRange,
} from '@elastic/eui'
import { clamp, fixDecimalPrecisionErrors } from 'elastic-ui/utils'
import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { IconBlock } from '../../../shared/molecules/IconBlock/IconBlock'
import { Modal } from '../../../shared/molecules/Modal/Modal'

const NumberFieldFlexItem = styled(EuiFlexItem)`
  min-width: 64px;
`

interface Props {
  isOpen: boolean
  maxCashbackPercent: number
  initialCashbackPercent: number
  onClose: () => void
  onAccept: (cashbackPercent: number) => void
}

type InputChangeEvent = React.ChangeEvent<HTMLInputElement>
type RangeChangeEvent =
  | React.ChangeEvent<HTMLInputElement>
  | React.MouseEvent<HTMLButtonElement, MouseEvent>

export const BonusSettingsModal: React.FC<Props> = ({
  onClose,
  onAccept,
  isOpen,
  maxCashbackPercent,
  initialCashbackPercent,
}) => {
  const [cashbackPercent, setCashbackPercent] = useState(initialCashbackPercent)

  const clampPercent = useCallback(
    (percent: number) => clamp(percent, { min: 0, max: maxCashbackPercent }),
    [maxCashbackPercent]
  )

  const driverPercent = fixDecimalPrecisionErrors(
    clampPercent(maxCashbackPercent - cashbackPercent),
    { fixedNumbersAfterDecimalPoint: 2 }
  )

  const handleAccept = useCallback(() => {
    onAccept(cashbackPercent)
  }, [cashbackPercent, onAccept])

  const handleNumberFieldChange = useCallback(
    (e: InputChangeEvent) => {
      const { valueAsNumber } = e.target
      const clampedPercent = fixDecimalPrecisionErrors(
        clampPercent(valueAsNumber),
        { fixedNumbersAfterDecimalPoint: 2 }
      )
      setCashbackPercent(clampedPercent)
    },
    [setCashbackPercent, clampPercent]
  )

  const handleRangeChange = useCallback(
    (e: RangeChangeEvent) => {
      const { valueAsNumber } = (e as React.ChangeEvent<
        HTMLInputElement
      >).target
      const clampedPercent = clampPercent(valueAsNumber)
      setCashbackPercent(clampedPercent)
    },
    [setCashbackPercent, clampPercent]
  )

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      title="Условия"
      cancelLabel="Отмена"
      acceptLabel="Сохранить"
      acceptIcon="save"
      onCancel={onClose}
      onAccept={handleAccept}
      onClickAway={onClose}
    >
      Вам возвращается процент от суммы, которую водители списывают с топливного
      депозита. Если направите часть водителям, мы зачислим им дополнительную
      сумму на топливную карту.
      <EuiSpacer size="l" />
      <IconBlock iconType="company">
        Вы получите
        <EuiSpacer size="xs" />
        <EuiFlexGroup alignItems="center" gutterSize="s">
          <NumberFieldFlexItem>
            <EuiFieldNumber
              value={cashbackPercent}
              step={0.01}
              onChange={handleNumberFieldChange}
            />
          </NumberFieldFlexItem>
          <EuiFlexItem grow={5}>
            <EuiRange
              min={0}
              max={maxCashbackPercent}
              value={cashbackPercent}
              step={0.01}
              onChange={handleRangeChange}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText>из {maxCashbackPercent}%</EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      </IconBlock>
      <EuiSpacer size="m" />
      <IconBlock iconType="user">Водитель получит {driverPercent}%</IconBlock>
    </Modal>
  )
}
