import React from 'react'
import styled from 'styled-components'
import { ButtonTypes } from 'ui-kit'

export interface DefaultProps {
  id?: string
  className?: string
  type?: ButtonTypes
  disabled?: boolean
  hovered?: boolean
  active?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  onClickCapture?: (e: React.MouseEvent<HTMLButtonElement>) => void
  onMouseOver?: (e: React.MouseEvent<HTMLButtonElement>) => void
  onMouseOut?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export const Base = styled.button<DefaultProps>`
  display: inline-flex;
  position: relative;
  padding: 8px 16px;
  height: 40px;
  min-width: 160px;
  justify-content: center;
  align-items: center;

  background-color: transparent;

  border: none;
  border-radius: 8px;
  outline: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  transition: background-color 0.3s ease;
  div {
    transition: color 0.3s ease;
  }
  svg {
    path {
      transition: 0.3s ease;
    }
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: ${({ theme }) => theme.pallete.black};
    border-radius: 4px;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
  }

  &:hover {
    &::before {
      opacity: ${({ disabled }) => !disabled && '0.1'};
    }
  }

  &:active {
    &::before {
      opacity: 0.2;
    }
  }

  ${({ active }) => active && `&::before {opacity: 0.2;}`}
`
