import React from 'react'
import styled from 'styled-components'
import { Icon as DefaultIcon } from 'ui-kit/components'
import Typography from 'ui-kit/typography'

type Props = {
  value: number
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const Icon = styled(DefaultIcon)`
  margin-right: 4px;
`

export const ViewCount = ({ value }: Props) => {
  return (
    <Wrapper>
      <Icon name="eye" />
      <Typography fontColor="textSecondary">
        {value.toLocaleString()}
      </Typography>
    </Wrapper>
  )
}
