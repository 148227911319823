import React from 'react'
import { EuiFormRow, EuiFormRowProps } from '@elastic/eui'
import { LimitContainer, LimitContainerProps } from './LimitContainer'

const EuiFormRowEnhanced: React.FC<EuiFormRowProps & LimitContainerProps> = ({
  error,
  isInvalid = Boolean(error),
  maxWidth,
  maxHeight,
  ...props
}) => (
  <LimitContainer maxWidth={maxWidth} maxHeight={maxHeight}>
    <EuiFormRow error={error} isInvalid={isInvalid} fullWidth {...props} />
  </LimitContainer>
)

export default EuiFormRowEnhanced
