import axios from 'axios'
import { BaseAction } from 'redux-actions'
import { ThunkAction } from 'redux-thunk'

import { State } from 'reducers'
import { failure, start, success } from 'api/park/aggregatorById/patch/actions'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  BaseAction
>
export const HANDLE_REQUEST: BaseAction['type'] =
  'api/park/aggregatorById/patch->HANDLE_REQUEST'

export const handleRequest = ({
  type,
  id,
  values,
}: any): THandleRequest => async (dispatch) => {
  dispatch(start())
  try {
    await axios.patch(`erp/1/park/aggregator/${id}`, {
      aggregator_type: type,
      aggregator_meta: { ...values },
    })
    dispatch(success())
  } catch (e) {
    if (e.response) {
      dispatch(failure(e.response.data))
    }
    throw e
  }
}
