import { connect } from 'react-redux'

import { EmployeeRights } from 'employees/features/employeeRights/organisms'

const mapStateToProps = () => ({})

const mapDispatchToProps = () => ({})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const EmployeeRightsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeRights)
