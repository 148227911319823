import styled from 'styled-components'

export const AlertMessage = styled.div`
  width: 360px;
  max-width: none !important;
  padding: 16px 16px 16px 56px !important;
  margin: 0 0 20px 12px;
  background: ${({ theme }) => theme.pallete.white} !important;
  border-radius: 8px;
  box-shadow: 0 3px 10px rgba(5, 16, 34, 0.17);

  .icon {
    position: absolute;
    top: 16px;
    left: 16px;
  }

  .close {
    position: absolute;
    z-index: 1;
    top: 16px;
    right: 16px;
  }
`
