import React from 'react'
import { CaptionText, SubBodyText } from 'ui-kit/components'
import { DropdownData } from 'shared/ui/dropdown/types'
import { Avatar, ContentCard, ContentCardBody, Dropdown, HBox } from 'shared/ui'
import styled, { useTheme } from 'styled-components'

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
// 208px
const RightSide = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
`

interface Props {
  id?: string
  avatar?: string
  name?: string
  position?: string
  type?: string
  account?: string
  editable?: boolean
  onSetOpen?: (isOpen: boolean) => void
  onSetId?: (id: string) => void
}

export const RegularCard: React.FC<Props> = ({
  id,
  avatar,
  account,
  type,
  name,
  position,
  editable,
  onSetOpen,
  onSetId,
}) => {
  const setOpenEditModal = (): void => {
    if (onSetOpen) {
      onSetOpen(true)
    }
    if (onSetId && id) {
      onSetId(id)
    }
  }

  const dropdownMenu: DropdownData[] = [
    {
      label: 'Редактировать',
      iconName: 'edit',
      function: () => setOpenEditModal(),
    },
  ]

  const theme = useTheme()

  return (
    <ContentCard
      fullHeight
      backgroundColor={theme.pallete.gray1}
      boxShadow="2px 4px 12px rgba(5, 16, 34, 0.06)"
    >
      <ContentCardBody>
        <LeftSide>
          <Avatar avatar={avatar} size="32px" />
          <HBox />
          <SubBodyText>{account || name}</SubBodyText>
          <HBox x={0.25} />
          <CaptionText color="gray7">{type || position}</CaptionText>
        </LeftSide>
        {editable ? (
          <RightSide>
            <Dropdown data={dropdownMenu} right />
          </RightSide>
        ) : null}
      </ContentCardBody>
    </ContentCard>
  )
}
