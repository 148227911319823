export enum DriverBalanceType {
  Bonus = 'bonus',
  Park = 'park',
}

export enum DriverAutoWithdrawStatus {
  NotSet = 'not_set',
  Restricted = 'restricted',
  Allowed = 'allowed',
}

export enum DriverStatus {
  Working = 'working',
  NotWorking = 'not_working',
  Fired = 'fired',
}

export const driverStatusToString = (status: DriverStatus): string => {
  switch (status) {
    case DriverStatus.Working:
      return 'Активен'
    case DriverStatus.NotWorking:
      return 'Не активен'
    case DriverStatus.Fired:
      return 'Уволен'
    default:
      return status
  }
}
