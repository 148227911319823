import React from 'react'

import { BodyText, Icon } from 'ui-kit/components'
import { IconNames, ColorTypes } from 'ui-kit'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
`

const IconWrapper = styled.div<{ iconOpacity: number }>`
  width: 100%;
  height: 100%;

  .tooltip {
    svg {
      opacity: ${({ iconOpacity }) => iconOpacity};
    }
  }
`
const BulbIconWrapper = styled.div`
  .bulb {
    .muk-icon {
      width: 32px !important;
      height: 32px !important;
    }
  }
`

interface Props {
  content: React.ReactNode
  alwaysOpen?: boolean
  backgroundColor?: string
  ownContainer?: React.ReactNode
  iconName?: IconNames
  iconColor?: ColorTypes
  textColor?: ColorTypes
  contentComponent?: React.FC
  bulb?: boolean
  iconOpacity?: number
  withDefaultIconColor?: boolean
}

export const Tooltip: React.FC<Props> = ({
  alwaysOpen,
  backgroundColor,
  content = undefined,
  ownContainer,
  iconName = 'question',
  iconColor = 'gray5',
  textColor,
  contentComponent,
  withDefaultIconColor = false,
  bulb,
  iconOpacity = 1,
}) => {
  const [isVisible, setIsVisible] = React.useState(alwaysOpen || false)

  const Content = styled(contentComponent || 'div')<{
    backgroundColor?: string
  }>`
    position: absolute;
    bottom: 0;
    left: 40px;
    border-radius: 10px;
    padding: 8px 16px;
    background-color: ${({ backgroundColor: newBackgroundColor, theme }) =>
      newBackgroundColor || theme.pallete.white};
    width: 326px;
    z-index: 100;
  `

  const onSetIsVisible = (newIsVisible: boolean) =>
    !alwaysOpen && setIsVisible(newIsVisible)

  return (
    <Wrapper>
      <IconWrapper
        iconOpacity={iconOpacity}
        onMouseOut={() => onSetIsVisible(false)}
        onMouseOver={() => onSetIsVisible(true)}
      >
        {!bulb ? (
          <Icon
            pointer={false}
            className="tooltip"
            name={iconName}
            color={withDefaultIconColor ? undefined : iconColor}
          />
        ) : (
          <BulbIconWrapper>
            <Icon
              pointer={false}
              className="bulb tooltip"
              name="bulb"
              color={withDefaultIconColor ? undefined : iconColor}
            />
          </BulbIconWrapper>
        )}
      </IconWrapper>
      {ownContainer
        ? isVisible && ownContainer
        : isVisible && (
            <Content backgroundColor={backgroundColor}>
              <BodyText color={textColor || undefined}>{content}</BodyText>
            </Content>
          )}
    </Wrapper>
  )
}
