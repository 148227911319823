import React from 'react'
import { Field } from 'redux-form'

import { Image } from 'shared/ui'
import { AuthLayout } from 'shared/router'
import { email, required } from 'common/utils/fieldValidation'
import ListInput from 'common/components/formItems/ListInput'

import {
  Actions,
  Form,
  ImageHolder,
  LeftContent,
  LeftContentWrapper,
  RightContent,
} from 'auth/emailConfirmationLost/styles'

import {
  BodyText,
  ListItem,
  TitleText,
  TransparentButton,
} from 'ui-kit/components'

import handSrc from 'static/backgrounds/hand.svg'

interface Props {
  minutes: number
  seconds: number
  total: number
  isSubmitLoading?: boolean
  onSubmit: () => void
}

const EmailConfirmationLostComponent: React.FC<Props> = (props) => {
  const { onSubmit, isSubmitLoading, seconds, minutes, total } = props

  const formatTime = (value: number): string =>
    `${value < 10 ? '0' : ''}${value}`

  const renderLeftSideContent = (): JSX.Element => {
    return (
      <LeftContentWrapper>
        <LeftContent>
          <ListItem>
            <TitleText fontWeight="semi-bold" className="title">
              Не пришло письмо?
            </TitleText>
          </ListItem>
          <BodyText className="text">
            Возможно вы не правильно указали свой email.
            <br />
            Укажите свою электронную почту еще раз
          </BodyText>
          <Form>
            <Field
              name="email"
              label="Введите Email повторно"
              validate={[required, email]}
              component={ListInput}
            />
            <Actions>
              <TransparentButton
                fontWeight="semi-bold"
                isLoading={isSubmitLoading}
                disabled={total !== 0}
                onClick={onSubmit}
              >
                Отправить письмо{' '}
                {total !== 0 &&
                  `еще раз через 
                ${formatTime(minutes)}:${formatTime(seconds)}`}
              </TransparentButton>
            </Actions>
          </Form>
        </LeftContent>
      </LeftContentWrapper>
    )
  }

  const renderRightSideContent = (): JSX.Element => {
    return (
      <RightContent>
        <ImageHolder>
          <Image src={handSrc} height="50vh" objectFit="contain" />
        </ImageHolder>
      </RightContent>
    )
  }

  return (
    <AuthLayout
      leftSideContent={renderLeftSideContent()}
      rightSideContent={renderRightSideContent()}
    />
  )
}

export default EmailConfirmationLostComponent
