import styled from 'styled-components'

export const Wrapper = styled('label')<{
  disabled?: boolean
  reverse?: boolean
}>`
  position: relative;
  display: flex;
  align-items: flex-start;
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'default')};

  input {
    &:disabled ~ .muk-radio-mark-wrapper {
      background-color: ${({ theme }) => theme.pallete.gray2} !important;
    }

    &:checked ~ .muk-radio-mark-wrapper {
      background-color: ${({ theme }) => theme.pallete.blue};

      .muk-radio-mark-wrapper-icon {
        opacity: 1;
      }
    }
  }

  .muk-radio-label {
    flex: 1;
    margin-right: 16px;
  }

  ${({ reverse }) =>
    reverse &&
    `
        flex-direction: row-reverse;

        .muk-radio-label {
          margin-left: 16px;
          margin-right: 0px;
        }
    `}
`

export const Label = styled.div`
  flex: 1;
`

export const Marker = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.pallete.gray3};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: relative;
  border-radius: 50%;
  pointer-events: none;

  .muk-radio-mark-wrapper-icon {
    width: 18px !important;
    height: 18px !important;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    svg {
      width: 100%;
      height: 100%;

      path {
        fill: ${({ theme }) => theme.pallete.white};
      }
    }
  }
`
