import { createApiReducer } from 'common/utils/reduxApiFactory'
import { getNotificationsBotActions } from 'api/park/notifications/telegram/bot/get/actions'
import { GetNotificationsBotState } from 'api/park/notifications/telegram/bot/get/types'

export const initialState: GetNotificationsBotState = {
  status: null,
  data: null,
}

export const getNotificationsBotReducer = createApiReducer(
  getNotificationsBotActions,
  initialState
)
