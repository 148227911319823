import React, { useState } from 'react'
import { Icon, ListGroup, ListLead } from 'ui-kit/components'
import { Employee } from 'api/types'
import ListItemComponent from 'common/components/listItem/ListItem'
import { formatPhone } from 'common/utils/formatters'
import styled from 'styled-components'

const FormerWrapper = styled.div`
  margin-top: 8px;

  .title {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &.minified {
    .muk-icon {
      transform: rotate(180deg);
    }
  }
`

const RowItem = styled.div<{ onClick?: () => void }>`
  display: flex;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  align-items: center;
  border-bottom: 0.5px solid ${({ theme }) => theme.pallete.gray3};

  .header-item {
    &:not(:first-child) {
      border-left-color: ${({ theme }) => theme.pallete.gray3};
    }
  }

  ${(props) =>
    !!props.onClick &&
    `
    &:hover {
      background-color: #EAEEF7;
    }
  `}
`

const Expanded = styled.div<{ expanded: boolean }>`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 336px;
  transition: max-height 0.25s ease-in;

  ${(props) =>
    !props.expanded &&
    `
    max-height: 0;
    transition: max-height 0.15s ease-out;
  `}
`

interface Props {
  employees: Employee[]
  onEmployeeClick?: (id: string) => void
}

export const FormerListing: React.FC<Props> = ({
  employees,
  onEmployeeClick,
}) => {
  const [expanded, setExpanded] = useState(true)
  return (
    <FormerWrapper className={expanded ? '' : 'minified'}>
      <ListGroup>
        <Icon
          className="title"
          name="expandMore"
          pointer
          reverse
          table
          onClick={() => setExpanded(!expanded)}
        >
          Бывшие сотрудники
        </Icon>
      </ListGroup>
      <Expanded expanded={expanded}>
        {employees.map((employee) => (
          <RowItem
            key={employee.id}
            onClick={
              onEmployeeClick ? () => onEmployeeClick(employee.id) : undefined
            }
          >
            <ListItemComponent flex="50%" border="table">
              <ListLead table subtitle={employee.position} reverse>
                {employee.full_name}
              </ListLead>
            </ListItemComponent>
            {employee.phone && (
              <ListItemComponent flex="50%" border="table">
                <ListLead table subtitle={employee.email} reverse>
                  {formatPhone(employee.phone)}
                </ListLead>
              </ListItemComponent>
            )}
          </RowItem>
        ))}
      </Expanded>
    </FormerWrapper>
  )
}
