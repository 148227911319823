import { createApiReducer } from 'common/utils/reduxApiFactory'
import { parkPaymentSettingsAllActions } from 'api/park/payment-settings/all/actions'
import { ParkPaymentSettingsAllState } from 'api/park/payment-settings/all/types'

export const initialState: ParkPaymentSettingsAllState = {
  status: null,
  data: null,
}

export const parkPaymentSettingsAllReducer = createApiReducer(
  parkPaymentSettingsAllActions,
  initialState
)
