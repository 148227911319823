import { createApiReducer } from 'common/utils/reduxApiFactory'
import { PostPayoutCredentialsState } from 'api/park/payout/credentials/post/types'
import { postPayoutCredentialsActions } from 'api/park/payout/credentials/post/actions'

export const initialState: PostPayoutCredentialsState = {
  status: null,
  data: null,
}

export const postPayoutCredentialsReducer = createApiReducer(
  postPayoutCredentialsActions,
  initialState
)
