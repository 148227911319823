import axios, { AxiosError } from 'axios'
import { BaseAction } from 'redux-actions'
import { ThunkAction } from 'redux-thunk'

import { State } from 'reducers'
import { PaymentSettings } from 'api/types'
import {
  failure,
  start,
  success,
} from 'api/park/payment-settings/patch/actions'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  BaseAction
>

export const HANDLE_REQUEST: BaseAction['type'] =
  'api/park/payment-settings/patch->HANDLE_REQUEST'

export const handleRequest = ({
  id,
  ...values
}: Partial<PaymentSettings>): THandleRequest => async (dispatch) => {
  dispatch(start())
  try {
    await axios.patch(`erp/1/park/payment-settings/${id}`, values)
    dispatch(success())
  } catch (e) {
    if (e.response) {
      const { response } = e as AxiosError
      dispatch(failure(response?.data))
    }
    throw e
  }
}
