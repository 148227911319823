import { createSelector } from 'reselect'
import { State } from 'reducers'
import { initialState } from 'api/park/payment-settings/post/reducer'

export const selectPostParkPaymentSettings = (state: State) =>
  state.api.park.paymentSettingsPost || initialState

export const makeSelectIsFetching = () =>
  createSelector(
    selectPostParkPaymentSettings,
    (state) => state.status === 'loading'
  )

export const makeSelectData = () =>
  createSelector(selectPostParkPaymentSettings, (state) => state.data)
