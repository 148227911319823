import React from 'react'

import { Driver } from 'common/types/api/models/driver'
import { LargeFilter } from 'common/types/local/filter'
import { Permission, SortDirection } from 'common/enums'

import { SortableColumns } from 'drivers/features/driversList/actions'

import { ContentCard } from 'shared/ui'
import {
  Heading,
  Listing,
  NoDrivers,
  Pagination,
} from 'drivers/features/driversList/molecules'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.paddings.main}px;
`

interface Props {
  permissions?: Permission[]
  drivers: Driver[]
  page: number
  search: string | null
  filterData: LargeFilter[]
  driversIsLoading: boolean
  totalPages: number
  searchLoading: boolean
  sortColumn: SortableColumns
  sortDirection: SortDirection
  filterList: string[]
  onRowClick: (driver: Driver) => void
  onSearch: (value: string) => void
  onChangePage: (direction: 'prev' | 'next') => void
  onApplyFilter: (filters: string[]) => void
  onClearFilter: () => void
  onChangeSort: (column: string, direction: SortDirection) => void
}

export const DriversList: React.FC<Props> = (props) => {
  const {
    onApplyFilter,
    onRowClick,
    onChangePage,
    page,
    totalPages,
    onSearch,
    onClearFilter,
    filterList,
    permissions,
    drivers,
    searchLoading,
    driversIsLoading,
    search,
    filterData,
    sortDirection,
    sortColumn,
    onChangeSort,
  } = props

  const onRowClickParam = permissions?.includes(Permission.ViewDriverDetails)
    ? onRowClick
    : undefined

  return (
    <Wrapper>
      <ContentCard>
        <Heading
          search={search}
          searchLoading={searchLoading}
          filterList={filterList}
          filterData={filterData}
          onSearch={onSearch}
          onApplyFilter={onApplyFilter}
          onClearFilter={onClearFilter}
        />
        <Listing
          onRowClick={onRowClickParam}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onChangeSort={onChangeSort}
          drivers={drivers}
          driversIsLoading={driversIsLoading}
        />
        {totalPages ? (
          <Pagination
            totalPages={totalPages}
            page={page}
            onChangePage={onChangePage}
          />
        ) : null}
        {!driversIsLoading && !totalPages ? <NoDrivers /> : null}
      </ContentCard>
    </Wrapper>
  )
}
