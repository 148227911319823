import { State } from 'reducers'
import { createSelector } from 'reselect'
import { apiSelectors } from 'api'
import { AggregatorType } from 'common/enums'

export const getCurrentId = (state: State) =>
  state.settings.aggregatorsList.current

export const getAddType = (state: State) => state.settings.aggregatorsList.type

export const selectInitialValues = createSelector(
  getAddType,
  getCurrentId,
  apiSelectors.park.aggregators.getAggregatorsList,
  apiSelectors.park.aggregatorByIdGet.getAggregator,
  (addType, currentId, aggregatorsList, fetchedAggregator) => {
    if (!fetchedAggregator) {
      return {}
    }

    const aggregator = currentId ? aggregatorsList[currentId] : null
    const aggregatorType = aggregator?.type
      ? aggregator.type
      : AggregatorType.Yandex
    const type = addType || aggregatorType
    const fetchedAggregatorByType = fetchedAggregator?.[type]

    if (!fetchedAggregatorByType) {
      return {}
    }

    return { ...fetchedAggregatorByType }
  }
)
