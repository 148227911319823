import { batch, connect } from 'react-redux'

import { State } from 'reducers'
import { apiSelectors } from 'api'
import { MyThunkDispatch } from 'api/reducer'
import { AggregatorType, ModalsNames } from 'common/enums'
import { modalsActions, modalsSelectors } from 'shared/modals'
import { setCurrent, setType } from 'settings/features/aggregatorsList/actions'
import { AggregatorsList } from 'settings/features/aggregatorsList/organisms'

const mapStateToProps = (state: State) => ({
  aggregators: apiSelectors.park.aggregators.getAggregators(state),
  isAddAggregatorModalOpened: modalsSelectors.getIsModalOpened(
    state,
    ModalsNames.AddAggregator
  ),
  isDeleteAggregatorModalOpened: modalsSelectors.getIsModalOpened(
    state,
    ModalsNames.DeleteAggregator
  ),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  openAddAggregatorModal: (type: AggregatorType) =>
    batch(() => {
      dispatch(setType(type))
      dispatch(modalsActions.openModal(ModalsNames.AddAggregator))
    }),
  openAddAggregatorModalToEdit: (id: string) =>
    batch(() => {
      dispatch(setCurrent(id))
      dispatch(modalsActions.openModal(ModalsNames.AddAggregator))
    }),
  openDeleteAggregatorModal: (id: string) =>
    batch(() => {
      dispatch(setCurrent(id))
      dispatch(modalsActions.openModal(ModalsNames.DeleteAggregator))
    }),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const AggregatorsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AggregatorsList)
