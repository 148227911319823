import styled from 'styled-components'

import sheeldSrc from 'static/icons/sheeld.svg'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 563px;
  width: 100%;

  .content-card {
    margin-top: 32px;
  }

  .download {
    cursor: pointer;

    p {
      font-weight: 600;
    }
  }
`

export const Link = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.pallete.blue};
  font-weight: 500;
`
export const DownloadLink = styled.a`
  text-decoration: none;
  display: block;
`

export const CardContent = styled.div`
  padding: 16px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 96px;
`

export const CardLeftSide = styled.div`
  display: flex;
  align-items: center;
`

export const CardLeftSideIcon = styled.div`
  margin-right: 8px;
  width: 40px;
  height: 40px;
  background-image: url(${CSS.escape(sheeldSrc)});
  background-position: center;
`

export const CardLeftSideText = styled.div`
  display: flex;
  flex-direction: column;
`

export const CardLeftSideTextTop = styled.div`
  min-height: 40px;
  display: flex;
  align-items: center;
  padding: 0 16px;
`

export const CardLeftSideTextBottom = styled.div`
  min-height: 24px;
  display: flex;
  align-items: center;
  padding: 0 16px;
`

export const CardRightSide = styled.div`
  display: flex;
  align-items: center;
`
