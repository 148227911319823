import { connect } from 'react-redux'

import { State } from 'reducers'
import { apiActions, apiSelectors } from 'api'
import { StepPayments } from 'steps/features/stepPayments/organisms'
import { MyThunkDispatch } from 'api/reducer'

const mapStateToProps = (state: State) => ({
  bankType: apiSelectors.park.details.getBankType(state),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  fetchParkDetails: () => dispatch(apiActions.park.details.handleRequest()),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const StepPaymentsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StepPayments)
