import { ThunkAction } from 'redux-thunk'
import { State } from 'reducers'
import axios from 'axios'
import { FailureError } from 'common/utils/reduxApiFactory'
import { TApiError } from 'api/types'
import { BankType, SubscriptionStatus } from 'common/enums'
import { promoActions as actions } from './actions'
import { PromoActions, PromoResponseBodySchema } from './types'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  PromoActions
>

interface Options {
  bankType?: BankType
  parkId?: string
  subscriptionStatus?: SubscriptionStatus
}

const isProd = (uri: string) => /mozen.io/.test(uri)

export const promoRequest = ({
  bankType,
  parkId,
  subscriptionStatus,
}: Options = {}): THandleRequest => async (dispatch) => {
  dispatch(actions.start())
  try {
    const response = await axios.get<PromoResponseBodySchema>(
      `/tracker/erp?bank_type=${bankType}&park_id=${parkId}&subscription_status=${subscriptionStatus}`,
      {
        baseURL: isProd(window?.location?.host)
          ? 'https://park.mozen.io/'
          : 'https://mozen.tech/',
      }
    )
    dispatch(actions.update(response.data))
    dispatch(actions.success())
  } catch (e) {
    if (e.response) {
      const data = e.response.data as TApiError
      const error: FailureError = new Error(data.message)
      error.data = data
      dispatch(actions.failure(error))
      throw error
    }
  }
}
