import { connect } from 'react-redux'
import { State } from 'reducers'
import { apiSelectors } from 'api'
import { modalsActions, modalsSelectors } from 'shared/modals'
import { ModalsNames } from 'common/enums'
import { DriverInfo } from 'drivers/features/driverInfo/organisms'
import { MyThunkDispatch } from 'api/reducer'

const mapStateToProps = (state: State) => ({
  info: apiSelectors.driversById.get.getInfo(state),
  contract: apiSelectors.driversById.get.getContractPreview(state),
  hasUserId: apiSelectors.driversById.get.getHasUserId(state),
  isSecurityCheckPassed: apiSelectors.driversById.get.getSecurityCheckPassed(
    state
  ),
  balance: apiSelectors.driversById.get.getBalanceParkAmount(state),
  bonus: apiSelectors.driversById.get.getBalanceBonusAmount(state),
  isAddDriverContractModalOpened: modalsSelectors.getIsModalOpened(
    state,
    ModalsNames.AddDriverContract
  ),
  isDeleteDriverContractModalOpened: modalsSelectors.getIsModalOpened(
    state,
    ModalsNames.DeleteDriverContract
  ),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  openAddDriverContractModal: () =>
    dispatch(modalsActions.openModal(ModalsNames.AddDriverContract)),
  openDeleteDriverContractModal: () =>
    dispatch(modalsActions.openModal(ModalsNames.DeleteDriverContract)),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const DriverInfoContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DriverInfo)
