import React, { useState } from 'react'
import { Loader } from 'ui-kit/atoms/loader'
import { Modal } from 'shared/ui/modal'
import OfdConnectForm from 'settings/features/ofdTab/container/OfdConnectForm'
import OfdEditForm from 'settings/features/ofdTab/container/OfdEditForm'
import { OfdConnector } from '../container/OfdConnector'
import { OfdLanding } from './OfdLanding'
import { OfdData } from './OfdData'

enum ModalType {
  Add,
  Edit,
}

export const OfdTab = () => {
  const [modal, setModal] = useState<ModalType>()
  const handleClickConnectOfd = () => {
    setModal(ModalType.Add)
  }
  const handleClickEditOfd = () => {
    setModal(ModalType.Edit)
  }
  const handleCloseModal = () => {
    setModal(undefined)
  }
  return (
    <OfdConnector>
      {({ data, landing, snoList, block, edit, add }) => {
        if (!snoList) {
          return <Loader />
        }

        if (!data && !landing) {
          return <Loader />
        }

        const renderContent = () => {
          if (data) {
            return (
              <OfdData
                data={data}
                onBlock={block}
                onEdit={handleClickEditOfd}
              />
            )
          }

          if (landing) {
            return (
              <OfdLanding
                content={landing.data}
                onRegistration={landing.sendRegistrationRequest}
                onConnect={handleClickConnectOfd}
              />
            )
          }
        }

        return (
          <>
            {renderContent()}
            <Modal
              title="Подключение онлайн-кассы"
              open={modal === ModalType.Add}
              onClose={handleCloseModal}
              closeButton
            >
              <OfdConnectForm
                onSubmit={add}
                taxOptions={snoList}
                onClose={handleCloseModal}
              />
            </Modal>
            <Modal
              title="Редактирование онлайн-кассы"
              open={modal === ModalType.Edit}
              onClose={handleCloseModal}
              closeButton
            >
              <OfdEditForm
                initialValues={{
                  ...data,
                  sno_id: snoList.find((item) => item.name === data?.snoName)
                    ?.code,
                }}
                onSubmit={edit}
                taxOptions={snoList}
                onClose={handleCloseModal}
              />
            </Modal>
          </>
        )
      }}
    </OfdConnector>
  )
}
