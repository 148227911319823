import React from 'react'
import styled from 'styled-components'
import { BodyText, TransparentButton } from 'ui-kit/components'

type Props = {
  parkCommission: number
  driverCommission: number
  onClick: () => void
}
const Wrapper = styled.div`
  margin-right: 8px;
`
const Action = styled.div`
  margin-top: 8px;
`

export const FuelBonusInfo = ({
  parkCommission,
  driverCommission,
  onClick,
}: Props) => {
  return (
    <Wrapper>
      <BodyText>{parkCommission}% с каждой заправки вам,</BodyText>
      <BodyText>{driverCommission}% водителю</BodyText>
      <Action>
        <TransparentButton fontWeight="semi-bold" onClick={onClick}>
          Изменить условия
        </TransparentButton>
      </Action>
    </Wrapper>
  )
}
export default React.memo(FuelBonusInfo)
