import React from 'react'
import { Field, InjectedFormProps } from 'redux-form'
import { CaptionText, SubBodyText, TransparentButton } from 'ui-kit/components'
import { BankType } from 'common/enums'
import { currencyToBase } from 'common/utils/formatters'
import Switch from 'common/components/formItems/Switch'
import { ContentCard, ContentCardBody, HBox, Row } from 'shared/ui'
import { AddNotificationsQiwiSettingsModalContainer } from 'settings/features/notificationsSettings/containers'
import {
  ContainerProps,
  FormProps,
} from 'settings/features/notificationsSettings/containers/NotificationsSettingsContainer'

export const NotificationsSettings: React.FC<
  InjectedFormProps<FormProps, ContainerProps> & ContainerProps
> = ({
  change,
  notificationsSettings,
  bankType,
  isAddNotificationsQiwiSettingsModalOpened,
  isFuelEnabled,
  onSave,
  openAddNotificationsQiwiSettingsModal,
}) => {
  const qiwiSettingsMinBalance = notificationsSettings?.qiwi_settings
    ? notificationsSettings.qiwi_settings.min_balance
    : null

  const handleSaveTransactionsNotify = async (
    _: any,
    newValue: boolean,
    prevValue: boolean
  ) => {
    const badRequest = await onSave({ transactions_notify: newValue })
    if (badRequest) {
      change('transactions_notify', prevValue)
    }
  }

  const handleSaveInsufficientFuelDeposit = async (
    _: any,
    newValue: boolean,
    prevValue: boolean
  ) => {
    const badRequest = await onSave({ insufficient_fuel_deposit: newValue })
    if (badRequest) {
      change('insufficient_fuel_deposit', prevValue)
    }
  }

  const handleSaveSuccessfulPaymentNotify = async (
    _: any,
    newValue: boolean,
    prevValue: boolean
  ) => {
    const badRequest = await onSave({ successful_payment_notify: newValue })
    if (badRequest) {
      change('successful_payment_notify', prevValue)
    }
  }

  const handleSaveFailedPaymentNotify = async (
    _: any,
    newValue: boolean,
    prevValue: boolean
  ) => {
    const badRequest = await onSave({ failed_payment_notify: newValue })
    if (badRequest) {
      change('failed_payment_notify', prevValue)
    }
  }

  const handleSaveEmailNotifications = async (
    _: any,
    newValue: boolean,
    prevValue: boolean
  ) => {
    const badRequest = await onSave({ email_notifications: newValue })
    if (badRequest) {
      change('email_notifications', prevValue)
    }
  }
  const handleSaveCertificateNotify = async (
    _: any,
    newValue: boolean,
    prevValue: boolean
  ) => {
    const badRequest = await onSave({ certificate_notify: newValue })
    if (badRequest) {
      change('certificate_notify', prevValue)
    }
  }
  return (
    <>
      <ContentCard>
        <ContentCardBody>
          <Field
            name="transactions_notify"
            onChange={handleSaveTransactionsNotify}
            component={Switch}
          >
            <SubBodyText>Есть платежи к обработке</SubBodyText>
            <HBox x={0.25} />
            <CaptionText color="gray6">
              Раз в час пишем о количестве платежей, которые ждут подтверждения
              или вернулись из банка с отказом.
            </CaptionText>
          </Field>
          <HBox />

          {isFuelEnabled && (
            <>
              <Field
                name="insufficient_fuel_deposit"
                onChange={handleSaveInsufficientFuelDeposit}
                component={Switch}
              >
                <SubBodyText>Низкий баланс на топливном депозите</SubBodyText>
                <HBox x={0.25} />
                <CaptionText color="gray6">
                  Предупредим, если средств на топливном депозите недостаточно.
                </CaptionText>
              </Field>
              <HBox />
            </>
          )}

          {bankType === BankType.Qiwi && (
            <>
              <Field
                name="email_notifications"
                onChange={handleSaveEmailNotifications}
                component={Switch}
              >
                <SubBodyText>
                  На QIWI меньше{' '}
                  {qiwiSettingsMinBalance
                    ? currencyToBase(qiwiSettingsMinBalance, true, 0)
                    : 'лимита'}
                </SubBodyText>
                <HBox x={0.25} />
                <CaptionText color="gray6">
                  Предупредим, если баланс счета QIWI опускается ниже{' '}
                  {qiwiSettingsMinBalance
                    ? currencyToBase(qiwiSettingsMinBalance, true, 0)
                    : 'лимита'}
                  .
                </CaptionText>
              </Field>
              <HBox x={0.25} />
              <Row>
                <TransparentButton
                  onClick={openAddNotificationsQiwiSettingsModal}
                >
                  Изменить лимит
                </TransparentButton>
              </Row>
            </>
          )}
          {bankType === BankType.Alfa && (
            <Field
              name="certificate_notify"
              onChange={handleSaveCertificateNotify}
              component={Switch}
            >
              <SubBodyText>Истекает сертификат безопасности</SubBodyText>
              <HBox x={0.25} />
              <CaptionText color="gray6">
                За 2 недели до конца действия сертификата безопасности напомним
                о замене.
              </CaptionText>
            </Field>
          )}
        </ContentCardBody>
      </ContentCard>

      <HBox />

      <ContentCard>
        <ContentCardBody>
          <Field
            name="successful_payment_notify"
            onChange={handleSaveSuccessfulPaymentNotify}
            component={Switch}
          >
            <SubBodyText>Успешная оплата подписки</SubBodyText>
            <HBox x={0.25} />
            <CaptionText color="gray6">
              Оповестим, когда оплата сервиса прошла успешно.
            </CaptionText>
          </Field>
          <HBox />

          <Field
            name="failed_payment_notify"
            onChange={handleSaveFailedPaymentNotify}
            component={Switch}
          >
            <SubBodyText>Проблемы с оплатой подписки</SubBodyText>
            <HBox x={0.25} />
            <CaptionText color="gray6">
              Оповестим, когда не хватило средств на оплату сервиса.
            </CaptionText>
          </Field>
        </ContentCardBody>
      </ContentCard>

      {bankType === BankType.Qiwi &&
      isAddNotificationsQiwiSettingsModalOpened ? (
        <AddNotificationsQiwiSettingsModalContainer />
      ) : null}
    </>
  )
}
