import React from 'react'
import { ColorButton } from 'ui-kit/components'
import styled from 'styled-components'
import Typography from 'ui-kit/typography'

type Props = {
  children: React.ReactNode
  onRetry: () => Promise<void>
  onSuccess: () => void
}

const Wrapper = styled.div`
  padding-top: 136px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const HelpText = styled(Typography).attrs({ variant: 'Body1' })`
  text-align: center;
  margin-bottom: 24px;
`
const Description = styled(Typography).attrs({ variant: 'Body3' })`
  margin-bottom: 8px;
`

export const RetryRequest = ({ onSuccess, onRetry, children }: Props) => {
  const [isFetching, setIsFetching] = React.useState(false)

  const handleRetry = React.useCallback(async () => {
    setIsFetching(true)
    try {
      await onRetry()
      setIsFetching(false)
      onSuccess()
    } catch (e) {
      setIsFetching(false)
    }
  }, [onRetry, onSuccess])

  return (
    <Wrapper>
      <Description>{children}</Description>
      <HelpText>
        Мы уже работаем над исправлением. <br />
        Попробуйте обновить через пару минут.
      </HelpText>
      <ColorButton isLoading={isFetching} onClick={handleRetry}>
        Обновить
      </ColorButton>
    </Wrapper>
  )
}
export default React.memo<Props>(RetryRequest)
