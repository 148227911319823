import React from 'react'
import { Icon, TableText } from 'ui-kit/components'
import { ColorTypes, IconNames } from 'ui-kit'

import { BalanceLogTransaction } from 'api/types'
import { currencyApart, getWidthInPercent } from 'common/utils/formatters'
import { formatToRegularDate, toTime } from 'common/utils/dates'
import { TariffTransactionType } from 'common/enums'
import { Column, StatusCell, Table } from 'shared/table'
import { Row, VBox } from 'shared/ui'
import styled from 'styled-components'

const NoWrap = styled.div`
  white-space: nowrap;
`
const ScaleText = styled.span`
  font-size: 80%;
`

interface Props {
  isFetching: boolean
  transactions: BalanceLogTransaction[]
}

export const Listing: React.FC<Props> = ({ isFetching, transactions }) => {
  const blockWidth = 1078

  const formatDateAndTime = (item: BalanceLogTransaction): JSX.Element => {
    const timeColor: ColorTypes = 'gray8'
    return (
      <TableText>
        {formatToRegularDate(item.registered_at)}
        <TableText color={timeColor}>{toTime(item.registered_at)}</TableText>
      </TableText>
    )
  }

  const formatTitle = (item: BalanceLogTransaction): JSX.Element => {
    const color: ColorTypes =
      item.operation_type === TariffTransactionType.TopUp ? 'green' : 'red'
    const name: IconNames =
      item.operation_type === TariffTransactionType.TopUp
        ? 'arrowUpward'
        : 'arrowDownward'
    return (
      <TableText>
        <Row>
          <Icon name={name} color={color} pointer={false} />
          <VBox x={0.5} />
          {item.title}
        </Row>
      </TableText>
    )
  }
  const formatComment = (item: BalanceLogTransaction): JSX.Element => {
    const color = item.comment ? undefined : 'gray8'
    return <TableText color={color}>{item.comment || '–'}</TableText>
  }

  const formatAmount = (item: BalanceLogTransaction): JSX.Element => {
    const color = item.transaction_amount.amount >= 0 ? undefined : 'gray8'
    const value: string[] = currencyApart(
      item.transaction_amount.amount,
      true,
      false
    )

    return (
      <TableText color={color}>
        <NoWrap>
          {value[1]}
          <ScaleText>{value[2]}</ScaleText>
        </NoWrap>
      </TableText>
    )
  }

  const formatBalance = (item: BalanceLogTransaction): JSX.Element => {
    const color = item.account_balance.amount >= 0 ? undefined : 'gray8'
    const value: string[] = currencyApart(
      item.account_balance.amount,
      false,
      false
    )

    return (
      <TableText color={color}>
        <NoWrap>
          {value[1]}
          <ScaleText>{value[2]}</ScaleText>
        </NoWrap>
      </TableText>
    )
  }

  const formatStatus = (item: BalanceLogTransaction): JSX.Element => (
    <StatusCell status={item.status} />
  )

  return (
    <Table isLoading={isFetching} source={transactions} hideHeaderOnEmpty>
      <Column
        data={formatDateAndTime}
        flex={`${getWidthInPercent(blockWidth, 101)}%`}
      >
        Дата
      </Column>

      <Column
        data={formatTitle}
        flex={`${getWidthInPercent(blockWidth, 246)}%`}
      >
        Основание
      </Column>

      <Column
        data={formatComment}
        flex={`${getWidthInPercent(blockWidth, 310)}%`}
      >
        Комментарий
      </Column>

      <Column
        data={formatAmount}
        flex={`${getWidthInPercent(blockWidth, 124)}%`}
      >
        Сумма, ₽
      </Column>

      <Column
        data={formatBalance}
        flex={`${getWidthInPercent(blockWidth, 124)}%`}
      >
        Остаток, ₽
      </Column>

      <Column
        data={formatStatus}
        flex={`${getWidthInPercent(blockWidth, 174)}%`}
      >
        Статус
      </Column>
    </Table>
  )
}
