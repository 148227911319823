import React from 'react'

import { HBox } from 'shared/ui'
import styled from 'styled-components'

export const Wrapper = styled.header`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

interface Props {
  title?: string
  toolbar?: React.ReactNode
}

export const ContentHeader: React.FC<Props> = ({ title, toolbar }) => (
  <Wrapper>
    {title ? <HBox /> : null}
    {toolbar || null}
  </Wrapper>
)
