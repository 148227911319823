import React, { useState } from 'react'
import styled from 'styled-components'

import { IconNames } from 'ui-kit'
import { SmallLoader, Icon, BodyText } from 'ui-kit/components'

import { Base, DefaultProps } from 'ui-kit/components/Buttons/Base/Base'

const Container = styled(Base)<{
  hovered?: boolean
  isLoading?: boolean
}>`
  background-color: ${({ hovered, isLoading, theme }) =>
    (hovered || isLoading) && theme.pallete.blue};
`

const VSpace = styled.div`
  width: 16px;
`

interface Props extends DefaultProps {
  iconName: IconNames
  isLoading?: boolean
}

export const IconTextButton: React.FC<Props> = ({
  className,
  children,
  iconName,
  disabled,
  isLoading,
  onClick,
}) => {
  const [isHovered, setIsHovered] = useState(false)

  const getColor = () => {
    if (disabled) {
      return 'gray6'
    }
    if (isLoading || isHovered) {
      return 'white'
    }

    return 'blue'
  }

  return (
    <Container
      className={className}
      isLoading={isLoading}
      disabled={disabled || isLoading}
      onClick={onClick}
      hovered={isHovered}
      onMouseEnter={disabled ? undefined : () => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isLoading ? (
        <SmallLoader white />
      ) : (
        <>
          <Icon name={iconName} color={getColor()} />
          {iconName && children ? <VSpace /> : null}
          {children ? <BodyText color={getColor()}>{children}</BodyText> : null}
        </>
      )}
    </Container>
  )
}
