import {
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiButtonEmpty,
  EuiButton,
} from '@elastic/eui'
import { ButtonContentIconSide } from '@elastic/eui/src/components/button/button_content'
import React from 'react'

interface Props {
  isOpen: boolean
  title?: string
  cancelLabel?: string
  acceptLabel?: string
  cancelColor?: 'danger' | 'text' | 'ghost' | 'primary' | 'disabled'
  acceptColor?:
    | 'danger'
    | 'text'
    | 'ghost'
    | 'primary'
    | 'secondary'
    | 'warning'
  maxWidth?: number
  cancelIcon?: string
  cancelIconSide?: ButtonContentIconSide
  acceptIcon?: string
  acceptIconSide?: ButtonContentIconSide
  isCancelDisabled?: boolean
  isAcceptDisabled?: boolean
  isCancelLoading?: boolean
  isAcceptLoading?: boolean
  onClose: () => void
  onCancel?: () => void
  onAccept?: () => void
  onClickAway?: () => void
}

export const Modal: React.FC<Props> = ({
  isOpen,
  onClose,
  onCancel,
  onAccept,
  onClickAway,
  cancelIcon,
  cancelIconSide,
  acceptIcon,
  acceptIconSide,
  title,
  cancelLabel,
  acceptLabel,
  cancelColor,
  acceptColor,
  isAcceptDisabled,
  isCancelDisabled,
  isAcceptLoading,
  isCancelLoading,
  children,
  maxWidth = 448,
}) => (
  <>
    {isOpen ? (
      <EuiOverlayMask onClick={onClickAway}>
        <EuiModal onClose={onClose} maxWidth={maxWidth}>
          {title ? (
            <EuiModalHeader>
              <EuiModalHeaderTitle>{title}</EuiModalHeaderTitle>
            </EuiModalHeader>
          ) : null}

          <EuiModalBody>{children}</EuiModalBody>

          {cancelLabel || acceptLabel ? (
            <EuiModalFooter>
              {cancelLabel ? (
                <EuiButtonEmpty
                  onClick={onCancel}
                  iconType={cancelIcon}
                  iconSide={cancelIconSide}
                  color={cancelColor}
                  isDisabled={isCancelDisabled}
                  isLoading={isCancelLoading}
                >
                  {cancelLabel}
                </EuiButtonEmpty>
              ) : null}

              {acceptLabel ? (
                <EuiButton
                  onClick={onAccept}
                  fill
                  iconType={acceptIcon}
                  iconSide={acceptIconSide}
                  color={acceptColor}
                  type="button"
                  isDisabled={isAcceptDisabled}
                  isLoading={isAcceptLoading}
                >
                  {acceptLabel}
                </EuiButton>
              ) : null}
            </EuiModalFooter>
          ) : null}
        </EuiModal>
      </EuiOverlayMask>
    ) : null}
  </>
)
