import styled from 'styled-components'

export const Wrapper = styled.div<{ withLockOverlay?: boolean }>`
  min-width: 55%;
  ${({ withLockOverlay }) =>
    withLockOverlay &&
    `
      opacity: 0.4;
    `}
`
