import { handleActions } from 'redux-actions'

import { SuspectTransaction } from 'common/types/api/models/antifraud'

import {
  Payloads,
  SET_TRANSACTIONS,
  SET_TRANSACTIONS_DATE_FROM,
  SET_TRANSACTIONS_DATE_TO,
  SET_TRANSACTIONS_PAGE,
  SET_TRANSACTIONS_SEARCH,
} from 'antifraud/blocked/actions'

export interface AntiFraudBlockedState {
  transactions: SuspectTransaction[]
  searchValue: string
  page: number
  total: number
  dateFrom: Date | null
  dateTo: Date | null
  checkedRecords: Set<string>
}

const initialState: AntiFraudBlockedState = {
  transactions: [],
  searchValue: '',
  page: 1,
  total: 0,
  dateFrom: null,
  dateTo: null,
  checkedRecords: new Set(),
}

const handleSetTransactions = (
  state: AntiFraudBlockedState,
  action: SET_TRANSACTIONS
): AntiFraudBlockedState => ({
  ...state,
  transactions: action.payload.transactions,
  total: action.payload.total_count,
})

const handleSetTransactionsSearch = (
  state: AntiFraudBlockedState,
  action: SET_TRANSACTIONS_SEARCH
): AntiFraudBlockedState => ({
  ...state,
  searchValue: action.payload,
})

const handleSetTransactionsPage = (
  state: AntiFraudBlockedState,
  action: SET_TRANSACTIONS_PAGE
): AntiFraudBlockedState => ({
  ...state,
  page: action.payload,
})

const handleSetTransactionsDateFrom = (
  state: AntiFraudBlockedState,
  action: SET_TRANSACTIONS_DATE_FROM
): AntiFraudBlockedState => ({
  ...state,
  dateFrom: action.payload,
})

const handleSetTransactionsDateTo = (
  state: AntiFraudBlockedState,
  action: SET_TRANSACTIONS_DATE_TO
): AntiFraudBlockedState => ({
  ...state,
  dateTo: action.payload,
})

export default handleActions<AntiFraudBlockedState, Payloads>(
  {
    [SET_TRANSACTIONS]: handleSetTransactions,
    [SET_TRANSACTIONS_SEARCH]: handleSetTransactionsSearch,
    [SET_TRANSACTIONS_PAGE]: handleSetTransactionsPage,
    [SET_TRANSACTIONS_DATE_FROM]: handleSetTransactionsDateFrom,
    [SET_TRANSACTIONS_DATE_TO]: handleSetTransactionsDateTo,
  },
  initialState
)
