import React from 'react'
import styled from 'styled-components'

import {
  ContentCard,
  ContentCardHeader,
  ContentCardBody,
  HBox,
  VBox,
} from 'shared/ui'
import { SubBodyText } from 'ui-kit/components'
import { DragAndDropInput } from 'ui-kit/molecules'
import Typography from 'ui-kit/typography'
import { ContainerProps } from 'drivers/features/driversUploadContracts/containers/UploadCardContainer'
import { Link } from 'ui-kit/atoms'

const TextWrapper = styled.div`
  padding: 16px 24px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Placeholder = styled(SubBodyText)`
  display: flex;
`

export const UploadCard: React.FC<ContainerProps> = ({
  onUpload,
  onUploadSuccess,
}) => {
  const handleUpload = async (file: string) => {
    const success = await onUpload(file)
    if (success) onUploadSuccess()
  }

  return (
    <ContentCard>
      <ContentCardHeader title="Заполните и загрузите" />
      <ContentCardBody>
        <Typography>
          Заполните недостающие данные. Если возникают вопросы, воспользуйтесь{' '}
          <Link
            href="https://help.mozen.io/zaghruzka-doghovorov/"
            target="_blank"
            rel="noopener noreferrer"
          >
            руководством по заполнению
          </Link>
          .
        </Typography>
        <HBox x={0.5} />

        <DragAndDropInput
          acceptOnly={[
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          ]}
          errorMessage="Файл не читается. Загрузите отредактированный XLSX файл."
          onChange={handleUpload}
          readAsArrayBuffer
        >
          {({ isDragAccept, isDragReject, isHover }) => {
            if (isDragReject) {
              return (
                <TextWrapper>
                  <SubBodyText color="textPrimary">
                    Файл не правильного формата.
                  </SubBodyText>
                </TextWrapper>
              )
            }
            if (isDragAccept) {
              return (
                <TextWrapper>
                  <SubBodyText color="textPrimary">
                    Отпустите файл, чтобы начать загрузку
                  </SubBodyText>
                </TextWrapper>
              )
            }
            return (
              <TextWrapper>
                <Placeholder color={isHover ? 'textSecondary' : 'textPrimary'}>
                  <SubBodyText color="accentPrimary" fontWeight="semi-bold">
                    Выберите
                  </SubBodyText>
                  <VBox x={1 / 6} />
                  или перетащите XLSX файл
                </Placeholder>
              </TextWrapper>
            )
          }}
        </DragAndDropInput>
      </ContentCardBody>
    </ContentCard>
  )
}
