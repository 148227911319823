import { createApiReducer } from 'common/utils/reduxApiFactory'
import { PromoState } from 'api/promo/types'
import { promoActions } from 'api/promo/actions'

export const initialState: PromoState = {
  status: null,
  data: null,
}

export const promoReducer = createApiReducer(promoActions, initialState)
