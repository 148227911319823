import React from 'react'
import { history } from 'store'
import { Icon, SubBodyText } from 'ui-kit/components'
import { RoutesPath, SubscriptionStatus } from 'common/enums'
import { HBox, InlineButton, Row, VBox } from 'shared/ui'
import styled from 'styled-components'

const Container = styled.div`
  padding: 0 ${({ theme }) => theme.paddings.main}px;
  width: 100%;
  background-color: ${({ theme }) => theme.pallete.white};
  box-shadow: 0 3px 10px rgba(192, 206, 226, 0.5),
    0 0 4px rgba(192, 206, 226, 0.2);
  z-index: 98;
`

const Inner = styled.div`
  display: flex;
  min-height: 70px;
`
const Empty = styled.div`
  min-height: 70px;
`

const MailToSupport = styled.a``

type Props = {
  status: SubscriptionStatus | null
  openDepositPayModal: () => void
}

export const SubscriptionNotice: React.FC<Props> = ({
  openDepositPayModal,
  status,
}) => {
  const handleClick = () => {
    history.push(RoutesPath.SettingsTariff)
    openDepositPayModal()
  }
  return (
    <>
      <Container>
        <Inner>
          {status === SubscriptionStatus.Unpaid && (
            <Row>
              <Icon name="warning2" />
              <VBox x={0.75} />
              <SubBodyText>
                На вашем депозите недостаточно средств для оплаты подписки.
                Пополните депозит, чтобы продолжить пользоваться системой.
                <HBox x={0} />
                <InlineButton onClick={handleClick}>
                  Пополнить сейчас
                </InlineButton>{' '}
                или{' '}
                <MailToSupport href="mailto: support@mozen.ru">
                  связаться с менеджером
                </MailToSupport>
              </SubBodyText>
            </Row>
          )}
          {status === SubscriptionStatus.Blocked && (
            <Row>
              <Icon name="warning2" />
              <VBox x={0.75} />
              <SubBodyText>
                Ваша подписка заблокирована. Если вы считаете, что аккаунт
                заблокирован по ошибке,{' '}
                <MailToSupport href="mailto: support@mozen.ru">
                  свяжитесь с менеджером
                </MailToSupport>
              </SubBodyText>
            </Row>
          )}
        </Inner>
      </Container>
      <Empty />
    </>
  )
}
