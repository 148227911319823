import { combineReducers } from 'redux'

import employeesByIdGetActions, {
  TEmployeesByIdGetAction,
} from 'api/employees/employeesById/get/actions'
import {
  EmployeesByIdGetState,
  reducer as employeesByIdGet,
} from 'api/employees/employeesById/get/reducer'
import * as employeesByIdGetSelectors from 'api/employees/employeesById/get/selectors'
import { handleRequest as employeesByIdGetRequest } from 'api/employees/employeesById/get/thunks'

import { TEmployeesByIdPatchAction } from 'api/employees/employeesById/patch/actions'
import {
  EmployeesByIdPatchState,
  reducer as employeesByIdPatch,
} from 'api/employees/employeesById/patch/reducer'
import * as employeesByIdPatchSelectors from 'api/employees/employeesById/patch/selectors'
import { handleRequest as employeesByIdPatchRequest } from 'api/employees/employeesById/patch/thunks'

import { TEmployeesByIdStatusAction } from 'api/employees/employeesById/status/actions'
import {
  EmployeesByIdStatusState,
  reducer as employeesByIdStatus,
} from 'api/employees/employeesById/status/reducer'
import * as employeesByIdStatusSelectors from 'api/employees/employeesById/status/selectors'
import { handleRequest as employeesByIdStatusRequest } from 'api/employees/employeesById/status/thunks'

import { TEmployeesGetAction } from 'api/employees/get/actions'
import { EmployeesGetState, reducer as get } from 'api/employees/get/reducer'
import * as getSelectors from 'api/employees/get/selectors'
import { handleRequest as getRequest } from 'api/employees/get/thunks'

import { TEmployeesLogsAction } from 'api/employees/logs/actions'
import { EmployeesLogsState, reducer as logs } from 'api/employees/logs/reducer'
import * as logsSelectors from 'api/employees/logs/selectors'
import { handleRequest as logsRequest } from 'api/employees/logs/thunks'

import { TEmployeesPostAction } from 'api/employees/post/actions'
import { EmployeesPostState, reducer as post } from 'api/employees/post/reducer'
import * as postSelectors from 'api/employees/post/selectors'
import { handleRequest as postRequest } from 'api/employees/post/thunks'

export interface EmployeesState {
  employeesByIdGet: EmployeesByIdGetState
  employeesByIdPatch: EmployeesByIdPatchState
  employeesByIdStatus: EmployeesByIdStatusState
  get: EmployeesGetState
  logs: EmployeesLogsState
  post: EmployeesPostState
}

export type TEmployeesAction =
  | TEmployeesByIdGetAction
  | TEmployeesByIdPatchAction
  | TEmployeesByIdStatusAction
  | TEmployeesGetAction
  | TEmployeesLogsAction
  | TEmployeesPostAction

export const employeesReducer = combineReducers({
  employeesByIdGet,
  employeesByIdPatch,
  employeesByIdStatus,
  get,
  logs,
  post,
})

export const employeesActions = {
  employeesByIdGet: {
    handleRequest: employeesByIdGetRequest,
    reset: employeesByIdGetActions.reset,
  },
  employeesByIdPatch: {
    handleRequest: employeesByIdPatchRequest,
  },
  employeesByIdStatus: {
    handleRequest: employeesByIdStatusRequest,
  },
  get: {
    handleRequest: getRequest,
  },
  logs: {
    handleRequest: logsRequest,
  },
  post: {
    handleRequest: postRequest,
  },
}

export const employeesSelectors = {
  employeesByIdGet: employeesByIdGetSelectors,
  employeesByIdPatch: employeesByIdPatchSelectors,
  employeesByIdStatus: employeesByIdStatusSelectors,
  get: getSelectors,
  logs: logsSelectors,
  post: postSelectors,
}
