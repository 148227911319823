import { Action } from 'redux-actions'

export type SET_LOADING = Action<boolean>
export const SET_LOADING: SET_LOADING['type'] = 'signIn/SET_LOADING'
export const setLoading = (fetching: boolean): SET_LOADING => ({
  type: SET_LOADING,
  payload: fetching,
})

export type Payloads = Pick<SET_LOADING, 'payload'>['payload']
