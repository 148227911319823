import React from 'react'
import { Redirect } from 'react-router-dom'

import { Permission, RoutesPath } from 'common/enums'

interface Props {
  permissions?: Permission[]
}

const DashboardComponent: React.FC<Props> = ({ permissions }) => {
  const pathname =
    permissions &&
    permissions.includes(Permission.ViewTransactionInfo) &&
    permissions.includes(Permission.ViewAllTransactions)
      ? RoutesPath.Analytics
      : RoutesPath.Drivers

  return <Redirect to={{ pathname }} />
}

export default DashboardComponent
