import { createSelector } from 'reselect'
import { State } from 'reducers'

export const getIsFetching = (state: State) =>
  state.api.park.technicalSupportContactsGet.status === 'loading'

const getData = (state: State) =>
  state.api.park.technicalSupportContactsGet.data

export const getContacts = createSelector(getData, (data) => data || undefined)
