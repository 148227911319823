import { createApiReducer } from 'common/utils/reduxApiFactory'
import { postNotificationsBotActions } from 'api/park/notifications/telegram/bot/post/actions'
import { PostNotificationsBotState } from 'api/park/notifications/telegram/bot/post/types'

export const initialState: PostNotificationsBotState = {
  status: null,
  data: null,
}

export const postNotificationsBotReducer = createApiReducer(
  postNotificationsBotActions,
  initialState
)
