import React from 'react'
import { WrappedFieldProps } from 'redux-form'

import Datepicker from 'common/components/datepicker/Datepicker'

interface Props {
  label?: string
  placeholder?: string
  disableOnBlur?: boolean
  centered?: boolean
}

const FormDatepicker: React.FC<WrappedFieldProps & Props> = ({
  input,
  meta,
  disableOnBlur,
  label,
  placeholder,
  centered,
  children,
}) => {
  const [value, setValue] = React.useState(input.value)

  const showError = !!(meta.touched && meta.error)

  const onChange = (date: Date): void => {
    setValue(date)

    input.onChange(date)
  }

  return (
    <Datepicker
      {...input}
      centered={centered}
      onBlur={disableOnBlur ? () => undefined : input.onBlur}
      label={label}
      placeholder={placeholder}
      selected={value}
      childrenElement={children}
      onChange={onChange}
      showError={showError}
      error={showError && input.value ? meta.error : undefined}
    />
  )
}

export default FormDatepicker
