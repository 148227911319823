import React from 'react'
import styled from 'styled-components'
import { ListItem, Radio } from 'ui-kit/components'

export interface Option {
  value: string
  description?: string
  name: string
}

interface Props {
  options: Option[]
  selected?: string
  onChange?: (selectedOption: Option) => void
}

const RadioOptionContainer = styled.div`
  width: 100%;
`

const Description = styled.span`
  color: ${({ theme }) => theme.pallete.darkGray};
`

export const RadioGroup: React.FC<Props> = ({
  options,
  selected,
  onChange,
}) => (
  <>
    {options.map((option) => {
      return (
        <ListItem key={option.name}>
          <RadioOptionContainer>
            <Radio
              name={option.name}
              checked={selected === option.name}
              onChange={() => onChange?.(option)}
            >
              <div>
                {option.value}{' '}
                <Description>
                  {option.description ? `— ${option.description}` : null}
                </Description>
              </div>
            </Radio>
          </RadioOptionContainer>
        </ListItem>
      )
    })}
  </>
)
