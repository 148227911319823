import { ThunkAction } from 'redux-thunk'
import { State } from 'reducers'
import axios, { AxiosResponse } from 'axios'
import { FailureError } from 'common/utils/reduxApiFactory'
import { StatisticsFeesParams, TApiError } from 'api/types'
import { actions, Actions } from 'api/park/statistics/fees/actions'
import { StatisticsFeesData } from 'api/park/statistics/fees/reducer'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  Actions
>

export const handleRequest = (
  params: StatisticsFeesParams
): THandleRequest => async (dispatch) => {
  dispatch(actions.start())
  try {
    const response: AxiosResponse<StatisticsFeesData> = await axios.get(
      'erp/1/park/statistics/fees',
      {
        params,
      }
    )
    dispatch(actions.update(response.data))
    dispatch(actions.success())
  } catch (e) {
    if (e.response) {
      const data = e.response.data as TApiError<StatisticsFeesParams>
      const error: FailureError<StatisticsFeesParams> = new Error(data.message)
      error.data = data
      dispatch(actions.failure(error))
    }
  }
}
