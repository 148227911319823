import React from 'react'

import { ListPlaceholder } from 'shared/ui'

export const EmptyListing: React.FC = () => (
  <ListPlaceholder
    image="wallet"
    imageHeight="176px"
    textContainerWidth="600px"
  >
    У вас нет ни одной операции по депозиту. Например, пополните депозит и здесь
    отобразится полная информация о пополнении.
  </ListPlaceholder>
)
