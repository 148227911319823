import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import Alert from 'react-s-alert'
import * as Sentry from '@sentry/browser'

import 'react-s-alert/dist/s-alert-default.css'
import 'react-s-alert/dist/s-alert-css-effects/slide.css'

import * as serviceWorker from 'serviceWorker'

import { store } from 'store'
import 'bootstrap'

import { GlobalStyles } from 'common/theme'
import { AlertTemplate } from 'shared/ui'
import { RouterContainer } from 'shared/router'
import { ThemeProvider } from 'styled-components'
import { lightTheme } from 'ui-kit/themes'

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={lightTheme}>
        <RouterContainer />
        <GlobalStyles />
      </ThemeProvider>
      <Alert
        stack={{ limit: 5 }}
        effect="slide"
        position="bottom-left"
        contentTemplate={AlertTemplate}
      />
    </Provider>
  )
}

Sentry.init({
  dsn: 'https://56216ffbd8f64adb8b5d8ee4b79f6783@sentry.appkode.ru/64',
  enabled: process.env.NODE_ENV !== 'development',
})

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
