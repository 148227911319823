import { connect } from 'react-redux'

import { State } from 'reducers'
import { apiSelectors } from 'api'
import { MyThunkDispatch } from 'api/reducer'
import { ModalsNames } from 'common/enums'
import { modalsActions, modalsSelectors } from 'shared/modals'
import { EmployeesList } from 'employees/features/employeesList/organisms'

const mapStateToProps = (state: State) => ({
  canViewEmployeeDetails: apiSelectors.employee.profile.getCanViewEmployeeDetails(
    state
  ),
  canChangeEmployee: apiSelectors.employee.profile.getCanChangeEmployee(state),
  isFetching: apiSelectors.employees.get.getIsFetching(state),
  activeEmployees: apiSelectors.employees.get.getActiveEmployees(state),
  inactiveEmployees: apiSelectors.employees.get.getInactiveEmployees(state),
  isAddEmployeeModalOpened: modalsSelectors.getIsModalOpened(
    state,
    ModalsNames.AddEmployee
  ),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  openAddEmployeeModal: () =>
    dispatch(modalsActions.openModal(ModalsNames.AddEmployee)),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const EmployeesListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeesList)
