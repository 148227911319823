import { handleActions } from 'redux-actions'
import {
  SetPaymentOutputFormatAction,
  Payloads,
  setPaymentOutputFormatActionType,
} from 'payments/features/manualConfirmationList/actions'

export interface ManualConfirmationListState {
  selectedOutputFormat: string
}

const initialState: ManualConfirmationListState = {
  selectedOutputFormat: 'txt',
}

const handleSetLoading = (
  state: ManualConfirmationListState,
  action: SetPaymentOutputFormatAction
): ManualConfirmationListState => ({
  ...state,
  selectedOutputFormat: action.payload,
})

export default handleActions<ManualConfirmationListState, Payloads>(
  {
    [setPaymentOutputFormatActionType]: handleSetLoading,
  },
  initialState
)
