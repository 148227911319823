import React from 'react'
import { TableText } from 'ui-kit/components'
import styled from 'styled-components'

export const InfoBar = styled.div`
  display: flex;
  padding: 16px;
  background-color: ${({ theme }) => theme.pallete.secondary};
`

export const InfoText = React.memo(() => {
  return (
    <InfoBar>
      <TableText>
        Обычно здесь появляются возвраты, т.к. были не верно указаны реквизиты
        водителем. Некоторые платежи банк мог провести, но сообщить статус «‎Не
        завершён». Внимательно проверьте эти платежи в банковской выписке
      </TableText>
    </InfoBar>
  )
})

InfoText.displayName = 'InfoText'
