import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { State } from 'reducers'
import { apiSelectors } from 'api'

import { Settings } from 'settings/components'

const mapStateToProps = (state: State) => ({
  permissions: apiSelectors.employee.profile.getPermissions(state),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  RouteComponentProps

export const SettingsContainer = withRouter(connect(mapStateToProps)(Settings))
