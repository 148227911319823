import React from 'react'

import { CaptionText } from 'ui-kit/components'
import styled from 'styled-components'

const Container = styled.div`
  padding: 10px 20px;

  @media (max-width: 1024px) {
    display: none;
  }
`

export const Footer: React.FC = () => (
  <Container>
    <CaptionText color="gray8">v {process.env.REACT_APP_VERSION}</CaptionText>
  </Container>
)
