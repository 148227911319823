import { connect } from 'react-redux'

import { State } from 'reducers'
import { apiActions, apiSelectors } from 'api'
import { MyThunkDispatch } from 'api/reducer'
import { EmployeesJournal } from 'employees/features/employeesJournal/organisms'

type OwnProps = {
  employee_id?: string
}

const mapStateToProps = (state: State) => ({
  isFetching: apiSelectors.employees.logs.getIsFetching(state),
  logs: apiSelectors.employees.logs.getLogs(state),
  totalAmount: apiSelectors.employees.logs.getTotalAmount(state),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  fetch: ({ params }: any) =>
    dispatch(apiActions.employees.logs.handleRequest({ params })),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps

export const EmployeesJournalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeesJournal)
