import { connect } from 'react-redux'

import { State } from 'reducers'
import { apiActions, apiSelectors } from 'api'
import { Payments } from 'payments/components'
import { MyThunkDispatch } from 'api/reducer'

const mapStateToProps = (state: State) => ({
  isBankTypeManual: apiSelectors.park.details.getIsBankTypeManual(state),
  canViewConfirmationTransactions: apiSelectors.employee.profile.getCanViewConfirmationTransactions(
    state
  ),
  canViewNotCompletedTransactions: apiSelectors.employee.profile.getCanViewNotCompletedTransactions(
    state
  ),
  transactionsCounters: apiSelectors.park.transactionsCounters.selectTransactionsCounters(
    state
  ),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  fetchTransactionsCounters: () =>
    dispatch(apiActions.park.transactionsCounters.handleRequest()),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const PaymentsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Payments)
