import React from 'react'
import ListItem from 'ui-kit/atoms/ListItem/ListItem'
import styled from 'styled-components'
import Mark from 'ui-kit/atoms/Mark/Mark'
import { Icon } from 'ui-kit/components'
import Typography from 'ui-kit/typography'

type Props = {
  name: string
  value: string
  onClick?: () => void
}

const TextValue = styled(Typography)<{ withUnderline?: boolean }>`
  text-decoration: ${({ withUnderline }) => withUnderline && 'underline'};
`

const Participant = ({ name, value, onClick }: Props) => (
  <ListItem
    icon={onClick ? <Icon data-testid="icon-user" name="user" /> : null}
    onClick={onClick}
    action={
      onClick ? (
        <Icon data-testid="icon-chevron" name="chevronRight" />
      ) : undefined
    }
  >
    <Mark caption={name}>
      <TextValue
        variant={onClick ? 'Body2' : 'Body1'}
        fontColor={onClick ? 'accentPrimary' : undefined}
        withUnderline={Boolean(onClick)}
      >
        {value}
      </TextValue>
    </Mark>
  </ListItem>
)

export default React.memo<Props>(Participant)
