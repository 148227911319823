import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Icon as DefaultIcon } from 'ui-kit/components'
import Typography from 'ui-kit/typography'

type Props = {
  available: string[]
  marked: string[]
  labelForAll?: string
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const Text = styled(Typography)<{ isDecorated: boolean }>`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 4px;
  text-decoration: ${({ isDecorated }) => isDecorated && 'underline'};
  user-select: ${({ isDecorated }) => isDecorated && 'none'};
`

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
`

const Action = styled.div<{ isCompressed: boolean; canClick: boolean }>`
  display: flex;
  align-self: ${({ isCompressed }) => isCompressed && 'flex-start'};
  cursor: ${({ canClick }) => canClick && 'pointer'};
`

type IconProps = {
  isToggle: boolean
}
const Icon = styled(DefaultIcon).attrs({ name: 'chevronLeft' })<IconProps>`
  transform: rotate(${({ isToggle }) => (isToggle ? '90deg' : '-90deg')});
`

export const NotificationAggregatorList = ({
  marked,
  available,
  labelForAll = 'все',
}: Props) => {
  const [isTextOverflow, setTextOverflow] = useState(false)
  const [isToggle, setToggle] = useState(false)
  const textOverflow = useRef<HTMLDivElement>(null)
  const root = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const currentTextOverflow = textOverflow.current
    setTextOverflow(
      (currentTextOverflow &&
        currentTextOverflow.offsetWidth < currentTextOverflow.scrollWidth) ||
        false
    )
  }, [])

  useEffect(() => {
    const currentRoot = root.current

    const handleWindowClick = (e: MouseEvent) => {
      if (!currentRoot) {
        return
      }

      if (!currentRoot.contains(e.target as any)) {
        setToggle((prevValue) => (prevValue ? false : prevValue))
      }
    }

    window.addEventListener('mousedown', handleWindowClick)

    return () => {
      window.removeEventListener('mousedown', handleWindowClick)
    }
  }, [])

  const handleActionClick = () => {
    setToggle((prevValue) => !prevValue)
  }

  const isMarkedAll = marked.length === available.length
  const text = isMarkedAll ? labelForAll : marked.join(', ')

  return (
    <Wrapper ref={root}>
      {isToggle &&
        marked.map((markedText, index, arr) => (
          <Typography fontColor="textSecondary" key={markedText}>
            {markedText}
            {index + 1 < arr.length && ','}
          </Typography>
        ))}
      <Action
        onClick={isTextOverflow || isMarkedAll ? handleActionClick : undefined}
        isCompressed={isToggle || isMarkedAll}
        canClick={isTextOverflow || isMarkedAll}
      >
        <Text
          fontColor="textSecondary"
          ref={textOverflow}
          isDecorated={isTextOverflow || isMarkedAll}
        >
          {isToggle ? 'свернуть' : text}
        </Text>
        <IconWrapper>
          {(isTextOverflow || isMarkedAll) && <Icon isToggle={isToggle} />}
        </IconWrapper>
      </Action>
    </Wrapper>
  )
}
