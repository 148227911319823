import { createApiReducer } from 'common/utils/reduxApiFactory'
import { PatchModulState } from 'api/park/modul/patch/types'
import { patchModulActions } from 'api/park/modul/patch/actions'

export const initialState: PatchModulState = {
  status: null,
  data: null,
}

export const patchModulReducer = createApiReducer(
  patchModulActions,
  initialState
)
