import styled from 'styled-components'

interface Props {
  alignItems?: string
  direction?: string
  justifyContent?: string
}

const Flexbox = styled.div<Props>`
  display: flex;
  align-items: ${({ alignItems = 'center' }) => alignItems};
  flex-direction: ${({ direction = 'initial' }) => direction};
  justify-content: ${({ justifyContent = 'initial' }) => justifyContent};
`

export default Flexbox
