import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import { State } from 'reducers'
import { MyThunkDispatch } from 'api/reducer'
import { FormNames } from 'common/enums'
import { onSaveTinkoff } from 'steps/features/stepPayments/thunks'
import { PaymentsTinkoff } from 'steps/features/stepPayments/organisms'
import { TinkoffAuth } from 'api/park/tinkoff/types'

export type FormProps = TinkoffAuth

const mapStateToProps = (_state: State) => ({})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  onSave: (values: FormProps) => dispatch(onSaveTinkoff({ values })),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const Form = reduxForm<FormProps, ContainerProps>({
  form: FormNames.StepPaymentsTinkoff,
})(PaymentsTinkoff)

export const PaymentsTinkoffContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Form)
