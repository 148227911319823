import { handleActions } from 'redux-actions'

import { Driver, DriverListInfo } from 'common/types/api/models/driver'
import { Sort } from 'common/types/local/sort'
import { SortDirection } from 'common/enums'

import {
  Payloads,
  SET_DRIVERS_LIST,
  SET_DRIVERS_LIST_FILTER,
  SET_DRIVERS_LIST_PAGE,
  SET_DRIVERS_LIST_SEARCH,
  SET_INFO,
  SORT_DRIVERS,
  SortableColumns,
} from 'drivers/features/driversList/actions'

export interface DriversListState {
  info: DriverListInfo | null
  drivers: Driver[]
  searchValue: string | null
  page: number
  totalPages: number
  filters: string[]
  sort: Sort<SortableColumns>
}

const initialState: DriversListState = {
  info: null,
  drivers: [],
  searchValue: null,
  page: 1,
  totalPages: 0,
  filters: [],
  sort: {
    column: 'name',
    direction: SortDirection.ASC,
  },
}

const handleSetInfo = (
  state: DriversListState,
  action: SET_INFO
): DriversListState => ({
  ...state,
  info: action.payload,
})

const handleSetDriversList = (
  state: DriversListState,
  action: SET_DRIVERS_LIST
): DriversListState => ({
  ...state,
  totalPages: action.payload.total_pages,
  drivers: action.payload.items,
})

const handleSetDriversListSearch = (
  state: DriversListState,
  action: SET_DRIVERS_LIST_SEARCH
): DriversListState => ({
  ...state,
  searchValue: action.payload,
})

const handleSetDriversListPage = (
  state: DriversListState,
  action: SET_DRIVERS_LIST_PAGE
): DriversListState => ({
  ...state,
  page: action.payload,
})

const handleSetDriversListFilter = (
  state: DriversListState,
  action: SET_DRIVERS_LIST_FILTER
): DriversListState => ({
  ...state,
  filters: action.payload,
})

const handleSetDriversSort = (
  state: DriversListState,
  action: SORT_DRIVERS
): DriversListState => ({
  ...state,
  sort: action.payload,
})

export default handleActions<DriversListState, Payloads>(
  {
    [SET_INFO]: handleSetInfo,
    [SET_DRIVERS_LIST]: handleSetDriversList,
    [SET_DRIVERS_LIST_SEARCH]: handleSetDriversListSearch,
    [SET_DRIVERS_LIST_PAGE]: handleSetDriversListPage,
    [SET_DRIVERS_LIST_FILTER]: handleSetDriversListFilter,
    [SORT_DRIVERS]: handleSetDriversSort,
  },
  initialState
)
