import React from 'react'

import { BodyText, CaptionText } from 'ui-kit/components'
import { FontWeightTypes, ColorTypes } from 'ui-kit'

import { currencyApart } from 'common/utils/formatters'
import styled from 'styled-components'

const ScaleBody = styled.span<{ scale?: number }>`
  font-size: ${({ scale }) => scale && `${scale}%`};
  line-height: 150%;
`
const ScaleCaption = styled.span<{ scale?: number }>`
  font-size: ${({ scale }) => scale && `${scale}%`};
`

interface Props {
  amount: number | null
  scale?: number
  color?: ColorTypes
  fontWeight?: FontWeightTypes
  isLoading?: boolean
  currency?: 'ruble' | 'mozencoin'
  explicitNegativeSign?: boolean
}

export const CurrencyText: React.FC<Props> = ({
  amount,
  scale = 100,
  color,
  fontWeight,
  isLoading,
  currency = 'ruble',
  explicitNegativeSign,
}) => {
  const renderBody = () => {
    if (isLoading) {
      return '...'
    }

    if (amount === undefined || amount === null) {
      return '-'
    }

    if (currency === 'mozencoin') {
      return `${amount} Ƶ`
    }

    const [, whole, fraction] = currencyApart(
      amount,
      undefined,
      undefined,
      explicitNegativeSign
    )

    return (
      <>
        {whole}
        <CaptionText color={color} fontWeight={fontWeight}>
          <ScaleCaption scale={scale}>{fraction}</ScaleCaption>
        </CaptionText>
      </>
    )
  }

  return (
    <BodyText color={color} fontWeight={fontWeight}>
      <ScaleBody scale={scale}>{renderBody()}</ScaleBody>
    </BodyText>
  )
}
