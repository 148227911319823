import React from 'react'
import styled from 'styled-components'

import Typography from 'ui-kit/typography'
import { PageTemplate } from 'ui-kit/templates'
import {
  AddDriverNotification,
  DriverNotificationsList,
} from 'ui-kit/organisms'
import { CheckboxOptions } from 'ui-kit/atoms/CheckboxSelect/CheckboxSelect'
import { Notification } from 'ui-kit/organisms/AddDriverNotification/AddDriverNotification'
import { Notifications } from 'ui-kit/organisms/DriverNotificationsList/DriverNotificationsList'
import { HBox } from 'shared/ui'

type Props = {
  aggregatorsList: CheckboxOptions
  notificationsList?: Notifications
  createNotification: (data: Notification) => Promise<void>
  getNotifications: (offset: number) => Promise<void>
  navigationComponent?: React.ReactNode
}

const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 16px;
  margin-bottom: 24px;
`

const Navigation = styled.div``

const PageBody = styled.div`
  display: flex;
  flex-direction: row;
`
const Action = styled.div`
  width: calc(50% - 8px);
  margin-right: 16px;
`
const List = styled.div`
  width: calc(50% - 8px);
`

export const DriverNotificationsPage = ({
  notificationsList,
  aggregatorsList,
  createNotification,
  getNotifications,
  navigationComponent,
}: Props) => {
  return (
    <PageTemplate>
      <PageHeader>
        {Boolean(navigationComponent) && (
          <Navigation>{navigationComponent}</Navigation>
        )}
        <HBox x={0.3} />
        <Typography as="h1" fontWeight="SemiBold" fontSize={20}>
          Уведомления водителям
        </Typography>
      </PageHeader>
      <PageBody>
        <Action>
          <AddDriverNotification
            aggregators={aggregatorsList}
            onCreate={createNotification}
          />
        </Action>
        <List>
          <DriverNotificationsList
            notifications={notificationsList}
            aggregators={aggregatorsList.list.reduce(
              (previousValue, currentValue) => [
                ...previousValue,
                currentValue.value,
              ],
              [] as string[]
            )}
            onFetch={getNotifications}
          />
        </List>
      </PageBody>
    </PageTemplate>
  )
}
