import { apiActions } from 'api'
import { MyThunkDispatch, MyThunkResult } from 'api/reducer'
import { modalsActions } from 'shared/modals'
import { ApiCode } from 'common/types/api/codes'
import { AggregatorType, ModalsNames } from 'common/enums'
import { toISOFormat } from 'common/utils/dates'
import { FormProps } from 'drivers/features/driverInfo/containers/AddDriverContractModalContainer'

export const onSaveDriverContract: ({
  id,
  values,
}: {
  id: AggregatorType
  values: FormProps
}) => MyThunkResult<void> = ({ id, values }) => (dispatch: MyThunkDispatch) => {
  const formValues = { ...values }
  delete formValues.contract_suffix
  if (!('is_non_resident' in formValues)) {
    formValues.is_non_resident = false
  }
  return dispatch(
    apiActions.driversById.contracts.handleRequest({
      id,
      values: {
        ...formValues,
        contract_date: formValues.contract_date
          ? toISOFormat(formValues.contract_date)
          : null,
        second_name: formValues.second_name ? formValues.second_name : null,
      },
    })
  ).then(
    () => {
      dispatch(apiActions.driversById.get.handleRequest(id))
      dispatch(modalsActions.closeModal(ModalsNames.AddDriverContract))
    },
    (reason) => {
      if (
        reason.response &&
        reason.response.data.code === ApiCode.InputValidationError &&
        reason.response.data.payload
      ) {
        throw reason.response.data.payload
      }
    }
  )
}

export const onDeleteDriverContract: ({
  driverId,
}: {
  driverId: string
}) => MyThunkResult<void> = ({ driverId }) => (dispatch: MyThunkDispatch) => {
  return dispatch(
    apiActions.driversById.contractDelete.handleRequest({
      driverId,
    })
  ).then(
    () => {
      dispatch(apiActions.driversById.get.handleRequest(driverId))
      dispatch(modalsActions.closeModal(ModalsNames.DeleteDriverContract))
    },
    (reason) => {
      if (
        reason.response &&
        reason.response.data.code === ApiCode.InputValidationError &&
        reason.response.data.payload
      ) {
        throw reason.response.data.payload
      }
    }
  )
}
