import React from 'react'

import { AggregatorType } from 'common/enums'
import { Col, HBox, Row, VBox } from 'shared/ui'
import {
  Balance,
  DriverContacts,
  DriverContract,
  Heading,
} from 'drivers/features/driverInfo/molecules'
import {
  AddDriverContractModalContainer,
  DeleteDriverContractModalContainer,
} from 'drivers/features/driverInfo/containers'
import { ContainerProps } from 'drivers/features/driverInfo/containers/DriverInfoContainer'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

export const DriverInfo: React.FC<ContainerProps> = ({
  info,
  contract,
  hasUserId,
  balance,
  isAddDriverContractModalOpened,
  isDeleteDriverContractModalOpened,
  openAddDriverContractModal,
  openDeleteDriverContractModal,
  isSecurityCheckPassed,
}) => (
  <>
    <Wrapper>
      {info && (
        <Heading
          info={info}
          hasUserId={hasUserId}
          isSecurityCheckPassed={isSecurityCheckPassed}
        />
      )}
      {hasUserId ? (
        <>
          <HBox />
          <Row>
            <Col percentage={25}>
              {info && (
                <DriverContacts
                  phone={info.phone}
                  aggregator_type={info.aggregator_type as AggregatorType}
                  aggregator_account={info.aggregator_account}
                />
              )}
            </Col>
            <VBox />
            <Col percentage={25}>
              <DriverContract
                contract={contract}
                openAddDriverContractModal={openAddDriverContractModal}
                openDeleteDriverContractModal={openDeleteDriverContractModal}
              />
            </Col>
            <VBox />
            <Col percentage={25}>
              {balance !== null && <Balance amount={balance} />}
            </Col>
            <VBox />
            <Col percentage={25} />
          </Row>
        </>
      ) : null}
    </Wrapper>
    {hasUserId && isAddDriverContractModalOpened ? (
      <AddDriverContractModalContainer />
    ) : null}
    {hasUserId && isDeleteDriverContractModalOpened ? (
      <DeleteDriverContractModalContainer />
    ) : null}
  </>
)
