import React from 'react'
import { change, untouch, WrappedFieldProps } from 'redux-form'
import InputMask, { InputState, ReactInputMask } from 'react-input-mask'

import { store } from 'store'

import { ListInput } from 'ui-kit/components'
import { IconNames } from 'ui-kit'

type TBeforeMaskedValueChange = (
  newState: InputState,
  oldState: InputState
) => InputState

interface Props {
  mask: string | TBeforeMaskedValueChange
  iconName?: IconNames
  pattern?: RegExp
  label?: string
  value?: string
  type?: string
  required?: boolean
  disabled?: boolean
  onIconClick?: (e: React.MouseEvent<HTMLElement>) => void
}

const InputMaskComponent: React.FC<Props & WrappedFieldProps> = (props) => {
  const {
    input,
    iconName,
    onIconClick,
    meta,
    label,
    value,
    required,
    type,
    mask,
    children,
    pattern,
    disabled,
  } = props

  const strMask: string = typeof mask === 'string' ? mask : '**********'

  const fnMask = typeof mask === 'string' ? undefined : mask

  const formatChars = typeof mask === 'string' ? undefined : { '*': '[0-9.]' }

  const showError = !!(meta.touched && meta.error)

  const onClick = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault()

    if (iconName === 'close') {
      store.dispatch(change(meta.form, input.name, null))
      store.dispatch(untouch(meta.form, input.name))
    }

    const item: HTMLElement = document.getElementsByName(input.name).item(0)

    if (item) {
      item.focus()
    }

    if (onIconClick) {
      onIconClick(e)
    }
  }

  const onBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    if (pattern) {
      if (!pattern.test(e.currentTarget.value)) {
        input.onChange(null)
      }
    } else {
      input.onBlur(e)
    }
  }

  return (
    <InputMask
      mask={strMask}
      maskChar={null}
      defaultValue={value}
      disabled={disabled}
      {...input}
      onBlur={onBlur}
      beforeMaskedValueChange={fnMask}
      formatChars={formatChars}
    >
      {(inputProps: ReactInputMask & { name: string }) => (
        <ListInput
          {...inputProps}
          label={label}
          showError={showError}
          error={showError && input.value ? meta.error : undefined}
          iconName={iconName}
          onIconClick={onClick}
          value={value}
          required={required}
          type={type}
          disabled={disabled}
        >
          {children}
        </ListInput>
      )}
    </InputMask>
  )
}

export default InputMaskComponent

export const maskNumberWithSuffix = (
  digits: number,
  suffix: string,
  decimalPlaces = 0
): TBeforeMaskedValueChange => (newState, oldState) => {
  const re = new RegExp(
    decimalPlaces <= 0
      ? `^(\\d{0,${digits}})[^\\d]*?$`
      : `^(\\d{1,${digits}}\\.\\d{0,${decimalPlaces}}|\\d{0,${digits}})[^\\d]*?$`
  )

  const isValid: boolean = re.test(newState.value)

  const value: string = isValid
    ? newState.value.replace(re, '$1')
    : oldState.value

  const s = isValid ? newState.selection : oldState.selection

  return {
    value: value.length ? value + suffix : '',
    selection: {
      start: !s ? 0 : Math.min(value.length, s.start),
      end: !s ? 0 : Math.min(value.length, s.end),
    },
  }
}
