import React from 'react'
import { BodyText, Icon } from 'ui-kit/components'

import { ContainerProps } from 'settings/features/parkCertificate/containers/ParkCertificateContainer'

import { formatToRegularDate } from 'common/utils/dates'
import { ContentCard, ContentCardBody, ContentCardHeader } from 'shared/ui'
import { AddCertificateModalContainer } from 'settings/features/parkCertificate/containers'

export const ParkCertificate: React.FC<ContainerProps> = ({
  canChange,
  isFetching,
  certificate,
  isAddCertificateModalOpened,
  openAddCertificateModal,
}) => {
  return !certificate && !canChange ? null : (
    <>
      <ContentCard>
        <ContentCardHeader title="Сертификат безопасности">
          {certificate && canChange ? (
            <Icon
              name="edit"
              color="blue"
              pointer
              onClick={openAddCertificateModal}
            >
              Заменить
            </Icon>
          ) : null}
        </ContentCardHeader>
        <ContentCardBody isLoading={isFetching}>
          {certificate ? (
            <Icon name="certificate" color="blue">
              <BodyText color="gray9">
                {`Действителен до ${formatToRegularDate(
                  certificate.expires_at
                )} г.`}
              </BodyText>
            </Icon>
          ) : (
            <Icon
              name="certificate"
              color="blue"
              onClick={openAddCertificateModal}
              pointer
            >
              Добавить сертификат
            </Icon>
          )}
        </ContentCardBody>
      </ContentCard>

      {isAddCertificateModalOpened ? <AddCertificateModalContainer /> : null}
    </>
  )
}
