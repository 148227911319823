import { createSelector } from 'reselect'
import { apiSelectors } from 'api'
import { PaymentSettings } from 'api/types'
import {
  defaultSettingsIndex,
  individualSettingsIndex,
} from 'settings/features/paymentSettingsTab/constants'

export const selectPaymentSettings = createSelector(
  apiSelectors.park.paymentSettingsAll.makeSelectData(),
  (settings) => {
    if (!settings) return null
    const result = [] as PaymentSettings[]
    result[defaultSettingsIndex] = settings.park_default_payment_settings
    result[individualSettingsIndex] = settings.individual_payment_settings
    return [...result, ...settings.group_payment_settings]
  }
)
