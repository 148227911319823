import { combineReducers } from 'redux'

import { TContractsCommitAction } from 'api/drivers/contracts/commit/actions'
import {
  ContractsCommitState,
  reducer as contractsCommit,
} from 'api/drivers/contracts/commit/reducer'
import * as contractsCommitSelectors from 'api/drivers/contracts/commit/selectors'
import { handleRequest as contractsCommitRequest } from 'api/drivers/contracts/commit/thunks'

import { TContractsNoContractAction } from 'api/drivers/contracts/no-contract/actions'
import {
  ContractsNoContractState,
  reducer as contractsNoContract,
} from 'api/drivers/contracts/no-contract/reducer'
import * as contractsNoContractSelectors from 'api/drivers/contracts/no-contract/selectors'
import { handleRequest as contractsNoContractRequest } from 'api/drivers/contracts/no-contract/thunks'

import { TContractsUploadAction } from 'api/drivers/contracts/upload/actions'
import {
  ContractsUploadState,
  reducer as contractsUpload,
} from 'api/drivers/contracts/upload/reducer'
import * as contractsUploadSelectors from 'api/drivers/contracts/upload/selectors'
import { handleRequest as contractsUploadRequest } from 'api/drivers/contracts/upload/thunks'

export interface DriversState {
  contractsCommit: ContractsCommitState
  contractsNoContract: ContractsNoContractState
  contractsUpload: ContractsUploadState
}

export type TDriversAction =
  | TContractsCommitAction
  | TContractsNoContractAction
  | TContractsUploadAction

export const driversReducer = combineReducers({
  contractsCommit,
  contractsNoContract,
  contractsUpload,
})

export const driversActions = {
  contractsCommit: {
    handleRequest: contractsCommitRequest,
  },
  contractsNoContract: {
    handleRequest: contractsNoContractRequest,
  },
  contractsUpload: {
    handleRequest: contractsUploadRequest,
  },
}

export const driversSelectors = {
  contractsCommit: contractsCommitSelectors,
  contractsNoContract: contractsNoContractSelectors,
  contractsUpload: contractsUploadSelectors,
}
