import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiLink,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'
import Subtitle from 'elastic-ui/referral-program/atoms/Subtitle'
import { EuiIconEnhanced } from 'elastic-ui/shared/atoms/EnhancedIcon'
import HorizontalSpacer from 'elastic-ui/shared/atoms/HorizontalSpacer'
import RenderGate from 'elastic-ui/shared/atoms/RenderGate'
import React, { useState } from 'react'
import Flexbox from '../../../shared/atoms/Flexbox'

interface Props {
  isLoading: boolean
  isConnected: boolean
  telegramChatLink: string
  onSubscribeClick: (key: string) => void
  onCancelSubscriptionClick: () => void
  isDisabled: boolean
}

const TelegramNotifications: React.FC<Props> = ({
  isLoading,
  isConnected,
  telegramChatLink,
  onSubscribeClick,
  onCancelSubscriptionClick,
  isDisabled,
}) => {
  const [key, setKey] = useState('')

  return (
    <>
      <Subtitle>Телеграм-уведомления</Subtitle>
      <EuiFlexGroup gutterSize="none">
        {isConnected ? (
          <>
            <Flexbox>
              <EuiIcon type="checkInCircleFilled" color="secondary" />
              <HorizontalSpacer size="s" />
              <EuiText>Подключены.</EuiText>
            </Flexbox>
            <EuiButtonEmpty
              color="danger"
              isLoading={isLoading}
              onClick={onCancelSubscriptionClick}
              isDisabled={isDisabled}
              size="xs"
            >
              Отключить
            </EuiButtonEmpty>
          </>
        ) : (
          <>
            <EuiFlexItem grow={5}>
              <EuiFieldText
                fullWidth
                value={key}
                onChange={(e) => setKey(e.target.value)}
                placeholder="Ключ из чата"
                prepend={[<EuiIconEnhanced type="key" key="bruhdude" />]}
                disabled={isDisabled}
              />
            </EuiFlexItem>
            <HorizontalSpacer size="m" />
            <EuiFlexItem>
              <EuiButton
                color="primary"
                isLoading={isLoading}
                onClick={() => onSubscribeClick(key)}
                fullWidth
                disabled={key.length === 0 || isDisabled}
              >
                Подписаться
              </EuiButton>
            </EuiFlexItem>
          </>
        )}
      </EuiFlexGroup>
      <RenderGate isOpen={!isConnected}>
        <EuiSpacer size="m" />
        <EuiText>
          Чтобы получать уведомления в&nbsp;телеграме, введите ключ из диалога с
          ботом.{' '}
          <EuiLink href={telegramChatLink} target="_blank">
            Открыть диалог
          </EuiLink>
        </EuiText>
      </RenderGate>
    </>
  )
}

export default TelegramNotifications
