import { ColorButton as DefaultColorButton } from 'ui-kit/components'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { VBox } from 'shared/ui/spacers'
import styled from 'styled-components'
import { ColorTypes } from 'ui-kit'

const ColorButton = styled(DefaultColorButton)`
  min-width: initial;
  border-radius: 32px;
  &:before {
    border-radius: 32px;
  }
`

const RadioButtonGroupWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
type RadioButtonGroupProps = {
  onChange: (value: any) => void
  initialValue: any
  options: { value: any; label: string }[]
  isDisabled?: boolean
}
export const RadioButtonGroup = React.memo<RadioButtonGroupProps>(
  ({ options, initialValue, isDisabled, onChange }) => {
    const [value, setValue] = useState(initialValue)

    const firstRender = useRef(true)
    useEffect(() => {
      if (firstRender.current) {
        firstRender.current = false
        return
      }
      setValue(initialValue)
    }, [initialValue])

    const handleChangeValue = useCallback(
      (newValue: string) => () => {
        setValue(newValue)
        onChange(newValue)
      },
      [onChange]
    )
    const isActive = (option: { value: string; label: string }) =>
      option.value === value

    const getTextColor = (option: {
      value: string
      label: string
    }): ColorTypes => {
      return isActive(option) ? 'white' : 'black'
    }

    const getBackgroundColor = (option: {
      value: string
      label: string
    }): ColorTypes => {
      return isActive(option) ? 'accentPrimary' : 'secondaryBG'
    }

    return (
      <RadioButtonGroupWrapper>
        {options.map((option) => (
          <React.Fragment key={`${option.label}-${option.value}`}>
            <ColorButton
              onClick={handleChangeValue(option.value)}
              color={getBackgroundColor(option)}
              textColor={getTextColor(option)}
              disabled={isDisabled}
            >
              {option.label}
            </ColorButton>
            <VBox x={1 / 3} />
          </React.Fragment>
        ))}
      </RadioButtonGroupWrapper>
    )
  }
)
RadioButtonGroup.displayName = 'RadioButtonGroup'
