import React from 'react'
import { Router, Switch } from 'react-router'
import { ApmRoute } from '@elastic/apm-rum-react'

import { history } from 'store'

import { RoutesPath } from 'common/enums'
import { Auth } from 'auth'
import ResetPassword from 'auth/resetPassword/containers/ResetPasswordContainer'
import EmailConfirmation from 'auth/emailConfirmation/containers/EmailConfirmationContainer'
import { LayoutContainer } from 'layout'
import { PrivateRoute } from 'shared/router/components'
import { getAuthTokens } from 'jwtAuth'

export const RouterComponent: React.FC = () => {
  const authUrls: string[] = [
    RoutesPath.ResetPassword,
    RoutesPath.EmailConfirmation,
    RoutesPath.SignIn,
  ]

  if (getAuthTokens() && authUrls.includes(history.location.pathname)) {
    history.replace(RoutesPath.MainPage)
  }

  return (
    <Router history={history}>
      <Switch>
        <ApmRoute
          exact
          path={RoutesPath.ResetPassword}
          component={ResetPassword}
        />
        <ApmRoute
          exact
          path={RoutesPath.EmailConfirmation}
          component={EmailConfirmation}
        />
        <ApmRoute path={RoutesPath.SignIn} component={Auth} />
        <PrivateRoute path={RoutesPath.MainPage} component={LayoutContainer} />
      </Switch>
    </Router>
  )
}
