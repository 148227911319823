import React from 'react'
import { BodyText, CaptionText } from 'ui-kit/components'
import {
  ContentCard,
  ContentCardBody,
  ContentCardHeader,
  HBox,
} from 'shared/ui'
import { ContainerProps } from 'employees/features/employeeRights/containers/EmployeeRightsContainer'

export const EmployeeRights: React.FC<ContainerProps> = () => (
  <ContentCard>
    <ContentCardHeader title="Права и доступ" />
    <ContentCardBody>
      <BodyText>Управление водителями</BodyText>
      <CaptionText>
        Просмотр заявок на подключение, обработка заявок, просмотр списка,
        просмотр списка выплат, работа с выплатами и еще 6 позиций
      </CaptionText>
      <HBox />

      <BodyText>Настройка автопарка</BodyText>
      <CaptionText>
        Редактирование данных организации, настройка выплат водителями загрузка
        ключа для h2h
      </CaptionText>
      <HBox />

      <BodyText>Настройка системы</BodyText>
      <CaptionText>
        Выдавать роль owner сотрудникам, редактирование ФИО, изменение пароля,
        просмотр сотрудников, добавление сотрудников и еще 2 позиции
      </CaptionText>
      <HBox />

      <BodyText>Управление финансами</BodyText>
      <CaptionText>
        Изменение платежных настроек у автопарка, просмотр баланса аккаунта,
        просмотр транзакций, операции с транзакциями, Просмотр взаиморасчетов
        автопарка, просмотр статистики работы автопарка
      </CaptionText>
    </ContentCardBody>
  </ContentCard>
)
