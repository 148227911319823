import React from 'react'
import { TableText } from 'ui-kit/components'
import { ColorTypes } from 'ui-kit'

import { ContractUpload } from 'api/types'
import { getWidthInPercent } from 'common/utils/formatters'
import { formatToRegularDate } from 'common/utils/dates'
import { Colgroup, Column, Table } from 'shared/table'

interface Props {
  contractsList: { [key: string]: ContractUpload[] }
}

export const Listing: React.FC<Props> = ({ contractsList }) => {
  const blockWidth = 1078

  const formatName = (item: ContractUpload): JSX.Element => {
    return <TableText>{item.driver_name_aggregator}</TableText>
  }

  const formatPhone = (item: ContractUpload): JSX.Element => {
    return <TableText>{item.phone}</TableText>
  }

  const formatContract = (item: ContractUpload): JSX.Element => {
    const color: ColorTypes = 'gray8'
    return (
      <TableText>
        {`№${item.contract_number} от ${formatToRegularDate(
          item.contract_date
        )} г.`}
        <TableText color={color}>
          {`${item.last_name} ${item.first_name} ${item.second_name || ''}`}
        </TableText>
      </TableText>
    )
  }

  return (
    <>
      {Object.keys(contractsList).map((key, index) => (
        <Table key={key} source={contractsList[key]} bodyMinHeight="auto">
          <Colgroup>{key}</Colgroup>
          <Column
            data={formatName}
            flex={`${getWidthInPercent(blockWidth, 370)}%`}
          >
            {index === 0 ? 'Водители' : null}
          </Column>
          <Column
            data={formatPhone}
            flex={`${getWidthInPercent(blockWidth, 200)}%`}
          >
            {index === 0 ? 'Телефон' : null}
          </Column>
          <Column
            data={formatContract}
            flex={`${getWidthInPercent(blockWidth, 500)}%`}
          >
            {index === 0 ? 'Договор' : null}
          </Column>
        </Table>
      ))}
    </>
  )
}
