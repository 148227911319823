import React from 'react'
import { NotificationsTelegramBotConnector } from 'settings/features/notificationsTelegramBot/containers'
import {
  EnableNotificationsTelegramBot,
  DisableNotificationsTelegramBot,
} from 'settings/features/notificationsTelegramBot/organisms'

export const NotificationsTelegramBot = () => {
  return (
    <NotificationsTelegramBotConnector>
      {({ isEnabled, link, getLink, disableNotifications, sendSecret }) => {
        if (isEnabled) {
          return (
            <DisableNotificationsTelegramBot onClick={disableNotifications} />
          )
        }

        return (
          <EnableNotificationsTelegramBot
            onConfirm={sendSecret}
            onConnect={getLink}
            link={link}
          />
        )
      }}
    </NotificationsTelegramBotConnector>
  )
}
