import { handleActions } from 'redux-actions'

import { SuspectTransaction } from 'common/types/api/models/antifraud'

import {
  Payloads,
  SET_OPEN_MODAL,
  SET_REQUEST,
  SET_TRANSACTION,
} from 'antifraud/modals/paymentDetails/actions'

export interface AntiFraudDetailsModalState {
  transaction: SuspectTransaction | null
  isOpen: boolean
  onlyResolve: boolean
  allowConfirming: boolean
  request?: (confirmed: boolean, id?: string) => void
}

const initialState: AntiFraudDetailsModalState = {
  transaction: null,
  isOpen: false,
  onlyResolve: false,
  allowConfirming: false,
}

const handleSetTransaction = (
  state: AntiFraudDetailsModalState,
  action: SET_TRANSACTION
): AntiFraudDetailsModalState => ({
  ...state,
  transaction: action.payload,
})

const handleSetModalIsOpen = (
  state: AntiFraudDetailsModalState,
  action: SET_OPEN_MODAL
): AntiFraudDetailsModalState => ({
  ...state,
  isOpen: action.payload,
})

const handleSetRequest = (
  state: AntiFraudDetailsModalState,
  action: SET_REQUEST
): AntiFraudDetailsModalState => ({
  ...state,
  request: action.payload || undefined,
})

export default handleActions<AntiFraudDetailsModalState, Payloads>(
  {
    [SET_TRANSACTION]: handleSetTransaction,
    [SET_OPEN_MODAL]: handleSetModalIsOpen,
    [SET_REQUEST]: handleSetRequest,
  },
  initialState
)
