import { createSelector } from 'reselect'
import { apiSelectors } from 'api'
import { IncomeDataSet } from 'ui-kit/molecules/IncomeInfo'

export const selectIncomeDataSet = createSelector(
  apiSelectors.park.getIncomeReport.selectData,
  (data) => {
    if (!data) {
      return null
    }

    return {
      yandexTransfers: data.total,
      accruals: {
        total: data.profit,
        cardPayment: data.card,
        cardFleetPayment: data.partner_ride_card,
        compensation: data.compensation,
        discountCompensation: data.promotion_discount,
        promotionalCode: data.promotion_promocode,
        corporatePayment: data.corporate,
        bonus: data.bonus,
        tips: data.tip,
      },
      writeOffs: {
        total: data.charges,
        platformCommission: {
          perOrder: data.platform_ride_fee,
          perNDS: data.platform_ride_vat,
          perBonus: data.platform_bonus_fee,
        },
        gasStation: data.platform_other_gas,
        callCenter: data.platform_callcenter_fee,
        subscription: data.subscription,
        subscriptionNDS: data.subscription_vat,
        childSeatRent: data.platform_other_rent_childseat,
        childSeatRentNDS: data.platform_other_rent_childseat_vat,
      },
    } as IncomeDataSet
  }
)
