import styled from 'styled-components'

export const Col = styled.div<{
  percentage?: number
  withAlignStart?: boolean
}>`
  display: flex;
  flex-direction: column;
  align-self: ${({ withAlignStart }) => withAlignStart && `start`};
  width: ${({ percentage }) => percentage && `${percentage}%`};
`
