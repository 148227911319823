import axios from 'axios'
import useSWR from 'swr'
import { useCallback, useState } from 'react'

import {
  Notifications,
  Notification,
} from 'ui-kit/organisms/DriverNotificationsList/DriverNotificationsList'
import { Notification as ANotification } from 'ui-kit/organisms/AddDriverNotification/AddDriverNotification'
import { Aggregator } from 'api/types'
import { AggregatorType } from 'common/enums'

enum DriverNotificationRecipientType {
  Yandex = 'yandex',
  Citymobil = 'citymobil',
  Didi = 'didi',
}
type DriverNotificationRecipient = {
  id: string
  type: DriverNotificationRecipientType
}
type DriverNotification = {
  aggregators_send_to: (DriverNotificationRecipient & { name: string })[]
  created_at: string
  id: string
  read_counter: number
  text: string
}
type DriverNotificationList = {
  messages: DriverNotification[]
  total: number
}

function getDriverNotifications(limit: number, offset?: number) {
  return axios.get<DriverNotificationList>('erp/1/park/notifications/mass', {
    params: { limit, offset },
  })
}
function postDriverNotification(
  recipients: DriverNotificationRecipient[],
  text: string
) {
  return axios.post<DriverNotification>('erp/1/park/notifications/mass', {
    aggregators: recipients,
    text,
  })
}

function transformToDriverNotificationRecipientType(type: AggregatorType) {
  switch (type) {
    case AggregatorType.DiDi:
      return DriverNotificationRecipientType.Didi
    case AggregatorType.Citymobil:
      return DriverNotificationRecipientType.Citymobil
    case AggregatorType.Yandex:
      return DriverNotificationRecipientType.Yandex
    default:
      return DriverNotificationRecipientType.Yandex
  }
}

function transformToUiNotification(
  data: DriverNotificationList,
  offset: number
): Notifications {
  const uiNotificationData = data.messages.map(
    (message) =>
      ({
        id: message.id,
        text: message.text,
        date: message.created_at,
        viewCount: message.read_counter,
        aggregators: message.aggregators_send_to.reduce(
          (previousValue, currentValue) => [
            ...previousValue,
            currentValue.name,
          ],
          [] as string[]
        ),
      } as Notification)
  )
  return { data: uiNotificationData, total: data.total, offset }
}

async function driverNotificationsFetcher(key: string, offset: number) {
  const response = await getDriverNotifications(5, offset)
  return transformToUiNotification(response.data, offset)
}

export function useDriverNotifications(aggregatorsList: Aggregator[]) {
  const [inMemoryOffset, setInMemoryOffset] = useState(0)

  const { data: driverNotifications, revalidate } = useSWR(
    ['driverNotification', inMemoryOffset],
    driverNotificationsFetcher
  )

  const createNotification = useCallback(
    async (notification: ANotification) => {
      const recipientsNames = notification.recipients
      const aggregators = recipientsNames.reduce((prev, current) => {
        const aggregator = aggregatorsList.find(
          (val) => val.account === current
        )
        if (aggregator) {
          return [
            ...prev,
            {
              ...aggregator,
              type: transformToDriverNotificationRecipientType(aggregator.type),
            },
          ]
        }
        return prev
      }, [] as DriverNotificationRecipient[])
      await postDriverNotification(aggregators, notification.text)
      if (inMemoryOffset !== 0) {
        setInMemoryOffset(0)
      } else {
        await revalidate()
      }
    },
    [aggregatorsList, inMemoryOffset, revalidate]
  )

  const getNotification = useCallback(async (newOffset: number) => {
    setInMemoryOffset(newOffset)
  }, [])

  return {
    driverNotifications,
    createNotification,
    getNotification,
  }
}
