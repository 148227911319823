import { AggregatorType } from 'common/enums'
import React from 'react'
import {
  ContentCard,
  ContentCardHeader,
  ContentCardBody,
  HBox,
} from 'shared/ui'
import { CheckboxSelect } from 'ui-kit/atoms'
import { ColorButton } from 'ui-kit/components'
import { Props } from '../../containers/AggregatorsCardContainer'

export const AggregatorsCard: React.FC<Props> = ({
  aggregators,
  onDownloadClick,
  isLoading,
  fetchAggregators,
}) => {
  const [error, setError] = React.useState('')
  const [selectedAggregators, setSelectedAggregators] = React.useState<
    string[]
  >([])
  const aggregatorIdAndTypeSeparator = '___'

  React.useEffect(() => {
    setSelectedAggregators(
      aggregators.map(
        ({ id, type }) => `${id}${aggregatorIdAndTypeSeparator}${type}`
      )
    )
  }, [aggregators])

  React.useEffect(() => {
    fetchAggregators()
  }, [fetchAggregators])

  const handleSelectedAggregatorsChange = (
    newSelectedAggregators: string[]
  ) => {
    if (newSelectedAggregators.length === 0) {
      setError('Выберите агрегаторы')
    } else {
      setError('')
    }

    setSelectedAggregators(newSelectedAggregators)
  }

  return (
    <ContentCard>
      <ContentCardHeader title="Скачайте" />
      <ContentCardBody isLoading={isLoading}>
        <CheckboxSelect
          error={error}
          caption="Учетная запись агрегатора"
          options={{
            all: 'Все',
            list: aggregators.map(({ id, account, type }) => ({
              value: `${id}${aggregatorIdAndTypeSeparator}${type}`,
              label: account,
            })),
          }}
          value={selectedAggregators}
          onChange={handleSelectedAggregatorsChange}
        />
        <HBox />
        <ColorButton
          onClick={() => {
            const mappedAggregators = selectedAggregators.map((idAndType) => {
              const [id, type] = idAndType.split(aggregatorIdAndTypeSeparator)
              return { id, type: type as AggregatorType }
            })

            onDownloadClick(mappedAggregators)
          }}
          disabled={Boolean(error)}
          fullWidth
        >
          Скачать список водителей (.XLSX)
        </ColorButton>
      </ContentCardBody>
    </ContentCard>
  )
}
