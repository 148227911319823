import { Action, BaseAction } from 'redux-actions'

import { ProfileResponse } from 'api/types'
import { Permission } from 'common/enums'

export type TUpdate = Action<ProfileResponse>
export type TUpdatePermissions = Action<Permission[]>
export type TPatch = Action<Partial<ProfileResponse>>
export type TStart = BaseAction
export type TSuccess = BaseAction
export type TFailure = Action<{ error: any }>
export type TReset = BaseAction
export type TResetStatus = BaseAction

export type TProfileAction =
  | TUpdate
  | TUpdatePermissions
  | TPatch
  | TStart
  | TSuccess
  | TFailure
  | TReset
  | TResetStatus

export const UPDATE: TUpdate['type'] = 'api/employee/profile->UPDATE'
export const UPDATE_PERMISSIONS: TUpdatePermissions['type'] =
  'api/employee/profile->UPDATE_PERMISSIONS'
export const PATCH: TPatch['type'] = 'api/employee/profile->PATCH'
export const START: TStart['type'] = 'api/employee/profile->START'
export const SUCCESS: TSuccess['type'] = 'api/employee/profile->SUCCESS'
export const FAILURE: TFailure['type'] = 'api/employee/profile->FAILURE'
export const RESET: TReset['type'] = 'api/employee/profile->RESET'
export const RESET_STATUS: TResetStatus['type'] =
  'api/employee/profile->RESET_STATUS'

export const update = (data: ProfileResponse): TUpdate => ({
  type: UPDATE,
  payload: data,
})
export const updatePermissions = (
  permissions: Permission[]
): TUpdatePermissions => ({
  type: UPDATE_PERMISSIONS,
  payload: permissions,
})
export const patch = (newPatch: Partial<ProfileResponse>): TPatch => ({
  type: PATCH,
  payload: newPatch,
})
export const start = () => ({
  type: START,
})
export const success = () => ({
  type: SUCCESS,
})
export const failure = (error: any): TFailure => ({
  type: FAILURE,
  payload: error,
})
export const reset = () => ({
  type: RESET,
})
export const resetStatus = () => ({
  type: RESET_STATUS,
})

export type TPayloads = Pick<
  TUpdate & TUpdatePermissions & TPatch & TFailure,
  'payload'
>['payload']

export default {
  update,
  patch,
  reset,
}
