import React from 'react'

interface ClampOptions {
  min: number
  max: number
}

export const clamp = (number: number, { min, max }: ClampOptions) =>
  Math.max(min, Math.min(number || min, max))

interface FixDecimalPrecisionErrorsOptions {
  fixedNumbersAfterDecimalPoint?: number
}

export const fixDecimalPrecisionErrors = (
  decimal: number,
  { fixedNumbersAfterDecimalPoint = 2 }: FixDecimalPrecisionErrorsOptions = {}
) => Number.parseFloat(decimal.toFixed(fixedNumbersAfterDecimalPoint))
