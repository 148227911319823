import { createSelector } from 'reselect'
import { State } from 'reducers'
import { apiSelectors } from 'api'
import { SubscriptionStatus } from 'common/enums'

export const getIsSidebarVisible = (state: State) => {
  const profile = apiSelectors.employee.profile.getProfile(state)
  return Boolean(profile && profile.full_name)
}

export const getIsNoticeVisible = createSelector(
  apiSelectors.park.details.getSubscriptionStatus,
  (subscriptionStatus) =>
    subscriptionStatus
      ? subscriptionStatus === SubscriptionStatus.Unpaid ||
        subscriptionStatus === SubscriptionStatus.Blocked
      : null
)
