import { connect } from 'react-redux'
import { MyThunkDispatch } from 'api/reducer'
import { DriverSettingsTab } from 'drivers/features/driverSettingsTab/components'
import { apiActions, apiSelectors } from 'api'
import { PaymentSettings } from 'api/types'

import {
  selectDriverSettingsData,
  selectIsFetching,
} from 'drivers/features/driverSettingsTab/selectors'
import { State } from 'reducers'

const mapStateToProps = (state: State) => ({
  bankType: apiSelectors.park.details.getBankType(state),
  isLoading: selectIsFetching(state),
  userId: apiSelectors.driversById.get.getUserId(state),
  isSettingsFetching: apiSelectors.driversById.paymentSettings.makeSelectGetIsFetching()(
    state
  ),
  settings: apiSelectors.driversById.paymentSettings.makeSelectGetData()(state),
  canChangePaymentsConditions: apiSelectors.employee.profile.getCanChangePaymentsConditions(
    state
  ),
  allowNonResident: apiSelectors.park.details.getAllowNonResident(state),
  isSecurityCheckPassed: apiSelectors.driversById.get.getSecurityCheckPassed(
    state
  ),
  isBankTypeManual: apiSelectors.park.details.getIsBankTypeManual(state),
  settingsOptionsList: apiSelectors.park.paymentSettingsAll.makeSelectOptions()(
    state
  ),
  data: selectDriverSettingsData(state),
  driverSettings: apiSelectors.driversById.paymentSettings.makeSelectData()(
    state
  ),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  fetchEditIndividualSettings: (
    settings: Partial<PaymentSettings>,
    driverId: string
  ) =>
    dispatch(
      apiActions.driversById.paymentSettings.handlePatchRequest(
        {
          driverId,
        },
        settings
      )
    ),
  fetchCreateIndividualSettings: (
    settings: Partial<PaymentSettings>,
    driverId: string
  ) =>
    dispatch(
      apiActions.driversById.paymentSettings.handlePostRequest(
        {
          driverId,
        },
        settings
      )
    ),
  fetchCreateGroupSettings: (settings: Partial<PaymentSettings>) =>
    dispatch(apiActions.park.paymentSettingsPatch.handleRequest(settings)),
  fetchAssignSettings: (paymentSettingsId: string, userId: string) =>
    dispatch(
      apiActions.park.paymentSettingsAssign.handleRequest({
        payment_settings_id: paymentSettingsId,
        user_ids: [userId],
      })
    ),
  fetchParkSettings: () =>
    dispatch(apiActions.park.paymentSettingsAll.handleRequest()),
  fetchDriverSettings: (driverId: string) =>
    dispatch(
      apiActions.driversById.paymentSettings.handleGetRequest({
        driverId,
      })
    ),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const DriverSettingsTabContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DriverSettingsTab)
