import { Action } from 'redux-actions'

export interface EngineeringWorksPayload {
  title: string
  message: string
}

export type SET_SERVER_ERROR = Action<boolean>
export type SET_ENGINEERING_WORKS = Action<EngineeringWorksPayload>

export const SET_SERVER_ERROR: SET_SERVER_ERROR['type'] =
  'router/SET_SERVER_ERROR'
export const SET_ENGINEERING_WORKS: SET_ENGINEERING_WORKS['type'] =
  'router/SET_ENGINEERING_WORKS'

export const setServerError = (error: boolean): SET_SERVER_ERROR => ({
  type: SET_SERVER_ERROR,
  payload: error,
})

export const setEngineeringWorks = (
  payload: EngineeringWorksPayload
): SET_ENGINEERING_WORKS => ({
  type: SET_ENGINEERING_WORKS,
  payload,
})

export type Payloads =
  | SET_SERVER_ERROR['payload']
  | SET_ENGINEERING_WORKS['payload']
