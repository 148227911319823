import { combineReducers } from 'redux'

import profileActions, {
  TProfileAction,
} from 'api/employee/profile/get/actions'
import {
  ProfileState,
  reducer as profile,
} from 'api/employee/profile/get/reducer'
import * as profileSelectors from 'api/employee/profile/get/selectors'
import { handleRequest as profileRequest } from 'api/employee/profile/get/thunks'

import { TProfilePostAction } from 'api/employee/profile/post/actions'
import {
  ProfilePostState,
  reducer as profilePost,
} from 'api/employee/profile/post/reducer'
import * as profilePostSelectors from 'api/employee/profile/post/selectors'
import { handleRequest as profilePostRequest } from 'api/employee/profile/post/thunks'

export interface EmployeeState {
  profile: ProfileState
  profilePost: ProfilePostState
}

export type TEmployeeAction = TProfileAction | TProfilePostAction

export const employeeReducer = combineReducers({
  profile,
  profilePost,
})

export const employeeActions = {
  profile: {
    handleRequest: profileRequest,
    update: profileActions.update,
    patch: profileActions.patch,
    reset: profileActions.reset,
  },
  profilePost: {
    handleRequest: profilePostRequest,
  },
}

export const employeeSelectors = {
  profile: profileSelectors,
  profilePost: profilePostSelectors,
}
