import React, { useCallback, useMemo, useState } from 'react'
import { Field, InjectedFormProps, SubmissionError } from 'redux-form'

import { BodyText, Icon } from 'ui-kit/components'

import ListInput from 'common/components/formItems/ListInput'
import { required } from 'common/utils/fieldValidation'
import {
  CancelOrSave,
  ContentCard,
  ContentCardBody,
  ContentCardHeader,
  Tooltip,
  ToggleOptions,
} from 'shared/ui'

import {
  ContainerProps,
  FormProps,
} from 'settings/features/parkEdgvr/containers/ParkEdgvrContainer'
import styled from 'styled-components'

const Display = styled.div<{ display: boolean }>`
  display: ${({ display }) => !display && 'none'};
`

const contentContainerComponent = styled.div`
  top: calc(100% + 8px) !important;
  bottom: initial !important;
  right: initial !important;
  left: calc(100% - 350px) !important;
  width: 350px !important;
  box-shadow: 0 3px 12px rgba(5, 16, 34, 0.06) !important;
`

const EdgvrInfo = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ParkEdgvr: React.FC<
  InjectedFormProps<FormProps, ContainerProps> & ContainerProps
> = ({
  reset,
  canChange,
  edgvrApiKey,
  isFetching,
  isFetchingDeleteEdgvrApiKey,
  onSave,
  deleteEdgvrApiKey,
  getEdgvrApiKey,
  submitting,
  handleSubmit,
}) => {
  const [editing, setEditing] = useState(false)
  const [isOptionsToggle, setOptionsToggle] = useState(false)

  const startEdit = () => setEditing(true)
  const stopEdit = () => {
    setEditing(false)
    reset()
  }

  const handleDelete = useCallback(async () => {
    try {
      await deleteEdgvrApiKey()
      await getEdgvrApiKey()
    } catch (e) {
      throw new SubmissionError(e)
    }
  }, [deleteEdgvrApiKey, getEdgvrApiKey])

  const optionsList = useMemo(() => {
    return [
      {
        text: 'Редактировать',
        icon: 'edit',
        action: () => {
          setEditing(true)
          setOptionsToggle(false)
        },
      },
      {
        text: 'Удалить',
        icon: 'deletion',
        critical: true,
        action: () => {
          setOptionsToggle(false)
          handleDelete()
        },
      },
    ]
  }, [handleDelete])

  const handleOptionsClick = useCallback(() => {
    setOptionsToggle((prevValue) => !prevValue)
  }, [])

  const handleSave = async (values: FormProps) => {
    try {
      await onSave(values)
      stopEdit()
    } catch (e) {
      throw new SubmissionError(e)
    }
  }

  const renderHeaderAction = () => {
    if (!canChange) {
      return null
    }

    if (editing) {
      return (
        <CancelOrSave
          isLoading={submitting}
          onCancel={stopEdit}
          onSubmit={handleSubmit(handleSave)}
        />
      )
    }

    return null
  }

  return edgvrApiKey || canChange ? (
    <>
      <ContentCard isLoading={isFetching || isFetchingDeleteEdgvrApiKey}>
        <ContentCardHeader title="Договоры водителей">
          {!isFetching && !edgvrApiKey && !editing ? (
            <Tooltip
              content="Чтобы все договоры, заключенные с водителями автоматически
                добавлялись в систему вам необходимо ввести ключ."
              contentComponent={contentContainerComponent}
            />
          ) : null}
          {renderHeaderAction()}
        </ContentCardHeader>
        <ContentCardBody>
          <Display display={!editing}>
            {edgvrApiKey ? (
              <EdgvrInfo>
                <Icon name="statusBig">
                  <BodyText color="gray9">Edgvr подключен</BodyText>
                </Icon>
                <ToggleOptions
                  active={isOptionsToggle}
                  onClick={handleOptionsClick}
                  options={optionsList}
                />
              </EdgvrInfo>
            ) : (
              <Icon name="contract" color="blue" pointer onClick={startEdit}>
                Подключить edgvr
              </Icon>
            )}
          </Display>
          <Display display={editing}>
            <Field
              name="edgvr_api_key"
              validate={required}
              iconName="close"
              label="Ключ API edgvr"
              component={ListInput}
            />
          </Display>
        </ContentCardBody>
      </ContentCard>
    </>
  ) : null
}
