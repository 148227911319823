import React from 'react'

import { CaptionText, ListLead } from 'ui-kit/components'

import { Driver } from 'common/types/api/models/driver'

import { formatPhone, getWidthInPercent } from 'common/utils/formatters'
import {
  aggregatorTypeToString,
  driverStatusToString,
  SortDirection,
} from 'common/enums'
import { Column, Table } from 'shared/table'
import { Tooltip } from 'shared/ui'

import { SortableColumns } from 'drivers/features/driversList/actions'
import styled from 'styled-components'

const NameWrapper = styled.div`
  display: flex;
  align-items: center;

  > div {
    margin-right: 16px;
  }
`

const ListingTooltip = styled(Tooltip)`
  top: -8px;
  bottom: initial;
  left: 40px;
  width: 180px;
  box-shadow: 0px 3px 10px rgba(5, 16, 34, 0.17);
`

interface Props {
  drivers: Driver[]
  driversIsLoading: boolean
  sortColumn: SortableColumns
  sortDirection: SortDirection
  onRowClick?: (driver: Driver) => void
  onChangeSort: (column: string, direction: SortDirection) => void
}

export const Listing: React.FC<Props> = ({
  drivers,
  onRowClick,
  driversIsLoading,
  onChangeSort,
  sortColumn,
  sortDirection,
}) => {
  const blockWidth = 1064

  const formatName = (driver: Driver): JSX.Element => {
    return (
      <NameWrapper>
        {!driver.has_payment_details && (
          <ListingTooltip
            withDefaultIconColor
            iconName="warning2"
            iconColor="white"
            content={<CaptionText>Не указаны реквизиты</CaptionText>}
          />
        )}
        <ListLead table>{driver.name}</ListLead>
      </NameWrapper>
    )
  }
  const formatDriverPhone = (driver: Driver): JSX.Element => (
    <ListLead table>{formatPhone(driver.phone)}</ListLead>
  )
  const formatAggregator = (driver: Driver): JSX.Element => (
    <ListLead table reverse subtitle={driver.park_account}>
      {aggregatorTypeToString(driver.aggregator_type)}
    </ListLead>
  )
  const formatStatus = (driver: Driver): JSX.Element => (
    <ListLead table>{driverStatusToString(driver.work_status)}</ListLead>
  )

  return (
    <Table
      onChangeSort={onChangeSort}
      source={drivers}
      sortColumn={sortColumn}
      sortDirection={sortDirection}
      onClickRow={onRowClick}
      bodyMinHeight="672px"
      isLoading={driversIsLoading}
    >
      <Column
        sortable="name"
        data={formatName}
        flex={`${getWidthInPercent(blockWidth, 384)}%`}
      >
        Водитель
      </Column>
      <Column
        data={formatDriverPhone}
        flex={`${getWidthInPercent(blockWidth, 224)}%`}
      >
        Телефон
      </Column>
      <Column
        data={formatAggregator}
        flex={`${getWidthInPercent(blockWidth, 300)}%`}
      >
        Агрегатор
      </Column>
      <Column
        data={formatStatus}
        flex={`${getWidthInPercent(blockWidth, 156)}%`}
      >
        Статус
      </Column>
    </Table>
  )
}
