import React from 'react'
import styled from 'styled-components'
import { Icon } from 'ui-kit/components'

const Centrolizer = styled.span<{ withHover?: boolean }>`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 4px;
    border: none;
    cursor: pointer;
    border-radius: 8px;

    &:hover {
      background-color: ${({ theme, withHover }) =>
        withHover ? theme.pallete.secondary : null};
    }
  `

interface InlineTextSelectStringProps {
  color?: string
  withHover?: boolean
}

const InlineTextSelectString = styled.span<InlineTextSelectStringProps>`
  display: inline-block;
  color: ${({ color }) => color};
  transition: 150ms color ease-in-out;
  position: relative;
`

interface InlineTextSelectProps {
  color?: string
  children?: string
  isSelecting?: boolean
  withHover?: boolean
}

export const InlineTextSelect: React.FC<InlineTextSelectProps> = ({
  color,
  children,
  isSelecting,
  withHover,
}) => {
  return (
    <Centrolizer withHover={withHover}>
      <InlineTextSelectString color={color}>{children}</InlineTextSelectString>
      <Icon
        name={isSelecting ? 'expandLess' : 'expandMore'}
        color={color as undefined}
        width={20}
        height={24}
      />
    </Centrolizer>
  )
}
