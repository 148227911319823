import React from 'react'

import { Pagination as Controls } from 'ui-kit/components'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

interface Props {
  totalPages: number
  page: number
  onChangePage: (direction: 'prev' | 'next') => void
}

export const Pagination: React.FC<Props> = ({
  totalPages,
  page,
  onChangePage,
}) => (
  <Container>
    <Controls
      currentPage={page}
      totalPages={totalPages}
      onClickPrev={() => onChangePage('prev')}
      onClickNext={() => onChangePage('next')}
    />
  </Container>
)
