import { createApiReducer } from 'common/utils/reduxApiFactory'
import { ParkReviseState } from './types'
import { postParkReviseActions } from './actions'

export const initialState: ParkReviseState = {
  status: null,
  data: null,
}

export const postParkReviseReducer = createApiReducer(
  postParkReviseActions,
  initialState
)
