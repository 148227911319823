import React from 'react'

import { Col } from 'shared/ui'
import { ProfileInfoContainer } from 'settings/features/profileInfo'

import { ContainerProps } from 'settings/features/profileTab/containers/ProfileTabContainer'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.paddings.main}px;
`

export const ProfileTab: React.FC<ContainerProps> = () => (
  <Wrapper>
    <Col percentage={60}>
      <ProfileInfoContainer />
    </Col>
  </Wrapper>
)
