import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: inline;
  color: ${({ theme }) => theme.pallete.blue};
  text-decoration: underline;
  cursor: pointer;
`

type Props = {
  onClick: () => void
}

export const InlineButton: React.FC<Props> = ({ onClick, children }) => (
  <Container onClick={onClick}>{children}</Container>
)
