import React from 'react'
import moment from 'moment'
import 'moment/locale/ru'

import Typography from 'ui-kit/typography'

type Props = {
  time: string
}

export const PrettyDate = ({ time }: Props) => {
  return (
    <Typography fontColor="textSecondary">
      {moment.utc(time).local().locale('ru').calendar(null, {
        sameElse: 'D MMMM YYYY',
      })}
    </Typography>
  )
}
