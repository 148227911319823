import React from 'react'
import { Field, InjectedFormProps, SubmissionError } from 'redux-form'

import { RejectResolveButtons } from 'ui-kit/components'
import InputMask, {
  maskNumberWithSuffix,
} from 'common/components/formItems/InputMask'
import { moreThanZero, required } from 'common/utils/fieldValidation'
import { HBox, Modal } from 'shared/ui'
import {
  ContainerProps,
  FormProps,
} from 'settings/features/notificationsSettings/containers/AddNotificationsQiwiSettingsModalContainer'

export const AddNotificationsQiwiSettingsModal: React.FC<
  InjectedFormProps<FormProps, ContainerProps> & ContainerProps
> = ({ submitting, handleSubmit, closeModal, onSave }) => {
  const handleSave = async (values: FormProps) => {
    try {
      await onSave(values)
    } catch (e) {
      throw new SubmissionError(e)
    }
  }
  return (
    <Modal
      open
      onClose={closeModal}
      noOverlayClick={false}
      closeButton
      overflow="initial"
      title="Уведомить о балансе QIWI"
      width={400}
    >
      <Field
        name="min_balance"
        label="Баланс ниже, ₽"
        mask={maskNumberWithSuffix(5, '', 2)}
        iconName="close"
        validate={[required, moreThanZero]}
        component={InputMask}
      />
      <HBox />
      <RejectResolveButtons
        fullWidth
        isLoading={submitting}
        onResolve={handleSubmit(handleSave)}
        onReject={closeModal}
        rejectText="Закрыть"
        disableResolve={submitting} // || !valid
        disableReject={submitting}
      />
    </Modal>
  )
}
