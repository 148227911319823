import { connect } from 'react-redux'

import { State } from 'reducers'
import { apiSelectors } from 'api'

import { Sidebar } from 'layout/features/sidebar/organisms'

const mapStateToProps = (state: State) => ({
  profile: apiSelectors.employee.profile.getProfile(state),
  canViewTariff: apiSelectors.employee.profile.getCanViewTariff(state),
})

export type ContainerProps = ReturnType<typeof mapStateToProps>

export const SidebarContainer = connect(mapStateToProps)(Sidebar)
