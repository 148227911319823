import { ThunkAction } from 'redux-thunk'
import { State } from 'reducers'
import axios, { AxiosResponse } from 'axios'
import { FailureError } from 'common/utils/reduxApiFactory'
import { TApiError } from 'api/types'
import { getTinkoffActions as actions } from 'api/park/tinkoff/get/actions'
import { TinkoffAuth } from 'api/park/tinkoff/types'
import { GetTinkoffActions } from 'api/park/tinkoff/get/types'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  GetTinkoffActions
>

export const getTinkoffRequest = (): THandleRequest => async (dispatch) => {
  dispatch(actions.start())
  try {
    const response: AxiosResponse<TinkoffAuth> = await axios.get(
      'erp/1/park/tinkoff'
    )
    dispatch(actions.update(response.data))
    dispatch(actions.success())
  } catch (e) {
    if (e.response) {
      const data = e.response.data as TApiError
      const error: FailureError = new Error(data.message)
      error.data = data
      dispatch(actions.failure(error))
    }
    throw e
  }
}
