import React, { useState } from 'react'
import { WrappedFieldProps } from 'redux-form'

import { BodyText } from 'ui-kit/components'
import styled from 'styled-components'

const Label = styled.label`
  cursor: pointer;
`

interface Props {
  children?: React.ReactNode
  fileType?: string[] | string
  accept?: string
}

const checkFileTypes = (type: string, fileType: string[] | string): boolean => {
  let valid = false

  if (typeof fileType === 'string') {
    if (fileType === type) {
      valid = true
    }
  } else {
    ;(fileType as string[]).forEach((item) => {
      if (item === type) {
        valid = true
      }
    })
  }

  return valid
}

export const FormFile: React.FC<Props & WrappedFieldProps> = ({
  children,
  fileType,
  accept,
  input,
}) => {
  const [fileName, setFileName] = useState<string | null>(null)
  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.currentTarget.files && e.currentTarget.files[0]) {
      if (
        !fileType ||
        checkFileTypes(e.currentTarget.files[0].type, fileType)
      ) {
        setFileName(e.currentTarget.files[0].name)
        input.onChange(e.currentTarget.files[0])
      }
    }
  }

  const renderBody = () => {
    if (!children || typeof children === 'string') {
      return (
        <BodyText color="blue" fontWeight="semi-bold">
          {fileName || children || 'Выбрать файл...'}
        </BodyText>
      )
    }

    if (fileName) {
      return (
        <BodyText color="blue" fontWeight="semi-bold">
          {fileName}
        </BodyText>
      )
    }

    return children
  }

  return (
    <>
      <input
        hidden
        type="file"
        id={input.name}
        {...input}
        value=""
        accept={accept}
        onChange={onChange}
      />
      <Label htmlFor={input.name}>{renderBody()}</Label>
    </>
  )
}
