import React, { useEffect, useRef, useState } from 'react'

import { Image } from 'shared/ui/image/components/Image'
import styled from 'styled-components'
import sotrudnikShokSrc from 'static/placeholders/sotrudnik-shok.svg'

const Wrapper = styled.div`
  position: relative;
`

const Container = styled.div<{ top: number }>`
  position: absolute;
  top: ${({ top }) => top}px;
`

interface Props {
  className?: string
}

export const NoContentImage: React.FC<Props> = ({ className }) => {
  const [top, setTop] = useState(0)
  const [random, setRandom] = useState(0)

  const imageEl = useRef<HTMLImageElement>(null)
  const divEl = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (imageEl.current?.height && divEl.current) {
      const offsetBootom =
        window.innerHeight -
        imageEl.current.height -
        divEl.current.getBoundingClientRect().top
      if (offsetBootom > 0) {
        setTop(offsetBootom)
      }
    } else {
      setRandom(Math.random())
    }
  }, [random])

  return (
    <Wrapper className={className}>
      <Container ref={divEl} top={top}>
        <Image ref={imageEl} src={sotrudnikShokSrc} alt="sotrudnik-shok" />
      </Container>
    </Wrapper>
  )
}
