import React, { useCallback, useEffect, useState } from 'react'
import { Field, InjectedFormProps, SubmissionError } from 'redux-form'

import {
  BodyText,
  FloatButton,
  Icon,
  TitleText,
  TransparentButton,
} from 'ui-kit/components'

import { BankType } from 'common/enums'
import ListInput from 'common/components/formItems/ListInput'
import { RadioGroup } from 'common/components/formItems/RadioGroup'

import {
  email,
  maxLength9,
  minLength20,
  minLength9,
  phone,
  required,
} from 'common/utils/fieldValidation'
import { bankAccountMask, phoneMask, phonePattern } from 'common/utils/patterns'
import InputMask from 'common/components/formItems/InputMask'

import { useStepsContext } from 'shared/hooks'
import { HBox, Row, Tooltip } from 'shared/ui'
import { list } from 'steps/data'
import { StepsActionType } from 'steps/types'
import {
  ContainerProps,
  FormProps,
} from 'steps/features/stepRequisites/containers/StepRequisitesContainer'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 563px;
`

const noVisibleStyle = css`
  display: none;
`
const Display = styled.div<{ isVisible: boolean }>`
  ${({ isVisible }) => !isVisible && noVisibleStyle}
`

const Form = styled.form``

const Actions = styled.div`
  width: 320px;
`

const contentContainerComponent = styled.div`
  top: calc(100% + 8px) !important;
  bottom: initial !important;
  right: initial !important;
  left: calc(100% - 296px) !important;
  width: 296px !important;
  box-shadow: 0 3px 12px rgba(5, 16, 34, 0.06) !important;
`

const bankTypeOptions = [
  {
    value: BankType.Alfa,
    label: 'Альфа-Банк — автоматические выплаты на счет,',
  },
  {
    value: BankType.AlfaE2C,
    label: 'Альфа-Банк — автоматические выплаты на карту,',
  },
  {
    value: BankType.Tinkoff,
    label: 'Тинькофф — автоматические выплаты на карту,',
  },
  {
    value: BankType.Qiwi,
    label: 'Точка — автоматические выплаты на карту,',
  },
  {
    value: BankType.Modul,
    label: 'Модульбанк — автоматические выплаты на карту,',
  },
  { value: BankType.Manual, label: 'другой банк — только ручные выплаты.' },
]

export const StepRequisites: React.FC<
  InjectedFormProps<FormProps, ContainerProps> & ContainerProps
> = ({ submitting, handleSubmit, valid, formValues, onSave }) => {
  const { stepsDispatch } = useStepsContext()
  useEffect(() => {
    stepsDispatch({
      type: StepsActionType.SET_CURRENT_STEP,
      payload: { current: list.requisites },
    })
  }, [stepsDispatch])

  const [hasSelectedChannelType, setHasSelectedChannelType] = useState(false)

  const handleSave = async (values: FormProps) => {
    stepsDispatch({
      type: StepsActionType.SET_CHANNEL_TYPE,
      payload: { channelType: formValues && formValues.bank_type },
    })

    try {
      await onSave(values)
    } catch (e) {
      throw new SubmissionError(e)
    }
  }

  const handleSelectChannelType = useCallback(
    (selected: boolean) => () => setHasSelectedChannelType(selected),
    []
  )

  const isQiwiBank = formValues && formValues.bank_type === BankType.Qiwi
  const isAlfaBank = formValues && formValues.bank_type === BankType.Alfa
  const isAlfaBankE2C = formValues && formValues.bank_type === BankType.AlfaE2C
  const isModulBank = formValues && formValues.bank_type === BankType.Modul
  const isTinkoffBank = formValues && formValues.bank_type === BankType.Tinkoff

  return (
    <Wrapper>
      <Display isVisible={!hasSelectedChannelType}>
        <TitleText fontWeight="semi-bold">
          В каком банке открыт ваш счет?
        </TitleText>
        <HBox />

        <Field
          name="bank_type"
          component={RadioGroup}
          options={bankTypeOptions}
        />

        <HBox x={2} />
        <FloatButton
          disabled={!(formValues && formValues.bank_type)}
          onClick={handleSelectChannelType(true)}
        >
          Далее
        </FloatButton>
      </Display>

      <Display isVisible={hasSelectedChannelType}>
        <TransparentButton
          fontWeight="semi-bold"
          onClick={handleSelectChannelType(false)}
        >
          <Row>
            <Icon color="blue" name="chevronLeft" />
            Банк
          </Row>
        </TransparentButton>
        <HBox x={0.5} />

        <TitleText fontWeight="semi-bold">
          {isQiwiBank && 'Реквизиты в Точке'}
          {(isAlfaBank || isAlfaBankE2C) && 'Реквизиты в Альфа-Банке'}
          {isModulBank && 'Реквизиты в Модульбанке'}
          {isTinkoffBank && 'Реквизиты в Тинькофф'}
        </TitleText>
        <HBox />
        <BodyText color="gray9">
          Укажите реквизиты автопарка, чтобы автоматически оформлять быстрые
          выплаты через Mozen.
        </BodyText>
        <HBox x={0.5} />
        <Form>
          <Field
            name="legal_name"
            validate={[required]}
            label="Название компании"
            component={ListInput}
          >
            <Tooltip
              content="Название компании, под которым вы зарегистрированы в банке"
              contentComponent={contentContainerComponent}
            />
          </Field>
          <Field
            name="bik"
            validate={[required, minLength9, maxLength9]}
            type="number"
            iconName="close"
            label="БИК банка"
            component={ListInput}
          />
          <Field
            name="bank_account"
            validate={[required, minLength20]}
            iconName="close"
            mask={bankAccountMask}
            label="Номер расчетного счета"
            component={InputMask}
          />

          <HBox x={2} />
          <BodyText color="gray9">
            Контакты, по которым водители могут связаться с вами:
          </BodyText>
          <HBox x={0.5} />

          <Field
            name="email"
            validate={
              formValues && formValues.phone ? [email, required] : email
            }
            label="Email"
            component={ListInput}
          >
            <Tooltip
              content="Email, по которому водители могут связаться с вами"
              contentComponent={contentContainerComponent}
            />
          </Field>
          <Field
            name="phone"
            label="Телефон"
            validate={
              formValues && formValues.email ? [phone, required] : phone
            }
            mask={phoneMask}
            pattern={phonePattern}
            component={InputMask}
          >
            <Tooltip
              content="Телефон, по которому водители могут связаться с вами"
              contentComponent={contentContainerComponent}
            />
          </Field>

          <HBox x={2} />
          <Actions>
            <FloatButton
              type="submit"
              disabled={!valid}
              isLoading={submitting}
              fullWidth
              onClick={handleSubmit(handleSave)}
            >
              Продолжить
            </FloatButton>
          </Actions>
        </Form>
      </Display>
    </Wrapper>
  )
}
