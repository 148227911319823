import React from 'react'

import { SubTitleText, TitleText } from 'ui-kit/components'
import styled from 'styled-components'
import { EuiSpacer } from '@elastic/eui'

const Container = styled.div`
  padding: ${({ theme }) => theme.paddings.threeQuarters}px;
`

export const NotificationsNotAvailable = () => (
  <Container>
    <TitleText>Mozen не отвечает</TitleText>
    <EuiSpacer size="l" />
    <SubTitleText>
      Мы уже в курсе и работаем над исправлением.
      <br />
      Приносим извинения за временные неудобства.
    </SubTitleText>
  </Container>
)
