import React, { useMemo } from 'react'
import styled from 'styled-components'

import { CaptionText } from 'ui-kit/components'
import { PaginationArrow } from 'ui-kit/components/Pagination/PaginationArrow'

const Wrapper = styled.div<{ withLockOverlay?: boolean }>`
  height: 56px;
  display: flex;
  align-items: center;
  user-select: none;
  ${({ withLockOverlay }) => withLockOverlay && `opacity: 0.4;`};

  .muk-pagination-text {
    margin: 0 8px;
  }
`

interface Props {
  id?: string
  currentPage: number
  totalPages: number
  className?: string
  isLocked?: boolean
  onClickPrev: (e: React.MouseEvent<HTMLDivElement>) => void
  onClickNext: (e: React.MouseEvent<HTMLDivElement>) => void
}

export const Pagination = React.memo<Props>(
  ({ id, totalPages, currentPage, onClickPrev, onClickNext, isLocked }) => {
    const isPrevDisabled = currentPage <= 1
    const isNextDisabled = currentPage >= totalPages

    const handleClickPrev = useMemo(() => {
      if (isLocked || isPrevDisabled) return
      return onClickPrev
    }, [isPrevDisabled, isLocked, onClickPrev])

    const handleClickNext = useMemo(() => {
      if (isLocked || isNextDisabled) return
      return onClickNext
    }, [isLocked, isNextDisabled, onClickNext])

    return (
      <Wrapper
        id={id}
        className="muk-pagination-wrapper"
        withLockOverlay={isLocked}
      >
        <PaginationArrow
          icon="chevronLeft"
          disabled={isLocked || isPrevDisabled}
          onClick={handleClickPrev}
        />
        <CaptionText
          color="gray8"
          fontWeight="medium"
          className="muk-pagination-text"
        >
          {currentPage} из {totalPages}
        </CaptionText>
        <PaginationArrow
          icon="chevronRight"
          disabled={isLocked || isNextDisabled}
          onClick={handleClickNext}
        />
      </Wrapper>
    )
  }
)

Pagination.displayName = 'Pagination'
