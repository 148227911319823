import { handleActions } from 'redux-actions'

import { SuspectTransaction } from 'common/types/api/models/antifraud'

import {
  Payloads,
  SET_TRANSACTIONS,
  SET_TRANSACTIONS_DATE_FROM,
  SET_TRANSACTIONS_DATE_TO,
  SET_TRANSACTIONS_FILTER,
  SET_TRANSACTIONS_PAGE,
  SET_TRANSACTIONS_SEARCH,
} from 'antifraud/suspect/actions'

export interface AntiFraudSuspectState {
  transactions: SuspectTransaction[]
  searchValue: string
  filters: string[]
  page: number
  total: number
  dateFrom: Date | null
  dateTo: Date | null
  checkedRecords: Set<string>
}

const initialState: AntiFraudSuspectState = {
  transactions: [],
  searchValue: '',
  filters: [],
  page: 1,
  total: 0,
  dateFrom: null,
  dateTo: null,
  checkedRecords: new Set(),
}

const handleSetTransactions = (
  state: AntiFraudSuspectState,
  action: SET_TRANSACTIONS
): AntiFraudSuspectState => ({
  ...state,
  transactions: action.payload.transactions,
  total: action.payload.total_count,
})

const handleSetTransactionsSearch = (
  state: AntiFraudSuspectState,
  action: SET_TRANSACTIONS_SEARCH
): AntiFraudSuspectState => ({
  ...state,
  searchValue: action.payload,
})

const handleSetTransactionsFilter = (
  state: AntiFraudSuspectState,
  action: SET_TRANSACTIONS_FILTER
): AntiFraudSuspectState => ({
  ...state,
  filters: action.payload,
})

const handleSetTransactionsPage = (
  state: AntiFraudSuspectState,
  action: SET_TRANSACTIONS_PAGE
): AntiFraudSuspectState => ({
  ...state,
  page: action.payload,
})

const handleSetTransactionsDateFrom = (
  state: AntiFraudSuspectState,
  action: SET_TRANSACTIONS_DATE_FROM
): AntiFraudSuspectState => ({
  ...state,
  dateFrom: action.payload,
})

const handleSetTransactionsDateTo = (
  state: AntiFraudSuspectState,
  action: SET_TRANSACTIONS_DATE_TO
): AntiFraudSuspectState => ({
  ...state,
  dateTo: action.payload,
})

export default handleActions<AntiFraudSuspectState, Payloads>(
  {
    [SET_TRANSACTIONS]: handleSetTransactions,
    [SET_TRANSACTIONS_SEARCH]: handleSetTransactionsSearch,
    [SET_TRANSACTIONS_FILTER]: handleSetTransactionsFilter,
    [SET_TRANSACTIONS_PAGE]: handleSetTransactionsPage,
    [SET_TRANSACTIONS_DATE_FROM]: handleSetTransactionsDateFrom,
    [SET_TRANSACTIONS_DATE_TO]: handleSetTransactionsDateTo,
  },
  initialState
)
