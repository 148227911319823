import { ApiMoney } from 'api/types'

export type ModulBank = {
  balance: ApiMoney
}

export type ModulAuth = {
  payments_token: string
  authorization_token: string
}

export enum ModulErrorCode {
  ModulTokensNotFound = 'ModulTokensNotFound',
  ModulBankAccountNotFoundError = 'ModulBankAccountNotFoundError',
  ModulBankError = 'ModulBankError',
}
