import { createApiReducer } from 'common/utils/reduxApiFactory'
import { PostReferralOffersState } from './types'
import { postReferralOffersActions } from './actions'

export const initialState: PostReferralOffersState = {
  status: null,
  data: null,
}

export const postReferralOffersReducer = createApiReducer(
  postReferralOffersActions,
  initialState
)
