import { Action } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { State, TRootAction } from 'reducers'
import { apiActions } from 'api'
import {
  getLimit,
  getPage,
} from 'settings/features/subscriptionEvents/selectors'

type ThunkResult<R> = ThunkAction<R, State, undefined, TRootAction>

export const onDidMount: () => ThunkResult<void> = () => {
  return (
    dispatch: ThunkDispatch<State, undefined, Action>,
    getState: () => State
  ) => {
    dispatch(apiActions.park.subscriptionSummary.handleRequest())
    dispatch(
      apiActions.park.subscriptionPayments.handleRequest(
        getLimit(getState()),
        getPage(getState())
      )
    )
  }
}
