import { apiActions, apiSelectors } from 'api'
import { MyThunkDispatch } from 'api/reducer'
import { connect } from 'react-redux'
import { State } from 'reducers'
import TariffPage from 'elastic-ui/tariff/pages/TariffPage'

const mapStateToProps = (state: State) => ({
  isSubscriptionLoading: apiSelectors.park.subscriptionSummary.getIsFetching(
    state
  ),
  isDepositTransactionsLoading: apiSelectors.park.subscriptionPayments.getIsFetching(
    state
  ),
  isBalanceLoading: apiSelectors.park.details.getIsFetching(state),
  isBankTypeManual: apiSelectors.park.details.getIsBankTypeManual(state),
  balance: (apiSelectors.park.details.getDepositBalance(state) || 0) / 100,
  subscriptionPrice:
    (apiSelectors.park.subscriptionSummary.getCurrentTariff(state)?.price
      .amount || 0) / 100,
  subscriptionStatus: apiSelectors.park.subscriptionSummary.getPlan(state)
    ?.status,
  depositTransactions: apiSelectors.park.subscriptionPayments.getPayments(
    state
  ),
  depositTransactionsTotalAmount: apiSelectors.park.subscriptionPayments.getPaymentsTotal(
    state
  ),
  bankType: apiSelectors.park.details.getBankType(state),
  isAutoTopupEnabled: apiSelectors.park.subscriptionSummary.getIsAutoTopupEnabled(
    state
  ),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  onPageChange: (page: number) =>
    dispatch(apiActions.park.subscriptionPayments.handleRequest(20, page)),
  onPaymentStatementDownload: (amount: number) =>
    dispatch(
      apiActions.park.topupDepositPaymentOrder.handleRequest(amount * 100)
    ),
  onDepositTopup: (amount: number) =>
    dispatch(apiActions.park.topupDepositPost.handleRequest(amount * 100)),
  setAutoTopup: (isEnabled: boolean) =>
    dispatch(apiActions.park.subscriptionDeposit.handleRequest(isEnabled)),
})

export type TariffProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps, mapDispatchToProps)(TariffPage)
