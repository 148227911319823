import { Action, BaseAction } from 'redux-actions'

import { ModalsNames } from 'common/enums'

export type TOPEN_MODAL = Action<ModalsNames>
export type TCLOSE_MODAL = Action<ModalsNames>
export type TCLOSE_ALL_MODALS = BaseAction
export type TSET_DATA_MODAL = Action<{ name: ModalsNames; data: any }>

export const OPEN_MODAL: TOPEN_MODAL['type'] = 'shared/modals->OPEN_MODAL'
export const CLOSE_MODAL: TCLOSE_MODAL['type'] = 'shared/modals->CLOSE_MODAL'
export const CLOSE_ALL_MODALS: TCLOSE_ALL_MODALS['type'] =
  'shared/modals->CLOSE_ALL_MODALS'
export const SET_DATA_MODAL: TSET_DATA_MODAL['type'] =
  'shared/modals->SET_DATA_MODAL'

export const openModal = (name: ModalsNames): TOPEN_MODAL => ({
  type: OPEN_MODAL,
  payload: name,
})
export const closeModal = (name: ModalsNames): TCLOSE_MODAL => ({
  type: CLOSE_MODAL,
  payload: name,
})
export const closeAllModals = () => ({ type: CLOSE_ALL_MODALS })
export const setData = (name: ModalsNames, data: any): TSET_DATA_MODAL => ({
  type: SET_DATA_MODAL,
  payload: {
    name,
    data,
  },
})

export type TPayloads = Pick<
  TOPEN_MODAL & TCLOSE_MODAL & TSET_DATA_MODAL,
  'payload'
>['payload']

export default {}
