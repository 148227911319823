import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import { State } from 'reducers'
import { apiSelectors } from 'api'
import { MyThunkDispatch } from 'api/reducer'
import { FormNames } from 'common/enums'
import { onSaveParkContacts } from 'settings/features/parkContacts/thunks'
import { ParkContacts } from 'settings/features/parkContacts/organisms'

export interface FormProps {
  email: string | null
  phone: string | null
}

const mapStateToProps = (state: State) => ({
  canChange: apiSelectors.employee.profile.getCanChangeParkRequisites(state),
  isFetching: apiSelectors.park.technicalSupportContactsGet.getIsFetching(
    state
  ),
  initialValues: apiSelectors.park.technicalSupportContactsGet.getContacts(
    state
  ),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  onSave: (values: FormProps) => dispatch(onSaveParkContacts({ values })),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const Form = reduxForm<FormProps, ContainerProps>({
  form: FormNames.ParkContacts,
  enableReinitialize: true,
})(ParkContacts)

export const ParkContactsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Form)
