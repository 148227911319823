import React from 'react'
import DatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker'
import ru from 'date-fns/locale/ru'

import 'react-datepicker/dist/react-datepicker.min.css'

import CustomTrigger from 'ui-kit/molecules/datepicker/CustomTrigger'
import { Wrapper } from 'ui-kit/molecules/datepicker/styles'

registerLocale('ru', ru)

interface Props extends ReactDatePickerProps {
  placeholder?: string
  className?: string
  label?: string
  value?: string
  type?: string
  required?: boolean
  showError?: boolean
  error?: string
  alwaysShowMask?: boolean
  childrenElement?: React.ReactNode
  small?: boolean
  onFocus?: React.EventHandler<React.FocusEvent<HTMLInputElement>>
}

const Datepicker: React.FC<Props> = ({
  placeholder = 'дд.мм.гггг',
  dateFormat = 'dd.MM.yyyy',
  showError,
  error,
  alwaysShowMask,
  label,
  required,
  childrenElement,
  small,
  className,
  onFocus,
  ...props
}) => {
  const customInput: JSX.Element = (
    <CustomTrigger
      showError={showError}
      error={error}
      alwaysShowMask={alwaysShowMask}
      label={label}
      required={required}
    >
      {childrenElement}
    </CustomTrigger>
  )
  return (
    <Wrapper small={small} className={className}>
      <DatePicker
        {...props}
        required={required}
        placeholderText={placeholder}
        dateFormat={dateFormat}
        locale="ru"
        customInput={customInput}
        onFocus={onFocus}
      />
    </Wrapper>
  )
}

export default Datepicker
