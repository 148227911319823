import { createApiReducer } from 'common/utils/reduxApiFactory'
import { PatchCloudCashBoxState } from 'api/receipt/cloud-cashbox/patch/types'
import { patchCloudCashBoxActions } from 'api/receipt/cloud-cashbox/patch/actions'

export const initialState: PatchCloudCashBoxState = {
  status: null,
  data: null,
}

export const patchCloudCashBoxReducer = createApiReducer(
  patchCloudCashBoxActions,
  initialState
)
