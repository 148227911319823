import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'

import { BankType, RoutesPath } from 'common/enums'
import { useStepsContext } from 'shared/hooks'
import { list } from 'steps/data'
import { StepsActionType } from 'steps/types'
import {
  PaymentsCertificateContainer,
  PaymentsQiwiContainer,
  PaymentsModulContainer,
  PaymentsTinkoffContainer,
} from 'steps/features/stepPayments/containers'

import { ContainerProps } from 'steps/features/stepPayments/containers/StepPaymentsContainer'
import { PaymentsAlfaE2C } from '../PaymentsAlfaE2C/PaymentsAlfaE2C'
import { PaymentsAlfaE2CContainer } from '../../containers/PaymentsAlfaE2CContainer'

export const StepPayments: React.FC<ContainerProps> = ({
  bankType,
  fetchParkDetails,
}) => {
  const { steps, stepsDispatch } = useStepsContext()
  const isQiwiBankType = steps.channelType === BankType.Qiwi
  const isAlfaBankType = steps.channelType === BankType.Alfa
  const isAlfaBankE2CType = steps.channelType === BankType.AlfaE2C
  const isModulBankType = steps.channelType === BankType.Modul
  const isManualBankType = steps.channelType === BankType.Manual
  const isTinkoffBankType = steps.channelType === BankType.Tinkoff

  useEffect(() => {
    stepsDispatch({
      type: StepsActionType.SET_CURRENT_STEP,
      payload: { current: list.payments },
    })
  }, [stepsDispatch])

  useEffect(() => {
    if (!steps.channelType) {
      if (!bankType) {
        fetchParkDetails()
      } else {
        stepsDispatch({
          type: StepsActionType.SET_CHANNEL_TYPE,
          payload: {
            channelType: bankType,
          },
        })
      }
    }
  }, [bankType, fetchParkDetails, steps.channelType, stepsDispatch])

  return (
    <>
      {isQiwiBankType && <PaymentsQiwiContainer />}
      {isAlfaBankType && <PaymentsCertificateContainer />}
      {isAlfaBankE2CType && <PaymentsAlfaE2CContainer />}
      {isModulBankType && <PaymentsModulContainer />}
      {isTinkoffBankType && <PaymentsTinkoffContainer />}
      {isManualBankType && <Redirect to={RoutesPath.StepsDrivers} />}
    </>
  )
}
