import { createApiReducer } from 'common/utils/reduxApiFactory'
import { postReportActions } from 'api/report/post/actions'
import { PostReportState } from 'api/report/post/types'

export const initialState: PostReportState = {
  status: null,
  data: null,
}

export const postReportReducer = createApiReducer(
  postReportActions,
  initialState
)
