import React, { useContext } from 'react'

import { ColorTypes } from 'ui-kit'

import { DefaultProps } from 'ui-kit/components/Typography/interfaces'
import { TableCaptionTextWrapper } from 'ui-kit/components/Typography/styles'
import { ThemeContext } from 'styled-components'

interface Props extends DefaultProps {
  bold?: boolean
}

export const TableCaptionText: React.FC<Props> = (props) => {
  const { children, id, className, bold, color = 'gray8' as ColorTypes } = props
  const theme = useContext(ThemeContext)

  return (
    <TableCaptionTextWrapper
      id={id}
      bold={bold}
      color={theme.pallete[color]}
      className={className}
    >
      {children}
    </TableCaptionTextWrapper>
  )
}
