import styled from 'styled-components'

export const LeftContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const LeftContent = styled.div`
  width: 100%;
  max-width: 464px;

  .title {
    margin-bottom: 16px;
  }

  .text {
    width: 100%;
    min-height: 64px;
    padding: 0 16px;
    text-align: justify;
  }
`

export const RightContent = styled.div`
  display: flex;
  align-items: center;
  height: calc(100vh - 170px);

  .right-title {
    padding: 0 16px 0 72px;
    margin-bottom: 15px;
  }
`

export const ImageHolder = styled.div`
  img {
    position: relative;
    left: -68px;
  }
`

export const Actions = styled.div`
  margin-top: 16px;
`

export const Form = styled.form`
  margin-top: 8px;
`
