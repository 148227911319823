import React from 'react'
import styled from 'styled-components'
import Card from 'ui-kit/atoms/Card/Card'
import { ColorButton, Icon } from 'ui-kit/components'
import { Currency } from 'ui-kit/components/Currency/Currency'
import FuelBonusInfo from 'ui-kit/atoms/FuelBonusInfo'
import Typography from 'ui-kit/typography'

type Props = {
  currency?: number
  parkCommission: number
  driverCommission: number
  onGiveBack: () => void
  onChangeCommission: () => void
}
const Wrapper = styled.div`
  padding: 16px;
  display: flex;
`
const Header = styled(Typography).attrs({ variant: 'Caption1' })`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const MainSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
`

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`
const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
`
const HelpText = styled(Typography).attrs({ variant: 'Caption1' })`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`
const HeaderIcon = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`

export const BonusScore = ({
  currency,
  parkCommission,
  driverCommission,
  onGiveBack,
  onChangeCommission,
}: Props) => {
  return (
    <Card>
      <Wrapper>
        <MainSection>
          <Header>
            <HeaderIcon>
              <Icon name="bonus" />
            </HeaderIcon>
            Бонусный счет
          </Header>
          <Currency
            regularFontWidth="bold"
            regularFontSize={32}
            decimalFontSize={24}
            decimalFontWidth="bolder"
            value={currency}
            explicitNegativeSign
          />
          <Footer>
            <ColorButton disabled onClick={onGiveBack}>
              Перевести на депозит
            </ColorButton>
            <HelpText>
              <strong>Скоро</strong>
              Для перевода обратитесь к менеджеру
            </HelpText>
          </Footer>
        </MainSection>
        <RightSection>
          <FuelBonusInfo
            parkCommission={parkCommission / 100}
            driverCommission={driverCommission / 100}
            onClick={onChangeCommission}
          />
        </RightSection>
      </Wrapper>
    </Card>
  )
}

export default React.memo(BonusScore)
