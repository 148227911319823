import React, {
  useMemo,
  createRef,
  RefObject,
  useEffect,
  useState,
} from 'react'

import { ListItem, ListLead } from 'ui-kit/components'
import { Aggregator } from 'common/types/api/models/aggregator'
import { AggregatorType, aggregatorTypeToString } from 'common/enums'
import { Avatar, ToggleOptions, VBox } from 'shared/ui'
import styled from 'styled-components'

import yandexSrc from 'static/icons/yandex.svg'
import citymobilSrc from 'static/icons/citymobil.svg'
import didiSrc from 'ui-kit/static/icons/di-di.svg'

function getSrc(type: AggregatorType) {
  switch (type) {
    case AggregatorType.Yandex:
      return yandexSrc
    case AggregatorType.Citymobil:
      return citymobilSrc
    case AggregatorType.DiDi:
      return didiSrc
    default:
      return yandexSrc
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Account = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
  }
`

interface Props {
  aggregators: Aggregator[]
  openAddAggregatorModalToEdit: (id: string) => void
  openDeleteAggregatorModal: (id: string) => void
}

export const Listing: React.FC<Props> = ({
  aggregators,
  openAddAggregatorModalToEdit,
  openDeleteAggregatorModal,
}) => {
  const [active, setActive] = useState<number>()

  const aggregatorsRefs = useMemo(
    () =>
      aggregators.reduce((map) => {
        return [...map, createRef<HTMLDivElement>()]
      }, [] as RefObject<HTMLDivElement>[]),
    [aggregators]
  )

  function handleClickOutside(event: MouseEvent) {
    const index = aggregatorsRefs.findIndex(
      (ref) => ref.current && ref.current.contains(event.target as Node)
    )
    if (index === -1) {
      setActive(undefined)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  return (
    <Container>
      {aggregators.map((item, index) => (
        <ListItem key={item.id} border="bottom">
          <Account>
            <Avatar avatar={getSrc(item.type)} size="24px" />
            <VBox x={0.75} />
            <ListLead subtitle={aggregatorTypeToString(item.type)}>
              {item.account}
            </ListLead>
          </Account>
          <VBox />
          <ToggleOptions
            ref={aggregatorsRefs[index]}
            active={index === active}
            onClick={() => {
              if (setActive) {
                setActive((prevState) =>
                  index === prevState ? undefined : index
                )
              }
            }}
            options={[
              {
                text: 'Редактировать',
                icon: 'edit',
                action: () => {
                  openAddAggregatorModalToEdit(item.id)
                  if (setActive) {
                    setActive(undefined)
                  }
                },
              },
              {
                text: 'Удалить',
                icon: 'deletion',
                critical: true,
                action: () => {
                  openDeleteAggregatorModal(item.id)
                  if (setActive) {
                    setActive(undefined)
                  }
                },
              },
            ]}
          />
        </ListItem>
      ))}
    </Container>
  )
}
