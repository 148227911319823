import { createApiReducer } from 'common/utils/reduxApiFactory'
import { parkPaymentSettingsDeleteActions } from 'api/park/payment-settings/delete/actions'
import { ParkPaymentSettingsDeleteState } from 'api/park/payment-settings/delete/types'

export const initialState: ParkPaymentSettingsDeleteState = {
  status: null,
  data: null,
}

export const parkPaymentSettingsDeleteReducer = createApiReducer(
  parkPaymentSettingsDeleteActions,
  initialState
)
