import React from 'react'
import { Icon, TitleText } from 'ui-kit/components'
import { history } from 'store'
import { RoutesPath } from 'common/enums'
import { formatPhone } from 'common/utils/formatters'
import { HBox, Row, VBox } from 'shared/ui'
import { BlockEmployeeModalContainer } from 'employees/features/employeeInfo/containers'
import { AddEmployeeModalContainer } from 'employees/features/employeeInfo/shared'
import { ContainerProps } from 'employees/features/employeeInfo/containers/EmployeeInfoContainer'
import styled from 'styled-components'

const SpaceBetweenRow = styled(Row)`
  justify-content: space-between;
`

export const EmployeeInfo: React.FC<ContainerProps> = ({
  employee,
  canChangeEmployee,
  isAddEmployeeModalOpened,
  isBlockEmployeeModalOpened,
  openAddEmployeeModal,
}) => {
  const goEmployees = () => history.push(RoutesPath.Employees)
  return (
    <>
      <Row>
        <Icon color="darkGray" name="chevronLeft" pointer onClick={goEmployees}>
          Сотрудники
        </Icon>
      </Row>
      <HBox x={0.5} />
      <SpaceBetweenRow>
        <TitleText fontWeight="bold">
          {employee?.full_name ? employee.full_name : 'Имя сотрудника'}
        </TitleText>
        {canChangeEmployee ? (
          <Icon
            name="settings"
            color="blue"
            pointer
            onClick={openAddEmployeeModal}
          >
            Настройки
          </Icon>
        ) : null}
      </SpaceBetweenRow>
      <HBox />

      <Row>
        <Icon name="user">{employee?.position}</Icon>
        <VBox x={2} />
        <Icon name="phone">
          {employee?.phone && formatPhone(employee.phone)}
        </Icon>
        <VBox x={2} />
        <Icon name="mail">{employee?.email}</Icon>
      </Row>

      {isAddEmployeeModalOpened && employee?.id ? (
        <AddEmployeeModalContainer id={employee.id} />
      ) : null}
      {isBlockEmployeeModalOpened ? <BlockEmployeeModalContainer /> : null}
    </>
  )
}
