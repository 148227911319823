import { ThunkAction } from 'redux-thunk'
import { State } from 'reducers'
import axios from 'axios'
import { FailureError } from 'common/utils/reduxApiFactory'
import { TApiError } from 'api/types'
import { postModulActions as actions } from 'api/park/modul/post/actions'
import { ModulAuth } from 'api/park/modul/types'
import { PostModulActions } from 'api/park/modul/post/types'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  PostModulActions
>

export const postModulRequest = (params: ModulAuth): THandleRequest => async (
  dispatch
) => {
  dispatch(actions.start())
  try {
    await axios.post('erp/1/park/modul', params)
    dispatch(actions.success())
  } catch (e) {
    if (e.response) {
      const data = e.response.data as TApiError
      const error: FailureError = new Error(data.message)
      error.data = data
      dispatch(actions.failure(error))
    }
    throw e
  }
}
