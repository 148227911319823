import { combineReducers } from 'redux'

import { PostReportActions, PostReportState } from 'api/report/post/types'
import { postReportReducer } from 'api/report/post/reducer'
import { postReportRequest } from 'api/report/post/thunks'
import * as postReportSelectors from 'api/report/post/selectors'

export interface ReportState {
  postReport: PostReportState
}

export type ReportAction = PostReportActions

export const reportReducer = combineReducers({
  postReport: postReportReducer,
})

export const reportActions = {
  postReport: {
    handleRequest: postReportRequest,
  },
}

export const reportSelectors = {
  postReport: postReportSelectors,
}
