import { createApiReducer } from 'common/utils/reduxApiFactory'
import { postDepositHistoryActions } from 'api/fuel/deposit-history/post/actions'
import { PostDepositHistoryState } from 'api/fuel/deposit-history/post/types'

export const initialState: PostDepositHistoryState = {
  status: null,
  data: null,
}

export const postDepositHistoryReducer = createApiReducer(
  postDepositHistoryActions,
  initialState
)
