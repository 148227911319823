import React from 'react'

import { Image } from 'shared/ui'
import driverSrc from 'ui-kit/static/placeholders/driver.svg'
import walletSrc from 'ui-kit/static/placeholders/wallet.svg'
import employeeShockedSrc from 'ui-kit/static/placeholders/employee-shocked.svg'

import {
  TextWrapper,
  Wrapper,
} from 'shared/ui/listPlaceholder/components/styles'
import { ColorTypes } from 'ui-kit'

type PlaceholderImageName = 'driver' | 'wallet' | 'employee-shocked' | undefined

interface Props {
  className?: string
  image?: PlaceholderImageName
  imageHeight?: string
  textColor?: ColorTypes
  textContainerWidth?: string
}

function getSrc(type: PlaceholderImageName): string {
  switch (type) {
    case 'driver':
      return driverSrc
    case 'employee-shocked':
      return employeeShockedSrc
    case 'wallet':
      return walletSrc
    default:
      return walletSrc
  }
}

export const ListPlaceholder: React.FC<Props> = ({
  className,
  children,
  image,
  imageHeight = '170px',
  textColor = 'gray7',
  textContainerWidth = '472px',
}) => (
  <Wrapper className={className}>
    <TextWrapper textContainerWidth={textContainerWidth} textColor={textColor}>
      {children}
    </TextWrapper>
    <Image
      src={getSrc(image)}
      alt={image}
      height={imageHeight}
      objectFit="none"
    />
  </Wrapper>
)
