import styledNormalize from 'styled-normalize'
import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  ${styledNormalize};

  * {
    box-sizing: border-box;
  }
  html, body {
      height: 100%;
  }
  body {
    background-color: ${({ theme }) => theme.pallete.secondary};
  }
  #root {
      height: 100%;
      overflow: auto;
  }
  a {
    color: ${({ theme }) => theme.pallete.blue};
  }
`
