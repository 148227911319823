import { ThunkAction } from 'redux-thunk'
import { State } from 'reducers'
import axios, { AxiosResponse } from 'axios'
import { FailureError } from 'common/utils/reduxApiFactory'
import { TApiError } from 'api/types'
import { patchTinkoffActions as actions } from 'api/park/tinkoff/patch/actions'
import { TinkoffAuth } from 'api/park/tinkoff/types'
import { PatchTinkoffActions } from 'api/park/tinkoff/patch/types'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  PatchTinkoffActions
>

export const patchTinkoffRequest = (
  updates: Partial<TinkoffAuth>
): THandleRequest => async (dispatch) => {
  dispatch(actions.start())
  try {
    const response: AxiosResponse<TinkoffAuth> = await axios.patch(
      'erp/1/park/tinkoff',
      updates
    )
    dispatch(actions.update(response.data))
    dispatch(actions.success())
  } catch (e) {
    if (e.response) {
      const data = e.response.data as TApiError
      const error: FailureError = new Error(data.message)
      error.data = data
      dispatch(actions.failure(error))
    }
    throw e
  }
}
