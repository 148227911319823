import React, { useCallback, useMemo, useState } from 'react'
import { CaptionText, ColorButton, Icon } from 'ui-kit/components'
import { HBox, VBox } from 'shared/ui/spacers'

type TextType = 'success' | 'default'
type TextValues = {
  [key in TextType]: React.ReactNode
}
type TextStrategy = {
  getText: (textType: TextType) => TextValues
}
type ReportSubmitButtonProps = {
  email: string
  isDisabled?: boolean
  onSubmit: () => Promise<void>
}
export const ReportSubmitButton = React.memo<ReportSubmitButtonProps>(
  ({ email, onSubmit, isDisabled: disabled }) => {
    const [asyncState, setAsyncState] = useState({
      loading: false,
      success: false,
    })
    const [textType, setTextType] = useState<TextType>('default')

    const asDefaultRender = useMemo(() => {
      const values = {
        default: `Отправить на ${email}`,
        success: <Icon name="check" color="textPrimary" />,
      } as TextValues

      return {
        getText: (newTextType: TextType) => values[newTextType],
      } as TextStrategy
    }, [email])

    const asAlreadySentRender = useMemo(() => {
      const values = {
        default: (
          <>
            <Icon name="update" color="surfaceLightPrimary" />
            <VBox x={1 / 3} />
            Отправить еще раз
          </>
        ),
        success: <Icon name="check" color="textPrimary" />,
      } as TextValues

      return {
        getText: (newTextType: TextType) => values[newTextType],
      } as TextStrategy
    }, [])

    const textRenderer = useMemo(() => {
      let strategy = asDefaultRender
      return {
        setStrategy: (newStrategy: TextStrategy) => {
          strategy = newStrategy
        },
        getText: (newTextType: TextType) => strategy.getText(newTextType),
      }
    }, [asDefaultRender])

    const showSuccess = useCallback((time: number) => {
      let timeout: number

      return new Promise((resolve) => {
        clearTimeout(timeout)
        setTextType('success')
        timeout = setTimeout(() => {
          setTextType('default')
          resolve(null)
        }, time)
      })
    }, [])

    const handleClick = useCallback(async () => {
      setAsyncState({ loading: true, success: false })
      try {
        await onSubmit()
        textRenderer.setStrategy(asAlreadySentRender)
        setAsyncState({ loading: false, success: true })
        await showSuccess(3000)
      } catch (e) {
        setAsyncState({ loading: false, success: false })
      }
    }, [asAlreadySentRender, onSubmit, showSuccess, textRenderer])

    const isDisabled = disabled || textType !== 'default' || asyncState.loading

    return (
      <>
        <ColorButton
          loaderColor="textDisabled"
          color={isDisabled ? 'gray3' : 'accentPrimary'}
          textColor={isDisabled ? 'textPrimary' : 'surfaceLightPrimary'}
          onClick={handleClick}
          fullWidth
          disabled={isDisabled}
          isLoading={asyncState.loading}
        >
          {textRenderer.getText(textType)}
        </ColorButton>
        {asyncState.success && (
          <>
            <HBox x={1 / 3} />
            <CaptionText>
              Когда отчет будет готов, отправим на почту{' '}
              <strong>{email}.</strong>
              <br />
              Это может занять несколько минут.
            </CaptionText>
          </>
        )}
      </>
    )
  }
)
ReportSubmitButton.displayName = 'ReportSubmitButton'
