import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 80px;
  border-bottom: 0.5px solid ${({ theme }) => theme.pallete.gray3};
  padding: 4px 16px 3.5px;
  box-sizing: border-box;
`
