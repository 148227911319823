import React from 'react'

import { Icon } from 'ui-kit/components'

import { VBox } from 'shared/ui'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.pallete.white};
  min-height: 100vh;
  height: 100%;
  justify-content: space-between;

  &.right-side-big-picture {
    .right-side-wrapper {
      padding-top: 112px;
    }
  }
`

const Left = styled.div`
  width: calc(100% - 560px);
  height: 100vh;
  overflow-y: auto;
`

const Right = styled.div<{ rightWidth?: number }>`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 146px;
  width: ${({ rightWidth }) =>
    `${rightWidth !== undefined ? rightWidth : 676}px`};
  background-color: ${({ theme }) => theme.pallete.black};
`

const Logo = styled.div`
  display: flex;
  padding-left: 100px;
  align-items: center;
`

interface Props {
  leftSideContent: string | number | JSX.Element
  rightSideContent: string | number | JSX.Element
  className?: string
  withoutLogo?: boolean
  rightWidth?: number
}

export const AuthLayout: React.FC<Props> = ({
  leftSideContent,
  rightSideContent,
  className,
  rightWidth,
  withoutLogo,
}) => (
  <Wrapper className={className}>
    <Left>{leftSideContent}</Left>
    <Right rightWidth={rightWidth} className="right-side-wrapper">
      {!withoutLogo ? (
        <Logo>
          <Icon name="mozenWhite" />
          <VBox x={0.5} />
          <Icon name="mozenText" />
        </Logo>
      ) : null}
      {rightSideContent}
    </Right>
  </Wrapper>
)
