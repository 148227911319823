import React from 'react'
import styled from 'styled-components'
import { ColorButton } from 'ui-kit/components/Buttons'
import DateRangePicker from 'ui-kit/molecules/dateRangePicker/DateRangePicker'
import Card from 'ui-kit/atoms/Card/Card'
import IncomeInfo, { IncomeDataSet } from 'ui-kit/molecules/IncomeInfo'
import Typography from 'ui-kit/typography'

type DateRange = {
  from: Date
  to: Date
}

type Props = {
  initialRange?: DateRange
  onUpdate: (range: DateRange) => Promise<void>
  data?: IncomeDataSet | null
  isDataValidating?: boolean
  onBid: () => Promise<void>
}

const to = new Date()
to.setDate(to.getDate() - 3)
const from = new Date(to)
from.setDate(from.getDate() - 30)

const minDate = new Date()
minDate.setMonth(minDate.getMonth() - 12)

const Action = styled.div`
  padding: 0 16px 16px;
`

const Content = styled.div`
  padding: 0 16px 16px;
`

const Header = styled(Typography.withComponent('h1')).attrs({
  fontSize: 16,
  fontWeight: 'SemiBold',
})`
  margin: 0;
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 16px;
  min-height: 56px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
`

const HelpText = styled(Typography.withComponent('div')).attrs({
  variant: 'Caption1',
})`
  padding-top: 8px;
`

export const IncomeStatement = ({
  isDataValidating,
  data,
  onUpdate,
  initialRange = { from, to },
  onBid,
}: Props) => {
  const [range, setRange] = React.useState(initialRange)
  const [isLongUpdate, setIsLongUpdate] = React.useState(false)

  const timerHandler = React.useRef<number>()
  React.useEffect(() => {
    timerHandler.current = setTimeout(() => {
      if (isDataValidating && !isLongUpdate) {
        setIsLongUpdate(true)
      }
    }, 3000)

    if (!isDataValidating && isLongUpdate) {
      setIsLongUpdate(false)
    }

    return () => {
      if (timerHandler.current) {
        clearTimeout(timerHandler.current)
      }
    }
  }, [isLongUpdate, isDataValidating])

  const handleClick = async () => {
    await onUpdate(range)
  }

  const handleChangeDate = (newFrom: Date, newTo: Date) => {
    setRange({ from: newFrom, to: newTo })
  }

  return (
    <Card>
      <HeaderWrapper>
        <Header>Начисления и списания</Header>
      </HeaderWrapper>
      <Action>
        <Typography variant="Caption1" color="secondaryDarkBlue">
          Период
        </Typography>
        <DateRangePicker
          hasCloseButton={false}
          dateTo={range.to}
          dateFrom={range.from}
          minDate={minDate}
          maxDate={to}
          onChangeDate={handleChangeDate}
        />
        <ColorButton
          fontWeight="semi-bold"
          isLoading={isDataValidating}
          disabled={isDataValidating}
          onClick={handleClick}
          fullWidth
        >
          {data ? 'Сформировать новый отчет' : 'Сформировать отчет'}
        </ColorButton>
        {isLongUpdate && (
          <HelpText>
            Собираем данные. Это может занять до минуты. Можете продолжать
            пользоваться ERP, мы сообщим, когда отчет будет готов.
          </HelpText>
        )}
      </Action>
      {data && (
        <Content>
          <IncomeInfo data={data} onClick={onBid} />
        </Content>
      )}
    </Card>
  )
}

export default IncomeStatement
