import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'

import { State, TRootAction } from 'reducers'
import { apiActions, apiSelectors } from 'api'
import { AggregatorsTab } from 'settings/features/aggregatorsTab/organisms'

const mapStateToProps = (state: State) => ({
  isFetching: apiSelectors.park.aggregators.getIsFetching(state),
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<State, undefined, TRootAction>
) => ({
  fetchAggregators: () => dispatch(apiActions.park.aggregators.handleRequest()),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const AggregatorsTabContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AggregatorsTab)
