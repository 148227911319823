import React from 'react'
import Typography from 'ui-kit/typography'
import Mark from 'ui-kit/atoms/Mark/Mark'
import ListItem from 'ui-kit/atoms/ListItem/ListItem'

type Props = {
  icon?: React.ReactNode
  caption: string
  children: React.ReactNode
}

const Item = ({ icon = null, caption, children }: Props) => {
  return (
    <ListItem icon={icon}>
      <Mark caption={caption}>
        <Typography variant="Body1">{children}</Typography>
      </Mark>
    </ListItem>
  )
}

export default React.memo<Props>(Item)
