import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Field } from 'redux-form'
import { difference, isEqual } from 'lodash'

import { BodyText, Checkbox, Icon, ListGroup } from 'ui-kit/components'
import {
  Permission,
  PermissionGroup,
  permissionsByTemplate,
  PermissionTemplate,
  permissionToString,
  templateOptions,
} from 'common/enums'
import { Option } from 'common/types/local/option'
import FormCheckbox from 'common/components/formItems/Checkbox'
import { HBox, Select } from 'shared/ui'
import styled from 'styled-components'

const Container = styled.div`
  padding: 0 16px;

  label {
    height: 56px;
  }
`

const Content = styled.div`
  .muk-checkbox-wrapper {
    width: 100%;
  }

  .muk-checkbox-label {
    font-size: 14px;
    line-height: 19px;
  }

  .group-title {
    .muk-checkbox-wrapper {
      .muk-checkbox-mark {
        background-color: #c0cee2;
      }

      input:checked ~ .muk-checkbox-mark {
        background-color: #2979ff;
      }
    }
  }
`

const DisablingContainer = styled.div`
  position: relative;
`
const DisablingLayer = styled.div<{ isDisabled: boolean }>`
  position: absolute;

  ${({ theme, isDisabled }) =>
    isDisabled &&
    `
    width: 100%;
    height: 100%;
    background-color: ${theme.pallete.white};
    opacity: 0.5;
    z-index: 1;
  `}
`

type Props = {
  permissions: Set<string>
  setPermissions: Dispatch<SetStateAction<Set<string>>>
  setEditingRights: Dispatch<SetStateAction<boolean>>
  canChangePermissions?: boolean
}

export const PermissionsLayout: React.FC<Props> = ({
  permissions,
  setPermissions,
  setEditingRights,
  canChangePermissions,
}) => {
  const [template, setTemplate] = useState<Option<PermissionTemplate>>(
    templateOptions[0]
  )

  const handleChangeTemplate = useCallback(
    (option: Option<PermissionTemplate>): void => {
      setTemplate(option)
      if (permissionsByTemplate[option.value]) {
        setPermissions(new Set(permissionsByTemplate[option.value]))
      }
    },
    [setPermissions]
  )

  const handleSetTemplate = useCallback(
    (temp: Set<string>): void => {
      const option = templateOptions.find((item: Option<PermissionTemplate>) =>
        isEqual(temp, new Set(permissionsByTemplate[item.value]))
      )
      handleChangeTemplate(option || templateOptions[0])
    },
    [handleChangeTemplate]
  )

  const handleChangePermission = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const temp = new Set([...Array.from(permissions)])

    if (e.currentTarget.checked) {
      if (PermissionGroup[e.currentTarget.name]) {
        PermissionGroup[e.currentTarget.name].forEach((value: Permission) => {
          temp.add(value)
        })
      } else {
        temp.add(e.currentTarget.name)
      }
    } else if (PermissionGroup[e.currentTarget.name]) {
      PermissionGroup[e.currentTarget.name].forEach((value: Permission) => {
        temp.delete(value)
      })
    } else {
      temp.delete(e.currentTarget.name)
    }

    setPermissions(temp)
    handleSetTemplate(temp)
  }

  useEffect(() => {
    handleSetTemplate(permissions)
    // TODO: Подумать как убрать этот хук - при добавлении зависимостей
    //  происходит бесконечный рендер
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const permissionsArray = useMemo(() => {
    return Array.from(permissions) as Permission[]
  }, [permissions])

  const isChecked = useCallback(
    (field: Permission) => !!permissionsArray.find((item) => item === field),
    [permissionsArray]
  )

  const renderGroup = (name: string, title: string): JSX.Element => (
    <>
      <ListGroup className="group-title">
        <Checkbox
          name={name}
          checked={
            !difference(
              PermissionGroup[name],
              Array.from(permissions) as Permission[]
            ).length
          }
          disabled={!canChangePermissions}
          onChange={handleChangePermission}
        >
          {title}
        </Checkbox>
      </ListGroup>
      <Container>
        {PermissionGroup[name].map(
          (item): JSX.Element => (
            <Field
              key={item}
              name={item}
              onChange={handleChangePermission}
              component={FormCheckbox}
              disabled={!canChangePermissions}
              checked={isChecked(item)}
            >
              {permissionToString(item)}
            </Field>
          )
        )}
      </Container>
      <HBox x={0.5} />
    </>
  )

  return (
    <Content>
      <HBox x={0.5} />
      <Container>
        <Icon
          pointer
          color="blue"
          name="chevronLeft"
          onClick={() => setEditingRights(false)}
        >
          Назад
        </Icon>
        <HBox />
        <BodyText color="gray6">Использовать настройки доступа:</BodyText>
        <HBox x={0.25} />
        <Select
          name="template"
          value={template}
          options={templateOptions}
          isClearable={false}
          isDisabled={!canChangePermissions}
          menuStyles={{ right: '16px', width: '300px' }}
          onChange={handleChangeTemplate}
        />
        <HBox x={0.25} />
      </Container>

      <DisablingContainer>
        <DisablingLayer isDisabled={!canChangePermissions} />
        {renderGroup('system', 'Настройки')}
        {renderGroup('drivers', 'Водители')}
        {renderGroup('payments', 'Платежи')}
        {renderGroup('employees', 'Сотрудники')}
        {renderGroup('fuel', 'Топливо')}
      </DisablingContainer>
    </Content>
  )
}
