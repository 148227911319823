import axios, { AxiosResponse } from 'axios'
import { BaseAction } from 'redux-actions'
import { ThunkAction } from 'redux-thunk'

import { State } from 'reducers'
import { DriversContractsUploadResponse } from 'api/types'
import {
  failure,
  start,
  success,
  update,
} from 'api/drivers/contracts/upload/actions'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  BaseAction
>
export const HANDLE_REQUEST: BaseAction['type'] =
  'api/drivers/contracts/upload->HANDLE_REQUEST'

export const handleRequest = (formData: FormData): THandleRequest => async (
  dispatch
) => {
  dispatch(start())
  try {
    const response: AxiosResponse<DriversContractsUploadResponse> = await axios.post(
      'erp/1/drivers/contracts/upload',
      formData
    )
    dispatch(update(response.data))
    dispatch(success())
  } catch (e) {
    if (e.response) {
      dispatch(failure(e.response.data))
    }
    throw e
  }
}
