import {
  checkSpacesPattern,
  datePattern,
  driverContractPattern,
  emailPattern,
  integerPattern,
  namePattern,
  phonePattern,
  uzaPattern,
} from 'common/utils/patterns'

const requiredText = 'Обязательное поле'
const wrongFormatText = 'Неверный формат'

export const required = (value: any) => {
  let result = value

  if (result === 0) {
    return
  }

  if (typeof result === 'string') {
    result = value.trim()
  }

  if (!result) {
    return requiredText
  }
}

export const comparePasswords = (
  value: string,
  allValues: { newPassword: string }
) => (value !== allValues.newPassword ? 'Пароли не совпадают' : undefined)

const maxLength = (max: number) => (value: string) =>
  value && (value || value.length !== 0) && value.length > max
    ? `Максимум ${max} символов`
    : undefined

export const maxLength9 = maxLength(9)
export const maxLength12 = maxLength(12)
export const maxLength13 = maxLength(13)
export const maxLength15 = maxLength(15)
export const maxLength20 = maxLength(20)
export const maxLength30 = maxLength(30)
export const maxLength50 = maxLength(50)
export const maxLength255 = maxLength(255)

const minLength = (min: number) => (value: string) =>
  value && value.length < min ? `Минимум ${min} символов` : undefined

export const minLength3 = minLength(3)
export const minLength6 = minLength(6)
export const minLength9 = minLength(9)
export const minLength10 = minLength(10)
export const minLength13 = minLength(13)
export const minLength17 = minLength(17)
export const minLength20 = minLength(20)
export const minLength240 = minLength(240)

export const email = (value: string) =>
  !!value && !emailPattern.test(value) ? wrongFormatText : undefined

export const phone = (value: string) =>
  !!value && !phonePattern.test(value) ? wrongFormatText : undefined

export const integer = (value: number) =>
  !!value && !integerPattern.test(value.toString()) ? 'Только цифры' : undefined

export const checkSpaces = (value: string) =>
  !!value && !checkSpacesPattern.test(value) ? wrongFormatText : undefined

export const moreThan = (value: number | string, limit: number) =>
  !!value && Number(value) <= limit
    ? `Значение должно быть больше ${limit}`
    : undefined

export const noMoreThan = (value: number | string, limit: number) =>
  !!value && Number(value) > limit
    ? `Значение не должно быть больше ${limit}`
    : undefined

export const moreThanZero = (value: number | string) => moreThan(value, 0)

export const checkboxRequired = (value: boolean) => !value

export const date = (value: string) =>
  !!value && !datePattern.test(value) ? wrongFormatText : undefined

export const todayOrBefore = (value: Date) => {
  return !!value && Number(new Date(value)) > Number(new Date())
    ? 'Дата не может быть установлена в будущем'
    : undefined
}

export const driverContract = (value: string) =>
  !!value && !driverContractPattern.test(value) ? wrongFormatText : undefined

export const name = (value: string) =>
  !!value && !namePattern.test(value) ? wrongFormatText : undefined

export const uza = (value: string) =>
  !!value && !uzaPattern.test(value) ? wrongFormatText : undefined
