import React from 'react'

import { ContentCard, ContentCardFooter, ContentCardHeader } from 'shared/ui'
import {
  AddAggregatorDropdown,
  EmptyListing,
  Listing,
} from 'settings/features/aggregatorsList/molecules'
import {
  AddAggregatorModalContainer,
  DeleteAggregatorModalContainer,
} from 'settings/features/aggregatorsList/containers'
import { ContainerProps } from 'settings/features/aggregatorsList/containers/AggregatorsListContainer'

export const AggregatorsList: React.FC<ContainerProps> = ({
  aggregators,
  isAddAggregatorModalOpened,
  isDeleteAggregatorModalOpened,
  openAddAggregatorModal,
  openAddAggregatorModalToEdit,
  openDeleteAggregatorModal,
}) => (
  <>
    <ContentCard>
      <ContentCardHeader title="Аккаунты автопарка">
        {!aggregators.length ? (
          <AddAggregatorDropdown
            right
            openAddAggregatorModal={openAddAggregatorModal}
          />
        ) : null}
      </ContentCardHeader>

      {!aggregators.length ? (
        <EmptyListing />
      ) : (
        <>
          <Listing
            aggregators={aggregators}
            openAddAggregatorModalToEdit={openAddAggregatorModalToEdit}
            openDeleteAggregatorModal={openDeleteAggregatorModal}
          />
          <ContentCardFooter border={false}>
            <AddAggregatorDropdown
              openAddAggregatorModal={openAddAggregatorModal}
            />
          </ContentCardFooter>
        </>
      )}
    </ContentCard>

    {isAddAggregatorModalOpened ? <AddAggregatorModalContainer /> : null}
    {isDeleteAggregatorModalOpened ? <DeleteAggregatorModalContainer /> : null}
  </>
)
