import React, { useEffect } from 'react'

import { BodyText, Icon, TitleText } from 'ui-kit/components'

import { history } from 'store'
import {
  AggregatorType,
  aggregatorTypeToString,
  RoutesPath,
} from 'common/enums'
import { useStepsContext } from 'shared/hooks'
import { Dropdown, HBox, VBox } from 'shared/ui'
import { list } from 'steps/data'
import { StepsActionType } from 'steps/types'
import { AddCard, RegularCard, SkipButton } from 'steps/features/content'
import { AddAggregatorModalContainer } from 'steps/features/stepAggregators/shared'

import { ContainerProps } from 'steps/features/stepAggregators/containers/StepAggregatorsContainer'
import styled from 'styled-components'
import yandexSrc from 'static/icons/yandex.svg'
import citymobilSrc from 'static/icons/citymobil.svg'
import didiSrc from 'ui-kit/static/icons/di-di.svg'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 563px;
`

const CardsTiles = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
`
const CardTile = styled.div`
  width: 50%;
  min-height: 152px;
  padding: 8px;
`

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`

function getSrc(type: AggregatorType): string {
  switch (type) {
    case AggregatorType.Citymobil:
      return citymobilSrc
    case AggregatorType.Yandex:
      return yandexSrc
    case AggregatorType.DiDi:
      return didiSrc
    default:
      return yandexSrc
  }
}

export const StepAggregators: React.FC<ContainerProps> = ({
  aggregators,
  isAddAggregatorModalOpened,
  fetchAggregators,
  openAddAggregatorModal,
}) => {
  const { stepsDispatch } = useStepsContext()
  useEffect(() => {
    stepsDispatch({
      type: StepsActionType.SET_CURRENT_STEP,
      payload: { current: list.aggregators },
    })

    fetchAggregators()
  }, [fetchAggregators, stepsDispatch])

  const handleSkip = (): void => {
    history.replace(RoutesPath.StepsPayments)
  }

  return (
    <>
      <Wrapper>
        <TitleText fontWeight="semi-bold" className="form-title">
          Подключите агрегаторы
        </TitleText>
        <HBox />
        <BodyText color="gray9">
          Чтобы видеть работающих водителей, получать информацию по поездкам и
          статистику по финансам, подключите аккаунты вагрегаторов.
        </BodyText>
        <HBox x={2} />
        <CardsTiles>
          {aggregators.map((item) => (
            <CardTile key={item.id}>
              <RegularCard
                avatar={getSrc(item.type)}
                account={item.account}
                type={aggregatorTypeToString(item.type)}
              />
            </CardTile>
          ))}

          <CardTile>
            <Dropdown
              data={[
                {
                  label: 'Яндекс.Такси/Uber',
                  iconName: 'yandex',
                  function: () => openAddAggregatorModal(AggregatorType.Yandex),
                },
                {
                  label: 'Ситимобил',
                  iconName: 'citymobil',
                  function: () =>
                    openAddAggregatorModal(AggregatorType.Citymobil),
                },
                // {
                //   label: 'DiDi',
                //   iconName: 'DiDi',
                //   function: () => openAddAggregatorModal(AggregatorType.DiDi),
                // },
              ]}
              component={({ isExpanded }) => (
                <AddCard
                  multiAdd
                  addText="Добавить агрегатор"
                  isExpanded={isExpanded}
                />
              )}
            />
          </CardTile>
        </CardsTiles>

        <HBox x={2} />
        <Actions>
          <VBox />
          {aggregators.length === 0 ? (
            <SkipButton
              onClick={handleSkip}
              tipText="Вы можете пропустить этот шаг и авторизовать агрегаторы позже в разделе &laquo;Настройки/Агрегаторы&raquo;"
            >
              Пропустить
            </SkipButton>
          ) : (
            <Icon
              name="arrowRight"
              color="blue"
              reverse
              pointer
              onClick={handleSkip}
            >
              Продолжить
            </Icon>
          )}
        </Actions>
      </Wrapper>
      {isAddAggregatorModalOpened ? <AddAggregatorModalContainer /> : null}
    </>
  )
}
