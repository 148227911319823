import { apiActions, apiSelectors } from 'api'
import { MyThunkDispatch } from 'api/reducer'
import {
  ReferralOffer,
  ReferralOfferPost,
  ReferralOfferResponseSchema,
} from 'api/referral/offer/types'
import { ProgramStates } from 'elastic-ui/referral-program/organisms/DescriptionAndActionsPanel/types'
import {
  InviterBonusFrequencies,
  InviterBonusTypes,
} from 'elastic-ui/referral-program/organisms/TermsEdit/types'
import {
  ProgramSettings,
  ProgramTerms,
  ReportPeriod,
} from 'elastic-ui/referral-program/types'
import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { State } from 'reducers'
import Alert from 'react-s-alert'
import { ApiCode } from 'common/types/api/codes'
import { ReferralProgram } from '../pages/ReferralProgram/ReferralProgram'
import mapper from './mapper'
import reverseMapper from './reverseMapper'

const mapStateToProps = (state: State) => ({
  aggregators: apiSelectors.park.aggregators.getAggregators(state),
  referralInvites: apiSelectors.referral.postInviteList.selectReferralInviteList(
    state
  ),
  referralOffer: apiSelectors.referral.offers.selectReferralOffer(state),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  fetchApplications: () =>
    dispatch(apiActions.referral.postInviteList.handleRequest()),
  updateOffers: (response: ReferralOfferResponseSchema) =>
    dispatch(apiActions.referral.offers.update(response)),
  getOffers: () => dispatch(apiActions.referral.offers.handleGet()),
  patchOffers: (settings: ReferralOffer) =>
    dispatch(apiActions.referral.offers.handlePatch(settings)),
  postOffers: (settings: ReferralOfferPost) =>
    dispatch(apiActions.referral.offers.handlePost(settings)),
  deleteOffer: (id: number) =>
    dispatch(apiActions.referral.offers.handleDelete(id)),
  fetchAggregators: () => dispatch(apiActions.park.aggregators.handleRequest()),
  downloadFinantialReport: (period: ReportPeriod) =>
    dispatch(
      apiActions.report.postReport.handleRequest(
        period.from,
        period.to,
        'referral'
      )
    ),
})

type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const temporaryDefaultTerms: ProgramTerms = {
  fullDescription: '',
  shortDescription: '',
  title: '',
}

const temporaryDefaultSettings: ProgramSettings = {
  bonusAmount: 1500,
  dontPayIfInviterIsNotTakingDrives: false,
  id: -1,
  inviterBonusFrequency: InviterBonusFrequencies.Daily,
  inviterBonusType: InviterBonusTypes.FixedBonus,
  selectedAggregatorAccounts: [],
}

const error = (msg = 'Не удалось совершить запрос') => {
  Alert.error('Ошибка', {
    customFields: {
      text: msg,
    },
  })
}

const ReferralProgramContainer = ({
  referralInvites,
  fetchApplications,
  getOffers,
  referralOffer,
  aggregators,
  fetchAggregators,
  patchOffers,
  postOffers,
  updateOffers,
  deleteOffer,
  downloadFinantialReport,
}: Props) => {
  const [isDownloadReportLoading, setIsDownloadReportLoading] = useState(false)

  const [
    isTelegramNotificationsLoading,
    setIsTelegramNotificationsLoading,
  ] = useState(false)

  const [isEditingTerms, setIsEditingTerms] = useState(false)

  const [isProgramStatusLoading, setIsProgramStatusLoading] = useState(false)
  const [isTermsLoading, setIsTermsLoading] = useState(false)

  const [isApplicationsTableLoading, setIsApplicationsTableLoading] = useState(
    true
  )

  const {
    applications,
    programSettings,
    programState,
    programTerms,
    aggregatorAccounts,
    telegramChatId,
  } = mapper({
    referralInvites,
    referralOffer,
    aggregators,
  })

  const isFirstTime = programState === ProgramStates.FirstTime
  const isTelegramNotificationsConnected = !!telegramChatId

  const tryGetAndUpdateOffers = useCallback(async () => {
    fetchAggregators()
    try {
      const offers = await getOffers()
      updateOffers(offers)
    } catch (e) {
      const { message, code } = e?.response?.data
      if (code === ApiCode.ReferralOfferNotFound) {
        updateOffers({
          offer: [
            {
              full_description: '',
              id: -1,
              inviter_reward_period_type: 'once',
              inviter_reward_type: 'amount_per_ride',
              inviter_reward_value: 150000,
              short_description: '',
              title: '',
              visible: undefined as any,
            },
          ],
        })
      } else {
        error(message)
      }
    }
  }, [updateOffers, getOffers, fetchAggregators])

  const tryGetApplications = useCallback(async () => {
    try {
      await fetchApplications()
    } catch (e) {
      const message = e?.response?.data?.message
      error(message)
    }
  }, [fetchApplications])

  useEffect(() => {
    setIsProgramStatusLoading(true)
    setIsTermsLoading(true)
    setIsTelegramNotificationsLoading(true)
    setIsApplicationsTableLoading(true)

    tryGetApplications().finally(() => {
      setIsApplicationsTableLoading(false)
    })

    tryGetAndUpdateOffers().finally(() => {
      setIsTermsLoading(false)
      setIsTelegramNotificationsLoading(false)
      setIsProgramStatusLoading(false)
    })
  }, [tryGetApplications, tryGetAndUpdateOffers])

  const onSubscribeToTelegramNotificationsClick = async (key: string) => {
    setIsTelegramNotificationsLoading(true)

    const reverseMap = reverseMapper({
      settings: programSettings as any,
      state: programState,
      telegramChatId: key,
    })

    try {
      const offer = await patchOffers(reverseMap)
      updateOffers({ offer: [offer] })
    } catch (e) {
      const message = e?.response?.data?.message
      error(message)
    }

    setIsTelegramNotificationsLoading(false)
  }

  const onCancelTelegramSubscription = async () => {
    setIsTelegramNotificationsLoading(true)
    const reverseMap = reverseMapper({
      settings: programSettings as any,
      state: programState,
      telegramChatId: undefined,
    })

    try {
      const offer = await patchOffers(reverseMap)
      updateOffers({ offer: [offer] })
    } catch (e) {
      const message = e?.response?.data?.message
      error(message)
    }

    setIsTelegramNotificationsLoading(false)
  }

  const onDownloadReportClick = async (period: ReportPeriod) => {
    setIsDownloadReportLoading(true)

    try {
      await downloadFinantialReport(period)
      Alert.info('Отправлено!', {
        customFields: {
          text: 'Проверьте вашу почту',
        },
      })
    } catch (e) {
      const message = e?.response?.data?.message
      error(message)
    }

    setIsDownloadReportLoading(false)
  }

  // The feature is uncertain. Might be added back.
  const onApplicationDeleteClick = async () => {}

  const onCancelEditingTermsClick = () => setIsEditingTerms(false)

  const onEditTermsClick = () => setIsEditingTerms(true)

  const onUpdateTermsClick = async (settings: ProgramSettings) => {
    setIsTermsLoading(true)

    try {
      const reverseMap = reverseMapper({
        settings,
        state: programState,
        telegramChatId,
      })

      if (isFirstTime) {
        setIsProgramStatusLoading(true)
        setIsApplicationsTableLoading(true)
        const offer = await postOffers(reverseMap)
        updateOffers({ offer: [offer] })
        setIsProgramStatusLoading(false)
        setIsApplicationsTableLoading(false)
      } else {
        const offer = await patchOffers(reverseMap)
        updateOffers({ offer: [offer] })
      }

      setIsEditingTerms(false)
    } catch (e) {
      const message = e?.response?.data?.message
      error(message)
    }

    setIsTermsLoading(false)
  }

  const onHideFromDriversClick = async () => {
    setIsProgramStatusLoading(true)

    try {
      const reverseMap = reverseMapper({
        settings: programSettings as any,
        state: ProgramStates.Hidden,
        telegramChatId,
      })

      const offer = await patchOffers(reverseMap)
      updateOffers({ offer: [offer] })
    } catch (e) {
      const message = e?.response?.data?.message
      error(message)
    }

    setIsProgramStatusLoading(false)
  }

  const onShowToDriversClick = async () => {
    setIsProgramStatusLoading(true)

    try {
      const reverseMap = reverseMapper({
        settings: programSettings as any,
        state: ProgramStates.Active,
        telegramChatId,
      })

      const offer = await patchOffers(reverseMap)
      updateOffers({ offer: [offer] })
    } catch (e) {
      const message = e?.response?.data?.message
      error(message)
    }

    setIsProgramStatusLoading(false)
  }

  const onStopProgramClick = async (id: number) => {
    setIsTermsLoading(true)
    setIsProgramStatusLoading(true)
    setIsApplicationsTableLoading(true)

    try {
      await deleteOffer(id)
      await tryGetAndUpdateOffers()
    } catch (e) {
      const message = e?.response?.data?.message
      error(message)
    }

    setIsTermsLoading(false)
    setIsProgramStatusLoading(false)
    setIsApplicationsTableLoading(false)
  }

  const onStartProgramClick = async () => {}

  return (
    <ReferralProgram
      aggregatorAccounts={aggregatorAccounts || []}
      programSettings={programSettings || temporaryDefaultSettings}
      programTerms={programTerms || temporaryDefaultTerms}
      programState={programState || ProgramStates.Hidden}
      applications={applications || []}
      company=""
      isProgramStatusLoading={isProgramStatusLoading}
      isTermsLoading={isTermsLoading}
      isFirstTime={isFirstTime}
      isApplicationsTableLoading={isApplicationsTableLoading}
      isDownloadReportLoading={isDownloadReportLoading}
      isTelegramNotificationsLoading={isTelegramNotificationsLoading}
      isTelegramNotificationsConnected={isTelegramNotificationsConnected}
      isEditingTerms={isEditingTerms}
      onSubscribeToTelegramNotificationsClick={
        onSubscribeToTelegramNotificationsClick
      }
      onCancelTelegramSubscription={onCancelTelegramSubscription}
      onDownloadReportClick={onDownloadReportClick}
      onApplicationDeleteClick={onApplicationDeleteClick}
      onCancelEditingTermsClick={onCancelEditingTermsClick}
      onEditTermsClick={onEditTermsClick}
      onUpdateTermsClick={onUpdateTermsClick}
      onHideFromDriversClick={onHideFromDriversClick}
      onShowToDriversClick={onShowToDriversClick}
      onStopProgramClick={onStopProgramClick}
      onStartProgramClick={onStartProgramClick}
    />
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferralProgramContainer)
