import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { State } from 'reducers'
import { apiActions, apiSelectors } from 'api'
import { MyThunkDispatch } from 'api/reducer'
import { onDidMount } from 'drivers/features/driverDetails/thunks'
import { getIsFetching } from 'drivers/features/driverDetails/selectors'
import { DriverDetails } from 'drivers/features/driverDetails/organisms'

const mapStateToProps = (state: State) => ({
  isFetching: getIsFetching(state),
  info: apiSelectors.driversById.get.getInfo(state),
  hasUserId: apiSelectors.driversById.get.getHasUserId(state),
  canViewDriverRequisites: apiSelectors.employee.profile.getCanViewDriverRequisites(
    state
  ),
  canChangeAutoTransfer: apiSelectors.employee.profile.getCanChangeAutoTransfer(
    state
  ),
  canChangePaymentsConditions: apiSelectors.employee.profile.getCanChangePaymentsConditions(
    state
  ),
  canViewPaymentsSettings: apiSelectors.employee.profile.getCanViewPaymentsSettings(
    state
  ),
  bankType: apiSelectors.park.details.getBankType(state),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  onDidMount: (id: string) => dispatch(onDidMount(id)),
  reset: () => dispatch(apiActions.driversById.get.reset()),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps

export const DriverDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DriverDetails)
