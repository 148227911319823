import React from 'react'
import styled from 'styled-components'
import Typography from 'ui-kit/typography'
import { NotificationAggregatorList, PrettyDate, ViewCount } from 'ui-kit/atoms'
import { VBox } from 'ui-kit/atoms/spacers'

export type Notification = {
  id: string
  aggregators: string[]
  text: string
  date: Date
  viewCount: number
}

type Props = {
  text: string
  viewCount: number
  utcDate: string
  markedAggregators: string[]
  availableAggregators: string[]
  className?: string
}

const Wrapper = styled.div`
  padding: 16px;
`

const Header = styled.div`
  display: flex;
  margin-bottom: 8px;
`
const Body = styled.div`
  margin-bottom: 8px;
  word-break: break-word;
`

const Footer = styled.div`
  display: flex;
  flex-direction: row;
`
const HeaderPrefix = styled(Typography)`
  margin-right: 4px;
`

export const DriverNotification = ({
  text,
  viewCount,
  utcDate,
  markedAggregators,
  availableAggregators,
  className,
}: Props) => {
  return (
    <Wrapper className={className}>
      <Header>
        <HeaderPrefix fontColor="textSecondary">для</HeaderPrefix>
        <NotificationAggregatorList
          available={availableAggregators}
          marked={markedAggregators}
          labelForAll="всех"
        />
      </Header>
      <Body>
        <Typography>{text}</Typography>
      </Body>
      <Footer>
        <PrettyDate time={utcDate} />
        <VBox />
        <ViewCount value={viewCount} />
      </Footer>
    </Wrapper>
  )
}
