import { ApiActionTypes, ApiState } from 'common/utils/reduxApiFactory'

export enum BotType {
  Fuel = 'fuel_learn_more',
  Yandex = 'yandex_accounting',
}

export type PostNotifyBotParams = {
  notification_type: BotType
}

export type PostNotifyBotState = ApiState

export type PostNotifyBotActions = ApiActionTypes
