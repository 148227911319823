import { EuiSpacer, EuiFormRow, EuiText, EuiFieldText } from '@elastic/eui'
import React, { useState, useCallback } from 'react'
import { Modal } from '../../../shared/molecules/Modal/Modal'

interface Props {
  isOpen: boolean
  onClose: () => void
  onAccept: (amount: number) => void
}

export const DepositModal: React.FC<Props> = ({
  onClose,
  onAccept,
  isOpen,
}) => {
  const [valueString, setValueString] = useState('')
  const [isInvalid, setIsInvalid] = useState(false)

  const isNumberValid = useCallback(
    (number: number) => !Number.isNaN(number) && number > 0,
    []
  )

  const clearAndFocusOnRef = useCallback(
    (instance: HTMLInputElement | null) => {
      if (instance) {
        setValueString('')
        instance.focus()
      }
    },
    [setValueString]
  )

  const handleValueChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValueString(event.target.value)
      setIsInvalid(false)
    },
    []
  )

  const handleAccept = useCallback(() => {
    const isValueCorrect = !/[^0-9]/g.test(valueString)
    if (!isValueCorrect || Number.parseInt(valueString, 10) > 9999999999) {
      return setIsInvalid(true)
    }

    const number = Number.parseFloat(valueString)
    if (isNumberValid(number)) {
      setIsInvalid(false)
      onAccept(number)
    } else {
      return setIsInvalid(true)
    }
  }, [valueString, onAccept, isNumberValid])

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      title="Пополнение депозита"
      cancelLabel="Отмена"
      acceptLabel="Скачать платежку"
      acceptIcon="download"
      onCancel={onClose}
      onAccept={handleAccept}
      onClickAway={onClose}
    >
      Введите сумму и мы сформируем платежное поручение для банк-клиента.
      <EuiSpacer size="l" />
      <EuiFormRow label="Сумма">
        <EuiFieldText
          placeholder="10000"
          value={valueString}
          inputRef={clearAndFocusOnRef}
          onChange={handleValueChange}
          append={[
            <EuiText size="m" key="bro">
              ₽
            </EuiText>,
          ]}
          width={90}
          required
          isInvalid={isInvalid}
        />
      </EuiFormRow>
    </Modal>
  )
}
