import React, { useEffect } from 'react'

import { BankType } from 'common/enums'
import { Col, HBox, Loader, VBox } from 'shared/ui'
import { ParkDetailsContainer } from 'settings/features/parkDetails'
import { ParkContactsContainer } from 'settings/features/parkContacts'
import { ParkEdgvrContainer } from 'settings/features/parkEdgvr'
import { ParkCertificateContainer } from 'settings/features/parkCertificate'
import { ParkTinkoffContainer } from 'settings/features/parkTinkoff'
import { ParkE2CContainer } from 'settings/features/parkE2C'

import { ContainerProps } from 'settings/features/requisitesTab/containers/RequisitesTabContainer'
import styled from 'styled-components'
import { ParkAzonContainer } from 'settings/features/parkAzon'

const Wrapper = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.paddings.main}px;
`

export const RequisitesTab: React.FC<ContainerProps> = ({
  isFetching,
  bankType,
  onDidMount,
}) => {
  useEffect(() => {
    onDidMount()
  }, [onDidMount])
  const isBankTypeQiwi = bankType === BankType.Qiwi
  const isBankTypeAlfa = bankType === BankType.Alfa
  const isBankTypeAlfaE2C = bankType === BankType.AlfaE2C
  const isBankTypeAzon = bankType === BankType.Azon
  const isBankTypeModul = bankType === BankType.Modul
  const isBankTypeTinkoff = bankType === BankType.Tinkoff

  const isE2C = isBankTypeModul || isBankTypeAlfaE2C || isBankTypeQiwi

  return isFetching ? (
    <Loader />
  ) : (
    <Wrapper>
      <Col percentage={50}>
        <ParkDetailsContainer />
        <HBox />
        <ParkContactsContainer />
      </Col>
      <VBox />
      <Col percentage={50}>
        {isBankTypeAlfa && (
          <>
            <ParkCertificateContainer />
            <HBox />
          </>
        )}
        {isBankTypeTinkoff && (
          <>
            <ParkTinkoffContainer />
            <HBox />
          </>
        )}
        {isE2C && (
          <>
            <ParkE2CContainer />
            <HBox />
          </>
        )}
        {isBankTypeAzon && (
          <>
            <ParkAzonContainer />
            <HBox />
          </>
        )}
        <ParkEdgvrContainer />
      </Col>
    </Wrapper>
  )
}
