import gsap from 'gsap'

export function initTimelines(
  odd: HTMLDivElement | null,
  even: HTMLDivElement | null
) {
  const incrementOddTimeline = gsap
    .timeline({
      paused: true,
    })
    .set(even, { autoAlpha: 1 })
    .set(odd, { autoAlpha: 1 })
    .fromTo(
      odd,
      1,
      {
        y: '-100%',
      },
      {
        y: 0,
      }
    )
    .fromTo(
      even,
      1,
      {
        y: 0,
      },
      {
        y: '100%',
      },
      '-=1'
    )
    .set(even, { autoAlpha: 0 })

  const incrementEvenTimeline = gsap
    .timeline({
      paused: true,
    })
    .set(even, { autoAlpha: 1 })
    .set(odd, { autoAlpha: 1 })
    .fromTo(
      even,
      1,
      {
        y: '-100%',
      },
      {
        y: 0,
      }
    )
    .fromTo(
      odd,
      1,
      {
        y: 0,
      },
      {
        y: '100%',
      },
      '-=1'
    )
    .set(odd, { autoAlpha: 0 })

  const decrementOddTimeline = gsap
    .timeline({
      paused: true,
    })
    .set(even, { autoAlpha: 1 })
    .set(odd, { autoAlpha: 1 })
    .fromTo(
      odd,
      1,
      {
        y: '100%',
      },
      {
        y: 0,
      }
    )
    .fromTo(
      even,
      1,
      {
        y: 0,
      },
      {
        y: '-100%',
      },
      '-=1'
    )
    .set(even, { autoAlpha: 0 })

  const decrementEvenTimeline = gsap
    .timeline({
      paused: true,
    })
    .set(even, { autoAlpha: 1 })
    .set(odd, { autoAlpha: 1 })
    .fromTo(
      even,
      1,
      {
        y: '100%',
      },
      {
        y: 0,
      }
    )
    .fromTo(
      odd,
      1,
      {
        y: 0,
      },
      {
        y: '-100%',
      },
      '-=1'
    )
    .set(odd, { autoAlpha: 0 })

  return {
    incrementOddTimeline,
    incrementEvenTimeline,
    decrementEvenTimeline,
    decrementOddTimeline,
  }
}
