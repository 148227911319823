import React, { useState } from 'react'
import { Field, InjectedFormProps, SubmissionError } from 'redux-form'

import ListInput from 'common/components/formItems/ListInput'
import { phoneMask, phonePattern } from 'common/utils/patterns'
import InputMask from 'common/components/formItems/InputMask'
import {
  CancelChangesModal,
  CancelOrSave,
  ContentCard,
  ContentCardBody,
  ContentCardHeader,
} from 'shared/ui'
import { email, required } from 'common/utils/fieldValidation'

import {
  ContainerProps,
  FormProps,
} from 'settings/features/parkContacts/containers/ParkContactsContainer'
import styled from 'styled-components'

const contentContainerComponent = styled.div`
  top: calc(100% + 8px) !important;
  bottom: initial !important;
  right: initial !important;
  left: calc(100% - 296px) !important;
  width: 296px !important;
  box-shadow: 0 3px 12px rgba(5, 16, 34, 0.06) !important;
`

export const ParkContacts: React.FC<
  InjectedFormProps<FormProps, ContainerProps> & ContainerProps
> = ({
  pristine,
  submitting,
  handleSubmit,
  reset,
  canChange,
  isFetching,
  onSave,
}) => {
  const [isCancelChangesModalOpened, setIsCancelChangesModalOpened] = useState(
    false
  )
  const handleCancel = (isCancelled: boolean) => {
    if (isCancelled) {
      reset()
    }
    setIsCancelChangesModalOpened(false)
  }
  const handleSave = async (values: FormProps) => {
    try {
      await onSave(values)
    } catch (e) {
      throw new SubmissionError(e)
    }
  }
  return (
    <>
      <ContentCard>
        <ContentCardHeader
          title="Контакты"
          tooltip={
            pristine
              ? {
                  content:
                    'Контакты, по которым водители могут связаться с вами',
                  component: contentContainerComponent,
                }
              : undefined
          }
        >
          {!pristine ? (
            <CancelOrSave
              isLoading={submitting}
              onCancel={() => setIsCancelChangesModalOpened(true)}
              onSubmit={handleSubmit(handleSave)}
            />
          ) : null}
        </ContentCardHeader>

        <ContentCardBody isLoading={isFetching}>
          <Field
            name="email"
            validate={[email, required]}
            iconName="close"
            label="Email"
            component={ListInput}
            disabled={!canChange}
          />
          <Field
            name="phone"
            label="Телефон"
            validate={[required]} // phone,
            iconName="close"
            pattern={phonePattern}
            mask={phoneMask}
            component={InputMask}
            disabled={!canChange}
          />
        </ContentCardBody>
      </ContentCard>

      {isCancelChangesModalOpened ? (
        <CancelChangesModal onClose={handleCancel} />
      ) : null}
    </>
  )
}
