import React from 'react'
import { isEmpty } from 'lodash'
import { InjectedFormProps, reduxForm, SubmissionError } from 'redux-form'
import { RouteComponentProps } from 'react-router'
import axios from 'axios'

import { history } from 'store'

import { RoutesPath } from 'common/enums'
import { ApiCode } from 'common/types/api/codes'

import ResetPasswordComponent from 'auth/resetPassword/components/ResetPasswordComponent'

import queryString from 'query-string'

interface FormProps {
  newPassword: string
  comparePassword: string
}

interface LocalState {
  submitLoading?: boolean
}

interface DataPayload {
  confirmCode: string
  email: string
  newPassword: string
}

type Group = InjectedFormProps<FormProps, RouteComponentProps> &
  RouteComponentProps

class ResetPasswordContainer extends React.Component<Group, LocalState> {
  constructor(props: Group) {
    super(props)
    this.state = {
      submitLoading: false,
    }
  }

  componentDidMount() {
    const { location } = this.props
    const parsedData = queryString.parse(location.search)

    if (isEmpty(parsedData)) {
      history.replace(RoutesPath.SignIn)
    }
  }

  private handleSubmit = async (values: FormProps): Promise<void> => {
    const { location } = this.props
    const parsedData = queryString.parse(location.search)

    if (isEmpty(parsedData) && !parsedData.code && !parsedData.email) {
      return
    }

    this.setState({ submitLoading: true })

    const data: DataPayload = {
      confirmCode: parsedData.code as string,
      email: parsedData.email as string,
      newPassword: values.newPassword,
    }

    try {
      await axios.post('auth/1/password/reset/confirm', data)

      history.replace(RoutesPath.SignIn)
    } catch (error) {
      if (
        error.response &&
        error.response.data.code === ApiCode.InputValidationError
      ) {
        throw new SubmissionError(error.response.data.payload)
      }
    } finally {
      this.setState({ submitLoading: false })
    }
  }

  render() {
    const { handleSubmit } = this.props
    const { submitLoading } = this.state
    return (
      <ResetPasswordComponent
        submitLoading={submitLoading}
        onSubmit={handleSubmit(this.handleSubmit)}
      />
    )
  }
}

const Form = reduxForm<FormProps, RouteComponentProps>({
  form: 'ResetPassword',
})(ResetPasswordContainer)

export default Form
