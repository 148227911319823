import { Action } from 'redux-actions'

import { DriverStats } from 'common/types/api/models/driver'

export type SET_STATS = Action<DriverStats>
export const SET_STATS: SET_STATS['type'] = 'driver_details/stats/SET_INFO'
export const setStats = (stats: DriverStats): SET_STATS => ({
  type: SET_STATS,
  payload: stats,
})

export type SET_STATS_DATE_FROM = Action<Date | null>
export const SET_STATS_DATE_FROM: SET_STATS_DATE_FROM['type'] =
  'driver_details/stats/SET_STATS_DATE_FROM'
export const setStatsDateFrom = (date: Date | null): SET_STATS_DATE_FROM => ({
  type: SET_STATS_DATE_FROM,
  payload: date,
})

export type SET_STATS_DATE_TO = Action<Date | null>
export const SET_STATS_DATE_TO: SET_STATS_DATE_TO['type'] =
  'driver_details/stats/SET_STATS_DATE_TO'
export const setStatsDateTo = (date: Date | null): SET_STATS_DATE_TO => ({
  type: SET_STATS_DATE_TO,
  payload: date,
})

export type Payloads = Pick<
  SET_STATS & SET_STATS_DATE_FROM & SET_STATS_DATE_TO,
  'payload'
>['payload']
