import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import { State } from 'reducers'
import { MyThunkDispatch } from 'api/reducer'
import { FormNames, ModalsNames } from 'common/enums'
import { modalsActions } from 'shared/modals'
import { onSaveParkTinkoff } from 'settings/features/parkTinkoff/thunks'
import { AddTinkoffModal } from 'settings/features/parkTinkoff/pages'
import { TinkoffAuth } from 'api/park/tinkoff/types'

export type FormProps = TinkoffAuth

const mapStateToProps = (_state: State) => ({})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  onSave: (values: FormProps) => dispatch(onSaveParkTinkoff({ values })),
  closeAddTinkoffModal: () =>
    dispatch(modalsActions.closeModal(ModalsNames.AddTinkoff)),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const Form = reduxForm<FormProps, ContainerProps>({
  form: FormNames.ParkTinkoff,
})(AddTinkoffModal)

export const AddTinkoffModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Form)
