import React from 'react'

import { PaymentsReportConnector } from 'payments/features/reportsTab/containers'
import { PurePaymentsReportTab } from 'payments/features/reportsTab/organisms'
import { Loader } from 'shared/ui/loader'
import { useDispatch } from 'react-redux'
import { apiActions } from 'api'

export const PaymentsReportTab = React.memo(() => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(apiActions.park.aggregators.handleRequest())
  }, [dispatch])

  return (
    <PaymentsReportConnector>
      {({
        email,
        aggregators,
        sendReport1C,
        sendReportPayments,
        isFetching,
        isE2C,
      }) => {
        if (!email) {
          return <Loader />
        }

        return (
          <PurePaymentsReportTab
            isE2C={isE2C}
            aggregators={aggregators}
            isFetching={isFetching}
            email={email}
            onSend1C={sendReport1C}
            onSendPayments={sendReportPayments}
          />
        )
      }}
    </PaymentsReportConnector>
  )
})
PaymentsReportTab.displayName = 'PaymentsReportTab'
