import React from 'react'

import { SubBodyText, SubTitleText } from 'ui-kit/components'
import { Col, HBox, NoContentImage, Row } from 'shared/ui'
import { DriverCardsContainer } from 'drivers/features/driverCards'

import { ContainerProps } from 'drivers/features/driverCardsTab/containers/DriverCardsTabContainer'
import styled from 'styled-components'

const Inner = styled.div`
  padding: ${({ theme }) => theme.paddings.main}px;
`

const Container = styled.div`
  display: flex;
  flex: 1;
`
const Info = styled.div`
  flex: 5;
  align-self: flex-start;
`
const Image = styled.div`
  flex: 5;
`
const Empty = styled.div`
  flex: 1;
`

export const DriverCardsTab: React.FC<ContainerProps> = ({ hasCards }) =>
  hasCards ? (
    <Inner>
      <Row>
        <Col percentage={25}>
          <DriverCardsContainer />
        </Col>
      </Row>
    </Inner>
  ) : (
    <>
      <HBox x={2.5} />
      <Container>
        <Empty />
        <Info>
          <SubTitleText color="darkGray" fontWeight="semi-bold">
            Водитель еще не привязал карту
          </SubTitleText>
          <HBox x={0.5} />
          <SubBodyText color="darkGray">
            Чтобы получать деньги, водителю нужно указать в мобильном приложении
            номер карты.
            <HBox x={0} />
            Он может сделать это во время вывода средств или в профиле.
          </SubBodyText>
        </Info>
        <Empty />
        <Image>
          <NoContentImage />
        </Image>
        <Empty />
      </Container>
    </>
  )
