import { ApiState, createApiReducer } from 'common/utils/reduxApiFactory'
import { StatisticsWeekdayWithdraw } from 'api/types'
import { actions } from 'api/park/statistics/weekday-withdraw/actions'

export type StatisticsWeekdayWithdrawState = ApiState<
  StatisticsWeekdayWithdraw[]
>

export const initialState: StatisticsWeekdayWithdrawState = {
  status: null,
  data: [],
}

export const reducer = createApiReducer(actions, initialState)
