import axios from 'axios'
import { BaseAction } from 'redux-actions'
import { ThunkAction } from 'redux-thunk'

import { State } from 'reducers'
import {
  failure,
  start,
  success,
} from 'api/employees/employeesById/patch/actions'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  BaseAction
>
export const HANDLE_REQUEST: BaseAction['type'] =
  'api/employeesById/patch->HANDLE_REQUEST'

export const handleRequest = ({ id, data }: any): THandleRequest => async (
  dispatch
): Promise<void> => {
  dispatch(start())
  try {
    await axios.patch(`erp/1/employees/${id}`, data)
    dispatch(success())
  } catch (e) {
    if (e.response) {
      dispatch(failure(e.response.data))
    }
    throw e
  }
}
