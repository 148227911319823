import React from 'react'
import { history } from 'store'

import { RoutesPath } from 'common/enums'
import ReferralProgramContainer from 'elastic-ui/referral-program/containers/ReferralProgramContainer'
import {
  EuiLink,
  EuiSpacer,
  EuiText,
  EuiTextColor,
  EuiTitle,
} from '@elastic/eui'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding: ${({ theme }) => theme.paddings.main}px;
`

export const DriversReferralProgramContainer: React.FC = () => {
  return (
    <Wrapper>
      <EuiTitle size="s">
        <h3>
          <EuiTextColor color="subdued">
            <EuiLink
              onClick={() => history.push(RoutesPath.Drivers)}
              color="subdued"
            >
              Водители
            </EuiLink>{' '}
            / <EuiTextColor color="default">Реферальная программа</EuiTextColor>
          </EuiTextColor>
        </h3>
      </EuiTitle>
      <EuiText color="subdued" size="m" />
      <EuiSpacer size="m" />
      <ReferralProgramContainer />
    </Wrapper>
  )
}
