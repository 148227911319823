import React, { useMemo } from 'react'
import {
  Field,
  InjectedFormProps,
  reduxForm,
  SubmissionError,
} from 'redux-form'

import { FormSubmitHandler } from 'redux-form/lib/reduxForm'
import { FormNames } from 'common/enums'

import { HBox } from 'shared/ui'
import { RejectResolveButtons } from 'ui-kit/components'
import ListInput from 'common/components/formItems/ListInput'
import { required } from 'common/utils/fieldValidation'

export type GroupNameFormDataData = { name: string }

type OwnProps = {
  isEdition?: boolean
  onSubmit: (values: GroupNameFormDataData) => void
  onCancel: () => void
}

type Props = InjectedFormProps<GroupNameFormDataData, OwnProps> & OwnProps

const SettingsNameForm = ({
  isEdition,
  submitting,
  handleSubmit: handleFormSubmit,
  onSubmit,
  onCancel,
  pristine,
}: Props) => {
  const handleSubmit = useMemo(() => {
    const submit: FormSubmitHandler<GroupNameFormDataData> = async (values) => {
      try {
        await onSubmit(values)
      } catch (e) {
        throw new SubmissionError(e)
      }
    }

    return handleFormSubmit(submit)
  }, [handleFormSubmit, onSubmit])

  return (
    <>
      <Field
        name="name"
        label="Название"
        component={ListInput}
        validate={[required]}
      />
      <HBox x={0.5} />
      <RejectResolveButtons
        className="actions"
        resolveText={isEdition ? 'Сохранить' : 'Создать'}
        rejectText="Отмена"
        onResolve={handleSubmit}
        onReject={onCancel}
        disableResolve={pristine}
        isLoading={submitting}
        fullWidth
      />
    </>
  )
}

export default reduxForm<GroupNameFormDataData, OwnProps>({
  form: FormNames.PaymentsSettingsGroupName,
})(SettingsNameForm)
