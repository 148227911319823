import React from 'react'
import { connect } from 'react-redux'

import { State } from 'reducers'
import { apiSelectors } from 'api'

import DashboardComponent from 'dashboard/components/DashboardComponent'

const DashboardContainer: React.FC<ContainerProps> = ({ permissions }) => {
  return <DashboardComponent permissions={permissions} />
}

const mapStateToProps = (state: State) => ({
  permissions: apiSelectors.employee.profile.getPermissions(state),
})

export type ContainerProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(DashboardContainer)
