import React, { useState } from 'react'
import Card from 'ui-kit/atoms/Card/Card'
import { InlineTextSelect } from 'ui-kit/atoms/InlineTextSelect/InlineTextSelect'
import { TransparentButton } from 'ui-kit/components'
import { ContentToggle } from 'ui-kit/molecules/ContentToggle/ContentToggle'
import { RadioGroup, Option } from 'ui-kit/molecules/RadioGroup/RadioGroup'

interface Props {
  children?: string
  options: Option[]
  selected: string
  onChange(selectedOption: Option): void
  opacity?: number
  isLoading?: boolean
  groupPosition?: 'start' | 'middle' | 'end'
}

export const TransparentButtonOutputFormatSelect: React.FC<Props> = ({
  children,
  options,
  selected,
  onChange,
  opacity,
  isLoading,
  groupPosition,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const Trigger: React.FC = () => (
    <TransparentButton
      opacity={opacity}
      isLoading={isLoading}
      fontWeight="semi-bold"
      textColor="white"
      groupPosition={groupPosition}
      isSizeLikeWithIcon
    >
      <InlineTextSelect isSelecting={isOpen} color="white">
        {children}
      </InlineTextSelect>
    </TransparentButton>
  )

  return (
    <ContentToggle
      trigger={Trigger}
      onTriggerClick={() => setIsOpen(true)}
      onClickAway={() => setIsOpen(false)}
      isOpen={isOpen}
    >
      <Card style={{ left: 0, top: -220, position: 'absolute', width: 320 }}>
        <RadioGroup
          options={options}
          selected={selected}
          onChange={(selectedOption) => {
            onChange(selectedOption)
            setIsOpen(false)
          }}
        />
      </Card>
    </ContentToggle>
  )
}
