import React from 'react'

import { Image } from 'shared/ui'

import {
  ErrorLayoutDescription,
  ErrorLayoutHeader,
  ErrorLayoutLeft,
  ErrorLayoutRight,
  ErrorLayoutSides,
  ErrorLayoutWrapper,
} from 'shared/router/components/ServerErrorLayout/styles'

import { BodyText, Icon, ListItem, TitleText } from 'ui-kit/components'

import fixingCarSrc from 'static/backgrounds/fixing-car.svg'

interface Props {
  title: string
  message: string
}

export const EngineeringWorksLayout: React.FC<Props> = ({ title, message }) => {
  return (
    <ErrorLayoutWrapper>
      <ErrorLayoutHeader>
        <Icon pointer={false} className="mozen-logo" name="mozen" />
      </ErrorLayoutHeader>
      <ErrorLayoutSides>
        <ErrorLayoutLeft>
          <ListItem>
            <TitleText fontWeight="semi-bold">{title}</TitleText>
          </ListItem>
          <ErrorLayoutDescription>
            <BodyText color="gray9">{message}</BodyText>
          </ErrorLayoutDescription>
        </ErrorLayoutLeft>
        <ErrorLayoutRight>
          <Image src={fixingCarSrc} />
        </ErrorLayoutRight>
      </ErrorLayoutSides>
    </ErrorLayoutWrapper>
  )
}
