import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { State } from 'reducers'
import { apiSelectors } from 'api'
import { ModalsNames } from 'common/enums'
import { modalsActions } from 'shared/modals'
import { Notice } from 'layout/features/notice/organisms'

const mapStateToProps = (state: State) => ({
  subscriptionStatus: apiSelectors.park.details.getSubscriptionStatus(state),
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openDepositPayModal: () =>
    dispatch(modalsActions.openModal(ModalsNames.DepositPay)),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const NoticeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Notice)
