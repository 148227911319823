import React from 'react'

import { Image } from 'shared/ui'
import styled from 'styled-components'

import avatarSrc from 'static/placeholders/avatar-profile.jpg'

const Wrapper = styled.div<{ size: string; clickable?: boolean }>`
  display: flex;
  position: relative;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.pallete.secondary};
  cursor: ${(props) => (props.clickable ? 'pointer' : 'initial')};

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`

interface Props {
  avatar?: string
  size?: string
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
}

export const Avatar: React.FC<Props> = ({
  avatar = avatarSrc,
  size = '40px',
  onClick,
}) => (
  <Wrapper size={size} onClick={onClick} clickable={!!onClick}>
    <Image
      src={avatar}
      alt="avatar"
      width="100%"
      height="100%"
      objectFit="cover"
    />
  </Wrapper>
)
