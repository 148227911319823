import styled from 'styled-components'

interface FilterBlock {
  big?: boolean
}

export const FilterBlock = styled.div<FilterBlock>`
  position: absolute;
  top: calc(100% + 11px);
  right: 0;
  background-color: ${({ theme }) => theme.pallete.white};
  box-shadow: 0 3px 10px rgba(5, 16, 34, 0.1);
  border-radius: 4px;
  width: ${(props) => (!props.big ? '269px' : '640px')};
  z-index: 10;

  .muk-checkbox-wrapper {
    width: 100%;
  }

  .actions-item {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`

export const SmallWrapper = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
`
