import axios, { AxiosResponse } from 'axios'
import { BaseAction } from 'redux-actions'
import { ThunkAction } from 'redux-thunk'

import { State } from 'reducers'
import { EmployeesByIdGetResponse } from 'api/types'
import {
  failure,
  start,
  success,
  update,
} from 'api/employees/employeesById/get/actions'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  BaseAction
>
export const HANDLE_REQUEST: BaseAction['type'] =
  'api/employeesById/get->HANDLE_REQUEST'

export const handleRequest = (employeeId: string): THandleRequest => async (
  dispatch
) => {
  dispatch(start())
  try {
    const response: AxiosResponse<EmployeesByIdGetResponse> = await axios.get(
      `erp/1/employees/${employeeId}`
    )
    dispatch(update(response.data))
    dispatch(success())
  } catch (e) {
    if (e.response) {
      dispatch(failure(e.response.data))
    }
  }
}
