import React from 'react'
import { Field, InjectedFormProps, SubmissionError } from 'redux-form'

import { BodyText, CaptionText, RejectResolveButtons } from 'ui-kit/components'
import ListInput from 'common/components/formItems/ListInput'
import {
  driverContract,
  name,
  required,
  todayOrBefore,
} from 'common/utils/fieldValidation'
import FormDatePicker from 'common/components/formItems/FormDatepicker'
import { HBox, Modal } from 'shared/ui'

import {
  ContainerProps,
  FormProps,
} from 'drivers/features/driverInfo/containers/AddDriverContractModalContainer'
import { Dispatch } from 'redux'
import styled from 'styled-components'

const NumberWithSuffix = styled.div`
  opacity: 0.5;
`

export const AddDriverContractModal: React.FC<
  InjectedFormProps<FormProps, ContainerProps> & ContainerProps
> = ({ handleSubmit, valid, submitting, formValues, onSave, closeModal }) => {
  const handleSave = async (
    values: FormProps,
    _: Dispatch,
    props: ContainerProps
  ) => {
    try {
      await onSave({ id: props.id, values })
    } catch (e) {
      throw new SubmissionError(e)
    }
  }
  return (
    <Modal
      open
      onClose={closeModal}
      closeButton
      title="Договор водителя"
      width={528}
    >
      <Field
        name="contract_number"
        label="Номер"
        iconName="close"
        validate={[required, driverContract]}
        component={ListInput}
      />
      <HBox x={0.5} />

      {formValues && formValues.is_non_resident ? (
        <>
          <NumberWithSuffix>
            <CaptionText color="gray8">Номер с суффиксом</CaptionText>
            <BodyText>
              {formValues.contract_number + formValues.contract_suffix}
            </BodyText>
          </NumberWithSuffix>
          <HBox x={0.5} />
        </>
      ) : null}

      <Field
        name="contract_date"
        label="Дата заключения"
        placeholder=""
        validate={[required, todayOrBefore]}
        component={FormDatePicker}
        disableOnBlur
        props={{ centered: false }}
      />
      <HBox x={0.5} />
      <Field
        name="last_name"
        label="Фамилия в договоре"
        iconName="close"
        validate={[required, name]}
        component={ListInput}
      />
      <HBox x={0.5} />
      <Field
        name="first_name"
        label="Имя"
        iconName="close"
        validate={[required, name]}
        component={ListInput}
      />
      <HBox x={0.5} />
      <Field
        name="second_name"
        label="Отчество, если есть"
        iconName="close"
        validate={[name]}
        component={ListInput}
      />
      <HBox />
      <RejectResolveButtons
        fullWidth
        onResolve={handleSubmit(handleSave)}
        onReject={closeModal}
        disableReject={submitting}
        disableResolve={!valid || submitting}
        isLoading={submitting}
      />
    </Modal>
  )
}
