import styled from 'styled-components'

import { FontWeightTypes } from 'ui-kit'

const renderFontWeight = (fontWeight: FontWeightTypes): string | number => {
  switch (fontWeight) {
    case 'light':
      return 300
    case 'normal':
      return 400
    case 'medium':
      return 500
    case 'semi-bold':
      return 600
    case 'bold':
      return 700
    case 'bolder':
      return 800
    default:
      return 'normal'
  }
}

const defaultStyles = (color: string, fontWeight: FontWeightTypes): string => `
    padding: 0;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    color: ${color};
    font-weight: ${renderFontWeight(fontWeight as FontWeightTypes)};
`

interface Props {
  color: string
  fontWeight: FontWeightTypes
}

export const HeaderTextWrapper = styled('h1')<Props>`
  font-size: 36px;
  line-height: 48px;
  ${(props) => defaultStyles(props.color, props.fontWeight)}
`

export const TitleTextWrapper = styled('h2')<Props>`
  font-size: 24px;
  line-height: 32px;
  ${(props) => defaultStyles(props.color, props.fontWeight)}
`
export const SubTitleTextWrapper = styled('h3')<Props>`
  font-size: 18px;
  line-height: 28px;
  ${(props) => defaultStyles(props.color, props.fontWeight)}
`

export const BodyTextWrapper = styled('div')<Props>`
  font-size: 16px;
  line-height: 24px;
  ${(props) => defaultStyles(props.color, props.fontWeight)}
`
export const SubBodyTextWrapper = styled('div')<Props>`
  font-size: 14px;
  line-height: 22px;
  ${(props) => defaultStyles(props.color, props.fontWeight)}
`

export const CaptionTextWrapper = styled('span')<Props>`
  font-size: 12px;
  line-height: 18px;
  ${(props) => defaultStyles(props.color, props.fontWeight)}
`

export const SubCaptionTextWrapper = styled('span')<Props>`
  font-size: 10px;
  line-height: 16px;
  ${(props) => defaultStyles(props.color, props.fontWeight)}
`

interface TableTextProps {
  bold?: boolean
  color: string
}

const defaultStylesTable = (color: string, bold?: boolean): string => `
    padding: 0;
    margin: 0;
    font-family: 'PT Sans', sans-serif;
    color: ${color};
    font-weight: ${bold ? 'bold' : 'normal'};
`

export const TableTextWrapper = styled('div')<TableTextProps>`
  font-size: 14px;
  line-height: 19px;
  ${(props) => defaultStylesTable(props.color, props.bold)}
`

export const TableCaptionTextWrapper = styled('span')<TableTextProps>`
  font-size: 12px;
  line-height: 15px;
  ${(props) => defaultStylesTable(props.color, props.bold)}
`
