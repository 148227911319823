import { ApiActionTypes, ApiState } from 'common/utils/reduxApiFactory'

export enum PaymentOrderType {
  Fuel = 'park_fuel_deposit_topup',
  Mozen = 'park_mozen_deposit_topup',
}

export type GetPaymentOrderParams = {
  payment_order_type: PaymentOrderType
  amount: number
}

export type GetPaymentOrderState = ApiState

export type GetPaymentOrderActions = ApiActionTypes
