import React from 'react'
import { ValidationProps } from '../types'

interface BaseProps<T extends HTMLElement> {
  required?: boolean
  fullWidth?: boolean
  onBlur?: (event: React.FocusEvent<T>) => void
  onInvalid?: (event: React.InvalidEvent<T>) => void
}

const createEuiFieldEnhancedBase = <
  T extends HTMLInputElement | HTMLTextAreaElement,
  U = Record<string, unknown>
>(
  Component: React.ComponentType<U & BaseProps<T>>
) => {
  const EuiFieldEnhanced: React.FC<U & ValidationProps<T> & BaseProps<T>> = ({
    onBlur,
    onInvalid,
    onValidation,
    required = Boolean(onValidation),
    fullWidth = true,
    ...props
  }) => {
    const handleBlur = React.useCallback(
      (e: React.FocusEvent<T>) => {
        e.persist()
        onBlur?.(e)
        onValidation?.(e)
      },
      [onBlur, onValidation]
    )

    const handleInvalid = React.useCallback(
      (e: React.InvalidEvent<T>) => {
        e.persist()
        e.preventDefault()
        e.currentTarget.focus()
        onInvalid?.(e)
        onValidation?.(e)
      },
      [onInvalid, onValidation]
    )

    return React.createElement(Component, {
      onBlur: handleBlur,
      onInvalid: handleInvalid,
      required,
      fullWidth,
      ...props,
    } as U & BaseProps<T>)
  }

  return EuiFieldEnhanced
}

export default createEuiFieldEnhancedBase
