import { createSelector } from 'reselect'
import { State } from 'reducers'
import { formatToChartDate } from 'common/utils/dates'
import { currencyToBase } from 'common/utils/formatters'

export const selectStatisticsFees = (state: State) =>
  state.api.park.statisticsFees

export const selectIsFetching = (state: State) =>
  state.api.park.statisticsFees.status === 'loading'

export const selectCharData = createSelector(
  selectStatisticsFees,
  (statisticsFeesState) => {
    if (!statisticsFeesState.data) return []

    return statisticsFeesState.data.data.map((item) => ({
      ...item,
      mozen_withdraw_fees_total: item.mozen_withdraw_fees_total / 100,
      ride_fees_total: item.ride_fees_total / 100,
      label_mozen_withdraw_fees_total: currencyToBase(
        item.mozen_withdraw_fees_total
      ),
      label_ride_fees_total: currencyToBase(item.ride_fees_total),
      date: formatToChartDate(item.date),
    }))
  }
)

export const selectCharSummary = createSelector(
  selectStatisticsFees,
  (statisticsFeesState) => statisticsFeesState.data?.summary
)
