import { createSelector } from 'reselect'
import { State } from 'reducers'

export const getIsFetching = (state: State) =>
  state.api.park.balanceLog.status === 'loading'

const getData = (state: State) => state.api.park.balanceLog.data

export const getTransactions = createSelector(getData, (data) =>
  data ? data.transactions : []
)

export const getTotalAmount = createSelector(getData, (data) =>
  data ? data.total_amount : null
)
