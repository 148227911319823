export enum ModalsNames {
  EditE2C = 'EditE2C',
  PaymentDetails = 'PaymentDetails',
  AddDriverContract = 'AddDriverContract',
  DeleteDriverContract = 'DeleteDriverContract',
  DepositPay = 'DepositPay',
  AddAggregator = 'AddAggregator',
  DeleteAggregator = 'DeleteAggregator',
  AddCertificate = 'AddCertificate',
  AddQiwi = 'AddQiwi',
  AddModul = 'AddModul',
  AddTinkoff = 'AddTinkoff',
  AddNotificationsQiwiSettings = 'AddNotificationsQiwiSettings',
  AddEmployee = 'AddEmployee',
  BlockEmployee = 'BlockEmployee',
}
