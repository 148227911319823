import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { history } from 'store'

import { RoutesPath } from 'common/enums'
import { addKTrackingData, useScript } from 'common/utils/helpers'

import { Image } from 'shared/ui'
import { AuthLayout } from 'shared/router'
import {
  Actions,
  ImageHolder,
  LeftContent,
  LeftContentWrapper,
  RightContent,
  SkipButton,
} from 'auth/signUpSuccess/styles'

import { BodyText, Icon, ListItem, TitleText } from 'ui-kit/components'
import handSrc from 'static/backgrounds/hand.svg'

const SignUpSuccessComponent: React.FC = () => {
  useEffect(addKTrackingData, [])
  useScript('//tracker.mozen.io/js/k.min.js')

  const renderLeftSideContent = (): JSX.Element => {
    return (
      <LeftContentWrapper>
        <LeftContent>
          <ListItem>
            <TitleText fontWeight="semi-bold" className="title">
              Заявка принята
            </TitleText>
          </ListItem>
          <BodyText className="text">
            Скоро вам перезвонит менеджер Mozen, а&nbsp;пока
            <br />
            <b>проверьте свой email,</b> мы&nbsp;прислали Вам ссылку
            <br />
            для подтверждения
          </BodyText>
          <ListItem>
            <Actions
              onClick={() => history.push(RoutesPath.EmailConfirmationLost)}
            >
              <BodyText fontWeight="semi-bold" color="blue">
                Отправить письмо снова
              </BodyText>
            </Actions>
          </ListItem>
          <SkipButton>
            <Link to={RoutesPath.MainPage}>
              <ListItem>
                <Icon name="arrowLeft" color="gray6">
                  <BodyText fontWeight="semi-bold" color="gray6">
                    Вернуться на главную
                  </BodyText>
                </Icon>
              </ListItem>
            </Link>
          </SkipButton>
        </LeftContent>
      </LeftContentWrapper>
    )
  }

  const renderRightSideContent = (): JSX.Element => {
    return (
      <RightContent>
        <ImageHolder>
          <TitleText
            className="right-title"
            fontWeight="semi-bold"
            color="white"
          >
            Поможем настроить инструменты
          </TitleText>
          <Image src={handSrc} height="50vh" objectFit="contain" />
        </ImageHolder>
      </RightContent>
    )
  }

  return (
    <AuthLayout
      leftSideContent={renderLeftSideContent()}
      rightSideContent={renderRightSideContent()}
    />
  )
}

export default SignUpSuccessComponent
