import React from 'react'

import { ListItem, SubBodyText } from 'ui-kit/components'
import { ContentCard, HBox } from 'shared/ui'

import { ContainerProps } from 'settings/features/parkDetails/containers/ParkDetailsContainer'
import styled from 'styled-components'

const GridContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
`

export const ParkDetails: React.FC<ContainerProps> = ({
  details,
  isFetching,
}) => {
  return (
    <ContentCard isLoading={isFetching}>
      <HBox x={0.5} />
      {details ? (
        <GridContent>
          <ListItem>
            <SubBodyText color="darkGray" fontWeight="medium">
              Организация
            </SubBodyText>
          </ListItem>
          <ListItem>
            <SubBodyText>{details.name}</SubBodyText>
          </ListItem>
          <ListItem>
            <SubBodyText color="darkGray" fontWeight="medium">
              ИНН
            </SubBodyText>
          </ListItem>
          <ListItem>
            <SubBodyText>{details.inn}</SubBodyText>
          </ListItem>

          <ListItem>
            <SubBodyText color="darkGray" fontWeight="medium">
              БИК
            </SubBodyText>
          </ListItem>
          <ListItem>
            <SubBodyText>{details.bik}</SubBodyText>
          </ListItem>
          <ListItem>
            <SubBodyText color="darkGray" fontWeight="medium">
              КПП
            </SubBodyText>
          </ListItem>
          <ListItem>
            <SubBodyText>{details.kpp}</SubBodyText>
          </ListItem>
          <ListItem>
            <SubBodyText color="darkGray" fontWeight="medium">
              Банк
            </SubBodyText>
          </ListItem>
          <ListItem>
            <SubBodyText>{details.bank_name}</SubBodyText>
          </ListItem>

          <ListItem>
            <SubBodyText color="darkGray" fontWeight="medium">
              Расч. счет
            </SubBodyText>
          </ListItem>
          <ListItem>
            <SubBodyText>{details.bank_account}</SubBodyText>
          </ListItem>
          <ListItem>
            <SubBodyText color="darkGray" fontWeight="medium">
              Корр. счет
            </SubBodyText>
          </ListItem>
          <ListItem>
            <SubBodyText>{details.correspondent_account}</SubBodyText>
          </ListItem>
        </GridContent>
      ) : null}
      <HBox x={0.5} />
    </ContentCard>
  )
}
