import { createSelector } from 'reselect'
import { State } from 'reducers'
import { initialState } from 'api/park/payment-settings/assign/reducer'

export const selectParkPaymentSettingsAssign = (state: State) =>
  state.api.park.paymentSettingsAssign || initialState

export const makeSelectIsFetching = () =>
  createSelector(
    selectParkPaymentSettingsAssign,
    (state) => state.status === 'loading'
  )
