import React from 'react'
import { Icon } from 'ui-kit/components'

type FormatActionsProps = {
  id: string
  onConfirmPayment: (isConfirmed: boolean, id: string) => void
}
export const FormatActions = React.memo<FormatActionsProps>(
  ({ id, onConfirmPayment }) => {
    const handleClickConfirmed = React.useCallback(() => {
      onConfirmPayment(true, id)
    }, [id, onConfirmPayment])

    const handleClickNotConfirmed = React.useCallback(() => {
      onConfirmPayment(false, id)
    }, [id, onConfirmPayment])

    return (
      <div className="actions">
        <Icon name="check" color="blue" onClick={handleClickConfirmed} />
        <Icon name="close" color="gray7" onClick={handleClickNotConfirmed} />
      </div>
    )
  }
)

FormatActions.displayName = 'FormatActions'
