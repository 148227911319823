import Alert from 'react-s-alert'
import { history } from 'store'
import { State } from 'reducers'
import { apiActions, apiSelectors } from 'api'
import { MyThunkDispatch, MyThunkResult } from 'api/reducer'
import { RoutesPath } from 'common/enums'

export const onUpload: (
  file: string
) => MyThunkResult<Promise<void | boolean>> = (file) => (
  dispatch: MyThunkDispatch
) => {
  const blob = new Blob([file], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })
  const formData: FormData = new FormData()
  formData.append('file', blob)
  return dispatch(
    apiActions.drivers.contractsUpload.handleRequest(formData)
  ).then(
    () => {
      return true
    },
    (reason) => {
      Alert.error('Ошибка', {
        customFields: {
          text: reason.response && reason.response.data.message,
        },
      })
    }
  )
}

export const onCommit: () => MyThunkResult<void> = () => (
  dispatch: MyThunkDispatch,
  getState: () => State
) => {
  return dispatch(
    apiActions.drivers.contractsCommit.handleRequest({
      data: {
        contracts: apiSelectors.drivers.contractsUpload.getContracts(
          getState()
        ),
      },
    })
  ).then(
    () => {
      history.push(RoutesPath.Drivers)
    },
    (reason) => {
      Alert.error('Ошибка', {
        customFields: {
          text: reason.response && reason.response.data.message,
        },
      })
    }
  )
}
