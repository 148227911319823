import React from 'react'
import { Redirect } from 'react-router-dom'
import { ApmRoute } from '@elastic/apm-rum-react'

import { RoutesPath } from 'common/enums'
import { getAuthTokens } from 'jwtAuth'

interface OwnProps {
  path: string
  component: React.FC<any> | React.ComponentType
  exact?: boolean
}

export const PrivateRoute: React.FC<OwnProps> = ({
  component: Component,
  ...rest
}) => (
  <ApmRoute
    {...rest}
    render={(routeProps: any) =>
      getAuthTokens() ? (
        React.createElement(Component, routeProps)
      ) : (
        <Redirect to={{ pathname: RoutesPath.SignIn }} />
      )
    }
  />
)
