import { DefaultTheme } from 'styled-components'

export default {
  pallete: {
    white: '#ffffff',
    black: '#051022',
    secondary: '#EAEEF7',
    gray1: '#FAFAFA',
    gray2: '#F5F5F5',
    gray3: '#EEEEEE',
    gray4: '#E0E0E0',
    gray5: '#BDBDBD',
    gray6: '#9E9E9E',
    gray7: '#757575',
    gray8: '#616161',
    gray9: '#424242',
    gray10: '#EAEEF7',
    lightGray: '#c0cee2',
    darkGray: '#8D98A9',
    blue: '#2979FF',
    blueMuted: '#3e86ff',
    lightBlue: '#08C0FD',
    lightBlueMuted: '#e6f9ff',
    violet: '#8759F2',
    violetMuted: '#936af3',
    green: '#00C853',
    greenMuted: '#e5f9ee',
    lightGreen: '#26F971',
    red: '#F93822',
    neonRed: '#F4264B',
    orange: '#ff9900',
    yellow: '#FEE60C',
    pink: '#FD3172',
    yellowDataSet: '#00C853',
    blueDataSet: '#D9D9D9',
    secondaryDarkBlue: '#8D98A9',
    secondaryBG: '#EAEEF7',
    secondaryLightBlue: '#C0CEE2',
    secondaryTableButton: '#F4F8FF',
    accentAttention: '#FF2626',
    accentAttentionInversed: '#F4264B',
    accentPrimary: '#2979FF',
    accentAntifraud: '#12CDF3',
    baseWhite: '#fff',
    baseBlack: '#051022',
    surfaceDarkPrimary: '#051022',
    surfaceBackgroundPrimary: '#EAEEF7',
    surfaceLightPrimary: '#FFFFFF',
    uiRed: '#FF5151',
    uiGreen: '#00C853',
    textPrimary: '#051022',
    textSecondary: '#69707A',
    textDisabled: '#9E9E9E',
    uiSecondary: '#C0CEE2',
    uiDisabled: '#E0E0E0',
    uiHover: 'rgba(234, 238, 247, 0.3)',
  },
  paddings: {
    quarter: 6,
    half: 12,
    threeQuarters: 18,
    main: 24,
    double: 48,
  },
} as DefaultTheme
