import React from 'react'
import { CaptionText, Icon, SubBodyText } from 'ui-kit/components'
import { Col } from 'shared/ui/flex'
import { formatPhone } from 'common/utils/formatters'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const DeleteDriverIcon = styled(Icon)`
  padding: 14px;
`
type Props = {
  name?: string
  phone: string
  onRemove?: () => void
}
export const PaymentSettingsDriverListItem: React.FC<Props> = ({
  name,
  phone,
  onRemove,
}) => {
  const handleRemove = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation()
    if (onRemove) {
      onRemove()
    }
  }

  return (
    <Wrapper>
      {onRemove !== undefined && (
        <DeleteDriverIcon name="circleMinus" onClick={handleRemove} pointer />
      )}
      <Col percentage={100}>
        <SubBodyText>{name}</SubBodyText>
        <CaptionText color="gray6">{formatPhone(phone)}</CaptionText>
      </Col>
      <Icon name="chevronRight" />
    </Wrapper>
  )
}
