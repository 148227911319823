import axios, { AxiosResponse } from 'axios'
import { BaseAction } from 'redux-actions'
import { ThunkAction } from 'redux-thunk'

import { State } from 'reducers'
import {
  failure,
  start,
  success,
} from 'api/drivers/contracts/no-contract/actions'
import { Aggregator } from 'api/types'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  BaseAction
>
export const HANDLE_REQUEST: BaseAction['type'] =
  'api/drivers/contracts/no-contract->HANDLE_REQUEST'

export const handleRequest = (aggregators: Omit<Aggregator, "account">[]): THandleRequest => async (dispatch) => {
  dispatch(start())
  try {
    const response: AxiosResponse<BlobPart> = await axios({
      url: "erp/1/drivers/contracts/no-contract",
      method: "POST",
      responseType: "blob",
      data: { aggregators }
    })
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: response.headers['content-type'] })
    )
    const link = document.createElement('a')
    link.href = url

    const contentDisposition = response.headers['content-disposition']
    let fileName = 'drivers.csv'
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
      if (fileNameMatch.length === 2) {
        const newFileName = fileNameMatch[1]
        fileName = newFileName
      }
    }
    link.setAttribute('download', fileName)

    document.body.appendChild(link)
    link.click()
    link.remove()
    window.URL.revokeObjectURL(url)
    dispatch(success())
  } catch (e) {
    if (e.response) {
      dispatch(failure(e.response.data))
    }
  }
}
