import React from 'react'
import { IncomeReportConnector } from 'analytics/features/incomeReport/containers/IncomeReportConnector'
import IncomeStatement from 'ui-kit/organisms/IncomeStatement'
import { toISOFormat } from 'common/utils/dates'

export const IncomeReport = React.memo(() => {
  return (
    <IncomeReportConnector>
      {({ data, updateData, isFetching, initialRange, notifyBot, canView }) => {
        if (!canView) {
          return null
        }
        return (
          <IncomeStatement
            data={data}
            isDataValidating={isFetching}
            onUpdate={async ({ from, to }) => {
              await updateData(toISOFormat(from), toISOFormat(to))
            }}
            onBid={notifyBot}
            initialRange={initialRange}
          />
        )
      }}
    </IncomeReportConnector>
  )
})

IncomeReport.displayName = 'IncomeReport'
