import axios, { AxiosResponse } from 'axios'
import { BaseAction } from 'redux-actions'
import { ThunkAction } from 'redux-thunk'

import { State } from 'reducers'
import {
  failure,
  start,
  success,
} from 'api/park/transactions/paymentOrder/actions'

export type THandleRequest = ThunkAction<
  Promise<BlobPart | void>,
  State,
  undefined,
  BaseAction
>

export const HANDLE_REQUEST: BaseAction['type'] =
  'api/park/transactions/payment-order->HANDLE_REQUEST'

export const handleRequest = ({ data }: any): THandleRequest => async (
  dispatch
) => {
  dispatch(start())

  function getFileName(contentDisposition: string, fileName: string) {
    let result = fileName
    const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
    if (fileNameMatch && fileNameMatch.length === 2) {
      const newFileName = fileNameMatch[1]
      result = newFileName
    }
    return result
  }

  try {
    const response: AxiosResponse<BlobPart> = await axios({
      url: 'erp/1/park/transactions/payment-order',
      method: 'POST',
      responseType: 'blob',
      data,
    })
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: response.headers['content-type'] })
    )
    const link = document.createElement('a')
    link.href = url
    let fileName = 'paymentOrder.txt'
    const contentDisposition = response.headers['content-disposition']
    if (contentDisposition) {
      fileName = getFileName(contentDisposition, fileName)
    }
    link.setAttribute('download', fileName)

    document.body.appendChild(link)
    link.click()
    link.remove()
    window.URL.revokeObjectURL(url)

    dispatch(success())
    return Promise.resolve(response.data)
  } catch (e) {
    if (e.response) {
      dispatch(failure(e.response.data))
    }
  }
}
