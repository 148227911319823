import axios, { AxiosResponse } from 'axios'
import { BaseAction } from 'redux-actions'
import { ThunkAction } from 'redux-thunk'

import { State } from 'reducers'
import { SubscriptionPaymentsResponse } from 'api/types'
import {
  failure,
  start,
  success,
  update,
} from 'api/park/subscription/payments/actions'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  BaseAction
>
export const HANDLE_REQUEST: BaseAction['type'] =
  'api/park/subscription/payments->HANDLE_REQUEST'

export const handleRequest = (
  limit: number,
  page: number
): THandleRequest => async (dispatch) => {
  dispatch(start())
  try {
    const response: AxiosResponse<SubscriptionPaymentsResponse> = await axios.get(
      'erp/1/park/subscription/payments',
      { params: { limit, offset: limit * (page - 1) } }
    )
    dispatch(update(response.data))
    dispatch(success())
  } catch (e) {
    if (e.response) {
      dispatch(failure(e.response.data))
    }
  }
}
