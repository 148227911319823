import React, { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { PaymentSettings } from 'api/types'
import { ContainerProps } from 'drivers/features/driverSettingsTab/containers/DriverSettingsTabContainer'

import { Loader } from 'shared/ui/loader'
import { PureDriverSettingsTab } from 'drivers/features/driverSettingsTab/organisms'

export const DriverSettingsTab: React.FC<ContainerProps> = ({
  bankType,
  data,
  userId,
  driverSettings,
  fetchDriverSettings,
  fetchParkSettings,
  fetchCreateIndividualSettings,
  fetchEditIndividualSettings,
  fetchAssignSettings,
  fetchCreateGroupSettings,
}) => {
  const { driver_id: driverId } = useParams<{ driver_id: string }>()
  useEffect(() => {
    const getData = async () => {
      await fetchParkSettings()
      await fetchDriverSettings(driverId)
    }
    getData()
  }, [driverId, fetchDriverSettings, fetchParkSettings])

  const handleEditIndividualSettings = useCallback(
    async (values: Partial<PaymentSettings>) => {
      await fetchEditIndividualSettings(
        { ...driverSettings, ...values },
        driverId
      )
      await fetchDriverSettings(driverId)
    },
    [driverSettings, driverId, fetchDriverSettings, fetchEditIndividualSettings]
  )

  const handleCreateIndividualSettings = useCallback(
    async (values: Partial<PaymentSettings>) => {
      await fetchCreateIndividualSettings(
        { ...driverSettings, ...values },
        driverId
      )
      await fetchDriverSettings(driverId)
    },
    [
      driverSettings,
      driverId,
      fetchCreateIndividualSettings,
      fetchDriverSettings,
    ]
  )

  const handleAssignSettings = useCallback(
    async (settingsId: string) => {
      if (userId) {
        await fetchAssignSettings(settingsId, userId)
        await fetchDriverSettings(driverId)
      }
    },
    [driverId, fetchAssignSettings, fetchDriverSettings, userId]
  )

  const handleCreateGroupSettings = useCallback(
    async (settingsName: string) => {
      const settings: Partial<PaymentSettings> = {
        ...driverSettings,
        is_group: true,
        name: settingsName,
      }
      await fetchCreateGroupSettings(settings)
      await fetchParkSettings()
      await fetchDriverSettings(driverId)
    },
    [
      driverSettings,
      driverId,
      fetchCreateGroupSettings,
      fetchDriverSettings,
      fetchParkSettings,
    ]
  )

  if (!data) {
    return <Loader absolute />
  }

  return (
    <PureDriverSettingsTab
      bankType={bankType}
      data={data}
      onEditIndividualSettings={handleEditIndividualSettings}
      onCreateIndividualSettings={handleCreateIndividualSettings}
      onAssignSettings={handleAssignSettings}
      onCreateGroupSettings={handleCreateGroupSettings}
    />
  )
}
