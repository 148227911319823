import styled from 'styled-components'

interface FilterBlock {
  big?: boolean
  filterBlockWidth?: number
}

export const FilterBlock = styled.div<FilterBlock>`
  position: absolute;
  top: calc(100% + 11px);
  right: 0;
  background-color: ${({ theme }) => theme.pallete.white};
  box-shadow: 0 3px 10px rgba(5, 16, 34, 0.1);
  border-radius: 4px;
  width: ${({ big, filterBlockWidth }) =>
    `${filterBlockWidth || (!big ? 269 : 640)}px`};
  z-index: 10;

  .muk-checkbox-wrapper {
    width: 100%;
  }

  .actions-item {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`

export const LargeWrapper = styled.div<{ withLockOverlay?: boolean }>`
  display: inline-flex;
  position: relative;
  background-color: transparent;
  border-radius: 4px;

  ${({ withLockOverlay }) =>
    withLockOverlay &&
    `
      opacity: 0.4;
    `}
`

export const LargeItem = styled.div``

interface ListItemsWrapper {
  column?: boolean
}

export const ListItemsWrapper = styled.div<ListItemsWrapper>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .list-item {
    max-width: ${(props) => (props.column ? '100%' : '50%')};
    width: 100%;
  }
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
`
