import React, { useMemo } from 'react'

import { makeDefaultDateRange } from 'common/utils/helpers'
import { toISOFormat } from 'common/utils/dates'
import DateRangePicker from 'common/components/dateRangePicker/DateRangePicker'
import {
  Col,
  ContentCard,
  ContentCardBody,
  ContentCardHeader,
  HBox,
  Row,
  VBox,
} from 'shared/ui'
import { ContainerProps } from 'analytics/features/statisticsWeekdayWithdraw/container/StatisticsWeekdayWithdrawContainer'
import { BodyText, CaptionText } from 'ui-kit/components'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const WeekdayLabel = styled(Col)`
  flex: 1;
`

const WeekdayCount = styled(Col)`
  text-align: right;
  white-space: nowrap;
  width: 100px;
`

const WeekdayAmount = styled(Col)`
  text-align: right;
  white-space: nowrap;
  width: 150px;
`

const TextRight = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`

const PaddingRow = styled(Row)`
  padding-left: ${({ theme }) => `${theme.paddings.main}px`};
  padding-right: ${({ theme }) => `${theme.paddings.main}px`};
`

const HoveredRow = styled(PaddingRow)`
  &:hover {
    background: rgba(234, 238, 247, 0.3);
  }
`

const now = new Date()
now.setDate(now.getDate() - 1)

const minDate = new Date(now)
minDate.setMonth(minDate.getMonth() - 12)

export const StatisticsWeekdayWithdraw: React.FC<ContainerProps> = ({
  statisticsWeekdayWithdraw,
  isStatisticsWeekdayWithdrawFetching,
  fetchStatisticsWeekdayWithdraw,
}) => {
  const { from, to, dateFrom, dateTo } = makeDefaultDateRange()
  const isChartVisible = statisticsWeekdayWithdraw.length > 0

  const datePicker = useMemo(() => {
    const handleClearDateRange = () => {
      fetchStatisticsWeekdayWithdraw(from, to)
    }

    const handleChangeDateRange = (newFrom: Date, newTo: Date) => {
      const isChangeCompleted = newFrom !== null && newTo !== null
      if (isChangeCompleted) {
        fetchStatisticsWeekdayWithdraw(toISOFormat(newFrom), toISOFormat(newTo))
      }
    }

    return (
      <DateRangePicker
        hasCloseButton={false}
        maxDate={now}
        minDate={minDate}
        dateFrom={dateFrom}
        dateTo={dateTo}
        onChangeDate={handleChangeDateRange}
        onClearDates={handleClearDateRange}
        disabled={!isChartVisible}
      />
    )
  }, [
    dateFrom,
    dateTo,
    fetchStatisticsWeekdayWithdraw,
    from,
    to,
    isChartVisible,
  ])

  return (
    <Wrapper>
      <ContentCard>
        <ContentCardHeader title="Выводы по дням недели">
          {datePicker}
        </ContentCardHeader>
        <ContentCardBody
          isLoading={!isChartVisible || isStatisticsWeekdayWithdrawFetching}
          noPadding
        >
          <HBox />
          <PaddingRow>
            <WeekdayLabel />
            <VBox />
            <WeekdayCount>
              <BodyText fontWeight="semi-bold">
                Ср. количество<sup>*</sup>
              </BodyText>
            </WeekdayCount>
            <VBox />
            <WeekdayAmount>
              <BodyText fontWeight="semi-bold">
                Ср. сумма<sup>*</sup>
              </BodyText>
            </WeekdayAmount>
            <VBox />
            <WeekdayAmount>
              <BodyText fontWeight="semi-bold">Общая сумма</BodyText>
            </WeekdayAmount>
          </PaddingRow>
          {statisticsWeekdayWithdraw.map((item) => (
            <React.Fragment key={item.weekday}>
              <HBox x={0.3} />
              <HoveredRow>
                <WeekdayLabel>
                  <BodyText
                    color={[6, 7].includes(item.weekday) ? 'red' : undefined}
                  >
                    {item.label_weekday}
                  </BodyText>
                </WeekdayLabel>
                <VBox />
                <WeekdayCount>
                  <BodyText>{item.count}</BodyText>
                </WeekdayCount>
                <VBox />
                <WeekdayAmount>
                  <BodyText>{item.avg_amount} ₽</BodyText>
                </WeekdayAmount>
                <VBox />
                <WeekdayAmount>
                  <BodyText>{item.total_amount} ₽</BodyText>
                </WeekdayAmount>
              </HoveredRow>
            </React.Fragment>
          ))}
          <HBox x={0.5} />
          <PaddingRow>
            <TextRight>
              <CaptionText>*Усредненные значения в день за период</CaptionText>
            </TextRight>
          </PaddingRow>
          <HBox />
        </ContentCardBody>
      </ContentCard>
    </Wrapper>
  )
}
