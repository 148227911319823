import React, { useEffect } from 'react'
import { Switch, useParams } from 'react-router-dom'

import { BankType, RoutesPath } from 'common/enums'
import { RouteTab } from 'common/types/local/routeTab'
import { PrivateRoute } from 'shared/router'
import { HBox, Loader, Tabs } from 'shared/ui'
import { DriverNotRegistered } from 'drivers/features/driverDetails/molecules'
import { DriverInfoContainer } from 'drivers/features/driverInfo'
import { DriverPaymentsContainer } from 'drivers/features/driverPayments'
import { DriverSettingsTabContainer } from 'drivers/features/driverSettingsTab'
import { DriverAccountsTabContainer } from 'drivers/features/driverAccountsTab'
import { DriverCardsTabContainer } from 'drivers/features/driverCardsTab'
import { ContainerProps } from 'drivers/features/driverDetails/containers/DriverDetailsContainer'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  min-height: 100%;
  padding: ${({ theme }) => theme.paddings.main}px;
`

export const DriverDetails: React.FC<ContainerProps> = React.memo(
  ({
    match,
    isFetching,
    info,
    hasUserId,
    canViewDriverRequisites,
    canChangeAutoTransfer,
    canChangePaymentsConditions,
    canViewPaymentsSettings,
    bankType,
    onDidMount,
    reset,
  }) => {
    const isBankTypeAlfa = bankType === BankType.Alfa
    const isBankTypeAlfaE2C = bankType === BankType.AlfaE2C
    const isBankTypeAzon = bankType === BankType.Azon
    const isBankTypeManual = bankType === BankType.Manual
    const isBankTypeQiwi = bankType === BankType.Qiwi
    const isBankTypeModul = bankType === BankType.Modul
    const isBankTypeTinkoff = bankType === BankType.Tinkoff

    const { driver_id } = useParams<{ driver_id: string }>()

    useEffect(() => {
      onDidMount(driver_id)
      return () => {
        reset()
      }
    }, [driver_id, onDidMount, reset])

    const routeTabs: RouteTab[] = [
      { label: 'Платежи', path: match.url, exact: true },
    ]
    if (
      canChangeAutoTransfer &&
      canChangePaymentsConditions &&
      canViewPaymentsSettings
    ) {
      routeTabs.push({
        label: 'Настройки вывода',
        path: `${match.url}/settings`,
        exact: true,
      })
    }
    if (canViewDriverRequisites) {
      if (isBankTypeAlfa || isBankTypeManual || isBankTypeAzon) {
        routeTabs.push({
          label: 'Счета',
          path: `${match.url}/accounts`,
          exact: true,
        })
      } else if (
        isBankTypeQiwi ||
        isBankTypeModul ||
        isBankTypeTinkoff ||
        isBankTypeAlfaE2C
      ) {
        routeTabs.push({
          label: 'Карты',
          path: `${match.url}/cards`,
          exact: true,
        })
      }
    }

    const renderContent = () => {
      if (isFetching) {
        return <Loader absolute />
      }

      if (!info) {
        return null
      }

      return (
        <>
          <DriverInfoContainer />
          {hasUserId ? (
            <>
              <HBox />
              <Tabs list={routeTabs} padding={0} />
              <HBox />
              <Switch>
                <PrivateRoute
                  exact
                  path={RoutesPath.DriverDetails}
                  component={DriverPaymentsContainer}
                />
                {canChangeAutoTransfer &&
                canChangePaymentsConditions &&
                canViewPaymentsSettings ? (
                  <PrivateRoute
                    exact
                    path={RoutesPath.DriverSettings}
                    component={DriverSettingsTabContainer}
                  />
                ) : null}
                {(isBankTypeAlfa || isBankTypeManual || isBankTypeAzon) && (
                  <PrivateRoute
                    exact
                    path={RoutesPath.DriverAccounts}
                    component={DriverAccountsTabContainer}
                  />
                )}
                {(isBankTypeQiwi ||
                  isBankTypeModul ||
                  isBankTypeTinkoff ||
                  isBankTypeAlfaE2C) && (
                  <PrivateRoute
                    exact
                    path={RoutesPath.DriverCards}
                    component={DriverCardsTabContainer}
                  />
                )}
              </Switch>
            </>
          ) : (
            <DriverNotRegistered phone={info.phone} />
          )}
        </>
      )
    }

    return <Wrapper>{renderContent()}</Wrapper>
  }
)
