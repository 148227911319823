import styled from 'styled-components'

export interface LimitContainerProps {
  maxWidth?: number | string
  maxHeight?: number | string
}

const getCssValue = (value?: number | string) => {
  if (value === undefined) return null
  return typeof value === 'number' ? `${value}px` : value
}

export const LimitContainer = styled.div<LimitContainerProps>`
  max-width: ${({ maxWidth }) => getCssValue(maxWidth)};
  max-height: ${({ maxHeight }) => getCssValue(maxHeight)};
`
