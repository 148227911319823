import { createApiReducer } from 'common/utils/reduxApiFactory'
import {
  deleteDriverPaymentSettingsActions,
  getDriverPaymentSettingsActions,
  patchDriverPaymentSettingsActions,
  postDriverPaymentSettingsActions,
} from 'api/driversById/payment-settings/actions'
import { DriverPaymentSettingsState } from 'api/driversById/payment-settings/types'

export const initialState: DriverPaymentSettingsState = {
  status: null,
  data: null,
}

export const getDriverPaymentSettingsReducer = createApiReducer(
  getDriverPaymentSettingsActions,
  initialState
)

export const postDriverPaymentSettingsReducer = createApiReducer(
  postDriverPaymentSettingsActions,
  initialState
)

export const deleteDriverPaymentSettingsReducer = createApiReducer(
  deleteDriverPaymentSettingsActions,
  initialState
)

export const patchDriverPaymentSettingsReducer = createApiReducer(
  patchDriverPaymentSettingsActions,
  initialState
)
