import { connect } from 'react-redux'
import { State } from 'reducers'
import { getIsFetching } from 'settings/features/tariffTab/selectors'
import { onDidMount } from 'settings/features/tariffTab/thunks'
import { TariffTab } from 'settings/features/tariffTab/organisms'
import { MyThunkDispatch } from 'api/reducer'

const mapStateToProps = (state: State) => ({
  isFetching: getIsFetching(state),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  onDidMount: () => dispatch(onDidMount()),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const TariffTabContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TariffTab)
