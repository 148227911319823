import Alert from 'react-s-alert'
import { apiActions } from 'api'
import { MyThunkDispatch, MyThunkResult } from 'api/reducer'
import { currencyToSub } from 'common/utils/formatters'
import { ModalsNames } from 'common/enums'
import { ApiCode } from 'common/types/api/codes'
import { modalsActions } from 'shared/modals'
import { FormProps as NotificationsSettingsFormProps } from 'settings/features/notificationsSettings/containers/NotificationsSettingsContainer'
import { FormProps as NotificationsQiwiSettingsFormProps } from 'settings/features/notificationsSettings/containers/AddNotificationsQiwiSettingsModalContainer'

export const onSaveNotificationsSettings: ({
  values,
}: {
  values: NotificationsSettingsFormProps
}) => MyThunkResult<Promise<void | boolean>> = ({ values }) => (
  dispatch: MyThunkDispatch
) => {
  const data: {
    transactions_notify?: boolean
    insufficient_fuel_deposit?: boolean
    park_subscription_settings: {
      successful_payment_notify?: boolean
      failed_payment_notify?: boolean
    }
    qiwi_settings?: {
      email_notifications?: boolean
    }
    certificate_settings?: {
      certificate_notify?: boolean
    }
  } = { park_subscription_settings: {} }
  if ('transactions_notify' in values) {
    data.transactions_notify = values.transactions_notify
  }
  if ('insufficient_fuel_deposit' in values) {
    data.insufficient_fuel_deposit = values.insufficient_fuel_deposit
  }
  if ('successful_payment_notify' in values) {
    data.park_subscription_settings.successful_payment_notify =
      values.successful_payment_notify
  }
  if ('failed_payment_notify' in values) {
    data.park_subscription_settings.failed_payment_notify =
      values.failed_payment_notify
  }
  if ('email_notifications' in values) {
    data.qiwi_settings = {
      email_notifications: values.email_notifications,
    }
  }
  if ('certificate_notify' in values) {
    data.certificate_settings = {
      certificate_notify: values.certificate_notify,
    }
  }
  return dispatch(
    apiActions.park.notificationsPatch.handleRequest({
      values: data,
    })
  ).then(
    () => undefined,
    () => {
      Alert.error('Ошибка', {
        customFields: {
          text: 'Не удалось сохранить',
        },
      })
      return true
    }
  )
}

export const onSaveQiwiSettingsMinBalance: ({
  values,
}: {
  values: NotificationsQiwiSettingsFormProps
}) => MyThunkResult<void> = ({ values }) => (dispatch: MyThunkDispatch) => {
  return dispatch(
    apiActions.park.notificationsPatch.handleRequest({
      values: {
        qiwi_settings: {
          min_balance: values.min_balance
            ? currencyToSub(parseFloat(values.min_balance))
            : null,
        },
      },
    })
  ).then(
    () => {
      dispatch(apiActions.park.notificationsGet.handleRequest())
      dispatch(
        modalsActions.closeModal(ModalsNames.AddNotificationsQiwiSettings)
      )
    },
    (reason) => {
      if (
        reason.response &&
        reason.response.data.code === ApiCode.InputValidationError &&
        reason.response.data.payload
      ) {
        throw reason.response.data.payload
      }
    }
  )
}
