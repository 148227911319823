import React, { useCallback } from 'react'
import { change, WrappedFieldProps } from 'redux-form'

import { store } from 'store'

import { Radio } from 'ui-kit/components'
import { HBox } from 'shared/ui'

interface Props {
  className?: string
  inputId?: string
  disabled?: boolean
  checked?: boolean
  options: {
    value: string
    label: string
    alt?: () => React.ReactNode
  }[]
}

export const RadioGroup: React.FC<Props & WrappedFieldProps> = ({
  options,
  inputId,
  disabled,
  input,
  meta,
}) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      input.onChange(e)
      store.dispatch(change(meta.form, input.name, e.target.value))
    },
    [input, meta.form]
  )
  return (
    <>
      {options.map((option, index, array) => (
        <React.Fragment key={option.value}>
          <Radio
            name={input.name}
            inputId={inputId}
            onChange={handleChange}
            disabled={disabled}
            value={option.value}
            checked={option.value === input.value}
            reverse
            alt={option.alt}
          >
            {option.label}
          </Radio>
          {index < array.length - 1 ? <HBox /> : null}
        </React.Fragment>
      ))}
    </>
  )
}
