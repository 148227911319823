import { Pagination } from 'api/types'
import { FuelDepositType } from 'ui-kit/organisms/FuelTransactions'

export enum DepositType {
  Fuel = 'fuel',
  CashBack = 'cashback',
}

export type MappedDepositType = {
  [index in FuelDepositType]: DepositType
}

export type MappedFuelDepositType = {
  [index in DepositType]: FuelDepositType
}

export const mappedDepositType: MappedDepositType = {
  0: DepositType.Fuel,
  1: DepositType.CashBack,
}

export const mappedFuelDepositType: MappedFuelDepositType = {
  cashback: FuelDepositType.CashBack,
  fuel: FuelDepositType.Fuel,
}

export type DepositHistory = {
  id: string
  operation_id: string
  amount: {
    amount: number
  }
  created_at: string
  deposit_type: DepositType
  description: string
  is_positive?: boolean
}

export interface PaginatedDepositHistory extends Pagination {
  history: DepositHistory[]
}
