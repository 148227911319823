import {
  EuiComboBox,
  EuiSpacer,
  EuiRadioGroup,
  EuiText,
  EuiLink,
  EuiSelect,
  EuiButtonEmpty,
  EuiButton,
  EuiComboBoxOptionOption,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
} from '@elastic/eui'
import Column from 'elastic-ui/referral-program/atoms/Column'
import { ProgramSettings } from 'elastic-ui/referral-program/types'
import Flexbox from 'elastic-ui/shared/atoms/Flexbox'
import HorizontalSpacer from 'elastic-ui/shared/atoms/HorizontalSpacer'
import RenderGate from 'elastic-ui/shared/atoms/RenderGate'
import React from 'react'
import EuiFormEnhanced from 'elastic-ui/shared/atoms/EuiFormEnhanced'
import EuiFormRowEnhanced from 'elastic-ui/shared/atoms/EuiFormRowEnhanced'
import EuiTextAreaEnhanced from 'elastic-ui/shared/atoms/EuiTextAreaEnhanced'
import EuiFieldTextEnhanced from 'elastic-ui/shared/atoms/EuiFieldTextEnhanced'
import EuiFieldNumberEnhanced from 'elastic-ui/shared/atoms/EuiFieldNumberEnhanced'
import { FieldAdder } from '../../molecules/FieldAdder'
import { getBonusAmountLabelFormBonusType } from './utils'
import {
  AggregatorAccount,
  InviterBonusTypes,
  InviterBonusFrequencies,
} from './types'
import TermsPreviewForDriver from '../TermsPreviewForDriver/TermsPreviewForDriver'

interface Props {
  onUpdateTermsClick: (settings: ProgramSettings) => void
  onCancelClick: () => void
  isLoading: boolean
  isFirstTime: boolean
  aggregatorAccounts: AggregatorAccount[]
  programSettings: ProgramSettings
  company: string
}

const initialValidationMessages = {
  selectedAccounts: '',
  bonusAmount: '',
  numberOfUnpaidDrives: '',
  minimumAmountOfDrivesForInvitee: '',
  periodForMinimumAmountOfDrivesForInvitee: '',
  paymentDurationForInviter: '',
  customTitle: '',
  customShortTerms: '',
  customFullTerms: '',
}

type MessageKey = keyof typeof initialValidationMessages

const parseFloatOrUndefined = (value?: string) =>
  value === undefined ? undefined : parseFloat(value)

const maxWidthForNumericFields = 300
const TermsEdit: React.FC<Props> = ({
  onUpdateTermsClick,
  onCancelClick,
  isLoading,
  isFirstTime,
  aggregatorAccounts,
  programSettings,
  company,
}) => {
  const [
    selectedAccountsInput,
    setSelectedAccountsInput,
  ] = React.useState<HTMLInputElement | null>(null)

  const [
    selectedAggregatorAccounts,
    setSelectedAggregatorAccounts,
  ] = React.useState<AggregatorAccount[]>(
    programSettings.selectedAggregatorAccounts
  )

  const [inviterBonusType, setInviterBonusType] = React.useState(
    programSettings.inviterBonusType
  )

  const [bonusAmountValue, setBonusAmountValue] = React.useState(
    programSettings.bonusAmount.toString()
  )

  const [
    numberOfUnpaidDrivesValue,
    setNumberOfUnpaidDrivesValue,
  ] = React.useState(programSettings.numberOfUnpaidDrives?.toString())

  const [inviterBonusFrequency, setInviterBonusFrequency] = React.useState(
    programSettings.inviterBonusFrequency
  )

  const [
    minimumAmountOfDrivesForInviteeValue,
    setMinimumAmountOfDrivesForInviteeValue,
  ] = React.useState(
    programSettings.minimumAmountOfDrivesForInvitee?.toString()
  )

  const [
    periodForMinimumAmountOfDrivesForInviteeValue,
    setPeriodForMinimumAmountOfDrivesForInviteeValue,
  ] = React.useState(
    programSettings.periodForMinimumAmountOfDrivesForInvitee?.toString()
  )

  const [
    paymentDurationForInviterValue,
    setPaymentDurationForInviterValue,
  ] = React.useState(programSettings.paymentDurationForInviter?.toString())

  const [
    dontPayIfInviterIsNotTakingDrives,
    setDontPayIfInviterIsNotTakingDrives,
  ] = React.useState(programSettings.dontPayIfInviterIsNotTakingDrives)

  const [customTitleValue, setCustomTitleValue] = React.useState(
    programSettings.customTitle
  )

  const [customShortTermsValue, setCustomShortTermsValue] = React.useState(
    programSettings.customShortTerms
  )

  const [customFullTermsValue, setCustomFullTermsValue] = React.useState(
    programSettings.customFullTerms
  )

  const [validationMessages, setValidationMessages] = React.useState(
    initialValidationMessages
  )

  const [
    isMinimumAmountOfDrivesForInviteeAdderVisible,
    setIsMinimumAmountOfDrivesForInviteeAdderVisible,
  ] = React.useState(minimumAmountOfDrivesForInviteeValue === undefined)

  const [
    isPeriodForMinimumAmountOfDrivesForInviteeAdderVisible,
    setIsPeriodForMinimumAmountOfDrivesForInviteeAdderVisible,
  ] = React.useState(
    periodForMinimumAmountOfDrivesForInviteeValue === undefined
  )

  const [
    isPaymentDurationForInviterAdderVisible,
    setIsPaymentDurationForInviterAdderVisible,
  ] = React.useState(paymentDurationForInviterValue === undefined)

  const [
    isDontPayIfInviterIsNotTakingDrivesAdderVisible,
    setIsDontPayIfInviterIsNotTakingDrivesAdderVisible,
  ] = React.useState(!dontPayIfInviterIsNotTakingDrives)

  const [
    isCustomTermsAdderVisible,
    setIsCustomTermsAdderVisible,
  ] = React.useState(
    [customTitleValue, customShortTermsValue, customFullTermsValue].every(
      (x) => !x?.length
    )
  )

  const [
    isCustomTitleHackyRequired,
    setIsCustomTitleHackyRequired,
  ] = React.useState(!isCustomTermsAdderVisible)

  const [
    isCustomShortTermsHackyRequired,
    setIsCustomShortTermsHackyRequired,
  ] = React.useState(!isCustomTermsAdderVisible)

  const [
    isCustomFullTermsHackyRequired,
    setIsCustomFullTermsHackyRequired,
  ] = React.useState(!isCustomTermsAdderVisible)

  const setValidationMessage = (key: MessageKey, message: string) =>
    setValidationMessages((messages) => ({
      ...messages,
      [key]: message,
    }))

  const resetValidationMessage = (key: MessageKey) =>
    setValidationMessages((messages) => ({
      ...messages,
      [key]: '',
    }))

  const handleSelectedAccountsChange = (
    newAccounts: EuiComboBoxOptionOption[]
  ) => {
    setSelectedAggregatorAccounts(
      aggregatorAccounts.filter((account) =>
        newAccounts.find((selectedAccount) => account.id === selectedAccount.id)
      )
    )
  }

  const handleSelectedAccountsValidation = () => {
    if (selectedAggregatorAccounts.length === 0) {
      setValidationMessage(
        'selectedAccounts',
        'Пожалуйста, укажите как минимум одну учетную запись.'
      )
      return false
    }
    resetValidationMessage('selectedAccounts')
    return true
  }

  const handleInviterBonusTypeChange = (id: string) => {
    resetValidationMessage('bonusAmount')
    setInviterBonusType(id as InviterBonusTypes)
    if (id === InviterBonusTypes.FixedBonus) {
      setNumberOfUnpaidDrivesValue(undefined)
    }
  }

  const handleBonusAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    resetValidationMessage('bonusAmount')
    setBonusAmountValue(e.target.value)
  }

  const handleBonusAmountValidation = (
    e: React.SyntheticEvent<HTMLInputElement>
  ) => {
    setValidationMessage('bonusAmount', e.currentTarget.validationMessage)
  }

  const handleNumberOfUnpaidDrivesChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    resetValidationMessage('numberOfUnpaidDrives')
    setNumberOfUnpaidDrivesValue(e.target.value)
  }

  const handleNumberOfUnpaidDrivesValidation = (
    e: React.SyntheticEvent<HTMLInputElement>
  ) => {
    setValidationMessage(
      'numberOfUnpaidDrives',
      e.currentTarget.validationMessage
    )
  }

  const handleInviterBonusFrequencyChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setInviterBonusFrequency(e.target.value as InviterBonusFrequencies)
    if (e.target.value === InviterBonusFrequencies.Daily) {
      setPaymentDurationForInviterValue(undefined)
    } else {
      setPeriodForMinimumAmountOfDrivesForInviteeValue(undefined)
    }
  }

  const handleMinimumAmountOfDrivesForInviteeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    resetValidationMessage('minimumAmountOfDrivesForInvitee')
    resetValidationMessage('numberOfUnpaidDrives')
    setMinimumAmountOfDrivesForInviteeValue(e.target.value)
  }

  const handleMinimumAmountOfDrivesForInviteeValidation = (
    e: React.SyntheticEvent<HTMLInputElement>
  ) => {
    setValidationMessage(
      'minimumAmountOfDrivesForInvitee',
      e.currentTarget.validationMessage
    )
  }

  const handlePeriodForMinimumAmountOfDrivesForInviteeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    resetValidationMessage('periodForMinimumAmountOfDrivesForInvitee')
    setPeriodForMinimumAmountOfDrivesForInviteeValue(e.target.value)
  }

  const handlePeriodForMinimumAmountOfDrivesForInviteeValidation = (
    e: React.SyntheticEvent<HTMLInputElement>
  ) => {
    setValidationMessage(
      'periodForMinimumAmountOfDrivesForInvitee',
      e.currentTarget.validationMessage
    )
  }

  const handlePaymentDurationForInviterChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    resetValidationMessage('paymentDurationForInviter')
    setPaymentDurationForInviterValue(e.target.value)
  }

  const handlePaymentDurationForInviterValidation = (
    e: React.SyntheticEvent<HTMLInputElement>
  ) => {
    setValidationMessage(
      'paymentDurationForInviter',
      e.currentTarget.validationMessage
    )
  }

  const handleCustomTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    resetValidationMessage('customTitle')
    setCustomTitleValue(e.target.value)
  }

  const handleCustomTitleBlur = () => {
    setIsCustomTitleHackyRequired(true)
  }

  const handleCustomTitleValidation = (
    e: React.SyntheticEvent<HTMLInputElement>
  ) => {
    setValidationMessage('customTitle', e.currentTarget.validationMessage)
  }

  const handleCustomShortTermsChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    resetValidationMessage('customShortTerms')
    setCustomShortTermsValue(e.target.value)
  }

  const handleCustomShortTermsBlur = () => {
    setIsCustomShortTermsHackyRequired(true)
  }

  const handleCustomShortTermsValidation = (
    e: React.SyntheticEvent<HTMLInputElement>
  ) => {
    setValidationMessage('customShortTerms', e.currentTarget.validationMessage)
  }

  const handleCustomFullTermsChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    resetValidationMessage('customFullTerms')
    setCustomFullTermsValue(e.target.value)
  }

  const handleCustomFullTermsBlur = () => {
    setIsCustomFullTermsHackyRequired(true)
  }

  const handleCustomFullTermsValidation = (
    e: React.SyntheticEvent<HTMLTextAreaElement>
  ) => {
    setValidationMessage('customFullTerms', e.currentTarget.validationMessage)
  }

  const handleCustomValidation = () => {
    const isSelectedAccountsFieldValid = handleSelectedAccountsValidation()

    if (selectedAccountsInput && !isSelectedAccountsFieldValid) {
      return selectedAccountsInput
    }

    return true
  }

  const handleBeforeCustomValidation = () => {
    if (!isCustomTermsAdderVisible) {
      setIsCustomTitleHackyRequired(true)
      setIsCustomShortTermsHackyRequired(true)
      setIsCustomFullTermsHackyRequired(true)
    }
  }

  const isFormInvalid = Object.values(validationMessages).some(Boolean)
  const isBonusComesFromCommisionShare =
    inviterBonusType === InviterBonusTypes.ParkCommisionShare

  return (
    <EuiFormEnhanced
      isInvalid={isFormInvalid}
      invalidCallout="none"
      isDisabled={isLoading}
      onSubmit={() => {
        onUpdateTermsClick({
          bonusAmount: parseFloat(bonusAmountValue),
          dontPayIfInviterIsNotTakingDrives,
          id: programSettings.id,
          inviterBonusFrequency,
          inviterBonusType,
          selectedAggregatorAccounts,
          customTitle: customTitleValue,
          customShortTerms: customShortTermsValue,
          customFullTerms: customFullTermsValue,
          minimumAmountOfDrivesForInvitee: parseFloatOrUndefined(
            minimumAmountOfDrivesForInviteeValue
          ),
          numberOfUnpaidDrives: parseFloatOrUndefined(
            numberOfUnpaidDrivesValue
          ),
          paymentDurationForInviter: parseFloatOrUndefined(
            paymentDurationForInviterValue
          ),
          periodForMinimumAmountOfDrivesForInvitee: parseFloatOrUndefined(
            periodForMinimumAmountOfDrivesForInviteeValue
          ),
        })
      }}
      onCustomValidation={handleCustomValidation}
    >
      <Column>
        <EuiFormRowEnhanced
          label="Учетные записи, для которых действует программа"
          error={validationMessages.selectedAccounts}
        >
          <EuiComboBox
            placeholder="Выберите учетные записи"
            fullWidth
            options={aggregatorAccounts.map((account) => ({
              label: account.name,
              id: account.id,
            }))}
            selectedOptions={selectedAggregatorAccounts.map((account) => ({
              label: account.name,
              id: account.id,
              color: 'default',
            }))}
            onChange={handleSelectedAccountsChange}
            onBlur={handleSelectedAccountsValidation}
            isInvalid={Boolean(validationMessages.selectedAccounts)}
            inputRef={setSelectedAccountsInput}
          />
        </EuiFormRowEnhanced>

        <EuiSpacer size="m" />

        <EuiFormRow label="Пригласивший получает" fullWidth>
          <EuiRadioGroup
            idSelected={inviterBonusType}
            options={[
              {
                id: InviterBonusTypes.MoneyForDriving,
                label: 'рубли за количество поездок',
              },
              {
                id: InviterBonusTypes.ParkCommisionShare,
                label: 'процент с комиссии автопарка',
              },
              {
                id: InviterBonusTypes.FixedBonus,
                label: 'фиксированную сумму',
              },
            ]}
            onChange={handleInviterBonusTypeChange}
          />
        </EuiFormRow>

        <EuiSpacer size="m" />

        <EuiFormRowEnhanced
          label="Размер награды"
          error={validationMessages.bonusAmount}
          maxWidth={maxWidthForNumericFields}
        >
          <EuiFieldNumberEnhanced
            append={getBonusAmountLabelFormBonusType(inviterBonusType)}
            value={bonusAmountValue}
            onChange={handleBonusAmountChange}
            onValidation={handleBonusAmountValidation}
            step={0.01}
            min={isBonusComesFromCommisionShare ? 0.1 : 1.0}
            max={isBonusComesFromCommisionShare ? 100 : 5000.0}
          />
        </EuiFormRowEnhanced>

        <EuiSpacer size="m" />

        <RenderGate isOpen={inviterBonusType !== InviterBonusTypes.FixedBonus}>
          {numberOfUnpaidDrivesValue === undefined ? (
            <EuiText>
              Оплачиваются все поездки.{' '}
              <EuiLink onClick={() => setNumberOfUnpaidDrivesValue('5')}>
                Изменить
              </EuiLink>
            </EuiText>
          ) : (
            <EuiFormRowEnhanced
              label="Не оплачиваются первые"
              error={validationMessages.numberOfUnpaidDrives}
              maxWidth={maxWidthForNumericFields}
              helpText={
                !isMinimumAmountOfDrivesForInviteeAdderVisible
                  ? 'Значение не может быть больше минимального количества поездок.'
                  : undefined
              }
            >
              <Flexbox>
                <EuiFieldNumberEnhanced
                  value={numberOfUnpaidDrivesValue}
                  append="поездок"
                  onChange={handleNumberOfUnpaidDrivesChange}
                  onValidation={handleNumberOfUnpaidDrivesValidation}
                  min={1}
                  max={parseInt(
                    minimumAmountOfDrivesForInviteeValue || '500',
                    10
                  )}
                />
                <HorizontalSpacer size="s" />
                <EuiLink
                  onClick={() => {
                    setNumberOfUnpaidDrivesValue(undefined)
                    resetValidationMessage('numberOfUnpaidDrives')
                  }}
                >
                  удалить
                </EuiLink>
              </Flexbox>
            </EuiFormRowEnhanced>
          )}
          <EuiSpacer size="m" />
        </RenderGate>

        <EuiFormRowEnhanced label="Периодичность">
          <EuiSelect
            value={inviterBonusFrequency}
            options={[
              InviterBonusFrequencies.OneTime,
              InviterBonusFrequencies.Daily,
              InviterBonusFrequencies.Weekly,
              InviterBonusFrequencies.Monthly,
            ].map((item) => ({ text: item }))}
            onChange={handleInviterBonusFrequencyChange}
            fullWidth
          />
        </EuiFormRowEnhanced>

        <EuiSpacer size="m" />

        <RenderGate isOpen={!isMinimumAmountOfDrivesForInviteeAdderVisible}>
          <EuiFormRowEnhanced
            label="Приглашенный должен завершить"
            error={validationMessages.minimumAmountOfDrivesForInvitee}
            maxWidth={maxWidthForNumericFields}
          >
            <Flexbox>
              <EuiFieldNumberEnhanced
                value={minimumAmountOfDrivesForInviteeValue}
                append="поездок"
                min={1}
                max={500}
                onChange={handleMinimumAmountOfDrivesForInviteeChange}
                onValidation={handleMinimumAmountOfDrivesForInviteeValidation}
              />
              <HorizontalSpacer size="s" />
              <EuiLink
                onClick={() => {
                  setIsMinimumAmountOfDrivesForInviteeAdderVisible(true)
                  setMinimumAmountOfDrivesForInviteeValue(undefined)
                }}
              >
                удалить
              </EuiLink>
            </Flexbox>
          </EuiFormRowEnhanced>
          <EuiSpacer size="m" />
        </RenderGate>

        {inviterBonusFrequency === InviterBonusFrequencies.OneTime ? (
          <RenderGate
            isOpen={
              !isPeriodForMinimumAmountOfDrivesForInviteeAdderVisible &&
              !isMinimumAmountOfDrivesForInviteeAdderVisible
            }
          >
            <EuiFormRowEnhanced
              label="Срок выполнения"
              error={
                validationMessages.periodForMinimumAmountOfDrivesForInvitee
              }
              maxWidth={maxWidthForNumericFields}
            >
              <Flexbox>
                <EuiFieldNumberEnhanced
                  value={periodForMinimumAmountOfDrivesForInviteeValue}
                  append="дней"
                  min={1}
                  max={366}
                  onChange={
                    handlePeriodForMinimumAmountOfDrivesForInviteeChange
                  }
                  onValidation={
                    handlePeriodForMinimumAmountOfDrivesForInviteeValidation
                  }
                />
                <HorizontalSpacer size="s" />
                <EuiLink
                  onClick={() => {
                    setIsPeriodForMinimumAmountOfDrivesForInviteeAdderVisible(
                      true
                    )
                    setPeriodForMinimumAmountOfDrivesForInviteeValue(undefined)
                  }}
                >
                  удалить
                </EuiLink>
              </Flexbox>
            </EuiFormRowEnhanced>
            <EuiSpacer size="m" />
          </RenderGate>
        ) : (
          <RenderGate isOpen={!isPaymentDurationForInviterAdderVisible}>
            <EuiFormRowEnhanced
              label="Длительность выплат"
              error={validationMessages.paymentDurationForInviter}
              maxWidth={maxWidthForNumericFields}
            >
              <Flexbox>
                <EuiFieldNumberEnhanced
                  value={paymentDurationForInviterValue}
                  append="дней"
                  min={1}
                  max={1000}
                  onChange={handlePaymentDurationForInviterChange}
                  onValidation={handlePaymentDurationForInviterValidation}
                />
                <HorizontalSpacer size="s" />
                <EuiLink
                  onClick={() => {
                    setIsPaymentDurationForInviterAdderVisible(true)
                    setPaymentDurationForInviterValue(undefined)
                  }}
                >
                  удалить
                </EuiLink>
              </Flexbox>
            </EuiFormRowEnhanced>
            <EuiSpacer size="m" />
          </RenderGate>
        )}

        <RenderGate isOpen={!isDontPayIfInviterIsNotTakingDrivesAdderVisible}>
          <EuiText>
            Чтобы получить награду, у пригласившего водителя должно быть 20
            завершенных поездок за последнюю неделю.{' '}
            <EuiLink
              onClick={() => {
                setIsDontPayIfInviterIsNotTakingDrivesAdderVisible(true)
                setDontPayIfInviterIsNotTakingDrives(false)
              }}
            >
              Удалить
            </EuiLink>
          </EuiText>
          <EuiSpacer size="m" />
        </RenderGate>
      </Column>

      <RenderGate isOpen={!isCustomTermsAdderVisible}>
        <EuiFlexGroup>
          <EuiFlexItem>
            <Column>
              <EuiFormRowEnhanced
                label="Заголовок баннера"
                helpText="До 30 символов."
                error={validationMessages.customTitle}
              >
                <EuiFieldTextEnhanced
                  placeholder="Введите заголовок"
                  value={customTitleValue}
                  onChange={handleCustomTitleChange}
                  onBlur={handleCustomTitleBlur}
                  onValidation={handleCustomTitleValidation}
                  maxLength={30}
                  required={isCustomTitleHackyRequired}
                />
              </EuiFormRowEnhanced>

              <EuiSpacer size="m" />

              <EuiFormRowEnhanced
                label="Описание баннера"
                helpText="До 45 символов."
                error={validationMessages.customShortTerms}
              >
                <EuiFieldTextEnhanced
                  placeholder="Введите описание"
                  value={customShortTermsValue}
                  onChange={handleCustomShortTermsChange}
                  onBlur={handleCustomShortTermsBlur}
                  onValidation={handleCustomShortTermsValidation}
                  maxLength={45}
                  required={isCustomShortTermsHackyRequired}
                />
              </EuiFormRowEnhanced>

              <EuiSpacer size="l" />

              <EuiFormRowEnhanced
                label="Подробное описание условий"
                error={validationMessages.customFullTerms}
              >
                <EuiTextAreaEnhanced
                  placeholder="Введите текст полного описания условий"
                  value={customFullTermsValue}
                  onChange={handleCustomFullTermsChange}
                  onBlur={handleCustomFullTermsBlur}
                  onValidation={handleCustomFullTermsValidation}
                  required={isCustomFullTermsHackyRequired}
                />
              </EuiFormRowEnhanced>
              <EuiSpacer size="m" />
              <EuiLink
                onClick={() => {
                  setIsCustomTermsAdderVisible(true)
                  setCustomTitleValue(undefined)
                  setCustomShortTermsValue(undefined)
                  setCustomFullTermsValue(undefined)
                }}
              >
                Вернуть на описание по умолчанию
              </EuiLink>
            </Column>
          </EuiFlexItem>
          <EuiFlexItem>
            <TermsPreviewForDriver
              onShowTermsToDriversClick={() => {}}
              company={company}
              isLoading={false}
              isProgramHidden={false}
              previewTitle={customTitleValue || 'Заголовок'}
              previewDescription={customShortTermsValue || 'Описание программы'}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer size="m" />
      </RenderGate>

      <Column>
        <RenderGate isOpen={isMinimumAmountOfDrivesForInviteeAdderVisible}>
          <FieldAdder
            onClick={() => {
              setIsMinimumAmountOfDrivesForInviteeAdderVisible(false)
              setMinimumAmountOfDrivesForInviteeValue('15')
            }}
          >
            Минимальное количество поездок
          </FieldAdder>
        </RenderGate>

        {inviterBonusFrequency === InviterBonusFrequencies.OneTime ? (
          <RenderGate
            isOpen={
              isPeriodForMinimumAmountOfDrivesForInviteeAdderVisible &&
              !isMinimumAmountOfDrivesForInviteeAdderVisible
            }
          >
            <FieldAdder
              onClick={() => {
                setIsPeriodForMinimumAmountOfDrivesForInviteeAdderVisible(false)
                setPeriodForMinimumAmountOfDrivesForInviteeValue('3')
              }}
            >
              Срок выполнения
            </FieldAdder>
          </RenderGate>
        ) : (
          <RenderGate isOpen={isPaymentDurationForInviterAdderVisible}>
            <FieldAdder
              onClick={() => {
                setIsPaymentDurationForInviterAdderVisible(false)
                setPaymentDurationForInviterValue('30')
              }}
            >
              Длительность выплат
            </FieldAdder>
          </RenderGate>
        )}

        <RenderGate isOpen={isDontPayIfInviterIsNotTakingDrivesAdderVisible}>
          <FieldAdder
            onClick={() => {
              setIsDontPayIfInviterIsNotTakingDrivesAdderVisible(false)
              setDontPayIfInviterIsNotTakingDrives(true)
            }}
          >
            Не начислять, если пригласивший не берет заказы
          </FieldAdder>
        </RenderGate>

        <RenderGate isOpen={isCustomTermsAdderVisible}>
          <FieldAdder
            onClick={() => {
              setIsCustomTermsAdderVisible(false)
              setCustomTitleValue('')
              setCustomShortTermsValue('')
              setCustomFullTermsValue('')
            }}
          >
            Описать условия программы самостоятельно
          </FieldAdder>
        </RenderGate>

        <EuiSpacer size="l" />
        <EuiButton
          type="submit"
          color="primary"
          fill
          onClick={handleBeforeCustomValidation}
        >
          {isFirstTime ? 'Запустить программу' : 'Обновить условия'}
        </EuiButton>
        <RenderGate isOpen={!isFirstTime}>
          <EuiButtonEmpty onClick={onCancelClick}>
            Отменить изменения
          </EuiButtonEmpty>
        </RenderGate>
        <EuiSpacer size="s" />
        <EuiText size="xs">Изменения повлияют на всех водителей.</EuiText>
        <EuiSpacer size="s" />
      </Column>
    </EuiFormEnhanced>
  )
}

export default TermsEdit
