import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  .table-title {
    color: ${({ theme }) => theme.pallete.gray7};
  }

  .list-item-inside-header {
    border-top: none;
    min-height: 55px;
  }

  .row-item-head {
    min-height: initial;
  }

  .row-item {
    padding: 0;

    &-head {
      background-color: ${({ theme }) => theme.pallete.white};

      .list-item-inside {
        &:first-child {
          border-left: transparent;
        }
      }
    }
  }

  .list-item-inside {
    border-top: none;
    border-bottom: none;

    &.checkbox {
      padding: 0;

      > div {
        width: 100%;

        label {
          width: 100%;
          height: 56px;
          justify-content: center;
        }
      }
    }

    &.actions-wrap {
      padding: 0 8px;

      .actions {
        display: flex;

        > span {
          height: 56px;
          width: 40px;
          justify-content: center;
        }
      }
    }
  }
`

export const Body = styled.div<{
  clickable: boolean
  bodyMinHeight: string
  isEmpty?: boolean
}>`
  position: relative;
  min-height: ${({ bodyMinHeight }) => bodyMinHeight};

  ${({ isEmpty }) =>
    isEmpty &&
    css`
      min-height: auto;
    `};

  .row-item {
    background-color: ${({ theme }) => theme.pallete.white};
    border-top: none;
    min-height: initial;

    ${({ clickable }) =>
      clickable &&
      css`
        &:hover {
          background-color: ${({ theme }) => theme.pallete.gray1};
        }
      `}
  }
`
