import React, { useMemo } from 'react'
import {
  ContentCard,
  ContentCardBody,
  ContentCardHeader,
} from 'shared/ui/contentCard'
import { PaymentSettings } from 'api/types'
import { Col, Row } from 'shared/ui/flex'
import { Icon, SubBodyText, SubTitleText } from 'ui-kit/components'
import { currencyToBase } from 'common/utils/formatters'
import { HBox, VBox } from 'shared/ui/spacers'

type Props = {
  settings: PaymentSettings
  onEdit: () => void
}

function settingsToWithdraw(settings: PaymentSettings) {
  let result = null
  const minWithdraw = currencyToBase(settings.min_withdraw_amount)
  const maxWithdraw = settings.max_withdraw_amount
    ? currencyToBase(settings.max_withdraw_amount)
    : null

  const hasMinWithdraw = settings.min_withdraw_amount !== null
  const hasMaxWithdraw = settings.max_withdraw_amount !== null
  if (hasMaxWithdraw && hasMinWithdraw) {
    result = `${minWithdraw} ₽ — ${maxWithdraw} ₽`
  } else if (hasMinWithdraw) {
    result = `от ${minWithdraw} ₽`
  } else if (hasMaxWithdraw) {
    result = `до ${maxWithdraw} ₽`
  }

  return result
}

function settingsToAntiFraud(settings: PaymentSettings) {
  return `Выплаты подтверждаются автоматически, если у водителя больше ${settings.fair_rides} поездок и ${settings.withdraw_transactions_for_auto_withdraw} выводов.`
}

function settingsToWithdrawCommission(settings: PaymentSettings) {
  let result = `${settings.withdraw_commission}%`
  if (settings.min_commission > 0) {
    result += `, минимум ${currencyToBase(settings.min_commission)} ₽`
  }
  return result
}

function settingsToResidentCommission(settings: PaymentSettings) {
  let result = null
  if (settings.non_resident_withdraw_commission > 0) {
    result = `${settings.non_resident_withdraw_commission}%`
  }
  return result
}

export const PurePaymentSettingCard: React.FC<Props> = ({
  settings,
  onEdit,
}) => {
  const withdrawString = settingsToWithdraw(settings)
  const antiFraudText = settingsToAntiFraud(settings)
  const withdrawCommissionText = settingsToWithdrawCommission(settings)
  const residentCommissionText = settingsToResidentCommission(settings)

  const settingsInfo = useMemo(() => {
    const nodes: React.ReactElement[] = []

    if (settings.day_withdraw_limit !== null) {
      nodes.push(
        <Col percentage={30} key="day_withdraw_limit">
          <SubBodyText fontWeight="bold">
            {currencyToBase(settings.day_withdraw_limit)} ₽
          </SubBodyText>
          <SubBodyText>лимит в день</SubBodyText>
        </Col>
      )
    }
    if (settings.week_withdraw_limit !== null) {
      nodes.push(
        <Col percentage={30} key="week_withdraw_limit">
          <SubBodyText fontWeight="bold">
            {currencyToBase(settings.week_withdraw_limit)} ₽
          </SubBodyText>
          <SubBodyText>в неделю</SubBodyText>
        </Col>
      )
    }
    if (settings.min_balance > 0) {
      nodes.push(
        <Col percentage={30} key="min_balance">
          <SubBodyText fontWeight="bold">
            {currencyToBase(settings.min_balance)} ₽
          </SubBodyText>
          <SubBodyText>неснижаемый остаток</SubBodyText>
        </Col>
      )
    }
    return nodes.reduce(
      (prev, next, index) =>
        index < 1 ? [next] : [...prev, <VBox key={`VBox-${next.key}`} />, next],
      [] as React.ReactElement[]
    )
  }, [
    settings.day_withdraw_limit,
    settings.min_balance,
    settings.week_withdraw_limit,
  ])

  return (
    <ContentCard>
      <ContentCardHeader title={settings.name}>
        {onEdit !== undefined && (
          <Icon name="edit" color="blue" pointer onClick={onEdit}>
            Редактировать
          </Icon>
        )}
      </ContentCardHeader>
      <ContentCardBody>
        <Row>
          <Col percentage={70}>
            <Row>
              <Col>
                <SubBodyText>Сумма вывода</SubBodyText>
                <SubTitleText fontWeight="bold">{withdrawString}</SubTitleText>
                <SubBodyText>{antiFraudText}</SubBodyText>
              </Col>
            </Row>
            <HBox x={2 / 3} />
            <Row>{settingsInfo}</Row>
          </Col>
          <VBox />
          <Col percentage={30}>
            <SubBodyText>Комиссия</SubBodyText>
            <SubBodyText fontWeight="bold">
              {withdrawCommissionText}
            </SubBodyText>
            {residentCommissionText && (
              <>
                <SubBodyText>для нерезидентов</SubBodyText>
                <SubBodyText fontWeight="bold">
                  {residentCommissionText}
                </SubBodyText>
              </>
            )}
          </Col>
        </Row>
      </ContentCardBody>
    </ContentCard>
  )
}
