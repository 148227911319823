import React from 'react'

import { ListItem } from 'ui-kit/components'
import { BorderType } from 'ui-kit'
import styled from 'styled-components'

interface Props {
  border?: BorderType
  flex?: string
  children?: React.ReactNode
  className?: string
  pointer?: boolean
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
}

const ListItemComponent = styled(({ children, ...rest }: Props) => (
  <ListItem {...rest}>{children}</ListItem>
))`
  flex: ${(props) => (props.flex ? props.flex : 1)};
  max-width: ${(props) => (props.flex ? props.flex : '100%')};
`

export default ListItemComponent
