import React from 'react'
import { Icon } from 'ui-kit/components'
import styled from 'styled-components'

const HoveredIcon = styled(Icon)`
  cursor: pointer;
  padding: ${({ theme }) => theme.paddings.half}px;
  border-radius: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.pallete.gray10};
  }
`

type FormatActionsProps = {
  id: string
  onConfirmPayment: (isConfirmed: boolean, id: string) => void
}
export const FormatActions = React.memo<FormatActionsProps>(
  ({ id, onConfirmPayment }) => {
    const handleClickConfirmed = () => {
      onConfirmPayment(true, id)
    }

    const handleClickNotConfirmed = () => {
      onConfirmPayment(false, id)
    }

    return (
      <div className="actions">
        <HoveredIcon
          name="block"
          color="gray7"
          onClick={handleClickNotConfirmed}
        />
        <HoveredIcon name="check" color="blue" onClick={handleClickConfirmed} />
      </div>
    )
  }
)

FormatActions.displayName = 'FormatActions'
