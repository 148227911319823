import React from 'react'
import { currencyToBase } from 'common/utils/formatters'
import { BodyText, TitleText } from 'ui-kit/components'
import { HBox } from 'shared/ui/spacers'
import styled from 'styled-components'

type Props = {
  feesTotal: number
  mozenWithdrawFeesTotal: number
  mozenWithdrawTotal: number
  rideFeesTotal: number
}

const Wrapper = styled.div`
  padding: 10px 16px;
  align-self: flex-start;
  white-space: nowrap;
`

export const StatisticsFeesSummary: React.FC<Props> = ({
  feesTotal,
  rideFeesTotal,
  mozenWithdrawFeesTotal,
}) => {
  return (
    <Wrapper>
      <TitleText fontWeight="semi-bold">
        {currencyToBase(feesTotal)} ₽
      </TitleText>
      <BodyText fontWeight="semi-bold">за период</BodyText>
      <HBox />
      <BodyText fontWeight="semi-bold" color="yellowDataSet">
        {currencyToBase(mozenWithdrawFeesTotal)} ₽
      </BodyText>
      <BodyText fontWeight="semi-bold" color="yellowDataSet">
        вывод средств
      </BodyText>
      <HBox x={0.5} />
      <BodyText fontWeight="semi-bold" color="gray6">
        {currencyToBase(rideFeesTotal)} ₽
      </BodyText>
      <BodyText fontWeight="semi-bold" color="gray6">
        поездки
      </BodyText>
    </Wrapper>
  )
}
