import axios, { AxiosResponse } from 'axios'
import { BaseAction } from 'redux-actions'
import { ThunkAction } from 'redux-thunk'

import { State } from 'reducers'
import { AggregatorsResponse } from 'api/types'
import { failure, start, success, update } from 'api/park/aggregators/actions'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  BaseAction
>
export const HANDLE_REQUEST: BaseAction['type'] =
  'api/park/aggregators->HANDLE_REQUEST'

export const handleRequest = (): THandleRequest => async (dispatch) => {
  dispatch(start())
  try {
    const response: AxiosResponse<AggregatorsResponse> = await axios.get(
      'erp/1/park/aggregators'
    )
    dispatch(update(response.data))
    dispatch(success())
  } catch (e) {
    if (e.response) {
      dispatch(failure(e.response.data))
    }
  }
}
