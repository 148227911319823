import React from 'react'

import { Search } from 'shared/table'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  padding-right: 8px;
`
type Props = {
  onChangeSearch: (value: string) => void
}
export const Header = React.memo<Props>(({ onChangeSearch }) => {
  return (
    <Wrapper>
      <Search onSetSearch={onChangeSearch} />
    </Wrapper>
  )
})

Header.displayName = 'Header'
