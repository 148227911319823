import React from 'react'

import { ContainerProps } from 'layout/features/sidebar/containers/DepositContainer'

import { CaptionText, Icon } from 'ui-kit/components'
import { HBox } from 'shared/ui'
import { Currency } from 'ui-kit/components/Currency/Currency'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 24px;

  @media (max-width: 1024px) {
    display: none;
  }
`

const FuelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const Deposit: React.FC<ContainerProps> = ({ balance, fuelSummary }) => {
  return (
    <Container>
      <CaptionText color="darkGray">Депозит:</CaptionText>
      <Currency
        color={balance && balance <= 0 ? 'uiRed' : 'white'}
        regularFontWidth="medium"
        regularFontSize={20}
        decimalFontSize={14}
        decimalFontWidth="semi-bold"
        value={balance !== null ? balance : undefined}
        explicitNegativeSign
      />
      {fuelSummary?.deposit_balance !== null &&
        fuelSummary?.deposit_balance !== undefined && (
          <>
            <HBox x={2 / 3} />
            <CaptionText color="darkGray">Топливо:</CaptionText>
            <FuelWrapper>
              <Currency
                color={fuelSummary?.deposit_balance <= 0 ? 'uiRed' : 'white'}
                regularFontWidth="medium"
                regularFontSize={20}
                decimalFontSize={14}
                decimalFontWidth="semi-bold"
                value={fuelSummary?.deposit_balance}
                explicitNegativeSign
              />
              {fuelSummary?.deposit_balance <= 0 && <Icon name="warning2" />}
            </FuelWrapper>
          </>
        )}
    </Container>
  )
}
