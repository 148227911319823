import React from 'react'

import { ContainerProps } from 'auth/signOut/containers/SignOutContainer'

export const SignOut: React.FC<ContainerProps> = ({ onDidMount }) => {
  React.useEffect(() => {
    onDidMount()
  }, [onDidMount])
  return null
}
