import React from 'react'

import { Column, Table as UITable } from 'shared/table'
import { getWidthInPercent } from 'common/utils/formatters'
import {
  TransactionAmount,
  TransactionDate,
  TransactionReason,
  TransactionStatus,
} from 'payments/features/notCompletedList/molecules'
import { PaymentTransaction } from 'api/types'

export const formatDate = (transaction: PaymentTransaction) => (
  <TransactionDate created={transaction.created_at} />
)

export const formatReason = (transaction: PaymentTransaction) => (
  <TransactionReason
    from={transaction.legs.from_participant}
    to={transaction.legs.to_participant}
    reason={transaction.reason}
  />
)

export const formatAmount = (transaction: PaymentTransaction) => (
  <TransactionAmount amount={transaction.amount} />
)

export const formatStatus = (transaction: PaymentTransaction) => (
  <TransactionStatus status={transaction.status} />
)

const blockWidth = 1064

type Props = {
  data: PaymentTransaction[]
  isFetching?: boolean
  canViewTransactionDetails?: boolean
  onRowClick: (transaction: PaymentTransaction) => void
}
export const Table: React.FC<Props> = ({
  data,
  isFetching,
  canViewTransactionDetails,
  onRowClick,
}) => (
  <UITable
    source={data}
    bodyMinHeight="336px"
    isLoading={isFetching}
    onClickRow={canViewTransactionDetails ? onRowClick : undefined}
    hideHeaderOnEmpty
  >
    <Column data={formatDate} flex={`${getWidthInPercent(blockWidth, 126)}%`}>
      Дата платежа
    </Column>
    <Column data={formatReason} flex={`${getWidthInPercent(blockWidth, 404)}%`}>
      Описание
    </Column>
    <Column data={formatAmount} flex={`${getWidthInPercent(blockWidth, 156)}%`}>
      Сумма, ₽
    </Column>
    <Column
      data={formatStatus}
      className="actions-wrap"
      disableRowClick
      flex={`${getWidthInPercent(blockWidth, 322)}%`}
    >
      Статус
    </Column>
  </UITable>
)
