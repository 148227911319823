import { connect } from 'react-redux'

import { onDidMount as layoutOnWillUnmount } from 'layout/thunks'
import { Steps } from 'steps/components'
import { MyThunkDispatch } from 'api/reducer'

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  layoutOnWillUnmount: () => dispatch(layoutOnWillUnmount()),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const StepsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Steps)
