import { LargeFilter } from 'common/types/local/filter'
import {
  PaymentOperationType,
  PaymentReason,
  paymentReasonToString,
  PaymentStatus,
  paymentStatusToString,
} from 'common/enums'

const operationCategory = 'Тип транзакций'

const reasonCategory = 'Основание'

const paymentStatusCategory = 'Статус платежа'

const reasons: LargeFilter[] = [
  PaymentReason.PARK_DRIVER_WITHDRAW,
  PaymentReason.PARK_DRIVER_WITHDRAW_COMMISSION,
  PaymentReason.DRIVER_FUEL_CARD_TOPUP,
  PaymentReason.TAXOMETER_MANUAL_TOPUP,
  PaymentReason.TAXOMETER_REFERRAL_TOPUP,
].map((name) => ({
  name,
  category: reasonCategory,
  label: paymentReasonToString(name),
}))

const statuses: LargeFilter[] = [
  PaymentStatus.PENDING,
  PaymentStatus.ACKNOWLEDGED,
  PaymentStatus.PROCESSING,
  PaymentStatus.SUCCEEDED,
  PaymentStatus.DECLINED,
  PaymentStatus.FAILED,
].map((name) => ({
  name,
  category: paymentStatusCategory,
  label: paymentStatusToString(name),
}))

export const filtersData: LargeFilter[] = [...reasons, ...statuses]
