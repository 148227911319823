export enum SubscriptionStatus {
  Active = 'ACTIVE',
  Unpaid = 'UNPAID',
  Blocked = 'BLOCKED',
  Suspended = 'SUSPENDED',
}

export const subscriptionStatusToString = (
  value: SubscriptionStatus
): string => {
  switch (value) {
    case SubscriptionStatus.Active:
      return 'активна'
    case SubscriptionStatus.Unpaid:
      return 'просрочена'
    case SubscriptionStatus.Blocked:
      return 'заблокирована'
    case SubscriptionStatus.Suspended:
      return 'приостановлена'
    default:
      return value
  }
}
