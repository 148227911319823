import { EuiSpacer, EuiTitle } from '@elastic/eui'
import React from 'react'

const Subtitle: React.FC<{ children: string }> = ({ children }) => (
  <>
    <EuiTitle size="xxxs">
      <h2>{children}</h2>
    </EuiTitle>
    <EuiSpacer size="xs" />
  </>
)

export default Subtitle
