import { connect } from 'react-redux'
import { getFormValues, reduxForm } from 'redux-form'

import { State } from 'reducers'
import { MyThunkDispatch } from 'api/reducer'
import { FormNames } from 'common/enums'
import { onSaveAlfaE2C } from 'steps/features/stepPayments/thunks'
import { PaymentsAlfaE2C } from '../organisms/PaymentsAlfaE2C/PaymentsAlfaE2C'

export interface FormProps {
  password: string
  username: string
}

const mapStateToProps = (state: State) => ({
  formValues: getFormValues(FormNames.StepPaymentsAlfaE2C)(state) as FormProps,
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  onSave: (values: FormProps) => dispatch(onSaveAlfaE2C({ values })),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const Form = reduxForm<FormProps, ContainerProps>({
  form: FormNames.StepPaymentsAlfaE2C,
})(PaymentsAlfaE2C)

export const PaymentsAlfaE2CContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Form)
