import { handleActions } from 'redux-actions'

import { Referral } from 'common/types/api/models/referral'

import {
  Payloads,
  SET_REFERRALS,
  SET_REFERRALS_PAGE,
  SET_REFERRALS_SEARCH,
} from 'drivers/features/driverReferrals/actions'

export interface DriverReferralsState {
  referrals: Referral[]
  searchValue: string
  page: number
  total: number
}

const initialState: DriverReferralsState = {
  referrals: [],
  searchValue: '',
  page: 1,
  total: 0,
}

const handleSetReferrals = (
  state: DriverReferralsState,
  action: SET_REFERRALS
): DriverReferralsState => ({
  ...state,
  referrals: action.payload.items,
  total: action.payload.total,
})

const handleSetReferralsSearch = (
  state: DriverReferralsState,
  action: SET_REFERRALS_SEARCH
): DriverReferralsState => ({
  ...state,
  searchValue: action.payload,
})

const handleSetReferralsPage = (
  state: DriverReferralsState,
  action: SET_REFERRALS_PAGE
): DriverReferralsState => ({
  ...state,
  page: action.payload,
})

export default handleActions<DriverReferralsState, Payloads>(
  {
    [SET_REFERRALS]: handleSetReferrals,
    [SET_REFERRALS_SEARCH]: handleSetReferralsSearch,
    [SET_REFERRALS_PAGE]: handleSetReferralsPage,
  },
  initialState
)
