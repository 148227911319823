import { createSelector } from 'reselect'
import { State } from 'reducers'

export const getIsFetching = (state: State) =>
  state.api.park.transactions.status === 'loading'

const getData = (state: State) => state.api.park.transactions.data

export const getTransactions = createSelector(getData, (data) => {
  if (!data) {
    return []
  }
  return data.transactions
})

export const getTotalAmount = createSelector(getData, (data) =>
  data ? data.total_amount : 0
)
