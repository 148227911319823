import React, { useCallback, useContext } from 'react'

import { Column, Table } from 'shared/table'
import { Checkbox, TableText } from 'ui-kit/components'
import { getWidthInPercent } from 'common/utils/formatters'
import { difference } from 'lodash'
import {
  TransactionAmount,
  TransactionBilling,
  TransactionDate,
  TransactionParticipant,
} from 'payments/features/manualConfirmationList/molecules'
import { PaymentTransaction } from 'api/types'
import { ThemeContext } from 'styled-components'
import { Option } from 'ui-kit/molecules/RadioGroup/RadioGroup'
import { InlineOutputFormatSelect } from '../../molecules/InlineOutputFormatSelect/InlineOutputFormatSelect'

export const formatDate = (transaction: PaymentTransaction) => (
  <TransactionDate created={transaction.created_at} />
)

export const formatParticipant = (transaction: PaymentTransaction) => (
  <TransactionParticipant participant={transaction.legs.to_participant} />
)

export const formatAmount = (transaction: PaymentTransaction) => (
  <TransactionAmount amount={transaction.amount} />
)

const blockWidth = 1064

const useColorFromOutputFormat = (format: string) => {
  const { pallete } = useContext(ThemeContext)
  return format === 'xlsx' ? '#008F27' : pallete.blue
}

type Props = {
  data: PaymentTransaction[]
  records: Set<string>
  isFetching?: boolean
  canViewTransactionDetails?: boolean
  canProcessTransactions?: boolean
  onRowClick: (transaction: PaymentTransaction) => void
  onChangeCheckbox: (changed: Set<string>) => void
  onDownload: (id: string) => void
  onConfirmPayment: (isConfirmed: boolean, id: string) => void
  selectedOutputFormat: string
  onSelectedOutputFormatChange: (selectedOption: Option) => void
  downloadOptions: Option[]
}
export const ConfirmationTable: React.FC<Props> = ({
  data,
  records,
  isFetching,
  canViewTransactionDetails,
  canProcessTransactions,
  onChangeCheckbox,
  onRowClick,
  onDownload,
  onConfirmPayment,
  selectedOutputFormat,
  onSelectedOutputFormatChange,
  downloadOptions,
}) => {
  const colorFromOutputFormat = useColorFromOutputFormat(selectedOutputFormat)
  const formatBilling = React.useCallback(
    (transaction: PaymentTransaction) => {
      return (
        <TransactionBilling
          id={transaction.id}
          lastDownloadingTime={transaction.last_payment_order_request}
          onDownload={onDownload}
          onConfirmPayment={onConfirmPayment}
          canProcessTransactions={canProcessTransactions}
        />
      )
    },
    [canProcessTransactions, onConfirmPayment, onDownload]
  )

  const handleChangeCheckbox = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const id = e.currentTarget.name
      const newRecords = new Set(records)

      if (e.currentTarget.checked) {
        newRecords.add(id)
      } else {
        newRecords.delete(id)
      }

      onChangeCheckbox(newRecords)
    },
    [onChangeCheckbox, records]
  )

  const handleChangeAllCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const allRecords: Set<string> = new Set()

    if (e.target.checked) {
      data.forEach((transaction: PaymentTransaction) => {
        allRecords.add(transaction.id)
      })
    }

    onChangeCheckbox(allRecords)
  }

  const isAllCheckboxChecked = !difference(
    data.map((item) => item.id),
    Array.from(records)
  ).length

  const renderCheckbox = React.useCallback(
    (transaction: PaymentTransaction) => {
      const isChecked = records.has(transaction.id) || false
      return (
        <div>
          <Checkbox
            name={transaction.id}
            checked={isChecked}
            onChange={handleChangeCheckbox}
          />
        </div>
      )
    },
    [records, handleChangeCheckbox]
  )

  return (
    <Table
      source={data}
      bodyMinHeight="336px"
      isLoading={isFetching}
      onClickRow={canViewTransactionDetails ? onRowClick : undefined}
      hideHeaderOnEmpty
    >
      {canProcessTransactions ? (
        <Column
          data={renderCheckbox}
          flex={`${getWidthInPercent(blockWidth, 56)}%`}
          className="checkbox"
          disableRowClick
        >
          <Checkbox
            name="all"
            checked={isAllCheckboxChecked}
            onChange={handleChangeAllCheckbox}
          />
        </Column>
      ) : null}
      <Column data={formatDate} flex={`${getWidthInPercent(blockWidth, 126)}%`}>
        Дата
      </Column>
      <Column
        data={formatParticipant}
        flex={`${getWidthInPercent(blockWidth, 404)}%`}
      >
        Водитель
      </Column>
      <Column
        data={formatAmount}
        flex={`${getWidthInPercent(blockWidth, 156)}%`}
      >
        Сумма
      </Column>
      <Column
        data={formatBilling}
        className="actions-wrap"
        disableRowClick
        flex={`${getWidthInPercent(blockWidth, 322)}%`}
      >
        <TableText className="table-title" bold>
          Поручение в формате{' '}
          <InlineOutputFormatSelect
            color={colorFromOutputFormat}
            options={downloadOptions}
            selected={selectedOutputFormat}
            onChange={onSelectedOutputFormatChange}
          >
            {
              downloadOptions.find((x) => x.name === selectedOutputFormat)
                ?.value
            }
          </InlineOutputFormatSelect>
        </TableText>
      </Column>
    </Table>
  )
}
