import { createSelector } from 'reselect'
import { State } from 'reducers'
import { ApiCode } from 'common/types/api/codes'

export const getIsFetching = (state: State) =>
  state.api.park.qiwiDetails.status === 'loading'

const getData = (state: State) => state.api.park.qiwiDetails.data

export const getBalance = createSelector(getData, (data) =>
  data ? data.balance && data.balance.amount : null
)

const getError = (state: State) => state.api.park.qiwiDetails.error

export const getQiwiCredentialsNotFound = createSelector(getError, (error) =>
  error ? error.code === ApiCode.QiwiCredentialsNotFound : null
)

export const getHasData = createSelector(getData, (data) => Boolean(data))

export const getIsBelowLimit = (state: State) =>
  state.api.park.qiwiDetails.data?.is_below_limit
