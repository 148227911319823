import { createApiReducer } from 'common/utils/reduxApiFactory'
import { GetReferralOffersState } from './types'
import { getReferralOffersActions } from './actions'

export const initialState: GetReferralOffersState = {
  status: null,
  data: null,
}

export const getReferralOffersReducer = createApiReducer(
  getReferralOffersActions,
  initialState
)
