import { combineReducers } from 'redux'

import { TContractsAction } from 'api/driversById/contracts/actions'
import {
  ContractsState,
  reducer as contracts,
} from 'api/driversById/contracts/reducer'
import * as contractsSelectors from 'api/driversById/contracts/selectors'
import { handleRequest as contractsRequest } from 'api/driversById/contracts/thunks'

import {
  ContractDeleteActions,
  ContractDeleteState,
} from 'api/driversById/contracts/delete/types'
import { contractDeleteReducer } from 'api/driversById/contracts/delete/reducer'
import * as contractDeleteSelectors from 'api/driversById/contracts/delete/selectors'
import { deleteContractRequest } from 'api/driversById/contracts/delete/thunks'

import getActions, { TGetAction } from 'api/driversById/get/actions'
import { GetState, reducer as get } from 'api/driversById/get/reducer'
import * as getSelectors from 'api/driversById/get/selectors'
import { handleRequest as getRequest } from 'api/driversById/get/thunks'

import { TPaymentConfirmationStatusAction } from 'api/driversById/payment-confirmation-status/actions'
import {
  PaymentConfirmationStatusState,
  reducer as paymentConfirmationStatus,
} from 'api/driversById/payment-confirmation-status/reducer'
import * as paymentConfirmationStatusSelectors from 'api/driversById/payment-confirmation-status/selectors'
import { handleRequest as paymentConfirmationStatusRequest } from 'api/driversById/payment-confirmation-status/thunks'

import { TWithdrawRestrictionAction } from 'api/driversById/withdraw-restriction/actions'
import {
  reducer as withdrawRestriction,
  WithdrawRestrictionState,
} from 'api/driversById/withdraw-restriction/reducer'
import * as withdrawRestrictionSelectors from 'api/driversById/withdraw-restriction/selectors'
import { handleRequest as withdrawRestrictionRequest } from 'api/driversById/withdraw-restriction/thunks'
import {
  DriverPaymentSettingsActions,
  DriverPaymentSettingsState,
} from 'api/driversById/payment-settings/types'
import {
  deleteDriverPaymentSettingsReducer,
  getDriverPaymentSettingsReducer,
  patchDriverPaymentSettingsReducer,
  postDriverPaymentSettingsReducer,
} from 'api/driversById/payment-settings/reducer'
import {
  deletePaymentSettingsRequest,
  getPaymentSettingsRequest,
  patchPaymentSettingsRequest,
  postPaymentSettingsRequest,
} from 'api/driversById/payment-settings/thunks'
import * as paymentSettingsSelectors from 'api/driversById/payment-settings/selectors'

export interface DriversByIdState {
  contracts: ContractsState
  contractDelete: ContractDeleteState
  get: GetState
  paymentConfirmationStatus: PaymentConfirmationStatusState
  withdrawRestriction: WithdrawRestrictionState
  getPaymentSettings: DriverPaymentSettingsState
  postPaymentSettings: DriverPaymentSettingsState
  patchPaymentSettings: DriverPaymentSettingsState
  deletePaymentSettings: DriverPaymentSettingsState
}

export type TDriversByIdAction =
  | TContractsAction
  | TGetAction
  | TPaymentConfirmationStatusAction
  | TWithdrawRestrictionAction
  | DriverPaymentSettingsActions
  | ContractDeleteActions

export const driversByIdReducer = combineReducers({
  contracts,
  contractDelete: contractDeleteReducer,
  get,
  paymentConfirmationStatus,
  withdrawRestriction,
  getPaymentSettings: getDriverPaymentSettingsReducer,
  postPaymentSettings: postDriverPaymentSettingsReducer,
  patchPaymentSettings: patchDriverPaymentSettingsReducer,
  deletePaymentSettings: deleteDriverPaymentSettingsReducer,
})

export const driversByIdActions = {
  contracts: {
    handleRequest: contractsRequest,
  },
  contractDelete: {
    handleRequest: deleteContractRequest,
  },
  get: {
    handleRequest: getRequest,
    reset: getActions.reset,
    start: getActions.start,
  },
  paymentConfirmationStatus: {
    handleRequest: paymentConfirmationStatusRequest,
  },
  withdrawRestriction: {
    handleRequest: withdrawRestrictionRequest,
  },
  paymentSettings: {
    handleGetRequest: getPaymentSettingsRequest,
    handlePostRequest: postPaymentSettingsRequest,
    handlePatchRequest: patchPaymentSettingsRequest,
    handleDeleteRequest: deletePaymentSettingsRequest,
  },
}

export const driversByIdSelectors = {
  contracts: contractsSelectors,
  contractDelete: contractDeleteSelectors,
  get: getSelectors,
  paymentConfirmationStatus: paymentConfirmationStatusSelectors,
  withdrawRestriction: withdrawRestrictionSelectors,
  paymentSettings: paymentSettingsSelectors,
}
