import React from 'react'
import { BodyText, Icon, ListItem } from 'ui-kit/components'
import { VBox } from 'shared/ui'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  height: 72px;
  align-items: center;
`

const BackLink = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

interface Props {
  unsetReview: () => void
}

export const Heading: React.FC<Props> = ({ unsetReview }) => (
  <Container>
    <VBox x={0.5} />
    <BackLink onClick={unsetReview}>
      <Icon name="arrowLeft" />
    </BackLink>
    <ListItem>
      <BodyText fontWeight="semi-bold">Данные из файла</BodyText>
    </ListItem>
  </Container>
)
