import React from 'react'
import { connect } from 'react-redux'
import axios, { AxiosResponse } from 'axios'

import { history } from 'store'
import { apiActions, apiSelectors } from 'api'

import { Driver, DriverListInfo } from 'common/types/api/models/driver'

import {
  resetDriversListPage as resetDriversListPageAction,
  setDriversList as setDriversListAction,
  setDriversListLimit as setDriversListLimitAction,
  setDriversListNextPage as setDriversListNextPageAction,
  setDriversListPrevPage as setDriversListPrevPageAction,
  setInfo as setInfoAction,
  SetDriversListPayload,
} from 'settings/features/paymentSettingsDriversList/actions'
import { DriversList } from 'settings/features/paymentSettingsDriversList/organisms'
import { MyThunkDispatch } from 'api/reducer'
import { PaymentSettingsAssignDriversModalContainer } from 'settings/features/paymentSettingsAssignDriverModal/containers'
import { PaginationLimit } from 'api/types'
import { ParkPaymentSettingsAllState } from 'api/park/payment-settings/all/types'
import { State } from 'reducers'

interface OwnProps {
  settingsId: string | null
}
interface Props {
  drivers: Driver[]
  page: number
  cursor: string | null
  limit: PaginationLimit
  totalPages: number
  settings: ParkPaymentSettingsAllState
}

interface DispatchProps {
  fetchDriversSettings: () => void
  setInfo: typeof setInfoAction
  setDriversList: typeof setDriversListAction
  setDriversListNextPage: typeof setDriversListNextPageAction
  setDriversListPrevPage: typeof setDriversListPrevPageAction
  setDriversListLimit: typeof setDriversListLimitAction
  resetDriversListPage: typeof resetDriversListPageAction
}

interface LocalState {
  driversIsLoading: boolean
  isModalOpened: boolean
}

class Container extends React.Component<
  Props & OwnProps & DispatchProps,
  LocalState
> {
  constructor(props: Props & OwnProps & DispatchProps) {
    super(props)

    this.state = {
      driversIsLoading: false,
      isModalOpened: false,
    }
  }

  componentDidMount() {
    if (history.action === 'PUSH' && (history.location.state as any)?.reset) {
      setTimeout(() => {
        this.handleRequestDrivers()
      }, 0)
    } else {
      this.handleRequestDrivers()
    }
  }

  componentDidUpdate(prevProps: Readonly<Props & OwnProps & DispatchProps>) {
    const { settingsId } = this.props
    if (prevProps.settingsId !== settingsId) {
      this.update()
    }
  }

  componentWillUnmount() {
    const { setDriversList } = this.props
    setDriversList({ total_pages: 0, items: [], cursor: null })
  }

  update = async () => {
    const { resetDriversListPage } = this.props
    await resetDriversListPage()
    await this.handleRequestDrivers()
  }

  private handleRequestDrivers = async (): Promise<void> => {
    const { settingsId, cursor, limit, setDriversList } = this.props

    const data = {
      limit,
      cursor,
    }

    this.setState({ driversIsLoading: true })

    try {
      const response: AxiosResponse<{
        total: number
        drivers: Driver[]
        cursor: string
      }> = await axios.get(
        `erp/1/park/payment-settings/${settingsId || 'individual'}/drivers`,
        { params: data }
      )

      setDriversList({
        total_pages: Math.ceil(response.data.total / limit),
        items: response.data.drivers,
        cursor: response.data.cursor,
      })
    } finally {
      this.setState({ driversIsLoading: false })
    }
  }

  private handleRowClick = (driver: Driver): void =>
    history.push(`/drivers/${driver.park_id_aggregator}/${driver.driver_id}`)

  private handleAddDriver = () => {
    this.setState({ isModalOpened: true })
  }

  private handleCloseModal = () => {
    this.setState({ isModalOpened: false })
  }

  private handleRemove = async (driver: Driver) => {
    const { fetchDriversSettings, settings } = this.props

    this.setState({ driversIsLoading: true })

    try {
      await axios.patch(
        `erp/1/park/payment-settings/${settings.data?.park_default_payment_settings?.id}/assign`,
        { user_ids: [driver.user_id] }
      )
      await fetchDriversSettings()
    } finally {
      this.setState({ driversIsLoading: false })

      await this.handleRequestDrivers()
    }
  }

  private handleAdd = async (userIds: string[]) => {
    const { fetchDriversSettings, settingsId } = this.props

    try {
      await axios.patch(`erp/1/park/payment-settings/${settingsId}/assign`, {
        user_ids: userIds,
      })
      await fetchDriversSettings()
    } finally {
      this.setState({ isModalOpened: false })
      await this.handleRequestDrivers()
    }
  }

  private handleChangePage = (direction: 'prev' | 'next'): void => {
    const { setDriversListPrevPage, setDriversListNextPage } = this.props
    const { driversIsLoading } = this.state

    if (!driversIsLoading) {
      if (direction === 'prev') {
        setDriversListPrevPage()

        setTimeout(() => this.handleRequestDrivers(), 0)
      } else if (direction === 'next') {
        setDriversListNextPage()

        setTimeout(() => this.handleRequestDrivers(), 0)
      }
    }
  }

  private handleChangeLimit = (limit: PaginationLimit): void => {
    const { setDriversListLimit } = this.props
    const { driversIsLoading } = this.state

    if (!driversIsLoading) {
      setDriversListLimit(limit)
      setTimeout(() => this.handleRequestDrivers(), 0)
    }
  }

  render() {
    const {
      drivers,
      page,
      limit,
      totalPages,
      settingsId,
      settings,
    } = this.props

    const { driversIsLoading, isModalOpened } = this.state

    const isDefaultSettings =
      settingsId === settings.data?.park_default_payment_settings?.id

    return (
      <>
        <DriversList
          drivers={drivers}
          onRowClick={this.handleRowClick}
          onAddDriver={settingsId ? this.handleAddDriver : undefined}
          onRemove={isDefaultSettings ? undefined : this.handleRemove}
          page={page}
          limit={limit}
          totalPages={totalPages}
          onChangePage={this.handleChangePage}
          onChangeLimit={this.handleChangeLimit}
          driversIsLoading={driversIsLoading}
        />
        {isModalOpened && (
          <PaymentSettingsAssignDriversModalContainer
            onAdd={this.handleAdd}
            onClose={this.handleCloseModal}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = (state: State, ownProps: OwnProps) => ({
  ...ownProps,
  settings: apiSelectors.park.paymentSettingsAll.selectParkPaymentSettingsAll(
    state
  ),
  drivers: state.driversListSettingsState.drivers,
  page: state.driversListSettingsState.page,
  limit: state.driversListSettingsState.limit,
  cursor: state.driversListSettingsState.currentCursor,
  totalPages: state.driversListSettingsState.totalPages,
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  fetchDriversSettings: () =>
    dispatch(apiActions.park.paymentSettingsAll.handleRequest()),
  setInfo: (info: DriverListInfo) => dispatch(setInfoAction(info)),
  setDriversList: (drivers: SetDriversListPayload) =>
    dispatch(setDriversListAction(drivers)),
  setDriversListPrevPage: () => dispatch(setDriversListPrevPageAction()),
  setDriversListNextPage: () => dispatch(setDriversListNextPageAction()),
  resetDriversListPage: () => dispatch(resetDriversListPageAction()),
  setDriversListLimit: (limit: PaginationLimit) =>
    dispatch(setDriversListLimitAction(limit)),
})

export const DriversListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container)
