import { applyMiddleware, compose, createStore } from 'redux'
import thunk, { ThunkMiddleware } from 'redux-thunk'
import { createBrowserHistory } from 'history'

import { rootReducer, State, TRootAction } from 'reducers'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

const composeEnchancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : compose

export const history = createBrowserHistory()

const middlewares = [thunk as ThunkMiddleware<State, TRootAction>]

export const store = createStore(
  rootReducer,
  composeEnchancers(applyMiddleware(...middlewares))
)
