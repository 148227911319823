import { Action } from 'redux-actions'

import { SuspectTransaction } from 'common/types/api/models/antifraud'

export type SET_TRANSACTION = Action<SuspectTransaction | null>
export const SET_TRANSACTION: SET_TRANSACTION['type'] =
  'payment/details/SET_TRANSACTION'
export const setTransaction = (
  transaction: SuspectTransaction | null
): SET_TRANSACTION => ({
  type: SET_TRANSACTION,
  payload: transaction,
})

export type SET_OPEN_MODAL = Action<boolean>
export const SET_OPEN_MODAL: SET_OPEN_MODAL['type'] =
  'payment/details/SET_OPEN_MODAL'
export const setOpenModal = (isOpen: boolean): SET_OPEN_MODAL => ({
  type: SET_OPEN_MODAL,
  payload: isOpen,
})

export type SET_REQUEST = Action<
  ((confirmed: boolean, id?: string) => void) | null
>
export const SET_REQUEST: SET_REQUEST['type'] = 'payment/details/SET_REQUEST'
export const setRequest = (
  request: ((confirmed: boolean, id?: string) => void) | null
): SET_REQUEST => ({
  type: SET_REQUEST,
  payload: request,
})

export type Payloads = Pick<
  SET_TRANSACTION & SET_OPEN_MODAL & SET_REQUEST,
  'payload'
>['payload']
