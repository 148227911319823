import { ThunkAction } from 'redux-thunk'
import { State } from 'reducers'
import axios, { AxiosResponse } from 'axios'
import { FailureError } from 'common/utils/reduxApiFactory'
import { TApiError } from 'api/types'
import { getCloudCashBoxActions as actions } from 'api/receipt/cloud-cashbox/get/actions'
import {
  GetCloudCashBox,
  GetCloudCashBoxActions,
} from 'api/receipt/cloud-cashbox/get/types'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  GetCloudCashBoxActions
>

export const getCloudCashBoxRequest = (): THandleRequest => async (
  dispatch
) => {
  dispatch(actions.start())
  try {
    const response: AxiosResponse<GetCloudCashBox> = await axios.get(
      'erp/1/receipt/cloud-cashbox'
    )
    dispatch(actions.update(response.data))
    dispatch(actions.success())
  } catch (e) {
    if (e.response) {
      const data = e.response.data as TApiError
      const error: FailureError = new Error(data.message)
      error.data = data
      dispatch(actions.failure(error))
    }
  }
}
