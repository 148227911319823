import React, { ChangeEvent, useMemo } from 'react'
import styled from 'styled-components'
import Typography from 'ui-kit/typography'
import TextareaAutosize from 'react-textarea-autosize'
import { mainFontFamily, smallBody } from 'ui-kit/typography/core'
import { v4 } from 'uuid'

type Props = {
  id?: string
  name?: string
  caption: string
  value: string
  onChange: (value: string) => void
  isDisabled?: boolean
  placeholder?: string
  error?: string | null
}

const ErrorMessage = styled.div`
  padding-left: 16px;
  padding-right: 16px;
`

const Wrapper = styled.div`
  position: relative;
`

const Caption = styled(Typography).attrs({
  variant: 'Caption1',
  fontColor: 'gray6',
})<{ hasError: boolean }>`
  display: block;
  padding-left: 16px;
  padding-right: 16px;

  color: ${({ hasError, theme }) => hasError && theme.pallete.uiRed};
`

const Line = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  margin-left: 16px;
  margin-right: 16px;
  height: 1px;
  background-color: ${({ theme }) => theme.pallete.gray3};
  width: calc(100% - 32px);
`

const Input = styled(TextareaAutosize)<{ isDisabled?: boolean }>`
  ${mainFontFamily};
  ${smallBody};

  border: none;
  box-sizing: border-box;
  resize: none;
  outline: none;
  overflow: auto;
  min-height: 24px;
  background-color: #fff;
  width: 100%;
  padding: 8px 16px;
  position: relative;

  &:focus + ${Line} {
    background-color: ${({ theme }) => theme.pallete.gray6};
  }

  opacity: ${({ isDisabled }) => isDisabled && 0.6};
`

export const TextArea = ({
  id,
  name,
  caption,
  onChange,
  value,
  isDisabled,
  placeholder,
  error,
}: Props) => {
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value)
  }

  const uuid = useMemo(() => {
    return id || v4()
  }, [id])

  return (
    <>
      <Wrapper>
        <Caption hasError={Boolean(error)} as="label" htmlFor={uuid}>
          {caption}
        </Caption>
        <Input
          id={uuid}
          name={name}
          value={value}
          onChange={handleChange}
          disabled={isDisabled}
          isDisabled={isDisabled}
          placeholder={placeholder}
        />
        <Line />
      </Wrapper>
      {error && (
        <ErrorMessage>
          <Typography variant="Caption2" fontColor="uiRed">
            {error}
          </Typography>
        </ErrorMessage>
      )}
    </>
  )
}
