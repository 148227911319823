import { Action } from 'redux-actions'

import { Trip } from 'common/types/api/models/trip'

interface SetTripsPayload {
  total: number
  items: Trip[]
}
export type SET_TRIPS = Action<SetTripsPayload>
export const SET_TRIPS: SET_TRIPS['type'] = 'driver_details/SET_TRIPS'
export const setTrips = (rides: SetTripsPayload): SET_TRIPS => ({
  type: SET_TRIPS,
  payload: rides,
})

export type SET_TRIPS_PAGE = Action<number>
export const SET_TRIPS_PAGE: SET_TRIPS_PAGE['type'] =
  'driver_details/SET_TRIPS_PAGE'
export const setTripsPage = (page: number): SET_TRIPS_PAGE => ({
  type: SET_TRIPS_PAGE,
  payload: page,
})

export type SET_TRIPS_SEARCH = Action<string>
export const SET_TRIPS_SEARCH: SET_TRIPS_SEARCH['type'] =
  'driver_details/SET_TRIPS_SEARCH'
export const setTripsSearch = (searchValue: string): SET_TRIPS_SEARCH => ({
  type: SET_TRIPS_SEARCH,
  payload: searchValue,
})

export type SET_TRIPS_FILTER = Action<string[]>
export const SET_TRIPS_FILTER: SET_TRIPS_FILTER['type'] =
  'driver_details/SET_TRIPS_FILTER'
export const setTripsFilter = (filters: string[]): SET_TRIPS_FILTER => ({
  type: SET_TRIPS_FILTER,
  payload: filters,
})

export type SET_TRIPS_DATE_FROM = Action<Date | null>
export const SET_TRIPS_DATE_FROM: SET_TRIPS_DATE_FROM['type'] =
  'driver_details/SET_TRIPS_DATE_FROM'
export const setTripsDateFrom = (date: Date | null): SET_TRIPS_DATE_FROM => ({
  type: SET_TRIPS_DATE_FROM,
  payload: date,
})

export type SET_TRIPS_DATE_TO = Action<Date | null>
export const SET_TRIPS_DATE_TO: SET_TRIPS_DATE_TO['type'] =
  'driver_details/SET_TRIPS_DATE_TO'
export const setTripsDateTo = (date: Date | null): SET_TRIPS_DATE_TO => ({
  type: SET_TRIPS_DATE_TO,
  payload: date,
})

export type Payloads = Pick<
  SET_TRIPS &
    SET_TRIPS_SEARCH &
    SET_TRIPS_PAGE &
    SET_TRIPS_FILTER &
    SET_TRIPS_DATE_FROM &
    SET_TRIPS_DATE_TO,
  'payload'
>['payload']
