import { createApiReducer } from 'common/utils/reduxApiFactory'
import { PatchTinkoffState } from 'api/park/tinkoff/patch/types'
import { patchTinkoffActions } from 'api/park/tinkoff/patch/actions'

export const initialState: PatchTinkoffState = {
  status: null,
  data: null,
}

export const patchTinkoffReducer = createApiReducer(
  patchTinkoffActions,
  initialState
)
