import React from 'react'
import { CaptionText, ColorButton } from 'ui-kit/components'
import ListInput from 'common/components/formItems/ListInput'
import {
  Field,
  FormSubmitHandler,
  InjectedFormProps,
  reduxForm,
  SubmissionError,
} from 'redux-form'
import { FormNames } from 'common/enums'
import { required } from 'common/utils/fieldValidation'
import { HBox } from 'shared/ui/spacers'
import styled from 'styled-components'
import UploadOfXml, {
  OfdXmlData,
} from 'ui-kit/organisms/UploadOfdXml/UploadOfXml'
import { RadioGroup } from 'common/components/formItems/RadioGroup'
import { CloudCashBox, IntegrationType } from 'api/receipt/cloud-cashbox/types'
import { Sno } from 'api/receipt/sno/types'

type OwnProps = {
  onClose: () => void
  taxOptions: Sno[]
  onSubmit: (values: Partial<CloudCashBox>) => Promise<void>
}

const Wrapper = styled.div`
  padding: 8px 16px 16px;
  max-width: 540px;
`

type Props = InjectedFormProps<CloudCashBox, OwnProps> & OwnProps

export const OfdEditForm = ({
  submitting,
  handleSubmit,
  onSubmit,
  onClose,
  taxOptions,
  initialize,
}: Props) => {
  const handleParse = (data: OfdXmlData) => {
    initialize({
      inn: data.inn,
      kpp: data.kpp,
      name: data.name,
      cb_integration: {
        login: data.login,
        password: data.password,
        group_code: data.groupCode,
        payment_address: data.hostname,
        integration_type: IntegrationType.ATOL,
      },
      sno_id: taxOptions[0].code,
    })
  }

  const handleSave = () => {
    const submit: FormSubmitHandler<CloudCashBox, OwnProps> = async (
      values
    ) => {
      try {
        await onSubmit(values)
        onClose()
      } catch (e) {
        throw new SubmissionError(e)
      }
    }

    return handleSubmit(submit)
  }

  return (
    <Wrapper>
      <UploadOfXml onParse={handleParse} />
      <HBox x={2 / 3} />

      <Field
        name="name"
        label="Название юридического лица"
        iconName="close"
        validate={[required]}
        component={ListInput}
      />

      <HBox x={2 / 3} />

      <Field
        name="inn"
        label="ИНН"
        iconName="close"
        validate={[required]}
        component={ListInput}
      />

      <HBox x={2 / 3} />

      <Field
        name="kpp"
        label="КПП"
        iconName="close"
        validate={[required]}
        component={ListInput}
      />

      <HBox x={2 / 3} />

      <CaptionText color="gray7">Налогообложение</CaptionText>
      <HBox x={0.5} />
      <Field
        name="sno_id"
        component={RadioGroup}
        options={taxOptions.map((option) => ({
          label: option.name,
          value: option.code,
        }))}
      />

      <HBox x={2 / 3} />

      <ColorButton isLoading={submitting} onClick={handleSave()}>
        Сохранить
      </ColorButton>
    </Wrapper>
  )
}

export default reduxForm<CloudCashBox, OwnProps>({
  form: FormNames.OfdEdit,
})(OfdEditForm)
