import { connect } from 'react-redux'

import { State } from 'reducers'
import { apiSelectors } from 'api'
import { ParkDetails } from 'settings/features/parkDetails/organisms'

const mapStateToProps = (state: State) => ({
  details: apiSelectors.park.details.getDetails(state),
  isFetching: apiSelectors.park.details.getIsFetching(state),
})

const mapDispatchToProps = () => ({})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const ParkDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ParkDetails)
