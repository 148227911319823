import React from 'react'

import ListItem from 'common/components/listItem/ListItem'
import { SortDirection } from 'common/enums'

import { ColumnWrapper } from 'shared/table/components/Column/styles'
import { ColumnProps } from 'shared/table/interfaces'

import { Icon, TableText } from 'ui-kit/components'
import { IconNames } from 'ui-kit'

export interface InjectedProps {
  onSortableClick: (name: string) => void
  sortColumn?: string
  sortDirection?: SortDirection
}

export const Column: React.FC<ColumnProps> = (props) => {
  const { children, flex, sortable, className, data, ...rest } = props

  const injectedProps: InjectedProps = rest as InjectedProps

  const onClick = (): void => {
    if (sortable) {
      injectedProps.onSortableClick(sortable)
    }
  }

  const sortPosition = (): IconNames => {
    switch (injectedProps.sortDirection) {
      case SortDirection.ASC:
        return 'arrowUpward'
      case SortDirection.DESC:
        return 'arrowDownward'
      default:
        return 'arrowDownward'
    }
  }

  return children ? (
    <ListItem
      onClick={onClick}
      pointer={!!sortable}
      border="left-both"
      flex={flex}
      className={
        className
          ? `list-item-inside list-item-inside-header ${className}`
          : 'list-item-inside list-item-inside-header'
      }
    >
      <ColumnWrapper sortable={!!sortable}>
        {typeof children === 'string' ? (
          <TableText className="table-title" bold>
            {children}
          </TableText>
        ) : (
          children
        )}
        {sortable && injectedProps.sortColumn === sortable && (
          <Icon className="sort-icon" color="blue" name={sortPosition()} />
        )}
      </ColumnWrapper>
    </ListItem>
  ) : null
}
