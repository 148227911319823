import { SpacerSize } from '@elastic/eui/src/components/spacer/spacer'
import styled from 'styled-components'

interface HorizontalSpacerProps {
  size: SpacerSize
}

const spacerLabeleToSize = (label: SpacerSize) => {
  const order: Record<SpacerSize, number> = {
    xs: 4,
    s: 8,
    m: 16,
    l: 32,
    xl: 64,
    xxl: 128,
  }

  return order[label]
}

const HorizontalSpacer = styled.div<HorizontalSpacerProps>`
  display: inline-block;
  width: ${({ size }) => spacerLabeleToSize(size)}px;
`

export default HorizontalSpacer
