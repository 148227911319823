import React from 'react'

import { BodyText, CaptionText } from 'ui-kit/components'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;

  @media (max-width: 1024px) {
    display: none;
  }
`

const StyledBodyText = styled(BodyText)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
`

interface Props {
  full_name?: string
  email?: string
}

export const UserInfo: React.FC<Props> = ({ full_name, email }) => (
  <Container>
    <StyledBodyText color="secondaryBG">{full_name}</StyledBodyText>
    <CaptionText color="darkGray">{email}</CaptionText>
  </Container>
)
