import { createApiReducer } from 'common/utils/reduxApiFactory'
import { PatchBlockCloudCashBoxState } from 'api/receipt/cloud-cashbox/block/patch/types'
import { patchBlockCloudCashBoxActions } from 'api/receipt/cloud-cashbox/block/patch/actions'

export const initialState: PatchBlockCloudCashBoxState = {
  status: null,
  data: null,
}

export const patchBlockCloudCashBoxReducer = createApiReducer(
  patchBlockCloudCashBoxActions,
  initialState
)
