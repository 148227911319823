import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { State } from 'reducers'
import { apiSelectors } from 'api'
import { MyThunkDispatch } from 'api/reducer'
import { onDidMount } from 'employees/features/employeeDetails/thunks'
import { EmployeeDetails } from 'employees/features/employeeDetails/organisms'

const mapStateToProps = (state: State) => ({
  isFetching: apiSelectors.employees.employeesByIdGet.getIsFetching(state),
  employee_id: apiSelectors.employees.employeesByIdGet.getId(state),
  canViewLogs: apiSelectors.employee.profile.getCanViewLogs(state),
})

const mapDispatchToProps = (
  dispatch: MyThunkDispatch,
  { match }: RouteComponentProps<{ employee_id: string }>
) => ({
  onDidMount: () => dispatch(onDidMount(match.params.employee_id)),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const EmployeeDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeDetails)
