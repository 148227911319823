import { ThunkAction } from 'redux-thunk'
import { State } from 'reducers'
import axios from 'axios'
import { FailureError } from 'common/utils/reduxApiFactory'
import { TApiError } from 'api/types'
import { postPayoutCredentialsActions as actions } from 'api/park/payout/credentials/post/actions'
import {
  PostPayoutCredentialsStateActions,
  PostPayoutCredentialsRequestSchema,
} from 'api/park/payout/credentials/post/types'
import { BankType } from 'common/enums'

export type HandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  PostPayoutCredentialsStateActions
>

export type BankOptionsFromBankType<
  T extends BankType
> = T extends BankType.AlfaE2C
  ? PostPayoutCredentialsRequestSchema['alfa_e2c']
  : T extends BankType.Qiwi
  ? PostPayoutCredentialsRequestSchema['qiwi']
  : T extends BankType.Modul
  ? PostPayoutCredentialsRequestSchema['modul']
  : never

export const postPayoutCredentialsRequest = <T extends BankType>(
  bankType: T,
  bankSpecificOptions: BankOptionsFromBankType<T>
): HandleRequest => async (dispatch) => {
  dispatch(actions.start())
  try {
    const requestBody: PostPayoutCredentialsRequestSchema = {
      [bankType]: bankSpecificOptions,
    }

    await axios.post('erp/1/park/payout/credentials', requestBody)
    dispatch(actions.success())
  } catch (e) {
    if (e.response) {
      const data = e.response.data as TApiError
      const error: FailureError = new Error(data.message)
      error.data = data
      dispatch(actions.failure(error))
    }
    throw e
  }
}
