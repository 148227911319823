import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  ContentCard,
  ContentCardBody,
  ContentCardHeader,
} from 'shared/ui/contentCard'
import { CaptionText } from 'ui-kit/components'
import { HBox, VBox } from 'shared/ui/spacers'
import {
  RadioButtonGroup,
  ReportSubmitButton,
} from 'payments/features/reportsTab/molecules'
import DateRangePicker from 'common/components/dateRangePicker/DateRangePicker'
import styled from 'styled-components'
import { Aggregator } from 'api/types'
import { CheckboxSelect } from 'ui-kit/atoms'
import { AggregatorType } from 'common/enums'

const Wrapper = styled.div`
  padding: ${({ theme }) => theme.paddings.main}px;
  display: flex;
  flex-direction: row;
`

const WrapperContainer = styled.div`
  width: 100%;
  max-width: px;
`

const halfYear = new Date()
halfYear.setMonth(halfYear.getMonth() - 7)
const now = new Date()
now.setDate(now.getDate() - 1)
const yesterday = new Date()
yesterday.setDate(yesterday.getDate() - 1)
const days3 = new Date()
days3.setDate(days3.getDate() - 3)
const week = new Date()
week.setDate(week.getDate() - 7)
const month = new Date()
month.setDate(month.getDate() - 30)
const days45 = new Date()
days45.setDate(days45.getDate() - 45)
const quarter = new Date()
quarter.setDate(quarter.getDate() - 90)

const report1CValues = {
  yesterday: { dateFrom: yesterday, dateTo: now },
  days3: { dateFrom: days3, dateTo: now },
  week: { dateFrom: week, dateTo: now },
  month: { dateFrom: month, dateTo: now },
  quarter: { dateFrom: quarter, dateTo: now },
}

const report1COptions = [
  { value: 'yesterday', label: 'Вчера' },
  { value: 'days3', label: '3 дня' },
  { value: 'week', label: '7 дней' },
  { value: 'month', label: '30 дней' },
  { value: 'quarter', label: '90 дней' },
]

const reportPaymentsValues = {
  yesterday: { dateFrom: yesterday, dateTo: now },
  days3: { dateFrom: days3, dateTo: now },
  week: { dateFrom: week, dateTo: now },
  month: { dateFrom: month, dateTo: now },
  days45: { dateFrom: days45, dateTo: now },
}

const reportPaymentsOptions = [
  { value: 'yesterday', label: 'Вчера' },
  { value: 'days3', label: '3 дня' },
  { value: 'week', label: '7 дней' },
  { value: 'month', label: '30 дней' },
  { value: 'days45', label: '45 дней' },
]

type ReportPeriod1C = keyof typeof report1CValues
type ReportPeriodPayments = keyof typeof reportPaymentsValues

function isDateEqual(a: Date, b: Date) {
  const isDayEqual = a.getDate() === b.getDate()
  const isMonthEqual = a.getMonth() === b.getMonth()
  const isYearEqual = a.getFullYear() === b.getFullYear()
  return isDayEqual && isMonthEqual && isYearEqual
}

function getOptionByDates1C(props: { from: Date; to: Date }) {
  const keys = Object.keys(report1CValues)
  let result: ReportPeriod1C | undefined
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i] as ReportPeriod1C
    const val = report1CValues[key]
    if (
      isDateEqual(val.dateTo, props.to) &&
      isDateEqual(val.dateFrom, props.from)
    ) {
      result = key
    }
  }
  return result
}

function getOptionByDatesPayments(props: { from: Date; to: Date }) {
  const keys = Object.keys(reportPaymentsValues)
  let result: ReportPeriodPayments | undefined
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i] as ReportPeriodPayments
    const val = reportPaymentsValues[key]
    if (
      isDateEqual(val.dateTo, props.to) &&
      isDateEqual(val.dateFrom, props.from)
    ) {
      result = key
    }
  }
  return result
}

type Props = {
  email: string
  aggregators: Aggregator[]
  isFetching: boolean
  isE2C: boolean
  onSend1C: (data: {
    dateFrom: Date
    dateTo: Date
    aggregators: Omit<Aggregator, 'account'>[]
  }) => Promise<void>
  onSendPayments: (data: { dateFrom: Date; dateTo: Date }) => Promise<void>
}
export const PurePaymentsReportTab: React.FC<Props> = ({
  email,
  aggregators,
  onSend1C,
  onSendPayments,
  isFetching: isFetching1C,
  isE2C,
}) => {
  const aggregatorIdAndTypeSeparator = '___'

  const [range1C, setRange1C] = useState({ from: yesterday, to: now })
  const [selectedAggregators, setSelectedAggregators] = useState<string[]>([])
  const [checklistError1C, setChecklistError1C] = useState<string | null>(null)
  const [isSubmitting1C, setIsSubmitting1C] = useState(false)

  const [rangePayments, setRangePayments] = useState({
    from: yesterday,
    to: now,
  })
  const [isSubmittingPayments, setIsSubmittingPayments] = useState(false)

  useEffect(() => {
    setSelectedAggregators(
      aggregators.map(
        ({ id, type }) => `${id}${aggregatorIdAndTypeSeparator}${type}`
      )
    )
  }, [aggregators])

  const handleSubmit1C = useCallback(async () => {
    setIsSubmitting1C(true)
    try {
      await onSend1C({
        dateTo: range1C.to,
        dateFrom: range1C.from,
        aggregators: selectedAggregators.map((idAndType) => {
          const [id, type] = idAndType.split(aggregatorIdAndTypeSeparator)
          return {
            id,
            type: type as AggregatorType,
          }
        }),
      })
      setIsSubmitting1C(false)
    } catch (e) {
      setIsSubmitting1C(false)
      throw e
    }
  }, [onSend1C, range1C.from, range1C.to, selectedAggregators])

  const handleSubmitPayments = useCallback(async () => {
    setIsSubmittingPayments(true)
    try {
      await onSendPayments({
        dateTo: rangePayments.to,
        dateFrom: rangePayments.from,
      })
      setIsSubmittingPayments(false)
    } catch (e) {
      setIsSubmittingPayments(false)
      throw e
    }
  }, [onSendPayments, rangePayments.from, rangePayments.to])

  const selectedOption1C = useRef<ReportPeriod1C | undefined>('yesterday')
  const handleChange1C = useCallback((option: ReportPeriod1C) => {
    const period = report1CValues[option]
    setRange1C({ from: period.dateFrom, to: period.dateTo })
    selectedOption1C.current = option
  }, [])

  const handleChangeDate1C = (from: Date, to: Date) => {
    selectedOption1C.current = getOptionByDates1C({ from, to })
    setRange1C({ from, to })
  }

  const selectedOptionPayments = useRef<ReportPeriodPayments | undefined>(
    'yesterday'
  )
  const handleChangePayments = useCallback((option: ReportPeriodPayments) => {
    const period = reportPaymentsValues[option]
    setRangePayments({ from: period.dateFrom, to: period.dateTo })
    selectedOptionPayments.current = option
  }, [])

  const handleChangeDatePayments = (from: Date, to: Date) => {
    selectedOptionPayments.current = getOptionByDatesPayments({ from, to })
    setRangePayments({ from, to })
  }

  const handleChangeAggregators = (newSelectedAggregators: string[]) => {
    if (newSelectedAggregators.length === 0) {
      setChecklistError1C('Выберите агрегаторы')
    } else {
      setChecklistError1C(null)
    }

    setSelectedAggregators(newSelectedAggregators)
  }

  const canSubmit1C =
    isFetching1C || Boolean(checklistError1C) || isSubmitting1C

  const canSubmitPayments = isSubmittingPayments

  return (
    <Wrapper>
      <WrapperContainer>
        <ContentCard>
          <ContentCardHeader title="Отчет для 1С" />
          <ContentCardBody>
            <CaptionText color="secondaryDarkBlue">Период</CaptionText>
            <DateRangePicker
              minDate={halfYear}
              maxDate={now}
              dateFrom={range1C.from}
              dateTo={range1C.to}
              hasCloseButton={false}
              onChangeDate={handleChangeDate1C}
            />
            <HBox x={1 / 3} />
            <RadioButtonGroup
              isDisabled={isSubmitting1C || isFetching1C}
              initialValue={selectedOption1C.current}
              options={report1COptions}
              onChange={handleChange1C}
            />
            <HBox />
            {aggregators.length > 1 && (
              <>
                <CheckboxSelect
                  error={checklistError1C}
                  caption="Учетная запись агрегатора"
                  options={{
                    all: 'Все',
                    list: aggregators.map(({ account, id, type }) => ({
                      label: account,
                      value: `${id}${aggregatorIdAndTypeSeparator}${type}`,
                    })),
                  }}
                  value={selectedAggregators}
                  onChange={handleChangeAggregators}
                  isDisabled={isFetching1C || isSubmitting1C}
                />
                <HBox />
              </>
            )}
            <ReportSubmitButton
              email={email}
              onSubmit={handleSubmit1C}
              isDisabled={canSubmit1C}
            />
          </ContentCardBody>
        </ContentCard>
      </WrapperContainer>

      <VBox />

      <WrapperContainer>
        {isE2C && (
          <ContentCard>
            <ContentCardHeader title="Отчет для сверки платежей" />
            <ContentCardBody>
              <CaptionText color="secondaryDarkBlue">Период</CaptionText>
              <DateRangePicker
                minDate={days45}
                maxDate={now}
                dateFrom={rangePayments.from}
                dateTo={rangePayments.to}
                hasCloseButton={false}
                onChangeDate={handleChangeDatePayments}
              />
              <HBox x={1 / 3} />
              <RadioButtonGroup
                initialValue={selectedOptionPayments.current}
                options={reportPaymentsOptions}
                onChange={handleChangePayments}
              />
              <HBox />
              <ReportSubmitButton
                email={email}
                onSubmit={handleSubmitPayments}
                isDisabled={canSubmitPayments}
              />
            </ContentCardBody>
          </ContentCard>
        )}
      </WrapperContainer>
    </Wrapper>
  )
}
