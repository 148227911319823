import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import { State } from 'reducers'
import { apiActions, apiSelectors } from 'api'
import { FormNames } from 'common/enums'
import { ProfileInfo } from 'settings/features/profileInfo/organisms'

export interface FormProps {
  full_name: string | null
  phone: string
  oldPassword: string
  newPassword: string
  confirmNewPassword: string
}

const mapStateToProps = (state: State) => ({
  profile: apiSelectors.employee.profile.getProfile(state),
})

const mapDispatchToProps = {
  patchProfile: apiActions.employee.profile.patch,
}

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps

const Form = reduxForm<FormProps, ContainerProps>({
  form: FormNames.ProfileInfo,
})(ProfileInfo)

export const ProfileInfoContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Form)
