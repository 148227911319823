import React, { useState } from 'react'

import { ContentCard } from 'shared/ui'
import { Line } from 'drivers/features/driverAccounts/molecules'
import { ContainerProps } from 'drivers/features/driverAccounts/containers/DriverAccountsContainer'
import styled from 'styled-components'

const Container = styled.div`
  padding-top: ${({ theme }) => theme.paddings.half}px;
  padding-bottom: ${({ theme }) => theme.paddings.half}px;
`

const Divider = styled.div`
  height: 1px;
  margin-left: ${({ theme }) => theme.paddings.threeQuarters}px;
  margin-right: ${({ theme }) => theme.paddings.threeQuarters}px;
  background-color: ${({ theme }) => theme.pallete.gray3};
`

export const DriverAccounts: React.FC<ContainerProps> = ({ accounts }) => {
  const [displayIndex, setDisplayIndex] = useState<number | null>(null)
  return (
    <ContentCard withMinHeight={false}>
      <Container>
        {accounts.map((account, index, array) => (
          <React.Fragment key={account.user_id}>
            <Line
              index={index}
              account={account}
              displayIndex={displayIndex}
              setDisplayIndex={setDisplayIndex}
            />
            {index !== array.length - 1 ? <Divider /> : null}
          </React.Fragment>
        ))}
      </Container>
    </ContentCard>
  )
}
