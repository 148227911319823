import React, { useState } from 'react'
import { Field, InjectedFormProps, SubmissionError } from 'redux-form'

import { FloatButton, Icon, ListGroup } from 'ui-kit/components'
import ListInput from 'common/components/formItems/ListInput'
import InputMask from 'common/components/formItems/InputMask'
import {
  email,
  maxLength30,
  minLength6,
  required,
} from 'common/utils/fieldValidation'
import { phoneMask, phonePattern } from 'common/utils/patterns'
import { HBox, Modal } from 'shared/ui'
import { PermissionsLayout } from 'employees/features/employeesList/molecules'
import {
  ContainerProps,
  FormProps,
} from 'employees/features/employeesList/containers/AddEmployeeModalContainer'
import { Dispatch } from 'redux'
import styled from 'styled-components'

const Container = styled.div`
  padding: 0 16px;
`
const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin: 16px 0;
  button {
    max-width: 360px;
  }
`
const SetRightsTrigger = styled.div`
  display: flex;
  margin: 16px 0;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`

export const AddEmployeeModal: React.FC<
  InjectedFormProps<FormProps, ContainerProps> & ContainerProps
> = ({
  id,
  submitting,
  closeModal,
  valid,
  handleSubmit,
  isBlocked,
  isParkCreator,
  employeePermissions,
  canChangePermissions,
  onSave,
  openBlockEmployeeModal,
}) => {
  const [editingRights, setEditingRights] = useState(false)

  const [permissions, setPermissions] = useState<Set<string>>(
    new Set<string>(employeePermissions)
  )

  const handleSave = (newPermissions: Set<string>) => async (
    values: FormProps,
    _: Dispatch<never>,
    props: ContainerProps
  ) => {
    try {
      await onSave({ values, permissions: newPermissions, id: props.id })

      if (isParkCreator) {
        window.location.reload()
      }
    } catch (e) {
      throw new SubmissionError(e)
    }
  }

  return (
    <Modal
      open
      noOverlayClick={false}
      onClose={closeModal}
      closeButton={!editingRights}
      title={editingRights ? 'Права и доступы' : 'Личные данные'}
      width={528}
      noPadding
    >
      {editingRights ? (
        <PermissionsLayout
          permissions={permissions}
          setPermissions={setPermissions}
          setEditingRights={setEditingRights}
          canChangePermissions={canChangePermissions}
        />
      ) : (
        <>
          <Container>
            <Field
              name="full_name"
              label="ФИО"
              validate={[required]}
              iconName="close"
              component={ListInput}
            />
            <HBox x={0.5} />
            <Field
              name="position"
              label="Должность"
              validate={[required]}
              iconName="close"
              component={ListInput}
            />
            <HBox x={0.5} />
            <Field
              name="phone"
              label="Номер телефона"
              validate={[required]}
              pattern={phonePattern}
              iconName="close"
              mask={phoneMask}
              component={InputMask}
            />
            <HBox x={0.5} />
            <Field
              name="email"
              label="Email"
              validate={[required, email]}
              iconName="close"
              component={ListInput}
              disabled={Boolean(id)}
            />
            <HBox x={0.5} />
            {!id ? (
              <>
                <Field
                  name="password"
                  label="Пароль"
                  type="password"
                  validate={[required, minLength6, maxLength30]}
                  component={ListInput}
                />
                <HBox x={0.5} />
              </>
            ) : null}
          </Container>

          {id ? (
            <Container>
              <HBox x={0.5} />
              <Icon
                name="close"
                pointer
                color={isBlocked ? 'blue' : 'red'}
                onClick={openBlockEmployeeModal}
              >
                {isBlocked ? 'Разблокировать' : 'Заблокировать'}
              </Icon>
              <HBox x={0.75} />
            </Container>
          ) : null}

          <ListGroup>Права и доступы</ListGroup>
          <Container>
            <SetRightsTrigger onClick={() => setEditingRights(true)}>
              <Icon name="user" color="blue">
                Настроить права и доступ
              </Icon>
              <Icon name="chevronRight" color="blue" />
            </SetRightsTrigger>
            <HBox />
            <Actions>
              <FloatButton
                disabled={!valid}
                onClick={handleSubmit(handleSave(permissions))}
                fullWidth
                isLoading={submitting}
              >
                {id ? 'Обновить' : 'Добавить'}
              </FloatButton>
            </Actions>
          </Container>
        </>
      )}
    </Modal>
  )
}
