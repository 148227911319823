import React, { useEffect, useRef, useState } from 'react'

import { Icon as DefaultIcon, SubBodyText } from 'ui-kit/components'
import { VBox } from 'shared/ui/spacers'
import { DropdownData } from 'shared/ui/dropdown/types'
import styled from 'styled-components'

const Wrapper = styled.div<{ inherit: boolean }>`
  position: relative;
  height: ${({ inherit }) => inherit && '100%'};
`

const Toggler = styled.div<{ inherit: boolean }>`
  display: flex;
  height: ${({ inherit }) => (inherit ? 'inherit' : '48px')};
  align-items: center;
  cursor: pointer;
`

const OptionsCard = styled.div<{ right?: boolean }>`
  position: absolute;
  top: 100%;
  right: ${({ right }) => right && '0'};
  min-width: 200px;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: ${({ theme }) => theme.pallete.white};
  box-shadow: 0 2px 5px rgba(192, 206, 226, 0.4),
    0px -2px 3px rgba(192, 206, 226, 0.25);
  border-radius: 5px;
  z-index: 99;
`

const Option = styled.div`
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  height: 48px;
  align-items: center;
  cursor: pointer;
`

const Icon = styled(DefaultIcon)`
  max-width: 24px;
  max-height: 24px;
  svg {
    max-width: 24px;
    max-height: 24px;
  }
`

interface Props {
  data: DropdownData[]
  right?: boolean
  component?: (props: ComponentRenderProps) => React.ReactElement
}
type ComponentRenderProps = { isExpanded: boolean }
type ComponentProps = {
  render(props: ComponentRenderProps): React.ReactElement
}
export const Dropdown: React.FC<Props> = ({
  children,
  data,
  right,
  component,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const elRef = useRef<HTMLDivElement>(null)

  const handleDocumentClick = (e: MouseEvent) => {
    const trigger = elRef.current
    const target = e.target as Node

    if (!!trigger && !trigger.contains(target)) {
      setIsExpanded(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleDocumentClick)
    return () => {
      document.removeEventListener('mousedown', handleDocumentClick)
    }
  }, [])

  const Component: React.FC<ComponentProps> = ({ render }) => (
    <>{render({ isExpanded })}</>
  )

  const renderContent = () => {
    if (component) {
      return <Component render={component} />
    }
    if (children) {
      return (
        <>
          {typeof children === 'string' ? (
            <SubBodyText color="blue" fontWeight="semi-bold">
              {children}
            </SubBodyText>
          ) : (
            children
          )}
          <VBox x={0.25} />
          <Icon name={isExpanded ? 'expandLess' : 'expandMore'} color="blue" />
        </>
      )
    }
    return <Icon name="options" />
  }

  return (
    <Wrapper ref={elRef} inherit={Boolean(component)}>
      <Toggler
        inherit={Boolean(component)}
        onClick={() => setIsExpanded((prevValue) => !prevValue)}
      >
        {renderContent()}
      </Toggler>

      {isExpanded && (
        <OptionsCard right={right}>
          {data.map((item) => (
            <Option key={item.label} onClick={item.function}>
              {item.iconName ? (
                <>
                  <Icon name={item.iconName} />
                  <VBox x={0.5} />
                </>
              ) : null}
              <SubBodyText>{item.label}</SubBodyText>
            </Option>
          ))}
        </OptionsCard>
      )}
    </Wrapper>
  )
}
