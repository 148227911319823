import { history } from 'store'
import { apiActions } from 'api'
import { MyThunkDispatch, MyThunkResult } from 'api/reducer'
import { BankType, RoutesPath } from 'common/enums'
import { ApiCode } from 'common/types/api/codes'
import { currencyToSub } from 'common/utils/formatters'
import { FormProps as QiwiFormProps } from 'steps/features/stepPayments/containers/PaymentsQiwiContainer'
import { FormProps as ModulFormProps } from 'steps/features/stepPayments/containers/PaymentsModulContainer'
import { FormProps as TinkoffFormProps } from 'steps/features/stepPayments/containers/PaymentsTinkoffContainer'
import { PostPayoutCredentialsRequestSchema } from 'api/park/payout/credentials/post/types'

export const onSaveQiwi: ({
  values,
}: {
  values: QiwiFormProps
}) => MyThunkResult<void> = ({ values }) => (dispatch: MyThunkDispatch) => {
  return dispatch(
    apiActions.park.postPayoutCredentials.handleRequest(BankType.Qiwi, {
      terminal_id: values.terminal_id,
      password: values.password,
      min_balance:
        values.min_balance && values.email_notifications
          ? currencyToSub(parseFloat(values.min_balance))
          : 0,
      email_notifications: values.email_notifications
        ? values.email_notifications
        : false,
    })
  ).then(
    () => {
      history.replace(RoutesPath.StepsDrivers)
    },
    (reason) => {
      if (reason.response) {
        if (
          reason.response.data.code === ApiCode.InputValidationError &&
          reason.response.data.payload
        ) {
          throw reason.response.data.payload
        }
        if (reason.response.data.code === ApiCode.QiwiCredentialsAlreadyExist) {
          history.replace(RoutesPath.StepsDrivers)
        }
      }
    }
  )
}

export const onSaveModul: ({
  values,
}: {
  values: ModulFormProps
}) => MyThunkResult<void> = ({ values }) => (dispatch: MyThunkDispatch) => {
  return dispatch(
    apiActions.park.postPayoutCredentials.handleRequest(BankType.Modul, {
      account_token: values.authorization_token,
      payment_token: values.payments_token,
    })
  ).then(
    () => {
      history.replace(RoutesPath.StepsDrivers)
    },
    (reason) => {
      if (reason.response) {
        if (
          reason.response.data.code === ApiCode.InputValidationError &&
          reason.response.data.payload
        ) {
          throw reason.response.data.payload
        }
      }
    }
  )
}

export const onSaveAlfaE2C: ({
  values,
}: {
  values: {
    password: string
    username: string
  }
}) => MyThunkResult<void> = ({ values }) => (dispatch: MyThunkDispatch) => {
  return dispatch(
    apiActions.park.postPayoutCredentials.handleRequest(BankType.AlfaE2C, {
      username: values.username,
      password: values.password,
    })
  ).then(
    () => {
      history.replace(RoutesPath.StepsDrivers)
    },
    (reason) => {
      if (reason.response) {
        if (
          reason.response.data.code === ApiCode.InputValidationError &&
          reason.response.data.payload
        ) {
          throw reason.response.data.payload
        }
      }
    }
  )
}

export const onSaveTinkoff: ({
  values,
}: {
  values: TinkoffFormProps
}) => MyThunkResult<void> = ({ values }) => (dispatch: MyThunkDispatch) => {
  return dispatch(apiActions.park.patchTinkoff.handleRequest(values)).then(
    () => {
      history.replace(RoutesPath.StepsDrivers)
    },
    (reason) => {
      if (reason.response) {
        if (
          reason.response.data.code === ApiCode.InputValidationError &&
          reason.response.data.payload
        ) {
          throw reason.response.data.payload
        }
      }
    }
  )
}
