import React, { useState } from 'react'
import DragAndDropInput from 'ui-kit/molecules/DragAndDropInput/DragAndDropInput'
import styled from 'styled-components'
import { CaptionText, SubBodyText } from 'ui-kit/components/Typography'
import { VBox } from 'ui-kit/atoms/spacers'
import { parse, validate } from 'fast-xml-parser'

export type OfdXmlData = {
  name: string
  inn: string
  kpp: string
  hostname: string
  groupCode: string
  login: string
  password: string
}

type Props = {
  onParse: (props: OfdXmlData) => void
}

const TextWrapper = styled.div`
  padding: 16px 24px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Placeholder = styled(SubBodyText)`
  display: flex;
`

type XmlParseData = {
  cms_params?: {
    '@_Company'?: string
    '@_INN'?: string
    '@_KPP'?: string
    shop?: {
      '@_hostname'?: string
      access?: {
        '@_group_code'?: string
        '@_login'?: string
        '@_password'?: string
      }
    }
  }
}

const UploadOfXml = ({ onParse }: Props) => {
  const [error, setError] = useState('')
  const [key, setKey] = useState(Date.now())
  const handleChange = (xml: string) => {
    const validXml = validate(xml)
    if (validXml !== true) {
      setKey(Date.now())
      setError('Файл не читается. Загрузите xml-файл из личного кабинета АТОЛ')
      console.error(validXml.err)
      return
    }
    const result: XmlParseData = parse(xml, { ignoreAttributes: false })

    const name = result?.cms_params?.['@_Company']
    const inn = result?.cms_params?.['@_INN']
    const kpp = result?.cms_params?.['@_KPP']
    const hostname = result?.cms_params?.shop?.['@_hostname']
    const groupCode = result?.cms_params?.shop?.access?.['@_group_code']
    const login = result?.cms_params?.shop?.access?.['@_login']
    const password = result?.cms_params?.shop?.access?.['@_password']
    if (name && inn && kpp && hostname && groupCode && login && password) {
      onParse({ name, inn, kpp, hostname, groupCode, login, password })
    } else {
      setKey(Date.now())
      setError('Файл не читается. Загрузите xml-файл из личного кабинета АТОЛ')
    }
  }

  return (
    <>
      <DragAndDropInput
        key={key}
        acceptOnly={['text/xml', 'application/xml']}
        errorMessage="Файл не читается. Загрузите xml-файл из личного кабинета АТОЛ"
        onChange={handleChange}
      >
        {({ isDragAccept, isDragReject, isHover }) => {
          if (isDragReject) {
            return (
              <TextWrapper>
                <SubBodyText color="textPrimary">
                  Файл не правильного формата. Загрузите xml-файл из личного
                  кабинета АТОЛ
                </SubBodyText>
              </TextWrapper>
            )
          }
          if (isDragAccept) {
            return (
              <TextWrapper>
                <SubBodyText color="textPrimary">
                  Отпустите файл, чтобы начать загрузку
                </SubBodyText>
              </TextWrapper>
            )
          }
          return (
            <TextWrapper>
              <Placeholder color={isHover ? 'textSecondary' : 'textPrimary'}>
                <SubBodyText color="accentPrimary" fontWeight="semi-bold">
                  Выберите
                </SubBodyText>
                <VBox x={1 / 6} />
                или перетащите xml-файл
              </Placeholder>
            </TextWrapper>
          )
        }}
      </DragAndDropInput>
      {error && <CaptionText color="uiRed">{error}</CaptionText>}
    </>
  )
}

export default React.memo<Props>(UploadOfXml)
