import { Action, BaseAction } from 'redux-actions'
import { AggregatorType } from 'common/enums'

export type SET_CURRENT = Action<string>
export type SET_TYPE = Action<AggregatorType>
export type RESET = BaseAction

export type TAggregatorsListAction = SET_CURRENT | SET_TYPE | RESET

export const SET_CURRENT: SET_CURRENT['type'] =
  'settings/aggregatorsList/SET_CURRENT'
export const SET_TYPE: SET_TYPE['type'] = 'settings/aggregatorsList/SET_TYPE'
export const RESET: RESET['type'] = 'settings/aggregatorsList/RESET'

export const setCurrent = (id: string): SET_CURRENT => ({
  type: SET_CURRENT,
  payload: id,
})
export const setType = (type: AggregatorType): SET_TYPE => ({
  type: SET_TYPE,
  payload: type,
})
export const reset = (): RESET => ({
  type: RESET,
})

export type Payloads = Pick<SET_CURRENT & SET_TYPE, 'payload'>['payload']
