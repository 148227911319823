import React from 'react'
import { Loader } from 'shared/ui/loader'
import styled from 'styled-components'

const Container = styled.div<{
  backgroundColor?: string
  boxShadow?: string
  zeroState?: boolean
  fullHeight?: boolean
  withMinHeight?: boolean
}>`
  position: relative;
  width: 100%;
  height: ${({ fullHeight }) => fullHeight && '100%'};
  min-height: ${({ withMinHeight }) => withMinHeight && '120px'};
  background-color: ${({ theme, backgroundColor, zeroState }) =>
    !zeroState && (backgroundColor || theme.pallete.white)};

  border: ${({ theme, zeroState }) =>
    zeroState && `2px dashed ${theme.pallete.uiSecondary}`};
  border-radius: 10px;

  box-shadow: ${({ boxShadow, zeroState }) => !zeroState && boxShadow};
`

interface Props {
  className?: string
  /** Screen ratio in percents */
  percentage?: number
  backgroundColor?: string
  boxShadow?: string
  isLoading?: boolean
  zeroState?: boolean
  fullHeight?: boolean
  withMinHeight?: boolean
}

const defaultProps = {
  boxShadow: '0 3px 10px rgba(5, 16, 34, 0.17)',
}

export const ContentCard: React.FC<Props> = ({
  children,
  backgroundColor,
  boxShadow,
  isLoading,
  zeroState,
  fullHeight,
  className,
  withMinHeight = true,
}) => (
  <Container
    className={className}
    boxShadow={boxShadow}
    backgroundColor={backgroundColor}
    zeroState={zeroState}
    fullHeight={fullHeight}
    withMinHeight={withMinHeight}
  >
    {isLoading ? <Loader absolute /> : children}
  </Container>
)

ContentCard.defaultProps = defaultProps
