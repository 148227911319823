import React from 'react'
import { TableText } from 'ui-kit/components'
import { renderParticipant } from 'common/utils/helpers'
import { PaymentTransactionParticipant } from 'api/types'

type Props = {
  participant: PaymentTransactionParticipant
}
export const TransactionParticipant = React.memo<Props>(({ participant }) => {
  return <TableText>{renderParticipant(participant)}</TableText>
})

TransactionParticipant.displayName = 'TransactionParticipant'
