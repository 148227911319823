import React, { useCallback, useState } from 'react'

import { PaymentSettingsDeleteButton } from 'settings/features/paymentSettingsTab/molecules'
import { PaymentSettingsDeleteModal } from 'settings/features/paymentSettingsTab/pages'

type Props = {
  onDelete: () => Promise<void>
}

export const PaymentSettingsDeleteAction = React.memo<Props>(({ onDelete }) => {
  const [isFetching, setIsFetching] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleResolve = useCallback(async () => {
    setIsModalOpen(false)
    setIsFetching(true)
    try {
      await onDelete()
    } catch (e) {
      console.error(e)
    }
    setIsFetching(false)
  }, [onDelete])

  const handleReject = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const handleClick = useCallback(() => {
    setIsModalOpen(true)
  }, [])

  return (
    <>
      <PaymentSettingsDeleteButton
        isLoading={isFetching}
        onClick={handleClick}
      />
      {isModalOpen && (
        <PaymentSettingsDeleteModal
          onResolve={handleResolve}
          onReject={handleReject}
        />
      )}
    </>
  )
})

PaymentSettingsDeleteAction.displayName = 'PaymentSettingsDeleteAction'
