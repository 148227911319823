import React from 'react'
import { formatToRegularDate } from 'common/utils/dates'
import { TableText } from 'ui-kit/components'

type Props = {
  created: string
}
export const TransactionDate = React.memo<Props>(({ created }) => (
  <TableText>{formatToRegularDate(created)}</TableText>
))

TransactionDate.displayName = 'TransactionDate'
