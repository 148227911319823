import { LargeFilter } from 'common/types/local/filter'
import {
  PaymentOperationType,
  PaymentReason,
  paymentReasonToString,
  PaymentStatus,
  paymentStatusToString,
} from 'common/enums'

const operationCategory = 'Тип транзакций'

const reasonCategory = 'Основание'

const paymentStatusCategory = 'Статус платежа'

export const filtersData: LargeFilter[] = [
  {
    name: PaymentOperationType.TOPUP,
    label: 'Доходные',
    category: operationCategory,
  },
  {
    name: PaymentOperationType.WITHDRAW,
    label: 'Расходные',
    category: operationCategory,
  },
  {
    name: PaymentReason.TAXI_RIDE_DRIVER,
    label: paymentReasonToString(PaymentReason.TAXI_RIDE_DRIVER),
    category: reasonCategory,
  },
  {
    name: PaymentReason.TAXI_RIDE_AGGREGATOR_COMMISSION,
    label: paymentReasonToString(PaymentReason.TAXI_RIDE_AGGREGATOR_COMMISSION),
    category: reasonCategory,
  },
  {
    name: PaymentReason.PARK_DRIVER_WITHDRAW,
    label: paymentReasonToString(PaymentReason.PARK_DRIVER_WITHDRAW),
    category: reasonCategory,
  },
  {
    name: PaymentReason.PARK_DRIVER_WITHDRAW_COMMISSION,
    label: paymentReasonToString(PaymentReason.PARK_DRIVER_WITHDRAW_COMMISSION),
    category: reasonCategory,
  },
  {
    name: PaymentReason.DRIVER_FUEL_CARD_TOPUP,
    label: paymentReasonToString(PaymentReason.DRIVER_FUEL_CARD_TOPUP),
    category: reasonCategory,
  },
  {
    name: PaymentReason.TAXOMETER_MANUAL_TOPUP,
    label: paymentReasonToString(PaymentReason.TAXOMETER_MANUAL_TOPUP),
    category: reasonCategory,
  },
  {
    name: PaymentStatus.PENDING,
    label: paymentStatusToString(PaymentStatus.PENDING),
    category: paymentStatusCategory,
  },
  {
    name: PaymentStatus.ACKNOWLEDGED,
    label: paymentStatusToString(PaymentStatus.ACKNOWLEDGED),
    category: paymentStatusCategory,
  },
  {
    name: PaymentStatus.PROCESSING,
    label: paymentStatusToString(PaymentStatus.PROCESSING),
    category: paymentStatusCategory,
  },
  {
    name: PaymentStatus.SUCCEEDED,
    label: paymentStatusToString(PaymentStatus.SUCCEEDED),
    category: paymentStatusCategory,
  },
  {
    name: PaymentStatus.DECLINED,
    label: paymentStatusToString(PaymentStatus.DECLINED),
    category: paymentStatusCategory,
  },
  {
    name: PaymentStatus.FAILED,
    label: paymentStatusToString(PaymentStatus.FAILED),
    category: paymentStatusCategory,
  },
]
