import { ThunkAction } from 'redux-thunk'
import { State } from 'reducers'
import axios, { AxiosResponse } from 'axios'
import { FailureError } from 'common/utils/reduxApiFactory'
import { TApiError } from 'api/types'
import { transactionsCountersActions } from 'api/park/transactions/counters/actions'
import {
  TransactionsCountersActions,
  TransactionsCounters,
} from 'api/park/transactions/counters/types'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  TransactionsCountersActions
>

export const getTransactionsCountersRequest = (): THandleRequest => async (
  dispatch
) => {
  const { start, update, success, failure } = transactionsCountersActions
  dispatch(start())
  try {
    const response: AxiosResponse<TransactionsCounters> = await axios.get(
      `erp/1/park/transactions/counters`
    )
    dispatch(update(response.data))
    dispatch(success())
  } catch (e) {
    if (e.response) {
      const data = e.response.data as TApiError
      const error: FailureError = new Error(data.message)
      error.data = data
      dispatch(failure(error))
    }
  }
}
