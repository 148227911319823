import {
  Action,
  ActionFunction0,
  ActionFunction1,
  combineActions,
  createAction,
  handleAction,
} from 'redux-actions'
import { TApiError } from 'api/types'

export type ApiActionType =
  | 'Start'
  | 'Success'
  | 'Update'
  | 'Failure'
  | 'Reset'
  | 'ResetStatus'

export type ApiStatusType = 'loading' | 'success' | 'failure' | null

export interface FailureError<T = never> extends Error {
  data?: TApiError<T>
}

export interface ApiState<T = never> {
  status: ApiStatusType
  data: T | null
  error?: any
}

export type ApiPayload<T = never> = Partial<ApiState<T>>

export type ApiActions<RequestResponse = never, RequestError = never> = {
  resetStatus: ActionFunction0<Action<ApiPayload<RequestResponse>>>
  start: ActionFunction0<Action<ApiPayload<RequestResponse>>>
  update: ActionFunction1<RequestResponse, Action<ApiPayload<RequestResponse>>>
  reset: ActionFunction0<Action<ApiPayload<RequestResponse>>>
  success: ActionFunction0<Action<ApiPayload<RequestResponse>>>
  failure: ActionFunction1<
    FailureError<RequestError>,
    Action<ApiPayload<RequestResponse>>
  >
}

export type ApiActionTypes<T = never, R = never> = ReturnType<
  ApiActions<T, R>[keyof ApiActions<T, R>]
>

export function createApiActions<
  RequestResponse = never,
  RequestParams = never
>(apiEndpoint: string) {
  const start = createAction<ApiPayload<RequestResponse>>(
    `${apiEndpoint}->Start`,
    () => ({
      status: 'loading',
    })
  )
  const success = createAction<ApiPayload<RequestResponse>>(
    `${apiEndpoint}->Success`,
    () => ({
      status: 'success',
    })
  )
  const update = createAction<ApiPayload<RequestResponse>, RequestResponse>(
    `${apiEndpoint}->Update`,
    (payload: RequestResponse) => ({ data: payload })
  )
  const failure = createAction<
    ApiPayload<RequestResponse>,
    FailureError<RequestParams>
  >(
    `${apiEndpoint}->Failure`,
    (payloadFailure: FailureError<RequestParams>) => ({
      status: 'failure',
      error: payloadFailure.data,
    })
  )
  const reset = createAction<ApiPayload<RequestResponse>>(
    `${apiEndpoint}->Reset`,
    () => ({
      data: null,
      status: null,
    })
  )
  const resetStatus = createAction<ApiPayload<RequestResponse>>(
    `${apiEndpoint}->ResetStatus`,
    () => ({
      status: null,
    })
  )

  return { start, success, update, failure, reset, resetStatus } as ApiActions<
    RequestResponse,
    RequestParams
  >
}

export function createApiReducer<RequestResponse, RequestParams>(
  actions: ApiActions<RequestResponse, RequestParams>,
  initialState: ApiState<RequestResponse>
) {
  return handleAction<ApiState<RequestResponse>, ApiPayload<RequestResponse>>(
    combineActions(
      actions.start,
      actions.success,
      actions.update,
      actions.failure,
      actions.reset,
      actions.resetStatus
    ),
    {
      next(state, action) {
        return { ...state, ...action.payload }
      },
      throw(state, action) {
        return { ...state, error: action.payload.data, status: 'failure' }
      },
    },
    initialState
  )
}
