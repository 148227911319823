import { EuiButtonEmpty, EuiModal, EuiPopover, EuiText } from '@elastic/eui'
import DescriptionAndActionsTemplate from 'elastic-ui/referral-program/templates/DescriptionAndActionsTemplate'
import { ProgramSettings } from 'elastic-ui/referral-program/types'
import RenderGate from 'elastic-ui/shared/atoms/RenderGate'
import { Modal } from 'elastic-ui/shared/molecules/Modal/Modal'
import React, { useState } from 'react'
import VerticalFlexbox from './atoms/VerticalFlexbox'
import { ProgramStates } from './types'
import {
  getDescriptionByState,
  getIconTypeAndColorByState,
  getTitleByState,
} from './utils'

interface Props {
  programSettings: ProgramSettings
  state: ProgramStates
  isLoading: boolean
  isFirstTime: boolean
  onHideFromDriversClick: () => void
  onShowToDriversClick: () => void
  onStopReferralProgramClick: (id: number) => void
  onStartReferralProgramClick: () => void
}

const DescriptionAndActionsPanel: React.FC<Props> = ({
  programSettings,
  state,
  isLoading,
  isFirstTime,
  onHideFromDriversClick,
  onShowToDriversClick,
  onStopReferralProgramClick,
  onStartReferralProgramClick,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const { type, color } = getIconTypeAndColorByState(state)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const title = getTitleByState(state)
  const description = getDescriptionByState(state)

  const closePopover = () => setIsPopoverOpen(false)
  const doAfterClosingPopover = (callback: () => void) => () => {
    closePopover()
    callback()
  }

  const isProgramDisabled = state === ProgramStates.Disabled
  const isProgramActive = state === ProgramStates.Active
  const isProgramHidden = state === ProgramStates.Hidden

  const closeModal = () => setIsModalOpen(false)

  return (
    <>
      <DescriptionAndActionsTemplate
        title={title}
        description={description}
        iconColor={color}
        iconType={type}
        isLoading={isLoading}
        isFirstTime={isFirstTime}
      >
        <EuiPopover
          button={
            <EuiButtonEmpty
              iconType={isPopoverOpen ? 'arrowUp' : 'arrowDown'}
              iconSide="right"
              onClick={() => setIsPopoverOpen((isOpen) => !isOpen)}
            >
              Действия
            </EuiButtonEmpty>
          }
          ownFocus
          isOpen={isPopoverOpen}
          closePopover={closePopover}
        >
          <VerticalFlexbox>
            <EuiButtonEmpty
              iconType="minusInCircle"
              color="danger"
              onClick={doAfterClosingPopover(() => setIsModalOpen(true))}
            >
              Остановить программу
            </EuiButtonEmpty>

            <RenderGate isOpen={isProgramHidden}>
              <EuiButtonEmpty
                iconType="eye"
                onClick={doAfterClosingPopover(onShowToDriversClick)}
              >
                Показывать условия водителям
              </EuiButtonEmpty>
            </RenderGate>

            <RenderGate isOpen={isProgramActive}>
              <EuiButtonEmpty
                iconType="eyeClosed"
                onClick={doAfterClosingPopover(onHideFromDriversClick)}
              >
                Не показывать водителям
              </EuiButtonEmpty>
            </RenderGate>
          </VerticalFlexbox>
        </EuiPopover>
      </DescriptionAndActionsTemplate>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onCancel={closeModal}
        onAccept={() => {
          onStopReferralProgramClick(programSettings.id)
          closeModal()
        }}
        acceptLabel="Отключить"
        cancelLabel="Отмена"
        acceptColor="danger"
        cancelColor="text"
        title="Отключение программы"
        onClickAway={closeModal}
      >
        <EuiText>
          Все необработанные заявки и возможность формирования отчета по данному
          оферу будут удалены
        </EuiText>
      </Modal>
    </>
  )
}

export default DescriptionAndActionsPanel
