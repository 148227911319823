import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { Drivers } from 'drivers/components'

const mapStateToProps = () => ({})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  RouteComponentProps

export const DriversContainer = withRouter(connect(mapStateToProps)(Drivers))
