import React, { useCallback, useMemo, useState } from 'react'

import { Col, HBox, VBox } from 'shared/ui'

import {
  PaymentSettingsIndividualCard,
  PaymentSettingsLockPlaceholder,
} from 'settings/features/paymentSettingsTab/molecules'
import { DriversListContainer as PaymentSettingsDriversList } from 'settings/features/paymentSettingsDriversList'
import { PaymentSettingsEdition } from 'settings/features/paymentSettingsEdition'
import { PaymentSettingsCard } from 'settings/features/paymentSettingsCard'
import { PaymentSettings } from 'api/types'
import { PaymentSettingsList } from 'settings/features/paymentSettingsTab/organisms/PaymentSettingsList/PaymentSettingsList'
import { PaymentSettingsDeleteAction } from 'settings/features/paymentSettingsTab/organisms'
import { defaultSettingsIndex } from 'settings/features/paymentSettingsTab/constants'
import styled from 'styled-components'
import useSWR from 'swr'
import { BankType } from 'common/enums'

const Wrapper = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.paddings.main}px;
`

const PaymentSettingDeleteButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

type Props = {
  bankType: BankType | null
  settingsList: PaymentSettings[]
  onCreateSettings: (settingsName: string) => Promise<void>
  onDeleteSettings: (settingsId: string) => Promise<void>
  isPaymentLocked?: boolean
}

export const PurePaymentSettingsTab = React.memo<Props>(
  ({
    settingsList,
    onCreateSettings,
    onDeleteSettings,
    isPaymentLocked,
    bankType,
  }) => {
    const [isSettingsEdit, setIsSettingsEdit] = useState(false)
    const {
      data: selectedSettingsIndex,
      mutate: setSelectedSettingsIndex,
    } = useSWR('PaymentSettingsTabSelectedIndex', {
      initialData: defaultSettingsIndex.toString(),
    })

    const totalSettingsList = settingsList.length

    const handleClickCreateSettings = useCallback(
      async (settingsName: string) => {
        await onCreateSettings(settingsName)
        setSelectedSettingsIndex(totalSettingsList.toString())
        setIsSettingsEdit(true)
      },
      [onCreateSettings, setSelectedSettingsIndex, totalSettingsList]
    )

    const handleClickDeleteSettings = useCallback(async () => {
      await Promise.all([
        onDeleteSettings(
          settingsList[parseInt(selectedSettingsIndex as string, 10)]?.id
        ),
        setSelectedSettingsIndex(
          (parseInt(selectedSettingsIndex as string, 10) - 1).toString()
        ),
      ])
    }, [
      onDeleteSettings,
      selectedSettingsIndex,
      setSelectedSettingsIndex,
      settingsList,
    ])

    const handleClickEditSettings = useCallback(() => {
      setIsSettingsEdit(true)
    }, [])

    const handleClickBack = useCallback(() => {
      setIsSettingsEdit(false)
    }, [])

    const handleClickSelectSettings = useCallback(
      (settingsIndex: number) => {
        setSelectedSettingsIndex(settingsIndex.toString())
      },
      [setSelectedSettingsIndex]
    )

    const isSettingsDeletable = useMemo(() => {
      return parseInt(selectedSettingsIndex as string, 10) >= 2
    }, [selectedSettingsIndex])

    const selectedSettingsId = useMemo(() => {
      return settingsList[parseInt(selectedSettingsIndex as string, 10)]?.id
    }, [selectedSettingsIndex, settingsList])

    if (isSettingsEdit && selectedSettingsId) {
      return (
        <PaymentSettingsEdition
          bankType={bankType}
          settingsId={selectedSettingsId}
          onBack={handleClickBack}
        />
      )
    }

    if (isPaymentLocked) {
      return <PaymentSettingsLockPlaceholder />
    }

    // TODO: Для списка водителей срабатывает двойной запрос при выходе из
    // редактирования потому что используем контейнер. Вынести его в чистый
    // компонент который будет только рендерить сам список. Запрос поставить в
    // коннектор и вызывать его у родителя (PurePaymentSettingsTab).
    return (
      <>
        <Wrapper>
          <Col percentage={25}>
            <PaymentSettingsList
              selectedSettingsIndex={parseInt(
                selectedSettingsIndex as string,
                10
              )}
              settingsList={settingsList}
              onSelect={handleClickSelectSettings}
              onCreate={handleClickCreateSettings}
            />
          </Col>
          <VBox />
          <Col percentage={75}>
            {selectedSettingsId ? (
              <PaymentSettingsCard
                settingsId={selectedSettingsId}
                onEdit={handleClickEditSettings}
              />
            ) : (
              <PaymentSettingsIndividualCard />
            )}
            <HBox />
            <PaymentSettingsDriversList settingsId={selectedSettingsId} />
            <HBox />
            {isSettingsDeletable && (
              <PaymentSettingDeleteButtonWrapper>
                <PaymentSettingsDeleteAction
                  onDelete={handleClickDeleteSettings}
                />
              </PaymentSettingDeleteButtonWrapper>
            )}
          </Col>
        </Wrapper>
      </>
    )
  }
)

PurePaymentSettingsTab.displayName = 'PurePaymentsTab'
