import {
  EuiBadge,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButtonIcon,
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiFormControlLayout,
  EuiIcon,
  EuiPopover,
  EuiShowFor,
  EuiText,
  formatDate,
} from '@elastic/eui'
import { Criteria } from '@elastic/eui/src/components/basic_table/basic_table'
import React, { useState, useCallback } from 'react'
import numberToCurrencyString from '../../../../common/utils/numberToCurrencyString'
import { Filters } from './Filters'
import {
  Filter,
  FuelTransaction,
  FuelTransactionType,
  TableChangeEvent,
} from '../../types'
import BadgeWrapper from './atoms/BadgeWrapper'

interface Props {
  isRowsPerPageOptionsHidden?: boolean
  rowsPerPageOptions?: number[]
  defaultRowsPerPagePage?: number
  isLoading?: boolean
  itemsOnPage: FuelTransaction[]
  totalItems: number
  onChange: (event: TableChangeEvent) => void
}

const mapFilterToOption = (filter: Filter): EuiComboBoxOptionOption => ({
  label: filter.label,
  color: filter.color,
})

const toggleFilterByType = (filter: Filter, type: Filter['type']): Filter =>
  filter.type === type ? { ...filter, isEnabled: !filter.isEnabled } : filter

export const Table: React.FC<Props> = ({
  itemsOnPage,
  isRowsPerPageOptionsHidden,
  rowsPerPageOptions,
  defaultRowsPerPagePage,
  totalItems,
  isLoading,
  onChange,
}) => {
  const [isOpen, setOpened] = useState(false)
  const [pageIndex, setPageIndex] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(
    defaultRowsPerPagePage || rowsPerPageOptions?.[0] || 50
  )

  const [filters, setFilters] = useState<Filter[]>([
    {
      type: FuelTransactionType.Bonus,
      label: 'Бонус',
      isEnabled: true,
      color: '#79AAD9',
    },
    {
      type: FuelTransactionType.Deposit,
      label: 'Депозит',
      isEnabled: true,
      color: '#E0E5EE',
    },
  ])

  const handleFilterChange = useCallback(
    ({ type }: Filter) => {
      const newFilters = filters.map((filter) =>
        toggleFilterByType(filter, type)
      )

      setFilters(newFilters)
      onChange({
        rowsPerPage,
        pageIndex,
        filters: newFilters.map((filter) => ({
          isEnabled: filter.isEnabled,
          label: filter.label,
          type: filter.type,
        })),
      })
    },
    [filters, onChange, pageIndex, rowsPerPage]
  )

  const mappedOnTableChange = useCallback(
    (criteria: Criteria<FuelTransaction>) => {
      const { index, size } = criteria.page || {}

      if (index !== undefined && size !== undefined) {
        setPageIndex(index)
        setRowsPerPage(size)
        onChange({
          rowsPerPage: size,
          pageIndex: index,
          filters: filters.map((filter) => ({
            isEnabled: filter.isEnabled,
            label: filter.label,
            type: filter.type,
          })),
        })
      }
    },
    [filters, onChange]
  )

  const columns: EuiBasicTableColumn<FuelTransaction>[] = [
    {
      field: 'reason',
      name: 'Основание',
      mobileOptions: {
        only: true,
        enlarge: true,
        header: false,
      } as any,
    },
    {
      field: 'type',
      name: 'Тип',
      mobileOptions: {
        only: true,
        header: false,
        fullWidth: true,
      } as any,
      // eslint-disable-next-line react/display-name
      render: (type: FuelTransactionType) => {
        const filterOfType = filters.find((filter) => filter.type === type)
        return (
          <EuiBadge color={filterOfType?.color}>{filterOfType?.label}</EuiBadge>
        )
      },
    },
    {
      field: 'date',
      name: 'Дата и время',
      mobileOptions: {
        only: true,
        enlarge: true,
        render: (item: FuelTransaction) =>
          formatDate(item.date, 'DD MMMM YYYY HH:mm'),
      } as any,
    },
    {
      field: 'date',
      name: 'Дата',
      truncateText: true,
      width: '77px',
      // eslint-disable-next-line react/display-name
      render: (date: number) => formatDate(date, 'DD.MM.YY'),
      mobileOptions: {
        show: false,
      },
    },
    {
      field: 'date',
      name: 'Время',
      dataType: 'date',
      truncateText: true,
      width: '62px',
      // eslint-disable-next-line react/display-name
      render: (date: number) => formatDate(date, 'HH:mm'),
      mobileOptions: {
        show: false,
      },
    },
    {
      field: 'type',
      width: '87px',
      name: (
        <>
          Вид{' '}
          <EuiPopover
            ownFocus
            isOpen={isOpen}
            closePopover={() => setOpened(false)}
            button={
              <EuiButtonIcon
                iconType="filter"
                onClick={() => setOpened((prevState) => !prevState)}
              />
            }
          >
            <Filters filters={filters} onFilterChange={handleFilterChange} />
          </EuiPopover>
        </>
      ),
      truncateText: true,
      mobileOptions: {
        show: false,
      },
      // eslint-disable-next-line react/display-name
      render: (type: FuelTransactionType) => {
        const filterOfType = filters.find((filter) => filter.type === type)
        if (filterOfType) {
          return (
            <BadgeWrapper>
              <EuiBadge color={filterOfType.color}>
                {filterOfType.label}
              </EuiBadge>
            </BadgeWrapper>
          )
        }

        return null
      },
    },
    {
      field: 'reason',
      name: 'Основание',
      align: 'left',
      mobileOptions: {
        show: false,
      },
    },
    {
      field: 'amount',
      align: 'right',
      name: 'Сумма',
      // eslint-disable-next-line react/display-name
      render: (amount: number) => {
        return (
          <EuiText color={amount > 0 ? 'secondary' : undefined}>
            {amount > 0 ? '+' : null}
            {numberToCurrencyString(amount, { withCurrencySign: true })}
          </EuiText>
        )
      },
    },
  ]

  return (
    <>
      <EuiShowFor sizes={['xs', 's']}>
        <EuiFormControlLayout
          prepend={[<EuiIcon key="yeahboy" type="filter" />]}
          fullWidth
        >
          <EuiComboBox
            options={filters.map(mapFilterToOption)}
            isClearable={false}
            fullWidth
            onChange={(newOptions) => {
              const newFilters = filters.map((filter) => {
                return newOptions.find(
                  (option) => option.label === filter.label
                )
                  ? { ...filter, isEnabled: true }
                  : { ...filter, isEnabled: false }
              })

              setFilters(newFilters)
              onChange({
                rowsPerPage,
                pageIndex,
                filters: newFilters.map((filter) => ({
                  isEnabled: filter.isEnabled,
                  label: filter.label,
                  type: filter.type,
                })),
              })
            }}
            selectedOptions={filters
              .filter((filter) => filter.isEnabled)
              .map(mapFilterToOption)}
          />
        </EuiFormControlLayout>
      </EuiShowFor>
      <EuiBasicTable
        columns={columns}
        onChange={mappedOnTableChange}
        items={isLoading ? [] : itemsOnPage}
        loading={isLoading}
        noItemsMessage={isLoading ? 'Загрузка...' : 'Нет элементов для показа'}
        pagination={{
          pageIndex,
          pageSize: rowsPerPage,
          totalItemCount: totalItems,
          pageSizeOptions: rowsPerPageOptions,
          hidePerPageOptions: isRowsPerPageOptionsHidden,
        }}
      />
    </>
  )
}
