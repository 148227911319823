export enum ApiCode {
  TechnicalError = 'TechnicalError',
  OrganizationDetailsAreEmpty = 'OrganizationDetailsAreEmpty',
  ReferralOfferNotFound = 'ReferralOfferNotFound',
  OrganizationDetailsAlreadyFilled = 'OrganizationDetailsAlreadyFilled',
  ParkNotConfirmed = 'ParkNotConfirmed',
  InvalidParkData = 'InvalidParkData',
  ParkIsAlreadyRegister = 'ParkIsAlreadyRegister',
  ParkRegisterOrderIsAlreadyExist = 'ParkRegisterOrderIsAlreadyExist',
  Unauthorized = 'Unauthorized',
  CertificateDetailsNotFound = 'CertificateDetailsNotFound',
  UserNotFound = 'UserNotFound',
  Forbidden = 'Forbidden',
  EmployeeIsInactive = 'EmployeeIsInactive',
  RefreshTokenExpired = 'RefreshTokenExpired',
  AuthTokenExpired = 'AuthTokenExpired',
  InputValidationError = 'InputValidationError',
  ValidationError = 'ValidationError',
  EdgvrApiKeyIncorrect = 'EdgvrApiKeyIncorrect',
  FuelSummaryNotFound = 'FuelSummaryNotFound',

  QiwiCredentialsNotFound = 'QiwiCredentialsNotFound',
  QiwiCredentialsInvalid = 'QiwiCredentialsInvalid',
  QiwiServiceError = 'QiwiServiceError',
  QiwiCredentialsAlreadyExist = 'QiwiCredentialsAlreadyExist',
  CloudCashboxNotFound = 'CloudCashboxNotFound',
}
