import { ReferralInvite } from 'api/referral/invite/list/post/types'
import { ReferralOfferResponse } from 'api/referral/offer/types'
import { Aggregator } from 'api/types'
import { ProgramStates } from '../organisms/DescriptionAndActionsPanel/types'
import { Application } from '../organisms/NewApplications/types'
import { AggregatorAccount } from '../organisms/TermsEdit/types'
import { ProgramSettings, ProgramTerms } from '../types'
import mapAggregatorToAggregatorAccount from './mappers/mapAggregatorToAggregatorAccount'
import mapInviterRewardPeriodTypeToInviterBonusFrequency from './mappers/mapInviterRewardPeriodTypeToInviterBonusFrequency'
import mapInviterRewardTypeToInviterBonusType from './mappers/mapInviterRewardTypeToInviterBonusType'
import mapReferralInvitesToApplication from './mappers/mapReferralInvitesToApplication'
import mapVisibleToProgramState from './reverseMappers/mapVisibleToProgramState'

interface Input {
  referralInvites: ReferralInvite[]
  referralOffer: ReferralOfferResponse
  aggregators: Aggregator[]
}

interface Output {
  applications: Application[]
  programSettings: ProgramSettings
  programTerms: ProgramTerms
  programState: ProgramStates
  aggregatorAccounts: AggregatorAccount[]
  telegramChatId: string | undefined
}

export default ({
  referralInvites,
  aggregators,
  referralOffer,
}: Partial<Input>): Partial<Output> => ({
  applications: referralInvites
    ? referralInvites.map(mapReferralInvitesToApplication)
    : undefined,
  programSettings:
    referralOffer && aggregators
      ? {
          selectedAggregatorAccounts:
            aggregators
              .filter((aggregator) =>
                referralOffer.aggregators?.includes(aggregator.id)
              )
              .map((aggregator) => ({
                name: aggregator.account,
                id: aggregator.id,
              })) || [],
          inviterBonusType: mapInviterRewardTypeToInviterBonusType(
            referralOffer.inviter_reward_type
          ),
          bonusAmount:
            referralOffer && referralOffer.inviter_reward_value / 100,
          numberOfUnpaidDrives: referralOffer.invited_ride_offset,
          inviterBonusFrequency: mapInviterRewardPeriodTypeToInviterBonusFrequency(
            referralOffer.inviter_reward_period_type || 'weekly'
          ),
          minimumAmountOfDrivesForInvitee: referralOffer.invited_ride_count,
          periodForMinimumAmountOfDrivesForInvitee:
            referralOffer.invited_ride_period,
          paymentDurationForInviter: referralOffer.inviter_reward_period_limit,
          dontPayIfInviterIsNotTakingDrives: !!(
            referralOffer.inviter_ride_count ||
            referralOffer.inviter_ride_period
          ),
          customTitle: referralOffer.custom_title,
          customShortTerms: referralOffer.custom_short_description,
          customFullTerms: referralOffer.custom_full_description,
          id: referralOffer.id,
        }
      : undefined,
  programState: mapVisibleToProgramState(referralOffer?.visible),
  programTerms: referralOffer
    ? {
        title: referralOffer.title,
        shortDescription: referralOffer.short_description,
        fullDescription: referralOffer.full_description,
      }
    : undefined,
  aggregatorAccounts: aggregators
    ? aggregators.map(mapAggregatorToAggregatorAccount)
    : undefined,
  telegramChatId: referralOffer?.telegram_chat_id || undefined,
})
