import React from 'react'
import Card from 'ui-kit/atoms/Card/Card'
import styled from 'styled-components'
import InlineRadio, {
  InlineRadioOption,
} from 'ui-kit/components/InlineRadio/InlineRadio'
import { Icon as DefaultIcon, Pagination, Limitation } from 'ui-kit/components'
import Table from 'ui-kit/atoms/Table/Table'
import { currencyToBase } from 'common/utils/formatters'
import { formatToRegularDate, toTime } from 'common/utils/dates'
import { EmptyData } from 'ui-kit/atoms/EmptyData/EmptyData'
import RetryRequest from 'ui-kit/atoms/RetryRequest'
import { Loader } from 'ui-kit/atoms/loader'
import { Link } from 'ui-kit/atoms/Link/Link'
import { PaginationLimit } from 'ui-kit/components/Limitation/Limitation'
import Typography from 'ui-kit/typography'

export type FuelAmount = {
  value: number
  isPositive?: boolean
}

export enum FuelDepositType {
  Fuel,
  CashBack,
}

export type FuelHistory = {
  id: string
  dateCreation: string
  description: string
  amount: FuelAmount
  depositType: FuelDepositType
}

export type TableData<T> = {
  list: T[]
  total: number
  limit: PaginationLimit
  offset: number
}

type Props = {
  data: TableData<FuelHistory>
  fetcher: (
    limit: PaginationLimit,
    depositType?: FuelDepositType,
    offset?: number
  ) => Promise<void>
  onGoToPayments: () => void
  onFetchStart?: () => void
}

const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 16px;
`

const Footer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.pallete.secondaryLightBlue};
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
`

const LastCell = styled(Table.Cell)`
  padding-right: calc(24px + 16px) !important;
`

const Icon = styled(DefaultIcon)`
  margin-left: 4px;
`

const FuelAmountWrapper = styled.div<{ isPositive?: boolean }>`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  margin-right: -24px;
`

const options: InlineRadioOption<FuelDepositType | undefined>[] = [
  { label: 'Все операции', value: undefined },
  {
    label: 'только по депозиту',
    value: FuelDepositType.Fuel,
    iconName: 'drop',
  },
  {
    label: 'только по бонусному счету',
    value: FuelDepositType.CashBack,
    iconName: 'bonus',
  },
]

type FuelAmountProps = {
  type: FuelDepositType
  amount: number
  isPositive?: boolean
}
const FuelAmount = ({ amount, isPositive, type }: FuelAmountProps) => {
  return (
    <FuelAmountWrapper>
      <Typography
        fontType="Table"
        fontSize={14}
        fontColor={isPositive ? 'uiGreen' : 'uiRed'}
      >
        {isPositive ? '+' : '−'}
        {currencyToBase(amount)}
      </Typography>
      {type === FuelDepositType.Fuel && <Icon name="drop" />}
      {type === FuelDepositType.CashBack && <Icon name="bonus" />}
    </FuelAmountWrapper>
  )
}

type TransactionCreated = {
  date: string
}

const TransactionCreatedWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const TransactionCreated = ({ date }: TransactionCreated) => {
  return (
    <TransactionCreatedWrapper>
      <Typography fontSize={14} fontType="Table">
        {formatToRegularDate(date)}
      </Typography>{' '}
      <Typography fontSize={14} fontType="Table" fontColor="textSecondary">
        {toTime(date)}
      </Typography>
    </TransactionCreatedWrapper>
  )
}

const TableWrapper = styled.div`
  min-height: 760px;
  display: flex;
  flex-direction: column;
`

const LoaderWrapper = styled.div`
  padding-top: 136px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const EmptyTextHelp = styled.span`
  display: inline-block;
  max-width: 400px;
`

function getEmptyTextType(depositType?: FuelDepositType) {
  switch (depositType) {
    case FuelDepositType.Fuel:
      return 'топливному депозиту'
    case FuelDepositType.CashBack:
      return 'бонусному счету'
    default:
      return 'топливному депозиту и бонусному счету'
  }
}

const LinkButton = Link.withComponent('button')

export const FuelTransactions = React.forwardRef<HTMLDivElement, Props>(
  ({ data, fetcher, onFetchStart, onGoToPayments }, ref) => {
    const { list, total, offset, limit } = data

    const [isFetchFail, setIsFetchFail] = React.useState(false)
    const [isFetching, setIsFetching] = React.useState(false)
    const requestParams = React.useRef<{
      limit: PaginationLimit
      offset?: number
      depositType?: FuelDepositType
    }>({
      limit,
      offset,
    })

    const [currentPage, setCurrentPage] = React.useState(offset / limit)

    const handleFetch = async () => {
      if (onFetchStart) {
        onFetchStart()
      }
      setIsFetching(true)
      try {
        await fetcher(
          requestParams.current.limit,
          requestParams.current.depositType,
          requestParams.current.offset
        )
        setIsFetching(false)
        setIsFetchFail(false)
      } catch (e) {
        setIsFetchFail(true)
        setIsFetching(false)
        throw e
      }
    }

    const handleChangeType = async (type?: FuelDepositType) => {
      requestParams.current = {
        ...requestParams.current,
        depositType: type,
        offset: 0,
      }
      setCurrentPage(0)
      setIsFetchFail(false)
      try {
        await handleFetch()
      } catch (e) {
        console.error(e)
      }
    }

    const handleSuccessRetry = () => {
      setIsFetchFail(false)
    }

    const handleChangePagination = (newOffset: number) => async () => {
      requestParams.current = {
        ...requestParams.current,
        offset: requestParams.current.limit * (currentPage + newOffset),
      }
      setCurrentPage((prevValue) => prevValue + newOffset)
      setIsFetchFail(false)
      try {
        await handleFetch()
      } catch (e) {
        console.error(e)
      }
    }

    const handleChangeLimit = async (newLimit: PaginationLimit) => {
      requestParams.current = {
        ...requestParams.current,
        limit: newLimit,
        offset: 0,
      }
      setIsFetchFail(false)
      setCurrentPage(0)
      try {
        await handleFetch()
      } catch (e) {
        console.error(e)
      }
    }

    return (
      <Card ref={ref}>
        <Header>
          <InlineRadio
            options={options}
            onChange={handleChangeType}
            isDisabled={isFetching}
          />
        </Header>
        <TableWrapper>
          <Table>
            <Table.Head>
              <Table.Row minHeight={35}>
                <Table.Cell width={135}>
                  <Typography fontSize={14} fontType="Table" fontWeight="Bold">
                    Дата и время
                  </Typography>
                </Table.Cell>
                <Table.Cell>
                  <Typography fontSize={14} fontType="Table" fontWeight="Bold">
                    Основание
                  </Typography>
                </Table.Cell>
                <LastCell align="right">
                  <Typography fontSize={14} fontType="Table" fontWeight="Bold">
                    Сумма
                  </Typography>
                </LastCell>
              </Table.Row>
            </Table.Head>
            {list.length > 0 && !isFetchFail && !isFetching && (
              <Table.Body>
                {list.map((item) => (
                  <Table.Row key={item.id} minHeight={56}>
                    <Table.Cell width={135}>
                      <TransactionCreated date={item.dateCreation} />
                    </Table.Cell>
                    <Table.Cell>
                      <Typography fontSize={14} fontType="Table">
                        {item.description}
                      </Typography>
                    </Table.Cell>
                    <LastCell align="right">
                      <FuelAmount
                        amount={item.amount.value}
                        type={item.depositType}
                        isPositive={item.amount.isPositive}
                      />
                    </LastCell>
                  </Table.Row>
                ))}
              </Table.Body>
            )}
          </Table>
          {list.length === 0 && !isFetchFail && !isFetching && (
            <EmptyData placeholderName="wallet">
              <EmptyTextHelp>
                Здесь будут завершенные операции по{' '}
                {getEmptyTextType(requestParams.current.depositType)}. Операции,
                требующие подтверждения, по-прежнему будут отображаться{' '}
                <LinkButton onClick={onGoToPayments}>в платежах.</LinkButton>
              </EmptyTextHelp>
            </EmptyData>
          )}
          {isFetching && !isFetchFail && (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          )}
          {isFetchFail && (
            <RetryRequest onRetry={handleFetch} onSuccess={handleSuccessRetry}>
              Не удается загрузить список операций
            </RetryRequest>
          )}
        </TableWrapper>
        {total > 0 && (
          <Footer>
            <Pagination
              isLocked={isFetching}
              onClickNext={handleChangePagination(+1)}
              totalPages={Math.ceil(total / limit)}
              currentPage={currentPage + 1}
              onClickPrev={handleChangePagination(-1)}
            />
            <Limitation
              isLocked={isFetching}
              currentLimit={requestParams.current.limit}
              limitAction={handleChangeLimit}
            />
          </Footer>
        )}
      </Card>
    )
  }
)
FuelTransactions.displayName = 'FuelTransactions'
export default React.memo(FuelTransactions)
