interface Config {
  withCurrencySign?: boolean
}

export default (amount: number, { withCurrencySign }: Config = {}) => {
  const minusOrEmptyString = amount < 0 ? '−' : ''

  // I'm not using built-in `currency: "RUR"` & `currencyDisplay: "sign"` properties because
  // it doesn't render an actual ruble sign, instead in renders just "руб." string for our currency.
  // That's is kind of fucked up since the ruble sign (₽) established on international level AFAIK.
  const prettifiedAbsoluteNumber = Math.abs(amount).toLocaleString('ru', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  const prettifiedNumber = `${minusOrEmptyString}${prettifiedAbsoluteNumber}`

  if (withCurrencySign) {
    return `${prettifiedNumber} ₽`
  }

  return prettifiedNumber
}
