import React from 'react'
import { Switch } from 'react-router-dom'

import { RoutesPath } from 'common/enums'
import { PrivateRoute } from 'shared/router'
import { HBox } from 'shared/ui'
import { EmployeesMainContainer } from 'employees/features/employeesMain'
import { EmployeeDetailsContainer } from 'employees/features/employeeDetails'

export const Employees: React.FC = () => (
  <>
    <HBox />
    <Switch>
      <PrivateRoute
        exact
        path={RoutesPath.Employees}
        component={EmployeesMainContainer}
      />
      <PrivateRoute
        exact
        path={RoutesPath.EmployeeDetails}
        component={EmployeeDetailsContainer}
      />
    </Switch>
  </>
)
