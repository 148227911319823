import React from 'react'
import { EuiButtonEmpty } from '@elastic/eui'

type Props = {
  children: string
  onClick: () => void
}

export const FieldAdder: React.FC<Props> = ({ children, onClick }) => (
  <div>
    <EuiButtonEmpty iconType="plusInCircle" onClick={onClick}>
      {children}
    </EuiButtonEmpty>
  </div>
)
