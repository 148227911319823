import React from 'react'
import {
  EuiIcon,
  EuiLink,
  EuiLoadingContent,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import EnhancedLoadingContent from 'elastic-ui/shared/atoms/EnhancedLoadingContent/EnhancedLoadingContent'
import Flexbox from 'elastic-ui/shared/atoms/Flexbox'
import HorizontalSpacer from 'elastic-ui/shared/atoms/HorizontalSpacer'
import { LimitContainer } from 'elastic-ui/shared/atoms/LimitContainer'
import RenderGate from 'elastic-ui/shared/atoms/RenderGate'

import PreviewCard from './atoms/PreviewCard'
import { LIMIT_CONTAINER_MAX_WIDTH } from './constants'

interface Props {
  onShowTermsToDriversClick: () => void
  isProgramHidden: boolean
  company: string
  isLoading: boolean
  previewTitle: string
  previewDescription: string
}

const contentLimit = 170
const TermsPreviewForDriver: React.FC<Props> = ({
  isProgramHidden,
  company,
  isLoading,
  previewTitle,
  previewDescription,
  onShowTermsToDriversClick,
}) => (
  <div>
    <PreviewCard>
      {isLoading ? (
        <>
          <LimitContainer maxWidth={contentLimit}>
            <EuiLoadingContent lines={3} />
          </LimitContainer>
          <LimitContainer maxWidth={contentLimit}>
            <EuiLoadingContent lines={1} />
          </LimitContainer>
        </>
      ) : (
        <>
          <LimitContainer maxWidth={contentLimit}>
            <EuiTitle size="xs">
              <h2>{previewTitle}</h2>
            </EuiTitle>
            <EuiText>{previewDescription}</EuiText>
          </LimitContainer>
          <LimitContainer maxWidth={contentLimit}>
            <EuiText size="s">{company}</EuiText>
          </LimitContainer>
        </>
      )}
    </PreviewCard>
    <EuiSpacer size="s" />
    <LimitContainer maxWidth={LIMIT_CONTAINER_MAX_WIDTH}>
      {isLoading ? (
        <EnhancedLoadingContent lines={2} size="xs" />
      ) : (
        <>
          <RenderGate isOpen={isProgramHidden}>
            <Flexbox>
              <EuiIcon type="eyeClosed" />
              <HorizontalSpacer size="xs" />
              <EuiText size="xs">
                Баннер скрыт от водителей.{' '}
                <EuiLink onClick={onShowTermsToDriversClick}>
                  Показывать
                </EuiLink>
              </EuiText>
            </Flexbox>
          </RenderGate>
          <RenderGate isOpen={!isProgramHidden}>
            <EuiText size="xs">
              Так выглядит баннер в мобильном приложении
            </EuiText>
          </RenderGate>
        </>
      )}
    </LimitContainer>
  </div>
)

export default TermsPreviewForDriver
