import { handleActions } from 'redux-actions'
import { AggregatorType } from 'common/enums'

import {
  Payloads,
  RESET,
  SET_CURRENT,
  SET_TYPE,
} from 'settings/features/aggregatorsList/actions'

export interface AggregatorsListState {
  current: string | null
  type: AggregatorType | null
}

const initialState: AggregatorsListState = {
  current: null,
  type: null,
}

const handleSetCurrent = (
  state: AggregatorsListState,
  action: SET_CURRENT
): AggregatorsListState => ({
  ...state,
  current: action.payload,
})

const handleSetType = (
  state: AggregatorsListState,
  action: SET_TYPE
): AggregatorsListState => ({
  ...state,
  type: action.payload,
})

const handleReset = (): AggregatorsListState => initialState

export default handleActions<AggregatorsListState, Payloads>(
  {
    [SET_CURRENT]: handleSetCurrent,
    [SET_TYPE]: handleSetType,
    [RESET]: handleReset,
  },
  initialState
)
