import React from 'react'

interface Props {
  isOpen: boolean
  children: React.ReactNode
}

const RenderGate: React.FC<Props> = ({ isOpen, children }) =>
  isOpen ? <>{children}</> : null

export default RenderGate
