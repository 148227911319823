import React from 'react'
import { Field } from 'redux-form'

import { Icon } from 'ui-kit/components'
import { phoneMask, phonePattern } from 'common/utils/patterns'
import {
  comparePasswords,
  maxLength30,
  minLength6,
  required,
} from 'common/utils/fieldValidation'
import ListInput from 'common/components/formItems/ListInput'
import InputMask from 'common/components/formItems/InputMask'
import { HBox } from 'shared/ui'
import styled from 'styled-components'

const Container = styled.div`
  padding: 16px;
`

type Props = {
  showPassword: boolean
  toggleShowPassword: () => void
}

export const ProfileInfoForm: React.FC<Props> = ({
  showPassword,
  toggleShowPassword,
}) => (
  <Container>
    <Field
      name="full_name"
      label="ФИО"
      iconName="close"
      validate={[required]}
      component={ListInput}
    />
    <HBox x={0.5} />
    <Field
      name="phone"
      label="Телефон"
      iconName="close"
      validate={[required]}
      mask={phoneMask}
      pattern={phonePattern}
      component={InputMask}
    />

    <HBox />
    <Icon name="lock" color="blue" pointer onClick={toggleShowPassword}>
      Изменить пароль
    </Icon>

    {showPassword ? (
      <>
        <HBox x={0.5} />
        <Field
          name="oldPassword"
          type="password"
          label="Текущий пароль"
          validate={[minLength6, maxLength30, required]}
          component={ListInput}
        />
        <HBox x={0.5} />
        <Field
          name="newPassword"
          type="password"
          label="Новый пароль"
          validate={[minLength6, maxLength30, required]}
          component={ListInput}
        />
        <HBox x={0.5} />
        <Field
          name="confirmNewPassword"
          type="password"
          label="Подтвердите пароль"
          validate={[comparePasswords, required]}
          component={ListInput}
        />
      </>
    ) : null}
  </Container>
)
