import React from 'react'
import styled, { css } from 'styled-components'

import { ColorTypes, IconNames } from 'ui-kit'
import * as icons from 'ui-kit/static/icons'

import { BodyText, TableText } from 'ui-kit/components/Typography'

const Wrapper = styled.div<{
  pointer?: boolean
  reverse?: boolean
  color?: ColorTypes
}>`
  position: relative;
  display: inline-flex;
  flex-direction: ${({ reverse }) => reverse && 'row-reverse'};
  align-items: center;
  cursor: ${({ pointer }) => pointer && 'pointer'};
`

// Needs for legacy code.
// If background color is specified (but width/height not), width/height must be 32 pixels
const getWidthOrHeight = (widthOrHeight?: number, backgroundColor?: string) => {
  if (widthOrHeight !== undefined) return `${widthOrHeight}px`
  if (backgroundColor !== undefined) return '32px'
  return null
}

const IconContainer = styled.div<{
  color?: ColorTypes
  backgroundColor?: ColorTypes
  width?: number
  height?: number
  marginLeft?: number
  marginRight?: number
}>`
  display: flex;
  width: ${({ width, backgroundColor }) =>
    getWidthOrHeight(width, backgroundColor)};
  height: ${({ height, backgroundColor }) =>
    getWidthOrHeight(height, backgroundColor)};
  margin-left: ${({ marginLeft = 0 }) => marginLeft}px;
  margin-right: ${({ marginRight = 0 }) => marginRight}px;
  justify-content: center;
  align-items: center;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor && theme.pallete[backgroundColor]};
  border-radius: ${({ backgroundColor }) => backgroundColor && '50%'};

  svg {
    display: block;

    ${({ color }) =>
      color &&
      css`
        [fill] {
          fill: ${({ theme }) => theme.pallete[color] || color};
        }
        [stroke] {
          stroke: ${({ theme }) => theme.pallete[color] || color};
        }
      `}
  }
`

const VSpace = styled.div`
  width: 16px;
`

interface Props {
  /** Icon name to svg file */
  name: IconNames
  id?: string
  className?: string
  color?: ColorTypes
  backgroundColor?: ColorTypes
  reverse?: boolean
  /** Changes children text style */
  table?: boolean
  pointer?: boolean
  width?: number
  height?: number
  marginLeft?: number
  marginRight?: number
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  onClickCapture?: (e: React.MouseEvent<HTMLElement>) => void
}

export const Icon: React.FC<Props> = ({
  children,
  name,
  id,
  className,
  color,
  backgroundColor,
  table,
  pointer,
  reverse,
  width,
  height,
  marginLeft,
  marginRight,
  onClick,
  onClickCapture,
}) => {
  const IconComponent = icons[name]
  return (
    <Wrapper
      id={id}
      className={className}
      color={color}
      pointer={pointer}
      reverse={reverse}
      onClick={onClick}
      onClickCapture={onClickCapture}
    >
      <IconContainer
        color={color}
        backgroundColor={backgroundColor}
        width={width}
        height={height}
        marginLeft={marginLeft}
        marginRight={marginRight}
      >
        {IconComponent ? <IconComponent data-testid={`icon-${name}`} /> : null}
      </IconContainer>
      {children ? <VSpace /> : null}
      {children &&
        (table ? (
          <TableText color={color}>{children}</TableText>
        ) : (
          <BodyText color={color}>{children}</BodyText>
        ))}
    </Wrapper>
  )
}
