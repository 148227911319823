import { EuiSpacer } from '@elastic/eui'
import React from 'react'
import HorizontalSpacer from 'elastic-ui/shared/atoms/HorizontalSpacer'
import StretchContainer from 'elastic-ui/shared/atoms/StretchContainer'
import {
  ProgramSettings,
  ProgramTerms,
  ReportPeriod,
} from 'elastic-ui/referral-program/types'
import { AggregatorAccount } from 'elastic-ui/referral-program/organisms/TermsEdit/types'
import DescriptionAndActionsPanel from '../../organisms/DescriptionAndActionsPanel/DescriptionAndActionsPanel'
import DownloadReport from '../../organisms/DownloadReport/DownloadReport'
import Terms from '../../organisms/Terms/Terms'
import NewApplications from '../../organisms/NewApplications/NewApplications'
import { ProgramStates } from '../../organisms/DescriptionAndActionsPanel/types'
import TelegramNotifications from '../../organisms/TelegramNotifications/TelegramNotifications'
import Flexbox from '../../../shared/atoms/Flexbox'
import { Application } from '../../organisms/NewApplications/types'

interface Props {
  aggregatorAccounts: AggregatorAccount[]
  programState: ProgramStates
  programSettings: ProgramSettings
  programTerms: ProgramTerms
  applications: Application[]
  company: string
  isProgramStatusLoading: boolean
  isTermsLoading: boolean
  isFirstTime: boolean
  isApplicationsTableLoading: boolean
  isDownloadReportLoading: boolean
  isTelegramNotificationsLoading: boolean
  isTelegramNotificationsConnected: boolean
  isEditingTerms: boolean
  onSubscribeToTelegramNotificationsClick: (key: string) => void
  onCancelTelegramSubscription: () => void
  onDownloadReportClick: (period: ReportPeriod) => void
  onApplicationDeleteClick: (id: string) => void
  onCancelEditingTermsClick: () => void
  onEditTermsClick: () => void
  onUpdateTermsClick: (settings: ProgramSettings) => void
  onHideFromDriversClick: () => void
  onShowToDriversClick: () => void
  onStopProgramClick: (id: number) => void
  onStartProgramClick: () => void
}

export const ReferralProgram: React.FC<Props> = ({
  aggregatorAccounts,
  programState,
  programSettings,
  programTerms,
  applications,
  company,
  isProgramStatusLoading,
  isTermsLoading,
  isFirstTime,
  isApplicationsTableLoading,
  isDownloadReportLoading,
  isTelegramNotificationsLoading,
  isTelegramNotificationsConnected,
  isEditingTerms,
  onSubscribeToTelegramNotificationsClick,
  onCancelTelegramSubscription,
  onDownloadReportClick,
  onApplicationDeleteClick,
  onCancelEditingTermsClick,
  onEditTermsClick,
  onUpdateTermsClick,
  onHideFromDriversClick,
  onShowToDriversClick,
  onStopProgramClick,
  onStartProgramClick,
}) => (
  <StretchContainer>
    <DescriptionAndActionsPanel
      programSettings={programSettings}
      state={programState}
      isLoading={isProgramStatusLoading}
      onHideFromDriversClick={onHideFromDriversClick}
      onShowToDriversClick={onShowToDriversClick}
      onStopReferralProgramClick={onStopProgramClick}
      onStartReferralProgramClick={onStartProgramClick}
      isFirstTime={isFirstTime}
    />
    <EuiSpacer size="m" />
    <Terms
      aggregatorAccounts={aggregatorAccounts}
      programSettings={programSettings}
      programTerms={programTerms}
      company={company}
      isLoading={isTermsLoading}
      isEditing={isEditingTerms}
      isFirstTime={isFirstTime}
      isProgramHidden={programState === ProgramStates.Hidden}
      onCancelEditingClick={onCancelEditingTermsClick}
      onEditClick={onEditTermsClick}
      onUpdateClick={onUpdateTermsClick}
      onShowTermsToDriversClick={onShowToDriversClick}
      isTelegramNotificationsConnected={isTelegramNotificationsConnected}
      isTelegramNotificationsLoading={isTelegramNotificationsLoading}
      onCancelTelegramSubscription={onCancelTelegramSubscription}
      onSubscribeToTelegramNotificationsClick={
        onSubscribeToTelegramNotificationsClick
      }
    />
    <EuiSpacer size="m" />
    <NewApplications
      isLoading={isApplicationsTableLoading}
      items={applications}
      onItemDeleteClick={onApplicationDeleteClick}
    />
    <EuiSpacer size="m" />
    <Flexbox alignItems="flex-start">
      <DownloadReport
        isLoading={isDownloadReportLoading}
        onDownloadClick={onDownloadReportClick}
      />
    </Flexbox>
    <EuiSpacer size="m" />
  </StretchContainer>
)
