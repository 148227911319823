import { connect } from 'react-redux'

import { State } from 'reducers'
import { ModalsNames } from 'common/enums'
import { apiSelectors } from 'api'
import { MyThunkDispatch } from 'api/reducer'
import { modalsActions } from 'shared/modals'
import { onDeleteDriverContract } from 'drivers/features/driverInfo/thunks'
import { DeleteDriverContractModal } from 'drivers/features/driverInfo/pages'

const mapStateToProps = (state: State) => ({
  isFetching: apiSelectors.driversById.contractDelete.selectIsFetching(state),
  driverId: apiSelectors.driversById.get.getId(state),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  closeModal: () =>
    dispatch(modalsActions.closeModal(ModalsNames.DeleteDriverContract)),
  fetchDelete: ({ driverId }: { driverId: string }) =>
    dispatch(onDeleteDriverContract({ driverId })),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const DeleteDriverContractModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteDriverContractModal)
