import { BankType } from 'common/enums'

export type TStep = {
  number: number
  label: string
  tooltip: string
}

export type TStepsState = {
  current: TStep
  channelType: BankType | null
}

export enum StepsActionType {
  SET_CURRENT_STEP = 'SET_CURRENT_STEP',
  SET_CHANNEL_TYPE = 'SET_CHANNEL_TYPE',
}

export type TStepsAction = {
  type: StepsActionType.SET_CURRENT_STEP | StepsActionType.SET_CHANNEL_TYPE
  payload: {
    current?: TStep
    channelType?: BankType
  }
}
