import styled from 'styled-components'
import type { ColorTypes } from 'ui-kit'
import type { Variant, Weight, FontType, Size } from './core'
import {
  makeColorStyle,
  makeVariantStyle,
  makeWeightStyle,
  makeFontStyle,
  makeFontSize,
} from './core'

export type Props = {
  fontType?: FontType
  fontSize?: Size
  fontWeight?: Weight
  fontColor?: ColorTypes

  variant?: Variant
}

export const Typography = styled.span<Props>`
  padding: 0;
  margin: 0;

  ${({ fontType }) => makeFontStyle(fontType)}
  ${({ fontColor }) => makeColorStyle(fontColor)};

  ${({ variant, fontSize }) => !variant && makeFontSize(fontSize)};
  ${({ variant, fontWeight }) => !variant && makeWeightStyle(fontWeight)};
  ${({ variant }) => variant && makeVariantStyle(variant)};
`

export default Typography
