import React from 'react'

import { Icon } from 'ui-kit/components'
import { Wrapper, Button, Input } from 'ui-kit/components/Counter/styles'

interface Props {
  id?: string
  className?: string
  value?: number
  onChange?: (value: number) => void
}

interface LocalState {
  value: number
}

export class Counter extends React.Component<Props, LocalState> {
  private maxValue = 99

  private minValue = 1

  constructor(props: Props) {
    super(props)

    this.state = {
      value: props.value ? props.value : 0,
    }
  }

  private changeQty = (
    e?: React.FormEvent<HTMLInputElement>,
    value?: number
  ): void => {
    const { onChange } = this.props

    if (e) {
      const curValue = Number(e.currentTarget.value.replace(/[^0-9]/, ''))

      if (curValue <= this.maxValue && curValue >= 0) {
        this.setState({ value: Number(curValue) })

        if (onChange) {
          onChange(Number(curValue))
        }
      }
    } else if (value !== undefined && value <= this.maxValue && value >= 0) {
      this.setState({ value })

      if (onChange) {
        onChange(value)
      }
    }
  }

  private increment = (): void => {
    const { value } = this.state

    setTimeout(() => this.changeQty(undefined, value + 1), 0)
  }

  private decrement = (): void => {
    const { value } = this.state

    setTimeout(() => this.changeQty(undefined, value - 1), 0)
  }

  render() {
    const { id } = this.props
    const { value } = this.state

    return (
      <Wrapper id={id} className="muk-counter-wrapper">
        <Button
          disabled={value < this.minValue}
          className="muk-counter-button"
          onClick={this.decrement}
        >
          <Icon className="muk-counter-icon" name="remove" />
        </Button>
        <Input
          className="muk-counter-input"
          type="text"
          value={value}
          onChange={this.changeQty}
        />
        <Button
          disabled={value >= this.maxValue}
          className="muk-counter-button"
          onClick={this.increment}
        >
          <Icon className="muk-counter-icon" name="add" />
        </Button>
      </Wrapper>
    )
  }
}
