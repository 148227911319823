import React from 'react'
import styled from 'styled-components'
import Card from 'ui-kit/atoms/Card/Card'
import { ColorButton, Icon } from 'ui-kit/components'
import { Currency } from 'ui-kit/components/Currency/Currency'
import Typography from 'ui-kit/typography'

type Props = {
  currency?: number
  onPay: () => void
}

const Wrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Header = styled(Typography).attrs({ variant: 'Caption1' })`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const Footer = styled.div`
  margin-top: 16px;
`
const HeaderIcon = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`

export const FuelDeposit = ({ currency, onPay }: Props) => {
  const normalColor = currency === undefined ? 'textSecondary' : 'textPrimary'
  const color = currency && currency <= 0 ? 'uiRed' : normalColor

  return (
    <Card>
      <Wrapper>
        <Header>
          <HeaderIcon>
            <Icon name="drop" />
          </HeaderIcon>
          Топливный депозит
        </Header>
        <Currency
          color={color}
          regularFontWidth="bold"
          regularFontSize={32}
          decimalFontSize={24}
          decimalFontWidth="bolder"
          value={currency}
          explicitNegativeSign
        />
        <Footer>
          <ColorButton onClick={onPay}>Пополнить</ColorButton>
        </Footer>
      </Wrapper>
    </Card>
  )
}
export default React.memo(FuelDeposit)
