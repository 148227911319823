import { ThunkAction } from 'redux-thunk'
import { State } from 'reducers'
import axios from 'axios'
import { FailureError } from 'common/utils/reduxApiFactory'
import { TApiError } from 'api/types'
import { patchReferralOffersActions as actions } from './actions'
import { PatchReferralOffersActions } from './types'
import {
  ReferralOffer,
  ReferralOfferResponse,
  ReferralOfferResponseSchema,
} from '../types'

export type HandleRequest = ThunkAction<
  Promise<ReferralOfferResponse>,
  State,
  undefined,
  PatchReferralOffersActions
>

export const patchReferralOffersRequest = (
  requestBody: ReferralOffer
): HandleRequest => async (dispatch) => {
  dispatch(actions.start())
  try {
    const { data } = await axios.patch<ReferralOfferResponse>(
      'erp/1/referral/offer',
      requestBody
    )
    dispatch(actions.success())
    return data
  } catch (e) {
    if (e.response) {
      const data = e.response.data as TApiError
      const error: FailureError = new Error(data.message)
      error.data = data
      dispatch(actions.failure(error))
    }

    throw e
  }
}
