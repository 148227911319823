import React from 'react'

import { LargeFilter as LargeFilterType } from 'common/types/local/filter'

import {
  Actions,
  FilterBlock,
  LargeItem,
  LargeWrapper,
  ListItemsWrapper,
} from 'shared/table/components/LargeFilter/styles'

import { BodyText, Checkbox, Icon, ListItem } from 'ui-kit/components'

interface Props {
  filters: LargeFilterType[]
  initialValues?: string[]
  hideTitle?: boolean
  column?: boolean
  filterBlockWidth?: number
  onApply?: (list: string[]) => void
  onClear?: () => void
  disabled?: boolean
}

export const LargeFilter: React.FC<Props> = (props) => {
  const {
    filters,
    onApply,
    onClear,
    initialValues,
    filterBlockWidth,
    hideTitle,
    column,
    disabled,
  } = props

  const [isOpen, setIsOpen] = React.useState(false)
  const [list, setList] = React.useState(initialValues || [])
  let currentCategory: LargeFilterType
  const filter: Set<string> = new Set(list)

  const isFirstRender = React.useRef(true)
  React.useEffect(() => {
    if (!isFirstRender.current && !isOpen) {
      if (initialValues) {
        setList(initialValues)
      }
    }
    isFirstRender.current = false
  }, [initialValues, isOpen])

  const onOpenFilter = (): void => setIsOpen(!isOpen)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const mappedFilter: string[] = []

    if (e.currentTarget.checked) {
      filter.add(e.currentTarget.name)
    } else {
      filter.delete(e.currentTarget.name)
    }

    filter.forEach((value) => {
      mappedFilter.push(value)
    })

    setList(mappedFilter)
  }

  const handleApply = (): void => {
    setIsOpen(false)

    if (onApply) {
      onApply(list)
    }
  }

  const handleClear = (): void => {
    setIsOpen(false)
    setList([])

    if (onClear) {
      onClear()
    }
  }

  const renderList = (category: string) =>
    filters &&
    filters.map(
      (item) =>
        item.category === category && (
          <ListItem key={item.name} className="list-item">
            <Checkbox
              className="filter-box"
              name={item.name}
              onChange={handleChange}
              checked={Boolean(
                list.find((listItem) => listItem === item.name) || false
              )}
            >
              {item.label}
            </Checkbox>
          </ListItem>
        )
    )

  const renderCategories = () =>
    filters?.map((item) => {
      if (
        currentCategory === null ||
        currentCategory?.category !== item.category
      ) {
        currentCategory = item

        return (
          <LargeItem key={item.label}>
            <ListItem>
              <BodyText fontWeight="semi-bold">{item.category}</BodyText>
            </ListItem>
            <ListItemsWrapper column={column}>
              {renderList(currentCategory.category)}
            </ListItemsWrapper>
          </LargeItem>
        )
      }
    })

  return (
    <LargeWrapper withLockOverlay={disabled}>
      <ListItem>
        <Icon
          name="filter"
          onClick={disabled ? undefined : onOpenFilter}
          color={list.length !== 0 ? 'blue' : 'gray8'}
          pointer
        >
          {!hideTitle && 'Фильтр'}
        </Icon>
      </ListItem>
      {isOpen && (
        <FilterBlock filterBlockWidth={filterBlockWidth} big>
          <div>{renderCategories()}</div>
          <ListItem className="actions-item">
            <Actions>
              <ListItem>
                <Icon
                  className="actions-close"
                  name="close"
                  color={list.length !== 0 ? 'blue' : 'gray8'}
                  onClick={handleClear}
                  pointer
                >
                  Очистить фильтр
                </Icon>
              </ListItem>
              <ListItem>
                <Icon
                  name="check"
                  onClick={() => (list.length !== 0 ? handleApply() : false)}
                  color="blue"
                  pointer
                >
                  Применить
                </Icon>
              </ListItem>
            </Actions>
          </ListItem>
        </FilterBlock>
      )}
    </LargeWrapper>
  )
}
