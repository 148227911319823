import { connect } from 'react-redux'

import { State } from 'reducers'
import { apiActions, apiSelectors } from 'api'
import { MyThunkDispatch } from 'api/reducer'
import { ModalsNames } from 'common/enums'
import { modalsActions, modalsSelectors } from 'shared/modals'
import { ConfirmationList } from 'payments/features/confirmationList/organisms'

const mapStateToProps = (state: State) => ({
  isTransactionFetching: apiSelectors.park.transactions.getIsFetching(state),
  isTransactionConfirmFetching: apiSelectors.park.transactionsConfirm.getIsFetching(
    state
  ),
  transactions: apiSelectors.park.transactions.getTransactions(state),
  totalAmount: apiSelectors.park.transactions.getTotalAmount(state),
  canViewTransactionDetails: apiSelectors.employee.profile.getCanViewTransactionDetails(
    state
  ),
  canProcessTransactions: apiSelectors.employee.profile.getCanProcessTransactions(
    state
  ),
  isPaymentDetailsModalOpened: modalsSelectors.getIsModalOpened(
    state,
    ModalsNames.PaymentDetails
  ),
  modalData: modalsSelectors.getData(state, ModalsNames.PaymentDetails),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  fetchTransactions: ({ params }: any) =>
    dispatch(
      apiActions.park.transactions.handleRequestPending({
        params: { ...params },
      })
    ),
  fetchTransactionsConfirm: ({ confirmed, transactionIds }: any) =>
    dispatch(
      apiActions.park.transactionsConfirm.handleRequest({
        data: {
          confirmed,
          transaction_ids: transactionIds,
        },
      })
    ),
  openPaymentDetailsModal: () =>
    dispatch(modalsActions.openModal(ModalsNames.PaymentDetails)),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const ConfirmationListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmationList)
