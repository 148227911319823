import React from 'react'

import { BodyText, RejectResolveButtons } from 'ui-kit/components'
import { Modal } from 'shared/ui/modal'
import { HBox } from 'shared/ui/spacers'
import styled from 'styled-components'

const Content = styled.div`
  padding: 16px;
  width: 528px;
`

const Actions = styled.div`
  padding: 16px;
`

interface Props {
  onClose: (isCancelled: boolean) => void
}

export const CancelChangesModal: React.FC<Props> = ({ onClose }) => (
  <Modal
    open
    onClose={() => onClose(false)}
    closeButton
    overflow="initial"
    title="Отменить изменения?"
  >
    <Content>
      <BodyText color="gray9">
        Все несохраненные изменения пропадут. Придется начать редактирование
        сначала.
      </BodyText>
    </Content>
    <HBox x={1.5} />
    <Actions>
      <RejectResolveButtons
        className="actions"
        resolveText="Да, отменить"
        rejectText="Нет, оставить"
        onResolve={() => onClose(true)}
        onReject={() => onClose(false)}
        fullWidth
      />
    </Actions>
  </Modal>
)
