import React from 'react'

import { DriverTransaction } from 'common/types/api/models/payment'
import { currencyToBase, getWidthInPercent } from 'common/utils/formatters'
import { formatToRegularDate } from 'common/utils/dates'
import { paymentReasonToString, paymentStatusToString } from 'common/enums'
import DateRangePicker from 'common/components/dateRangePicker/DateRangePicker'
import { Column, LargeFilter, Table } from 'shared/table'
import { ContentCard, ListPlaceholder } from 'shared/ui'

import {
  FilterAndPicker,
  Header,
  LinkWrapper,
  PaginatorWrapper,
} from 'drivers/features/driverPayments/organisms/DriverPayments/styles'
import { filtersData } from 'drivers/features/driverPayments/data'

import {
  BodyText,
  Icon,
  ListItem,
  ListLead,
  Pagination,
} from 'ui-kit/components'

interface Props {
  payments: DriverTransaction[]
  page: number
  total: number
  isFetching: boolean
  search: string
  filterList: string[]
  dateTo: Date | null
  dateFrom: Date | null
  searchLoading: boolean
  onSearch: (value: string) => void
  onChangePage: (direction: 'prev' | 'next') => void
  onApplyFilter: (filters: string[]) => void
  onClearFilter: () => void
  onChangeDate: (from: Date, to: Date) => void
  onClearDates: () => void
  preview?: boolean
  onShowAll?: () => void
}

export const DriverPayments: React.FC<Props> = ({
  payments,
  page,
  dateFrom,
  dateTo,
  onChangePage,
  total,
  filterList,
  preview,
  onShowAll,
  onApplyFilter,
  onClearFilter,
  onChangeDate,
  onClearDates,
  isFetching,
}) => {
  const blockWidth = 1064

  const formatDate = (payment: DriverTransaction): JSX.Element => (
    <ListLead table>{formatToRegularDate(payment.created_at)}</ListLead>
  )

  const formatReason = (payment: DriverTransaction): JSX.Element => (
    <ListLead table>{paymentReasonToString(payment.reason)}</ListLead>
  )

  const formatAmount = (payment: DriverTransaction): JSX.Element => (
    <ListLead table>{currencyToBase(payment.amount)}</ListLead>
  )

  const formatStatus = (payment: DriverTransaction): JSX.Element => (
    <ListLead table>{paymentStatusToString(payment.status)}</ListLead>
  )

  return (
    <>
      <ContentCard>
        <Header>
          <ListItem>
            <BodyText fontWeight="semi-bold">Платежи</BodyText>
          </ListItem>
          <FilterAndPicker>
            <DateRangePicker
              className="date-range-picker"
              onClearDates={onClearDates}
              dateFrom={dateFrom || undefined}
              dateTo={dateTo || undefined}
              onChangeDate={onChangeDate}
            />
            <LargeFilter
              filters={filtersData}
              onClear={onClearFilter}
              onApply={onApplyFilter}
              initialValues={filterList}
              filterBlockWidth={720}
            />
          </FilterAndPicker>
        </Header>
        <Table
          source={payments}
          preview={preview}
          isLoading={isFetching}
          bodyMinHeight="336px"
        >
          <Column
            data={formatDate}
            flex={`${getWidthInPercent(blockWidth, 218)}%`}
          >
            Дата платежа
          </Column>
          <Column
            data={formatReason}
            flex={`${getWidthInPercent(blockWidth, 431)}%`}
          >
            Основание
          </Column>
          <Column
            data={formatAmount}
            flex={`${getWidthInPercent(blockWidth, 207)}%`}
          >
            Сумма, ₽
          </Column>
          <Column
            data={formatStatus}
            flex={`${getWidthInPercent(blockWidth, 207)}%`}
          >
            Статус
          </Column>
        </Table>
        {!!total && (
          <PaginatorWrapper>
            {preview && (
              <LinkWrapper>
                <ListItem>
                  <Icon
                    name="chevronRight"
                    color="blue"
                    reverse
                    onClick={onShowAll}
                  >
                    Посмотреть все
                  </Icon>
                </ListItem>
              </LinkWrapper>
            )}
            {!preview && !!total && (
              <Pagination
                currentPage={page}
                totalPages={total}
                onClickPrev={() => onChangePage('prev')}
                onClickNext={() => onChangePage('next')}
              />
            )}
          </PaginatorWrapper>
        )}
        {!isFetching && !total && (
          <ListPlaceholder image="wallet" imageHeight="176px">
            Здесь будет отображаться
            <br />
            движение финансов водителя
          </ListPlaceholder>
        )}
      </ContentCard>
    </>
  )
}
