import React from 'react'
import { HBox, VBox } from 'shared/ui'
import styled from 'styled-components'
import Typography from 'ui-kit/typography'
import { Link } from 'ui-kit/atoms/Link'
import { AggregatorsCardContainer, UploadCardContainer } from '../../containers'

const Container = styled.div`
  display: flex;
  flex-direction: row;
`

const UploadContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const AggregatorsContainer = styled.div`
  width: 100%;
`

const TypographyCentered = styled(Typography)`
  text-align: center;
`

interface Props {
  onUploadSuccess: () => void
}

export const UploadContractsForm: React.FC<Props> = ({ onUploadSuccess }) => (
  <Container>
    <AggregatorsContainer>
      <AggregatorsCardContainer />
    </AggregatorsContainer>

    <VBox />

    <UploadContainer>
      <UploadCardContainer onUploadSuccess={onUploadSuccess} />
      <HBox x={1.5} />
      <TypographyCentered>
        Чтобы обновлять информацию автоматически,
        <br />
        <Link href="https://edgvr.org/">используйте Edgvr</Link>
      </TypographyCentered>
    </UploadContainer>
  </Container>
)
