import { createApiReducer } from 'common/utils/reduxApiFactory'
import { getParkPayoutCredentialsActions } from './actions'
import { GetParkPayoutCredentialsState } from './types'

export const initialState: GetParkPayoutCredentialsState = {
  status: null,
  data: null,
}

export const getParkPayoutCredentialsReducer = createApiReducer(
  getParkPayoutCredentialsActions,
  initialState
)
