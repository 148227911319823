import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'
import { Limitation, Pagination } from 'ui-kit/components'
import { PaginationLimit, PaymentTransaction } from 'api/types'
import DateRangePicker from 'common/components/dateRangePicker/DateRangePicker'
import { toISOFormat } from 'common/utils/dates'
import { separateFilter } from 'common/utils/helpers'
import { LargeFilter, Search } from 'shared/table'
import { ContentCard, ListPlaceholder } from 'shared/ui'
import { filtersData } from 'payments/features/allList/filtersData'
import { initialState, reducer } from 'payments/features/allList/reducer'
import { Listing } from 'payments/features/allList/molecules'
import { PaymentDetailsModalContainer } from 'payments/features/paymentDetailsModal'
import { ContainerProps } from 'payments/features/allList/containers/AllListContainer'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding: ${({ theme }) => theme.paddings.main}px;
`

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  padding-right: 8px;
`

const FilterAndPicker = styled.div`
  display: flex;
  align-items: center;

  .date-range-picker {
    margin-right: 16px;
  }
`

const PaginatorWrapper = styled.div`
  display: flex;
  padding-left: ${({ theme }) => theme.paddings.half}px;
  padding-right: ${({ theme }) => theme.paddings.main}px;
  justify-content: space-between;
  align-items: center;
`

export const AllList: React.FC<ContainerProps> = ({
  isFetching,
  transactions,
  totalAmount,
  canViewTransactionDetails,
  isPaymentDetailsModalOpened,
  fetchTransactions,
  openPaymentDetailsModal,
  modalData,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [transaction, setTransaction] = useState<PaymentTransaction>()
  const totalPages = totalAmount ? Math.ceil(totalAmount / state.limit) : 0

  const data = useMemo(() => {
    let result = {}

    const operationTypes = separateFilter(
      state.filters,
      filtersData,
      'Тип транзакций'
    )
    if (operationTypes.length > 0) {
      result = { ...result, transaction_type: operationTypes }
    }

    const paymentStatus = separateFilter(
      state.filters,
      filtersData,
      'Статус платежа'
    )
    if (paymentStatus.length > 0) {
      result = { ...result, payment_status: paymentStatus }
    }

    const reasonTypes = separateFilter(state.filters, filtersData, 'Основание')
    if (reasonTypes.length > 0) {
      result = { ...result, reason_type: reasonTypes }
    }

    return result
  }, [state.filters])

  const handleFetch = useCallback(
    () =>
      fetchTransactions({
        params: {
          limit: state.limit,
          offset: state.limit * (state.page - 1),
          date_from: state.from ? toISOFormat(state.from) : null,
          date_to: state.to ? toISOFormat(state.to) : null,
          search: state.search,
          ...data,
        },
      }),
    [
      data,
      fetchTransactions,
      state.from,
      state.limit,
      state.page,
      state.search,
      state.to,
    ]
  )
  useEffect(() => {
    const handleFetchWithModal = async () => {
      if (modalData) {
        setTransaction(modalData)
        openPaymentDetailsModal()
      } else {
        await handleFetch()
      }
    }
    handleFetchWithModal()
  }, [handleFetch, openPaymentDetailsModal, modalData])

  const onNextPage = () => {
    dispatch({ type: 'next' })
  }
  const onPrevPage = () => {
    dispatch({ type: 'prev' })
  }
  const onLimit = (limit: PaginationLimit) => {
    dispatch({ type: 'limit', limit })
  }
  const onSearch = (search: string) => {
    dispatch({ type: 'search', search })
  }
  const onChangeDates = (from: Date, to: Date) => {
    if (from && to) {
      dispatch({ type: 'changeDates', from, to })
    }
  }
  const onClearDates = () => {
    dispatch({ type: 'clearDates' })
  }
  const onChangeFilters = (filters: string[]) => {
    dispatch({ type: 'changeFilters', filters })
  }
  const onClearFilters = () => {
    dispatch({ type: 'clearFilters' })
  }

  const handleRowClick = canViewTransactionDetails
    ? (newTransaction: PaymentTransaction) => {
        setTransaction(newTransaction)
        openPaymentDetailsModal()
      }
    : undefined

  return (
    <>
      <Wrapper>
        <ContentCard>
          <TableHeader>
            <Search value={state.search} onSetSearch={onSearch} />
            <FilterAndPicker>
              <DateRangePicker
                className="date-range-picker"
                onClearDates={onClearDates}
                dateFrom={state.from || undefined}
                dateTo={state.to || undefined}
                onChangeDate={onChangeDates}
              />
              <LargeFilter
                filterBlockWidth={720}
                filters={filtersData}
                initialValues={state.filters}
                onApply={onChangeFilters}
                onClear={onClearFilters}
              />
            </FilterAndPicker>
          </TableHeader>
          <Listing
            transactions={transactions}
            isFetching={isFetching}
            handleRowClick={handleRowClick}
          />
          {totalPages ? (
            <PaginatorWrapper>
              <Pagination
                currentPage={state.page}
                totalPages={totalPages}
                onClickPrev={onPrevPage}
                onClickNext={onNextPage}
              />
              <Limitation currentLimit={state.limit} limitAction={onLimit} />
            </PaginatorWrapper>
          ) : null}
          {!isFetching && !totalPages ? (
            <ListPlaceholder image="wallet" imageHeight="176px">
              Здесь будет отображаться движение денег
              <br />в вашем автопарке
            </ListPlaceholder>
          ) : null}
        </ContentCard>
      </Wrapper>

      {isPaymentDetailsModalOpened && transaction ? (
        <PaymentDetailsModalContainer
          transaction={transaction}
          callbackFetch={handleFetch}
        />
      ) : null}
    </>
  )
}
