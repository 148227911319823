import { handleActions } from 'redux-actions'

import { Driver, DriverListInfo } from 'common/types/api/models/driver'
import { Sort } from 'common/types/local/sort'
import { SortDirection } from 'common/enums'

import {
  Payloads,
  RESET_DRIVERS_LIST_PAGE,
  SET_DRIVERS_LIST,
  SET_DRIVERS_LIST_FILTER,
  SET_DRIVERS_LIST_LIMIT,
  SET_DRIVERS_LIST_NEXT_PAGE,
  SET_DRIVERS_LIST_PREV_PAGE,
  SET_DRIVERS_LIST_SEARCH,
  SET_INFO,
  SORT_DRIVERS,
  SortableColumns,
} from 'settings/features/paymentSettingsDriversList/actions'
import { PaginationLimit } from 'api/types'

type DriverListHistory = {
  [key: number]: {
    prev: string | null
    next: string | null
  }
}

export interface DriversListSettingsState {
  info: DriverListInfo | null
  drivers: Driver[]
  searchValue: string | null
  page: number
  currentCursor: string | null
  history: DriverListHistory
  limit: PaginationLimit
  totalPages: number
  filters: string[]
  sort: Sort<SortableColumns>
}

const initialState: DriversListSettingsState = {
  info: null,
  drivers: [],
  searchValue: null,
  page: 1,
  currentCursor: null,
  history: {},
  limit: 20,
  totalPages: 0,
  filters: [],
  sort: {
    column: 'name',
    direction: SortDirection.ASC,
  },
}

const handleSetInfo = (
  state: DriversListSettingsState,
  action: SET_INFO
): DriversListSettingsState => ({
  ...state,
  info: action.payload,
})

const handleSetDriversList = (
  state: DriversListSettingsState,
  action: SET_DRIVERS_LIST
): DriversListSettingsState => {
  let currentHistory = state.history[state.page]
  if (currentHistory) {
    currentHistory = { ...currentHistory, next: action.payload.cursor }
  } else {
    currentHistory = { prev: null, next: action.payload.cursor }
  }

  return {
    ...state,
    totalPages: action.payload.total_pages,
    drivers: action.payload.items,
    history: {
      ...state.history,
      [state.page]: currentHistory,
    },
  }
}

const handleSetDriversListSearch = (
  state: DriversListSettingsState,
  action: SET_DRIVERS_LIST_SEARCH
): DriversListSettingsState => ({
  ...state,
  searchValue: action.payload,
})

const handleSetDriversListNextPage = (
  state: DriversListSettingsState
): DriversListSettingsState => ({
  ...state,
  history: {
    ...state.history,
    [state.page + 1]: { prev: state.currentCursor, next: null },
  },
  currentCursor: state.history[state.page].next,
  page: state.page + 1,
})

const handleSetDriversListPrevPage = (
  state: DriversListSettingsState
): DriversListSettingsState => ({
  ...state,
  currentCursor: state.history[state.page].prev,
  page: state.page - 1,
})

const handleResetDriversListPage = (
  state: DriversListSettingsState
): DriversListSettingsState => ({
  ...state,
  page: 1,
  currentCursor: null,
  history: {},
  totalPages: 0,
})

const handleSetDriversListLimit = (
  state: DriversListSettingsState,
  action: SET_DRIVERS_LIST_LIMIT
): DriversListSettingsState => ({
  ...state,
  page: 1,
  currentCursor: null,
  history: {},
  limit: action.payload,
})

const handleSetDriversListFilter = (
  state: DriversListSettingsState,
  action: SET_DRIVERS_LIST_FILTER
): DriversListSettingsState => ({
  ...state,
  filters: action.payload,
})

const handleSetDriversSort = (
  state: DriversListSettingsState,
  action: SORT_DRIVERS
): DriversListSettingsState => ({
  ...state,
  sort: action.payload,
})

export default handleActions<DriversListSettingsState, Payloads>(
  {
    [SET_INFO]: handleSetInfo,
    [SET_DRIVERS_LIST]: handleSetDriversList,
    [SET_DRIVERS_LIST_SEARCH]: handleSetDriversListSearch,
    [SET_DRIVERS_LIST_PREV_PAGE]: handleSetDriversListPrevPage,
    [SET_DRIVERS_LIST_NEXT_PAGE]: handleSetDriversListNextPage,
    [RESET_DRIVERS_LIST_PAGE]: handleResetDriversListPage,
    [SET_DRIVERS_LIST_LIMIT]: handleSetDriversListLimit,
    [SET_DRIVERS_LIST_FILTER]: handleSetDriversListFilter,
    [SORT_DRIVERS]: handleSetDriversSort,
  },
  initialState
)
