import { createApiReducer } from 'common/utils/reduxApiFactory'
import { postSubscriptionDepositAutoTopupActions } from './actions'
import { PostSubscriptionDepositAutoTopupState } from './types'

export const initialState: PostSubscriptionDepositAutoTopupState = {
  status: null,
  data: null,
}

export const postSubscriptionDepositAutoTopupReducer = createApiReducer(
  postSubscriptionDepositAutoTopupActions,
  initialState
)
