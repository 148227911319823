import React from 'react'

import { ColorTypes } from 'ui-kit'

import { ColorButton } from 'ui-kit/components'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;

  align-items: center;
`

const VSpace = styled.div`
  width: 24px;
`

interface Props {
  onResolve?: (e: React.MouseEvent<HTMLButtonElement>) => void
  onReject?: (e: React.MouseEvent<HTMLButtonElement>) => void
  resolveText?: string
  rejectText?: string
  className?: string
  fullWidth?: boolean
  rejectTextColor?: ColorTypes
  rejectBgColor?: ColorTypes
  resolveTextColor?: ColorTypes
  resolveBgColor?: ColorTypes
  disableResolve?: boolean
  disableReject?: boolean
  isLoading?: boolean
}

const defaultProps: Props = {
  onReject: undefined,
  onResolve: undefined,
  resolveText: 'Сохранить',
  rejectText: 'Отмена',
  rejectTextColor: 'black' as ColorTypes,
  rejectBgColor: 'gray3' as ColorTypes,
  resolveTextColor: 'white' as ColorTypes,
  resolveBgColor: 'blue' as ColorTypes,
}

export const RejectResolveButtons: React.FC<Props> = ({
  resolveText,
  rejectText,
  resolveBgColor,
  rejectBgColor,
  resolveTextColor,
  rejectTextColor,
  fullWidth,
  onResolve,
  onReject,
  disableResolve,
  disableReject,
  isLoading,
}) => (
  <Wrapper>
    <ColorButton
      onClick={onReject}
      fullWidth={fullWidth}
      textColor={rejectTextColor}
      color={rejectBgColor}
      disabled={disableReject}
    >
      {rejectText}
    </ColorButton>
    <VSpace />
    <ColorButton
      onClick={onResolve}
      fullWidth={fullWidth}
      textColor={resolveTextColor}
      color={resolveBgColor}
      fontWeight="medium"
      disabled={disableResolve}
      isLoading={isLoading}
    >
      {resolveText}
    </ColorButton>
  </Wrapper>
)

RejectResolveButtons.defaultProps = defaultProps
