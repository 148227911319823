import styled from 'styled-components'

export const Link = styled.a`
  align-items: normal;
  background-color: rgba(0, 0, 0, 0);
  border-color: ${({ theme }) => theme.pallete.blueMuted};
  border-style: none;
  box-sizing: content-box;
  color: ${({ theme }) => theme.pallete.blueMuted};
  cursor: pointer;
  display: inline;
  font: inherit;
  height: auto;
  padding: 0;
  perspective-origin: 0 0;
  text-align: start;
  text-decoration: underline;
  transform-origin: 0 0;
  width: auto;
  appearance: none;
  user-select: text;
  outline: none;

  @supports (-moz-appearance: none) {
    /* Mozilla-only */
    &::-moz-focus-inner {
      /* reset any predefined properties */
      border: none;
      padding: 0;
    }
    &:focus {
      /* add outline to focus pseudo-class */
      outline-style: dotted;
      outline-width: 1px;
    }
  }
`
