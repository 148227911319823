import { ThunkAction } from 'redux-thunk'
import { State } from 'reducers'
import axios, { AxiosResponse } from 'axios'
import { FailureError } from 'common/utils/reduxApiFactory'
import { TApiError } from 'api/types'
import { postCloudCashBoxActions as actions } from 'api/receipt/cloud-cashbox/post/actions'
import {
  PostCloudCashBox,
  PostCloudCashBoxActions,
} from 'api/receipt/cloud-cashbox/post/types'
import { CloudCashBox } from 'api/receipt/cloud-cashbox/types'

export type THandleRequest = ThunkAction<
  Promise<void>,
  State,
  undefined,
  PostCloudCashBoxActions
>

export const postCloudCashBoxRequest = (
  params: CloudCashBox
): THandleRequest => async (dispatch) => {
  dispatch(actions.start())
  try {
    const response: AxiosResponse<PostCloudCashBox> = await axios.post(
      'erp/1/receipt/cloud-cashbox',
      params
    )
    dispatch(actions.update(response.data))
    dispatch(actions.success())
  } catch (e) {
    if (e.response) {
      const data = e.response.data as TApiError
      const error: FailureError = new Error(data.message)
      error.data = data
      dispatch(actions.failure(error))
    }
    throw e
  }
}
