import { createApiReducer } from 'common/utils/reduxApiFactory'
import { GetCloudCashBoxState } from 'api/receipt/cloud-cashbox/get/types'
import { getCloudCashBoxActions } from 'api/receipt/cloud-cashbox/get/actions'

export const initialState: GetCloudCashBoxState = {
  status: null,
  data: null,
}

export const getCloudCashBoxReducer = createApiReducer(
  getCloudCashBoxActions,
  initialState
)
