import React, { Dispatch, useEffect, useReducer } from 'react'

import { AuthLayout } from 'shared/router'
import { list } from 'steps/data'
import { StepsActionType, TStepsAction, TStepsState } from 'steps/types'
import { LeftSide, RightSide } from 'steps/features/content'

import { ContainerProps } from 'steps/containers/StepsContainer'

const initialState: TStepsState = {
  current: list.requisites,
  channelType: null,
}

function stepsReducer(state: TStepsState, action: TStepsAction): TStepsState {
  switch (action.type) {
    case StepsActionType.SET_CURRENT_STEP:
      return {
        ...state,
        current: action.payload.current
          ? action.payload.current
          : state.current,
      }
    case StepsActionType.SET_CHANNEL_TYPE:
      return {
        ...state,
        channelType: action.payload.channelType
          ? action.payload.channelType
          : state.channelType,
      }
    default:
      return state
  }
}

export const StepsContext = React.createContext<
  [TStepsState, Dispatch<TStepsAction>] | null
>(null)

export const Steps: React.FC<ContainerProps> = ({ layoutOnWillUnmount }) => {
  useEffect(() => {
    return () => {
      layoutOnWillUnmount()
    }
  }, [layoutOnWillUnmount])

  const [steps, stepsDispatch] = useReducer(stepsReducer, initialState)

  return (
    <StepsContext.Provider value={[steps, stepsDispatch]}>
      <AuthLayout
        leftSideContent={<LeftSide current={steps.current} />}
        rightSideContent={<RightSide tooltip={steps.current.tooltip} />}
        withoutLogo
        className="right-side-big-picture"
        rightWidth={564}
      />
    </StepsContext.Provider>
  )
}
