import { createApiReducer } from 'common/utils/reduxApiFactory'
import { GetSnoState } from 'api/receipt/sno/get/types'
import { getSnoActions } from 'api/receipt/sno/get/actions'

export const initialState: GetSnoState = {
  status: null,
  data: null,
}

export const getSnoReducer = createApiReducer(getSnoActions, initialState)
