import { connect } from 'react-redux'
import { getFormValues, reduxForm } from 'redux-form'

import { State } from 'reducers'
import { FormNames, ModalsNames } from 'common/enums'
import { apiSelectors } from 'api'
import { MyThunkDispatch } from 'api/reducer'
import { modalsActions } from 'shared/modals'
import { onSaveDriverContract } from 'drivers/features/driverInfo/thunks'
import { AddDriverContractModal } from 'drivers/features/driverInfo/pages'

export interface FormProps {
  contract_number: string
  contract_date: Date | null
  first_name: string
  last_name: string
  second_name: string | null
  contract_suffix: string | null
  is_non_resident?: boolean | null
}

const mapStateToProps = (state: State) => ({
  id: apiSelectors.driversById.get.getId(state),
  initialValues: apiSelectors.driversById.get.getContractFormValues(state),
  formValues: getFormValues(FormNames.AddDriverContract)(state) as FormProps,
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  closeModal: () =>
    dispatch(modalsActions.closeModal(ModalsNames.AddDriverContract)),
  onSave: ({ id, values }: any) =>
    dispatch(onSaveDriverContract({ id, values })),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const Form = reduxForm<FormProps, ContainerProps>({
  form: FormNames.AddDriverContract,
  touchOnChange: true,
})(AddDriverContractModal)

export const AddDriverContractModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Form)
