import React, { useEffect, useRef } from 'react'

import { BodyText, CaptionText, Icon, TitleText } from 'ui-kit/components'

import { history } from 'store'
import { DriversByIdGetResponse } from 'api/types'
import { RoutesPath } from 'common/enums'
import { toLastActivity } from 'common/utils/dates'
import { Avatar, Col, HBox, Row, Tooltip, VBox } from 'shared/ui'
import styled from 'styled-components'

import avatarDriverSrc from 'static/placeholders/avatar-driver.svg'

const Left = styled.div`
  display: flex;
  align-items: flex-start;
`
const Right = styled.a`
  text-decoration: none;
`

interface Props {
  info: DriversByIdGetResponse
  hasUserId: boolean
  isSecurityCheckPassed: boolean
}

const contentContainer = styled.div`
  top: calc(100% + 8px) !important;
  bottom: initial !important;
  right: initial !important;
  left: 0 !important;
  width: 344px !important;
  box-shadow: 0 3px 10px rgba(192, 206, 226, 0.5),
    0 0 4px rgba(192, 206, 226, 0.2) !important;
`

const SecurityCheckPassed = () => (
  <>
    <BodyText fontWeight="bold">Надежный водитель</BodyText>
    <HBox x={0.5} />
    <BodyText>Платежи подтверждаются автоматически.</BodyText>
  </>
)

const SecurityCheckNotPassed = () => (
  <>
    <BodyText fontWeight="bold">Новый водитель</BodyText>
    <HBox x={0.5} />
    <BodyText>
      Платежи подтверждаются вручную, пока водитель не совершит достаточное
      количество безналичных поездок и подтвержденных выводов.
    </BodyText>
  </>
)

const SpaceBetweenRow = styled(Row)`
  justify-content: space-between;
`

export const Heading: React.FC<Props> = ({
  info: { name, last_ride_date, aggregator_url },
  hasUserId,
  isSecurityCheckPassed,
}) => {
  const historyCount = useRef(-1)

  const goDrivers = () => {
    history.push(RoutesPath.Drivers)
  }

  const goBack = () => {
    history.go(historyCount.current)
  }

  useEffect(() => {
    const stopListen = history.listen(() => {
      historyCount.current -= 1
    })

    return () => {
      stopListen()
    }
  }, [])

  const canGoBack = history.action === 'PUSH'

  return (
    <>
      <Row>
        <Icon
          color="darkGray"
          name="chevronLeft"
          pointer
          onClick={canGoBack ? goBack : goDrivers}
        >
          {canGoBack ? 'Назад' : 'Водители'}
        </Icon>
      </Row>
      <HBox x={0.5} />
      <SpaceBetweenRow>
        <Left>
          <Avatar avatar={avatarDriverSrc} />
          <VBox x={0.75} />
          <Col>
            <Row>
              <TitleText fontWeight="semi-bold">{name}</TitleText>
              {isSecurityCheckPassed ? (
                <>
                  <VBox x={0.5} />
                  <Tooltip
                    iconName="successful"
                    content={<SecurityCheckPassed />}
                    contentComponent={contentContainer}
                    withDefaultIconColor
                  />
                </>
              ) : (
                <>
                  <VBox x={0.5} />
                  <Tooltip
                    iconName="successfulLightGray"
                    content={<SecurityCheckNotPassed />}
                    contentComponent={contentContainer}
                    withDefaultIconColor
                  />
                </>
              )}
            </Row>
            {hasUserId && last_ride_date ? (
              <>
                <HBox x={0.5} />
                <CaptionText
                  color="darkGray"
                  className="driver-header-caption"
                  fontWeight="medium"
                >
                  {`Был активен ${toLastActivity(last_ride_date)}`}
                </CaptionText>
              </>
            ) : null}
          </Col>
        </Left>
        <VBox />
        <Right href={aggregator_url} target="_blank">
          <Icon name="link" color="blue">
            Профиль в Диспетчерской
          </Icon>
        </Right>
      </SpaceBetweenRow>
    </>
  )
}
