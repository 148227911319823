import { createApiReducer } from 'common/utils/reduxApiFactory'
import { PostReferralInviteListState } from './types'
import { postReferralInviteListActions } from './actions'

export const initialState: PostReferralInviteListState = {
  status: null,
  data: { invite: [] },
}

export const postReferralInviteListReducer = createApiReducer(
  postReferralInviteListActions,
  initialState
)
