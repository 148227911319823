import { connect } from 'react-redux'
import { getFormValues, reduxForm } from 'redux-form'

import { State } from 'reducers'
import { MyThunkDispatch } from 'api/reducer'
import { BankType, FormNames } from 'common/enums'
import { onSaveRequisites } from 'steps/features/stepRequisites/thunks'
import { StepRequisites } from 'steps/features/stepRequisites/organisms'

export interface FormProps {
  bank_type: BankType
  legal_name: string
  bik: string
  bank_account: string
  email?: string
  phone?: string
}

const mapStateToProps = (state: State) => ({
  formValues: getFormValues(FormNames.StepRequisites)(state) as FormProps,
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  onSave: (values: FormProps) => dispatch(onSaveRequisites({ values })),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const Form = reduxForm<FormProps, ContainerProps>({
  form: FormNames.StepRequisites,
})(StepRequisites)

export const StepRequisitesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Form)
