import { connect } from 'react-redux'

import { MyThunkDispatch } from 'api/reducer'
import { onDidMount } from 'analytics/thunks'
import { Analytics } from 'analytics/components'
import { State } from 'reducers'
import { selectCanView } from 'analytics/selectors'

const mapStateToProps = (state: State) => ({
  canView: selectCanView(state),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  onDidMount: () => dispatch(onDidMount()),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const AnalyticsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Analytics)
