import { createApiReducer } from 'common/utils/reduxApiFactory'
import { PatchReferralOffersState } from './types'
import { patchReferralOffersActions } from './actions'

export const initialState: PatchReferralOffersState = {
  status: null,
  data: null,
}

export const patchReferralOffersReducer = createApiReducer(
  patchReferralOffersActions,
  initialState
)
