import { createApiReducer } from 'common/utils/reduxApiFactory'
import { GetTinkoffState } from 'api/park/tinkoff/get/types'
import { getTinkoffActions } from 'api/park/tinkoff/get/actions'

export const initialState: GetTinkoffState = {
  status: null,
  data: null,
}

export const getTinkoffReducer = createApiReducer(
  getTinkoffActions,
  initialState
)
