import styled from 'styled-components'

interface Props {
  reverse?: boolean
}

export const Wrapper = styled('span')<Props>`
  display: inline-flex;
  flex-direction: column;

  ${(props) =>
    props.reverse &&
    `
    flex-direction: column-reverse;
  `}
`
