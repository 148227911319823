import { createApiReducer } from 'common/utils/reduxApiFactory'
import { postParkPaymentSettingsActions } from 'api/park/payment-settings/post/actions'
import { ParkPaymentSettingsState } from 'api/park/payment-settings/post/types'

export const initialState: ParkPaymentSettingsState = {
  status: null,
  data: null,
}

export const postParkPaymentSettingsReducer = createApiReducer(
  postParkPaymentSettingsActions,
  initialState
)
