import { Action } from 'redux-actions'

import { DriverTransaction } from 'common/types/api/models/payment'

export interface SetPaymentsPayload {
  total: number
  items: DriverTransaction[]
}
export type SET_PAYMENTS = Action<SetPaymentsPayload>
export const SET_PAYMENTS: SET_PAYMENTS['type'] = 'driver_details/SET_PAYMENTS'
export const setPayments = (payments: SetPaymentsPayload): SET_PAYMENTS => ({
  type: SET_PAYMENTS,
  payload: payments,
})

export type SET_PAYMENTS_PAGE = Action<number>
export const SET_PAYMENTS_PAGE: SET_PAYMENTS_PAGE['type'] =
  'driver_details/SET_PAYMENTS_PAGE'
export const setPaymentsPage = (page: number): SET_PAYMENTS_PAGE => ({
  type: SET_PAYMENTS_PAGE,
  payload: page,
})

export type SET_PAYMENTS_SEARCH = Action<string>
export const SET_PAYMENTS_SEARCH: SET_PAYMENTS_SEARCH['type'] =
  'driver_details/SET_PAYMENTS_SEARCH'
export const setPaymentsSearch = (
  searchValue: string
): SET_PAYMENTS_SEARCH => ({
  type: SET_PAYMENTS_SEARCH,
  payload: searchValue,
})

export type SET_PAYMENTS_FILTER = Action<string[]>
export const SET_PAYMENTS_FILTER: SET_PAYMENTS_FILTER['type'] =
  'driver_details/SET_PAYMENTS_FILTER'
export const setPaymentsFilter = (filters: string[]): SET_PAYMENTS_FILTER => ({
  type: SET_PAYMENTS_FILTER,
  payload: filters,
})

export type SET_PAYMENTS_DATE_FROM = Action<Date | null>
export const SET_PAYMENTS_DATE_FROM: SET_PAYMENTS_DATE_FROM['type'] =
  'driver_details/SET_PAYMENTS_DATE_FROM'
export const setPaymentsDateFrom = (
  date: Date | null
): SET_PAYMENTS_DATE_FROM => ({
  type: SET_PAYMENTS_DATE_FROM,
  payload: date,
})

export type SET_PAYMENTS_DATE_TO = Action<Date | null>
export const SET_PAYMENTS_DATE_TO: SET_PAYMENTS_DATE_TO['type'] =
  'driver_details/SET_PAYMENTS_DATE_TO'
export const setPaymentsDateTo = (date: Date | null): SET_PAYMENTS_DATE_TO => ({
  type: SET_PAYMENTS_DATE_TO,
  payload: date,
})

export type Payloads = Pick<
  SET_PAYMENTS &
    SET_PAYMENTS_SEARCH &
    SET_PAYMENTS_PAGE &
    SET_PAYMENTS_FILTER &
    SET_PAYMENTS_DATE_FROM &
    SET_PAYMENTS_DATE_TO,
  'payload'
>['payload']
