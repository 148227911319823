import React from 'react'

import { CaptionText, Icon } from 'ui-kit/components'
import { HBox, Row, VBox } from 'shared/ui'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  border: 1px dashed ${({ theme }) => theme.pallete.blue};
  border-radius: 10px;
  cursor: pointer;
`

const PlusCircle = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.pallete.blue};
  border-radius: 50%;
`

export const AddCard: React.FC<{
  multiAdd?: boolean
  onClickAdd?: () => void
  addText?: string
  isExpanded?: boolean
}> = ({ multiAdd, onClickAdd, addText, isExpanded }) => (
  <Container onClick={multiAdd ? () => undefined : onClickAdd}>
    <PlusCircle>
      <Icon name="add" color="white" />
    </PlusCircle>
    <HBox x={0.5} />
    {multiAdd ? (
      <Row>
        <CaptionText color="blue" fontWeight="medium">
          {addText}
        </CaptionText>
        <VBox x={0.25} />
        <Icon name={isExpanded ? 'expandLess' : 'expandMore'} color="blue" />
      </Row>
    ) : (
      <CaptionText color="blue" fontWeight="medium">
        {addText}
      </CaptionText>
    )}
  </Container>
)
