import { connect } from 'react-redux'
import { State } from 'reducers'
import { apiSelectors } from 'api'
import { DriverAccountsTab } from 'drivers/features/driverAccountsTab/organisms'

const mapStateToProps = (state: State) => ({
  hasAccounts: apiSelectors.driversById.get.getHasAccounts(state),
})

const mapDispatchToProps = () => ({})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const DriverAccountsTabContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DriverAccountsTab)
