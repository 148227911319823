import { createSelector } from 'reselect'
import { State } from 'reducers'
import { Option } from 'common/types/local/option'

export const selectParkPaymentSettingsAll = (state: State) =>
  state.api.park.paymentSettingsAll

export const selectIsFetching = (state: State) =>
  state.api.park.paymentSettingsAll.status === 'loading'

export const makeSelectData = () =>
  createSelector(selectParkPaymentSettingsAll, (state) => {
    if (!state.data) return null
    return state.data
  })

export const makeSelectGroupOptions = () =>
  createSelector(makeSelectData(), (data) => {
    if (!data) return null

    return data.group_payment_settings.map((settings) => ({
      label: settings.name,
      value: settings.id,
    }))
  })

export const makeSelectDefaultOption = () =>
  createSelector(makeSelectData(), (data) => {
    if (!data) return null

    return {
      label: data.park_default_payment_settings.name,
      value: data.park_default_payment_settings.id,
    }
  })

export const makeSelectOptions = () =>
  createSelector(
    makeSelectGroupOptions(),
    makeSelectDefaultOption(),
    (groupOptions, defaultOption) => {
      const result: Option<string>[] = []

      if (defaultOption) {
        result.push(defaultOption)
      }

      if (groupOptions) {
        result.push(...groupOptions)
      }

      return result
    }
  )
const selectSettingsIdFromOwnProps = (_: State, settingsId: string) =>
  settingsId

export const selectSettingsById = createSelector(
  makeSelectData(),
  selectSettingsIdFromOwnProps,
  (settings, settingsId) => {
    if (!settings) return null
    if (settings.park_default_payment_settings.id === settingsId) {
      return settings.park_default_payment_settings
    }
    return settings.group_payment_settings.find(
      (item) => item.id === settingsId
    )
  }
)
