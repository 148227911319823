import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import { MyThunkDispatch } from 'api/reducer'

import { FormNames } from 'common/enums'
import { onSaveEdgvr } from 'steps/features/stepDrivers/thunks'
import { StepDrivers } from 'steps/features/stepDrivers/organisms'

export interface FormProps {
  edgvr_api_key: string
}

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  onSave: (values: FormProps) => dispatch(onSaveEdgvr({ values })),
})

const Form = reduxForm<FormProps, ContainerProps>({
  form: FormNames.StepDriversEdgvr,
})(StepDrivers)

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const StepDriversContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Form)
