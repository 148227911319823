import decode from 'jwt-decode'
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

type Token = string

export interface AuthTokens {
  accessToken: Token
  refreshToken: Token
}

type TokenDto = {
  exp: number
  iat: number
}

export type TokenRefreshRequest = (
  refreshToken: string
) => Promise<AxiosResponse<AuthTokens>>

export interface IAuthTokenInterceptorConfig {
  header?: string
  headerPrefix?: string
  requestRefresh: TokenRefreshRequest
}

const EXPIRE_OFFSET = 10
const KEY_PREFIX = `auth-token-${process.env.NODE_ENV}`

export function setAuthTokens(tokens: AuthTokens) {
  localStorage.setItem(`${KEY_PREFIX}-accessToken`, tokens.accessToken)
  localStorage.setItem(`${KEY_PREFIX}-refreshToken`, tokens.refreshToken)
}

export function clearAuthTokens() {
  localStorage.removeItem(`${KEY_PREFIX}-accessToken`)
  localStorage.removeItem(`${KEY_PREFIX}-refreshToken`)
}

export function getAuthTokens() {
  const access = localStorage.getItem(`${KEY_PREFIX}-accessToken`)
  const refresh = localStorage.getItem(`${KEY_PREFIX}-refreshToken`)

  if (!access || !refresh) {
    return null
  }

  return {
    refreshToken: refresh,
    accessToken: access,
  } as AuthTokens
}

function getRefreshToken() {
  const authTokens = getAuthTokens()
  return authTokens?.refreshToken ?? null
}

function getAccessToken() {
  const authTokens = getAuthTokens()
  return authTokens?.accessToken ?? null
}

function getTokenExpiresTimeStamp(token: Token) {
  try {
    const decoded = decode<TokenDto>(token)
    return decoded.exp
  } catch (e) {
    return null
  }
}

function getExpiresInFromJWT(token: Token) {
  const exp = getTokenExpiresTimeStamp(token)
  if (exp) {
    return exp - Date.now() / 1000
  }

  return -1
}

function isTokenExpired(token: Token) {
  const expin = getExpiresInFromJWT(token) - EXPIRE_OFFSET
  return expin < 0
}

async function refreshToken(requestRefresh: TokenRefreshRequest) {
  const token = getRefreshToken()

  if (!token) {
    return Promise.reject(new Error('No refresh token available'))
  }

  try {
    const response = await requestRefresh(token)
    setAuthTokens(response.data)
    return response
  } catch (err) {
    // failed to refresh... check error type
    if (
      err &&
      err.response &&
      (err.response.status === 401 || err.response.status === 422)
    ) {
      // got invalid token response for sure, remove saved tokens because they're invalid
      clearAuthTokens()
      return Promise.reject(
        new Error(`Got 401 on token refresh; Resetting auth token: ${err}`)
      )
    }

    // some other error, probably network error
    return Promise.reject(new Error(`Failed to refresh auth token: ${err}`))
  }
}

export async function refreshTokenIfNeeded(
  requestRefresh: TokenRefreshRequest
) {
  const accessToken = getAccessToken()

  if (!accessToken || isTokenExpired(accessToken)) {
    const authTokens = await refreshToken(requestRefresh)
    return authTokens.data.accessToken
  }

  return accessToken
}

function makeJwtAuthInterceptor({
  header = 'Authorization',
  headerPrefix = 'Bearer ',
  requestRefresh,
}: IAuthTokenInterceptorConfig) {
  return async function jwtAuthInterceptor(requestConfig: AxiosRequestConfig) {
    if (!getRefreshToken()) return requestConfig

    let accessToken
    try {
      accessToken = await refreshTokenIfNeeded(requestRefresh)
    } catch (err) {
      console.warn(err)
      return Promise.reject(
        new Error(
          `Unable to refresh access token for request: ${requestConfig} due to token refresh error: ${err}`
        )
      )
    }

    if (accessToken) {
      return {
        ...requestConfig,
        headers: {
          ...requestConfig.headers,
          [header]: `${headerPrefix}${accessToken}`,
        },
      } as AxiosRequestConfig
    }

    return requestConfig
  }
}

export function useJwtAuth(
  axiosInstance: AxiosInstance,
  config: IAuthTokenInterceptorConfig
) {
  let requestRefreshPromise: Promise<AxiosResponse<AuthTokens>> | undefined

  const cacheRequestRefresh = async (token: string) => {
    if (requestRefreshPromise) {
      return requestRefreshPromise
    }

    requestRefreshPromise = config.requestRefresh(token)
    const response = await requestRefreshPromise
    requestRefreshPromise = undefined
    return response
  }

  axiosInstance.interceptors.request.use(
    makeJwtAuthInterceptor({
      ...config,
      requestRefresh: cacheRequestRefresh,
    })
  )
}
