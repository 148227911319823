import React from 'react'
import styled, { css } from 'styled-components'
import { ColorTypes, IconNames } from 'ui-kit'
import { Icon as DefaultIcon } from 'ui-kit/components'
import Typography from 'ui-kit/typography'

export type InlineRadioOption<T> = {
  label: string
  value: T
  iconName?: IconNames
  isDisabled?: boolean
}

type Props<T> = {
  options: InlineRadioOption<T>[]
  onChange: (value: T) => void
  initialValue?: T
  isDisabled?: boolean
}

const InlineRadioButtonContentWrapper = styled.div`
  position: relative;
  z-index: 11;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 24px;
`

const Icon = styled(DefaultIcon)`
  margin-right: 4px;
`

const InlineRadioButtonWrapper = styled.button<{
  isActive?: boolean
  isDisabled?: boolean
  withIcon?: boolean
}>`
  outline: none;
  appearance: none;
  border: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 96px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: ${({ withIcon }) => (withIcon ? 12 : 16)}px;
  padding-right: ${({ withIcon }) => (withIcon ? 20 : 16)}px;
  box-sizing: border-box;
  position: relative;
  text-align: center;
  cursor: pointer;
  user-select: none;
  background-color: transparent;

  z-index: ${({ isActive }) => isActive && 10};

  &:hover {
    z-index: 10;
  }

  &:hover:focus {
    z-index: 20;
  }
  
  &:focus {
      z-index: 11;
      &:before {
        border: ${({ theme, isDisabled }) =>
          !isDisabled && `1px solid ${theme.pallete.accentPrimary}`};
      }
  }

  &:before {
    content: '';
    position: absolute;
    left: -1px;
    top: -1px;
    right: -1px;
    bottom: -1px;
    border: 1px solid ${({ theme }) => theme.pallete.secondaryLightBlue};

    ${({ isActive, isDisabled }) =>
      isActive &&
      !isDisabled &&
      css`
        border: 1px solid ${({ theme }) => theme.pallete.accentPrimary};
        background-color: ${({ theme }) => theme.pallete.accentPrimary};
      `}
    
    ${({ isActive, isDisabled }) =>
      isDisabled &&
      isActive &&
      css`
        border: 1px solid ${({ theme }) => theme.pallete.uiDisabled};
        background-color: ${({ theme }) => theme.pallete.uiDisabled};
      `}

    ${({ isDisabled }) =>
      isDisabled &&
      css`
        border: 1px solid ${({ theme }) => theme.pallete.uiDisabled};
      `}
  }

  &:hover:before {
    ${({ isDisabled }) =>
      !isDisabled &&
      css`
        z-index: 11;
        border: 1px solid ${({ theme }) => theme.pallete.accentPrimary};
      `}
  }

  &:first-child {
    padding-left: ${({ withIcon }) => (withIcon ? 16 : 20)}px;

    &:before {
      border-bottom-left-radius: 70px;
      border-top-left-radius: 70px;
    }
  }

  &:last-child {
    padding-right: ${({ withIcon }) => (withIcon ? 24 : 20)}px;

    &:before {
      border-bottom-right-radius: 70px;
      border-top-right-radius: 70px;
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 70px;
  background-color: #fff;
`

type InlineRadioButtonProps = {
  children: React.ReactNode
  onClick?: () => void
  icon?: IconNames
  isDisabled?: boolean
  isActive?: boolean
}
const InlineRadioButton = React.memo<InlineRadioButtonProps>(
  ({ children, isActive, isDisabled, icon, onClick }) => {
    const notActiveColor = isDisabled ? 'uiDisabled' : undefined
    return (
      <InlineRadioButtonWrapper
        isActive={isActive}
        isDisabled={isDisabled}
        withIcon={!!icon}
        onClick={isActive ? undefined : onClick}
        tabIndex={isDisabled ? -1 : 1}
      >
        <InlineRadioButtonContentWrapper>
          {icon !== undefined ? (
            <Icon name={icon} color={isActive ? 'white' : notActiveColor} />
          ) : null}
          {children}
        </InlineRadioButtonContentWrapper>
      </InlineRadioButtonWrapper>
    )
  }
)
InlineRadioButton.displayName = 'InlineRadioButton'

const Separator = styled.div`
  margin-left: 1px;
`

export default function InlineRadio<T>({
  initialValue,
  options,
  onChange,
  isDisabled,
}: Props<T>) {
  const [value, setValue] = React.useState(initialValue || options[0]?.value)

  const handleClick = React.useCallback(
    (newValue: T) => () => {
      setValue(newValue)
      onChange(newValue)
    },
    [onChange]
  )

  return (
    <Wrapper>
      {options
        .map((option) => {
          let color: ColorTypes = 'textPrimary'
          if (option.value === value) {
            color = 'white'
          } else if (option.isDisabled || isDisabled) {
            color = 'uiDisabled'
          }
          return (
            <InlineRadioButton
              key={option.label}
              isActive={option.value === value}
              icon={option.iconName}
              isDisabled={option.isDisabled || isDisabled}
              onClick={
                option.isDisabled || isDisabled
                  ? undefined
                  : handleClick(option.value)
              }
            >
              <Typography variant="Body1" fontColor={color}>
                {option.label}
              </Typography>
            </InlineRadioButton>
          )
        })
        .reduce(
          (prev, next, index) =>
            index < 1
              ? [next]
              : [...prev, <Separator key={`separator-${next.key}`} />, next],
          [] as React.ReactElement[]
        )}
    </Wrapper>
  )
}
