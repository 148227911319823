import { PaginationLimit } from 'api/types'

type StateType = {
  limit: PaginationLimit
  page: number
  search: string
  from: Date | null
  to: Date | null
  filters: string[]
}

export const initialState: StateType = {
  limit: 20,
  page: 1,
  search: '',
  from: null,
  to: null,
  filters: [],
}

type ActionType = {
  type:
    | 'prev'
    | 'next'
    | 'limit'
    | 'search'
    | 'changeDates'
    | 'clearDates'
    | 'changeFilters'
    | 'clearFilters'
  limit?: PaginationLimit
  search?: string
  from?: Date
  to?: Date
  filters?: string[]
}

export function reducer(state: StateType, action: ActionType): StateType {
  switch (action.type) {
    case 'prev':
      return { ...state, page: state.page - 1 }
    case 'next':
      return { ...state, page: state.page + 1 }
    case 'limit':
      return {
        ...state,
        limit: action.limit ? action.limit : state.limit,
        page: 1,
      }
    case 'search':
      return {
        ...state,
        search: action.search !== undefined ? action.search : state.search,
        page: 1,
      }
    case 'changeDates':
      return {
        ...state,
        from: action.from ? action.from : state.from,
        to: action.to ? action.to : state.to,
        page: 1,
      }
    case 'clearDates':
      return { ...state, from: null, to: null, page: 1 }
    case 'changeFilters':
      return {
        ...state,
        filters: action.filters ? action.filters : state.filters,
        page: 1,
      }
    case 'clearFilters':
      return { ...state, filters: [], page: 1 }
    default:
      return state
  }
}
