import { ReferralOfferResponse } from 'api/referral/offer/types'
import { InviterBonusTypes } from 'elastic-ui/referral-program/organisms/TermsEdit/types'

type RewardType = ReferralOfferResponse['inviter_reward_type']

const map: Record<RewardType, InviterBonusTypes> = {
  amount: InviterBonusTypes.FixedBonus,
  amount_per_ride: InviterBonusTypes.MoneyForDriving,
  commission_percent: InviterBonusTypes.ParkCommisionShare,
}

export default (
  rewardType: ReferralOfferResponse['inviter_reward_type']
): InviterBonusTypes => map[rewardType]
