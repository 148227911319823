import { connect } from 'react-redux'

import { State } from 'reducers'
import { apiActions, apiSelectors } from 'api'
import { MyThunkDispatch } from 'api/reducer'
import { ModalsNames } from 'common/enums'
import { modalsActions, modalsSelectors } from 'shared/modals'
import { ManualConfirmationList } from 'payments/features/manualConfirmationList/organisms'
import { getSelectedOutputFormat } from '../selectors'
import { setPaymentOutputFormat } from '../actions'

const mapStateToProps = (state: State) => ({
  isTransactionsFetching: apiSelectors.park.transactions.getIsFetching(state),
  isTransactionsPaymentOrderFetching: apiSelectors.park.transactionsPaymentOrder.getIsFetching(
    state
  ),
  isTransactionsConfirmFetching: apiSelectors.park.transactionsConfirm.getIsFetching(
    state
  ),
  transactions: apiSelectors.park.transactions.getTransactions(state),
  totalAmount: apiSelectors.park.transactions.getTotalAmount(state),
  canViewTransactionDetails: apiSelectors.employee.profile.getCanViewTransactionDetails(
    state
  ),
  canProcessTransactions: apiSelectors.employee.profile.getCanProcessTransactions(
    state
  ),
  isPaymentDetailsModalOpened: modalsSelectors.getIsModalOpened(
    state,
    ModalsNames.PaymentDetails
  ),
  modalData: modalsSelectors.getData(state, ModalsNames.PaymentDetails),
  selectedOutputFormatDownloadOption: getSelectedOutputFormat(state),
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  fetchTransactions: ({ params }: any) =>
    dispatch(
      apiActions.park.transactions.handleRequestPending({
        params,
      })
    ),
  fetchTransactionsConfirm: ({ confirmed, transactionIds }: any) =>
    dispatch(
      apiActions.park.transactionsConfirm.handleRequest({
        data: {
          confirmed,
          transaction_ids: transactionIds,
        },
      })
    ),
  fetchTransactionsPaymentOrder: ({
    transactionIds,
    fileFormat,
  }: {
    transactionIds: string[]
    fileFormat: string
  }) =>
    dispatch(
      apiActions.park.transactionsPaymentOrder.handleRequest({
        data: {
          transaction_ids: transactionIds,
          file_format: fileFormat,
          xml_payment_order_data:
            fileFormat === 'xml'
              ? {
                  park_contract_date:
                    'Введите дату заключения договора с банком',
                  park_contract_number:
                    'Введите номер договора с банком',
                  registry_date: "Введите дату реестра",
                  registry_number: "Введите номер реестра",
                }
              : null,
        },
      })
    ),
  openPaymentDetailsModal: () =>
    dispatch(modalsActions.openModal(ModalsNames.PaymentDetails)),
  setSelectedOutputFormatDownloadOption: (outputFormat: string) =>
    dispatch(setPaymentOutputFormat(outputFormat)),
})

export type ContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const ManualConfirmationListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ManualConfirmationList)
