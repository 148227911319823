import React, { useState } from 'react'
import { Icon, SubBodyText, TitleText } from 'ui-kit/components'
import { history } from 'store'
import { RoutesPath } from 'common/enums'
import {
  ActionBottomPanel,
  ContentCard,
  ContentCardBody,
  HBox,
  Row,
} from 'shared/ui'
import {
  Heading,
  Listing,
} from 'drivers/features/driversUploadContracts/molecules'
import { UploadCardContainer } from 'drivers/features/driversUploadContracts/containers'
import { ContainerProps } from 'drivers/features/driversUploadContracts/containers/DriversUploadContractsContainer'
import styled from 'styled-components'
import { UploadContractsForm } from '../UploadContractsForm/UploadContractsForm'

const Wrapper = styled.div`
  min-height: 100%;
  padding: ${({ theme }) => theme.paddings.main}px;
`

export const DriversUploadContracts: React.FC<ContainerProps> = ({
  contractsList,
  contractsListLength,
  isFetchingCommit,
  onCommit,
}) => {
  const goDrivers = () => history.push(RoutesPath.Drivers)
  const [review, setReview] = useState(false)

  const startReview = React.useCallback(() => setReview(true), [])

  return (
    <>
      <Wrapper>
        <Row>
          <Icon color="darkGray" name="chevronLeft" pointer onClick={goDrivers}>
            Водители
          </Icon>
        </Row>
        <HBox x={0.5} />
        <TitleText fontWeight="bold">Загрузка договоров</TitleText>
        <HBox />

        {!review ? (
          <UploadContractsForm onUploadSuccess={startReview} />
        ) : null}

        {review ? (
          <ContentCard>
            <Heading unsetReview={() => setReview(false)} />
            <Listing contractsList={contractsList} />
            {!contractsListLength ? (
              <ContentCardBody>
                <SubBodyText>Нет совпадений</SubBodyText>
              </ContentCardBody>
            ) : (
              <HBox />
            )}
          </ContentCard>
        ) : null}
      </Wrapper>
      {review ? (
        <ActionBottomPanel
          count={contractsListLength}
          countTitle="Совпало"
          isLoading={isFetchingCommit}
          doTitle="Подтвердить"
          onConfirm={onCommit}
          onCancel={() => setReview(false)}
          cancelTitle="Выбрать другой файл"
          cancelIcon="table"
        />
      ) : null}
    </>
  )
}
