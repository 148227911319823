import 'css.escape'

import axios, { AxiosError } from 'axios'
import Alert from 'react-s-alert'

import { history, store } from 'store'
import { RoutesPath } from 'common/enums'
import { ApiCode } from 'common/types/api/codes'
import { UpdateTokenResponse } from 'common/types/api/responses/auth'
import { setEngineeringWorks, setServerError } from 'shared/router/actions'
import { ModulErrorCode } from 'api/park/modul/types'
import { AuthTokens, useJwtAuth } from 'jwtAuth'
import { apm } from '@elastic/apm-rum'

apm.init({
  serverUrl: process.env.REACT_APP_ELASTIC_APM_URL,
  serviceName: process.env.REACT_APP_ELASTIC_APM_NAME,
  environment: process.env.NODE_ENV,
})

axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.defaults.headers['X-App-ID'] = process.env.REACT_APP_X_APP_ID

const refreshTokenAxios = axios.create()

// TODO: add this in layout
// const fuelSummary = async () => {
//   try {
//     await store.dispatch(apiActions.fuel.getFuelSummary.handleRequest() as any)
//   } catch (e) {
//     console.error(e)
//   }
// }

export function transformToAuthTokens(data: string) {
  try {
    const updateTokenResponse: UpdateTokenResponse = JSON.parse(data)
    return {
      accessToken: updateTokenResponse.access,
      refreshToken: updateTokenResponse.refresh,
    } as AuthTokens
  } catch (error) {
    throw Error(
      `[requestClient] Error parsing response JSON data - ${JSON.stringify(
        error
      )}`
    )
  }
}

// define token refresh function
const makeRefreshRequest = (token: string) => {
  return refreshTokenAxios.post<AuthTokens>(
    'auth/1/refresh',
    {
      refresh: token,
    },
    {
      transformResponse: transformToAuthTokens,
    }
  )
}

// eslint-disable-next-line react-hooks/rules-of-hooks
useJwtAuth(axios, {
  requestRefresh: makeRefreshRequest,
  header: 'X-Access-JWT',
  headerPrefix: '',
})

axios.interceptors.response.use(undefined, async (error: AxiosError) => {
  if (!error?.response) {
    return Promise.reject(error)
  }

  if (
    (!error.response && !axios.isCancel(error)) ||
    error.response?.status === 504
  ) {
    store.dispatch(setServerError(true))
  } else {
    const alertText = (text?: string) =>
      Alert.error('Ошибка', {
        customFields: {
          text:
            text ||
            'При обработке запроса произошла ошибка. ' +
              'Проверьте данные и попробуйте еще раз',
        },
      })

    const alertMessage = () =>
      Alert.error('Ошибка', {
        customFields: {
          text: error?.response?.data?.message,
        },
      })

    switch (error?.response?.status) {
      case 500:
        if (error.response.data.code === ApiCode.TechnicalError) {
          const { title, message } = error.response.data
          store.dispatch(setEngineeringWorks({ title, message }))
        }
        break
      case 400:
        if (
          error.response.data.code === ApiCode.InputValidationError ||
          error.response.data.code === ApiCode.EdgvrApiKeyIncorrect
        ) {
          break
        }

        alertMessage()
        break

      case 401: {
        history.replace(RoutesPath.SignOut)
        alertText('Сессия истекла')

        break
      }

      case 403:
        alertText('Доступ запрещен')
        break

      case 404:
        if (error.response.data.code === ApiCode.CertificateDetailsNotFound) {
          Alert.error('Напоминание', {
            customFields: {
              text: 'Сертификат безопасности отсутствует',
            },
          })
          break
        }
        if (error.response.data.code === ApiCode.FuelSummaryNotFound) {
          break
        }
        if (error.response.data.code === ApiCode.CloudCashboxNotFound) {
          break
        }
        if (error.response.data.code === ApiCode.ReferralOfferNotFound) {
          break
        }

        alertText('Ресурс не найден')
        break

      case 409:
        switch (error.response.data.code) {
          case ApiCode.ParkNotConfirmed:
            history.replace(RoutesPath.EmailConfirmation)
            break
          case ApiCode.OrganizationDetailsAreEmpty:
            history.replace(RoutesPath.Steps)
            break
          case ApiCode.EmployeeIsInactive:
            history.replace(RoutesPath.SignOut)
            alertMessage()
            break
          default:
            alertMessage()
        }
        break

      case 520:
        if (
          error.response.data.code === ApiCode.QiwiCredentialsAlreadyExist ||
          error.response.data.code === ApiCode.QiwiCredentialsNotFound
        ) {
          alertMessage()
          break
        }

        if (error.response?.data.code === ModulErrorCode.ModulTokensNotFound) {
          break
        }

        if (
          error.response?.data.code ===
            ModulErrorCode.ModulBankAccountNotFoundError ||
          error.response?.data.code === ModulErrorCode.ModulBankError
        ) {
          alertMessage()
          break
        }

        alertText()
        break

      default:
        alertText()
    }
  }

  return Promise.reject(error)
})
