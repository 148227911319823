import React from 'react'
import { Icon, ColorButton } from 'ui-kit/components'
import useSWR from 'swr'
import { VBox } from 'ui-kit/atoms/spacers'
import styled from 'styled-components'
import Typography from 'ui-kit/typography'

type Props = {
  onClick: () => Promise<void>
  isOrderRequest?: boolean
}

const HelpText = styled(Typography.withComponent('div')).attrs({
  variant: 'Caption1',
})`
  margin-top: 8px;
`

export const IncomeInfoButton = ({ onClick }: Props) => {
  const [isFetching, setIsFetching] = React.useState(false)
  const { data, mutate } = useSWR('IncomeInfoButton')
  const [isMore, setIsMore] = React.useState(false)

  const handleClick = React.useCallback(async () => {
    setIsFetching(true)
    await onClick()
    await mutate(true)
    setIsFetching(false)
  }, [mutate, onClick])

  const handleClickMore = React.useCallback(async () => {
    setIsFetching(true)
    await mutate(false)
    await new Promise((resolve) => setTimeout(resolve, 1000))
    setIsMore(true)
    await mutate(true)
    setIsFetching(false)
  }, [mutate])

  return (
    <>
      <ColorButton
        fullWidth
        isLoading={isFetching}
        fontWeight="semi-bold"
        color={data ? 'uiGreen' : undefined}
        onClick={data ? handleClickMore : handleClick}
      >
        {data ? (
          <>
            <Icon name="check" color="white" />
            <VBox x={1 / 3} />
          </>
        ) : null}
        {data ? 'Заявка отправлена' : 'Заказать'}
      </ColorButton>

      {data && (
        <HelpText>
          {isMore && 'Вы уже отправили заявку. '}
          Менеджер свяжется с вами в ближайшее время.
        </HelpText>
      )}
    </>
  )
}
export default IncomeInfoButton
