import React, { useState } from 'react'
import {
  ContentCard,
  ContentCardBody,
  ContentCardHeader,
  HBox,
} from 'shared/ui'
import {
  CaptionText,
  ColorButton as DefaultColorButton,
} from 'ui-kit/components'
import styled from 'styled-components'

type Props = {
  onClick: () => Promise<void>
}

const ColorButton = styled(DefaultColorButton)`
  align-self: baseline;
`

export const DisableNotificationsTelegramBot: React.FC<Props> = ({
  onClick,
}) => {
  const [isFetch, setIsFetch] = useState(false)

  const handleClick = async () => {
    setIsFetch(true)
    await onClick()
    setIsFetch(false)
  }

  return (
    <ContentCard withMinHeight={false}>
      <ContentCardHeader title="Telegram" />
      <ContentCardBody>
        <CaptionText color="textSecondary">
          Уведомления из ERP приходят в Telegram
        </CaptionText>
        <HBox x={2 / 3} />
        <ColorButton
          color="uiRed"
          onClick={handleClick}
          isLoading={isFetch}
          disabled={isFetch}
        >
          Отключить
        </ColorButton>
      </ContentCardBody>
    </ContentCard>
  )
}
