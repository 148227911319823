// @flow
import React from 'react'
import * as placeholders from 'ui-kit/static/placeholders'
import { PlaceholderNames } from 'ui-kit'
import styled from 'styled-components'
import Typography from 'ui-kit/typography'

type Props = {
  children: React.ReactNode
  placeholderName?: PlaceholderNames
}
const Wrapper = styled.div`
  padding-top: 136px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Placeholder = styled.div`
  margin-bottom: 24px;
`
const Description = styled(Typography).attrs({
  variant: 'Body1',
  fontColor: 'textDisabled',
})`
  text-align: center;
`

export const EmptyData = ({ placeholderName, children }: Props) => {
  const PlaceholderImage =
    placeholderName !== undefined && placeholders[placeholderName]
  return (
    <Wrapper>
      {PlaceholderImage && (
        <Placeholder>
          <PlaceholderImage />
        </Placeholder>
      )}
      <Description>{children}</Description>
    </Wrapper>
  )
}
export default React.memo(EmptyData)
