import React from 'react'

import { BodyText } from 'ui-kit/components/Typography'
import { Wrapper } from 'ui-kit/components/ListText/styles'

interface Props {
  className?: string
}

export const ListText: React.FC<Props> = ({ className, children }) => {
  return (
    <Wrapper className={className}>
      <BodyText>{children}</BodyText>
    </Wrapper>
  )
}
