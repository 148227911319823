import React, { useEffect, useRef, useState } from 'react'
import {
  SubBodyText as DefaultSubBodyText,
  SubTitleText as DefaultSubTitleText,
  TransparentButton,
} from 'ui-kit/components'
import { Link } from 'ui-kit/atoms/Link'
import { ContentCard, ContentCardBody } from 'shared/ui/contentCard'
import { PostOrPage } from '@tryghost/content-api'
import { HBox, VBox } from 'shared/ui/spacers'
import styled, { css } from 'styled-components'
import bg from './background.svg'

type Props = {
  content: PostOrPage
  onRegistration?: () => Promise<void>
  onConnect?: () => void
}

const Wrapper = styled.div`
  padding: ${({ theme }) => theme.paddings.main}px;
  position: relative;
  margin-bottom: 213px;
`

const GhostHtml = styled.div`
  @charset "UTF-8";

  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  margin: 0;
  main {
    display: block;
  }
  h1 {
    font-size: 2em;
    margin: 0.67em 0;
  }
  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }
  pre {
    font-family: monospace, monospace;
    font-size: 1em;
  }
  a {
    background-color: transparent;
  }
  abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
  }
  b,
  strong {
    font-weight: bolder;
  }
  code,
  kbd,
  samp {
    font-family: monospace, monospace;
    font-size: 1em;
  }
  small {
    font-size: 80%;
  }
  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  sub {
    bottom: -0.25em;
  }
  sup {
    top: -0.5em;
  }
  img {
    border-style: none;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }
  button,
  input {
    overflow: visible;
  }
  button,
  select {
    text-transform: none;
  }
  [type='button'],
  [type='reset'],
  [type='submit'],
  button {
    -webkit-appearance: button;
  }
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner,
  button::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }
  [type='button']:-moz-focusring,
  [type='reset']:-moz-focusring,
  [type='submit']:-moz-focusring,
  button:-moz-focusring {
    outline: 1px dotted ButtonText;
  }
  fieldset {
    padding: 0.35em 0.75em 0.625em;
  }
  legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
  }
  progress {
    vertical-align: baseline;
  }
  textarea {
    overflow: auto;
  }
  [type='checkbox'],
  [type='radio'] {
    box-sizing: border-box;
    padding: 0;
  }
  [type='number']::-webkit-inner-spin-button,
  [type='number']::-webkit-outer-spin-button {
    height: auto;
  }
  [type='search'] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }
  [type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }
  details {
    display: block;
  }
  summary {
    display: list-item;
  }
  [hidden],
  template {
    display: none;
  }
  .kg-bookmark-thumbnail img,
  .u-absolute0 {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .button,
  .godo-n-q-i,
  .godo-ne,
  .godo-ne-input,
  .kg-bookmark-container,
  body,
  figcaption,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: Roboto, Whitney SSm A, Whitney SSm B, -apple-system,
      BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Open Sans,
      Helvetica Neue, sans-serif;
  }
  .checkout-title,
  .f-subscribe-left,
  .godo-n-q blockquote,
  .more-link,
  .post-excerpt,
  .post-inner,
  .sf-cover-title,
  .story-excerpt,
  .story-travel-title {
    font-family: Merriweather, Mercury SSm A, Mercury SSm B, Georgia, serif;
  }
  .kg-bookmark-description,
  .prev-next-title,
  .u-lineClamp2 {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .col-right,
  .godo-n-q,
  .kg-bookmark-container,
  .kg-bookmark-metadata,
  .kg-gallery-container,
  .kg-gallery-row,
  .mainMenu > ul,
  .row,
  .sticky-bottom-wrap,
  .sticky-parent,
  .sticky-top-wrap,
  .u-flex,
  .u-flexColumn,
  .u-flexColumnCenter {
    display: -webkit-box;
    display: flex;
  }
  .sticky-bottom-wrap,
  .sticky-top-wrap,
  .u-flex--1 {
    -webkit-box-flex: 1;
    flex: 1;
  }
  .col-right,
  .godo-n-q-i,
  .kg-embed-card,
  .kg-gallery-container,
  .post-inner,
  .sticky-bottom-wrap,
  .sticky-parent,
  .sticky-top-wrap,
  .u-flexColumn,
  .u-flexColumnCenter {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
  .kg-gallery-row,
  .row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
  }
  .godo-ne-form-group,
  .justify-content-center,
  .kg-gallery-row,
  .title-line,
  .u-flexColumn {
    -webkit-box-pack: center;
    justify-content: center;
  }
  .sticky-parent,
  .sticky-top-wrap {
    -webkit-box-pack: start;
    justify-content: flex-start;
  }
  .sticky-bottom-wrap {
    -webkit-box-pack: end;
    justify-content: flex-end;
  }
  .godo-n-q-i,
  .godo-ne-form-group,
  .kg-bookmark-metadata,
  .kg-embed-card,
  .post-inner,
  .title-line,
  .u-flexCenter,
  .u-flexColumnCenter {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
  }
  .search {
    opacity: 0;
    -webkit-transition: opacity 0.2s ease-out 0.1s, visibility 0s 0.4s;
    transition: opacity 0.2s ease-out 0.1s, visibility 0s 0.4s;
    z-index: 100;
    visibility: hidden;
  }
  .search-shader {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .search-inner {
    opacity: 0;
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    -webkit-transition: opacity 0.3s cubic-bezier(0.06, 0.47, 0.38, 0.99),
      -webkit-transform 0.3s cubic-bezier(0.06, 0.47, 0.38, 0.99);
    transition: opacity 0.3s cubic-bezier(0.06, 0.47, 0.38, 0.99),
      -webkit-transform 0.3s cubic-bezier(0.06, 0.47, 0.38, 0.99);
    transition: transform 0.3s cubic-bezier(0.06, 0.47, 0.38, 0.99),
      opacity 0.3s cubic-bezier(0.06, 0.47, 0.38, 0.99);
    transition: transform 0.3s cubic-bezier(0.06, 0.47, 0.38, 0.99),
      opacity 0.3s cubic-bezier(0.06, 0.47, 0.38, 0.99),
      -webkit-transform 0.3s cubic-bezier(0.06, 0.47, 0.38, 0.99);
    overflow: hidden;
  }
  :root {
    --composite-color: #00469b;
    --composite-color-hover: #487ec0;
    --header-color: #111;
    --header-color-hover: #555;
    --header-menu-color: #111;
    --header-menu-color-hover: #555;
    --header-bg-color: #fff;
    --header-logo-color: #000;
    --newsletter-color: #00469b;
    --gray--500: #84858a;
    --gray--600: #707177;
    --gray--900: #2d2e34;
    --text--secondary__Color: var(--gray--600);
    --text--title__Color: #111;
    --content-text__Color: var(--gray--900);
    --hr-bg: #ddd;
    --figcaption-color: rgba(0, 0, 0, 0.68);
    --q-color: rgba(0, 0, 0, 0.44);
    --input-bg-color: #fff;
    --input-color: #303030;
    --input-border-color: #ddd;
    --search-color-link: rgba(0, 0, 0, 0.7);
    --search-bg-link: rgba(0, 0, 0, 0.05);
    --search-bg-link-hover: #dedede;
    --search-color-message: #738a94;
    --sidebar-post-border-color: #e5eff5;
    --sidebar-post-bg: #f8f8f8;
    --border-bottom-color: rgba(0, 0, 0, 0.0785);
    --sidenav-bg: #eee;
    --sidenav-color: #111;
    --sidenav-border-color: #ddd;
    --story-color: #17181e;
    --story-footer-color: var(--text--secondary__Color);
    --story-content-color: var(--content-text__Color);
    --story-more-link-color: var(--gray--600);
    --story-more-link-border: rgba(28, 29, 33, 0.15);
    --story-title-color: var(--text--title__Color);
    --fashion-featured-bg: #fff;
    --drop-bg-color: #eee;
    --drop-color-link: #111;
    --drop-color-link-hover: #555;
    --drop-border-color: #ddd;
    --post-title-color: var(--text--title__Color);
    --blockquote-color: #252525;
    --post-excerpt-color: rgba(0, 0, 0, 0.6);
    --post-color-link: var(--composite-color);
    --post-link-border: #e2e1e1;
    --mobile-share-bg: #fff;
    --bookmark-bg: #fff;
    --bookmark-color: var(--text--title__Color);
    --bookmark-meta-color: rgba(0, 0, 0, 0.84);
    --bookmark-border-color: rgba(0, 0, 0, 0.1);
    --bookmark-color-des: var(--gray--500);
    --member-plan-bg: #fff;
    --member-plan-border: #e6e8eb;
    --member-color: #212529;
    --archive-author-bg-img: #ddd;
  }
  html[data-theme='dark'] {
    --text--title__Color: #eee;
    --content-text__Color: #888;
    --text--secondary__Color: #888;
    --header-color: #eee;
    --header-color-hover: #bbb;
    --header-menu-color: #eee;
    --header-menu-color-hover: #bbb;
    --header-bg-color: #222;
    --header-logo-color: #fff;
    --hr-bg: #444;
    --figcaption-color: #888;
    --q-color: #888;
    --input-bg-color: #444;
    --input-color: #eee;
    --input-border-color: #444;
    --search-color-link: #eee;
    --search-bg-link: #444;
    --search-bg-link-hover: #333;
    --search-color-message: #bbb;
    --sidebar-post-bg: #222;
    --sidebar-post-border-color: #444;
    --border-bottom-color: hsla(0, 0%, 100%, 0.1);
    --sidenav-bg: #333;
    --sidenav-color: #eee;
    --sidenav-border-color: #444;
    --story-color: #fff;
    --story-more-link-color: #888;
    --story-more-link-border: hsla(0, 0%, 62.7%, 0.15);
    --fashion-featured-bg: #222;
    --drop-bg-color: #333;
    --drop-color-link: #eee;
    --drop-color-link-hover: #bbb;
    --drop-border-color: #444;
    --blockquote-color: #fff;
    --post-excerpt-color: #888;
    --post-link-border: #444;
    --mobile-share-bg: #444;
    --bookmark-bg: #222;
    --bookmark-meta-color: #eee;
    --bookmark-border-color: #444;
    --member-plan-bg: #222;
    --member-plan-border: #444;
    --member-color: #eee;
    --archive-author-bg-img: #888;
  }
  html[data-theme='dark'] body {
    background-color: #222;
    color: #eee;
  }
  html[data-theme='dark'] .header {
    border-bottom: 1px solid #333;
  }
  html[data-theme='dark'] .header-dark-mode .icon--moon {
    display: none;
  }
  html[data-theme='dark'] .header-dark-mode .icon--sunny {
    display: inline-block !important;
  }
  html[data-theme='dark'] .u-bgGray {
    background-color: #2b2b2b;
  }
  html[data-theme='dark'] .u-textColorNormal {
    color: #bbb;
    fill: #bbb;
  }
  html[data-theme='dark'] .u-textColorDarker {
    color: #ccc;
    fill: #ccc;
  }
  html[data-theme='dark'] .button--line {
    border-color: #2b2b2b;
    background: #2b2b2b;
    color: #fff;
  }
  html[data-theme='dark'] .witget-subscribe {
    background-color: #222;
    border-color: #444;
    color: #eee;
  }
  html[data-theme='dark'] .author-bio {
    color: #eee;
  }
  html[data-theme='dark'] .title-line:after,
  html[data-theme='dark'] .title-line:before {
    border-top-color: #444;
  }
  html[data-theme='dark'] .hr-list {
    border-top-color: hsla(0, 0%, 100%, 0.07);
  }
  html[data-theme='dark'] .search-message {
    border-color: #555;
  }
  html[data-theme='dark'] .search-close {
    background-color: #444;
  }
  html[data-theme='dark'] .post-inner {
    color: #ccc;
  }
  html[data-theme='dark'] .post-inner strong {
    color: #fff;
  }
  html[data-theme='dark'] .post-related {
    background-color: #1e1e1e;
  }
  html[data-theme='dark'] .post-upgrade-cta {
    background-color: #333;
  }
  html[data-theme='dark'] .page-hero-bg {
    display: none;
  }
  html[data-theme='dark'] .page-hero-wrap {
    color: #eee;
  }
  html[data-theme='dark'] .author-follow .button {
    background-color: #666;
    color: #eee;
  }
  html[data-theme='dark'] .author-card-media .button {
    border-color: #eee;
  }
  html[data-theme='dark'] .is-contact .contact-stain {
    display: none;
  }
  html[data-theme='dark'] .godo-ne {
    background-color: #222;
    border-color: #444;
  }
  html[data-theme='dark'] .ne-t:before {
    background-color: #444;
  }
  *,
  :after,
  :before,
  html {
    box-sizing: border-box;
  }
  body,
  html {
    font-size: 1rem;
  }
  body {
    color: #212529;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.4;
    margin: 0 auto;
    text-rendering: optimizeLegibility;
    overflow-x: hidden;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  a:active,
  a:focus,
  a:hover {
    outline: 0;
  }
  blockquote {
    position: relative;
    margin: 20px 0;
    padding: 20px 20px 0;
    color: var(--blockquote-color);
    font-size: 1.5rem;
    line-height: 1.44;
    font-weight: 500;
    text-align: center;
    font-style: italic;
    font-family: Georgia, Merriweather, serif;
  }
  blockquote:before {
    z-index: 0;
    position: absolute;
    margin: 0;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(5px) rotate(1deg);
    transform: translateX(-50%) translateY(5px) rotate(1deg);
    color: #6a737d;
    opacity: 0.4;
    font-size: 10rem;
    font-family: Georgia, Arial, sans-serif;
    font-style: normal;
    line-height: 0.4;
    content: '\\201c';
  }
  blockquote p:first-of-type {
    margin-top: 0;
  }
  figure {
    margin: 0;
  }
  figcaption {
    color: var(--figcaption-color);
    display: block;
    font-feature-settings: 'liga' on, 'lnum' on;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: 1.4;
    margin-top: 10px;
    outline: 0;
    position: relative;
    text-align: center;
    top: 0;
    width: 100%;
  }
  hr:not(.hr-list) {
    margin: 40px auto 10px;
    height: 1px;
    background-color: var(--hr-bg);
    border: 0;
    max-width: 100%;
  }
  .post-footer-hr {
    margin: 32px 0;
  }
  img {
    height: auto;
    max-width: 100%;
    vertical-align: middle;
    width: auto;
  }
  img:not([src]) {
    visibility: hidden;
  }
  i {
    vertical-align: middle;
  }
  ol,
  ul {
    list-style: none;
    list-style-image: none;
    margin: 0;
    padding: 0;
  }
  mark {
    background-color: transparent !important;
    background-image: linear-gradient(180deg, #d7fdd3, #d7fdd3);
    color: rgba(0, 0, 0, 0.8);
  }
  q {
    color: var(--q-color);
    display: block;
    font-size: 1.75rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.48;
    padding-left: 50px;
    padding-top: 15px;
    text-align: left;
  }
  q:after,
  q:before {
    display: none;
  }
  input,
  textarea {
    background: var(--input-bg-color);
    border-radius: 3px;
    color: var(--input-color);
    display: block;
    font-size: 1rem;
    margin: 0;
    outline: none;
    padding: 0 10px;
    position: relative;
    -webkit-transition: box-shadow 0.08s ease-in, color 0.08s ease-in;
    transition: box-shadow 0.08s ease-in, color 0.08s ease-in;
    width: 100%;
    border: 1px solid var(--input-border-color);
  }
  textarea {
    min-height: 160px;
    padding: 15px 10px;
  }
  input {
    height: 44px;
    line-height: 2.75rem;
  }
  .input--md {
    height: 37px;
    line-height: 2.3125rem;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
    display: inline-block;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial,
      sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    font-size: 1rem;
    line-height: 1.5;
    margin: 20px 0 0;
    max-width: 100%;
    overflow-x: auto;
    vertical-align: top;
    white-space: nowrap;
    width: auto;
    -webkit-overflow-scrolling: touch;
  }
  table td,
  table th {
    padding: 6px 13px;
    border: 1px solid #dfe2e5;
  }
  table tr:nth-child(2n) {
    background-color: #f6f8fa;
  }
  table th {
    letter-spacing: 0.0125rem;
    text-transform: uppercase;
    font-weight: 600;
  }
  .link {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
  }
  .link--underline:active,
  .link--underline:focus,
  .link--underline:hover {
    text-decoration: underline;
  }
  .site-wrapper {
    min-height: 100vh;
  }
  .site-main {
    -webkit-box-flex: 1;
    flex-grow: 1;
  }
  .site-main.pb4 {
    padding-bottom: 4rem;
  }
  .bg-facebook {
    background-color: #3b5998 !important;
  }
  .bg-twitter {
    background-color: #1277c9 !important;
  }
  .bg-linkedin {
    background-color: #007bb6 !important;
  }
  .bg-reddit {
    background-color: #ff4500 !important;
  }
  .bg-pocket {
    background-color: #f50057 !important;
  }
  .bg-pinterest {
    background-color: #bd081c !important;
  }
  .bg-whatsapp {
    background-color: #64d448 !important;
  }
  .c-facebook {
    color: #3b5998;
  }
  .c-twitter {
    color: #55acee;
  }
  .back-to-top {
    background: rgba(0, 0, 0, 0.3);
    border: 2px solid #fff;
    color: #fff;
    cursor: pointer;
    height: 50px;
    opacity: 1;
    position: fixed;
    right: 0;
    top: 50%;
    -webkit-transform: translate3d(100px, 0, 0);
    transform: translate3d(100px, 0, 0);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    width: 50px;
    z-index: 5;
  }
  .back-to-top:hover {
    background: rgba(0, 0, 0, 0.5);
  }
  .back-to-top.to-top {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  .back-to-top .icon--arrow-up {
    height: 40px;
    width: 40px;
  }
  svg {
    height: auto;
    width: 100%;
  }
  .loadingBar {
    background-color: #48e79a;
    display: none;
    height: 2px;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    z-index: 800;
  }
  .is-loading .loadingBar {
    -webkit-animation: e 1s ease-in-out infinite;
    animation: e 1s ease-in-out infinite;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    display: block;
  }
  .blur-up {
    -webkit-animation: i 3s ease-out;
    animation: i 3s ease-out;
  }
  .video-responsive {
    display: block;
    height: 0;
    overflow: hidden;
    padding: 0 0 56.25%;
    position: relative;
    width: 100%;
  }
  .video-responsive iframe,
  .video-responsive video {
    border: 0;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .notification {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 9000;
    padding: 20px 60px;
    color: #fff;
    font-size: 1.125rem;
    text-align: center;
    background: #98c22e;
    -webkit-transition: all 0.35s cubic-bezier(0.19, 1, 0.22, 1) 0.5s;
    transition: all 0.35s cubic-bezier(0.19, 1, 0.22, 1) 0.5s;
    -webkit-transform: translateY(-175%);
    transform: translateY(-175%);
    visibility: hidden;
  }
  .notification svg {
    right: 25px;
    top: 20px;
  }
  .checkout-success .notification-checkout,
  .signin-success .notification-signin,
  .signup-success .notification-signup,
  .subscribe-success .notification-subscribe {
    visibility: visible;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  .notification.closed {
    visibility: hidden;
    -webkit-transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transform: translateY(-175%);
    transform: translateY(-175%);
  }
  .mapache-light-gallery {
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: inherit;
    font-weight: 700;
    line-height: 1.1;
    margin: 0;
  }
  h1 a,
  h2 a,
  h3 a,
  h4 a,
  h5 a,
  h6 a {
    color: inherit;
    line-height: inherit;
  }
  h1 {
    font-size: 2.75rem;
  }
  h2 {
    font-size: 2.125rem;
  }
  h3 {
    font-size: 1.875rem;
  }
  h4 {
    font-size: 1.625rem;
  }
  h5 {
    font-size: 1.5rem;
  }
  h6 {
    font-size: 1.25rem;
  }
  p {
    margin: 0;
  }
  .u-textColorNormal {
    color: #757575;
    fill: #757575;
  }
  .u-textColorWhite {
    color: #fff !important;
    fill: #fff !important;
  }
  .u-textColorDarker {
    color: rgba(0, 0, 0, 0.8);
    fill: rgba(0, 0, 0, 0.8);
  }
  .text-muted {
    color: #888;
  }
  .text-success {
    color: var(--composite-color);
  }
  .u-relative {
    position: relative;
  }
  .u-absolute {
    position: absolute;
  }
  .u-fixed {
    position: fixed !important;
  }
  .u-block {
    display: block !important;
  }
  .u-inlineBlock {
    display: inline-block;
  }
  .u-hide {
    display: none !important;
  }
  .u-bgDark {
    background-color: #110f16;
  }
  .u-backgroundWhite {
    background-color: #fafafa;
  }
  .u-bgGray {
    background-color: #f0f0f0;
  }
  .u-bgGradient {
    background: #391f69;
    background-image: linear-gradient(
      91.1deg,
      #391f69 -2.3%,
      #732b9b 44.4%,
      #cc116e 103.4%
    );
  }
  .u-bgOpacity {
    background: #000;
    opacity: 0.5;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .u-bgCover {
    background-origin: border-box;
    background-position: 50%;
    background-size: cover;
  }
  .u-pointer {
    cursor: pointer;
  }
  .zindex1 {
    z-index: 1;
  }
  .zindex2 {
    z-index: 2;
  }
  .zindex3 {
    z-index: 3;
  }
  .zindex4 {
    z-index: 4;
  }
  .u-clear:after {
    content: '';
    display: table;
    clear: both;
  }
  .u-fontSizeMicro {
    font-size: 0.6875rem;
  }
  .u-fontSizeSmallest {
    font-size: 0.75rem;
  }
  .u-fontSize13 {
    font-size: 0.8125rem;
  }
  .u-fontSizeSmaller {
    font-size: 0.875rem;
  }
  .u-fontSize15 {
    font-size: 0.9375rem;
  }
  .u-fontSizeSmall {
    font-size: 1rem;
  }
  .u-fontSizeBase {
    font-size: 1.125rem;
  }
  .u-fontSize20 {
    font-size: 1.25rem;
  }
  .u-fontSize21 {
    font-size: 1.3125rem;
  }
  .u-fontSize22 {
    font-size: 1.375rem;
  }
  .u-fontSizeLarge {
    font-size: 1.5rem;
  }
  .u-fontSize26 {
    font-size: 1.625rem;
  }
  .u-fontSize28 {
    font-size: 1.75rem;
  }
  .u-fontSizeLarger {
    font-size: 2rem;
  }
  .u-fontSize36 {
    font-size: 2.25rem;
  }
  .u-fontSize40 {
    font-size: 2.5rem;
  }
  .u-fontSizeLargest {
    font-size: 2.75rem;
  }
  .u-fontSizeJumbo {
    font-size: 3.75rem;
  }
  .u-fontWeightThin {
    font-weight: 300;
  }
  .u-fontWeightNormal {
    font-weight: 400;
  }
  .u-fontWeightMedium {
    font-weight: 500;
  }
  .u-fontWeightSemibold {
    font-weight: 600;
  }
  .u-fontWeightBold {
    font-weight: 700;
  }
  .u-textUppercase {
    text-transform: uppercase;
  }
  .u-textCapitalize {
    text-transform: capitalize;
  }
  .u-textAlignCenter {
    text-align: center;
  }
  .u-fontItalic {
    font-style: italic;
  }
  .u-textShadow {
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.33);
  }
  .extreme-container,
  .u-marginAuto {
    margin-left: auto;
    margin-right: auto;
  }
  .u-marginTop20 {
    margin-top: 20px;
  }
  .u-marginTop30 {
    margin-top: 30px;
  }
  .u-marginBottom10 {
    margin-bottom: 10px;
  }
  .u-marginBottom15 {
    margin-bottom: 15px;
  }
  .u-marginBottom20 {
    margin-bottom: 20px;
  }
  .u-marginBottom30 {
    margin-bottom: 30px;
  }
  .u-marginBottom40 {
    margin-bottom: 40px;
  }
  .u-padding0 {
    padding: 0 !important;
  }
  .u-padding20 {
    padding: 20px;
  }
  .u-padding15 {
    padding: 15px !important;
  }
  .u-paddingLeft15 {
    padding-left: 15px;
  }
  .u-paddingLeft20 {
    padding-left: 20px;
  }
  .u-paddingRight10 {
    padding-right: 10px;
  }
  .u-paddingRight20 {
    padding-right: 20px;
  }
  .u-paddingBottom15 {
    padding-bottom: 15px;
  }
  .u-paddingBottom30 {
    padding-bottom: 30px;
  }
  .u-paddingBottom20 {
    padding-bottom: 20px;
  }
  .u-paddingTop2 {
    padding-top: 2px;
  }
  .u-paddingTop5 {
    padding-top: 5px;
  }
  .u-paddingTop10 {
    padding-top: 10px;
  }
  .u-paddingTop15 {
    padding-top: 15px;
  }
  .u-paddingTop20 {
    padding-top: 20px;
  }
  .u-paddingTop30 {
    padding-top: 30px;
  }
  .u-lineHeight1 {
    line-height: 1;
  }
  .u-lineHeightTight {
    line-height: 1.2;
  }
  .u-overflowHidden {
    overflow: hidden;
  }
  .u-floatRight {
    float: right;
  }
  .u-floatLeft {
    float: left;
  }
  .u-flexContentAround {
    justify-content: space-around;
  }
  .u-flexContentBetween {
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .u-flex1 {
    -webkit-box-flex: 1;
    flex: 1 1 auto;
  }
  .u-flex0 {
    -webkit-box-flex: 0;
    flex: 0 0 auto;
  }
  .kg-bookmark-content,
  .u-flexColumnTop {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: start;
    justify-content: flex-start;
  }
  .u-flexEnd {
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: end;
    justify-content: flex-end;
  }
  .kg-bookmark-thumbnail img,
  .u-image {
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  .u-container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
  }
  .u-maxWidth1200 {
    max-width: 1200px;
  }
  .u-maxWidth1000 {
    max-width: 1000px;
  }
  .u-maxWidth740 {
    max-width: 740px;
  }
  .u-maxWidth1040 {
    max-width: 1040px;
  }
  .u-sizeFullWidth {
    width: 100%;
  }
  .u-sizeFullHeight {
    height: 100%;
  }
  .u-round {
    border-radius: 50%;
  }
  .u-borderRadius2 {
    border-radius: 2px;
  }
  .u-border-bottom {
    border-bottom: 1px solid var(--border-bottom-color);
  }
  .u-boxShadowBottom {
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.05);
  }
  .title-line {
    text-transform: uppercase;
    letter-spacing: 0.04em;
    font-weight: 500;
  }
  .title-line:after,
  .title-line:before {
    content: '';
    border-top: 1px solid #d5d5d5;
    margin: 0 15px 0 0;
    -webkit-box-flex: 1;
    flex: 1 0 0;
  }
  .title-line:after {
    margin: 0 0 0 13px;
  }
  .u-oblique {
    background-color: var(--composite-color);
    color: #fff;
    display: inline-block;
    font-size: 0.9375rem;
    font-weight: 500;
    letter-spacing: 0.03em;
    line-height: 1;
    padding: 5px 13px;
    text-transform: uppercase;
    -webkit-transform: skewX(-15deg);
    transform: skewX(-15deg);
  }
  .u-lineClamp2 {
    line-height: 1.1;
    max-height: 2.3em;
  }
  .extreme-container {
    box-sizing: border-box;
    max-width: 1232px;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
  }
  .post-fluid {
    max-width: 1650px;
  }
  .col-left {
    width: 100%;
  }
  .col-right {
    width: 342px;
    margin-left: auto;
  }
  .row {
    flex-wrap: wrap;
    flex: 0 1 auto;
    margin-left: -16px;
    margin-right: -16px;
  }
  .row,
  .row .col {
    -webkit-box-flex: 0;
  }
  .row .col {
    flex: 0 0 auto;
    box-sizing: border-box;
    padding-left: 16px;
    padding-right: 16px;
  }
  .row .col.s1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }
  .row .col.s2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%;
  }
  .row .col.s3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .row .col.s4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%;
  }
  .row .col.s5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%;
  }
  .row .col.s6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .row .col.s7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%;
  }
  .row .col.s8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%;
  }
  .row .col.s9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .row .col.s10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%;
  }
  .row .col.s11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%;
  }
  .row .col.s12 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .row.no-gutters {
    margin-left: 0;
    margin-right: 0;
  }
  .row.no-gutters .col {
    padding-left: 0;
    padding-right: 0;
  }
  .header {
    background-color: var(--header-bg-color);
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);
    height: 60px;
    padding: 0 16px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;
  }
  .header-logo {
    color: var(--header-logo-color);
    height: 30px;
  }
  .header-logo img {
    height: 100%;
  }
  .mainMenu {
    position: relative;
    overflow: hidden;
  }
  .mainMenu > ul {
    -webkit-box-flex: 1;
    flex: 1;
    margin-right: 20px;
    overflow: hidden;
    white-space: nowrap;
  }
  .header-left .hla,
  .mainMenu > ul li a {
    display: inline-block;
    font-weight: 500;
    padding: 0 8px;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    letter-spacing: 0.02em;
    line-height: 3.75rem;
    cursor: pointer;
  }
  .mainMenu > ul li a {
    color: var(--header-menu-color);
    font-size: 0.9375rem;
  }
  .mainMenu > ul li.nav-current a,
  .mainMenu > ul li a:hover {
    color: var(--header-menu-color-hover);
  }
  .header-left .hla {
    color: var(--header-color);
  }
  .header-left .hla:hover {
    color: var(--header-color-hover);
  }
  .menu-burger {
    height: 48px;
    position: relative;
    -webkit-transition: -webkit-transform 0.4s;
    transition: -webkit-transform 0.4s;
    transition: transform 0.4s;
    transition: transform 0.4s, -webkit-transform 0.4s;
    width: 48px;
  }
  .menu-burger span {
    background-color: var(--header-color);
    display: block;
    height: 2px;
    left: 14px;
    margin-top: -1px;
    position: absolute;
    top: 50%;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    width: 20px;
  }
  .menu-burger span:first-child {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  .menu-burger span:last-child {
    -webkit-transform: translateY(6px);
    transform: translateY(6px);
  }
  .header-social-media > a {
    flex-basis: 100%;
    text-align: center;
    padding: 5px;
  }
  .drop-content {
    background: var(--drop-bg-color);
    box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.05);
    display: none;
    margin: 0;
    min-width: 430px;
    padding: 20px;
    right: -24px;
    top: 100%;
    z-index: 11;
  }
  .drop-content a {
    color: var(--drop-color-link);
  }
  .drop-content a:hover {
    color: var(--drop-color-link-hover);
  }
  .drop-content .header-social-media .icon {
    height: 26px;
    width: 26px;
  }
  .drop-border {
    border-bottom: 1px solid var(--drop-border-color);
  }
  .dropdown:before {
    bottom: 0;
    content: '';
    display: none;
    height: 0;
    left: 50%;
    margin-left: -10px;
    position: absolute;
    -webkit-transition: opacity 0.03s linear, visibility 0.03s linear;
    transition: opacity 0.03s linear, visibility 0.03s linear;
    width: 0;
    border: 10px solid transparent;
    border-bottom: 10px solid var(--drop-bg-color);
  }
  .dropdown:hover .drop-content,
  .dropdown:hover:before {
    display: block;
    -webkit-animation: j 0.4s cubic-bezier(0.73, 0.005, 0.22, 1);
    animation: j 0.4s cubic-bezier(0.73, 0.005, 0.22, 1);
  }
  .account-menu {
    min-width: 240px;
  }
  .account-menu .login {
    padding: 12px 0 18px;
  }
  .footer {
    background-color: #111;
    padding: 5vw 0;
  }
  .footer,
  .footer a {
    color: #9ba1a6;
  }
  .footer a:hover {
    color: #eee;
  }
  .footer-divider {
    background-color: #444 !important;
    margin: 1.5rem 0 !important;
  }
  .footer .icon--favorite {
    height: 14px;
    width: 14px;
    color: red;
  }
  .footer-menu > ul li {
    display: inline-block;
    margin: 5px 1rem 5px 0;
  }
  .footer-social-media a {
    margin-right: 1rem;
  }
  .f-subscribe-left p {
    color: #ddd;
  }
  .f-form-input {
    -webkit-box-flex: 1;
    flex: 1;
    margin-right: 20px;
    background-color: #222;
    color: #bbb;
    border-color: transparent;
  }
  .f-form .button {
    width: 115px;
  }
  .f-form.error .message-error,
  .f-form.invalid .message-error {
    color: #ec694b;
    display: block !important;
  }
  .f-form.success .message-success {
    color: #acd053;
    display: block !important;
  }
  .f-form.loading .button-text {
    display: none;
  }
  .f-form.loading .icon--loader {
    display: inline-block !important;
    -webkit-animation: d 0.8s linear infinite;
    animation: d 0.8s linear infinite;
  }
  .sidebar-title {
    margin-bottom: 30px;
    font-size: 0.875rem;
  }
  .sidebar-post {
    background-color: var(--sidebar-post-bg);
    border-bottom: 1px solid rgba(0, 0, 0, 0.0785);
    box-shadow: 0 1px 7px var(--border-bottom-color);
    min-height: 60px;
  }
  .sidebar-post h3 {
    padding: 10px;
  }
  .sidebar-post-border {
    border-left: 3px solid #cc116e;
    color: rgba(0, 0, 0, 0.2);
    padding: 0 10px;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #888;
  }
  .sidebar-post:hover .sidebar-post-border {
    background-color: var(--sidebar-post-border-color);
  }
  .sidebar-post:nth-child(3n) .sidebar-post-border {
    border-color: #f59e00;
  }
  .sidebar-post:nth-child(3n + 2) .sidebar-post-border {
    border-color: #26a8ed;
  }
  .mp-category-list li {
    margin-bottom: 5px;
  }
  .mp-category-bg {
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }
  .mp-category-count {
    background: #fff;
    color: #111;
    height: 35px;
    width: 35px;
  }
  .mp-category-link {
    min-height: 90px;
    padding: 20px;
    border-radius: 5px;
    overflow: hidden;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  .mp-category-link span {
    white-space: nowrap;
    letter-spacing: 0.02em;
    text-transform: capitalize;
  }
  .mp-category-link img {
    z-index: -2;
  }
  .mp-category-link .bar {
    border-bottom: 1px dashed hsla(0, 0%, 100%, 0.5);
    margin: 0 25px;
  }
  .mp-category-link:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    will-change: transform;
    -webkit-transition: -webkit-transform 0.35s
      cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: -webkit-transform 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: transform 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: transform 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94),
      -webkit-transform 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .swf.error .message-error,
  .swf.error .message-success,
  .swf.invalid .message-error,
  .swf.invalid .message-success,
  .swf.success .message-error,
  .swf.success .message-success {
    color: #fff;
    padding: 10px;
    margin-top: 10px;
  }
  .swf.error .message-error,
  .swf.invalid .message-error {
    background: #ec694b;
    display: block !important;
  }
  .swf.success .message-success {
    background: #acd053;
    display: block !important;
  }
  .swf.loading .button-text {
    display: none;
  }
  .swf.loading .icon--loader {
    display: inline-block !important;
    -webkit-animation: d 0.8s linear infinite;
    animation: d 0.8s linear infinite;
  }
  .widget-twitter > div {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  .sideNav {
    background-color: var(--header-bg-color);
    color: var(--sidenav-color);
    height: 100vh;
    padding: 60px 20px;
    position: fixed !important;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: 0.4s;
    transition: 0.4s;
    will-change: transform;
    z-index: 8;
  }
  .sideNav-menu a {
    padding: 10px 0;
    display: block;
  }
  .sideNav-wrap {
    background: var(--sidenav-bg);
    overflow: auto;
    padding: 0 20px 20px;
    top: 60px;
  }
  .sideNav-item {
    border-bottom: 1px solid var(--sidenav-border-color);
    padding: 20px 0 19px;
  }
  .sideNav-follow > a {
    padding: 0 5px;
    display: block;
  }
  .sideNav-follow .icon {
    width: 24px;
    height: 24px;
  }
  .page-hero-wrap {
    padding: 40px 0;
    color: #343f44;
  }
  .page-hero-name {
    padding: 70px 0 60px;
  }
  .page-hero-content {
    line-height: 1.6;
  }
  .page-hero-content > * {
    margin-bottom: 1rem;
  }
  .page-hero-content a {
    box-shadow: inset 0 -2px 0 currentColor;
  }
  .button {
    background: rgba(0, 0, 0, 0.05);
    border: 1px solid transparent;
    color: rgba(0, 0, 0, 0.68);
    cursor: pointer;
    display: inline-block;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    height: 37px;
    letter-spacing: 0.02em;
    line-height: 2.1875rem;
    padding: 0 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-rendering: optimizeLegibility;
    -webkit-transition: background-color 0.1s, border-color 0.1s, color 0.1s,
      fill 0.1s;
    transition: background-color 0.1s, border-color 0.1s, color 0.1s, fill 0.1s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
  }
  .button:focus {
    outline: none;
  }
  .button .icon {
    position: relative;
    top: -1px;
  }
  .button--large {
    font-size: 1rem;
    height: 44px;
    line-height: 2.625rem;
    padding: 0 18px;
  }
  .button--dark {
    background: #000;
    border-color: #000;
    box-shadow: 3px 3px 16px rgba(0, 0, 0, 0.2);
    color: #fff;
  }
  .button--dark:hover {
    background: #3a3c3c;
    border-color: #3a3c3c;
    color: #fff;
  }
  .button--filled {
    color: #fff;
    background: var(--composite-color);
    border-color: var(--composite-color);
  }
  .button--filled:hover {
    background: var(--composite-color-hover);
    border-color: var(--composite-color-hover);
    color: #fff;
  }
  .button--circle {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    line-height: 2.25rem;
    padding: 0;
    font-size: 1.125rem;
    border: none;
  }
  .button--circle .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .button--line {
    -webkit-transition: color 0.3s ease,
      box-shadow 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    transition: color 0.3s ease,
      box-shadow 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    border: 1px solid #000;
    background: #fff;
    color: #000;
  }
  .button--line:hover {
    color: #fff;
    box-shadow: inset 0 -50px 8px -4px #000;
  }
  .button--tag:before {
    content: '#';
  }
  .buttonSet > .button {
    margin-right: 8px;
    margin-bottom: 8px;
    vertical-align: middle;
  }
  .buttonSet > .button:last-child {
    margin-right: 0;
  }
  .button--comment {
    display: block;
    font-weight: 500;
    margin: 50px auto 0;
    max-width: 300px;
    text-transform: uppercase;
  }
  .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  .animated.infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }
  .bounceIn {
    -webkit-animation-name: a;
    animation-name: a;
  }
  .bounceInDown {
    -webkit-animation-name: b;
    animation-name: b;
  }
  .pulse {
    -webkit-animation-name: c;
    animation-name: c;
  }
  .slideOutDown {
    -webkit-animation-name: h;
    animation-name: h;
  }
  @-webkit-keyframes a {
    0%,
    20%,
    40%,
    60%,
    80%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
      opacity: 0;
      -webkit-transform: scale3d(0.3, 0.3, 0.3);
      transform: scale3d(0.3, 0.3, 0.3);
    }
    20% {
      -webkit-transform: scale3d(1.1, 1.1, 1.1);
      transform: scale3d(1.1, 1.1, 1.1);
    }
    40% {
      -webkit-transform: scale3d(0.9, 0.9, 0.9);
      transform: scale3d(0.9, 0.9, 0.9);
    }
    60% {
      opacity: 1;
      -webkit-transform: scale3d(1.03, 1.03, 1.03);
      transform: scale3d(1.03, 1.03, 1.03);
    }
    80% {
      -webkit-transform: scale3d(0.97, 0.97, 0.97);
      transform: scale3d(0.97, 0.97, 0.97);
    }
    to {
      opacity: 1;
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
  }
  @keyframes a {
    0%,
    20%,
    40%,
    60%,
    80%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
      opacity: 0;
      -webkit-transform: scale3d(0.3, 0.3, 0.3);
      transform: scale3d(0.3, 0.3, 0.3);
    }
    20% {
      -webkit-transform: scale3d(1.1, 1.1, 1.1);
      transform: scale3d(1.1, 1.1, 1.1);
    }
    40% {
      -webkit-transform: scale3d(0.9, 0.9, 0.9);
      transform: scale3d(0.9, 0.9, 0.9);
    }
    60% {
      opacity: 1;
      -webkit-transform: scale3d(1.03, 1.03, 1.03);
      transform: scale3d(1.03, 1.03, 1.03);
    }
    80% {
      -webkit-transform: scale3d(0.97, 0.97, 0.97);
      transform: scale3d(0.97, 0.97, 0.97);
    }
    to {
      opacity: 1;
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
  }
  @-webkit-keyframes b {
    0%,
    60%,
    75%,
    90%,
    to {
      -webkit-animation-timing-function: cubic-bezier(215, 610, 355, 1);
      animation-timing-function: cubic-bezier(215, 610, 355, 1);
    }
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -3000px, 0);
      transform: translate3d(0, -3000px, 0);
    }
    60% {
      opacity: 1;
      -webkit-transform: translate3d(0, 25px, 0);
      transform: translate3d(0, 25px, 0);
    }
    75% {
      -webkit-transform: translate3d(0, -10px, 0);
      transform: translate3d(0, -10px, 0);
    }
    90% {
      -webkit-transform: translate3d(0, 5px, 0);
      transform: translate3d(0, 5px, 0);
    }
    to {
      -webkit-transform: none;
      transform: none;
    }
  }
  @keyframes b {
    0%,
    60%,
    75%,
    90%,
    to {
      -webkit-animation-timing-function: cubic-bezier(215, 610, 355, 1);
      animation-timing-function: cubic-bezier(215, 610, 355, 1);
    }
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -3000px, 0);
      transform: translate3d(0, -3000px, 0);
    }
    60% {
      opacity: 1;
      -webkit-transform: translate3d(0, 25px, 0);
      transform: translate3d(0, 25px, 0);
    }
    75% {
      -webkit-transform: translate3d(0, -10px, 0);
      transform: translate3d(0, -10px, 0);
    }
    90% {
      -webkit-transform: translate3d(0, 5px, 0);
      transform: translate3d(0, 5px, 0);
    }
    to {
      -webkit-transform: none;
      transform: none;
    }
  }
  @-webkit-keyframes c {
    0% {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
    50% {
      -webkit-transform: scale3d(1.2, 1.2, 1.2);
      transform: scale3d(1.2, 1.2, 1.2);
    }
    to {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
  }
  @keyframes c {
    0% {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
    50% {
      -webkit-transform: scale3d(1.2, 1.2, 1.2);
      transform: scale3d(1.2, 1.2, 1.2);
    }
    to {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
  }
  @-webkit-keyframes d {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(1turn);
      transform: rotate(1turn);
    }
  }
  @keyframes d {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(1turn);
      transform: rotate(1turn);
    }
  }
  @-webkit-keyframes e {
    0% {
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
    40% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    60% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    to {
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
  }
  @keyframes e {
    0% {
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
    40% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    60% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    to {
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
  }
  @-webkit-keyframes f {
    0% {
      opacity: 0;
    }
    10% {
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes f {
    0% {
      opacity: 0;
    }
    10% {
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
  @-webkit-keyframes g {
    0% {
      opacity: 0;
    }
    10% {
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes g {
    0% {
      opacity: 0;
    }
    10% {
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
  @-webkit-keyframes h {
    0% {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
    to {
      visibility: hidden;
      -webkit-transform: translate3d(0, 20%, 0);
      transform: translate3d(0, 20%, 0);
    }
  }
  @keyframes h {
    0% {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
    to {
      visibility: hidden;
      -webkit-transform: translate3d(0, 20%, 0);
      transform: translate3d(0, 20%, 0);
    }
  }
  @-webkit-keyframes i {
    0% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes i {
    0% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-webkit-keyframes j {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.98) translateY(-0.6em);
      transform: scale(0.98) translateY(-0.6em);
    }
    to {
      opacity: 1;
      -webkit-transform: scale(1) translateY(0);
      transform: scale(1) translateY(0);
    }
  }
  @keyframes j {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.98) translateY(-0.6em);
      transform: scale(0.98) translateY(-0.6em);
    }
    to {
      opacity: 1;
      -webkit-transform: scale(1) translateY(0);
      transform: scale(1) translateY(0);
    }
  }
  .icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
  }
  .icon.top2 {
    margin-top: -2px;
  }
  .icon--s {
    height: 18px;
    width: 18px;
  }
  .icon--m {
    width: 26px;
    height: 26px;
  }
  .rotate90 {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .rotate270 {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  .search-inner {
    max-width: 700px;
    padding: 6vw 20px 20px;
    width: 100%;
    margin-bottom: 30px;
  }
  .search-wrap {
    background-color: var(--input-bg-color);
    border-radius: 5px;
    box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.09),
      2px 32px 38px -20px rgba(0, 0, 0, 0.25);
    overflow: hidden;
  }
  .search-message {
    padding: 7px 8px;
    color: var(--search-color-message);
    font-size: 0.9em;
    border-top: 1px solid rgba(231, 239, 243, 0.7);
  }
  .search-close {
    position: absolute;
    right: 20px;
    top: 8px;
  }
  .search-form {
    padding: 12px 16px;
    overflow: hidden;
  }
  .search-form .icon--search {
    margin-right: 5px;
    color: var(--input-color);
    opacity: 0.7;
  }
  .search-form input {
    border-color: transparent;
  }
  .search-results {
    overflow: auto;
    max-height: calc(100vh - 15rem);
    -webkit-overflow-scrolling: touch;
  }
  .search-results a {
    padding: 10px 20px;
    background: var(--search-bg-link);
    color: var(--search-color-link);
    text-decoration: none;
    display: block;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .search-results a.search-result--active,
  .search-results a:hover {
    background: var(--search-bg-link-hover);
  }
  .search-nav-hints {
    max-width: 680px;
    line-height: 3rem;
    margin-top: 10px;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .search-nav-hint {
    display: inline-block;
    line-height: 1.5rem;
    margin-left: 12px;
    font-size: 0.8125rem;
    color: #fff;
  }
  .search-nav-hint > span {
    display: inline-block;
    background-color: #f42;
    padding: 1px 4px 2px;
    border-radius: 2px;
    opacity: 0.9;
    min-width: 20px;
    line-height: 1.125rem;
    text-align: center;
  }
  body.has-search {
    overflow: hidden;
  }
  body.has-search .search {
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
  }
  body.has-search .search-inner {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: -webkit-transform 0.8s cubic-bezier(0.26, 0.63, 0, 0.96);
    transition: -webkit-transform 0.8s cubic-bezier(0.26, 0.63, 0, 0.96);
    transition: transform 0.8s cubic-bezier(0.26, 0.63, 0, 0.96);
    transition: transform 0.8s cubic-bezier(0.26, 0.63, 0, 0.96),
      -webkit-transform 0.8s cubic-bezier(0.26, 0.63, 0, 0.96);
  }
  .hr-list {
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.0785);
    margin: 40px 0 0;
  }
  .story-feed .story-feed-content:first-child .hr-list:first-child {
    margin-top: 5px;
  }
  .separate {
    margin-left: 0.2em;
    margin-right: 0.2em;
  }
  .separate:before {
    content: '/';
  }
  .image-hover img {
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  .image-hover:hover img {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
  }
  .title-hover {
    color: var(--story-title-color);
  }
  .title-hover:hover {
    opacity: 0.6;
  }
  .flow-meta {
    margin-bottom: 1em;
  }
  .flow-meta-cat {
    color: var(--story-color);
    letter-spacing: 0.02em;
    padding: 4px 2px;
  }
  .flow-meta-cat:hover {
    opacity: 0.6;
  }
  .more-link {
    -webkit-transition: none 0.25s ease-in-out;
    transition: none 0.25s ease-in-out;
    -webkit-transition-property: background-size, color;
    transition-property: background-size, color;
    background-repeat: no-repeat, no-repeat;
    background-position: 0 100%, 0 100%;
    background-size: 0 1px, 100% 1px;
    display: inline-block;
    color: var(--story-more-link-color);
    background-image: linear-gradient(
        0deg,
        var(--story-more-link-color),
        var(--story-more-link-color)
      ),
      linear-gradient(
        0deg,
        var(--story-more-link-border),
        var(--story-more-link-border)
      );
    font-style: italic;
    line-height: 1.6;
  }
  .more-link:after {
    content: '...';
  }
  .more-link:focus,
  .more-link:hover {
    color: var(--story-more-link-color);
    background-size: 100% 1px, 100% 1px;
  }
  .u-meta-featured {
    font-size: 0.75rem;
    letter-spacing: 0.025em;
    padding: 3px 10px;
    right: 10px;
    top: 10px;
  }
  .story-image {
    -webkit-box-flex: 0;
    flex: 0 0 45%;
  }
  .story-footer {
    color: var(--story-footer-color);
  }
  .story-title {
    margin-bottom: 0.625em;
  }
  .story-body {
    min-height: 250px;
    padding: 1rem 0 1rem 2rem;
  }
  .story-lower {
    color: var(--story-content-color);
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    letter-spacing: 0.02em;
    margin-bottom: 1.5em;
  }
  .story-excerpt {
    font-weight: 300;
    line-height: 1.6;
  }
  .story-travel {
    margin-bottom: 3.5em;
  }
  .story-travel .flow-meta {
    margin-bottom: 1.25em;
  }
  .story-travel-image-link {
    padding-top: 66.5%;
    margin-bottom: 1.375em;
  }
  .story-travel-title {
    font-weight: 400;
    line-height: 1.4;
  }
  .h-cover-header {
    padding: 30px 3.846153846% 20px;
  }
  .h-cover-bg {
    background: linear-gradient(180deg, transparent 30%, rgba(0, 0, 0, 0.9));
  }
  .h-cover-bg,
  .h-cover-header,
  .h-cover-img {
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  .h-cover-items:hover .h-cover-header {
    bottom: 10px;
  }
  .h-cover-items:hover .h-cover-bg {
    background: linear-gradient(180deg, transparent 0, rgba(0, 0, 0, 0.9));
  }
  .h-cover-items:hover .h-cover-img {
    -webkit-transform: scale(1.01);
    transform: scale(1.01);
  }
  .h-cover-items .flow-meta,
  .sf-cover .flow-meta {
    color: hsla(0, 0%, 100%, 0.85);
  }
  .h-cover-items .flow-meta-cat,
  .sf-cover .flow-meta-cat {
    color: #fff;
  }
  .is-blog .story-feed {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .entry-bg {
    opacity: 0;
  }
  .entry-image {
    margin-bottom: 3rem;
    padding-top: 60%;
  }
  .entry-image:hover .entry-bg {
    opacity: 0.2;
  }
  .entry-divider hr {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .post-box-image {
    padding-top: 66.66667%;
  }
  .post-box-excerpt {
    color: #7d7d7d;
    max-height: 3.3em;
    line-height: 1.6;
  }
  :not(pre) > code {
    padding: 1px 5px;
    border-radius: 0.3px;
    white-space: normal;
    background: #e5eff5;
    text-shadow: none;
    color: #343f44;
    font-size: 0.8em;
  }
  pre {
    overflow-x: auto;
    margin: 0;
    padding: 20px;
    max-width: 100%;
    border: none;
    color: #e5eff5;
    font-size: 0.875rem;
    line-height: 1.6em;
    background: #2a3644;
    border-radius: 5px;
  }
  pre code {
    padding: 0;
    font-size: inherit;
    line-height: inherit;
    background: transparent;
    white-space: pre;
  }
  pre code * {
    color: inherit;
  }
  code,
  pre {
    font-family: Cascadia Code, Operator Mono Medium, Dank Mono, Fira Code,
      Menlo, Courier, monospace;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
  }
  pre[class*='language-'] {
    padding: 1.25rem;
    margin-bottom: 0;
    overflow: auto;
    border-radius: 8px;
  }
  :not(pre) > code[class*='language-'],
  pre[class*='language-'] {
    border: none;
  }
  :not(pre) > code[class*='language-'] {
    padding: 0.3em 0.4em;
    border-radius: 0.3em;
    white-space: normal;
    background: #e5eff5;
    text-shadow: none;
    color: #343f44;
    font-size: 0.9em;
  }
  a > code[class*='language-'] {
    color: #3eb0ef;
  }
  .token.cdata,
  .token.comment,
  .token.doctype,
  .token.prolog {
    color: hsla(0, 0%, 100%, 0.5);
  }
  .token.punctuation {
    color: #fff;
  }
  .namespace {
    opacity: 0.7;
  }
  .token.constant,
  .token.deleted,
  .token.property,
  .token.symbol,
  .token.tag {
    color: #ff5884;
  }
  .token.boolean,
  .token.number {
    color: #ae81ff;
  }
  .token.attr-name,
  .token.builtin,
  .token.char,
  .token.inserted,
  .token.selector,
  .token.string {
    color: #e6db74;
  }
  .language-css .token.string,
  .style .token.string,
  .token.entity,
  .token.operator,
  .token.url,
  .token.variable {
    color: #fff;
  }
  .token.atrule,
  .token.attr-value,
  .token.class-name,
  .token.function {
    color: #a6e22e;
  }
  .token.keyword {
    color: #66d9ef;
  }
  .token.important,
  .token.regex {
    color: #fd971f;
  }
  .token.bold,
  .token.important {
    font-weight: 700;
  }
  .token.italic {
    font-style: italic;
  }
  .token.entity {
    cursor: help;
  }
  body.has-cover .header {
    position: fixed;
  }
  body.has-cover:not(.has-menu).is-transparency .header {
    background-color: hsla(0, 0%, 100%, 0);
    box-shadow: none;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }
  body.has-cover:not(.has-menu).is-transparency .header-left .hla,
  body.has-cover:not(.has-menu).is-transparency .header-logo,
  body.has-cover:not(.has-menu).is-transparency .mainMenu > ul li a {
    color: #fff;
  }
  body.has-cover:not(.has-menu).is-transparency .menu-burger span {
    background-color: #fff;
  }
  .kg-embed-card {
    min-width: 100%;
    max-width: 100%;
  }
  .kg-gallery-container {
    max-width: 100%;
    width: 100%;
  }
  .kg-gallery-row:not(:first-of-type) {
    margin: 0.75em 0 0;
  }
  .kg-gallery-image img {
    display: block;
    margin: 0;
    width: 100%;
    height: 100%;
  }
  .kg-gallery-image:not(:first-of-type) {
    margin: 0 0 0 0.75em;
  }
  .kg-bookmark,
  .kg-bookmark-card,
  .kg-bookmark-publisher {
    position: relative;
  }
  .kg-bookmark-container {
    background: var(--bookmark-bg);
    border: 1px solid var(--bookmark-border-color);
    border-radius: 5px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  .kg-bookmark-content {
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-basis: 100%;
    padding: 20px;
  }
  .kg-bookmark-title {
    color: var(--bookmark-color);
    font-weight: 500;
    line-height: 1.2;
    font-size: 1.25rem;
  }
  .kg-bookmark-description {
    margin-bottom: 8px;
    color: var(--bookmark-color-des);
    line-height: 1.4;
    max-height: 4em;
    margin-top: 10px;
    font-size: 1rem;
  }
  .kg-bookmark-metadata {
    font-size: 0.9375rem;
    color: var(--bookmark-meta-color);
  }
  .kg-bookmark-thumbnail {
    -webkit-box-flex: 1;
    flex-grow: 1;
    min-width: 33%;
    position: relative;
    min-height: 150px;
  }
  .kg-bookmark-icon {
    width: 22px;
    height: 22px;
    margin: 0 8px 0 0 !important;
  }
  .kg-bookmark-author:after {
    content: '•';
    margin: 0 6px;
    color: rgba(0, 0, 0, 0.54);
  }
  .sticky-top-wrap .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 90px;
    bottom: 0;
  }
  .sticky-bottom-wrap .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: auto;
    bottom: 30px;
  }
  .post-comments {
    background: #fff;
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.05);
    font-size: 0.875rem;
    overflow-y: auto;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: 0.2s;
    transition: 0.2s;
    will-change: transform;
    z-index: 15;
  }
  .post-comments-header {
    height: 60px;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: #fff;
  }
  .post-comments-overlay {
    background-color: rgba(0, 0, 0, 0.2);
    display: none;
    -webkit-transition: background-color 0.4s linear;
    transition: background-color 0.4s linear;
    z-index: 14;
    cursor: pointer;
  }
  body.has-comments {
    overflow: hidden;
  }
  body.has-comments .post-comments-overlay {
    display: block;
  }
  body.has-comments .post-comments {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  .witget-subscribe {
    background: #fff;
    border: 1px solid #ddd;
    padding: 25px;
    position: relative;
  }
  .prev-next-span {
    font-size: 1rem;
    margin-bottom: 5px;
  }
  .prev-next-span i {
    display: inline-block;
    -webkit-transition: all 277ms cubic-bezier(0.16, 0.01, 0.77, 1);
    transition: all 277ms cubic-bezier(0.16, 0.01, 0.77, 1);
  }
  .prev-next-title {
    margin: 0;
    font-size: 1.125rem;
  }
  .prev-next-link:hover .arrow-right {
    -webkit-animation: f 0.5s ease-in-out forwards;
    animation: f 0.5s ease-in-out forwards;
  }
  .prev-next-link:hover .arrow-left {
    -webkit-animation: g 0.5s ease-in-out forwards;
    animation: g 0.5s ease-in-out forwards;
  }
  .post-share {
    right: auto;
    width: 40px;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    top: 30px;
  }
  .post-share .button {
    color: #fff;
    margin: 8px 0 0;
  }
  .post-share .i-chat {
    background-color: #fff;
    border: 2px solid #bbb;
    color: #bbb;
  }
  .post-share .post-share-inner {
    -webkit-transition: visibility 0s linear 0s, opacity 0.3s 0s;
    transition: visibility 0s linear 0s, opacity 0.3s 0s;
  }
  .post-share .post-share-inner.is-hidden {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: visibility 0s linear 0.3s, opacity 0.3s 0s;
    transition: visibility 0s linear 0.3s, opacity 0.3s 0s;
  }
  .post-share-mob {
    top: auto;
    background: var(--mobile-share-bg);
    z-index: 5;
  }
  .post-share-mob .button {
    margin: 5px;
    border-radius: 5px;
    height: 28px;
    line-height: 1.625rem;
  }
  .share-margin .post-share {
    top: -50px;
  }
  .instagram-hover {
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
  }
  .instagram-img {
    height: 264px;
  }
  .instagram-img:hover > .instagram-hover {
    opacity: 1;
  }
  .instagram-btn {
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 3;
    position: absolute;
  }
  .h-cover {
    padding: 4px;
    min-height: 400px;
  }
  .h-cover-items {
    padding: 4px;
    overflow: hidden;
    height: 350px;
    width: 100%;
  }
  .h-cover-items.firts {
    height: 500px;
  }
  .h-cover-inner {
    height: 100%;
  }
  .h-cover-img {
    -webkit-transition: all 0.25s;
    transition: all 0.25s;
  }
  .h-cover-header {
    bottom: 0;
    left: 0;
    right: 0;
  }
  .h-cover-excerpt {
    color: #888;
  }
  .fashion-featured .h-cover-header {
    background-color: var(--fashion-featured-bg);
    right: 18%;
    padding: 15px 20px 0 0;
  }
  .fashion-featured .h-cover-items {
    padding: 10px;
  }
  .fashion-featured .h-cover-title {
    color: var(--post-title-color);
  }
  .fashion-featured .h-cover-title:hover {
    opacity: 0.6;
  }
  .sf-cover {
    min-height: calc(100vh - 100px);
  }
  .sf-cover-title {
    line-height: 1.3;
  }
  .sf-cover .flow-meta {
    -webkit-box-pack: center;
    justify-content: center;
  }
  .hm-cover {
    padding: 30px 0;
    min-height: 100vh;
  }
  .hm-down {
    -webkit-animation-duration: 1.2s !important;
    animation-duration: 1.2s !important;
    bottom: 60px;
    color: hsla(0, 0%, 100%, 0.5);
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 70px;
    z-index: 100;
  }
  .hm-down svg {
    display: block;
    fill: currentcolor;
    height: auto;
    width: 100%;
  }
  .post {
    margin-bottom: 4.5rem;
  }
  .post-title {
    line-height: 1.2;
    max-width: 1000px;
  }
  .post-excerpt {
    line-height: 1.6;
  }
  .post-author-social {
    vertical-align: middle;
    margin-left: 2px;
    padding: 0 3px;
  }
  .post-img {
    width: 100%;
  }
  .avatar-image {
    display: inline-block;
    vertical-align: middle;
  }
  .avatar-image--smaller {
    width: 50px;
    height: 50px;
  }
  .post-inner {
    font-size: 1.125rem;
    line-height: 1.7;
  }
  .post-inner > :not(a) {
    margin: 1rem 0;
    min-width: 100%;
  }
  .post-inner > :not(a):first-child {
    margin-top: 2rem;
  }
  .post-inner p {
    margin: 1rem 0;
  }
  .post-inner a:not(.kg-bookmark-container):not(.button) {
    color: var(--post-color-link);
    text-decoration: none;
    word-break: break-word;
    -webkit-transition: color, border-color 0.2s linear;
    transition: color, border-color 0.2s linear;
    border-bottom: 1px solid var(--post-link-border);
  }
  .post-inner a:not(.kg-bookmark-container):not(.button):hover {
    border-bottom-color: var(--post-color-link);
  }
  .post-inner blockquote,
  .post-inner q {
    margin: 2.5rem 0;
  }
  .post-inner img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .post-inner h1,
  .post-inner h2,
  .post-inner h3,
  .post-inner h4,
  .post-inner h5,
  .post-inner h6 {
    font-style: normal;
    color: var(--post-title-color);
    line-height: 1.2;
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
  .post-inner h1 + p,
  .post-inner h2 + p,
  .post-inner h3 + p,
  .post-inner h4 + p,
  .post-inner h5 + p,
  .post-inner h6 + p {
    margin-top: 0;
  }
  .post-inner ol,
  .post-inner ul {
    counter-reset: a;
  }
  .post-inner ol li,
  .post-inner ul li {
    margin-bottom: 10px;
    margin-left: 30px;
  }
  .post-inner ol li:before,
  .post-inner ul li:before {
    box-sizing: border-box;
    display: inline-block;
    margin-left: -22px;
    position: absolute;
    text-align: right;
  }
  .post-inner ul > li:before {
    content: '\\2022';
    font-family: Georgia, Arial, system-ui;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  .post-inner ol > li:before {
    content: counter(a) '.';
    counter-increment: a;
    padding-right: 12px;
  }
  .post-inner .warning {
    background: #fbe9e7;
    color: #d50000;
  }
  .post-inner .note {
    background: #e1f5fe;
    color: #0288d1;
  }
  .post-inner .success {
    background: #e0f2f1;
    color: #00897b;
  }
  .post-inner .note,
  .post-inner .success,
  .post-inner .warning {
    padding: 20px;
    border-radius: 5px;
  }
  .post-inner .note a,
  .post-inner .success a,
  .post-inner .warning a {
    color: inherit !important;
    border-color: inherit !important;
  }
  .post-upgrade-cta {
    background-color: #f6f1e4;
    padding: 48px 56px;
    text-align: center;
    max-width: 600px;
    min-width: auto !important;
    margin: 4rem auto !important;
  }
  .post-upgrade-cta .button {
    width: 270px;
  }
  .post-upgrade-title {
    margin: 0;
  }
  .post-related {
    padding-top: 5%;
    padding-bottom: 5%;
    background-color: #f8f8f8;
  }
  .lg-backdrop {
    background-color: rgba(0, 0, 0, 0.8) !important;
  }
  .lg-sub-html,
  .lg-toolbar {
    background-color: rgba(0, 0, 0, 0.1) !important;
  }
  .cc-image {
    height: 96vh;
    min-height: 500px;
  }
  .cc-image:before {
    background-image: linear-gradient(0deg, transparent, rgba(0, 0, 0, 0.4));
    content: '';
    display: block;
    height: 200px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
  }
  .cc-video {
    background-color: #1e1e1e;
    padding: 90px 0 50px;
  }
  .cc-video-excerpt {
    color: #aaa;
  }
  .cc-video-cont {
    padding: 0 0 56.25%;
    height: 0;
  }
  .cc-video-close {
    left: -18px;
    position: absolute;
    display: none;
    top: -18px;
    z-index: 5;
  }
  .is-article-single .post-body-wrap {
    margin-left: 0 !important;
  }
  .is-article-single .post-share {
    left: -100px;
  }
  .is-article-single .kg-width-full .kg-image {
    max-width: 100vw;
  }
  .is-article-single .kg-width-wide .kg-image {
    max-width: 1200px;
  }
  .is-article-single .kg-gallery-container {
    max-width: 1200px;
    width: 100vw;
  }
  .author-inner {
    padding: 40px 5%;
    width: 90%;
  }
  .author-left {
    width: 216px;
    margin-right: 50px;
  }
  .author-avatar {
    height: 216px;
    width: 216px;
    overflow: hidden;
  }
  .author-avatar img {
    border: 8px solid #ddd;
  }
  .author-bio {
    color: #555;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 150%;
    width: 100%;
  }
  .author-meta span {
    display: inline-block;
    font-size: 1.0625rem;
    font-style: italic;
    margin: 0 25px 16px 0;
    opacity: 0.8;
    word-wrap: break-word;
  }
  .author-follow .button {
    background-color: #fff;
    border: 1px solid #000;
    color: #000;
    margin-bottom: 10px;
  }
  .author-follow .button:hover {
    border-color: transparent;
    color: #fff;
  }
  .author .fb:hover {
    background-color: #3b5998 !important;
  }
  .author .tw:hover {
    background-color: #55acee !important;
  }
  .author .rs:hover {
    background-color: #e99804 !important;
  }
  .author-card {
    padding: 0 10px;
  }
  .author-card-avatar {
    height: 280px;
  }
  .author-card-avatar img {
    box-shadow: 8px 8px 0 0 var(--archive-author-bg-img);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  .author-card-avatar img:hover {
    box-shadow: 0 0 var(--archive-author-bg-img);
  }
  .author-card-website {
    color: #999;
  }
  .author-card-website:hover {
    opacity: 0.8;
  }
  .author-card-name:hover {
    opacity: 0.6;
  }
  .author-card-bio {
    padding-top: 4px;
    margin-bottom: 12px;
    line-height: 1.5;
  }
  .author-card .button {
    -webkit-box-flex: 1;
    flex: 1 0 0;
    height: 32px;
    line-height: 1.875rem;
  }
  .author-card-media .button:last-child,
  .author-card-media .button:not(:first-child):not(:last-child) {
    margin-left: -0.05rem;
  }
  .contact-form {
    width: 100%;
    max-width: 510px;
    text-align: center;
  }
  .contact-stain {
    width: 75%;
    height: auto;
    top: -40px;
    left: 15%;
    z-index: 1;
  }
  .contact-svg {
    z-index: 3;
    top: 0;
    left: 11%;
    width: 80%;
    pointer-events: none;
  }
  .contact-maps:before {
    height: 50px;
    width: 2px;
    background: #85aeaa;
    content: '';
    display: inline-block;
    margin-bottom: 90px;
  }
  .pae {
    margin-bottom: 60px;
    margin-top: 100px;
  }
  .pae-icon svg {
    width: 64px;
    height: 64px;
  }
  .pae-title {
    font-size: 1.2rem;
    margin: 20px 0;
    letter-spacing: 0.05em;
    font-weight: 500;
  }
  .about {
    padding-bottom: 5vh;
  }
  .about-header {
    min-height: 55vh;
  }
  .about-header-inner {
    padding-bottom: 3em;
  }
  .about-title {
    font-size: 5.625rem;
  }
  .about-gradient {
    background: linear-gradient(
      135deg,
      rgba(16, 43, 123, 0.7),
      rgba(46, 204, 113, 0.7)
    );
  }
  .spc-header:after,
  .spc-header:before {
    content: '';
    left: 0;
    position: absolute;
    width: 100%;
    display: block;
    z-index: 2;
  }
  .spc-header:before {
    height: 200px;
    top: 0;
    background-image: linear-gradient(0deg, transparent, #18151f);
  }
  .spc-header:after {
    height: 300px;
    bottom: 0;
    background-image: linear-gradient(180deg, transparent, #110f16);
  }
  .spc-h-inner {
    padding: calc(9vw + 55px) 4vw 120px;
    font-size: 1.25rem;
  }
  .spc-h-t {
    font-size: 4rem;
  }
  .spc-h-e {
    color: #fecd35;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 0.3125rem;
    margin-top: 5px;
    text-transform: uppercase;
  }
  .spc-des {
    margin: 40px auto 30px;
    line-height: 1.4;
    font-family: Georgia, Merriweather, serif;
    opacity: 0.8;
  }
  .spc-des em {
    font-style: italic;
    color: #fecd35;
  }
  .spc-buttons .button {
    background: hsla(0, 0%, 100%, 0.9);
    border-radius: 35px;
    -webkit-transition: background 0.5s ease;
    transition: background 0.5s ease;
  }
  .spc-buttons .button:hover {
    background: #fff;
    color: #000;
  }
  .spc-buttons .button img {
    display: inline-block;
    height: 20px;
    margin: 0 8px 0 0;
    width: auto;
  }
  .site-category-body {
    padding: 3rem 16px;
    min-height: 220px;
  }
  .topic-img {
    -webkit-transition: -webkit-transform 0.7s;
    transition: -webkit-transform 0.7s;
    transition: transform 0.7s;
    transition: transform 0.7s, -webkit-transform 0.7s;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  .topic-card {
    height: 320px;
    padding: 30px;
    letter-spacing: 0.02em;
    background: linear-gradient(60deg, #562db5, #19afc9);
  }
  .topic-card:hover .topic-img {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
  }
  .topic-card:hover .topic-bg {
    opacity: 0.5;
  }
  .ne-t:before {
    background-color: #e0f6ec;
    bottom: 5%;
    content: '';
    display: block;
    height: 20px;
    left: 50%;
    position: absolute;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 105%;
    z-index: -1;
  }
  .ne-body ul li {
    font-size: 1rem;
  }
  .godo-ne {
    background: #fff;
    border-radius: 4px;
    border: 1px solid #e6e8eb;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.03),
      inset 0 4px 0 0 var(--newsletter-color);
    margin: 40px auto 30px;
    max-width: 600px;
    min-width: auto !important;
    padding: 30px 50px 40px;
    position: relative;
  }
  .godo-ne,
  .godo-ne-form {
    width: 100%;
  }
  .godo-ne-form label {
    color: var(--newsletter-color);
    display: block;
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0.1em;
    margin: 0 0 15px;
    text-transform: uppercase;
  }
  .godo-ne-form small {
    display: block;
    font-size: 0.75rem;
    margin: 15px 0 0;
    opacity: 0.8;
  }
  .godo-ne-input {
    margin-right: 10px;
  }
  .godo-ne-input.error {
    border-color: #e16767;
  }
  .godo-ne-success {
    text-align: center;
  }
  .godo-ne-success h3 {
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .godo-ne-success p {
    font-size: 0.9375rem;
    font-style: italic;
  }
  .godo-n-q {
    margin: 2vw 0;
    position: relative;
    z-index: 2;
  }
  .godo-n-q blockquote {
    border: 0;
    font-size: 1rem;
    font-style: normal;
    line-height: 1.5em;
    margin: 20px 0 0;
    opacity: 0.8;
    padding: 0;
    color: inherit;
  }
  .godo-n-q blockquote:before {
    display: none;
  }
  .godo-n-q img {
    border-radius: 100%;
    border: 5px solid #fff;
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.18);
    display: block;
    height: 105px;
    width: 105px;
  }
  .godo-n-q h3 {
    font-size: 1.4rem;
    font-weight: 500;
    margin: 10px 0 0;
    color: currentColor;
  }
  .godo-n-q-i {
    -webkit-box-flex: 1;
    flex: 1 1 300px;
    margin: 0 20px 40px;
    text-align: center;
  }
  .godo-n-q-d {
    color: var(--newsletter-color);
    font-size: 0.8125rem;
    font-weight: 500;
    letter-spacing: 0.0625rem;
    line-height: 1.3em;
    margin: 6px 0 0;
    text-transform: uppercase;
  }
  .is-membership {
    background: rgba(0, 42, 115, 0.02);
  }
  .checkout-wrap {
    margin: 5vw auto 10vw;
    text-align: center;
  }
  .checkout-des {
    opacity: 0.6;
    margin: 0 auto 60px;
    max-width: 500px;
  }
  .checkout-plan {
    padding: 30px;
    border-radius: 4px;
    background-color: var(--member-plan-bg);
    border: 1px solid var(--member-plan-border);
    text-align: left;
    min-width: 290px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.03),
      inset 0 4px 0 0 var(--composite-color);
  }
  .checkout-plan-header {
    position: relative;
    padding: 0 0 50px;
    color: #8392a5;
    text-align: center;
  }
  .checkout-plan-header h3 {
    margin: 0 0 25px;
    font-size: 1.2rem;
    font-weight: 700;
    color: var(--member-color);
    text-transform: uppercase;
  }
  .checkout-plan-header span {
    font-size: 2.2rem;
    font-weight: 300;
  }
  .checkout-plan-header strong {
    color: var(--member-color);
    font-size: 4rem;
    font-weight: 500;
  }
  .checkout-plan-header:after {
    content: '';
    display: block;
    height: 2px;
    position: absolute;
    bottom: 25px;
    left: 30%;
    right: 30%;
    background: var(--member-plan-border);
    border-radius: 2px;
  }
  .checkout-plan-content {
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .checkout-plan-content li {
    position: relative;
    margin: 0;
    padding: 0 0 15px 20px;
    line-height: 1.4em;
  }
  .checkout-plan-content li:before {
    display: block;
    position: absolute;
    left: 0;
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--composite-color);
    content: '✓';
  }
  .page-error-title {
    font-size: 15.625rem;
  }
  .page-error-wrap {
    padding: 5vw 4vw;
  }
  .site-account {
    padding: 5vw;
  }
  .account-box {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }
  @media only screen and (max-width: 766px) {
    blockquote {
      font-size: 1.125rem;
    }
    h1 {
      font-size: 2.375rem;
    }
    h2 {
      font-size: 2rem;
    }
    h3 {
      font-size: 1.625rem;
    }
    h4 {
      font-size: 1.5rem;
    }
    h5 {
      font-size: 1.25rem;
    }
    .u-md-fontSizeBase,
    h6 {
      font-size: 1.125rem;
    }
    .u-md-fontSize22 {
      font-size: 1.375rem;
    }
    .u-md-fontSizeLarger {
      font-size: 2rem;
    }
    .u-md-fontSize36 {
      font-size: 2.25rem !important;
    }
    .u-block-before-md {
      display: block !important;
    }
    .u-hide-before-md {
      display: none !important;
    }
    .footer {
      padding-bottom: 4rem;
    }
    .page-hero-name {
      padding: 20px 0 40px;
    }
    .search-inner {
      padding-top: 3.5rem;
    }
    .search-results {
      max-height: calc(100vh - 10rem);
    }
    .hr-list {
      margin-bottom: 30px;
    }
    .story {
      margin-top: 20px;
    }
    .story-image {
      height: 235px;
      display: block;
    }
    .story-body {
      padding: 1rem 0 0;
    }
    .kg-width-wide .kg-image {
      width: 100%;
    }
    .instagram-img {
      height: 180px;
    }
    .post-inner {
      font-size: 1.0625rem;
    }
    .post-inner q {
      font-size: 1.375rem;
      line-height: 1.4;
    }
    .post-inner blockquote,
    .post-inner q {
      margin: 1.75rem 0;
      padding-left: 0;
    }
    .post-inner iframe {
      width: 100% !important;
    }
    .author-inner {
      width: 100%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      text-align: center;
      margin-bottom: 0;
    }
    .author-left {
      margin: 0 auto 20px;
      width: 150px;
    }
    .author-avatar {
      width: 150px;
      height: 150px;
    }
    .topic-card {
      height: 200px;
    }
    .godo-ne-input {
      margin: 0 0 10px;
    }
    .godo-ne-form-group {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
    }
    .godo-ne-button {
      width: 100%;
      margin-bottom: 5px;
    }
    .page-error-title {
      font-size: 11.25rem;
    }
  }
  @media only screen and (max-width: 1000px) {
    .u-hide-before-lg {
      display: none !important;
    }
    .u-block-before-lg {
      display: block !important;
    }
    .header-left {
      -webkit-box-flex: 1 !important;
      flex-grow: 1 !important;
    }
    .header-logo span {
      font-size: 1.5rem;
    }
    body.has-menu {
      overflow: hidden;
    }
    body.has-menu .sideNav {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    body.has-menu .menu-burger {
      border: 0;
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
    }
    body.has-menu .menu-burger span:first-child {
      -webkit-transform: rotate(45deg) translate(0);
      transform: rotate(45deg) translate(0);
    }
    body.has-menu .menu-burger span:nth-child(2) {
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
    }
    body.has-menu .menu-burger span:last-child {
      -webkit-transform: rotate(-45deg) translate(0);
      transform: rotate(-45deg) translate(0);
    }
    body.has-menu .footer,
    body.has-menu .main {
      -webkit-transform: translateX(-25%) !important;
      transform: translateX(-25%) !important;
    }
    .post-author,
    .post-body,
    .post-header {
      max-width: 650px;
      margin-left: auto;
      margin-right: auto;
    }
    .cc-video-cont {
      margin: 0 -16px 30px;
    }
  }
  @media only screen and (min-width: 766px) {
    .u-hide-after-md {
      display: none !important;
    }
    .row .col.m1 {
      flex-basis: 8.33333%;
      max-width: 8.33333%;
    }
    .row .col.m2 {
      flex-basis: 16.66667%;
      max-width: 16.66667%;
    }
    .row .col.m3 {
      flex-basis: 25%;
      max-width: 25%;
    }
    .row .col.m4 {
      flex-basis: 33.33333%;
      max-width: 33.33333%;
    }
    .row .col.m5 {
      flex-basis: 41.66667%;
      max-width: 41.66667%;
    }
    .row .col.m6 {
      flex-basis: 50%;
      max-width: 50%;
    }
    .row .col.m7 {
      flex-basis: 58.33333%;
      max-width: 58.33333%;
    }
    .row .col.m8 {
      flex-basis: 66.66667%;
      max-width: 66.66667%;
    }
    .row .col.m9 {
      flex-basis: 75%;
      max-width: 75%;
    }
    .row .col.m10 {
      flex-basis: 83.33333%;
      max-width: 83.33333%;
    }
    .row .col.m11 {
      flex-basis: 91.66667%;
      max-width: 91.66667%;
    }
    .row .col.m12 {
      flex-basis: 100%;
      max-width: 100%;
    }
    .footer-social-media {
      text-align: right;
    }
    .f-subscribe {
      -webkit-box-pack: justify;
      justify-content: space-between;
    }
    .f-subscribe-right {
      width: 70%;
      max-width: 485px;
      padding-left: 20px;
    }
    .f-subscribe-left {
      max-width: 620px;
      margin-bottom: 0;
    }
    .story-travel-image-link {
      padding-top: 130%;
    }
    .story-travel-large .story-travel-image-link {
      padding-top: 61.614%;
    }
    .entry-divider hr {
      margin-top: 3rem;
      margin-bottom: 3rem;
    }
    .post-comments {
      left: auto;
      max-width: 700px;
      min-width: 500px;
    }
    .prev-next-link {
      margin: 0 !important;
    }
    .prev-next-right {
      text-align: right;
    }
    .h-cover-items {
      width: 50%;
    }
    .h-cover-items.firts {
      width: 100%;
    }
  }
  @media only screen and (min-width: 1000px) {
    .u-hide-after-lg {
      display: none !important;
    }
    .col-left {
      max-width: calc(100% - 360px);
    }
    body.is-article .col-left {
      padding-right: 30px;
    }
    .row .col.l1 {
      flex-basis: 8.33333%;
      max-width: 8.33333%;
    }
    .row .col.l2 {
      flex-basis: 16.66667%;
      max-width: 16.66667%;
    }
    .row .col.l3 {
      flex-basis: 25%;
      max-width: 25%;
    }
    .row .col.l4 {
      flex-basis: 33.33333%;
      max-width: 33.33333%;
    }
    .row .col.l5 {
      flex-basis: 41.66667%;
      max-width: 41.66667%;
    }
    .row .col.l6 {
      flex-basis: 50%;
      max-width: 50%;
    }
    .row .col.l7 {
      flex-basis: 58.33333%;
      max-width: 58.33333%;
    }
    .row .col.l8 {
      flex-basis: 66.66667%;
      max-width: 66.66667%;
    }
    .row .col.l9 {
      flex-basis: 75%;
      max-width: 75%;
    }
    .row .col.l10 {
      flex-basis: 83.33333%;
      max-width: 83.33333%;
    }
    .row .col.l11 {
      flex-basis: 91.66667%;
      max-width: 91.66667%;
    }
    .row .col.l12 {
      flex-basis: 100%;
      max-width: 100%;
    }
    .h-cover {
      height: 60vh;
    }
    .h-cover-items {
      height: 50%;
      width: 33.33333%;
    }
    .h-cover-items.firts {
      height: 100%;
      width: 66.66666%;
    }
    .fashion-featured {
      height: 80vh;
      min-height: 600px;
    }
    .home-story-grid .post-box-title {
      font-size: 1.125rem;
    }
    body.is-article .post-body-wrap,
    body.is-image .post-author,
    body.is-video .post-author {
      margin-left: 70px;
    }
    .spc-h-inner {
      font-size: 1.875rem;
    }
    .site-category {
      text-align: left;
    }
    .site-category-count {
      margin-top: 0;
    }
    .site-category-description {
      margin-left: 0;
    }
  }
  @media only screen and (max-width: 576px) {
    .row .col.col-s12 {
      flex-basis: 100% !important;
      max-width: 100% !important;
    }
    .kg-bookmark-container {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
    }
    .kg-bookmark-content {
      -webkit-box-ordinal-group: 2;
      order: 1;
    }
  }
  @media only screen and (min-width: 1230px) {
    body.has-video-fixed .cc-video-embed {
      bottom: 20px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
      height: auto;
      padding-bottom: 0;
      position: fixed;
      right: 20px;
      width: 360px;
      z-index: 8;
    }
    body.has-video-fixed .cc-video-close {
      display: block;
    }
    .cc-video-title {
      font-size: 2.6rem;
    }
  }
<!--  max-width: 740px;-->
<!--  margin: auto;-->

<!--  font-family: Montserrat, sans-serif;-->
`

const SubTitleText = styled(DefaultSubTitleText)``
const SubBodyText = styled(DefaultSubBodyText)``

const RegistrationLink = styled(Link)`
  font-family: 'Montserrat', sans-serif;
  text-decoration: none;
  font-weight: 600;
  min-height: 40px;
  line-height: 32px;
  box-sizing: border-box;
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 8px;
  color: ${({ theme }) => theme.pallete.accentPrimary};
  background-color: ${({ theme }) => theme.pallete.white};
`

const Actions = styled.div`
  display: flex;
`
const ExpandBlockCss = css`
  max-height: 213px;

  ${SubTitleText} {
    opacity: 1;
    visibility: visible;
  }

  ${SubBodyText} {
    opacity: 1;
    visibility: visible;
  }

  ${Actions} {
    margin-top: 0;
  }

  &:before {
    transform: translateY(0px);
  }
`
const PromoBlock = styled.div<{ isExpand?: boolean }>`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 240px;
  z-index: 10;
  max-height: 96px;
  min-height: 96px;
  background: linear-gradient(268.63deg, #448aff 0.07%, #76d4ff 99.65%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 477px;
  padding-left: ${({ theme }) => theme.paddings.main}px;
  padding-top: ${({ theme }) => theme.paddings.main}px;
  padding-bottom: ${({ theme }) => theme.paddings.main}px;
  transition: all 0.3s;
  will-change: transform;

  ${SubTitleText} {
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }

  ${SubBodyText} {
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }

  ${Actions} {
    transition: all 0.3s;
    margin-top: -94px;
  }

  &:hover {
    ${ExpandBlockCss}
  }

  &:before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    top: -25px;
    width: 477px;
    background-image: url(${CSS.escape(bg)});
    transform: translateY(55px);
    transition: all 0.3s;
  }

  @media (max-width: 1025px) {
    left: 72px;
  }

  ${({ isExpand }) => isExpand && ExpandBlockCss};
`
const rootEl = document.getElementById('root')

export const OfdLanding = ({ content, onConnect }: Props) => {
  const [isExpand, setIsExpand] = useState(false)

  const scrollLine = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const handleScroll = () => {
      const top =
        (scrollLine.current?.getBoundingClientRect().top || 0) -
        window.innerHeight
      const isScrollOnLine = top <= 0

      if (isScrollOnLine && !isExpand) {
        setIsExpand(true)
      } else if (!isScrollOnLine && isExpand) {
        setIsExpand(false)
      }
    }

    rootEl?.addEventListener('scroll', handleScroll)

    return () => rootEl?.removeEventListener('scroll', handleScroll)
  }, [isExpand])

  return (
    <>
      <Wrapper>
        <ContentCard>
          <ContentCardBody>
            {!!content.html && (
              <GhostHtml>
                <div className="site-wrapper u-flexColumnTop">
                  <div className="site-main">
                    {content.feature_image && (
                      <figure className="post-image u-block u-marginAuto u-marginTop30 u-maxWidth1200">
                        <img
                          width={807}
                          height={355}
                          className="post-img u-block u-marginAuto"
                          src={content.feature_image}
                          alt={content.title}
                        />
                      </figure>
                    )}
                    <article className="post u-maxWidth740 u-container u-marginAuto u-marginTop30 u-marginBottom40">
                      <h1 className="post-title u-marginBottom20">
                        {content.title}
                      </h1>
                      <div className="post-body">
                        <div
                          className="post-inner js-post-content"
                          /* eslint-disable-next-line react/no-danger */
                          dangerouslySetInnerHTML={{
                            __html: content.html,
                          }}
                        />
                      </div>
                    </article>
                  </div>
                </div>
              </GhostHtml>
            )}
          </ContentCardBody>
        </ContentCard>
        <div ref={scrollLine} />
      </Wrapper>
      <PromoBlock isExpand={isExpand}>
        <SubTitleText fontWeight="bold" color="white">
          Подключите онлайн-кассу ATOL
        </SubTitleText>
        <HBox x={1 / 3} />
        <SubBodyText color="white">
          Бесплатно, если регистрируете новую кассу через Mozen. Менеджер
          поможет подключить новую кассу для работы. <br />
          200 рублей в месяц к тарифу, если подключаете существующую.
        </SubBodyText>
        <HBox x={2 / 3} />
        <Actions>
          <RegistrationLink
            href="https://online.atol.ru/lk/Account/Register?partnerUid=6898a80f-2415-438e-a4cd-a7ba3e9f1b32"
            target="_blank"
          >
            Зарегистрировать новую
          </RegistrationLink>
          <VBox x={2 / 3} />
          <TransparentButton
            textColor="baseWhite"
            fontWeight="semi-bold"
            onClick={onConnect}
          >
            Подключить кассу
          </TransparentButton>
        </Actions>
      </PromoBlock>
    </>
  )
}
