import { combineReducers } from 'redux'

import aggregatorPostActions, {
  TAggregatorPostAction,
} from 'api/park/aggregator/post/actions'
import {
  AggregatorPostState,
  reducer as aggregatorPost,
} from 'api/park/aggregator/post/reducer'
import * as aggregatorPostSelectors from 'api/park/aggregator/post/selectors'
import { handleRequest as aggregatorPostRequest } from 'api/park/aggregator/post/thunks'

import { TAggregatorByIdDeleteAction } from 'api/park/aggregatorById/delete/actions'
import {
  AggregatorByIdDeleteState,
  reducer as aggregatorByIdDelete,
} from 'api/park/aggregatorById/delete/reducer'
import * as aggregatorByIdDeleteSelectors from 'api/park/aggregatorById/delete/selectors'
import { handleRequest as aggregatorByIdDeleteRequest } from 'api/park/aggregatorById/delete/thunks'

import aggregatorByIdGetActions, {
  TAggregatorByIdGetAction,
} from 'api/park/aggregatorById/get/actions'
import {
  AggregatorByIdGetState,
  reducer as aggregatorByIdGet,
} from 'api/park/aggregatorById/get/reducer'
import * as aggregatorByIdGetSelectors from 'api/park/aggregatorById/get/selectors'
import { handleRequest as aggregatorByIdGetRequest } from 'api/park/aggregatorById/get/thunks'

import { TAggregatorByIdPatchAction } from 'api/park/aggregatorById/patch/actions'
import {
  AggregatorByIdPatchState,
  reducer as aggregatorByIdPatch,
} from 'api/park/aggregatorById/patch/reducer'
import * as aggregatorByIdPatchSelectors from 'api/park/aggregatorById/patch/selectors'
import { handleRequest as aggregatorByIdPatchRequest } from 'api/park/aggregatorById/patch/thunks'

import { TAggregatorsAction } from 'api/park/aggregators/actions'
import {
  AggregatorsState,
  reducer as aggregators,
} from 'api/park/aggregators/reducer'
import * as aggregatorsSelectors from 'api/park/aggregators/selectors'
import { handleRequest as aggregatorsRequest } from 'api/park/aggregators/thunks'

import { TAllowAutoTransferGetAction } from 'api/park/allow-auto-transfer/get/actions'
import {
  AllowAutoTransferGetState,
  reducer as allowAutoTransferGet,
} from 'api/park/allow-auto-transfer/get/reducer'
import * as allowAutoTransferGetSelectors from 'api/park/allow-auto-transfer/get/selectors'
import { handleRequest as allowAutoTransferGetRequest } from 'api/park/allow-auto-transfer/get/thunks'

import { TAllowAutoTransferPostAction } from 'api/park/allow-auto-transfer/post/actions'
import {
  AllowAutoTransferPostState,
  reducer as allowAutoTransferPost,
} from 'api/park/allow-auto-transfer/post/reducer'
import * as allowAutoTransferPostSelectors from 'api/park/allow-auto-transfer/post/selectors'
import { handleRequest as allowAutoTransferPostRequest } from 'api/park/allow-auto-transfer/post/thunks'

import { TBalanceLogAction } from 'api/park/balance/log/actions'
import {
  BalanceLogState,
  reducer as balanceLog,
} from 'api/park/balance/log/reducer'
import * as balanceLogSelectors from 'api/park/balance/log/selectors'
import { handleRequest as balanceLogRequest } from 'api/park/balance/log/thunks'

import calculateTopupGiftActions, {
  TCalculateTopupGiftAction,
} from 'api/park/calculate-topup-gift/actions'
import {
  CalculateTopupGiftState,
  reducer as calculateTopupGift,
} from 'api/park/calculate-topup-gift/reducer'
import * as calculateTopupGiftSelectors from 'api/park/calculate-topup-gift/selectors'
import { handleRequest as calculateTopupGiftRequest } from 'api/park/calculate-topup-gift/thunks'

import { TCertificateGetAction } from 'api/park/certificate/get/actions'
import {
  CertificateGetState,
  reducer as certificateGet,
} from 'api/park/certificate/get/reducer'
import * as certificateGetSelectors from 'api/park/certificate/get/selectors'
import { handleRequest as certificateGetRequest } from 'api/park/certificate/get/thunks'

import { TCertificatePostAction } from 'api/park/certificate/post/actions'
import {
  CertificatePostState,
  reducer as certificatePost,
} from 'api/park/certificate/post/reducer'
import * as certificatePostSelectors from 'api/park/certificate/post/selectors'
import { handleRequest as certificatePostRequest } from 'api/park/certificate/post/thunks'

import { TChangeEdgvrApiKeyAction } from 'api/park/change-edgvr-api-key/actions'
import {
  ChangeEdgvrApiKeyState,
  reducer as changeEdgvrApiKey,
} from 'api/park/change-edgvr-api-key/reducer'
import * as changeEdgvrApiKeySelectors from 'api/park/change-edgvr-api-key/selectors'
import { handleRequest as changeEdgvrApiKeyRequest } from 'api/park/change-edgvr-api-key/thunks'

import { TContractsAction } from 'api/park/contracts/actions'
import {
  ContractsState,
  reducer as contracts,
} from 'api/park/contracts/reducer'
import * as contractsSelectors from 'api/park/contracts/selectors'
import { handleRequest as contractsRequest } from 'api/park/contracts/thunks'

import { TDetailsAction } from 'api/park/details/actions'
import { DetailsState, reducer as details } from 'api/park/details/reducer'
import * as detailsSelectors from 'api/park/details/selectors'
import { handleRequest as detailsRequest } from 'api/park/details/thunks'

import { TEdgvrApiKeyAction } from 'api/park/edgvr-api-key/actions'
import {
  EdgvrApiKeyState,
  reducer as edgvrApiKey,
} from 'api/park/edgvr-api-key/reducer'
import * as edgvrApiKeySelectors from 'api/park/edgvr-api-key/selectors'
import { handleRequest as edgvrApiKeyRequest } from 'api/park/edgvr-api-key/thunks'

import { TNotificationsGetAction } from 'api/park/notifications/get/actions'
import {
  NotificationsGetState,
  reducer as notificationsGet,
} from 'api/park/notifications/get/reducer'
import * as notificationsGetSelectors from 'api/park/notifications/get/selectors'
import { handleRequest as notificationsGetRequest } from 'api/park/notifications/get/thunks'

import { TNotificationsPatchAction } from 'api/park/notifications/patch/actions'
import {
  NotificationsPatchState,
  reducer as notificationsPatch,
} from 'api/park/notifications/patch/reducer'
import * as notificationsPatchSelectors from 'api/park/notifications/patch/selectors'
import { handleRequest as notificationsPatchRequest } from 'api/park/notifications/patch/thunks'

import { TOrganizationDetailsAction } from 'api/park/organization-details/actions'
import {
  OrganizationDetailsState,
  reducer as organizationDetails,
} from 'api/park/organization-details/reducer'
import * as organizationDetailsSelectors from 'api/park/organization-details/selectors'
import { handleRequest as organizationDetailsRequest } from 'api/park/organization-details/thunks'

import { TPaymentSettingsGetAction } from 'api/park/payment-settings/get/actions'
import {
  PaymentSettingsGetState,
  reducer as paymentSettingsGet,
} from 'api/park/payment-settings/get/reducer'
import * as paymentSettingsGetSelectors from 'api/park/payment-settings/get/selectors'
import { handleRequest as paymentSettingsGetRequest } from 'api/park/payment-settings/get/thunks'

import { TPaymentSettingsPatchAction } from 'api/park/payment-settings/patch/actions'
import {
  PaymentSettingsPatchState,
  reducer as paymentSettingsPatch,
} from 'api/park/payment-settings/patch/reducer'
import * as paymentSettingsPatchSelectors from 'api/park/payment-settings/patch/selectors'
import { handleRequest as paymentSettingsPatchRequest } from 'api/park/payment-settings/patch/thunks'

import qiwiDetailsActions, {
  TQiwiDetailsAction,
} from 'api/park/qiwi/details/actions'
import {
  QiwiDetailsState,
  reducer as qiwiDetails,
} from 'api/park/qiwi/details/reducer'
import * as qiwiDetailsSelectors from 'api/park/qiwi/details/selectors'
import { handleRequest as qiwiDetailsRequest } from 'api/park/qiwi/details/thunks'

import { TQiwiPatchAction } from 'api/park/qiwi/patch/actions'
import {
  QiwiPatchState,
  reducer as qiwiPatch,
} from 'api/park/qiwi/patch/reducer'
import * as qiwiPatchSelectors from 'api/park/qiwi/patch/selectors'
import { handleRequest as qiwiPatchRequest } from 'api/park/qiwi/patch/thunks'

import { TQiwiPostAction } from 'api/park/qiwi/post/actions'
import { QiwiPostState, reducer as qiwiPost } from 'api/park/qiwi/post/reducer'
import * as qiwiPostSelectors from 'api/park/qiwi/post/selectors'
import { handleRequest as qiwiPostRequest } from 'api/park/qiwi/post/thunks'

import { TRegisterAction } from 'api/park/register/actions'
import { reducer as register, RegisterState } from 'api/park/register/reducer'
import * as registerSelectors from 'api/park/register/selectors'
import { handleRequest as registerRequest } from 'api/park/register/thunks'

import { TSubscriptionPaymentsAction } from 'api/park/subscription/payments/actions'
import {
  reducer as subscriptionPayments,
  SubscriptionPaymentsState,
} from 'api/park/subscription/payments/reducer'
import * as subscriptionPaymentsSelectors from 'api/park/subscription/payments/selectors'
import { handleRequest as subscriptionPaymentsRequest } from 'api/park/subscription/payments/thunks'

import { TSubscriptionSummaryAction } from 'api/park/subscription/summary/actions'
import {
  reducer as subscriptionSummary,
  SubscriptionSummaryState,
} from 'api/park/subscription/summary/reducer'
import * as subscriptionSummarySelectors from 'api/park/subscription/summary/selectors'
import { handleRequest as subscriptionSummaryRequest } from 'api/park/subscription/summary/thunks'

import { TTechnicalSupportContactsGetAction } from 'api/park/technical-support-contacts/get/actions'
import {
  reducer as technicalSupportContactsGet,
  TechnicalSupportContactsGetState,
} from 'api/park/technical-support-contacts/get/reducer'
import * as technicalSupportContactsGetSelectors from 'api/park/technical-support-contacts/get/selectors'
import { handleRequest as technicalSupportContactsGetRequest } from 'api/park/technical-support-contacts/get/thunks'

import { TTechnicalSupportContactsPatchAction } from 'api/park/technical-support-contacts/patch/actions'
import {
  reducer as technicalSupportContactsPatch,
  TechnicalSupportContactsPatchState,
} from 'api/park/technical-support-contacts/patch/reducer'
import * as technicalSupportContactsPatchSelectors from 'api/park/technical-support-contacts/patch/selectors'
import { handleRequest as technicalSupportContactsPatchRequest } from 'api/park/technical-support-contacts/patch/thunks'

import topupDepositPaymentOrderActions, {
  TTopupDepositPaymentOrderAction,
} from 'api/park/topup-deposit/payment-order/actions'
import {
  reducer as topupDepositPaymentOrder,
  TopupDepositPaymentOrderState,
} from 'api/park/topup-deposit/payment-order/reducer'
import * as topupDepositPaymentOrderSelectors from 'api/park/topup-deposit/payment-order/selectors'
import { handleRequest as topupDepositPaymentOrderRequest } from 'api/park/topup-deposit/payment-order/thunks'

import topupDepositPostActions, {
  TTopupDepositPostAction,
} from 'api/park/topup-deposit/post/actions'
import {
  reducer as topupDepositPost,
  TopupDepositPostState,
} from 'api/park/topup-deposit/post/reducer'
import * as topupDepositPostSelectors from 'api/park/topup-deposit/post/selectors'
import { handleRequest as topupDepositPostRequest } from 'api/park/topup-deposit/post/thunks'

import { TPaymentTransactionsAction } from 'api/park/transactions/get/actions'
import {
  PaymentTransactionsState,
  reducer as transactions,
} from 'api/park/transactions/get/reducer'
import * as transactionsSelectors from 'api/park/transactions/get/selectors'
import {
  handleRequest as transactionsRequest,
  handleRequestNotCompleted as transactionsRequestNotCompleted,
  handleRequestPending as transactionsRequestPending,
} from 'api/park/transactions/get/thunks'

import { TPaymentTransactionsConfirmAction } from 'api/park/transactions/confirm/actions'
import {
  PaymentTransactionsConfirmState,
  reducer as transactionsConfirm,
} from 'api/park/transactions/confirm/reducer'
import * as transactionsConfirmSelectors from 'api/park/transactions/confirm/selectors'
import { handleRequest as transactionsConfirmRequest } from 'api/park/transactions/confirm/thunks'

import { TPaymentTransactionsPaymentOrderAction } from 'api/park/transactions/paymentOrder/actions'
import {
  PaymentTransactionsPaymentOrderState,
  reducer as transactionsPaymentOrder,
} from 'api/park/transactions/paymentOrder/reducer'
import * as transactionsPaymentOrderSelectors from 'api/park/transactions/paymentOrder/selectors'
import { handleRequest as transactionsPaymentOrderRequest } from 'api/park/transactions/paymentOrder/thunks'
import {
  ParkPaymentSettingsActions,
  ParkPaymentSettingsState,
} from 'api/park/payment-settings/post/types'
import { postParkPaymentSettingsReducer } from 'api/park/payment-settings/post/reducer'
import { postParkPaymentSettingsRequest } from 'api/park/payment-settings/post/thunks'
import * as parkPaymentSettingsSelectors from 'api/park/payment-settings/post/selectots'
import {
  ParkPaymentSettingsAllActions,
  ParkPaymentSettingsAllState,
} from 'api/park/payment-settings/all/types'
import { parkPaymentSettingsAllReducer } from 'api/park/payment-settings/all/reducer'
import { getParkPaymentSettingsAllRequest } from 'api/park/payment-settings/all/thunks'
import * as parkPaymentSettingsAllSelectors from 'api/park/payment-settings/all/selectors'

import {
  ParkPaymentSettingsAssignActions,
  ParkPaymentSettingsAssignState,
} from 'api/park/payment-settings/assign/types'
import { parkPaymentSettingsAssignReducer } from 'api/park/payment-settings/assign/reducer'
import { patchParkPaymentSettingsAssignRequest } from 'api/park/payment-settings/assign/thunks'
import * as parkPaymentSettingsAssignSelectors from 'api/park/payment-settings/assign/selectots'

import { Actions as StatisticsFeesActions } from 'api/park/statistics/fees/actions'
import {
  reducer as statisticsFeesReducer,
  StatisticsFeesState,
} from 'api/park/statistics/fees/reducer'
import { handleRequest as statisticsFeesRequest } from 'api/park/statistics/fees/thunks'
import * as statisticsFeesSelectors from 'api/park/statistics/fees/selectors'

import { Actions as StatisticsWeekdayWithdrawActions } from 'api/park/statistics/weekday-withdraw/actions'
import {
  reducer as statisticsWeekdayWithdrawReducer,
  StatisticsWeekdayWithdrawState,
} from 'api/park/statistics/weekday-withdraw/reducer'
import { handleRequest as statisticsWeekdayWithdrawRequest } from 'api/park/statistics/weekday-withdraw/thunks'
import * as statisticsWeekdayWithdrawSelectors from 'api/park/statistics/weekday-withdraw/selectors'

import {
  ParkPaymentSettingsDeleteActions,
  ParkPaymentSettingsDeleteState,
} from 'api/park/payment-settings/delete/types'
import { parkPaymentSettingsDeleteReducer } from 'api/park/payment-settings/delete/reducer'
import { deleteParkPaymentSettingsRequest } from 'api/park/payment-settings/delete/thunks'
import * as parkPaymentSettingsDeleteSelectors from 'api/park/payment-settings/delete/selectots'

import {
  TransactionsCountersActions,
  TransactionsCountersState,
} from 'api/park/transactions/counters/types'
import { transactionsCountersReducer } from 'api/park/transactions/counters/reducer'
import { getTransactionsCountersRequest } from 'api/park/transactions/counters/thunks'
import * as transactionsCountersSelectors from 'api/park/transactions/counters/selectors'

import {
  PostNotifyBotActions,
  PostNotifyBotState,
} from 'api/park/notify-bot/post/types'
import { postNotifyBotReducer } from 'api/park/notify-bot/post/reducer'
import { postNotifyBotRequest } from 'api/park/notify-bot/post/thunks'
import * as postNotifyBotSelectors from 'api/park/notify-bot/post/selectors'

import {
  GetPaymentOrderActions,
  GetPaymentOrderState,
} from 'api/park/payment-order/get/types'
import { getPaymentOrderReducer } from 'api/park/payment-order/get/reducer'
import { getPaymentOrderRequest } from 'api/park/payment-order/get/thunks'
import * as getPaymentOrderSelectors from 'api/park/payment-order/get/selectors'

import {
  GetNotificationsBotActions,
  GetNotificationsBotState,
} from 'api/park/notifications/telegram/bot/get/types'
import { getNotificationsBotReducer } from 'api/park/notifications/telegram/bot/get/reducer'
import { getNotificationsBotRequest } from 'api/park/notifications/telegram/bot/get/thunks'
import * as getNotificationsBotSelectors from 'api/park/notifications/telegram/bot/get/selectors'

import {
  PostNotificationsBotActions,
  PostNotificationsBotState,
} from 'api/park/notifications/telegram/bot/post/types'
import { postNotificationsBotReducer } from 'api/park/notifications/telegram/bot/post/reducer'
import { postNotificationsBotRequest } from 'api/park/notifications/telegram/bot/post/thunks'
import * as postNotificationsBotSelectors from 'api/park/notifications/telegram/bot/post/selectors'

import {
  DeleteNotificationsBotActions,
  DeleteNotificationsBotState,
} from 'api/park/notifications/telegram/bot/delete/types'
import { deleteNotificationsBotReducer } from 'api/park/notifications/telegram/bot/delete/reducer'
import { deleteNotificationsBotRequest } from 'api/park/notifications/telegram/bot/delete/thunks'
import * as deleteNotificationsBotSelectors from 'api/park/notifications/telegram/bot/delete/selectors'

import {
  GetIncomeReportActions,
  GetIncomeReportState,
} from 'api/park/income-report/get/types'
import { getIncomeReportReducer } from 'api/park/income-report/get/reducer'
import { getIncomeReportRequest } from 'api/park/income-report/get/thunks'
import * as getIncomeReportSelectors from 'api/park/income-report/get/selectors'

import {
  DeleteEdgvrApiKeyActions,
  DeleteEdgvrApiKeyState,
} from 'api/park/edgvr-api-key/delete/types'
import { deleteEdgvrApiKeyReducer } from 'api/park/edgvr-api-key/delete/reducer'
import { deleteEdgvrApiKeyRequest } from 'api/park/edgvr-api-key/delete/thunks'
import * as deleteEdgvrApiKeySelectors from 'api/park/edgvr-api-key/delete/selectors'

import { GetModulActions, GetModulState } from 'api/park/modul/get/types'
import { getModulReducer } from 'api/park/modul/get/reducer'
import { getModulRequest } from 'api/park/modul/get/thunks'
import * as getModulSelectors from 'api/park/modul/get/selectors'

import { PostModulActions, PostModulState } from 'api/park/modul/post/types'
import { postModulReducer } from 'api/park/modul/post/reducer'
import { postModulRequest } from 'api/park/modul/post/thunks'
import * as postModulSelectors from 'api/park/modul/post/selectors'

import { PatchModulActions, PatchModulState } from 'api/park/modul/patch/types'
import { patchModulReducer } from 'api/park/modul/patch/reducer'
import { patchModulRequest } from 'api/park/modul/patch/thunks'
import * as patchModulSelectors from 'api/park/modul/patch/selectors'

import { GetTinkoffActions, GetTinkoffState } from 'api/park/tinkoff/get/types'
import { getTinkoffReducer } from 'api/park/tinkoff/get/reducer'
import { getTinkoffRequest } from 'api/park/tinkoff/get/thunks'
import * as getTinkoffSelectors from 'api/park/tinkoff/get/selectors'

import {
  PatchTinkoffActions,
  PatchTinkoffState,
} from 'api/park/tinkoff/patch/types'
import { patchTinkoffReducer } from 'api/park/tinkoff/patch/reducer'
import { patchTinkoffRequest } from 'api/park/tinkoff/patch/thunks'
import * as patchTinkoffSelectors from 'api/park/tinkoff/patch/selectors'

import {
  GetParkPayoutCredentialsActions,
  GetParkPayoutCredentialsState,
} from 'api/park/payout/credentials/get/types'
import { getParkPayoutCredentialsReducer } from 'api/park/payout/credentials/get/reducer'
import { getParkPayoutCredentialsRequest } from 'api/park/payout/credentials/get/thunks'
import * as getParkPayoutCredentialsSelectors from 'api/park/payout/credentials/get/selectors'

import {
  PostPayoutCredentialsState,
  PostPayoutCredentialsStateActions,
} from 'api/park/payout/credentials/post/types'
import { postPayoutCredentialsReducer } from 'api/park/payout/credentials/post/reducers'
import { postPayoutCredentialsRequest } from 'api/park/payout/credentials/post/thunks'
import * as postPayoutCredentialsSelectors from 'api/park/payout/credentials/post/selectors'

import { GetParkAzonState, GetParkAzonActions } from 'api/park/azon/get/types'
import { getParkAzonReducer } from 'api/park/azon/get/reducer'
import { getParkAzonRequest } from 'api/park/azon/get/thunks'
import * as getParkAzonSelectors from 'api/park/azon/get/selectors'

import {
  PostParkAzonState,
  PostParkAzonActions,
} from 'api/park/azon/post/types'
import { postParkAzonReducer } from 'api/park/azon/post/reducer'
import { postParkAzonRequest } from 'api/park/azon/post/thunks'
import * as postParkAzonSelectors from 'api/park/azon/post/selectors'

import { ParkReviseState, ParkReviseActions } from 'api/park/revise/post/types'
import { postParkReviseReducer } from 'api/park/revise/post/reducer'
import { postParkReviseRequest } from 'api/park/revise/post/thunks'
import * as postParkReviseSelectors from 'api/park/revise/post/selectors'

import {
  PostSubscriptionDepositAutoTopupActions,
  PostSubscriptionDepositAutoTopupState,
} from './subscription/deposit/auto-topup/post/types'
import { postSubscriptionDepositAutoTopupRequest } from './subscription/deposit/auto-topup/post/thunks'
import * as postSubscriptionDepositAutoTopupSelectors from './subscription/deposit/auto-topup/post/selectors'
import { postSubscriptionDepositAutoTopupReducer } from './subscription/deposit/auto-topup/post/reducer'

export interface ParkState {
  aggregatorPost: AggregatorPostState
  aggregatorByIdDelete: AggregatorByIdDeleteState
  aggregatorByIdGet: AggregatorByIdGetState
  aggregatorByIdPatch: AggregatorByIdPatchState
  aggregators: AggregatorsState
  allowAutoTransferGet: AllowAutoTransferGetState
  allowAutoTransferPost: AllowAutoTransferPostState
  balanceLog: BalanceLogState
  calculateTopupGift: CalculateTopupGiftState
  certificateGet: CertificateGetState
  certificatePost: CertificatePostState
  changeEdgvrApiKey: ChangeEdgvrApiKeyState
  contracts: ContractsState
  details: DetailsState
  edgvrApiKey: EdgvrApiKeyState
  notificationsGet: NotificationsGetState
  notificationsPatch: NotificationsPatchState
  organizationDetails: OrganizationDetailsState
  paymentSettingsGet: PaymentSettingsGetState
  paymentSettingsPost: ParkPaymentSettingsState
  paymentSettingsPatch: PaymentSettingsPatchState
  paymentSettingsAll: ParkPaymentSettingsAllState
  paymentSettingsAssign: ParkPaymentSettingsAssignState
  paymentSettingsDelete: ParkPaymentSettingsDeleteState
  qiwiDetails: QiwiDetailsState
  qiwiPatch: QiwiPatchState
  qiwiPost: QiwiPostState
  register: RegisterState
  subscriptionPayments: SubscriptionPaymentsState
  subscriptionSummary: SubscriptionSummaryState
  subscriptionDeposit: PostSubscriptionDepositAutoTopupState
  technicalSupportContactsGet: TechnicalSupportContactsGetState
  technicalSupportContactsPatch: TechnicalSupportContactsPatchState
  topupDepositPaymentOrder: TopupDepositPaymentOrderState
  topupDepositPost: TopupDepositPostState
  transactions: PaymentTransactionsState
  transactionsConfirm: PaymentTransactionsConfirmState
  transactionsPaymentOrder: PaymentTransactionsPaymentOrderState
  statisticsFees: StatisticsFeesState
  statisticsWeekdayWithdraw: StatisticsWeekdayWithdrawState
  transactionsCounters: TransactionsCountersState
  postNotifyBot: PostNotifyBotState
  getPaymentOrder: GetPaymentOrderState
  getNotificationsBot: GetNotificationsBotState
  postNotificationsBot: PostNotificationsBotState
  deleteNotificationsBot: DeleteNotificationsBotState
  getIncomeReport: GetIncomeReportState
  deleteEdgvrApiKey: DeleteEdgvrApiKeyState
  getModul: GetModulState
  postModul: PostModulState
  patchModul: PatchModulState
  getTinkoff: GetTinkoffState
  patchTinkoff: PatchTinkoffState
  postPayoutCredentials: PostPayoutCredentialsState
  getParkPayoutCredentials: GetParkPayoutCredentialsState
  getParkAzon: GetParkAzonState
  postParkAzon: PostParkAzonState
  postParkRevise: ParkReviseState
}

export type TParkAction =
  | TAggregatorPostAction
  | TAggregatorByIdDeleteAction
  | TAggregatorByIdGetAction
  | TAggregatorByIdPatchAction
  | TAggregatorsAction
  | TAllowAutoTransferGetAction
  | TAllowAutoTransferPostAction
  | TBalanceLogAction
  | TCalculateTopupGiftAction
  | TCertificateGetAction
  | TCertificatePostAction
  | TChangeEdgvrApiKeyAction
  | TContractsAction
  | TDetailsAction
  | TEdgvrApiKeyAction
  | TNotificationsGetAction
  | TNotificationsPatchAction
  | TOrganizationDetailsAction
  | TPaymentSettingsGetAction
  | TPaymentSettingsPatchAction
  | TSubscriptionPaymentsAction
  | PostSubscriptionDepositAutoTopupActions
  | TQiwiDetailsAction
  | TQiwiPatchAction
  | TQiwiPostAction
  | TRegisterAction
  | TSubscriptionSummaryAction
  | TTechnicalSupportContactsGetAction
  | TTechnicalSupportContactsPatchAction
  | TTopupDepositPaymentOrderAction
  | TTopupDepositPostAction
  | TPaymentTransactionsAction
  | TPaymentTransactionsConfirmAction
  | TPaymentTransactionsPaymentOrderAction
  | ParkPaymentSettingsActions
  | ParkPaymentSettingsAllActions
  | ParkPaymentSettingsAssignActions
  | StatisticsFeesActions
  | StatisticsWeekdayWithdrawActions
  | ParkPaymentSettingsDeleteActions
  | TransactionsCountersActions
  | PostNotifyBotActions
  | GetPaymentOrderActions
  | GetNotificationsBotActions
  | PostNotificationsBotActions
  | DeleteNotificationsBotActions
  | GetIncomeReportActions
  | DeleteEdgvrApiKeyActions
  | GetModulActions
  | PostModulActions
  | PatchModulActions
  | GetTinkoffActions
  | PatchTinkoffActions
  | PostPayoutCredentialsStateActions
  | GetParkPayoutCredentialsActions
  | GetParkAzonActions
  | PostParkAzonActions
  | ParkReviseActions

export const parkReducer = combineReducers({
  aggregatorPost,
  aggregatorByIdDelete,
  aggregatorByIdGet,
  aggregatorByIdPatch,
  aggregators,
  allowAutoTransferGet,
  allowAutoTransferPost,
  balanceLog,
  calculateTopupGift,
  certificateGet,
  certificatePost,
  changeEdgvrApiKey,
  contracts,
  details,
  edgvrApiKey,
  notificationsGet,
  notificationsPatch,
  organizationDetails,
  paymentSettingsPost: postParkPaymentSettingsReducer,
  paymentSettingsGet,
  paymentSettingsPatch,
  paymentSettingsAll: parkPaymentSettingsAllReducer,
  paymentSettingsAssign: parkPaymentSettingsAssignReducer,
  paymentSettingsDelete: parkPaymentSettingsDeleteReducer,
  qiwiDetails,
  qiwiPatch,
  qiwiPost,
  register,
  subscriptionPayments,
  subscriptionSummary,
  subscriptionDeposit: postSubscriptionDepositAutoTopupReducer,
  technicalSupportContactsGet,
  technicalSupportContactsPatch,
  topupDepositPaymentOrder,
  topupDepositPost,
  transactions,
  transactionsConfirm,
  transactionsPaymentOrder,
  statisticsFees: statisticsFeesReducer,
  statisticsWeekdayWithdraw: statisticsWeekdayWithdrawReducer,
  transactionsCounters: transactionsCountersReducer,
  postNotifyBot: postNotifyBotReducer,
  getPaymentOrder: getPaymentOrderReducer,
  getNotificationsBot: getNotificationsBotReducer,
  postNotificationsBot: postNotificationsBotReducer,
  deleteNotificationsBot: deleteNotificationsBotReducer,
  getIncomeReport: getIncomeReportReducer,
  deleteEdgvrApiKey: deleteEdgvrApiKeyReducer,
  getModul: getModulReducer,
  postModul: postModulReducer,
  patchModul: patchModulReducer,
  getTinkoff: getTinkoffReducer,
  patchTinkoff: patchTinkoffReducer,
  postPayoutCredentials: postPayoutCredentialsReducer,
  getParkPayoutCredentials: getParkPayoutCredentialsReducer,
  getParkAzon: getParkAzonReducer,
  postParkAzon: postParkAzonReducer,
  postParkRevise: postParkReviseReducer,
})

export const parkActions = {
  aggregatorPost: {
    handleRequest: aggregatorPostRequest,
    reset: aggregatorPostActions.reset,
  },
  aggregatorByIdDelete: {
    handleRequest: aggregatorByIdDeleteRequest,
  },
  aggregatorByIdGet: {
    handleRequest: aggregatorByIdGetRequest,
    reset: aggregatorByIdGetActions.reset,
  },
  aggregatorByIdPatch: {
    handleRequest: aggregatorByIdPatchRequest,
  },
  aggregators: {
    handleRequest: aggregatorsRequest,
  },
  allowAutoTransferGet: {
    handleRequest: allowAutoTransferGetRequest,
  },
  allowAutoTransferPost: {
    handleRequest: allowAutoTransferPostRequest,
  },
  balanceLog: {
    handleRequest: balanceLogRequest,
  },
  calculateTopupGift: {
    handleRequest: calculateTopupGiftRequest,
    reset: calculateTopupGiftActions.reset,
  },
  certificateGet: {
    handleRequest: certificateGetRequest,
  },
  certificatePost: {
    handleRequest: certificatePostRequest,
  },
  changeEdgvrApiKey: {
    handleRequest: changeEdgvrApiKeyRequest,
  },
  contracts: {
    handleRequest: contractsRequest,
  },
  details: {
    handleRequest: detailsRequest,
  },
  edgvrApiKey: {
    handleRequest: edgvrApiKeyRequest,
  },
  notificationsGet: {
    handleRequest: notificationsGetRequest,
  },
  notificationsPatch: {
    handleRequest: notificationsPatchRequest,
  },
  organizationDetails: {
    handleRequest: organizationDetailsRequest,
  },
  paymentSettingsPost: {
    handleRequest: postParkPaymentSettingsRequest,
  },
  paymentSettingsGet: {
    handleRequest: paymentSettingsGetRequest,
  },
  paymentSettingsPatch: {
    handleRequest: paymentSettingsPatchRequest,
  },
  paymentSettingsAll: {
    handleRequest: getParkPaymentSettingsAllRequest,
  },
  paymentSettingsDelete: {
    handleRequest: deleteParkPaymentSettingsRequest,
  },
  paymentSettingsAssign: {
    handleRequest: patchParkPaymentSettingsAssignRequest,
  },
  qiwiDetails: {
    handleRequest: qiwiDetailsRequest,
    reset: qiwiDetailsActions.reset,
  },
  qiwiPatch: {
    handleRequest: qiwiPatchRequest,
  },
  qiwiPost: {
    handleRequest: qiwiPostRequest,
  },
  register: {
    handleRequest: registerRequest,
  },
  subscriptionPayments: {
    handleRequest: subscriptionPaymentsRequest,
  },
  subscriptionSummary: {
    handleRequest: subscriptionSummaryRequest,
  },
  subscriptionDeposit: {
    handleRequest: postSubscriptionDepositAutoTopupRequest,
  },
  technicalSupportContactsGet: {
    handleRequest: technicalSupportContactsGetRequest,
  },
  technicalSupportContactsPatch: {
    handleRequest: technicalSupportContactsPatchRequest,
  },
  topupDepositPaymentOrder: {
    handleRequest: topupDepositPaymentOrderRequest,
    reset: topupDepositPaymentOrderActions.reset,
  },
  topupDepositPost: {
    handleRequest: topupDepositPostRequest,
    reset: topupDepositPostActions.reset,
  },
  transactions: {
    handleRequest: transactionsRequest,
    handleRequestPending: transactionsRequestPending,
    handleRequestNotCompleted: transactionsRequestNotCompleted,
  },
  transactionsConfirm: {
    handleRequest: transactionsConfirmRequest,
  },
  transactionsCounters: {
    handleRequest: getTransactionsCountersRequest,
  },
  transactionsPaymentOrder: {
    handleRequest: transactionsPaymentOrderRequest,
  },
  statisticsFees: {
    handleRequest: statisticsFeesRequest,
  },
  statisticsWeekdayWithdraw: {
    handleRequest: statisticsWeekdayWithdrawRequest,
  },
  postNotifyBot: {
    handleRequest: postNotifyBotRequest,
  },
  getPaymentOrder: {
    handleRequest: getPaymentOrderRequest,
  },
  getNotificationsBot: {
    handleRequest: getNotificationsBotRequest,
  },
  postNotificationsBot: {
    handleRequest: postNotificationsBotRequest,
  },
  deleteNotificationsBot: {
    handleRequest: deleteNotificationsBotRequest,
  },
  getIncomeReport: {
    handleRequest: getIncomeReportRequest,
  },
  deleteEdgvrApiKey: {
    handleRequest: deleteEdgvrApiKeyRequest,
  },
  getModul: {
    handleRequest: getModulRequest,
  },
  postModul: {
    handleRequest: postModulRequest,
  },
  patchModul: {
    handleRequest: patchModulRequest,
  },
  getTinkoff: {
    handleRequest: getTinkoffRequest,
  },
  patchTinkoff: {
    handleRequest: patchTinkoffRequest,
  },
  postPayoutCredentials: {
    handleRequest: postPayoutCredentialsRequest,
  },
  getParkPayoutCredentials: {
    handleRequest: getParkPayoutCredentialsRequest,
  },
  getParkAzon: {
    handleRequest: getParkAzonRequest,
  },
  postParkAzon: {
    handleRequest: postParkAzonRequest,
  },
  postParkRevise: {
    handleRequest: postParkReviseRequest,
  },
}

export const parkSelectors = {
  aggregatorPost: aggregatorPostSelectors,
  aggregatorByIdDelete: aggregatorByIdDeleteSelectors,
  aggregatorByIdGet: aggregatorByIdGetSelectors,
  aggregatorByIdPatch: aggregatorByIdPatchSelectors,
  aggregators: aggregatorsSelectors,
  allowAutoTransferGet: allowAutoTransferGetSelectors,
  allowAutoTransferPost: allowAutoTransferPostSelectors,
  balanceLog: balanceLogSelectors,
  calculateTopupGift: calculateTopupGiftSelectors,
  certificateGet: certificateGetSelectors,
  certificatePost: certificatePostSelectors,
  changeEdgvrApiKey: changeEdgvrApiKeySelectors,
  contracts: contractsSelectors,
  details: detailsSelectors,
  edgvrApiKey: edgvrApiKeySelectors,
  notificationsGet: notificationsGetSelectors,
  notificationsPatch: notificationsPatchSelectors,
  organizationDetails: organizationDetailsSelectors,
  paymentSettingsPost: parkPaymentSettingsSelectors,
  paymentSettingsGet: paymentSettingsGetSelectors,
  paymentSettingsPatch: paymentSettingsPatchSelectors,
  paymentSettingsAll: parkPaymentSettingsAllSelectors,
  paymentSettingsAssign: parkPaymentSettingsAssignSelectors,
  paymentSettingsDelete: parkPaymentSettingsDeleteSelectors,
  qiwiDetails: qiwiDetailsSelectors,
  qiwiPatch: qiwiPatchSelectors,
  qiwiPost: qiwiPostSelectors,
  register: registerSelectors,
  subscriptionPayments: subscriptionPaymentsSelectors,
  subscriptionSummary: subscriptionSummarySelectors,
  technicalSupportContactsGet: technicalSupportContactsGetSelectors,
  technicalSupportContactsPatch: technicalSupportContactsPatchSelectors,
  topupDepositPaymentOrder: topupDepositPaymentOrderSelectors,
  topupDepositPost: topupDepositPostSelectors,
  transactions: transactionsSelectors,
  transactionsConfirm: transactionsConfirmSelectors,
  transactionsPaymentOrder: transactionsPaymentOrderSelectors,
  statisticsFees: statisticsFeesSelectors,
  statisticsWeekdayWithdraw: statisticsWeekdayWithdrawSelectors,
  transactionsCounters: transactionsCountersSelectors,
  postNotifyBot: postNotifyBotSelectors,
  getPaymentOrder: getPaymentOrderSelectors,
  getNotificationsBot: getNotificationsBotSelectors,
  subscriptionDeposit: postSubscriptionDepositAutoTopupSelectors,
  postNotificationsBot: postNotificationsBotSelectors,
  deleteNotificationsBot: deleteNotificationsBotSelectors,
  getIncomeReport: getIncomeReportSelectors,
  deleteEdgvrApiKey: deleteEdgvrApiKeySelectors,
  getModul: getModulSelectors,
  postModul: postModulSelectors,
  patchModul: patchModulSelectors,
  getTinkoff: getTinkoffSelectors,
  patchTinkoff: patchTinkoffSelectors,
  postPayoutCredentials: postPayoutCredentialsSelectors,
  getParkPayoutCredentials: getParkPayoutCredentialsSelectors,
  getParkAzon: getParkAzonSelectors,
  postParkAzon: postParkAzonSelectors,
  postParkRevise: postParkReviseSelectors,
}
