import { State } from 'reducers'
import { createSelector } from 'reselect'
import { selectIsDeleteReferralOfferStatus } from './delete/selectors'
import { selectIsGetReferralOfferStatus } from './get/selectors'
import { selectIsPatchReferralOfferStatus } from './patch/selectors'
import { selectIsPostReferralOfferStatus } from './post/selectors'

export const selectReferralOffer = (state: State) =>
  state.api.referral.offers.data?.offer?.[0]

export const selectIsReferralOfferFetching = (state: State) =>
  createSelector(
    selectIsGetReferralOfferStatus,
    selectIsPatchReferralOfferStatus,
    selectIsPostReferralOfferStatus,
    selectIsDeleteReferralOfferStatus,
    (...statuses) => statuses.some((status) => status === 'loading')
  )
