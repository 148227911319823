import Alert from 'react-s-alert'
import { apiActions } from 'api'
import { MyThunkDispatch, MyThunkResult } from 'api/reducer'
import { ApiCode } from 'common/types/api/codes'
import { FormProps } from 'settings/features/parkEdgvr/containers/ParkEdgvrContainer'

export const onSaveParkEdgvr: ({
  values,
}: {
  values: FormProps
}) => MyThunkResult<void> = ({ values }) => (dispatch: MyThunkDispatch) => {
  return dispatch(
    apiActions.park.changeEdgvrApiKey.handleRequest({
      values: { edgvr_api_key: values.edgvr_api_key },
    })
  ).then(
    () => {
      dispatch(apiActions.park.edgvrApiKey.handleRequest())
      Alert.info('Изменения сохранены')
    },
    (reason) => {
      if (
        reason.response &&
        (reason.response.data.code === ApiCode.EdgvrApiKeyIncorrect ||
          reason.response.data.code === ApiCode.InputValidationError)
      ) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw { edgvr_api_key: 'Неправильный Ключ API edgvr' }
      }
    }
  )
}
