import React from 'react'

import { DepositTransactionsContainer } from 'settings/features/depositTransactions'
import styled from 'styled-components'

const Wrapper = styled.div`
  margin: ${({ theme }) => theme.paddings.main}px;
`

export const DepositTransactionsPage: React.FC = () => (
  <Wrapper>
    <DepositTransactionsContainer />
  </Wrapper>
)
